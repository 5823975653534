/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
/* import { Link } from "react-router-dom"; */

// import botn_info from '../images/botn-info.png';
import user_icon from '../images/uesr-icon.png';
import botn_login from '../images/botn-login.png';
import botn_logout from '../images/botn-logout.png';

function TitleBar(){

  const adminLoginState = Number(localStorage.getItem('adminLoginState'));
  // const adminMode = Number(localStorage.getItem("adminMode"));
  const adminName = localStorage.getItem("adminName");

  const onLogoutHandler = (e) =>{
    e.preventDefault();
    localStorage.removeItem('adminLoginState');
    localStorage.removeItem('adminIdx');
    localStorage.removeItem('adminName');
    localStorage.removeItem('adminMode');
    localStorage.removeItem('adminRegion');
    localStorage.removeItem('menuAvailables');
    //사용자관련
    localStorage.removeItem("mem_idx");
    localStorage.removeItem("mcm_idx");
    localStorage.removeItem("mem_mode");
    localStorage.removeItem("mem_nickname");
    localStorage.removeItem("mcm_name");
    localStorage.removeItem("memberLoginState");
    localStorage.removeItem("mem_admin");
    document.location.href = '/Admin/Login';
  }
  const onLoginHandler = (e) => {
    document.location.href = '/Admin/Login';
  }

  // const onMemberHandler = (e) => {
  //   e.preventDefault();
  //   let sURLPage = "";
  //   if(adminMode === 1) sURLPage = "/Admin/Environment/AdminState";
  //   else if(adminMode === 2) sURLPage = "/Admin/Environment/SubAdminInformation";
  //   else if(adminMode === 3) sURLPage = "/Admin/Environment/AgencyAdminInformation";
  //   window.location.href = sURLPage;
  // }

  return(
    <div id="head-wrap">
      <div className="center-wrap">
        <div className="f-rightt">
          {adminLoginState === 0 ? "" :
            <>
              <div className="info">
                <img src={user_icon} alt=""/>Welcome&nbsp;&nbsp;<span>{adminName}</span>
              </div>
              {/* <button onClick={(e)=>onMemberHandler(e)} className="btn-s"><img src={botn_info} alt=""/>관리자정보</button> */}
            </>
          }
          {/* <Link className="btn-s" target="_blank" to="/Main"><img src={botn_info} alt=""/>웹사이트 이동</Link> */}
          {adminLoginState === 0 ?
            <button onClick={(e)=>onLoginHandler(e)} className="btn-s"><img src={botn_login} alt=""/>로그인</button>
          :
            <button onClick={(e)=>onLogoutHandler(e)} className="btn-s"><img src={botn_logout} alt=""/>로그아웃</button>
          }
        </div>
      </div>
    </div>
  )
}

export default TitleBar;
