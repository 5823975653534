/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import { Link } from 'react-router-dom';

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

import bigIcon3 from "../../images/big_icon03.png";

const DoongjiSeriousOK = () => {

  return (
    <>
      <Header/>
      <HeadTitle titleString={"매물 등록 완료"} backString={'backDoongji'}/>
      <div id="content">
        <div className="pd_wrap">
          <div className="ok_form_wrap">
            <div className="center_wrap">
              <img src={bigIcon3} alt=""/>
              <div className="top_txt">
                <strong>의뢰가 등록되었습니다.</strong>
                <p>의뢰해주신 내용이 관리자에게<br/>전달될 예정입니다.<br/>확인 후 별도 안내 드리겠습니다.</p>
              </div>
              <div className="btn_bottom one_btn">
                <Link className="btn_style_yes" to="/Doongji/DoongjiList">홈으로 이동</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DoongjiSeriousOK;