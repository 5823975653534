import React, { useState,useEffect } from "react"; 
import { isNull } from '../../components/common/Common';

//매물 상세 정보 표시
const DoongjiOptionInfo = ({varProductData}) => {
  const [varOptions,setOptions] = useState([]);
  const [varOptionString,setOptionString] = useState('');

  //페이지 정보 표시 - 최초
  useEffect(() => {
    const options = varProductData.rp_options;
    if(!isNull(options)) {
      if(Number(varProductData.rp_serious) <= 5) {
        const optionArray = options.split(',');
        setOptions(optionArray);
      } else {
        setOptionString(options);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varProductData]);
  
  return (varProductData.rp_serious === 9 || 
          (varProductData.rp_serious <= 5 && varOptions.length === 0) || 
          isNull(varProductData.rp_serious > 5 && varOptionString)) ? null : (
    <div className="info03">
      <div className="tit07_1">옵션</div>
      <div className="con_box">
        {varProductData.rp_serious <= 5 ? (
          <div className="c_hash2">
            <ul>
              {varOptions.map((option, i) => (
                <li key={"option_" + i} style={{ marginRight: "6px" }}>{option}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="c_hash2">{varOptionString}</div>
        )}
      </div>
    </div>
  );
}
export default DoongjiOptionInfo;