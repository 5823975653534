import React from "react";
import { Link } from "react-router-dom";
/* import axios from 'axios';
import CONFIG from '../../../configs/Configs'; */

import {ChangeDateStringOnlyDate,addThousandPoint,ResetCheckBoxes,SetProductTitle} from "../../../components/common/Common";

function PaymentSearchResult({varMaxNumPage,varPaymentLists,setPaymentLists,onDeletePaymentHandler}) {

  //목록의 모든 항목 선택하기
  const onCheckedAll = (e) => {
    const bChecked = Number(e.currentTarget.checked);
    clearBoardLists(bChecked);
  } 
  //체크박스 초기화
  const clearBoardLists = (bmode) => {
    const checkBoxes = document.querySelectorAll('.del-item');
    for(var i=0; i < checkBoxes?.length; i++) {
      checkBoxes[i].checked = bmode;
    }
    //배열값 변경
    let copyDatas = [...varPaymentLists];
    for (var j=0; j < copyDatas.length; j++) {
      copyDatas[j].checked = bmode;
    }
    setPaymentLists(copyDatas);
  }
  //목록상의 항목 선택하기
  const onCheckedElement = (e,ph_idx) => {
    const bChecked = Number(e.currentTarget.checked);
    const findIndex = varPaymentLists.findIndex(element=>element.ph_idx===ph_idx);
    let copyLists = [...varPaymentLists];
    copyLists[findIndex].checked = bChecked;
    setPaymentLists(copyLists);
    ResetCheckBoxes();
  } 

  return (
    <div className="main-box">
      <div className="btn-right">
        <button onClick={(e)=>onDeletePaymentHandler(e)} className="btn line">삭제</button>
      </div>
      <table className="list">
        <colgroup>
          <col style={{width:"5%"}} />
          <col style={{width:"5%"}} />
          <col style={{width:"15%"}} />
          <col style={{width:"15%"}} />
          <col style={{width:"15%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"15%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"10%"}} />
        </colgroup>
        <thead>
          <tr>
            <th className="board-list">
              <input type="checkbox" id="deleteItems" onChange={(e)=>onCheckedAll(e)} />
            </th>
            <th>번호</th>
            <th>구매자</th>
            <th>구매상품</th>
            <th>결제금액</th>
            <th>결제방식</th>
            <th>구매일자</th>
            <th>진행상태</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {varPaymentLists.map((payment, i) => (
            <tr key={'payment_'+payment.ph_idx}>
              <td className="board-list">
                <input type="checkbox" className={"del-item"} id={"deleteItem_" + payment.ph_idx} onChange={(e)=>onCheckedElement(e,payment.ph_idx)}/>
              </td>
              <td>{varMaxNumPage - i}</td>
              <td className="ellipsis">{payment.mcm_name}</td>
              <td className="ellipsis">{SetProductTitle(payment)}</td>
              <td className="ellipsis">{addThousandPoint(payment.ph_amount)}</td>
              <td className="ellipsis">{payment.ph_method===0?"무통장입금":"신용카드"}</td>
              <td className="ellipsis">{ChangeDateStringOnlyDate(payment.ph_rdate)}</td>
              <td className="ellipsis" style={payment.ph_status===1||payment.ph_status===2||payment.ph_status===5?{color:"red"}:{}}>
                {payment.ph_status===1?"신청중"
                :payment.ph_status===2?"승인/결제대기"
                :payment.ph_status===3?"결제완료"
                :payment.ph_status===4?"결제취소"
                :payment.ph_status===5?"환불요청"
                :payment.ph_status===6?"환불완료"
                :payment.ph_status===7?"환불실패":""
                }
              </td>
              <td>
                <Link to={`/Admin/Payment/PaymentDetail/${payment.ph_mode}/${payment.ph_idx}`} style={{width:"90px"}} className="btn line">세부정보</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PaymentSearchResult;