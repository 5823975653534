/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import AdminState from "../../components/environment/AdminState";
import SubAdminInformation from "../../components/environment/SubAdminInformation";
import AgencyAdminInformation from "../../components/environment/AgencyAdminInformation";
import SubAdminState from "../../components/environment/SubAdminState";
import AgencyAdminState from "../../components/environment/AgencyAdminState";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import '../../admin_css/variables.css';

const Environment = () => {
  //메뉴항목지정
  const [varMenuItem,setMenuItem] = useState('');

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'ENVIRONMENT'}/>
      <Menus varMenuType={'ENVIRONMENT'} varMenuItem={varMenuItem}/>
      <Routes>
        {/* 운영계정관리 */}
        <Route path="AdminState" element={<AdminState setMenuItem={setMenuItem}/>} />
        <Route path="SubAdminInformation" element={<SubAdminInformation setMenuItem={setMenuItem}/>} />
        <Route path="AgencyAdminInformation" element={<AgencyAdminInformation setMenuItem={setMenuItem}/>} />
        <Route path="SubAdminState" element={<SubAdminState setMenuItem={setMenuItem}/>} />
        <Route path="AgencyAdminState" element={<AgencyAdminState setMenuItem={setMenuItem} />} />
      </Routes>
    </>
  );
}
export default Environment;