/* eslint-disable import/no-anonymous-default-export */
export default {
  SERVER_HOST : "http://newproj.cafe24.com:8007/api",
  CHATTING_HOST : "http://newproj.cafe24.com:8007",
  PAYMENT_HOST :  "http://newproj.cafe24.com",
  TossClientKey : "test_ck_dP9BRQmyarYAeQaQ5QZrJ07KzLNk",
  TossSecretKey : "test_sk_GKNbdOvk5rkz95k67dnVn07xlzmj",
  TossCustomerKey: "YbX2HuSlsC9uVJW6NMRMj",
  header : {"Content-Type": 'application/json'},
  VariableItems: [
    {index:0,title:""},
    {index:1,title:"검색필터종류",ids:"vsm_idx",idn:"vsm_name"},
    {index:21,title:"검색필터세부항목",ids:"vfo_idx",idn:"vfo_name"},
    {index:2,title:"상품옵션",ids:"vpo_idx",idn:"vpo_name"},
    {index:3,title:"건축물용도",ids:"vpp_idx",idn:"vpp_name"},
    {index:4,title:"건축물구조",ids:"vst_idx",idn:"vst_name"},
    {index:5,title:"종목구분",ids:"vfi_idx",idn:"vfi_name"},
    {index:6,title:"사업자파트너구분",ids:"vb_idx",idn:"vb_name"},
    {index:26,title:"사업자파트너업종구분",ids:"vbd_idx",idn:"vbd_name"},
    {index:7,title:"모두의장터구분",ids:"vbs_idx",idn:"vbs_name"},
    {index:27,title:"장터중분류구분",ids:"vbi_idx",idn:"vbi_name"},
    {index:107,title:"장터소분류구분",ids:"vbp_idx",idn:"vbp_name"},
    {index:8,title:"건물위치",ids:"vbl_idx",idn:"vbl_name"},
    {index:9,title:"용도지역",ids:"vua_idx",idn:"vua_name"},
    {index:10,title:"지하철노선",ids:"subway_idx",idn:"subway_line"},
    {index:30,title:"지하철역",ids:"subway_idx",idn:"subway_name"}
  ]
};
