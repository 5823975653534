import React, { useState,useEffect } from "react"; 
import { useParams,useNavigate,Link } from 'react-router-dom'; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";
import {CopyToClipboard} from "react-copy-to-clipboard/src";

import { isNull,ChangeMobileFormat,ChangeDateStringOnlyDate,isObjEmpty,CheckLoginStateOnly,addThousandPoint,GetPhotoImage } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import KakaoMap from '../../components/include/KakaoMap';
import JangterSlider from '../../components/jangter/JangterSlider';
import JangterDelete from '../../components/popup/JangterDelete';
import ReportProduct from '../../components/popup/ReportProduct';
import MapLocation from '../../components/popup/MapLocation';

import heartOffIcon from "../../images/icon_heart_w.svg";
import heartOnIcon from "../../images/icon_heart_y.svg";
import alertIcon from "../../images/icon_singo.png";
import shareIcon from "../../images/icon_share.png";
import callIcon from "../../images/icon_call_gray.png";
import callingImage from "../../images/icon_call30.png";

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

//파트너스 항목 표시
const JangterView = () => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const memberLoginState = Number(localStorage.getItem("memberLoginState"));
  const { jt_idx } = useParams();
  const navigator = useNavigate();

  const [varJangterData,setJangterData] = useState({});
  const [varJangterPhoto,setJangterPhoto] = useState([]);

  //파트너스 정보 가져오기
  const getJangterInformation = async() => {
    const sendData = {jt_idx:jt_idx,mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_jangter_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //검색된 목록
        setJangterData(datas.results[0]);
        setJangterPhoto(datas.photos);
      } else {
        setJangterData([]);
        setJangterPhoto([]);
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    getJangterInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //찜목록 등록/해제
  const onChangeJjimListHandler = async (e,jt_idx) => {
    if(memberLoginState === 0) { navigator('/Login'); }
    e.preventDefault();
    let jj_idx = varJangterData.jj_idx;
    let jj_status = varJangterData.jj_status;
    if(jj_idx === null) {
      jj_idx = 0;
      jj_status = 0;
    } 
    if(jj_status === 1) jj_status = 0;
    else jj_status = 1;
  
    const sendData = {pageMode:2,opp_idx:jt_idx,jj_idx:jj_idx,jj_mem_idx:mem_idx,jj_status:jj_status};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_jjim_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(res.data);
      if(datas.retvalue === 1) {
        //검색된 목록
        setJangterData({...varJangterData,jj_idx:datas.jj_idx,jj_status:jj_status});
      }
    });   
  }

  ////채팅방열기
  const onMakeChatRoomHandler = async (e) => {
    if(!CheckLoginStateOnly(mem_idx)) {
      navigator('/Login');
    } else {
      //채팅방 정보 읽거나 만들기
      const sendData = {ch_mode:2,no_idx:jt_idx,mem_idx:mem_idx};
      console.log(sendData);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_chatroom`,sendData).then((res) => {
        const datas = res.data;
        console.log(datas);
        if(datas.retvalue === 1 || datas.retvalue === 2) {
          navigator(`/Chat/ChatRoom/2/${datas.ch_idx}`); 
        } else {
          alert(datas.message);
        }
      });
    }
  }

  //장터게시물 삭제 팝업창
  const [varPopupJangterDelete,setPopupJangterDelete] = useState(false);
  const onPopupJangterDeleteHandler = (e,bmode) => {
    e.preventDefault();

    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupJangterDelete(false);
      navigator('/Jangter');
    } else {
      onPopupWinHandler(bmode);
      setPopupJangterDelete(bmode);
    }
  }
  const onPopupWinHandler = (bmode) => {
    console.log(bmode);
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }

  //신고하기
  const [varPopupReportProduct,setPopupReportProduct] = useState(false);
  const onPopupReportProductHandler = (e,bmode) => {
    if(memberLoginState === 0) { navigator('/Login'); }
    e.preventDefault();
    onPopupWinHandler(bmode);
    setPopupReportProduct(bmode);
  }
  //공유하기
  const onShareProductHandler = async(e) => {
    if(memberLoginState === 0) {
      navigator('/Login');
    }
    alert('클립보드로 복사되었습니다.');
  }

  //전체 지도보기
  const [varMapLocation,setMapLocation] = useState(false);
  const onOpenMapLocationHandler = (e,bmode) => {
    setMapLocation(bmode);
  }

  return isObjEmpty(varJangterData)?null: (
    <> 
      <Header/>
      <div className="layer-bg" style={{backgroundColor:"rgba(0,0,0,1)"}}></div>
      <HeadTitle titleString={varJangterData.mcm_name===null?varJangterData.vbi_name:varJangterData.mcm_name}/>
      {isObjEmpty(varJangterData)?null:
      <div id="content" style={{paddingBottom:"40px"}}>
        <div className="pd_wrap02">
          <div className="cpy_view_wrap">
            <JangterSlider varJangterData={varJangterData} varJangterPhoto={varJangterPhoto}/>
          </div>
          <div className="top_info_wrap b_shadow">
            <div className="center_wrap">
              <div className="c_wrap02">
                <div className="tit03">
                  <span className="btn_st_A">{varJangterData.jt_dongname}</span>
                  <span className="btn_title">{varJangterData.jt_title}</span>
                  <div className="right_wrap">
                    <div className="heart wish_chk" onClick={e=>onChangeJjimListHandler(e,varJangterData.jt_idx)}>
                      <img src={varJangterData.jj_idx===null||varJangterData.jj_status===0?heartOffIcon:heartOnIcon} alt="하트"/>
                    </div>
                    <div className="singo"><img src={alertIcon} alt="신고" onClick={e=>onPopupReportProductHandler(e,true)}/></div>
                    <div className="share">
                      <CopyToClipboard text={`${CONFIG.PAYMENT_HOST}/Jangter/JangterView/${varJangterData.jt_idx}`} onCopy={e=>onShareProductHandler(e)}>
                        <img src={shareIcon} alt="공유"/>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
                <div className="date_wrap">
                  <div className="date">등록일 <span>{ChangeDateStringOnlyDate(varJangterData.jt_rdate)}</span></div> <span className="gray_bar">&nbsp;</span>
                  <div className="hit">조회수 <span>{varJangterData.jt_hits}</span>&nbsp;회</div>
                </div>
                {varJangterData.jt_mobile ? (
                  <div className="call">
                    <img src={callIcon} alt=""/>
                    &nbsp;{ChangeMobileFormat(varJangterData.jt_mobile)}
                  </div>
                ) : null}
                {varJangterData.jt_serious1===3?null
                :varJangterData.jt_serious1===1?
                  <div style={{padding:"10px 0px"}}>
                    {"금액 "+addThousandPoint(varJangterData.jt_price)+"원"}
                  </div>
                :
                  <div style={{padding:"10px 0px"}}>
                    {varJangterData.jt_price_mode===0?"시급 ":"월급 "}{addThousandPoint(varJangterData.jt_price)+"원"}
                  </div>
                }
                <div className="desc" style={{padding:"10px 0px",whiteSpace:"pre-line"}}>{varJangterData.jt_comment}</div>
              </div>
            </div>
          </div>
          {varJangterData.jt_serious1===3?null:
            <div className="mid_info_wrap">
              <div className="center_wrap">
                <div className="info01">
                  {isNull(varJangterData.jt_address1)?null:
                    <>
                      {varJangterData.jt_serious1===1?
                      <div className="tit07_1">거래장소</div>:
                        varJangterData.jt_serious2===5?
                        <div className="tit07_1">구인장소</div>:
                        <div className="tit07_1">구직장소</div>
                      }
                      <div className="desc" style={{marginBottom:"5px"}}>{varJangterData.jt_address1+" "+varJangterData.jt_address2}</div>
                    </>
                  }
                  {/* 지도표시 */}
                  {isNull(varJangterData.jt_zipcode)?null:
                    <>
                      <KakaoMap varCenterPoint={varJangterData.jt_location} varAddress={varJangterData.jt_address1} onOpenMapLocationHandler={onOpenMapLocationHandler}/>
                      <div className="desc">* 지도를 클릭하시면 확대됩니다.</div>
                    </>
                  }
                </div>
              </div>
            </div>
          }
          {varJangterData.jt_serious1!==2?null:
            <div className="mid_info_wrap" style={{backgroundColor:"#fff"}}>
              <div className="center_wrap">
                <div className="info04" style={{marginBottom:"50px"}}>
                  {varJangterData.jt_serious2===5?
                        <div className="tit07_1">구인 정보</div>:
                        <div className="tit07_1">구직 정보</div>
                  }
                  <div className="info04_inner">
                    <div className="img_wrap" style={{maxWidth:"30%"}}>
                      <img src={`${CONFIG.SERVER_HOST}/`+GetPhotoImage(varJangterData.mem_mode===0?varJangterData.mem_file:varJangterData.mcm_file,'member')} alt=""/>
                    </div>
                    <div className="box">
                      <div className="c_name">{varJangterData.mem_mode===0?varJangterData.mem_name:varJangterData.mcm_name}</div>
                      <div className="person_info">
                        {varJangterData.mem_mode>0?<><span>대표</span>&nbsp;&nbsp;<span className="name_txt">{varJangterData.mem_name}</span>&nbsp;&nbsp;<br/></>:null}
                        {ChangeMobileFormat(varJangterData.mem_mode===0?varJangterData.mem_mobile:varJangterData.mcm_phone)}
                      </div>
                      <div className="fnc_wrap">
                        <a href={`tel:${varJangterData.mem_mode===0?varJangterData.mem_mobile:varJangterData.mcm_phone}`}><img src={callingImage} style={{width:"50px"}} alt=""/></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        {/* 작성자가 정보를 보는 경우 */}
        {Number(varJangterData.mem_idx)===mem_idx?
          <div className="btn_bottom in_my_write" style={{zIndex:"10"}}>
            <Link className="btn_style_no" style={{width:'25%'}} to="/Jangter">홈</Link>
            <Link className="btn_style_brown" style={{width:'25%'}} to={`/Jangter/JangterDetail/${jt_idx}`}>수정</Link>
            <button className="btn_style_gray w_del_open" onClick={e=>onPopupJangterDeleteHandler(e,true)}>삭제</button>
          </div>
        :
          <div className="btn_bottom one_btn" style={{zIndex:"10"}}>
            <button className="btn_style_yes" onClick={e=>onMakeChatRoomHandler(e)}>채팅하기</button>
          </div>
        }      
      </div>
      }
    {/* 팝업창 */}
    <ReportProduct rpIdx={jt_idx} rpMode={2} varPopupReportProduct={varPopupReportProduct} onPopupReportProductHandler={onPopupReportProductHandler}/>
    <JangterDelete varPopupJangterDelete={varPopupJangterDelete} varCurrentJangterIndex={jt_idx} onPopupJangterDeleteHandler={onPopupJangterDeleteHandler}/>
    <MapLocation varCenterPoint={varJangterData.jt_location} varAddress={varJangterData.jt_address1} 
                    varMapLocation={varMapLocation} onOpenMapLocationHandler={onOpenMapLocationHandler}/>
    </>
  );
}
export default JangterView;
