/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import PaymentVoucherList from "../../components/payments/PaymentVoucherList";
import PaymentPremiumList from "../../components/payments/PaymentPremiumList";
import PaymentDetail from "../../components/payments/PaymentDetail";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import "../../admin_css/board.css";

/* 요금/이용권 관리 */
const Payments = () => {
  //메뉴항목지정
  const [varMenuItem,setMenuItem] = useState('');

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'PAYMENTS'}/>
      <Menus varMenuType={'PAYMENTS'} varMenuItem={varMenuItem}/>
      <Routes>
        {/* 이용권 결제 관리 */}
        <Route path="/PaymentVoucherList" element={<PaymentVoucherList setMenuItem={setMenuItem} />} />
        {/* 프리미엄 결제 관리 */}
        <Route path="/PaymentPremiumList" element={<PaymentPremiumList setMenuItem={setMenuItem} />} />
        {/* 결제 세부 정보 관리 */}
        <Route path="/PaymentDetail/:ph_mode/:ph_idx" element={<PaymentDetail setMenuItem={setMenuItem} />} />
      </Routes>
    </>
  );
}
export default Payments;