import React from "react";
import { Link } from "react-router-dom";
/* import axios from 'axios';
import CONFIG from '../../../configs/Configs'; */

import {ChangeDateStringOnlyDate} from "../../../components/common/Common";

function ApprovalSearchResult({varMaxNumPage,varApprovalLists,getMemberApprovalList}) {
  //미승인 파트너스 삭제
/*   const onDeleteApprovalHandler = async (e,mcm_idx,mem_idx) => {
    if(!window.confirm('선택된 미승인 파트너스들을 삭제하시겠습니까?')) return false;

    const sendData = {mcm_idx:mcm_idx,mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_approval_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //미승인 파트너스목록 재설정
        getMemberApprovalList();
      }
    });
  } */

  return (
    <>
      <div className="main-box">
        <table className="list">
          <colgroup>
            <col style={{width:"5%"}} />
            <col style={{width:"15%"}} />
            <col style={{width:"25%"}} />
            <col style={{width:"10%"}} />
            <col style={{width:"15%"}} />
            <col style={{width:"15%"}} />
            <col style={{width:"15%"}} />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>회사명</th>
              <th>회원ID(이메일)</th>
              <th>파트너스종류</th>
              <th>승인요청일</th>
              <th>가입일</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {varApprovalLists.map((partner, i) => (
              <tr key={'partner_'+partner.mcm_idx}>
                <td>{varMaxNumPage - i}</td>
                <td className="ellipsis">{partner.mcm_name}</td>
                <td className="ellipsis">{partner.mem_email}</td>
                <td className="ellipsis">{partner.mem_mode===1?"부동산":"사업자"}</td>
                <td className="ellipsis">{ChangeDateStringOnlyDate(partner.mcm_rdate)}</td>
                <td className="ellipsis">{ChangeDateStringOnlyDate(partner.mem_rdate)}</td>
                <td >
                  <Link to={`../ApprovalDetail/${partner.mcm_idx}`} style={{width:"90px"}} className="btn line black">세부정보</Link>
                  {/* <button onClick={(e)=>onDeleteApprovalHandler(e,partner.mcm_idx,partner.mem_idx)} style={{width:"90px"}} className="btn line">삭제</button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ApprovalSearchResult;