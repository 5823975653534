import React, { useState,useEffect } from "react"; 
import { useNavigate, Link ,useSearchParams } from 'react-router-dom';
import { addThousandPoint } from "../../components/common/Common";
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import HeadTitle from '../../components/include/HeadTitle';
import bigIcon from "../../images/big_icon01.png";

const SuccessToss = () => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [varOrderName,setOrderName] = useState('');
  const [varPaymentIndex,setPaymentIndex] = useState(0);
  const [varPaymentMode,setPaymentMode] = useState(0);
  const [varPaymentExtend,setPaymentExtend] = useState(0);
  
  const [varVoucherSlot,setVoucherSlot] = useState(0);
  const [varVoucherTerm,setVoucherTerm] = useState(0);
  const [varAdvertiseIndex,setAdvertiseIndex] = useState(0);

  //Toss페이로부터 결제성공 전달값
  const [varResult,setResult] = useState({});

  //주문상품명 가져오기
  const GetOrderProductName = async(orderId) => {
    const sendData = {mem_idx:mem_idx,orderId:orderId}
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_ordername_payment`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(datas.results?.length > 0) {
          const dataOrder = datas.results[0];

          let orderName = "";
          if(dataOrder.ph_mode === 3) orderName = "둥지프리미엄광고";
          else if(dataOrder.ph_mode === 4) orderName = "장터프리미엄광고";
          else orderName = dataOrder.pay_name+'('+dataOrder.pay_terms+'개월)'; 

          if(dataOrder.ph_mode<3 && dataOrder.ph_extend===1) orderName += " - 이용기간연장"
          setOrderName(orderName);

          setPaymentIndex(dataOrder.ph_idx);
          setPaymentMode(dataOrder.ph_mode);
          setVoucherTerm(dataOrder.pay_terms);
          setAdvertiseIndex(dataOrder.rpa_idx);
          setPaymentExtend(dataOrder.ph_extend);

          //슬럿수 ---> 둥지이용권:1, 장터이용권:2
          if(dataOrder.ph_mode === 1) setVoucherSlot(dataOrder.doongji_slot);
          else if(dataOrder.ph_mode === 2) setVoucherSlot(dataOrder.jangter_slot);
        }
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    const orderId = searchParams.get("orderId");
    const paymentKey = searchParams.get("paymentKey");
    const amount = Number(searchParams.get("amount"));
    console.log(orderId,paymentKey,amount);
    setResult({orderId:orderId,paymentKey:paymentKey,amount:amount});
    GetOrderProductName(orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //결제확인
  const ApprovalCardPaymentHandler = async(e) => {
    console.log(varResult);
    const sendData = {paymentInfo:varResult};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_approval_tosspayment`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        SaveCardPayment();
      } else {
        alert(datas.message);
      }
    });
  }

  //결제내역을 서버에 저장
  const SaveCardPayment = async() => {
    const sendData = {mem_idx:mem_idx,paymentKey:varResult.paymentKey,ph_idx:varPaymentIndex,ph_mode:varPaymentMode,
                    ph_extend:varPaymentExtend,rpa_idx:varAdvertiseIndex,slot:varVoucherSlot,terms:varVoucherTerm};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_success_payment`,sendData).then((res) => {
      const datas = res.data;
      if(varPaymentMode === 2) {  //장터 파트너스
        CheckAndUpdateJangterPartner();
      } else {
        alert(datas.message);
        navigate('/MyPage');
      }
    });
  }
  //장터 이용권결제완료이면 장터 파트너스 광고요청건 승인
  const CheckAndUpdateJangterPartner = async() => {
    const sendData = {mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_jangter_partner`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      alert(datas.message);
      //if(datas.retvalue === 1) {
        navigate('/Jangter/RegisterPartner');
      //}
    });
  }

  return (
    <>
      <HeadTitle titleString={"카드 결제 확인"} backString={"none"}/>
      <div className="pd_wrap">
        <div className="ok_form_wrap">
          <div className="center_wrap">
            <img src={bigIcon} alt=""/>
            <div className="top_txt">
              <strong>결제상품 : {varOrderName}</strong>
              <strong>결제금액 : {addThousandPoint(varResult.amount)+" 원"}</strong>
              <p>결제내역이 맞으면 결제확인 버튼을 클릭해주세요.</p>
            </div>
            <div className="btn_bottom one_btn">
            {varPaymentIndex === 0 ? 
              <Link to={varPaymentMode<3?"/MyPage/PaymentList":"/MyPage/PremiumList"} className="btn_style_yes">결제 관리로 이동</Link>
            :
              <button className="btn_style_yes" onClick={e=>ApprovalCardPaymentHandler(e)}>결제 확인</button>
            }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SuccessToss;