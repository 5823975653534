import React, { useState,useEffect,useRef } from "react"; 

import DoongjiProduct from "./DoongjiProduct";
import { isNull } from "../../components/common/Common";
import reloadImage from '../../images/reload.svg';
import filterImage from '../../images/icon_filter_w.svg';
import ringArrowIcon from '../../images/icon-rightarrow.png';

const SearchedResult = ({varSortingList,varFilterOptions,setFilterOptions,varTotalCount,varDoongjiList,setDoongjiList,
    varFixedFilterList,getFilterOptions,onSelectFilterHandler,onPopupFilterHandler,FilterReloading,varMorePage,varPageNum,setPageNum}) => {
  //매물형태 : 3=전체
  //const [varShowMode,setShowMode] = useState(3);
  //최종 표시될 매물 목록 <<< 필터링,쏘팅 적용
  const [varShowList,setShowList] = useState([]);
  const [varShowCount,setShowCount] = useState(0);

  const scrollRef = useRef([]);

  //페이지 정보 표시 - 최초
  useEffect(() => {
    //전체매물로 선택
    if(varFilterOptions.rp_mode === 3) {
      document.getElementById('rp_mode1').checked = true;
      document.getElementById('rp_mode2').checked = true;
    } else if(varFilterOptions.rp_mode === 2) {
      document.getElementById('rp_mode1').checked = false;
      document.getElementById('rp_mode2').checked = true;
    } else if(varFilterOptions.rp_mode === 1) {
      document.getElementById('rp_mode1').checked = true;
      document.getElementById('rp_mode2').checked = false;
    }
    //전체매물 --> 표시매물
    setShowList(varDoongjiList);
    setShowCount(varTotalCount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varDoongjiList]);

  useEffect(() => {
    FilterReloading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varFilterOptions]);

  useEffect(() => {
    ResetScrollTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varShowList]);

  //선택되었던 매물로 이동
  const ResetScrollTop = () => {
    if(!isNull(localStorage.getItem('scrollIndex')) && localStorage.getItem('scrollIndex')!== -1){
      const inum  = localStorage.getItem('scrollIndex');
      const iTop  = localStorage.getItem('scrollTop');
      const saleBlock = scrollRef.current[inum];
      if(!isNull(saleBlock)) { 
        setTimeout(()=>window.scroll({top:iTop,left:0}),10);
        localStorage.setItem('scrollIndex',-1);
      }
    }
  }

  //등록유형 변경시- 1:일반매물/2:중개매물/3:전체
  const onChangeListHandler = (e) => {
    const bmode1 = document.getElementById('rp_mode1').checked;
    const bmode2 = document.getElementById('rp_mode2').checked;
    let iset = 0;
    if(bmode1) iset += 1;
    if(bmode2) iset += 2;
    
    if(iset === 0) {
      setShowList([]);
      setShowCount(0);
    } else {
      setFilterOptions({...varFilterOptions,rp_mode:iset});
    }
  }

  //필터 초기화
  const onReloadHandler = (e) => {
    if(!window.confirm('설정된 모든 필터를 초기화하시겠습니까?')) return false;
    getFilterOptions();
  }

  //쏘팅항목 선택시
  const onChangeSortHandler = (e) => {
    const { value } = e.currentTarget;
    setFilterOptions({...varFilterOptions,filters:value});
  }

  const onMoveFilterOption = (e) => {
    var filterareaBox = document.getElementById('filter_lists');
    var scrollX = filterareaBox.scrollLeft;
    var newScroollX = scrollX + 80;
    if(newScroollX > 473) newScroollX = 473;
    filterareaBox.scrollLeft = newScroollX;
  }

  return (
      <section>
        <div className="main_dunggi_wrap">
          <div className="filter_type01_wrap">
            <div className="center_wrap">
              <div className="tit02">둥지 <span className="red_txt"><span className="gray_bar">&nbsp;</span>{varShowCount} 건</span>
                {/* 표시목록 필터링 전체 옵션 팝업창 호출 */}
                <div className="filter02_open_btn go_right" onClick={e=>onPopupFilterHandler(e,true)}>
                  <span>상세필터</span>&nbsp;
                  <img src={filterImage} alt="필터오픈버튼"/>
                </div>
              </div>
              {/* 매물등록유형 */}
              <ul className="filter_opt01">
                <li className="terms_chks">
                  <input type="checkbox" name="rp_mode" className="chk1" id="rp_mode2" onChange={(e)=>onChangeListHandler(e)}/>
                  <i></i><label htmlFor="rp_mode2">중개매물</label>
                </li>
                <li className="terms_chks">
                  <input type="checkbox" name="rp_mode" className="chk1" id="rp_mode1" onChange={e=>onChangeListHandler(e)}/>
                  <i></i><label htmlFor="rp_mode1">일반매물</label>
                </li>
                {/* 목록 정렬을 위한 항목 */}
                <li className="sort_box">
                  <select name="sorting" id="sorting" value={varFilterOptions.filters} onChange={e=>onChangeSortHandler(e)}>
                  {varSortingList.map((sorts,i)=>(
                    <option key={'sorts_'+i} value={sorts.vs_idx}>{sorts.vs_name}</option>
                  ))}
                  </select>
                </li>
              </ul>
            </div>
          </div>
          {/* 표시목록 필터링을 위한 팝업창을 호출하기 위한 각각의 항목 버튼 */}
          <div className="filter_ctg_wrap mostly-customized-scrollbar">
            <img src={reloadImage} alt="" className="filter-left-init" onClick={e=>onReloadHandler(e)}/>
            <ul className="filter_ctg" id="filter_lists">
            {varFixedFilterList.map((filter,i)=>(
              <li key={"filter_btn_"+i} id={'filter_btn_'+filter.vsm_idx} 
                className={filter.vsm_show===0?"hidden":
                filter.vsm_select===0?"":"active"}>
                <button className="mini_ft_open01" onClick={e=>onSelectFilterHandler(e,filter.vsm_idx)}>{filter.vsm_name}</button>
              </li>
            ))}
            </ul>
            <div className="grad_arrow"><img src={ringArrowIcon} className="filter-right-move" alt="" onClick={e=>onMoveFilterOption(e)}/></div>
          </div>
        </div>
        {/* 둥지매물목록 */}
        <div className="main_dunggi list_type01" id="product_list">
          {varShowList.map((product,i)=>(
            <div className="list_con" key={'product_'+i} ref={e=>(scrollRef.current[i]=e)}>
              <DoongjiProduct inum={i} product={product} varDoongjiList={varDoongjiList} setDoongjiList={setDoongjiList}/>
            </div>
          ))}
          {varMorePage?<div className="list_con more"  onClick={()=>setPageNum(varPageNum=>varPageNum+1)}>더보기</div>:null}
        </div>
      </section>    
  );
}
export default SearchedResult;