/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import prevPageImage from '../../images/sub/btn_pre.png';
import nextPageImage from '../../images/sub/btn_next.png';

import '../admin_css/paging.css';

const Paging = ({ totalCount, pageSize, curPage, setPage }) => {
  const [varPageList,setPageList] = useState([]);
  const [varStartPage,setStartPage] = useState(1);
  const displayPages = 6; //보여지는 페이지 버튼 수

  //전체 페이지 수
  let totoalPage = 0;
  if (totalCount > 0) {
    totoalPage = Math.ceil(totalCount / pageSize); //총 페이지 수 (데이타 수/페이지표시아이템수)
  }

  //보여줄 페이지 배열 만들기
  const changePageList = () => {
    //배열 만들기
    let tmpPage = [];
    for(var i=varStartPage,j=0; j < displayPages; i++,j++) {
      if(i <= totoalPage) {
        tmpPage[j] = i;
      }
    }
    //console.log(tmpPage);
    setPageList([...tmpPage]);
  }

  //변수 변경 시 시행
  useEffect(() => {
    changePageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, pageSize, varStartPage]);

  const onChangePrevHandler = (e) => {
    e.preventDefault();
    const newPage = curPage - 1;
    if (newPage > 0) {
      setPage(newPage);
      if(newPage < varStartPage) {
        setStartPage(newPage);
      }
    }
  };
  const onChangeNextHandler = (e) => {
    e.preventDefault();
    const newPage = curPage + 1;
    if (newPage <= totoalPage) {
      setPage(newPage);
      if(newPage >= (varStartPage+displayPages)) {
        setStartPage(newPage-(displayPages-1));
      }
    }
  };

  return totalCount === 0 ? null : (
      <div className="paging">
          <a className="btn_prev" style={{cursor:"pointer"}} onClick={(e) => onChangePrevHandler(e)} disabled={curPage === 1}>
              <img src={prevPageImage} alt="이전" />
          </a>
          {varPageList.map((ipage, i) => (
            <a key={'page_'+ ipage} style={{cursor:"pointer"}} onClick={() => setPage(ipage)} className={curPage === ipage ? "selected" : null}>
              {ipage}
            </a>
          ))}
          <a className="btn_next" style={{cursor:"pointer"}} onClick={(e) => onChangeNextHandler(e)} disabled={curPage === totoalPage}>
            <img src={nextPageImage} alt="다음" />
          </a>
      </div>
  );
};

export default Paging;
