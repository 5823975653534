import React, { useState,useEffect } from "react"; 
import { Link,useParams } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { addThousandPoint,ChangeDateStringOnlyDate,isObjEmpty } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

import BigIcon from '../../images/big_icon03.png';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const PaymentComplete = () => {
  const { ph_idx } = useParams();
  const [varPaymentData,setPaymentData] = useState({});

  //프리미엄광고 정보 호출하기
  const getPaymentInformation = async () => {
    const sendData = {ph_idx:ph_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_payment_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1 && datas.results?.length > 0) {
        setPaymentData(datas.results[0]);
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    document.getElementById('main_body').style.backgroundColor = '#FFFFFF';
    getPaymentInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isObjEmpty(varPaymentData)?null:(
    <>
      <Header/>
      <HeadTitle titleString={"결제 완료"}/>
      <div id="content">
        <div className="pd_wrap02">
          <div className="ok_form_wrap ad_pay_ok_wrap">
            <div className="top_wrap">
              <div className="center_wrap">
                <img src={BigIcon} alt=""/>
                <div className="top_txt">
                  <div className="find_txt">
                    <strong>결제가 완료되었습니다.</strong>
                    {varPaymentData.ph_mode>2 ? "관리자 확인 후 프리미엄 광고에 등록됩니다."
                    :"구매하신 구독권 이용기간 동안 둥지장터의 유료서비스를 사용하실 수 있습니다."}
                  </div>
                </div>
              </div>
            </div>
            <div className="pay_ok_list table_st02">   
              <div className="center_wrap">
                <div className="tit07_1">결제 정보</div>
                <table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th>결제 항목</th>
                      <td>
                        <span>
                          {varPaymentData.ph_mode>2?"프리미엄 광고"
                          :varPaymentData.ph_mode===1?"둥지 "+varPaymentData.slots+"_"+varPaymentData.terms
                          :varPaymentData.ph_mode===2?"장터 "+varPaymentData.slots+"_"+varPaymentData.terms
                          :null}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>결제 금액</th>
                      <td><span>{addThousandPoint(varPaymentData.ph_amount)+" 원"}</span></td>
                    </tr>
                    <tr>
                      <th>결제 수단</th>
                      <td><span>{varPaymentData.ph_method===1?"신용카드":"무통장입금"}</span></td>
                    </tr>
                    <tr>
                      <th>결제일</th>
                      <td><span>{ChangeDateStringOnlyDate(varPaymentData.ph_pdate)}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="btn_bottom">
              <Link className="btn_style_no" to={"/MyPage/PremiumList"}>결제 관리로 이동</Link>
              <Link className="btn_style_yes" to={"/"}>홈으로 이동</Link>
            </div>  
          </div>
        </div>
      </div>
    </>
  );
}
export default PaymentComplete;