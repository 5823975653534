import React from "react";
import { Link,useParams } from 'react-router-dom'; 

import bigIcon from "../../images/big_icon03.png";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

//파트너 검색
const JangterRegisterComplete = () => {
  const { jt_serious2 } = useParams();
  return (
    <>
      <Header/>
      <HeadTitle titleString={"모두의 장터 등록"}/>
      <div id="content" style={{paddingBottom:"40px"}}>
        <div className="pd_wrap">
          <div className="ok_form_wrap join_ok_wrap">
            <div className="center_wrap">
              <img src={bigIcon} alt=""/>
              <div className="top_txt">
                <strong>장터 새 글 등록 완료!</strong>
                <p>장터 새 글이 성공적으로 등록되었습니다.<br/>상세 화면을 확인하시겠습니까?</p>
              </div>
              <div className="btn_bottom">
                <Link className="btn_style_no close_layer" to={'/Jangter'}>장터 메인으로</Link>
                <Link className="btn_style_yes" to={`/Jangter/JangterList/${jt_serious2}`}>등록 장터 보기</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default JangterRegisterComplete;