/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import { Link } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import DaumPostCode from "../../components/popup/DaumPostCode";

import { ChangeMobileNumber,ChangeMobileFormat,isObjEmpty,onlyNumber,OnlyNumerics,isNull } from "../../components/common/Common";

const DoongjiPartner = () => {
  const mem_idx = localStorage.getItem("mem_idx");
  const [varProductData,setProductData] = useState({});

  // 중복제출 방지
  const [isSubmitting, setIsSubmitting] = useState(false);

  //매물정보 저장 객체
  const newData = {rw_idx:0,rw_mem_idx:mem_idx,rw_zipcode:"",rw_address1:"",rw_address2:"",rw_regcode:"",rw_dongname:"",rw_roadname:"",
                  rw_location:"",rw_comment:"",rw_phone:"",rw_license:0,rw_career:0,rw_career_year:0,rw_name:"",rw_gender:0,rw_age:0};

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setProductData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  //주소찾기----------------------------------------------------------------------
  const [varOpenZipcode,setOpenZipcode] = useState(false);
  //주소찾기팝업창 열기/닫기
  const onOpenZipcodeHandler = (e,bmode) => {
    e.preventDefault();
    setOpenZipcode(bmode);
  }
  //주소관련 정보 업데이트
  const ChangeAddress = (zipcode,address1,regcode,dongName,roadName,locString) => {
    console.log(zipcode,address1,regcode,dongName,roadName,locString);
    setProductData((prevState)=>{return{...prevState,rw_zipcode:zipcode,rw_address1:address1,rw_regcode:regcode,rw_dongname:dongName,rw_roadname:roadName,rw_location:locString}});
  };

  //입력값 저장
  const onChangeInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    if(name === "rw_phone") {
      const svalue = onlyNumber(value);
      setProductData((prevState)=>{return{...prevState,[name]:ChangeMobileNumber(svalue)}});
    } else {
      if(name === "rw_career") {
        const careerBox = document.getElementById('rw_career_year');
        console.log(careerBox);
        if(Number(value) === 1) {
          if(careerBox.classList.contains('disabled')) careerBox.classList.remove('disabled');
          setProductData((prevState)=>{return{...prevState,rw_career:1}});
        } else {
          if(!careerBox.classList.contains('disabled')) careerBox.classList.add('disabled');
          setProductData((prevState)=>{return{...prevState,rw_career:0,rw_career_year:0}});
        }
      } else if(name === "rw_career_year" || name === "rw_gender" || name === "rw_age") {
        const svalue = OnlyNumerics(value);
        setProductData((prevState)=>{return{...prevState,[name]:Number(svalue)}});
      } else {
        setProductData((prevState)=>{return{...prevState,[name]:value}});
      }
    }
  }

  //저장하기
  const onRegisterPartnerHandler = async(e) => {
    if(isNull(varProductData.rw_name)) {
      alert('신청자명을 먼저 입력하십시오'); return false;
    } else if(varProductData.rw_career === 1 && (varProductData.rw_career_year === 0 || varProductData.rw_career_year === "")) {
      alert('부동산 경력의 년수를 먼저 입력하십시오'); return false;
    } else if(isNull(varProductData.rw_age) || varProductData.rw_age === "0") {
      alert('나이를 먼저 입력하십시오'); return false;
    }
    if(!window.confirm('공인중개사 모집에 가입하시겠습니까?')) return false;
    if (isSubmitting) return; // 이미 제출 중이면 중복 제출 방지
    setIsSubmitting(true); // 제출 중으로 변경
    const sendData = {infos:varProductData};
    console.log('sendData',sendData);
    //매물정보 DB 등록
    await axios.post(`${CONFIG.SERVER_HOST}/_update_doongji_partner`, sendData).then((res) => {
      console.log(res.data);
      if(res.data.retvalue === 1) {
        window.location.href = "/Doongji/DoongjiSeriousOK";
      } else {
        alert(res.data.message);
        setIsSubmitting(false); // 제출방지 초기화
      }
    });
  }

  return isObjEmpty(varProductData)?null : (
    <>
      <Header/>
      <HeadTitle titleString={"공인중개사 파트너 모집"} backString={'/Doongji/DoongjiList'}/>
      <div id="content">
        <div className="pd_wrap03">
          <div className="sell_wrap write_form_wrap">
            <div className="center_wrap">
              <div className="tit07_1">신청 내용</div>
              <div className="form_wrap22" style={{paddingBottom:"0px"}}>
                <div className="tit07_2">신청자명<span className="star_red"> (필수)</span></div>
                <input type="text" name="rw_name" style={{width:"70%"}} value={varProductData.rw_name||""} placeholder="신청자명을 입력해주세요." onChange={e=>onChangeInputHandler(e)}/>
              </div>
              <div className="form_wrap">
                <div className="tit07_2">거주지(사무실) 주소</div>
                <div className="has_btn form_Address" onClick={e=>onOpenZipcodeHandler(e,true)}>
                  <input type="text" name="rw_zipcode" placeholder="우편번호 입력" value={varProductData.rw_zipcode||""} className="w_s_input back-disbale" readOnly/>
                  <button className="w_s_btn" onClick={(e)=>onOpenZipcodeHandler(e,true)}>우편번호</button>
                </div>
                <div onClick={e=>onOpenZipcodeHandler(e,true)}>
                  <input type="text" name="rw_address1" value={varProductData.rw_address1||""} className="back-disbale" readOnly/>
                </div>                
                <input type="text" name="rw_address2" value={varProductData.rw_address2} placeholder="상세주소를 입력하세요." onChange={e=>onChangeInputHandler(e)}/>
              </div>
              {/* 공인중개사 자격증 유무 */}
              <div className="form_wrap">
                <div className="tit05">공인중개사 자격증<span className="star_red"> (필수)</span></div>
                <div className="fomr_small">
                  <div className="has_btn">
                    <div className="dan_short2">
                      <li className="terms_chk">
                        <input type="radio" name="rw_license" className="chk1" id="rw_license1" value="1" onChange={e=>onChangeInputHandler(e)}/>
                        <i></i>&nbsp;&nbsp;<label htmlFor="rw_license1">있음</label>&nbsp;&nbsp;
                      </li>
                      <li className="terms_chk">
                        <input type="radio" name="rw_license" className="chk1" id="rw_license0" value="0" defaultChecked={true} onChange={e=>onChangeInputHandler(e)}/>
                        <i></i>&nbsp;&nbsp;<label htmlFor="rw_license0">없음</label>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              {/* 부동산 경력 유무 */}
              <div className="form_wrap">
                <div className="tit05">부동산 경력<span className="star_red"> (필수)</span></div>
                <div className="fomr_small">
                  <div className="has_btn">
                    <div className="dan_short2">
                      <li className="terms_chk" style={{marginTop:"10px"}}>
                        <input type="radio" name="rw_career" className="chk1" id="rw_career0" value="0" defaultChecked={true} onChange={e=>onChangeInputHandler(e)}/>
                        <i></i>&nbsp;&nbsp;<label htmlFor="rw_career0">없음</label>
                      </li>
                      <li className="terms_chk" style={{marginTop:"10px"}}>
                        <input type="radio" name="rw_career" className="chk1" id="rw_career1" value="1" onChange={e=>onChangeInputHandler(e)}/>
                        <i></i>&nbsp;&nbsp;<label htmlFor="rw_career1">있음</label>
                      </li>&nbsp;&nbsp;
                      <li className="right_wrap02" style={{lineHeight:"28px"}}>
                        <input type="text" name="rw_career_year" id="rw_career_year" className="career_year disabled" placeholder="0" value={varProductData.rw_career_year} onChange={e=>onChangeInputHandler(e)}/>&nbsp;&nbsp;년
                      </li>
                    </div>
                  </div>
                </div>
                <div className="form_wrap22">
                  <div className="tit07_2">성별<span className="star_red"> (필수)</span></div>
                  <div style={{paddingTop:"15px"}}>
                    <input type="radio" name="rw_gender" id="gender0" value={0} defaultChecked={true} onChange={e=>onChangeInputHandler(e)}/>&nbsp;
                    <label htmlFor="gender0">남성</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" name="rw_gender" id="gender0" value={1} onChange={e=>onChangeInputHandler(e)}/>&nbsp;
                    <label htmlFor="gender1">여성</label>&nbsp;&nbsp;
                  </div>
                </div>
                <div className="form_wrap22">
                  <div className="tit07_2">나이<span className="star_red"> (필수)</span></div>
                  <input type="text" name="rw_age" style={{width:"80%"}} value={varProductData.rw_age||""} placeholder="나이를 입력해주세요." onChange={e=>onChangeInputHandler(e)}/>
                </div>
                <div className="form_wrap22"></div>
                <textarea name="rw_comment" cols="30" rows="5" style={{maxWidth:'100%',minWidth:'100%',width:'100%'}}
                      placeholder="의뢰 내용을 작성해주세요." value={varProductData.rw_comment} onChange={e=>onChangeInputHandler(e)}/>
              </div>
              {/* 연락처 */}
              <div className="form_wrap">
                <div className="tit05">연락처</div>
                <input type="text" name="rw_phone" value={ChangeMobileFormat(varProductData.rw_phone)} 
                    placeholder="의뢰인의 연락처를 입력해주세요." onChange={e=>onChangeInputHandler(e)}/>
                <div className="txt_gray">공인중개사로 등록에 관련된 대응이 가능한 관리인 및 담당자의 연락처를 적어주세요.</div>
              </div>
            </div>
            <div className="btm_box">
              <strong>공인중개사 파트너란?</strong>
              <p>둥지장터와 함께 일해보세요.<br/>미래와 꿈을 향해 둥지장터가 힘이 되겠습니다.</p>
            </div>
            <div className="btn_bottom">
              <Link to="/Doongji/DoongjiSerious" className="btn_style_no">취소</Link>
              <button className="btn_style_yes" onClick={e=>onRegisterPartnerHandler(e)}>등록</button>
            </div>
          </div>
        </div>
        {/* 우편번호/주소 검색*/}
        <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler}/>
      </div>
    </>
  );
}
export default DoongjiPartner;