/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams,useNavigate,Link } from "react-router-dom";
import CONFIG from "../../../configs/Configs";
import axios from "axios";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import arrImage from "../../images/arr.png";
import iconhome from "../../images/icon-home.png";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import "../../admin_css/board.css";
import { unescapeHtml,escapeHtml,isNull,isObjEmpty,ChangeDateTimeFormat,GetAuthority } from "../../../components/common/Common";

const AdminQuestionDetail = () => {
  const admin_idx = Number(localStorage.getItem('adminIdx'));
  const admin_name = localStorage.getItem("adminName");
  const admin_mode = Number(localStorage.getItem("adminMode")); //1:운영자
  const admin_authority = localStorage.getItem("adminAuthority");

  let { board_idx } = useParams();
  const navigator = useNavigate();

  //게시판 변수 저장 객체
  const newData = {      
    board_idx: 0,
    quest_idx: admin_idx,
    quest_name: admin_name,
    quest_admin: admin_mode,
    quest_authority: admin_authority,
    answer_idx: 0,
    answer_name:"",
    answer_authority:"",
    board_title: "",
    board_comment: "",
    board_answer: "",
    board_rdate: null,
    board_adate: null,
    board_status:1,
    board_hits: 0
  };

  //게시판정보
  const [varBoardInfo,setBoardInfo] = useState({});
  //글작성자 회원번호
  const [varWriterState,setWriterState] = useState(1);  //1:질문편집모드/2:답변편집모드/3:둘다편집/0:보기

  const [varBoardAction,setBoardAction] = useState("");

  //게시판정보 읽어오기
  const getBoardInformation = async () => {
    var sendData = {adMode:6,board_idx: board_idx,pageNum:1,pageSize:10};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_admin_board_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(datas.results?.length > 0) {
          let copyData = datas.results[0];

          //편집모드
          let saction = "질문/답변을 저장";
          if(admin_idx===copyData.quest_idx && admin_idx===copyData.answer_idx){
            setWriterState(3);
          } else if(admin_idx===copyData.quest_idx) {
            saction = "질문을 저장";
            setWriterState(1);
          } else if(admin_idx===copyData.answer_idx) {
            saction = "답변을 저장";
            setWriterState(2);
          } else {
            setWriterState(0);
          }
          setBoardAction(saction);

          //문의/답변자 권한 및 이름
          const questAuthority = GetAuthority(copyData.quest_admin,copyData.quest_regname);
          copyData.quest_authority = questAuthority;
          if(copyData.answer_idx === 0) {
            copyData.answer_idx = admin_idx;
            copyData.answer_name = admin_name;
            copyData.answer_authority = admin_authority;
            setWriterState(2);
          } else {
            copyData.answer_authority = GetAuthority(copyData.answer_admin,copyData.answer_regname);
          }
          setBoardInfo(copyData);
        } else {
          setBoardInfo(newData);
          if(Number(board_idx)===0) setWriterState(1);
        }
      } else {
        setBoardInfo(newData);
        if(Number(board_idx)===0) setWriterState(1);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setBoardInfo(newData);
    getBoardInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board_idx]);

  //회원정보편집
  const onBoardInfoHandler = (e) => {
    const {name,value} = e.currentTarget;
    setBoardInfo({...varBoardInfo, [name]:escapeHtml(value)});
  }

  //게시판정보 저장
  const onUpdateBoardHandler = async (e) => {
    if((varWriterState === 1 || varWriterState === 3) && (isNull(varBoardInfo.board_title) || isNull(varBoardInfo.board_comment))) {
      alert('1:1문의에 대한 질문을 위해 제목과 질문내용을 먼저 입력하세요.'); return false;
    } else if((varWriterState === 2 || varWriterState === 3) && isNull(varBoardInfo.board_answer)) {
        alert('1:1문의에 대한 답변을 먼저 입력하세요.'); return false;
    }
    if(!window.confirm(`1:1 문의의 ${varBoardAction}하시겠습니까?`)) return false;
    
    const sendData = {boardInfo: varBoardInfo};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_admin_question_information`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //목록페이지로 이동
        navigator(`/Admin/AdminQuestion`);
      } else {
        alert(datas.message);
      }
    });
  }

  //글삭제
  const onDeleteBoardIndormation = async(e) => {
    if(!window.confirm('이 글을 삭제합니까?')) return false;
    const sendData = {mode:9,board_idx: board_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_board_information`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        navigator(`/Admin/AdminQuestion`);
      } else {
        alert(datas.message);
      }
    });    
  }


  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'ADMINBOARDS'}/>
      <Menus varMenuType={'ADMINBOARDS'} varMenuItem={'AdminQuestion'}/>
      <div id="contents" className="ad_contents">
        <div className="tit">
          <div className="title">{varWriterState?Number(board_idx)===0?`1:1 문의등록`:`1:1 문의상세`:`1:1 문의 열람`}</div>
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arrImage} alt="" />관리자 게시판
            <img src={arrImage} alt="" />1:1 문의
            <img src={arrImage} alt="" />{varWriterState?Number(board_idx)===0?`1:1 문의등록`:`1:1 문의상세`:`1:1 문의 열람`}
          </div>
        </div>
        {isObjEmpty(varBoardInfo)?null:
        <div className="content">
          <div className="main-box">
            <table className="write">
              <colgroup>
                <col width="20%"></col>
                <col width="30%"></col>
                <col width="20%"></col>
                <col width="30%"></col>
              </colgroup>
              <thead></thead>
              <tbody>
                <tr>
                  <th>제목</th>
                  <td colSpan={3}>
                    {varWriterState!==1 && varWriterState!==3?unescapeHtml(varBoardInfo.board_title)
                    :<input type="text" name="board_title" value={unescapeHtml(varBoardInfo.board_title)} onChange={(e)=>onBoardInfoHandler(e)}/>
                    }
                  </td>
                </tr>
                <tr>
                  <th>문의자 권한</th>
                  <td>{varBoardInfo.quest_authority}</td>
                  <th>문의자</th>
                  <td>{varBoardInfo.quest_name}</td>
                </tr>
                <tr>
                  <th>질문</th>
                  <td colSpan={3}>
                  {varWriterState!==1 && varWriterState!==3?unescapeHtml(varBoardInfo.board_comment)
                  :<textarea name="board_comment" style={{maxWidth:"100%",maxHeight:"50px"}} value={unescapeHtml(varBoardInfo.board_comment)} onChange={(e)=>onBoardInfoHandler(e)}/>
                  }
                  </td>
                </tr>
                <tr>
                  <th>문의일시</th>
                  <td colSpan={3}>{ChangeDateTimeFormat(varBoardInfo.board_rdate)}</td>
                </tr>
                <tr>
                  <th>답변자 권한</th>
                  <td>{varBoardInfo.answer_authority}</td>
                  <th>답변자</th>
                  <td>{varBoardInfo.answer_name}</td>
                </tr>
                <tr>
                  <th>답변</th>
                  <td colSpan={3}>
                    {varWriterState!==2 && varWriterState!==3?unescapeHtml(varBoardInfo.board_answer)
                    :<textarea name="board_answer" style={{maxWidth:"100%"}} value={unescapeHtml(varBoardInfo.board_answer)} onChange={(e)=>onBoardInfoHandler(e)}/>
                    }
                  </td>
                </tr>
                <tr>
                  <th>답변일시</th>
                  <td colSpan={3}>{isNull(varBoardInfo.board_adate)?"":ChangeDateTimeFormat(varBoardInfo.board_adate)}</td>
                </tr>
              </tbody>
            </table>
            <div className="btn-center">
              {varWriterState>0?<button className="btn" style={{width:"100px"}} onClick={(e)=>onUpdateBoardHandler(e)}>저장</button>:null}
              {varBoardInfo.board_idx>0?<button onClick={(e)=>onDeleteBoardIndormation(e)} style={{width:"100px"}} className="btn line black">삭제</button>:null}
              <Link to={`/Admin/AdminQuestion`} style={{width:"100px"}} className="btn line">목록</Link>
            </div>
          </div>
        </div>
        }
      </div>
    </>
  );
}
export default AdminQuestionDetail;