/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

import { ChangeMobileNumber,ChangeMobileFormat,isObjEmpty,onlyNumber,ASC } from "../../components/common/Common";

const DoongjiFinding = () => {
  const navigate = useNavigate();
  const mem_idx = localStorage.getItem("mem_idx");
  //입력정보 저장
  const [varProductData,setProductData] = useState({});

  //매물종류목록
  const [varSeriousList,setSeriousList] = useState([]);

  //지역정보 관련 변수
  const [varRegionList,setRegionList] = useState([]);

  const [varSiDoList,setSiDoList] = useState([]);
  const [varSiGunGuList,setSiGunGuList] = useState([]);
  const [varDongRiList,setDongRiList] = useState([]);

  // 중복제출 방지
  const [isSubmitting, setIsSubmitting] = useState(false);

  //매물정보 저장 객체
  const newData = {rf_idx:0,rf_mem_idx:mem_idx,rf_serious:0,rf_title:"",rf_comment:"",rf_phone:"",rf_sido:"",
                  rf_sigungu:'',rf_dongri:"",rf_wantarea:""};

  //매물종류목록 읽어오기
  const getProductInformation = async () => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_doongji_finding`).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //매물종류
        setSeriousList(datas.seriouslist);
        //검색된 전체 지역 목록
        setRegionList(datas.regions);
        //시/도 목록
        const SiDoList = datas.regions.filter(element=>element.regcode.substr(2,8)==='00000000');
        setSiDoList(SiDoList);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setProductData(newData);
    getProductInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  //주소찾기----------------------------------------------------------------------
  //시도,시군구 산택
  const onSelectedRegionHandler = (e,ino) => {
    const { value } = e.currentTarget;
    //const sname = e.currentTarget.options[e.currentTarget.selectedIndex].text;

    if(ino === 1) { //시/도 선택시
      if(value === "") {  
        setSiGunGuList([]);
      } else {
        //시군구청 목록 가져오기
        getSiGunGuRegionList(value);
      }
      setDongRiList([]);
      setProductData((prevState)=>{return{...prevState,rf_sido:value,rf_sigungu:'',rf_dongri:''}});
    } else if(ino === 2) {  //시/군/구 선택시
      if(value === "") {  
        setDongRiList([]);
      } else {
        //동리목록 가져오기
        getDongRiRegionList(value);
      }
      setProductData((prevState)=>{return{...prevState,rf_sigungu:value,rf_dongri:''}});
    } else {
      setProductData((prevState)=>{return{...prevState,rf_dongri:value}});
    }
  }
  //시/군/구 목록 가져오기
  const getSiGunGuRegionList = (regcode) => {
    console.log(regcode);
    const prefixCode = regcode.substr(0,2);
    //시/군/구 목록
    const sGunGuList = varRegionList.filter(el=>el.regcode.substr(0,2)===prefixCode && el.regcode.substr(5,5)==='00000' && el.regcode.substr(2,8)!=='00000000');
    //쏘팅
    sGunGuList.sort(ASC('regname',true));
    setSiGunGuList(sGunGuList);
  }
  //동/리 목록 가져오기
  const getDongRiRegionList = (regcode) => {
    const prefixCode = regcode.substr(0,5);
    //동/리 목록
    const sDongRiList = varRegionList.filter(el=>el.regcode.substr(0,5)===prefixCode && el.regcode.substr(5,5)!=='00000');
    //쏘팅
    sDongRiList.sort(ASC('regname',true));
    setDongRiList(sDongRiList);
  }

  //입력값 저장
  const onChangeInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    if(name === "rf_phone") {
      const svalue = onlyNumber(value);
      setProductData((prevState)=>{return{...prevState,[name]:ChangeMobileNumber(svalue)}});
    } else {
      setProductData((prevState)=>{return{...prevState,[name]:value}});
    }
  }

  //저장하기
  const onRegisterFindingHandler = async(e) => {
    if(varProductData.rf_title === "") {
      alert('신청 제목을 먼저 입력하십시오.'); return false;
    } else if(varProductData.rf_serious === 0) {
      alert('매물 종류를 먼저 선택하십시오'); return false;
    } else if(varProductData.rf_sido === '') {
      alert('찾는 동네를 먼저 선택하십시오'); return false;
    }  else if(varProductData.rf_zipcode === "") {
      alert('주소를 먼저 선택하십시오'); return false;
    } else if(varProductData.rf_comment === "") {
      alert('의뢰내용을 먼저 선택하십시오'); return false;
    } else if(varProductData.rf_phone === "") {
      alert('연럭처를 먼저 입력하십시오'); return false;
    }
    if(!window.confirm('현재 매물을 전문가에게 의뢰하시겠습니까?')) return false;
    if (isSubmitting) return; // 이미 제출 중이면 중복 제출 방지
    setIsSubmitting(true); // 제출 중으로 변경
    const sendData = {infos:varProductData};
    console.log('sendData',sendData);
    //매물정보 DB 등록
    await axios.post(`${CONFIG.SERVER_HOST}/_update_doongji_finding`, sendData).then((res) => {
      console.log(res.data);
      if(res.data.retvalue === 1) {
        navigate("/Doongji/DoongjiSeriousOK");
      } else {
        alert(res.data.message);
        setIsSubmitting(false); // 제출방지 초기화
      }
    });
  }

  return isObjEmpty(varProductData)?null : (
    <>
      <Header/>
      <HeadTitle titleString={"내 집을 찾아주세요"} backString={'/Doongji/DoongjiList'}/>
      <div id="content">
        <div className="pd_wrap03">
          <div className="sell_wrap write_form_wrap">
            <div className="center_wrap">
              {/* 신청 제목 */}
              <div className="form_wrap">
                <div className="tit07_1">신청 제목<span className="star_red"> (필수)</span></div>
                <input type="text" name="rf_title" value={varProductData.rf_title||""} placeholder="신청하려는 매물의 제목을 작성해주세요." onChange={e=>onChangeInputHandler(e)}/>
              </div>
              {/* 매물종류 */}
              <div className="form_wrap">
                <div className="tit05">매물 종류<span className="star_red"> (필수)</span></div>
                <select name="rf_serious" value={varProductData.rf_serious} onChange={e=>onChangeInputHandler(e)}>
                  <option value={0}>매물종류선택</option>
                  {varSeriousList.map((serious,i)=>(
                    <option key={'serious_'+i} value={serious.vfo_idx}>{serious.vfo_name}</option>
                  ))}
                </select>
              </div>
              <div className="tit07_1">신청 내용</div>
              {/* 매물 주소 */}
              <div className="form_wrap">
                <div className="tit07_2">찾는 동네<span className="star_red"> (필수)</span></div>
                <select value={varProductData.rf_sido||""} onChange={e=>onSelectedRegionHandler(e,1)}>
                  <option value="">시/도 선택</option>
                  {varSiDoList.map((sido,i)=>(
                    <option key={'sido_'+i} value={sido.regcode}>{sido.regname}</option>
                  ))}
                </select>
                <select value={varProductData.rf_sigungu||""} onChange={e=>onSelectedRegionHandler(e,2)}>
                  <option value="">시/군/구 선택</option>
                  {varSiGunGuList.map((sigungu,i)=>(
                    <option key={'sigungu_'+i} value={sigungu.regcode}>{sigungu.regname}</option>
                  ))}
                </select>
                <select value={varProductData.rf_dongri||""} onChange={e=>onSelectedRegionHandler(e,3)}>
                  <option value="">동/리 선택</option>
                  {varDongRiList.map((dongri,i)=>(
                    <option key={'dongri_'+i} value={dongri.regcode}>{dongri.regname}</option>
                  ))}
                </select>
                <div>
                  <input type="text" name="rf_wantarea" value={varProductData.rf_wantarea} placeholder="원하는 지역명을 작성해주세요 예)논현동,대치동" onChange={e=>onChangeInputHandler(e)}/>
                </div>
              </div>
              {/* 의뢰내용 */}
              <div className="form_wrap">
                <div className="tit05">의뢰 내용<span className="star_red"> (필수)</span></div>
                <textarea name="rf_comment" cols="30" rows="5" style={{maxWidth:'100%',minWidth:'100%',width:'100%'}}
                      placeholder="의뢰 내용을 작성해주세요." value={varProductData.rf_comment} onChange={e=>onChangeInputHandler(e)}/>
              </div>
              {/* 연락처 */}
              <div className="form_wrap">
                <div className="tit05">연락처<span className="star_red"> (필수)</span></div>
                <input type="text" name="rf_phone" value={ChangeMobileFormat(varProductData.rf_phone)} 
                  placeholder="의뢰인의 연락처를 입력해주세요." onChange={e=>onChangeInputHandler(e)}/>
                <div className="txt_gray">의뢰와 관련된 대응이 가능한 관리인 및 담당자의 연락처를 적어주세요.</div>
              </div>
            </div>
            <div className="btm_box">
                <strong>매물 찾아주세요 서비스는<br/>무엇인가요?</strong>
                <p>내가 원하는 집 찾기 힘드시죠? <br/>둥지의 파트너 공인중개사가 찾고자 하시는 물건을 신속하게 찾아드립니다.</p>
            </div>
            <div className="btn_bottom">
              <Link to="/Doongji/DoongjiSerious" className="btn_style_no">취소</Link>
              <button className="btn_style_yes" onClick={e=>onRegisterFindingHandler(e)}>등록</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DoongjiFinding;