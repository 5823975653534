/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ useState,useEffect } from "react"; 
import { isObjEmpty } from '../common/Common';
import closeButton from "../../images/icon_close.png";

const FilterSubway = ({ varFilterMode,varPopupFilterSubway,varSubwayLineList,varSubwayStationList,varFixedFilterList,setFixedFilterList,onPopupFilterSubwayHandler}) => {
  //필터옵션변수
  const [varFilterData,setFilterData] = useState({});
  const [varSelectedStationList,setSelectedStationList] = useState([]);

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(varFilterMode > 0) {
      const currentData = varFixedFilterList.filter(element=>element.vsm_idx===varFilterMode);
      setFilterData(currentData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varFilterMode]);

  //지하철노선
  const onSelectSubwayLineHandler = (e) => {
    const { value } = e.currentTarget;

    //지하철역변경
    if(value === "") {
      setFilterData({...varFilterData, subway_line:value,subway_idx:0});
      setSelectedStationList([]);
    } else {
      setFilterData({...varFilterData, subway_line:value});
      const selectList = varSubwayStationList.filter(element=>element.subway_line===value);
      setSelectedStationList(selectList);
    }
  }
  //지하철역
  const onSelectSubwayStationHandler = (e) => {
    const { value } = e.currentTarget;
    //필터배열정리
    setFilterData({...varFilterData, subway_idx:value});
  }

  //검색시작
  const onSearchingFilterHandler = (e) =>{
    let copyData = [...varFixedFilterList];
    const findIndex = copyData.findIndex(element=>element.vsm_idx===varFilterMode);
    copyData[findIndex] = {...varFilterData};
    //검색실행
    setFixedFilterList(copyData);
    //팝업창 닫기
    onPopupFilterSubwayHandler(e,-1);
  }

  return isObjEmpty(varFilterData)?null:(
    <div className="layer-pop mini_ft01 h_auto" style={varPopupFilterSubway?{display:"block"}:{display:"none"}}>
      <div className="pop_head">
        <div className="layer_tit">{varFilterData.vsm_name}</div>
        <div className="close_layer top_close_btn"><img src={closeButton} alt="" onClick={(e)=>onPopupFilterSubwayHandler(e,false)}/></div>
      </div>
      <div className="layer_inner">
            <div className="dan2" style={{padding:"10px"}}>
              <select name="subway_line" value={varFilterData.subway_line} onChange={e=>onSelectSubwayLineHandler(e)}>
                <option value="">노선선택</option>
                {varSubwayLineList.map((subway1,i)=>(
                  <option key={'subwayline_'+i} value={subway1.subway_line}>{subway1.subway_line}</option>
                ))}
              </select>
              <select name="subway_idx" value={varFilterData.subway_idx} onChange={e=>onSelectSubwayStationHandler(e)}>
                <option value={0}>역선택</option>
                {varSelectedStationList.map((subway2,i)=>(
                  <option key={'subwaystation_'+i} value={subway2.subway_idx}>{subway2.subway_name}</option>
                ))}
              </select>
            </div>
      </div>
      <div className="btn_bottom one_btn">
        <button className="btn_style_yes" onClick={e=>onSearchingFilterHandler(e)}>적용</button>
      </div>
    </div>
  );
}

export default FilterSubway