/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import AdminTerms from "../../components/environment/AdminTerms";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import '../../admin_css/variables.css';

const Variables = () => {
  //메뉴항목지정
  const [varMenuItem,setMenuItem] = useState('');

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'ENVIRONMENT'}/>
      <Menus varMenuType={'ENVIRONMENT'} varMenuItem={varMenuItem}/>
      <Routes>
        {/* 약관관리 */}
        <Route path="AdminTerms/:termCode" element={<AdminTerms setMenuItem={setMenuItem} />} />
      </Routes>
    </>
  );
}
export default Variables;