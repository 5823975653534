import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

function AdminState({setMenuItem}) {
  const adminLoginState = Number(localStorage.getItem("adminLoginState"));
  const admin_idx = localStorage.getItem("adminIdx");

  //관리자 변수값 저장 변수
  const [varName, setName] = useState("");
  const [varEmail, setEmail] = useState("");
  const [varPassword, setPassword] = useState("");
  const [varConfirmPassword, setConfirmPassword] = useState("");

  //현재 관리자 정보 저장 변수
  const [varCurrentAdmin,setCurrentAdmin] = useState({name:"",email:""});

  const getAdminState = async () => {
    const params = new URLSearchParams();
    params.append("admin_idx", admin_idx);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_admin_state`,params).then((res) => {
      const datas = res.data.results;
      setName(datas.mem_name);
      setEmail(datas.mem_email);
      //현재 관리자 정보 저장
      setCurrentAdmin({name:datas.mem_name,email:datas.mem_email});
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('AdminState');
    getAdminState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //관리자 정보 재저장
  const onUpdateAdminStateHandler = async (e) => {
    e.preventDefault();
    if (varEmail === "") {
      alert('아이디(이메일)을 먼저 입력하십시오.'); return false;
    } else if(varName === "") {
      alert('운영자의 이름을 먼저 입력하십시오.'); return false;
    } else if(varPassword === "") {
      alert('운영자의 비밀번호를 먼저 입력하십시오.'); return false;
    } else if(varPassword !== ""){
      if (varPassword !== varConfirmPassword) {
        alert("두개의 비밀번호가 일치하지 않습니다. 다시 확인후 입력해주세요");
        return false;
      }
    }
    if (varEmail === varCurrentAdmin.email && varName === varCurrentAdmin.name && varPassword === "") {
      alert("변경내용이 없습니다. 저장할 필요가 없습니다.");
      return false;
    }
    const sendData = {admin_idx:admin_idx, admin_email:varEmail, admin_name:varName, admin_pwd:varPassword};
    await axios.post(`${CONFIG.SERVER_HOST}/_update_admin_state`, sendData).then((res) => {
      alert(res.data.message);
    });
  }

  const onEmailHandler = (e) => {
    setEmail(e.currentTarget.value);
  };
  const onPasswordHanlder = (e) => {
    setPassword(e.currentTarget.value);
  };
  const onConfirmPasswordHanlder = (e) => {
    setConfirmPassword(e.currentTarget.value);
  };
  const onNameHanlder = (e) => {
    setName(e.currentTarget.value);
  };

  return (
      <div id="contents" className="ad_contents">
        <div className="tit">
          <div className="title">운영자 관리</div>
          <div className="path">
            <img src={iconhome} alt=""/>
            HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt="" />운영 계정 관리<img src={arrImage} alt=""/>운영자 관리
          </div>
        </div>
      {adminLoginState===0?null:
        <div className="content">
          <div className="main-box">
            <table className="write">
              <thead></thead>
              <tbody>
                <tr>
                  <th>아이디(이메일)</th>
                  <td><input type="text" className="email-box" value={varEmail||''} onChange={(e)=>onEmailHandler(e)} maxLength="100" /></td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td><input type="text" className="name-box" value={varName||''} onChange={(e)=>onNameHanlder(e)} maxLength="30" /></td>
                </tr>
                <tr>
                  <th>비밀번호</th>
                  <td><input type="password" className="password-box" value={varPassword||''} onChange={(e)=>onPasswordHanlder(e)} maxLength="20" /></td>
                </tr>
                <tr>
                  <th>비밀번호 재설정</th>
                  <td><input type="password" className="password-box" value={varConfirmPassword||''} onChange={(e)=>onConfirmPasswordHanlder(e)} maxLength="20" /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btn-center">
            <button className="btn" style={{width:"90px"}} onClick={(e)=>onUpdateAdminStateHandler(e)}>수정</button>
          </div>
        </div>
      }
      </div>
  )
}

export default AdminState;