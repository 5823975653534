/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import CONFIG from '../../../configs/Configs';

//import VariableOneBlock from '..//variables/VariableOneBlock';
import VariableTwoBlock from '..//variables/VariableTwoBlock';
import VariableThreeBlock from '../variables/VariableThreeBlock';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

const JangterVariables = ({setMenuItem}) => {

  useEffect(()=>{
    setMenuItem('JangterVariables');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div id="contents" className="ad_contents">
        <div className="tit">
          <div className="title">카테고리 관리</div>
          <div className="path">
              <img src={iconhome} alt=""/>HOME<img src={arrImage} alt=""/>카테고리관리<img src={arrImage} alt=""/>장터 카테고리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="select-list">
              <thead></thead>
              <tbody>
                {/* 사업자파트너구분 */}
                <VariableTwoBlock varMode={6} varItems={CONFIG.VariableItems} />
                {/* 모두의 장터구분 */}
                <VariableThreeBlock varMode={7} varItems={CONFIG.VariableItems} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
}
export default JangterVariables;