/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect,useRef } from "react"; 
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import { ChangeDateStringToKorean,GetPhotoImage,isNull } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import Footer from "../../components/include/Footer";

import exitChatIcon from "../../images/icon_next2_y.png";

//채팅룸 둥지 목록
const ChatJangterList = () => {
  const mem_idx = localStorage.getItem("mem_idx");
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const scrollRef = useRef([]);
  const navigate = useNavigate();

  //채팅룸 목록 저장 배열
  const [varChattingRoomList,setChattingRoomList] = useState([]);
  //않읽은 내용수
  const [varDoongjiYetCount,setDoongjiYetCount] = useState(0);
  const [varJangterYetCount,setJangterYetCount] = useState(0);

  // 이미지 로딩 상태 관리
  const [varPhotoImages, setPhotoImages] = useState([]);

  // 이미지 로딩 및 재시도 함수
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // 캐시 방지용 타임스탬프 추가
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            console.warn(`Retrying to load image: ${src} (${retries} retries left)`);
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  //채팅방 목록 읽어오기
  const GetChattingRoomList = async () => {
    const sendData = {mem_mode:mem_mode,mem_idx:mem_idx,ch_mode:2};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_chatroom_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(datas.results?.length > 0) {
          let roomList = datas.results;
          //차단된 경우 목록에서 제외
          let fixList = [];
          for(var i=0; i < roomList.length; i++) {
            let roomData = roomList[i];
            if(roomData.ch_mem_idx === mem_idx) {
              if(roomData.ch_mem_block) continue;
            } else {
              if(roomData.ch_own_block) continue;
            }
            fixList.push(roomData);
          }
          setChattingRoomList(fixList);

          // 이미지 로딩 처리
          const initialPhotoImages = fixList.map((room) => ({
            file_name: room.file_name,
            src: null,
            loading: true,
          }));
          setPhotoImages(initialPhotoImages);

          fixList.forEach((room, index) => {
            const sfile = room.file_name;
            const simage = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(sfile, 'jangter') + `?t=${new Date().getTime()}`;

            loadImageWithRetry(simage)
              .then((loadedImage) => {
                setPhotoImages((prevImages) => {
                  const newImages = [...prevImages];
                  newImages[index] = {
                    ...newImages[index],
                    src: loadedImage,
                    loading: false,
                  };
                  return newImages;
                });
              })
              .catch((error) => {
                console.error(error.message);
                setPhotoImages((prevImages) => {
                  const newImages = [...prevImages];
                  newImages[index] = {
                    ...newImages[index],
                    loading: false,
                  };
                  return newImages;
                });
              });
          });
        }
        //미확인 매시지 건수
        setDoongjiYetCount(datas.doongjiCount);
        setJangterYetCount(datas.jangterCount);
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    GetChattingRoomList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ResetScrollTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varChattingRoomList]);
  
  //선택되었던 매물로 이동
  const ResetScrollTop = () => {
    const scrollIndex = localStorage.getItem('scrollIndex');
    if(!isNull(scrollIndex) && scrollIndex !== -1){
      const inum  = localStorage.getItem('scrollIndex');
      const iTop  = localStorage.getItem('scrollTop');
      const saleBlock = scrollRef.current[inum];
      if(!isNull(saleBlock)) { 
        setTimeout(()=>window.scroll({top:iTop,left:0}),10);
        localStorage.setItem('scrollIndex',-1);
      }
    }
  }

  //채팅방에서 나가기 ---> 방폭파? 혹은 남은자에게는 표시?
  const onExitChatRoomHandler = async (e,ch_idx) => {
    e.preventDefault();
    if(!window.confirm('채팅방을 나가시겠습니까?')) return false;
    //서버에 적용
    const sendData = {ch_idx:ch_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_remove_chatroom_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        GetChattingRoomList();
      }
    });
  }

  const onGoingChattingRoomHandler = (e,inum,ch_mode,ch_idx) => {
    localStorage.setItem('scrollIndex',inum);
    localStorage.setItem('scrollTop',window.scrollY);
    navigate(`/Chat/ChatRoom/${ch_mode}/${ch_idx}`);
  }

  return(
    <>
      <Header/>
      <HeadTitle titleString={"채팅"} backString={"/Doongji/DoongjiList"}/>
      <div id="content">
        <div className="chat_list_wrap pd_wrap01">
          <div className="tab_menu">
            <ul>
              <li>
                <Link to="/Chat/ChatDoongjiList">
                  <div style={{position:"relative"}}>둥지{varDoongjiYetCount===0?null:<span>{varDoongjiYetCount}</span>}</div>
                </Link>
              </li>
              <li className="on">
                <Link to="/Chat/ChatJangterList">
                  <div style={{position:"relative"}}>장터{varJangterYetCount===0?null:<span>{varJangterYetCount}</span>}</div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="list_type02">
            {varChattingRoomList?.length === 0?
              <div className="chat_con">등록된 채팅방이 없습니다.</div>
            :varChattingRoomList.map((chatroom,i)=>(
              <div className="chat_con" key={'chatroom_'+i} id={'chatroom_'+chatroom.ch_idx} ref={e=>(scrollRef.current[i]=e)}>
                <div className="img_wrap">
                  <div style={{ position: "relative" }}>
                    {varPhotoImages[i]?.loading ? (
                      <div className="loading-indicator">
                        <div className="loader" style={{ width: '50px', height: '50px', position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)' }}></div>
                      </div>
                    ) : (
                      <img src={varPhotoImages[i]?.src} alt="" />
                      )}
                  </div>
                </div>
                <div className="box" onClick={e => onGoingChattingRoomHandler(e, i, chatroom.ch_mode, chatroom.ch_idx)}>
                  <div className="tit_txt">{chatroom.title}</div>
                  <div className={Number(chatroom.yetc)>0?"readyet active":"readyet"}>{Number(chatroom.yetc)===0?null:chatroom.yetc}</div>
                  <div className="c_name">{chatroom.regName}</div>
                  <div className="date_wrap">
                      <div className="date"><span>{ChangeDateStringToKorean(chatroom.ch_rdate)}</span></div>
                  </div>
                </div>
                <div className="chay_out_btn no_a_link" onClick={e=>onExitChatRoomHandler(e,chatroom.ch_idx)}>나가기&nbsp;
                  <img src={exitChatIcon} alt=""/>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer varPageMode={4}/>
    </>
  );
}
export default ChatJangterList;