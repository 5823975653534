import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import CompanyAccountList from './CompanyAccountList';
import CompanyAccountDetail from './CompanyAccountDetail';

function CompanyAccount({setMenuItem}) {
  //은행목록
  const [varBankLists, setBankLists] = useState([]);

  //은행계좌 목록 정보 저장 변수 
  const [varAccountLists, setAccountLists] = useState([]);
  //현재 선택된 은행계좌 고유번호 저장 변수
  const [varCurrentAccount,setCurrentAccount] = useState(-1);
  //은행계좌 정보 변경 페이지 표시 유무
  const [varShowDetailPage,setShowDetailPage] = useState(false);

  //등록된 은행계좌 수
  const [varTotalList, setTotalList] = useState(0);

  //은행계좌 읽어오기
  const getCompanyAccountsList = async () => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_company_account_list`).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setTotalList(datas.results.length);
        setAccountLists(datas.results);
        //은행목록
        setBankLists(datas.banks);
      }
    });
  }
  
  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('CompanyAccount');
    getCompanyAccountsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">입금 계좌 관리</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt=""/>운영 정보 관리<img src={arrImage} alt=""/>입금 계좌 관리
        </div>
      </div>
      <CompanyAccountDetail varShowDetailPage={varShowDetailPage} setShowDetailPage={setShowDetailPage}
            varBankLists={varBankLists} varCurrentAccount={varCurrentAccount} 
            varAccountLists={varAccountLists} getCompanyAccountsList={getCompanyAccountsList} />
      <CompanyAccountList setShowDetailPage={setShowDetailPage} varTotalList={varTotalList} 
            setCurrentAccount={setCurrentAccount} varAccountLists={varAccountLists}
            getCompanyAccountsList={getCompanyAccountsList} />
    </div>
  )
}

export default CompanyAccount;