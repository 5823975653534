import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs'; 

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

import { isObjEmpty, addThousandPoint, isNull, ChangeMobileFormat } from "../common/Common";

import heartOffIcon from "../../images/icon_heart_w.png";
import heartOnIcon from "../../images/icon_heart_y.png";
import noSlideImage from "../../images/no_img.png";
import callIcon from "../../images/icon_call_gray.png";

// 파트너스 항목 표시
const JangterProduct = ({ inum, product, varJangterList, setJangterList }) => {
  const mem_idx = localStorage.getItem("mem_idx");
  const memberLoginState = Number(localStorage.getItem("memberLoginState"));
  const jt_idx = product.jt_idx;
  const navigator = useNavigate();

  // 매물 정보
  const [varProductData, setProductData] = useState({});
  // 사진 배열
  const [varPhotoList, setPhotoList] = useState([]);

  // 이미지 로딩 상태 관리
  const [varPhotoImages, setPhotoImages] = useState([]);

  // loadImageWithRetry 함수 정의
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // 캐시 방지용 타임스탬프 추가
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            // console.warn(`Retrying to load image: ${src} (${retries} retries left)`);
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  // 장터 상품 사진 목록 가져오기
  const getProductPhotosList = async () => {
    const sendData = { jt_idx: jt_idx };
    await axios.post(`${CONFIG.SERVER_HOST}/_get_jangter_photo_list`, sendData).then((res) => {
      const datas = res.data;
      if (datas.retvalue === 1 && datas.results?.length > 0) {
        setPhotoList(datas.results);
      } else {
        setPhotoList([]);
      }
    });
  };

  // 페이지 정보 표시 - 최초
  useEffect(() => {
    setProductData(product);
    getProductPhotosList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  // 이미지 로딩 처리
  useEffect(() => {
    if (varPhotoList?.length > 0) {
      const initialPhotoImages = varPhotoList.map((photo) => ({
        file_name: photo.file_name,
        src: null,
        loading: true,
      }));
      setPhotoImages(initialPhotoImages);

      varPhotoList.forEach((photo, index) => {
        const sfile = photo.file_name;
        const simage = `${CONFIG.SERVER_HOST}/jangter/${sfile}?t=${new Date().getTime()}`;

        loadImageWithRetry(simage)
          .then((loadedImage) => {
            // console.log('Image loaded successfully:', loadedImage);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                src: loadedImage,
                loading: false,
              };
              return newImages;
            });
          })
          .catch((error) => {
            console.error(error.message);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                loading: false,
              };
              return newImages;
            });
          });
      });
    } else {
      setPhotoImages([]);
    }
  }, [varPhotoList]);

  // 찜 목록 등록/해제
  const onChangeJjimListHandler = async (e, jt_idx) => {
    if (memberLoginState === 0) {
      navigator('/Login');
      return false;
    }
    e.preventDefault();
    let jj_idx = varProductData.jj_idx;
    let jj_status = varProductData.jj_status;
    if (jj_idx === null) {
      jj_idx = 0;
      jj_status = 0;
    }
    if (jj_status === 1) jj_status = 0;
    else jj_status = 1;

    const sendData = { pageMode: 2, opp_idx: jt_idx, jj_idx: jj_idx, jj_mem_idx: mem_idx, jj_status: jj_status };
    await axios.post(`${CONFIG.SERVER_HOST}/_update_jjim_list`, sendData).then((res) => {
      const datas = res.data;
      let copyList = [...varJangterList];
      const findIndex = copyList.findIndex(element => element.jt_idx === jt_idx);
      copyList[findIndex].jj_idx = datas.jj_idx;
      copyList[findIndex].jj_status = jj_status;
      copyList[findIndex].mem_idx = mem_idx;
      // console.log(copyList[findIndex]);
      setJangterList(copyList);
      setProductData({ ...varProductData, jj_idx: jj_idx, jj_status: jj_status });
    });
  };

  const onGoingDetailHandler = (e, jt_idx) => {
    localStorage.setItem('scrollIndex', inum);
    localStorage.setItem('scrollTop', window.scrollY);
    navigator(`/Jangter/JangterView/${jt_idx}`);
  };

  return isObjEmpty(varProductData) ? null : (
    <>
      {/* 매물 사진 슬라이드 */}
      <div className="slider-container">
        {varPhotoImages?.length <= 1 ? (
          <div className="c_wrap" key={'photo_00'}>
            <div
              className="img_wrap2"
              style={{ textAlign: 'center', position: 'relative', margin: 'auto' }}
              onClick={e => onGoingDetailHandler(e, varProductData.jt_idx)}
            >
              {varPhotoImages?.length === 0 ? (
                <img src={noSlideImage} alt="" />
              ) : (
                <div>
                  {varPhotoImages[0]?.loading ? (
                    // 로딩 중일 때 로딩 인디케이터 표시
                    <div className="loading-indicator">
                      {/* 로딩 스피너 */}
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <>
                      <img
                        src={varPhotoImages[0]?.src}
                        alt=""
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          objectFit: 'cover',
                        }}
                      />
                      {/* 워터마크를 추가하려면 아래 주석을 해제하세요 */}
                      {/* <img
                        src={`${CONFIG.SERVER_HOST}/water_m.png`}
                        alt="Watermark"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: '85px',
                          height: 'auto',
                          objectFit: 'cover',
                          pointerEvents: 'none',
                        }}
                      /> */}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Swiper
            slidesPerView={1.5}
            spaceBetween={5}
            loop={true}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {varPhotoImages.map((photoObj, i) => (
              <SwiperSlide className="swiper-slide" key={'photo_' + i}>
                <div className="c_wrap">
                  <div className="img_wrap" onClick={e => onGoingDetailHandler(e, varProductData.jt_idx)}>
                    {photoObj.loading ? (
                      // 로딩 중일 때 로딩 인디케이터 표시
                      <div className="loading-indicator">
                        {/* 로딩 스피너 */}
                        <div className="loader"></div>
                      </div>
                    ) : (
                      <>
                        <img
                          src={photoObj.src}
                          alt=""
                          style={{ objectFit: 'cover' }}
                        />
                        {/* 워터마크를 추가하려면 아래 주석을 해제하세요 */}
                        {/* <img
                          src={`${CONFIG.SERVER_HOST}/water_m.png`}
                          alt="Watermark"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '55px',
                            height: 'auto',
                            objectFit: 'cover',
                            pointerEvents: 'none',
                          }}
                        /> */}
                      </>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>

      <div className="c_wrap02" style={{ position: "relative" }}>
        <div onClick={e => onGoingDetailHandler(e, varProductData.jt_idx)}>
          <div className="tit03">
            <span className="btn_st_A">{varProductData.jt_dongname}</span>
            <span className="btn_title">{varProductData.jt_title}</span>
          </div>
          {isNull(varProductData.jt_mobile) ? null : (
            <div className="call">
              <img src={callIcon} alt="" />
              &nbsp;&nbsp;{ChangeMobileFormat(varProductData.jt_mobile)}
            </div>
          )}
          {varProductData.jt_serious1 === 3 ? null : varProductData.jt_serious1 === 1 ? (
            <div style={{ padding: "10px 0px" }}>{"금액 " + addThousandPoint(varProductData.jt_price) + "원"}</div>
          ) : (
            <div style={{ padding: "10px 0px" }}>
              {varProductData.jt_price_mode === 0 ? "시급 " : "월급 "}
              {addThousandPoint(varProductData.jt_price) + "원"}
            </div>
          )}
          <div className="descript-lines2">{varProductData.jt_comment}</div>
        </div>
        <div className="right_wrap">
          <div className="heart wish_chk" onClick={e => onChangeJjimListHandler(e, varProductData.jt_idx)}>
            <img
              src={varProductData.jj_idx === null || varProductData.jj_status === 0 ? heartOffIcon : heartOnIcon}
              alt=""
            />
          </div>
        </div>
      </div>
      {/* 게시중일 때 노출됨 */}
      {varProductData.pr_status === 3 ? (
        <div className="blind_bg" style={{ zIndex: "3", display: "block" }}>
          <div className="txt_wrap">
            <strong>블라인드 처리</strong>
            <span>처리 관리자에게 문의해주세요</span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default JangterProduct;
