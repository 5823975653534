/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react"; 

import { DisplayNumber,AddOptionToString,ExceptOptionToString,isObjEmpty, DisplayNumberWithZero } from "../common/Common";

const DetailPriceInfo = ({varProductData,setProductData,onChangeInputHandler}) => {

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(varProductData.rp_include === null) {
      setProductData({...varProductData,rp_include:""});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //포함내역을 문자열로 조정한후에 변수에 저장
  const onSelectCheckBoxHandler = (e) => {
    const { name, value } = e.currentTarget;
    const bmode = e.currentTarget.checked;
    const currentInclude = varProductData.rp_include || ""; // 기본값 설정

    if(bmode) {
      if(!varProductData.rp_include.includes(value)) {
        const newString = AddOptionToString(value,currentInclude,',');
        setProductData({...varProductData,[name]:newString});
      }
    } else {
      if(varProductData.rp_include.includes(value)) {
        const newString = ExceptOptionToString(value,currentInclude,',');
        setProductData({...varProductData,[name]:newString});
      }
    }
  }

  // rp_include의 기본값 설정
  const rp_include = varProductData.rp_include || "";

  return isObjEmpty(varProductData) ? null : (
      <div id="trans_type1"> 
        <div className="form_wrap2">
          <div className="tit07_2">
            {varProductData.rp_type===0?"매매가/전세금"
            :varProductData.rp_type===11?"매매가"
            :varProductData.rp_type===12?"전세금"
            :varProductData.rp_type===15?"분양가"
            :"보증금"}
            <span className="star_red"> (필수)</span>
          </div>
          <div className="one-line">
            <input type="text" name="rp_deposit" className="one-line-won" placeholder="0" value={DisplayNumber(varProductData.rp_deposit)} onChange={e=>onChangeInputHandler(e)}/>
            <span className="danwe_txt">만원</span>
          </div>
        </div>
        {/* 거래유형이 '분양'의 경우 제외 */}
        {varProductData.rp_type===15|| varProductData.rp_type===11 || varProductData.rp_type===12 ? null:
          <div className="form_wrap2">
            <div className="tit07_2">월세/단기<span className="star_red"> (필수)</span></div>
            <div className="one-line">
              <input type="text" name="rp_monthly" className="one-line-won" placeholder="0"value={DisplayNumber(varProductData.rp_monthly)} onChange={e=>onChangeInputHandler(e)}/>
              <span className="danwe_txt">만원</span>
            </div>
          </div>
        }
        {varProductData.rp_serious===9 ? null:
          <div className="form_wrap2">
            <div className="tit07_2">관리비<span className="star_red"> (필수)</span></div>
            <div className="one-line">
              <input type="text" name="rp_admin_expense" className="one-line-won" placeholder="0" value={DisplayNumberWithZero(varProductData.rp_admin_expense)} onChange={e=>onChangeInputHandler(e)}/>
              <span className="danwe_txt">만원</span>
            </div>
          </div>
        }
        {varProductData.rp_serious < 6 || varProductData.rp_serious===9 ? null:
          <div className="form_wrap2">
            <div className="tit07_2">시설권리금</div>
            <div className="one-line">
              <input type="text" name="rp_right_money" className="one-line-won" placeholder="0" value={DisplayNumber(varProductData.rp_right_money)} onChange={e=>onChangeInputHandler(e)}/>
              <span className="danwe_txt">만원</span>
            </div>
          </div>
        }
        {/* 거래유형이 '분양'/ 매매종류가 주택류의 경우 제외 */}
        {varProductData.rp_type===15 || varProductData.rp_serious>=6?null:
          <div className="form_wrap2">
            <div className="tit07_2">포함 내역</div>
            <div className="one-line line-left-row">
              <li className="terms_chks" style={{marginRight:"5px"}}>
                  <input type="checkbox" name="rp_include" className="chk1" id="rp_include1" value="전기" onChange={e=>onSelectCheckBoxHandler(e)} checked={rp_include.includes("전기")}/>
                  <i></i><label htmlFor="rp_include1">전기</label>
              </li>
              <li className="terms_chks" style={{marginRight:"5px"}}>
                  <input type="checkbox" name="rp_include" className="chk1" id="rp_include2" value="수도" onChange={e=>onSelectCheckBoxHandler(e)} checked={rp_include.includes("수도")}/>
                  <i></i><label htmlFor="rp_include2">수도</label>
              </li>
              <li className="terms_chks">
                  <input type="checkbox" name="rp_include" className="chk1" id="rp_include3" value="도시가스" onChange={e=>onSelectCheckBoxHandler(e)} checked={rp_include.includes("도시가스")}/>
                  <i></i><label htmlFor="rp_include3">도시가스</label>
              </li>
            </div>
          </div>
        }
      </div>
  );
}
export default DetailPriceInfo;