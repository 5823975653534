import React, { useState, useEffect } from "react"; 
import callingImage from "../../images/icon_call30.png";
import CONFIG from '../../configs/Configs';
import { ChangeMobileFormat, GetPhotoImage } from "../common/Common";

// 매물 중개 정보 표시
const PartnerDealingInfo = ({ varPartnerData }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);

  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime();
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  useEffect(() => {
    let photoImage = varPartnerData.mcm_file;
    if (photoImage === null || photoImage === "") photoImage = 'default_member.png';
    const simage = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(photoImage, 'member') + `?t=${new Date().getTime()}`;
    setImageLoading(true);
    loadImageWithRetry(simage)
      .then((loadedImage) => {
        setImageSrc(loadedImage);
        setImageLoading(false);
      })
      .catch((error) => {
        console.error(error.message);
        setImageLoading(false);
      });
  }, [varPartnerData.mcm_file]);

  return (
    <div className="info04" style={{ padding: "5px 10px 10px 10px", marginTop: "10px", backgroundColor: "#ffffff" }}>
      <div className="tit07_1">업체 정보</div>
      <div className="info04_inner">
        <div className="img_wrap" style={{ maxWidth: "30%", position: 'relative' }}>
          {imageLoading ? (
            <div className="loading-indicator" style={{width:"107px", height:"100px", position: 'relative'}}>
              <div className="loading-spinner"></div>
            </div>
          ) : (
            <img src={imageSrc} alt="" style={{ objectFit: "cover", width: "100%", height: "100%" }} />
          )}
        </div>
        <div className="box">
          <div className="c_name">{varPartnerData.mcm_name}</div>
          <span className="person_info">
            대표&nbsp;&nbsp;<span className="name_txt">{varPartnerData.mem_name}</span>&nbsp;&nbsp;<br />
            {ChangeMobileFormat(varPartnerData.mcm_phone)}
          </span>
          <div className="fnc_wrap" style={{ marginRight: "10px" }}>
            <a href={`tel:${varPartnerData.mem_mobile}`}><img src={callingImage} style={{ width: "50px" }} alt="" /></a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PartnerDealingInfo;
