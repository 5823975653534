/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DaumPostcode from "react-daum-postcode";
// 이미지
import closeImage from "../../images/btn_close_white.png";

const Zipcode = ({varOpenZipcode,setOpenZipcode,onCompleteZipcodeHandler}) => {
  return (
    <div className="poplayerCompanySearch">
      <div className="consbox2" style={{ zIndex: 999 }}>
        <div className="btnclose" onClick={()=>setOpenZipcode(false)}><img src={closeImage} alt="" /></div>
        <DaumPostcode
          style={{ width: "100%", height: "500px" }}
          jsOptions={{ animation: varOpenZipcode }}
          autoClose={true}
          onComplete={(e) => onCompleteZipcodeHandler(e)}
        />
      </div>
    </div>
  )
}
export default Zipcode;