import React,{ useState,useEffect } from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import footerIcon from '../../images/common/footer_logo.png';
import { ChangeMobileFormat } from "../common/Common";

const FooterCom = () => {
  const [varCompanyState,setCompanyState] = useState({});

  //운영회사 정보 읽기
  const GetCompanyEnvironment = async() => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_company_information`).then((res) => {
      if(res.data.retvalue === 1) {
        if(res.data.results?.length > 0) {
          //서비스 업체 정보 저장
          setCompanyState(res.data.results[0]);
        }
      }
    });
  }
  useEffect(() => {
    GetCompanyEnvironment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!varCompanyState.company_name) {
    return null; // 데이터가 로드되기 전에는 컴포넌트를 렌더링하지 않음
  }

  return (
    <div className="footer">
      <div className="footer-con">
        <div className="center_wrap">
          <img src={footerIcon} className="f-logo" alt=""/>
          <div className="txt" style={{textAlign:"left",paddingBottom:"80px"}}>
              <b>{varCompanyState.company_name}</b><br/>대표: {varCompanyState.company_ceo}<br/>사업자 등록번호: {varCompanyState.bizcode}<br/>
              {varCompanyState.buytelcode===""?null:`통신판매업신고: ${varCompanyState.buytelcode}`} <br/>
            주소: [{varCompanyState.zipcode}]{varCompanyState.address1}<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{varCompanyState.address2}<br/>
            고객센터: {ChangeMobileFormat(varCompanyState.telephone)}<br/>Copyright 2022 둥지 All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
export default FooterCom;