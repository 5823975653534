import React, { useEffect,useRef } from "react"; 
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";
import { useParams } from "react-router-dom";
import CONFIG from '../../configs/Configs';
import {v4 as uuidv4} from 'uuid';

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import { isNull } from "../../components/common/Common";

const TossPayment = () => {
  const { ph_idx,enCodedString } = useParams();
  const paymentWidgetRef = useRef(null);  //<PaymentWidgetInstance | null>
  let paymentData = {ph_idx:ph_idx,orderId:"",orderPrice:0,customerName:"",orderName:""};

  useEffect(() => {
    const deCodedString = decodeURI(enCodedString); //base64_decode(enCodedString);
    console.log("deCodedString : ", deCodedString);
    if(!isNull(deCodedString)) {
      const dataArray = deCodedString.split('|');
      paymentData.orderId = dataArray[0];
      paymentData.orderPrice = Number(dataArray[1]);
      paymentData.customerName = dataArray[2];
      paymentData.orderName = dataArray[3];
      console.log("paymentData.orderName : ", dataArray[3]);
    }

    (async () => {
      const TossCustomerKey = uuidv4();
      const paymentWidget = await loadPaymentWidget(`${CONFIG.TossClientKey}`,TossCustomerKey);
      paymentWidget.renderPaymentMethods("#payment-widget", {value:paymentData.orderPrice, paymentMethods: ['카드']});
      paymentWidgetRef.current = paymentWidget;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPaymentHandler = async(e) => {
    const paymentWidget = paymentWidgetRef.current;
    try {
      console.log(paymentData.orderName);
      await paymentWidget?.requestPayment({
        orderId: paymentData.orderId,
        orderName: paymentData.orderName,
        customerName: paymentData.customerName,
        customerEmail: "admin@gmail.com",
        successUrl: `${CONFIG.PAYMENT_HOST}/SuccessToss`,
        failUrl: `${CONFIG.PAYMENT_HOST}/FailToss`
      }) 
    } catch (err) {
        console.log(err);
    }
  }

  return (
    <>
    <Header/>
    <HeadTitle titleString={"주문서"} backString={"PremiumList"}/>
    <div id="content">
      <div id="payment-widget" />
      <div className="btn_bottom one_btn">
        <button className="btn_style_yes" id="payment-button" onClick={e=>onPaymentHandler(e)}>결제하기</button>
      </div>
    </div>
    </>
  )
}
export default TossPayment;
