import React, { useState,useEffect,useRef } from "react"; 

import { DESC,Sorting,isNull } from "../common/Common";

import JangterProduct from "../../components/jangter/JangterProduct";

//장터 게시물 검색 목록
const SearchedJangterResult = ({varJangterTitle,varSerious3,varTotalCount,setTotalCount,varJangterList,setJangterList,
                                varMorePage,varPageNum,setPageNum}) => {

  const scrollRef = useRef([]);
  const jt_serious2 = Number(localStorage.getItem("jangterSerious2"));
  const [varFilteredList,setFilteredList] = useState([]);
  const [varSerious3Option,setSerious3Option] = useState(0);

  //페이지 정보 표시 - 최초
  useEffect(() => {
    //console.log('varSerious3Option',varSerious3Option,varJangterList);
    if(varSerious3Option === 0) {
      setFilteredList(varJangterList);
      setTotalCount(varJangterList.length);
    } else {
      const copyList = varJangterList.filter(element=>element.jt_serious3===varSerious3Option);
      console.log(copyList);
      setFilteredList(copyList);
      setTotalCount(copyList.length);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSerious3Option,varJangterList]);

  useEffect(() => {
    ResetScrollTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varFilteredList]);
  
  //선택되었던 매물로 이동
  const ResetScrollTop = () => {
    if(!isNull(localStorage.getItem('scrollIndex')) && localStorage.getItem('scrollIndex')!== -1){
      const inum  = localStorage.getItem('scrollIndex');
      const iTop  = localStorage.getItem('scrollTop');
      const saleBlock = scrollRef.current[inum];
      if(!isNull(saleBlock)) { 
        setTimeout(()=>window.scroll({top:iTop,left:0}),10);
        localStorage.setItem('scrollIndex',-1);
      }
    }
  }

  //소분류 선택
  const onChangeOptionHandler = (e) => {
    const { value } = e.currentTarget;
    setSerious3Option(Number(value));
  }

  //쏘팅항목 선택시
  const onChangeSortHandler = (e) => {
    const { value } = e.currentTarget;
    const iValue = Number(value);
    let arrayList = [...varFilteredList];
    console.log(arrayList);
    let fname = "jt_price";  //가격 
    let fmode = false;
    if(iValue===1) { //최신등록순
      fname = "jt_rdate"; fmode = true;
    } else if(iValue===6) { //시급높은순
      fname = "jt_hits";  //인기순 = 조회수 믾은 것부터
    } 
    if(iValue === 1 || iValue === 6) {
      arrayList.sort(DESC(fname,fmode));
    } else {
      //0:시급/1:월급
      let arrayList0 = arrayList.filter(el=>el.jt_price_mode===0);
      let arrayList1 = arrayList.filter(el=>el.jt_price_mode===1);
      if(iValue === 2 || iValue === 4) {  //높은 순
        arrayList0.sort(Sorting(fname,1));
        arrayList1.sort(Sorting(fname,1));
      } else {  //낮은 순
        arrayList0.sort(Sorting(fname,0));
        arrayList1.sort(Sorting(fname,0));
      } 
      if(iValue === 2 || iValue === 3) {  //시급
        arrayList = [...arrayList0,...arrayList1];
      } else { //월급
        arrayList = [...arrayList1,...arrayList0];
      }
    }
    setFilteredList(arrayList);
  }
  
  return (
    <section>
      <div className="tit01 st02 mk_page_title"><div className="center_wrap">{varJangterTitle}</div></div>
      <div className="mk_con_wrap">
        <div className="filter_type02_wrap">
          <div className="center_wrap">
            <div className="tit01 st02">장터 
              <span className="red_txt"><span className="gray_bar">&nbsp;</span>{varTotalCount+" 건"}</span>
              <div className="go_right">
                <li className="sort_box" style={{paddingTop:"5px"}}>
                 {/* 소분류 */}
                  <select name="jt_serious3" id="sorting" defaultValue={0} style={{width:"120px",fontSize:"14px"}} onChange={e=>onChangeOptionHandler(e)}>
                    <option value={0}>소분류선택</option>
                    {varSerious3.map((serious3,i)=>(
                      <option key={'serious3_'+i} value={serious3.vbp_idx}>{serious3.vbp_name}</option>
                    ))}
                  </select>&nbsp;
                  {/* 목록 정렬을 위한 항목 */}
                  <select name="sorting" id="sorting" defaultValue="1" style={{width:"100px",fontSize:"14px"}} onChange={e=>onChangeSortHandler(e)}>
                    <option value="1">최신등록순</option>
                    {jt_serious2!==5&&jt_serious2!==6?null:
                      <>
                        <option value="2">시급높은순</option>
                        <option value="3">시급낮은순</option>
                        <option value="4">월급높은순</option>
                        <option value="5">월급낮은순</option>
                      </>
                    }
                    <option value="6">인기순</option>
                  </select>
                </li>
              </div>
            </div>
          </div>
        </div>  
        <div className="main_dunggi list_type01">
        {/* 장터게시툴 목록 */}
          {varFilteredList?.length===0?
            <div style={{textAlign:"center",height:"30px",marginTop:"10px"}}>검색된 장터게시물이 없습니다.</div>
          :
            <>
              {varFilteredList.map((product,i)=>(
                <div className="list_con" key={'product_'+i} ref={e=>(scrollRef.current[i]=e)}>
                  <JangterProduct inum={i} product={product} varJangterList={varJangterList} setJangterList={setJangterList}/>
                </div>
              ))}   
              {varMorePage?<div className="list_con more"  onClick={()=>setPageNum(varPageNum=>varPageNum+1)}>더보기</div>:null}
            </>
          }
        </div>     
      </div>
    </section>
  )
}
export default SearchedJangterResult;