import React, { useState,useEffect } from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";
import { isObjEmpty,unescapeHtml } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import FooterCom from '../../components/include/FooterCom';

import colseIcon from "../../images/icon_close.png";

const Settings = () => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const [varAppSetting,setAppSetting] = useState({});
  //팝업창
  const [varOpenTerms,setOpenTerms] = useState(false);
  //약관  
  const [varTermsTitle,setTermsTitle] = useState("");
  const [varTermsString,setTermsString] = useState("");

  //앱설정정보 가져오기
  const getMyAppInfomation = async() => {
    const sendData = {mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_myapp_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //검색된 목록
        setAppSetting(datas.results);
      }
    });
  }
  useEffect(() => {
    getMyAppInfomation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //on/off 버튼 조정시
  const ChangeAppSetting = async (sname,bmode) => {
    const sendData = {mem_idx:mem_idx,sfield:sname,state:Number(bmode)};
    await axios.post(`${CONFIG.SERVER_HOST}/_change_myapp_information`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 0) {
        alert(datas.message);
      }
    });
  }

  const onChangeModeHandler = (e,sid) => {
    const inputBox = document.getElementById(sid);
    const bmode = !inputBox.checked;
    if(bmode) {
      if(!e.currentTarget.classList.contains('switchthreeOn')) e.currentTarget.classList.add('switchthreeOn');
    } else {
      if(e.currentTarget.classList.contains('switchthreeOn')) e.currentTarget.classList.remove('switchthreeOn');
    }
    ChangeAppSetting(sid,bmode);
  }

  //약관 팝업창 열기/닫기-------------------------------------------------------------------------------------
  const onOpenTermsHandler = async(e,bmode) => {
    const { name } = e.currentTarget;
    let termTitle = "";
    switch (name) {
      default:
      case "serviceterm":
        termTitle = "이용약관"; break;
      case "privacy":
        termTitle = "개인정보 처리방침"; break;
      case "marketing":
        termTitle = "마케팅 정보 수신"; break;
    }
    setTermsTitle(termTitle);
    if(bmode) {
      const sendData = {sfield:name};
      await axios.post(`${CONFIG.SERVER_HOST}/_get_myapp_condition`,sendData).then((res) => {
        const datas = res.data;
        console.log(datas);
        if(datas.retvalue === 1) {        
          const termString = unescapeHtml(datas.results);
          setTermsString(termString);
        }
      });
    }
    setOpenTerms(bmode);
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  } 

  return isObjEmpty(varAppSetting)?null:(
    <>
      <Header/>
      <div className="layer-bg"></div>  
      <HeadTitle titleString={"앱설정"}/>
      <div id="content" style={{marginBottom:"10px"}}>
        <div className="setting_wrap">
          <div className="box">
            <div className="tit02"><div className="center_wrap">알림설정</div></div>
            <div className="top_wrap">
              <ul className="center_wrap">
                <li>
                  <p>공지 알림</p>
                  <span className="txt_right">
                    <label className="switch-label">
                      <input type="checkbox" id="alert_notice" name="alert_notice" className="checkbox" value={varAppSetting.alert_notice}/>
                      <div className={varAppSetting.alert_notice===0?"switchthree":"switchthree switchthreeOn"} onClick={e=>onChangeModeHandler(e,"alert_notice")}>
                        <span></span><span>OFF</span>
                      </div>
                    </label>
                  </span>
                </li>
                <li>
                  <p>신규 채팅 알림</p>
                  <span className="txt_right">
                    <label className="switch-label">
                      <input type="checkbox" id="alert_chatting" name="alert_chatting" className="checkbox" value={varAppSetting.alert_chatting}/>
                      <div className={varAppSetting.alert_chatting===0?"switchthree":"switchthree switchthreeOn"} onClick={e=>onChangeModeHandler(e,"alert_chatting")}>
                        <span></span><span>OFF</span>
                      </div>
                    </label>
                  </span>
                </li>
                {/* <li>
                  <p>커뮤니티 댓글 알림</p>
                  <span className="txt_right">
                    <label className="switch-label">
                      <input type="checkbox" id="alert_reply" name="alert_reply" className="checkbox" value={varAppSetting.alert_reply}/>
                      <div className={varAppSetting.alert_reply===0?"switchthree":"switchthree switchthreeOn"} onClick={e=>onChangeModeHandler(e,"alert_reply")}>
                        <span></span><span>OFF</span>
                      </div>
                    </label>
                  </span>
                </li> */}
                <li>
                  <p>1:1 문의하기 답변 알림</p>
                  <span className="txt_right">
                    <label className="switch-label">
                      <input type="checkbox" id="alert_qna" name="alert_qna" className="checkbox" value={varAppSetting.alert_qna}/>
                      <div className={varAppSetting.alert_qna===0?"switchthree":"switchthree switchthreeOn"} onClick={e=>onChangeModeHandler(e,"alert_qna")}>
                        <span></span><span>OFF</span>
                      </div>
                    </label>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="box" style={{marginTop:"10px"}}>
            <div className="tit02"><div className="center_wrap">앱 정보</div></div>
            <div className="con_wrap">
              <div className="center_wrap">
                <ul className="menu">
                  <li><button name="serviceterm" onClick={e=>onOpenTermsHandler(e,true)}>이용약관</button></li>
                  <li><button name="privacy" onClick={e=>onOpenTermsHandler(e,true)}>개인정보 처리방침</button></li>
                  <li><button name="marketing" onClick={e=>onOpenTermsHandler(e,true)}>마케팅 정보 수신</button></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="box" style={{marginTop:"10px"}}>
            <FooterCom/>
          </div>
        </div>
      </div>
      {/* 약관표시창 */}
      <div className="layer-pop pop-condition" style={varOpenTerms?{display:"block"}:{display:"none"}}>
        <div className="pop_head">
          <div className="layer_tit">{varTermsTitle}</div>
          <div className="close_layer top_close_btn"><img src={colseIcon} alt="" onClick={e=>onOpenTermsHandler(e,false)}/></div>
        </div>
        <div className="layer_inner" style={{height:"80%"}}>
          <div className="center_wrap">
            <div className="con">
              <div className="con_box" style={{marginTop:"0px"}}>
                <div className="desc">{varTermsString}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_bottom one_btn">
          <button className="btn_style_yes close_layer" onClick={e=>onOpenTermsHandler(e,false)}>확인</button>
        </div>
      </div>
    </>
    );
  }
  export default Settings;