import React, { useState,useEffect } from "react";
import { useParams,useNavigate,Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';
import DatePicker from "react-datepicker";
import { ko } from 'date-fns/esm/locale';

import { ChangeSearchingDateFormat,isObjEmpty,addThousandPoint,removeThousandPoint,ChangeMobileFormat } from "../../../components/common/Common";
import SetRegion from "./SetRegion";

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

//파트너스 게시물 정보 표시
function PremiumDetail({setMenuItem}) {
  const {rpa_mode,rpa_idx} = useParams();
  const navigate = useNavigate();

  //표시항목 설정
  let menuItem = "";
  let menuTitle = "";
  if(Number(rpa_mode) === 1) {
    menuItem = 'DoongjiPADList';
    menuTitle = "둥지 프리미엄 광고 정보";
  } else {
    menuItem = 'JangterPADList';
    menuTitle = "장터 프리미엄 광고 정보";
  }
  
  //선택/추가할 저장 변수 
  const [varProductDatas, setProductDatas] = useState({});
  const [varRegionList, setRegionList] = useState([]);
  
  const [varStartDate, setStartDate] = useState(null);
  const [varEndDate, setEndDate] = useState(null);
  const [varLoopRegions, setLoopRegions] = useState([]);

  //파트너스 게시물 정보 읽어오기
  const getPremiumInformation = async () => {
    const sendData = {rpa_idx:rpa_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_premium_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      //지역설정
      setRegionList(datas.regions);
      if(datas.retvalue === 1) {
        if(datas.results?.length > 0) {
          setProductDatas(datas.results[0]);
          setStartDate(datas.results.sdate===null?null:new Date(datas.results[0].rpa_sdate));
          setEndDate(datas.results.edate===null?null:new Date(datas.results[0].rpa_edate));
        }
      } else {
        alert(datas.message);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setLoopRegions([{id:1},{id:2},{id:3},{id:4},{id:5}]);
    setMenuItem(menuItem);
    getPremiumInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rpa_mode,rpa_idx]);

  //상태선택
  const onChangeSelectHandler = (e) => {
    const { name, value } = e.currentTarget;
    setProductDatas({...varProductDatas,[name]:Number(value)});
  }
  //게시 기간 설정값 저장
  const onChangeDateHandler = (ino,date) => {
    if(date === "" ) date = null;
    if(ino === 0) {
      setStartDate(date);
      setProductDatas({...varProductDatas, rpa_sdate:ChangeSearchingDateFormat(date)});
    } else {
      setEndDate(date);
      setProductDatas({...varProductDatas, rpa_edate:ChangeSearchingDateFormat(date)});
    }
  }

  //파트너스 게시물 정보 저장
  const onUpdateProductHandler = async (e) => {
    if (varProductDatas.rpa_regcode1 === '') {
      alert('게시힐 첫번째 지역을 선택해 주세요'); return false;
    } else if(varStartDate === null || varStartDate === "") {
      alert('광고시작일자를 선택해 주세요'); return false;
    } else if(varEndDate === null || varEndDate === "") {
      alert('광고종료일자를 선택해 주세요'); return false;
    } else if(varProductDatas.ph_amount === "" || varProductDatas.ph_amount === 0) {
      alert('사용자가 결제해야할 금액을 먼저 입력해 주세요'); return false;

    }
    if(!window.confirm('파트너스 게시물 정보를 저장합니까?')) return false;
    const sendData = {rpa_idx: rpa_idx, infos:varProductDatas};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/admin_update_premium_information`, sendData).then((res) => {
      alert(res.data.message);
      if (res.data.retvalue === 1) {
        if(varProductDatas.rpa_status === 2) {
          SendAligoMessage();
        } else {
          navigate(`/Admin/ProductAdvertise/PremiumList/${rpa_mode}`);
        }
      }
    });
  }
  //무통장 알리고 메시지 발송
  const SendAligoMessage = async() => {
    const receiverPhone = ChangeMobileFormat(varProductDatas.mem_mobile);
    const message = `[둥지장터_결제요청]
    프리미엄 광고 광고비 산정이 완료되었습니다.
    결제 및 관리자 승인 후 광고가 집행됩니다.    
    
    ▶금액: ${addThousandPoint(varProductDatas.ph_amount)+" 원"}

    (내역확인: 마이페이지>파트너스 결제 관리)
    `;
    const sendData = {sender:"02-3144-2654",receiver:receiverPhone,msg:message,msg_type:'LMS'};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_send_message`, sendData).then((res) => {
      console.log(res.data);
      alert(res.data.message);
      navigate(`/Admin/ProductAdvertise/PremiumList/${rpa_mode}`);
    });
  }
  const onChangeInputHandler = (e) => {
    const { value } = e.currentTarget;
    let smoney = removeThousandPoint(value);
    if(smoney === "" || smoney === "0")  setProductDatas({...varProductDatas,ph_amount:smoney,rpa_status:1});
    else setProductDatas({...varProductDatas,ph_amount:smoney});
  }

  //세부항목표시 페이지로 이동
  const onGotoProductDetailHandler = () => {
    if(Number(rpa_mode)===1) {  //둥지
      navigate(`/Admin/ProductList/DoongjiDetail/${varProductDatas.rp_idx}`);
    } else {  //파트너
      navigate(`/Admin/ProductList/PartnerDetail/${varProductDatas.jp_idx}`)
    }
  }

  return isObjEmpty(varProductDatas)?null: (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">{menuTitle}</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>둥지/장터관리<img src={arrImage} alt=""/>프리미엄 광고 관리<img src={arrImage} alt=""/>{menuTitle}
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <table className="write">
            <colgroup>
              <col width="20%"></col>
              <col width="30%"></col>
              <col width="20%"></col>
              <col width="30%"></col>
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>광고유형</th>
                <td>{Number(rpa_mode)===1?"둥지":"장터"}</td>
                <th>희망광고컨텐츠</th>
                <td onClick={onGotoProductDetailHandler} style={{cursor:"pointer"}}>{Number(rpa_mode)===1?varProductDatas.rp_title:varProductDatas.mcm_name}</td>
              </tr>
              <tr>
                <th>광고희망지역</th>
                <td colSpan="3"><table>
                  <thead></thead>
                  <tbody>
                  {varLoopRegions.map((element,i) =>(
                    <tr key={'region_'+i}>
                      <SetRegion irow={element.id} varProductDatas={varProductDatas} setProductDatas={setProductDatas} varRegionList={varRegionList} />
                    </tr>
                  ))}
                  </tbody>
                </table></td>
              </tr>
              <tr>
                <th>결제금액</th>
                <td colSpan="3">
                <input type="text" name="ph_amount" style={{width:"150px",textAlign:"center"}} value={addThousandPoint(varProductDatas.ph_amount)} onChange={e=>onChangeInputHandler(e)}/>&nbsp;원
                </td>
              </tr>
              <tr>
                <th>희망 게재 기간</th>
                <td colSpan="3" className="datepicker_area">
                  <DatePicker locale={ko} name="sdate" className="datepicker" style={{width:"120px"}} dateFormat="yyyy.MM.dd" selected={varStartDate} selectStart onChange={(date)=>onChangeDateHandler(0,date)} />
                  &nbsp;&nbsp;~&nbsp;&nbsp;
                  <DatePicker locale={ko} name="edate" className="datepicker" style={{width:"120px"}} dateFormat="yyyy.MM.dd" selected={varEndDate} selectEnd minDate={varStartDate} onChange={(date)=>onChangeDateHandler(1,date)} />   
                </td>
              </tr>
              <tr>
                <th>게시상태</th>
                <td>
                  <select name="rpa_show" style={{width:"130px"}} onChange={(e)=>onChangeSelectHandler(e)} defaultValue={varProductDatas.rpa_show}>
                    <option value={0}>게시중지</option>
                    <option value={1}>게시중</option>
                  </select>
                </td>
                {/* <th>진행상태</th>
                <td>
                  <select name="rpa_status" style={{width:"130px"}} onChange={(e)=>onChangeSelectHandler(e)} defaultValue={varProductDatas.rpa_status}>
                    <option value={0}>종료</option>
                    <option value={1}>신청</option>
                    <option value={2}>승인/결제대기</option>
                    <option value={3}>결제완료</option>
                  </select>
                </td> */}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" style={{width:"100px"}} onClick={(e)=>onUpdateProductHandler(e)}>{Number(rpa_idx)===0?"등록":"저장"}</button>
          <Link to={`/Admin/ProductAdvertise/PremiumList/${rpa_mode}`} style={{width:"100px"}} className="btn line">목록</Link>
        </div>
      </div>
    </div>   
  )
}

export default PremiumDetail;