import React, { useState,useEffect } from "react";
import { useParams,useNavigate,Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import DaumPostCode from "../../../components/popup/DaumPostCode";
import DetailPhotos from '../../components/include/DetailPhotos';
import AdminShowBigSizePhoto from '../../components/include/AdminShowBigSizePhoto';

import {removeThousandPoint,addThousandPoint,ChangeDateStringOnlyDate,isObjEmpty} from "../../../components/common/Common";

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

//장터게시물 정보 표시
function JangterDetail({setMenuItem}) {
  const {jt_idx} = useParams();
  const navigate = useNavigate();
  
  //작성자
  const mem_idx = Number(localStorage.getItem("adminIdx"));

  //선택/추가할 저장 변수 
  const [varProductDatas, setProductDatas] = useState({});
  const [varSeriousList1, setSeriousList1] = useState([]);
  const [varSeriousList2, setSeriousList2] = useState([]);
  const [varSeriousList3, setSeriousList3] = useState([]);
  //필터링
  const [varFilteredList2, setFilteredList2] = useState([]);
  const [varFilteredList3, setFilteredList3] = useState([]);

  //매물사진
  const [varProductPhotos,setProductPhotos] = useState([]); //이미 저장된 매물사진 목록
  const [varTempProductPhotos,setTempProductPhotos] = useState([]); //신규 매물 사진 목록 - 선택된 사진 저장 배열
  const [varTempProductPhotoFiles,setTempProductPhotoFiles] = useState([]); //신규 매물 사진 정보 목록 - 선택된 사진 정보 저장 배열
  const [varEmptyProductPhotos,setEmptyProductPhotos] = useState([]); //신규 매물 사진 목록 - 비어 있는 사진 저장 배열

  //신규 등록을 위한 객체
  const newJangterData = {jt_idx:0,jt_mem_idx:mem_idx,mem_name:"관리자",jt_code:"",jt_title:"",jt_serious1:0,jt_serious2:0,jt_serious3:0,
        jt_comment:"",jt_zipcode:"",jt_address1:"",jt_address2:"",jt_location:"",jt_regcode:'',jt_dongname:'',jt_price:0,jt_rdate:"",jt_status:1};

  //장터게시물정보 읽어오기
  const getJangterInformation = async () => {
    const sendData = {jt_idx:jt_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_jangter_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      let photoMax = 5;
      let prdlist = {...newJangterData};
      if(Number(jt_idx)>0) {
        if(datas.retvalue === 1 && datas.results?.length > 0) {
          prdlist = datas.results[0];
          //매물사진정보
          setProductPhotos(datas.photos);
          photoMax = photoMax - datas.photos?.length;
        }
      }
      setProductDatas(prdlist);

      setSeriousList1(datas.serious1);
      setSeriousList2(datas.serious2);
      setSeriousList3(datas.serious3);
      //console.log(prdlist.jt_serious1,datas.serious2)
      if (prdlist.jt_serious1 > 0) {
        const filteredData2 = datas.serious2.filter(el=>el.vbs_idx===prdlist.jt_serious1);
        setFilteredList2(filteredData2);
      } else {
        setFilteredList2([]);
      }
      if (prdlist.jt_serious2 > 0) {
        const filteredData3 = datas.serious3.filter(el=>el.vbi_idx===prdlist.jt_serious2);
        setFilteredList3(filteredData3);
      } else {
        setFilteredList3([]);
      }

      //매물사진 15개까지 등록가능 --> 빈 공간 저장배열 만들기
      const photoData = {file_idx:0,file_name:"",file_preview:""};
      let photoArray = [];
      for(var i=0; i<photoMax; i++) {
        photoArray.push(photoData);
      }
      setEmptyProductPhotos(photoArray);
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('JangterList');
    getJangterInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //장터게시물정보 입력
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    if(name === "jt_price") {
      let strValue = removeThousandPoint(value);
      setProductDatas({...varProductDatas,[name]:strValue});
    } else {
      setProductDatas({...varProductDatas,[name]:value});
    }
  };
  //분류
  const onChangeSelectHandler = (e) => {
    const { name, value } = e.currentTarget;
    if(name === "jt_serious1") {
      const vbs_idx = Number(value);
      if (vbs_idx > 0) {
        const filteredData2 = varSeriousList2.filter(el=>el.vbs_idx===vbs_idx);
        console.log(vbs_idx,varSeriousList2,filteredData2);
        setFilteredList2(filteredData2);
        setFilteredList3([]);
      } else {
        setFilteredList2([]);
        setFilteredList3([]);
      }
      setProductDatas({...varProductDatas,jt_serious1:vbs_idx,jt_serious2:0,jt_serious3:0});
    } else if(name === "jt_serious2") {
      const vbi_idx = Number(value);
      if (vbi_idx > 0) {
        const filteredData3 = varSeriousList3.filter(el=>el.vbi_idx===vbi_idx);
        setFilteredList3(filteredData3);
      } else {
        setFilteredList3([]);
      }
      setProductDatas({...varProductDatas,jt_serious2:vbi_idx,jt_serious3:0});
    } else {
      setProductDatas({...varProductDatas,[name]:Number(value)});
    }
    
  }

  //우편번호/주소찾기
  const [varOpenZipcode, setOpenZipcode] = useState(false);
  //우편번호/주소 검색 팝업창 열기/닫기
  const onOpenZipcodeHandler = (e, bts) => {
    e.preventDefault();
    setOpenZipcode(bts);
  };
  //우편번호 선택으로 우편번호/주소와 같이 반환
  const ChangeAddress = (zipcode,address1,regcode,dongName,roadName,locString) => {
    console.log(zipcode,address1,regcode,dongName,roadName,locString);
    setProductDatas((prevState)=>{return{...prevState, jt_zipcode: zipcode, jt_address1: address1, jt_regcode:regcode, jt_dongname:dongName,jt_location:locString}});
  };

  //매물사진 확대하기
  const [varOpenPhoto,setOpenPhoto] = useState(false);
  const [varPhotoImage,setPhotoImage] = useState(null);
  const onShowBigSizePhotoHandler = (e,files) => {
    e.preventDefault();
    setPhotoImage(files);
    setOpenPhoto(true);
  }
  const onOpenPhotoHandler = (e,bmode) => {
    setOpenPhoto(bmode);
  }

  //장터게시물정보 저장------------------------------------------------------
  const onUpdateProductHandler = async(e) => {
    if (varProductDatas.jt_title === '') {
      alert('제목을 입력해 주세요'); return false;
    } else if (varProductDatas.jt_serious1 === 0) {
      alert('대분류를 선택해 주세요'); return false;
    } else if (varProductDatas.jt_serious2 === 0) {
      alert('중분류를 선택해 주세요'); return false;
    } else if (varProductDatas.jt_price === '') {
      alert('가격을 입력해 주세요'); return false;
    } else if (varProductDatas.jt_status === -1) {
      alert('진행상태를 선택해 주세요'); return false;
    }
    
    //업로드할 파일이 있는지 점검
    //서버로 업로드할 매물사진을 배열로 저장
    let PhotoForm = new FormData();
    for(var i=0; i < varTempProductPhotoFiles?.length; i++) {
      let formData = varTempProductPhotoFiles[i];
      console.log(varTempProductPhotoFiles[i]);
      PhotoForm.append('jangter',formData.get('jangter'));
    }
    console.log('추가된 매물 사진 서버로 업로드 등록',varTempProductPhotos?.length);
    //추가된 매물 사진 배열을 서버로 업로드 등록
    if(varTempProductPhotos?.length > 0) {
      await axios.post(`${CONFIG.SERVER_HOST}/jangterArray`, PhotoForm).then((res) => {
        console.log(res.data);
        if(res.data.retvalue === 1) {
          const fileNames = res.data.fileNames; //배열
          console.log(fileNames);
          //매물사진 DB 등록
          UpdateJangterInformation(fileNames);
        }
      });
    } else {  //등록할 매물사진이 없는 경우
      UpdateJangterInformation("");
    }
  };
  //장터게시물정보 저장 - 데이타베이스 저장
  const UpdateJangterInformation = async (fileNames) => {
    //데이타베이스에 저장
    const sendData = {infos:varProductDatas};
    console.log(varProductDatas);
    await axios.post(`${CONFIG.SERVER_HOST}/admin_update_jangter_information`, sendData).then((res) => {
      if (res.data.retvalue === 1) {
        let na_idx = jt_idx;
        if(Number(jt_idx) === 0) na_idx = res.data.jt_idx;
        //매물사진 DB 등록
        console.log(fileNames);
        if(fileNames?.length > 0) {
          UploadJangterPhotos(na_idx,fileNames);
        } else {
          alert(res.data.message);
          navigate("/Admin/ProductList/JangterList");
        }
      } else {
        alert(res.data.message);
      }
    });
  }
  const UploadJangterPhotos = async(jt_idx,fileNames) => {
    const sendData = {jt_idx:jt_idx,fileNames:fileNames};
    console.log('sendData',sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_register_jangter_photos`, sendData).then((res) => {
      if (res.data.retvalue === 1) {
        navigate("/Admin/ProductList/JangterList");
      } else {
        alert(res.data.message);
      }
    });
  }

  return isObjEmpty(varProductDatas)?null:(
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">장터 게시물 정보</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>장터/장터관리<img src={arrImage} alt=""/>이용 관리<img src={arrImage} alt=""/>장터 게시물 정보
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <table className="write">
            <colgroup>
              <col width="20%"></col>
              <col width="30%"></col>
              <col width="20%"></col>
              <col width="30%"></col>
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>제목</th>
                <td><input type="text" name="jt_title" value={varProductDatas.jt_title||""} onChange={(e)=>onChangeInputHandler(e)} maxLength="100" /></td>
                <th>장터등록코드</th>
                <td>{jt_idx===0?"":varProductDatas.jt_code||""}</td>
              </tr>
              <tr>
                <th>대분류</th>
                <td>
                  <select name="jt_serious1" className="select-box" value={varProductDatas.jt_serious1} onChange={(e)=>onChangeSelectHandler(e)}>
                      <option key={"serious1"} value={0}>대분류선택</option>
                    {varSeriousList1.map((serious1,i)=>(
                      <option key={"serious1_"+i} value={serious1.vbs_idx}>{serious1.vbs_name}</option>
                    ))}
                  </select>
                </td>
                <th>중분류</th>
                <td>
                  <select name="jt_serious2" className="select-box" value={varProductDatas.jt_serious2} onChange={(e)=>onChangeSelectHandler(e)}>
                      <option key={"serious2"} value={0}>중분류선택</option>
                    {varFilteredList2.map((serious2,i)=>(
                      <option key={"serious2_"+i} value={serious2.vbi_idx}>{serious2.vbi_name}</option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <th>소분류</th>
                <td>
                  <select name="jt_serious3" className="select-box" value={varProductDatas.jt_serious3} onChange={(e)=>onChangeSelectHandler(e)}>
                      <option key={"serious3"} value={0}>소분류선택</option>
                    {varFilteredList3.map((serious3,i)=>(
                      <option key={"serious3_"+i} value={serious3.vbp_idx}>{serious3.vbp_name}</option>
                    ))}
                  </select>
                </td>
                <th>등록자</th>
                <td>{varProductDatas.jt_idx===0?"":varProductDatas.mem_name||""}</td>
              </tr>
              <tr>
                <th>내용</th>
                <td colSpan={3}><textarea name="jt_comment" value={varProductDatas.jt_comment||""} onChange={(e)=>onChangeInputHandler(e)}/></td>
              </tr>
              <tr>
                <th>주소</th>
                <td colSpan={3}>
                  <input type="text" name="jt_zipcode" className="zipcode-box" value={varProductDatas.jt_zipcode||""} readOnly/>
                  <button onClick={(e)=>onOpenZipcodeHandler(e,true)} style={{width:"90px"}} className="btn line">우편번호</button><br/>
                  <input type="text" name="jt_address1" className="address1-box" value={varProductDatas.jt_address1||""} readOnly/>&nbsp;&nbsp;
                  <input type="text" name="jt_address2" className="address2-box" id={"jt_address2"} value={varProductDatas.jt_address2||""} 
                          placeholder="상세 주소를 입력해주세요." onChange={(e)=>onChangeInputHandler(e)}/>
                </td>
              </tr> 
              <tr><td colSpan={4} className="product_subtitle">추가정보</td></tr>
              <tr className="image-area">
                <th className="image-title">매물사진</th>
                <td colSpan={3}>
                  <div className="image-block" >
                    <DetailPhotos varMode={2} photoMax={5}
                        varProductPhotos={varProductPhotos} setProductPhotos={setProductPhotos}
                        varTempProductPhotos={varTempProductPhotos} setTempProductPhotos={setTempProductPhotos}
                        varTempProductPhotoFiles={varTempProductPhotoFiles} setTempProductPhotoFiles={setTempProductPhotoFiles}
                        varEmptyProductPhotos={varEmptyProductPhotos} setEmptyProductPhotos={setEmptyProductPhotos}
                        onShowBigSizePhotoHandler={onShowBigSizePhotoHandler}/>
                  </div>
                </td> 
              </tr>
              <tr>
                <th>금액</th>
                <td><input type="text" className="price-box" name="jt_price" value={addThousandPoint(varProductDatas.jt_price)||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;만원</td>
                <th></th><td></td>
              </tr>
              <tr>
                <th>등록일자</th>
                <td>{varProductDatas.jt_idx===0?"":ChangeDateStringOnlyDate(varProductDatas.jt_rdate)}</td>
                <th>진행상태</th>
                <td>
                  <select className="select-box" name="jt_status" defaultValue={varProductDatas.jt_status} onChange={(e)=>onChangeInputHandler(e)}>
                    {/* <option value={-1}>진행상태선택</option> */}
                    <option value={0}>종료</option>
                    <option value={1}>진행중</option>
                    <option value={2}>거래완료</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" style={{width:"90px"}} onClick={(e)=>onUpdateProductHandler(e)}>{jt_idx===0?"등록":"저장"}</button>
          <Link to={"/Admin/ProductList/JangterList"} style={{width:"90px"}} className="btn line">목록</Link>
        </div>
      </div>
      {/* 우편번호/주소 검색*/}
      <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler}/>
      {/* 사진확대*/}
      <AdminShowBigSizePhoto varPhotoImage={varPhotoImage} varOpenPhoto={varOpenPhoto} onOpenPhotoHandler={onOpenPhotoHandler}/>
    </div>
  )
}

export default JangterDetail;