import React from "react"; 
import { DayClickEventHandler,DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { ko } from 'date-fns/locale';

import closeIcon from "../../images/sub/btn_close2.png";

const SelectDate = ({varDate,setDate,varDateMode,varPopupDaySelect,setPopupDaySelect,onChangeDateHandler,varDropDown}) => {
  const today = new Date();
  const sYear = today.getFullYear();
  const eYear = sYear+5;
  const onDayClickHandler: DayClickEventHandler = (day) => {
    onChangeDateHandler(varDateMode,day);
  }
  const onCloseHandler = (e) => {
    setPopupDaySelect(false);
  }
  return (
    <div className="layer-pop-select-date" style={varPopupDaySelect?{display:'block'}:{display:'none'}}>
      <div className="date-right-close"><img src={closeIcon} alt="" onClick={e=>onCloseHandler(e)}/></div>
      {varDropDown?
      <DayPicker fromYear={sYear} toYear={eYear} captionLayout="dropdown" locale={ko} mode="single" 
                      selected={varDate} fromDate={new Date()} onSelect={setDate} onDayClick={onDayClickHandler}/>
      :
      <DayPicker locale={ko} mode="single" fromDate={new Date()} selected={varDate} onSelect={setDate} onDayClick={onDayClickHandler}/>
      }
    </div>
  );
}
export default SelectDate;