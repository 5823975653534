/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import { useParams,Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";
// import { useLocation } from 'react-router-dom';

import { CheckLoginStateOnly,remainDays } from '../../components/common/Common';

import HeadTitle from '../../components/include/HeadTitle';
import DoongjiSlider from '../../components/doongji/DoongjiSlider';
import DoongjiHeader from '../../components/doongji/DoongjiHeader';
import DoongjiTitle from '../../components/doongji/DoongjiTitle';
import DoongjiPriceInfo from '../../components/doongji/DoongjiPriceInfo';
import DoongjiProductInfo from '../../components/doongji/DoongjiProductInfo';
import DoongjiDetailInfo from '../../components/doongji/DoongjiDetailInfo';
import DoongjiOptionInfo from '../../components/doongji/DoongjiOptionInfo';
import DoongjiLocationInfo from '../../components/doongji/DoongjiLocationInfo';
import DoongjiDealingInfo from '../../components/doongji/DoongjiDealingInfo';

import DoongjiDelete from '../../components/popup/DoongjiDelete';
import ReportProduct from '../../components/popup/ReportProduct';
import SupportMoney from '../../components/popup/SupportMoney';
import MapLocation from '../../components/popup/MapLocation';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const DoongjiView = () => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const memberLoginState = Number(localStorage.getItem("memberLoginState"));

  const { rp_idx } = useParams();
  const navigator = useNavigate();

  const [varProductData,setProductData] = useState({});
  const [varProductPhoto,setProductPhoto] = useState([]);
  const [varRegisterDoongji,setRegisterDoongji] = useState(0);
  const [varRemainDays,setRemainDays] = useState(0);

  //팝업창
  const [varPopupDoongjiDelete,setPopupDoongjiDelete] = useState(false);
  const [varPopupReportProduct,setPopupReportProduct] = useState(false);
  const [varPopupSupportMoney,setPopupSupportMoney] = useState(false);

  const [backString, setBackString] = useState(''); // 추가된 상태

  // const location = useLocation(); 


  useEffect(() => {
  // location.state로 접근해서 필요한 데이터 사용
    const handleResize = () => {
      if (window.innerWidth >= 750) {
        setBackString('nonWebDoongji');
      }
    };

    handleResize(); // 초기 렌더링 시 상태 설정
    window.addEventListener('resize', handleResize); // 창 크기 변경 감지

    return () => window.removeEventListener('resize', handleResize); // 정리 작업
  }, []);
  
  const onPopupDoongjiDeleteHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupDoongjiDelete(false);
      navigator('/Doongji/DoongjiList');
    } else {
      onPopupWinHandler(bmode);
      setPopupDoongjiDelete(bmode);
    }
  }
  const onPopupReportProductHandler = (e,bmode) => {
    if(memberLoginState === 0) {
      navigator('/Login');
    }    
    e.preventDefault();
    onPopupWinHandler(bmode);
    setPopupReportProduct(bmode);
  }
  const onPopupSupportMoneyHandler = (e,bmode) => {
    e.preventDefault();
    onPopupWinHandler(bmode);
    setPopupSupportMoney(bmode);
  }
  const onPopupWinHandler = (bmode) => {
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }

  //매물정보 읽어오기
  const getProductInformation = async () => {
    const sendData = {rp_idx:rp_idx,mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_doongji_view_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(datas.results?.length === 1) {
          //매물정보
          setProductData(datas.results[0]);
        }
        //매물사진목록
        setProductPhoto(datas.photos);
        //나의 보유현황
        if(datas.accounts.length > 0) {
          const varAccounts = datas.accounts[0];
          setRemainDays(remainDays(varAccounts.doongji_expdate));
          //등록되어 있는 수량
          const varCounter = datas.useCount;
          let canRegisterDoongji = 0;
          let rSlot = varAccounts.doongji_slots;
          if(remainDays(varAccounts.doongji_expdate) < 0) rSlot = 0;
          if((mem_mode===0 || mem_mode===2) && rSlot === 0) rSlot=2;
          if(rSlot > varCounter) canRegisterDoongji = 1;
          setRegisterDoongji(canRegisterDoongji);
          console.log(rSlot,varCounter);
        }
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    //document.getElementById('main_body').style.backgroundColor = '#f5f5f4';
    getProductInformation();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ////채팅방열기
  const onMakeChatRoomHandler = async (e) => {
    if(!CheckLoginStateOnly(mem_idx)) {
      navigator('/Login');
    } else {
      //채팅방 정보 읽거나 만들기
      const sendData = {ch_mode:1,no_idx:rp_idx,mem_idx:mem_idx};
      await axios.post(`${CONFIG.SERVER_HOST}/_get_chatroom`,sendData).then((res) => {
        const datas = res.data;
        console.log(datas);
        if(datas.retvalue === 1 || datas.retvalue === 2) {  //채팅방을 찾음(1)/새로만듦(2)
          navigator(`/Chat/ChatRoom/1/${datas.ch_idx}`); 
        } else {
          alert(datas.message);
        }
      });
    }
  }


  //전체 지도보기
  const [varMapLocation,setMapLocation] = useState(false);
  const onOpenMapLocationHandler = (e,bmode) => {
    setMapLocation(bmode);
  }

  //재등록
  const onReregisterDoongjiHandler = (e) => {
    console.log("varRegisterDoongji: ", varRegisterDoongji);
    if(varRegisterDoongji===1) {  //등록 가능
      navigator(`/Doongji/DoongjiDetail/${rp_idx}`);
    } 
    else {  //이용권구매
      if(mem_mode===0 || mem_mode===2) {
        alert('이미 2개의 둥지매물이 등록되어 있습니다. 무료로 등록할 수 있는 둥지매물은 2개까지 입니다.');
      }
      else if(varRemainDays < 0){
        alert('보유한 이용권이 없습니다. 이용권을 구매하세요');
        navigator('/MyPage/BuyVoucher/0');
        return false;
      } 
      else {
        alert('이미 게시된 매물들로 인하여 추가로 매물을 게시할 수 없습니다.');
        return false;
      }
    }
  }

  return (
    <>
      <div className="layer-bg" style={{backgroundColor:"rgba(0, 0, 0, 1)"}}></div>  
      <HeadTitle titleString={varProductData.rp_title||""} backString={backString}/>
      <div className="pd_wrap">
        <div className="view_wrap">
          <DoongjiSlider varProductData={varProductData} varProductPhoto={varProductPhoto}/>
          <DoongjiHeader varProductData={varProductData} setProductData={setProductData} onPopupReportProductHandler={onPopupReportProductHandler}/>
          <div className="mid_info_wrap">
            <DoongjiTitle varProductData={varProductData}/>
            <div className="center_wrap" style={{marginBottom:"70px"}}>
              <DoongjiPriceInfo varProductData={varProductData}/>
              <DoongjiProductInfo varProductData={varProductData}/>
              <DoongjiDetailInfo varProductData={varProductData} onPopupSupportMoneyHandler={onPopupSupportMoneyHandler}/>
              <DoongjiOptionInfo varProductData={varProductData}/>
              {memberLoginState===0 || (varProductData.rp_address1===null || (varProductData.rp_address1==="" && varProductData.rp_location === "")) ? null: 
                <DoongjiLocationInfo varProductData={varProductData} onOpenMapLocationHandler={onOpenMapLocationHandler}/>
              }
              {memberLoginState===1?<DoongjiDealingInfo varProductData={varProductData}/>:null}
            </div>
          </div>
        </div>
        {/* 작성자가 정보를 보는 경우 */}
        {varProductData.mem_idx!==mem_idx?
          <div className="btn_bottom one_btn" style={{zIndex:"10"}}>
            <button className="btn_style_yes" onClick={e=>onMakeChatRoomHandler(e)}>채팅하기</button>
          </div>
        :
          varProductData.rp_status===0 || (varProductData.rp_status===0 && remainDays(varProductData.rp_edate) < 0)?
            <div className="btn_bottom in_my_write2" style={{zIndex:"10"}}>
              <Link className="btn_style_no" to="/">홈</Link>
              <button className="btn_style_yes" onClick={e=>onReregisterDoongjiHandler(e)}>매물등록재개</button>
            </div>
          :
            <div className="btn_bottom in_my_write" style={{zIndex:"10"}}>
              <Link className="btn_style_no" to="/">홈</Link>
              <Link className="btn_style_brown" to={`/Doongji/DoongjiDetail/${rp_idx}`}>수정</Link>
              <button className="btn_style_gray w_del_open" onClick={e=>onPopupDoongjiDeleteHandler(e,true)}>삭제</button>
              {(varProductData.mem_mode===1 && varProductData.rp_status===2)?<Link className="btn_style_premium2" style={{width:"60%"}} to={`/MyPage/RegisterPremium/1/${rp_idx}`}>프리미엄 광고 신청</Link>:null}
            </div>
        }
      </div>
      {/* 팝업창 */}
      <DoongjiDelete varCurrentDoongjiIndex={rp_idx} varPopupDoongjiDelete={varPopupDoongjiDelete} onPopupDoongjiDeleteHandler={onPopupDoongjiDeleteHandler}/>
      <ReportProduct rpIdx={rp_idx} rpMode={1} varPopupReportProduct={varPopupReportProduct} onPopupReportProductHandler={onPopupReportProductHandler}/>
      <SupportMoney varPopupSupportMoney={varPopupSupportMoney} onPopupSupportMoneyHandler={onPopupSupportMoneyHandler}/>
      <MapLocation varCenterPoint={varProductData.rp_location||""} varAddress={varProductData.rp_address1||""} 
                    varMapLocation={varMapLocation} onOpenMapLocationHandler={onOpenMapLocationHandler}/>
    </>
  );
}
export default DoongjiView;