/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from '../../../configs/Configs';

//1단계 변수 설정의 경우 -- varMode=2:상품옵션, varMode=3:건축물용도, varMode=4:건축물내부구조, varMode=11:업종구분
const VariableOneBlock = ({varMode,varItems}) => {

  const itemObj1 = varItems.filter(element=>element.index === varMode)[0];
  const [varParams,setParams] = useState({mode:varMode,index:0,curIndex:varMode,name:''});
  const [varItemList,setItemList] = useState([]);
  const [varSelectedItemIndex,setSelectedItemIndex] = useState(0);
  const [varKeyInItemName,setKeyInItemName] = useState('');
  
  //변수항목 가져오기
  const getVariableList = async() => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_variables`,varParams).then((res) => {
      if(res.data.retvalue === 1) {
        setItemList(res.data.results);
      }
    });
  }
  //최초실행
  useEffect(()=>{
    getVariableList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varParams.mode]);

  //항목선택
  const SelectVariableItemHandler = (e) => {
    const selectObj = e.currentTarget;
    const selectedItemIndex = Number(selectObj.value);
    if(selectedItemIndex === 0) return false;
    setSelectedItemIndex(selectedItemIndex);
    setKeyInItemName(selectObj.options[selectObj.selectedIndex].text);
    setParams({...varParams, index:selectedItemIndex});
  }
  //항목입력
  const keyinVariableItemHandler = (e) => {
    const keyInString = e.currentTarget.value;
    setKeyInItemName(keyInString);
    setParams({...varParams, name:keyInString});
  }
  //항목삭제
  const deleteVariableItemHandler = async (e) => {
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_variables`,varParams).then((res) => {
      console.log(res.data);
      alert(res.data.message);
      if(res.data.retvalue === 1) {
        const newLists = varItemList.filter(element => element[itemObj1.ids] !== varParams.index);
        setItemList(newLists);
        //최초값으로 재설정
        if(newLists?.length > 0) {
          const newSelectItemIndex = newLists[0][itemObj1.ids];
          setSelectedItemIndex(newSelectItemIndex);
        }
      }
    });
  }
  //항목업데이트
  const updateVariableItemHandler = async (e) => {
    if(varParams.name === "") {
      alert('등록할 변수항목이 입력되어 있지 않습니다.');
      return false;
    }
    await axios.post(`${CONFIG.SERVER_HOST}/_update_variables`,varParams).then((res) => {
      console.log(res.data);
      alert(res.data.message);
      if(res.data.retvalue === 1) {
        if(varParams.index === 0) { //신규추가
          const newIndex = res.data.newIndex;
          const indexField = itemObj1.ids;
          const nameField = itemObj1.idn;
          const newData = {[indexField]:newIndex,[nameField]:varParams.name};
          setItemList(varItemList.concat(newData));
          //추가된 항목을 선택
          setSelectedItemIndex(newIndex); //현재 선택된 항목(추가된 항목)
          setKeyInItemName(''); //입력창 초기화
        } else {  //변경
          const findIndex = varItemList.findIndex(element => element[itemObj1.ids] === varParams.index);
          let copyLists = [...varItemList];
          copyLists[findIndex] = {...copyLists[findIndex], name:varParams.name};
          setItemList(copyLists);
          setKeyInItemName(''); //입력창 초기화
        }
      }
    });
  }
  //초기화 - 추가를 위해
  const InitializeVariableItemHandler = (e) => {
    setSelectedItemIndex(0);
    setKeyInItemName('');
    setParams({...varParams, index:0});
    document.getElementById('keyinItem').focus();
  }

  return (
    <tr>
      <th>{itemObj1.title}</th>
      <td className="select-items">
        <select size="5" onChange={(e)=>SelectVariableItemHandler(e,1)} value={varSelectedItemIndex}>
          <option value={0}>아래의 항목을 선택하세요</option>
          { varItemList.length > 0 ? varItemList.map((items,i) => (
            <option key={itemObj1.ids+i} value={items[itemObj1.ids]}>{items[itemObj1.idn]}</option>     
          )) : null }
        </select>
      </td>
      <td className="select-addition"><button onClick={(e)=>deleteVariableItemHandler(e,1)} className="btn line">삭제</button></td>
      <td className="select-addition"><button onClick={(e)=>InitializeVariableItemHandler(e,1)} className="btn line">신규</button></td>
      <td className="select-addition sel-label"><label>변수항목</label></td>
      <td className="select-input">
        <input type="text" onChange={(e)=>keyinVariableItemHandler(e,1)} id="keyinItem" value={varKeyInItemName} />
      </td>
      <td className="select-addition2">
        <button onClick={(e)=>updateVariableItemHandler(e,1)} className="btn line" style={{width:"90px"}}>등록하기</button>
      </td>
    </tr>
  );
}
export default VariableOneBlock;