/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link,useNavigate,useParams } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import HeadTitle from '../../components/include/HeadTitle';
import { isPassword } from '../../components/common/Common';

//비밀번호 찾기
const EditPWD = () => {
  let mem_idx = 0;
  const navigate = useNavigate();
  const { backMode } = useParams();
  if(Number(backMode) === 0) mem_idx = Number(localStorage.getItem("foundIdx"));
  else mem_idx = Number(localStorage.getItem("mem_idx"));
  
  const newMember = {mem_idx:mem_idx,mem_pwd:"",mem_pwd2:""};
  const [varMember,setMember] = useState(newMember);

  //입력값 처리
  const onChangeInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    setMember({...varMember,[name]:value});
  }

  //비밀번호 재저장
  const onUpdatePWDHandler = async (e) => {
    if (varMember.mem_pwd === "") {
      alert('비밀번호를 먼저 입력하세요.'); return false;
    } else if (varMember.mem_pwd !== varMember.mem_pwd2) {
      alert('비밀번호와 확인용 비밀번호가 일치하지 않습니다.'); return false;
    } else if(!isPassword(varMember.mem_pwd) || varMember.mem_pwd?.length < 8 || varMember.mem_pwd?.length > 12 ) {
      alert('비밀번호는 영문, 숫자, 특수문자 포함 8~12자리로 입력해주세요.'); return false;
    }
    const sendData = {infos:varMember}
    await axios.post(`${CONFIG.SERVER_HOST}/_update_pwd`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        if(Number(backMode) > 0) {
          localStorage.removeItem("mem_idx");
          localStorage.removeItem("mcm_idx");
          localStorage.removeItem("mem_mode");
          localStorage.removeItem("mem_nickname");
          localStorage.removeItem("memberLoginState");
          localStorage.removeItem("mem_admin");
          localStorage.removeItem("autoLogin");
        }
        navigate('../FoundPWDOK');
      } else {
        alert(datas.message);
      }
    });
  }

  return (
    <>
      <HeadTitle titleString={"비밀번호 변경"}/>
      <div className="pd_wrap02">
        <div className="find_common_wrap find_account_wrap">
          <div className="pp_top_text_tp01">새로운 비밀번호를 입력해주세요.</div>
          <div className="find_bg_gray">
            <div className="center_wrap">
              <div className="form_wrap">
                <div className="tit07">비밀번호</div>
                <input type="password" name="mem_pwd" value={varMember.mem_pwd} placeholder="비밀번호를 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
                <div className="txt_s_red">영문, 숫자, 특수문자 포함 8자리~12자리로 입력해주세요.</div>
              </div>
              <div className="form_wrap">
                <div className="tit07">비밀번호 재확인</div>
                <input type="password" name="mem_pwd2" value={varMember.mem_pwd2} placeholder="확인할 비밀번호를 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
                <div className="txt_s_red">비밀번호와 동일하게 입력해주세요.</div>
              </div>
              <div className="btn_bottom">
                <button className="btn_style_yes" onClick={e=>onUpdatePWDHandler(e)}>확인</button>
                <Link to={Number(backMode)===1?"/Mypage/MyInfo":Number(backMode)===2?"/Mypage/MyPartnerInfo":"/Login"} className="btn_style_no">취소</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditPWD;