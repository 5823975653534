/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import { isObjEmpty } from "../common/Common";
import DetailPriceInfo from '../../components/doongji/DetailPriceInfo';
import DetailPriceMore from '../../components/doongji/DetailPriceMore';

const DetailPrice = ({varProductData,setProductData,varTypesList,onChangeInputHandler,onChangeSelectHandler,onChangeRadioInputHandler}) => {

  return isObjEmpty(varProductData) ? null : (
    <>        
      <div className="tit07_1">금액 정보</div>
      <div className="form_wrap2">
        <div className="tit07_2">거래 유형<span className="star_red"> (필수)</span></div>
        <select name="rp_type" className="one-line" value={varProductData.rp_type} onChange={e=>onChangeSelectHandler(e)}>
          <option value={0}>선택</option>
          {varTypesList.map((types,i)=>(
            <option key={'types_'+i} value={types.vfo_idx}>{types.vfo_name}</option>
          ))}
        </select>
      </div>
      <DetailPriceInfo varProductData={varProductData} setProductData={setProductData} onChangeInputHandler={onChangeInputHandler}/>
      <DetailPriceMore varProductData={varProductData} onChangeInputHandler={onChangeInputHandler} onChangeRadioInputHandler={onChangeRadioInputHandler}/>
    </>
  );
}
export default DetailPrice;