import React, { useState,useEffect } from "react"; 
import CONFIG from '../../configs/Configs';
import { GetPhotoImage } from "../common/Common";
import $ from "jquery";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

import SlidingZoomPhotos from '../popup/SlidingZoomPhotos';

import noSlideImage from "../../images/no_img.png";

const PartnerSlider = ({varPartnerPhoto}) => {
  const [varPhotoImages,setPhotoImages] = useState([]);

  // 이미지 로딩 및 재시도 함수
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // 캐시 방지용 타임스탬프 추가
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            console.warn(
              `Retrying to load image: ${src} (${retries} retries left)`
            );
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

 //페이지 정보 표시 - 최초
 useEffect(() => {
    if (varPartnerPhoto?.length > 0) {
      const initialPhotoImages = varPartnerPhoto.map((photo) => ({
        file_name: photo.file_name,
        src: null,
        loading: true,
      }));
      setPhotoImages(initialPhotoImages);

      varPartnerPhoto.forEach((photo, index) => {
        const sfile = photo.file_name;
        const simage =
          `${CONFIG.SERVER_HOST}/` +
          GetPhotoImage(sfile, 'jangter') +
          `?t=${new Date().getTime()}`;
        loadImageWithRetry(simage)
          .then((loadedImage) => {
            // console.log('Image loaded successfully:', loadedImage);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                src: loadedImage,
                loading: false,
              };
              return newImages;
            });
          })
          .catch((error) => {
            console.error(error.message);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                loading: false,
              };
              return newImages;
            });
          });
      });
    } else {
      setPhotoImages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPartnerPhoto]);

  //매물사진 확대하기-------------------------------------------------------------------
  const [varOpenPhoto,setOpenPhoto] = useState(false);

  const onShowBigSizePhotoHandler = (e) => {
    e.preventDefault();
    setOpenPhoto(true);
    onPopupBackWin(true);
  }
  const onOpenPhotoHandler = (e,bmode) => {
    setOpenPhoto(bmode);
    onPopupBackWin(bmode);
  }
  const onPopupBackWin = (bmode) => {
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }

  return (
    <>
      <div className="swiper-container photo_slide" style={{maxHeight:"430px",height:"400px"}}>
        {/* 매물사진슬라이딩 */}
        <div className="swiper-wrapper imgpopup_btn">
          {varPartnerPhoto?.length === 0?
            <div className="c_wrap" key={'photo_00'}>
              <div className="img_wrap3">
                {varPartnerPhoto?.length === 0?
                  <img src={noSlideImage} alt="" className="only-one-image"/>
                :
                  <div>
                   {varPhotoImages[0]?.loading ? (
                <div className="loading-indicator">
                  <div className="loading-spinner"></div>
                </div>
              ) : (
                <>
                  <img
                    src={varPhotoImages[0]?.src}
                    alt=""
                    className="only-one-image"
                    style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  />
                  {/* <img
                    src={`${CONFIG.SERVER_HOST}/water_m.png`}
                    alt="Watermark"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '95px',
                      height: 'auto',
                      objectFit: 'cover',
                      pointerEvents: 'none',
                    }}
                  /> */}
                </>
              )}
                  </div>
                }
              </div>
            </div>
          :
            <Swiper pagination={{clickable: true}} loop={true} modules={[Pagination]}>
              <div className="swiper-container">
                {varPhotoImages.map((photoObj,j)=>(
                  <SwiperSlide className="swiper-slide" key={'photo_' + j}>
                  <div className="c_wrap">
                    <div
                      className="img_wrap3"
                      style={{ position: 'relative', height: '400px' }}
                      onClick={(e) => onShowBigSizePhotoHandler(e, j)} // 인덱스 j 전달
                    >
                      {photoObj.loading ? (
                        <div className="loading-indicator">
                          <div className="loading-spinner"></div>
                        </div>
                      ) : (
                        <>
                          <img
                            src={photoObj.src}
                            alt=""
                            style={{
                              objectFit: 'cover',
                              width: '100%',
                              height: '100%',
                            }}
                          />
                          {/* <img
                            src={`${CONFIG.SERVER_HOST}/water_m.png`}
                            alt="Watermark"
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '95px',
                              height: 'auto',
                              objectFit: 'cover',
                              pointerEvents: 'none',
                            }}
                          /> */}
                        </>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
                ))}
              </div>
            </Swiper>
          }
        </div>
      </div>
      {/* 이미지확대 팝업창 */}
      <SlidingZoomPhotos varPhotoImages={varPhotoImages} varOpenPhoto={varOpenPhoto} onOpenPhotoHandler={onOpenPhotoHandler}/>
    </>
  );
}
export default PartnerSlider;