/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 

const DetailRushSale = ({varRushSales,varProductData, setProductData}) => {

  const onChangeRushInputHandler =(e) => {
    const { value } = e.currentTarget;
    if(Number(value) === 1) {
      if(varRushSales?.length > 0) {
        if(Number(varProductData.rp_idx)!==Number(varRushSales[0].rp_idx)) {
          alert('급매는 단 한 매물만 적용할 수 있습니다.'); return false;
        }
      } 
    }
    setProductData({...varProductData,'rp_rush_sale':Number(value)});
  }
  
  return (
    <>
      <div className="form_wrap">
        <div className="tit07_1">등록 유형&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
        <ul className="dan2">
          <li className="terms_chk34">
            <input type="radio" name="rp_rush_sale" className="chk1" id="rp_rush_sale0" checked={varProductData.rp_rush_sale===0?true:false} value="0" onChange={e=>onChangeRushInputHandler(e)}/>
            <i></i><label htmlFor="rp_rush_sale0">일반</label>
          </li>
          <li className="terms_chk34">
            <input type="radio" name="rp_rush_sale" className="chk1" id="rp_rush_sale1" checked={varProductData.rp_rush_sale===1?true:false} value="1" onChange={e=>onChangeRushInputHandler(e)}/>
            <i></i><label htmlFor="rp_rush_sale1">급매</label>
          </li>
        </ul>
      </div>
      <div className="txt_gray">급매는 아이디당 1개만 등록이 가능합니다.</div>
    </>
  );
}
export default DetailRushSale