import React, { useState,useEffect,useRef } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';
import removeImage from "../../images/btn_delete_image.png";
import companyPhoto from "../../../images/sub/@thumnail03.png";
import taxPhoto from "../../../images/sub/@thumnail03.png";
import licensePhoto from "../../../images/sub/@thumnail03.png";

import { ChangeDateStringOnlyDate,ChangeMobileFormat,isObjEmpty,SetEmptyInputFile,GetPhotoImage,unescapeHtml } from '../../../components/common/Common';
 
function MemberCompany({varMemberInfo,setMemberInfo,varBusinessList,onMemberInfoHandler,onOpenZipcodeHandler,onShowBigSizePhotoHandler}) {
  const fileRef1= useRef(null);
  const fileRef2= useRef(null);
  const fileRef3= useRef(null);
  
  //사진 파일
  const [varPhoto1,setPhoto1] = useState(companyPhoto);
  const [varPhoto2,setPhoto2] = useState(taxPhoto);
  const [varPhoto3,setPhoto3] = useState(licensePhoto);

  //페이지 정보 표시 - 최초
  useEffect(() => {
    //사진이 존재하면 
    if(varMemberInfo.mcm_image === 1) {
      setPhoto1(`${CONFIG.SERVER_HOST}/`+GetPhotoImage(varMemberInfo.mcm_file,'member'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varMemberInfo.mcm_file]);   
  useEffect(() => {
    //사업자등록증 존재하면
    if(varMemberInfo.mcm_tax_image === 1) {
      setPhoto2(`${CONFIG.SERVER_HOST}/`+GetPhotoImage(varMemberInfo.mcm_tax_file,'member'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varMemberInfo.mcm_tax_file]);  
  useEffect(() => { 
    //부동산자격증 존재하면
    if(varMemberInfo.mcm_broker_image === 1) {
      setPhoto3(`${CONFIG.SERVER_HOST}/`+GetPhotoImage(varMemberInfo.mcm_broker_file,'member'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varMemberInfo.mcm_broker_file]);   

  //사진첨부
  const onAddPhotoHandler = (e,ino) => {
    e.preventDefault();
    if(ino === 1) fileRef1.current.click();
    else if(ino === 2) fileRef2.current.click();
    else fileRef3.current.click();
  }
  //사진 선택후 화면에서 표시
  const onChangePhotoHandler = (e,ino) => {
    e.preventDefault();
    const uploadFile = e.currentTarget.files[0];

    let sname = "";
    if(ino === 1){
      setPhoto1(URL.createObjectURL(uploadFile));
      sname = "mcm_image";
    }  else if(ino === 2) {
      setPhoto2(URL.createObjectURL(uploadFile));
      sname = "mcm_tax_image";
    }  else {
      setPhoto3(URL.createObjectURL(uploadFile));
      sname = "mcm_broker_image";
    }
    setMemberInfo((prevState)=>{return {...prevState, [sname]:1}});
  }

  //사진 삭제
  const onDeletePhotoHandler = async (e,ino) => {
    e.preventDefault();

    //파일버튼 선택 초기화
    const fileBox = document.getElementById('selectFile'+ino);
    SetEmptyInputFile(fileBox);

    let sname = "";
    let sname2 = "";
    if(ino === 1) {
      setPhoto1(companyPhoto);
      sname = "mcm_image";
      sname2 = "mcm_file";
    } else if(ino === 2) {
      setPhoto2(taxPhoto);
      sname = "mcm_tax_image";
      sname2 = "mcm_tax_file";
    } else {
      setPhoto3(licensePhoto);
      sname = "mcm_broker_image";
      sname2 = "mcm_broker_file";
    }

    //정보 배열 적용
    setMemberInfo((prevState)=>{return{...prevState, [sname]:0,[sname2]:''}});
  }

  //중복확인
  const onCheckDuplicationHandler = async (e) => {
    if(varMemberInfo.mem_email === "") {
      alert("아이디(이메일주소)를 먼저 입력하세요."); return false;
    }
    const sendData = {checkField:'mem_email',checkString:varMemberInfo.mem_email};
    await axios.post(`${CONFIG.SERVER_HOST}/_check_duplication`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        setMemberInfo({...varMemberInfo,chk_email:1});
      } else {
        setMemberInfo({...varMemberInfo,chk_email:0,mem_email:''});
      }
      alert(datas.message);
    });
  }
  //경고단계
  const onChangeWarningHandler = (e,imode) => {
    setMemberInfo({...varMemberInfo,mem_warning:imode});
  }
  
  return isObjEmpty(varMemberInfo)?null:(
    <div className="main-box">
      <table className="write">
        <colgroup><col width="20%"/><col width="30%"/><col width="20%"/><col width="30%"/></colgroup>
        <thead></thead>
        <tbody>
          <tr>
            <th>아이디(이메일)</th>
            <td>
              <input type="text" className="email-box" style={{width:"68%"}} name="mem_email" value={varMemberInfo.mem_email||""} onChange={(e)=>onMemberInfoHandler(e)}/>
              <button className={varMemberInfo.chk_email===1?"btn line disable":"btn line"} style={{width:"27%"}} onClick={e=>onCheckDuplicationHandler(e)}>중복체크</button>
            </td>
            <th>등록일자</th>
            <td>{varMemberInfo.mem_idx===0?"":ChangeDateStringOnlyDate(varMemberInfo.mem_rdate)}</td>
          </tr>
          <tr>
            <th>비밀번호</th>
            <td><input type="password" className="password-box" name="mem_pwd" value={varMemberInfo.mem_pwd||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
            <th>비밀번호 재확인</th>
            <td><input type="password" className="password-box" name="mem_pwd2" value={varMemberInfo.mem_pwd2||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
          </tr>   
          <tr>
            <th>업체명</th>
            <td><input type="text" className="name-box" name="mcm_name" value={varMemberInfo.mcm_name||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
            <th>업체 대표 번호</th>
            <td><input type="text" className="mobile-box" name="mcm_phone" value={ChangeMobileFormat(varMemberInfo.mcm_phone)||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
          </tr>  
          <tr>
            <th>사업장주소</th>
            <td colSpan="3">
              <input type="text" name="mcm_zipcode" className="zipcode-box" value={varMemberInfo.mcm_zipcode||""} readOnly/>
              <button onClick={(e)=>onOpenZipcodeHandler(e,true)} style={{width:"90px"}} className="btn line">우편번호</button><br/>
              <input type="text" name="mcm_address1" className="address1-box" value={varMemberInfo.mcm_address1||""} readOnly/>&nbsp;&nbsp;
              <input type="text" name="mcm_address2" className="address2-box" id={"mcm_address2"} value={varMemberInfo.mcm_address2||""} 
                      placeholder="상세 주소를 입력해주세요." onChange={(e)=>onMemberInfoHandler(e)}/>
            </td>
          </tr>  
          {varMemberInfo.mem_mode===1?null:
          <tr>
            <th>업체분류</th>
            <td>
              <select name="mcm_business" value={varMemberInfo.mcm_business} onChange={e=>onMemberInfoHandler(e)}>
                <option value={0}>업종선택</option>
                {varBusinessList.map((biz,i)=>(
                  <option key={'biz_'+i} value={biz.vb_idx}>{biz.vb_name}</option>
                ))}
              </select>
            </td>
            {varMemberInfo.mem_mode!==2?<><th></th><td></td></>:
              <>
                <th>사용중인 이용권</th>
                <td>{varMemberInfo.vouchers}</td>
              </>
            }
          </tr>   
          }
          <tr>
            <th>담당자명</th>
            <td><input type="text" className="name-box" name="mem_name" value={varMemberInfo.mem_name||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
            <th>휴대폰</th>
            <td><input type="text" className="mobile-box" name="mem_mobile" value={ChangeMobileFormat(varMemberInfo.mem_mobile)||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
          </tr> 
          <tr>
            <th>회원경고단계</th>
            <td colSpan={3}>
              <input type="checkbox" id="chk1" name="mem_warning" value={0} checked={varMemberInfo.mem_warning===0} onChange={(e)=>onChangeWarningHandler(e,0)} />
              <label htmlFor="chk1" >없음</label>
              <input type="checkbox" id="chk1" name="mem_warning" value={1} checked={varMemberInfo.mem_warning===1} onChange={(e)=>onChangeWarningHandler(e,1)} />
              <label htmlFor="chk1" >1단계</label>
              <input type="checkbox" id="chk2" name="mem_warning" value={2} checked={varMemberInfo.mem_warning===2} onChange={(e)=>onChangeWarningHandler(e,2)} />
              <label htmlFor="chk1" >2단계</label>
              <input type="checkbox" id="chk3" name="mem_warning" value={3} checked={varMemberInfo.mem_warning===3} onChange={(e)=>onChangeWarningHandler(e,3)} />
              <label htmlFor="chk1" >3단계</label>
              <input type="checkbox" id="chk4" name="mem_warning" value={4} checked={varMemberInfo.mem_warning===4} onChange={(e)=>onChangeWarningHandler(e,4)} />
              <label htmlFor="chk1" >4단계</label>
              <input type="checkbox" id="chk5" name="mem_warning" value={5} checked={varMemberInfo.mem_warning===5} onChange={(e)=>onChangeWarningHandler(e,5)} />
              <label htmlFor="chk1" >5단계</label>
            </td>
          </tr> 
          <tr>
            <th>사업자등록번호</th>
            <td><input type="text" name="mcm_tax_id" value={varMemberInfo.mcm_tax_id||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
            <th>이메일(세금계산서발행용)</th>
            <td><input type="text" name="mcm_tax_email" value={varMemberInfo.mcm_tax_email||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
          </tr> 
          {varMemberInfo.mem_mode===2?null:
            <tr>
              <th>부동산중개사번호</th>
              <td><input type="text" name="mcm_broker_license" value={varMemberInfo.mcm_broker_license||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
            </tr>
          }
          <tr className="image-area">
            <th className="image-title">업체대표사진<br/>
              <button className="btn-danger" style={{width:"90px",marginLeft:"0px",marginTop:"10px"}} onClick={(e)=>onAddPhotoHandler(e,1)}>사진추가</button>
              <input type="file" id="selectFile1" onChange={(e)=>onChangePhotoHandler(e,1)} accept={"image/*"} ref={fileRef1} hidden={true}/>
            </th>
            <td colSpan={3} className="image-block">
              <div className="image-box" id="imgFile1">
                <img className="btn-delete-image" src={removeImage} alt="" onClick={(e)=>onDeletePhotoHandler(e,1)} style={varMemberInfo.mcm_image===1?{display:'block'}:{display:'none'}}/>
                <img className="request-image" src={varPhoto1} alt="" onClick={(e)=>onShowBigSizePhotoHandler(e,varPhoto1)}/>
              </div>
            </td>  
          </tr>
          {varMemberInfo.mem_mode===2?null:
            <tr className="image-area">
              <th className="image-title">사업자등록증<br/>
                <button className="btn line" style={{width:"90px",marginLeft:"0px",marginTop:"10px"}} onClick={(e)=>onAddPhotoHandler(e,2)}>첨부하기</button>
                <input type="file" id="selectFile2" onChange={(e)=>onChangePhotoHandler(e,2)} accept={"image/*"} ref={fileRef2} hidden={true}/>
              </th>
              <td className="image-block">
                <div className="image-box" id="imgFile2">
                  <img className="btn-delete-image" src={removeImage} alt="" onClick={(e)=>onDeletePhotoHandler(e,2)} style={varMemberInfo.mcm_tax_image===1?{display:'block'}:{display:'none'}}/>
                  <img className="request-image" src={varPhoto2} alt="" onClick={(e)=>onShowBigSizePhotoHandler(e,varPhoto2)}/>
                </div>
              </td>  
              <th className="image-title">공인중개사자격증<br/>
                <button className="btn line" style={{width:"90px",marginLeft:"0px",marginTop:"10px"}} onClick={(e)=>onAddPhotoHandler(e,3)}>첨부하기</button>
                <input type="file" id="selectFile3" onChange={(e)=>onChangePhotoHandler(e,3)} accept={"image/*"} ref={fileRef3} hidden={true}/>
              </th>
              <td className="image-block">
                <div className="image-box" id="imgFile3">
                  <img className="btn-delete-image" src={removeImage} alt="" onClick={(e)=>onDeletePhotoHandler(e,3)} style={varMemberInfo.mcm_broker_image===1?{display:'block'}:{display:'none'}}/>
                  <img className="request-image" src={varPhoto3} alt="" onClick={(e)=>onShowBigSizePhotoHandler(e,varPhoto3)}/>
                </div>
              </td>  
            </tr>
          }
          <tr>
            <th>업체 소개글</th>
            <td colSpan="3">
              <textarea name="mcm_introduce" value={varMemberInfo.mcm_introduce} style={{maxWidth:"100%"}} onChange={(e)=>onMemberInfoHandler(e)}/>
            </td>
          </tr>
          <tr>
            <th>관리자 메모</th>
            <td colSpan={3}>
              <textarea name="mem_memo" value={unescapeHtml(varMemberInfo.mem_memo)||""} onChange={(e)=>onMemberInfoHandler(e)}/>
            </td>
          </tr> 
          <tr>
            <th>업체 승인</th>
            <td colSpan="3">
              <select name="mcm_approval" value={varMemberInfo.mcm_approval} style={{width:"130px"}} onChange={(e)=>onMemberInfoHandler(e)}>
                <option value={0}>미승인</option>
                <option value={1}>사용승인</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default MemberCompany;