/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import PremiumList from "../../components/products/PremiumList";
import PremiumDetail from "../../components/products/PremiumDetail";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import "../../admin_css/board.css";

/* 요금/이용권 관리 */
const ProductAdvertise = () => {
  //메뉴항목지정
  const [varMenuItem,setMenuItem] = useState('');

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'PRODUCTS'}/>
      <Menus varMenuType={'PRODUCTS'} varMenuItem={varMenuItem}/>
      <Routes>
        {/* 프레미엄 광고 리스트 */}
        <Route path="PremiumList/:rpa_mode" element={<PremiumList setMenuItem={setMenuItem}/>} />
        {/* 프레미엄 광고 정보 관리 */}
        <Route path="PremiumDetail/:rpa_mode/:rpa_idx" element={<PremiumDetail setMenuItem={setMenuItem} />} />
      </Routes>
    </>
  );
}
export default ProductAdvertise;

