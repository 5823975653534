import React, { useState, useEffect } from "react";
import { useParams,useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import arrImage from "../../images/arr.png";
import iconhome from "../../images/icon-home.png";

import { escapeHtml,unescapeHtml } from "../../../components/common/Common";

//게시판 정보 편집
function BoardDetail({varMenuItem,setMenuItem}) {
  const admin_idx = Number(localStorage.getItem('adminIdx'));
  const admin_mode = localStorage.getItem("adminMode"); //1:운영자
  let { mode,board_idx } = useParams();
  const navigate = useNavigate();

  //게시판 종류
  const [varBoardMode,setBoardMode] = useState(0);
  const [varMenuTitle,setMenuTitle] = useState('');
  //게시판 변수 저장 객체
  const newData = {      
    board_idx: 0,
    board_num:0,
    board_read: 0,
    board_mem_idx: admin_idx,
    quest_idx:admin_idx,
    answer_idx:admin_idx,
    quest_name: "",
    answer_name: "",
    board_title: "",
    board_comment: "",
    board_answer: "",
    board_rdate: null,
    board_adate: null,
    board_hits: 0
  };

  //게시판정보
  const [varBoardInfo,setBoardInfo] = useState({});
  //글작성자 회원번호
  const [varWriterIndex,setWriterIndex] = useState(admin_idx);

  //게시판정보 읽어오기
  const getBoardInformation = async (imode) => {
    //신규게시판
    console.log('mode',mode,board_idx);
    if(Number(board_idx) === 0) {
      setBoardInfo(newData);
    //게시판 정보를 서버로부터 읽어오기
    } else {
      var sendData = {mode: imode,board_idx: board_idx};
      console.log(sendData);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_board_information`,sendData).then((res) => {
        const datas = res.data;
        console.log(datas);
        if(datas.retvalue === 1) {
          let copyData = datas.results;
          copyData.board_comment = unescapeHtml(copyData.board_comment);
          if(imode === 3) { //1:1문의
            setWriterIndex(copyData.quest_idx);
            copyData.board_answer = unescapeHtml(copyData.board_answer);
          } else {
            setWriterIndex(copyData.board_mem_idx);
          }
          setBoardInfo(copyData);
        }
      });
    }
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    let itemString = "";
    let itemTitle = "";
    switch(Number(mode)) {
      default:
      case 1:
        itemString = "BoardNotice";
        itemTitle = "공지사항";
        break;
      case 2:
        itemString = "BoardFAQ";
        itemTitle = "FAQ";
        break;
      case 3:
        itemString = "BoardOneToOne";
        itemTitle = "1:1문의";
        break;
    }
    setMenuItem(itemString);
    setMenuTitle(itemTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setBoardMode(Number(mode));
    getBoardInformation(Number(mode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode,board_idx]);

  //회원정보편집
  const onBoardInfoHandler = (e) => {
    const {name,value} = e.currentTarget;
    setBoardInfo({...varBoardInfo, [name]:value});
  }

  //게시판정보 저장
  const onUpdateBoardHandler = async (e) => {
    let smess = "";
    let smess_push = "";
    switch(Number(mode)) {
      default:
      case 1: 
        smess="공지사항을 등록하시겠습니까?";
        smess_push="사용자들에게 공지사항을 발송합니까?";
        break;
      case 2: 
        smess="FAQ를 등록하시겠습니까?";
        break;
      case 3: 
        smess="1:1 답변을 등록하시겠습니까?";
        smess_push="사용자에게 답변에 대한 메시지를 발송합니까?";
        break;
    }
    if(!window.confirm(smess)) return false;
    //문자열변경
    let copyData = {...varBoardInfo};
    copyData.board_comment = escapeHtml(copyData.board_comment);
    if(varBoardMode === 3) {
      copyData.board_answer = escapeHtml(copyData.board_answer);
    }
    const sendData = {mode: varBoardMode,answer_idx: admin_idx,boardInfo: copyData};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_board_information`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        if(varPushMessage && (varBoardMode===1 || (varBoardMode===3 && copyData.board_answer!==""))) {
          if(window.confirm(smess_push)) {
            if(varBoardMode === 1)
              SendPushMessage(1,0);
            else 
              SendPushMessage(3,varBoardInfo.quest_idx);
          }
        }
        //목록페이지로 이동
        navigate(`/Admin/Board/${varMenuItem}`);
      } else {
        alert(datas.message);
      }
    });
  }

  //필독
  const onSelectCheckedHandler = (e) => {
    const bChecked = Number(e.currentTarget.checked);
    console.log(bChecked);
    setBoardInfo({...varBoardInfo, board_read:bChecked});
  }

  //푸쉬---
  const [varPushMessage,setPushMessage] = useState(false);
  const onCheckedPushHandler = (e) => {
    setPushMessage(e.currentTarget.checked);
  }
  //메시지발송 - 공지사항
  const SendPushMessage = async(imode,ioppidx) => {
    const sendData = {pushMode: imode,oppIdx: ioppidx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_send_push_message`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        navigate(`/Admin/Board/${varMenuItem}`);
      } else {
        alert(datas.message);
      }
    });
  }
  //글삭제
  const onDeleteBoardIndormation = async(e) => {
    if(!window.confirm('이 글을 삭제합니까?')) return false;
    
    const sendData = {mode: varBoardMode,board_idx: board_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_board_information`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        navigate(`/Admin/Board/${varMenuItem}`);
      } else {
        alert(datas.message);
      }
    });    
  }

  return (
    <>          
      <div id="contents" className="ad_contents">
        <div className="tit">
          <div className="title">{varMenuTitle}</div>
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arrImage} alt="" />앱 게시판 관리<img src={arrImage} alt="" />{varMenuTitle}
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="write">
              <colgroup>
                <col width="20%"></col>
                <col width="80%"></col>
              </colgroup>
              <thead></thead>
              <tbody>
                <tr>
                  <th>제목</th>
                  <td><input type="text" name="board_title" value={varBoardInfo.board_title||""} onChange={(e)=>onBoardInfoHandler(e)}/></td>
                </tr>
                <tr>
                  <th>{varBoardMode === 3 ? '질문':'내용'}</th>
                  <td><textarea name="board_comment" style={{maxWidth:"100%"}} value={varBoardInfo.board_comment||""} onChange={(e)=>onBoardInfoHandler(e)}/></td>
                </tr>
                {varBoardMode !== 3 ? null :
                  <tr>
                    <th>답변</th>
                    <td><textarea name="board_answer" style={{maxWidth:"100%"}} value={varBoardInfo.board_answer||""} onChange={(e)=>onBoardInfoHandler(e)}/></td>
                  </tr>
                }
                {varBoardMode !== 1 ? null :
                  <tr>
                    <th>필독유무</th>
                    <td><input type="checkbox" name="board_read" onClick={(e)=>onSelectCheckedHandler(e)} defaultChecked={varBoardInfo.board_read}/>&nbsp;필독</td>
                  </tr>
                }
                {varBoardMode === 2 ? null :
                  <tr>
                    <th>PUSH 발송유무</th>
                    <td><input type="checkbox" name="board_push" onClick={(e)=>onCheckedPushHandler(e)}/>&nbsp;{(varBoardInfo.board_idx===0 || varBoardInfo.answer_idx===0)?"발송":"재발송"}</td>
                  </tr>
                }
              </tbody>
            </table>
            <div className="btn-center">
              <button className="btn line black" style={{width:"100px"}} onClick={(e)=>onUpdateBoardHandler(e)}>저장</button>
              {(admin_mode===1||admin_idx===varWriterIndex) && Number(board_idx)>0?<button onClick={(e)=>onDeleteBoardIndormation(e)} style={{width:"100px"}} className="btn line">삭제</button>:null}
              <Link to={`/Admin/Board/${varMenuItem}`} style={{width:"100px"}} className="btn line">목록</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BoardDetail;