/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Routes,Route } from "react-router-dom";

import Header from "../../components/include/Header";

import JoinMember from "./JoinMember";
import JoinPartner from "./JoinPartner";
import JoinMemberOK from "./JoinMemberOK";
import JoinPartnerOK from "./JoinPartnerOK";

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const JoinUs = () => {
  return (
    <>
    <Header/>
    <div id="content">
      <Routes>
        <Route path="/" element={<JoinMember />} />
        <Route path="JoinMember" element={<JoinMember />} />
        <Route path="JoinMemberOK" element={<JoinMemberOK />} />
        <Route path="JoinPartner/:joinMode" element={<JoinPartner />} />
        <Route path="JoinPartnerOK" element={<JoinPartnerOK />} />
      </Routes>
    </div>
  </>
  );
}
export default JoinUs;