import React, { useState,useEffect } from "react"; 
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { isNull,isObjEmpty,ChangeMobileFormat,MakeStringToArray,MakeArrayToString,remainDays,GetPhotoImage } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import DetailPhotos from '../../components/doongji/DetailPhotos';
import ShowBigSizePhoto from '../../components/popup/ShowBigSizePhoto';

import callIcon from "../../images/icon_call_gray.png";
import addressIcon from "../../images/icon_map_gray.png";

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

//장터 등록하기
const RegisterPartner = () => {
  const navigate = useNavigate();
  const mem_idx = Number(localStorage.getItem("mem_idx"));

  const [varPartnerData,setPartnerData] = useState({});
  const [varBusinessList,setBusinessList] = useState([]);
  const [varSelectedBusiness,setSelectedBusiness] = useState([]);
  //이용권보유에 대한 유효성 결과
  const [varRegisterPartner,setRegisterPartner] = useState(1);
  
  const [varProductPhotos,setProductPhotos] = useState([]); //이미 저장된 매물사진 목록
  const [varTempProductPhotos,setTempProductPhotos] = useState([]); //신규 매물 사진 목록 - 선택된 사진 저장 배열
  const [varTempProductPhotoFiles,setTempProductPhotoFiles] = useState([]); //신규 매물 사진 정보 목록 - 선택된 사진 정보 저장 배열
  const [varEmptyProductPhotos,setEmptyProductPhotos] = useState([]); //신규 매물 사진 목록 - 비어 있는 사진 저장 배열
  //보유이용권정보
  const [varHavingAccount,setHavingAccount] = useState({slot:0,expdate:null});

  // 중복제출 방지
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 로딩 상태 관리
  const [isLoading, setIsLoading] = useState(false);

  const [imageSrc, setImageSrc] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);

  //장터등록을 위한 정보 호출하기
  const getPartnerInformation = async () => {
    const sendData = {mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_my_partner_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      let photoMax = 15;
      if(datas.retvalue === 1 && datas.results?.length > 0) {
        let pData = datas.results[0];
        //console.log(pData.jp_idx);
        let photos = [];
        if(!isNull(pData.jp_idx)) photos = datas.photos.filter(el=>el.jp_idx===pData.jp_idx);
        setProductPhotos(photos);

        //파트너스의 선택 가능한 종목 
        const vb_idx = pData.mcm_business;
        const selBizList = datas.buseinss.filter(element=>element.vb_idx===vb_idx);
        setBusinessList(selBizList);

        //매물사진정보
        photoMax = photoMax - photos?.length;
        //매물사진 15개까지 등록가능 --> 빈 공간 저장배열 만들기
        const photoData = {file_idx:0,file_name:"",file_preview:""};
        let photoArray = [];
        for(var i=0; i<photoMax; i++) {
          photoArray.push(photoData);
        }
        setEmptyProductPhotos(photoArray);

        //유효성 검토
        const remaindays = remainDays(pData.jangter_expdate);
        console.log(pData.jp_status,pData.jangter_slots,remaindays);
        //if(isNull(pData.jp_idx) || pData.jp_status < 2) {
          if(pData.jangter_slots === 0 || remaindays < 0) {
            setRegisterPartner(0);  //이용권 없음
            alert('보유한 이용권이 없습니다. 먼저 이용권을 구매하세요.');
            navigate('/MyPage/BuyVoucher/0');
          }
        //}

        //보유이용권정보
        setHavingAccount({slot:pData.jangter_slots,expdate:pData.jangter_expdate});

        if(isNull(pData.jp_idx) || pData.jp_idx === 0) {
          pData.jp_idx = 0; pData.jp_show = 0;
        } 
        setPartnerData(pData);

        //장터 파트너스로 등록되어 있는지 확인
        if(isNull(pData.jp_idx) || pData.jp_idx === 0) {
          setRegisterPartner(0);
          //등록된 파트너스 광고 정보가 임시이면 - 무통장입금 --> 임시 요청된 슬럿수 읽기
          //GetJangterPartnerSlot();
        } else {
          const jpnames = pData.jp_names;
          const namesArray = MakeStringToArray(jpnames);
          setSelectedBusiness(namesArray);
        } 
      }
    });
  }

  /* //임시 요청된 슬럿수 읽기
  const GetJangterPartnerSlot = async() => {
    const sendData = {mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_my_partner_slots`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1 && datas.results.length > 0) {
        const accounts = datas.results[0];
        const expdate = ResetDates(accounts.ph_rdate,1,accounts.terms);
        setHavingAccount({slot:accounts.vo_slot,expdate:expdate});
      } else {
        alert('장터 파트너스 광고를 위해 신청된 이용권 구매 정보가 없습니다. 이용권을 먼저 구입 신청하세요.');
        //navigate('/MyPage/BuyVoucher/0');
      }
    });
  } */

  //페이지 정보 표시 - 최초
  useEffect(() => {
    //document.getElementById('main_body').style.backgroundColor = '#FFFFFF';
    getPartnerInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const photoImage = varPartnerData.mcm_file || 'default_member.png';
    const imageUrl = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(photoImage, 'member') + `?t=${new Date().getTime()}`;
    setImageLoading(true);
    loadImageWithRetry(imageUrl)
      .then((loadedImage) => {
        setImageSrc(loadedImage);
        setImageLoading(false);
      })
      .catch((error) => {
        console.error(error.message);
        setImageLoading(false);
      });
  }, [varPartnerData.mcm_file]);

  //파트너스 장터 등록
  const onRegisterPartnerHandler = async (e) => {
    /* if(varPartnerData.jangter_slots === 0) {
      alert('보유한 이용권이 없습니다. 먼저 이용권을 구매하세요.'); return false;
    } */
    if(varSelectedBusiness?.length === 0) {
      alert('게시할 분야를 먼저 선택해주세요.');return false;
    }
    console.log(varRegisterPartner);
    if(!window.confirm('파트너스 광고 정보를 등록하시겠습니까?')) return false;

    if (isSubmitting) return; // 이미 제출 중이면 중복 제출 방지
    setIsSubmitting(true); // 제출 중으로 변경
    showLoading();

    //서버로 업로드할 매물사진을 배열로 저장
    let PhotoForm = new FormData();
    for(var i=0; i < varTempProductPhotoFiles?.length; i++) {
      let formData = varTempProductPhotoFiles[i];
      PhotoForm.append('jangter',formData.get('jangter'));
    }
    console.log('추가된 매물 사진 서버로 업로드 등록',varTempProductPhotoFiles?.length);
    //추가된 매물 사진 배열을 서버로 업로드 등록
    if(varTempProductPhotoFiles?.length > 0) {
      await axios.post(`${CONFIG.SERVER_HOST}/jangterArray`, PhotoForm).then((res) => {
        console.log(res.data);
        if(res.data.retvalue === 1) {
          const fileNames = res.data.fileNames; //배열
          console.log(fileNames);
          //매물사진 DB 등록
          hideLoading();
          RegisterJangterPartner(fileNames);
        }
      });
    } else {  //등록할 매물사진이 없는 경우
      hideLoading();
      RegisterJangterPartner("");
    }
  }
  //서버의 데이타베이스로 매물 정보를 저장
  const RegisterJangterPartner = async(fileNames) => {
    const sendData = {Infos:varPartnerData,useMode:varRegisterPartner};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_my_partner_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      alert(datas.message);
      if(datas.retvalue === 1) {
        const jp_idx = datas.jp_idx;
        if(fileNames!=="") {
          const sendData2 = {jp_idx:jp_idx,fileNames:fileNames};
          RegistePhotos(sendData2);
        } else {
          // if(varRegisterPartner === 0) {
          //   navigate('/MyPage/PaymentList');
          // } else {
          //   navigate('/MyPage');
          // }
          navigate('/MyPage');
        }
      }
    });
  }
  const RegistePhotos = async(sendData) => {
    console.log('sendData',sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_register_my_partner_photos`, sendData).then((res) => {
      // if(varRegisterPartner === 0) {
      //   navigate('/MyPage/PaymentList');
      // } else {
      //   navigate('/MyPage');
      // }
      navigate('/MyPage');
    });
  }
  //게시유무
  const onChangeShowHandler = (e) => {
    const { name,value } = e.currentTarget;
    if (varSelectedBusiness.length === 0 && Number(value) === 1) {
      alert('카테고리가 선택되지 않았습니다. 게시중으로 변경하려면 카테고리를 선택하세요.');
      return;
    }
    setPartnerData({...varPartnerData, [name]:Number(value)});
  }
  //선택
  const onSelectBussinessHandler = (e,vbd_name) => {
    const bmode = e.currentTarget.checked;
    let haveSlot = varHavingAccount.slot;
    let expdate = varHavingAccount.expdate;
    let remaindays = remainDays(expdate);
    console.log(haveSlot);
    //console.log('remaindays',remaindays);
    if((haveSlot === 0 || (remaindays < 0 && varPartnerData.jp_idx > 0))) {
      // alert('보유한 이용권이 없습니다. 먼저 이용권을 구매하세요.'); return false;
      alert('보유한 이용권이 없습니다. 이용권을 구매하세요');
      navigate('/MyPage/BuyVoucher/0');
      return false;
      
    }
    if(bmode) {
      //이미 선택한 것인지 확인 --> 없으면
      if(!varSelectedBusiness.includes(vbd_name)) {
        if(varSelectedBusiness?.length === haveSlot) {
          alert(`이미 보유한 수량을 넘어서는 선택입니다. 선택할 수 없습니다. \n보유수량 : ${haveSlot}개`);
          e.currentTarget.checked = false;
          return false;
        } else {
          //배열에 추가
          let newData1 = [...varSelectedBusiness];
          newData1.push(vbd_name);
          setSelectedBusiness(newData1); 
          setPartnerData({...varPartnerData, jp_names:MakeArrayToString(newData1)});
          console.log(newData1);
        }
      }
    } else {  //선택취소이면 
      if(varSelectedBusiness.includes(vbd_name)) {
        //배열에서 삭제
        const newData2 = varSelectedBusiness.filter(element=>element!==vbd_name);
        setSelectedBusiness(newData2); 
        setPartnerData({...varPartnerData, jp_names:MakeArrayToString(newData2)});
        console.log(newData2);
      }
    }
  }

  //매물사진 확대하기
  const [varOpenPhoto,setOpenPhoto] = useState(false);
  const [varPhotoImage,setPhotoImage] = useState(null);
  const onShowBigSizePhotoHandler = (e,files) => {
    e.preventDefault();
    setPhotoImage(files);
    setOpenPhoto(true);
  }
  const onOpenPhotoHandler = (e,bmode) => {
    setOpenPhoto(bmode);
  }

  // 로딩 표시 함수
  const showLoading = () => {
    setIsLoading(true);
  }

  const hideLoading = () => {
    setIsLoading(false);
  }

  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // 캐시 방지용 타임스탬프 추가
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  return isObjEmpty(varPartnerData)?null:(
    <>
      {/* 로딩 화면 */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-indicator2">
            <div className="loader"></div>
          </div>
        </div>
      )}
      <Header/>
      <HeadTitle titleString={"내 장터 관리"} backString={'/MyPage'}/>
      <div id="content">
        <div className="pd_btm100">
          <div className="my_market_wrap" >
            <div className="list_type02" style={{marginBottom:"0px"}}>
              <div className="mk_con"> 
                <div className="name_wrap">
                  <div className="center_wrap">
                    <div className="tit03"><span className="btn_st_A">{varPartnerData.mcm_dongname}</span>&nbsp;{varPartnerData.mcm_name}</div>
                  </div>
                </div>
                <div className="info_wrap">
                  <div className="img_wrap" style={{position:"relative"}}>
                    {imageLoading ? (
                      <div className="loading-indicator">
                        <div className="loading-spinner"></div>
                      </div>
                    ) : (
                      <img src={imageSrc} alt=""/>
                    )}
                  </div>
                  <div className="info_txt">
                    <div className="location"><img src={addressIcon} alt=""/> {varPartnerData.mcm_address1+" "+varPartnerData.mcm_address2}</div>
                    <div className="call"><img src={callIcon} alt=""/>{ChangeMobileFormat(varPartnerData.mcm_phone)}</div>
                    <div className="desc">
                        <span>{varPartnerData.mcm_introduce}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="center_wrap">
              <div className="btm_info_wrap">   
                <div className="con">
                  <div className="tit07_3" style={{marginTop:"10px"}}>등록장터</div>
                  <div className="txt_gray">　* 보유하고 계신 이용권에 따라 카테고리 노출이 가능합니다.</div>
                  <div className="form_wrap">
                    <div className="tit04">&nbsp;{varPartnerData.vb_name}</div>     
                    <ul className="dan3">       
                      {varBusinessList.map((bizopt,i)=>(
                        <li className="terms_chks" key={'bizopt_'+i}>
                          <input type="checkbox" name="bizopt" className="chk1" id={"bizopt_"+bizopt.vbd_idx} value={bizopt.vbd_idx} 
                                checked={isNull(varPartnerData.jp_names)?false:varPartnerData.jp_names.includes(bizopt.vbd_name)?true:false}
                                onChange={e=>onSelectBussinessHandler(e,bizopt.vbd_name)}/>
                          <i></i><label htmlFor={"bizopt_"+bizopt.vbd_idx}>{bizopt.vbd_name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="form_wrap">
                    <div className="tit07_3">게시 상태 변경</div>
                    <div className="dan3">
                      <li className="terms_chk35">
                        <input type="radio" name="jp_show" className="chk1" id="jp_show1" value="1" 
                            checked={varPartnerData.jp_show===1} onChange={e=>onChangeShowHandler(e)}/>
                        <i></i><label htmlFor="jp_show1">게시중</label>
                      </li>
                      <li className="terms_chk35">
                        <input type="radio" name="jp_show" className="chk1" id="jp_show0" value="0" 
                            checked={varPartnerData.jp_show===0} onChange={e=>onChangeShowHandler(e)}/>
                        <i></i><label htmlFor="jp_show0">게시중지</label>
                      </li>
                    </div>
                  </div>
                  {/* 장터파트너스 사진 */}
                  <DetailPhotos varMode={22} photoMax={15}
                  varProductPhotos={varProductPhotos} setProductPhotos={setProductPhotos}
                  varTempProductPhotos={varTempProductPhotos} setTempProductPhotos={setTempProductPhotos}
                  varTempProductPhotoFiles={varTempProductPhotoFiles} setTempProductPhotoFiles={setTempProductPhotoFiles}
                  varEmptyProductPhotos={varEmptyProductPhotos} setEmptyProductPhotos={setEmptyProductPhotos}
                  onShowBigSizePhotoHandler={onShowBigSizePhotoHandler}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_bottom one_btn">
          <button className="btn_style_brown" onClick={e=>onRegisterPartnerHandler(e)}>장터 등록</button>
          {varPartnerData.jp_idx === 0 || varPartnerData.jp_show===0 || varPartnerData.jp_status < 2? null:
            <Link className="btn_style_yes" to={`/MyPage/RegisterPremium/2/${varPartnerData.jp_idx}`}>프리미엄 광고 신청</Link>
          }
        </div>
        {/* 사진확대*/}
        <ShowBigSizePhoto varPhotoImage={varPhotoImage} varOpenPhoto={varOpenPhoto} onOpenPhotoHandler={onOpenPhotoHandler}/>
      </div>
    </>
  );
}
export default RegisterPartner;