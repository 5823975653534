import React, { useState, useEffect } from 'react';
import CONFIG from '../../configs/Configs';
import $ from 'jquery';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

import { GetPhotoImage, isNull } from '../common/Common';

import SlidingZoomPhotos from '../popup/SlidingZoomPhotos';

import ShowYouTube from '../popup/ShowYouTube';

import budongImage from '../../images/flag_budong.png';
import noSlideImage from '../../images/no_img.png';
import youtubeImage from '../../images/youtube_start.jpeg';

const DoongjiSlider = ({ varProductData, varProductPhoto }) => {
  const [varPhotoImages, setPhotoImages] = useState([]);

  // YouTube 존재 여부 확인
  const existYoutube = isNull(varProductData.rp_youtube) ? 0 : 1;

  // 사진 개수
  const photoNum = varProductPhoto?.length;

  // 이미지 로딩 및 재시도 함수
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // 캐시 방지용 타임스탬프 추가
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            // console.warn(
            //   `Retrying to load image: ${src} (${retries} retries left)`
            // );
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  useEffect(() => {
    if (varProductPhoto?.length > 0) {
      const initialPhotoImages = varProductPhoto.map((photo) => ({
        file_name: photo.file_name,
        src: null,
        loading: true,
      }));
      setPhotoImages(initialPhotoImages);

      varProductPhoto.forEach((photo, index) => {
        const sfile = photo.file_name;
        const simage =
          `${CONFIG.SERVER_HOST}/` +
          GetPhotoImage(sfile, 'doongji') +
          `?t=${new Date().getTime()}`;
        loadImageWithRetry(simage)
          .then((loadedImage) => {
            // console.log('Image loaded successfully:', loadedImage);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                src: loadedImage,
                loading: false,
              };
              return newImages;
            });
          })
          .catch((error) => {
            console.error(error.message);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                loading: false,
              };
              return newImages;
            });
          });
      });
    } else {
      setPhotoImages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varProductPhoto]);

  // 매물사진 확대하기
  const [varOpenPhoto, setOpenPhoto] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0); // 현재 선택된 이미지 인덱스

  const onShowBigSizePhotoHandler = (e, index) => {
    e.preventDefault();
    setCurrentPhotoIndex(index); // 현재 이미지 인덱스 설정
    setOpenPhoto(true);
    onPopupBackWin(true);
  };

  const onOpenPhotoHandler = (e, bmode) => {
    setOpenPhoto(bmode);
    onPopupBackWin(bmode);
  };

  const onPopupBackWin = (bmode) => {
    if (bmode) {
      $('.layer-bg').fadeIn('1500');
    } else {
      $('.layer-bg').fadeOut('1500');
    }
  };

  // YouTube 보기
  const [varOpenYoutube, setOpenYoutube] = useState(false);
  const [varYoutubeLink, setYoutubeLink] = useState(null);
  const onShowYoutubeHandler = (e, rp_youtube) => {
    e.preventDefault();
    setYoutubeLink(rp_youtube);
    setOpenYoutube(true);
    onPopupBackWin(true);
  };
  const onOpenYoutubeHandler = (bmode) => {
    setOpenYoutube(bmode);
    onPopupBackWin(bmode);
  };

  return (
    <>
      <div
        className="swiper-container photo_slide"
        style={{ maxHeight: '430px', height: '400px' }}
      >
        {/* 매물사진슬라이딩 */}
        {photoNum === 0 && existYoutube === 0 ? (
          <div className="c_wrap">
            <div className="img_wrap3" style={{ height: '400px' }}>
              <img src={noSlideImage} alt="" className="only-one-image" />
            </div>
          </div>
        ) : photoNum === 1 && existYoutube === 0 ? (
          <div className="c_wrap">
            <div
              className="img_wrap3"
              style={{ position: 'relative', height: '400px' }}
              onClick={(e) => onShowBigSizePhotoHandler(e, 0)} // 인덱스 0 전달
            >
              {varPhotoImages[0]?.loading ? (
                <div className="loading-indicator">
                  <div className="loading-spinner"></div>
                </div>
              ) : (
                <>
                  <img
                    src={varPhotoImages[0]?.src}
                    alt=""
                    className="only-one-image"
                    style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  />
                  {/* <img
                    src={`${CONFIG.SERVER_HOST}/water_m.png`}
                    alt="Watermark"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '95px',
                      height: 'auto',
                      objectFit: 'cover',
                      pointerEvents: 'none',
                    }}
                  /> */}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="swiper-container">
            <div className="swiper-wrapper imgpopup_btn">
              <Swiper
                slidesPerView={1}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                loop={true}
              >
                {existYoutube === 0 ? null : (
                  <SwiperSlide>
                    <div className="c_wrap">
                      <div
                        className="img_wrap3"
                        style={{ position: 'relative', height: '400px' }}
                        onClick={(e) =>
                          onShowYoutubeHandler(e, varProductData.rp_youtube)
                        }
                      >
                        <img src={youtubeImage} alt="" className="only-one-image" />
                      </div>
                    </div>
                  </SwiperSlide>
                )}
                {varPhotoImages.map((photoObj, j) => (
                  <SwiperSlide className="swiper-slide" key={'photo_' + j}>
                    <div className="c_wrap">
                      <div
                        className="img_wrap3"
                        style={{ position: 'relative', height: '400px' }}
                        onClick={(e) => onShowBigSizePhotoHandler(e, j)} // 인덱스 j 전달
                      >
                        {photoObj.loading ? (
                          <div className="loading-indicator">
                            <div className="loading-spinner"></div>
                          </div>
                        ) : (
                          <>
                            <img
                              src={photoObj.src}
                              alt=""
                              style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                              }}
                            />
                            {/* <img
                              src={`${CONFIG.SERVER_HOST}/water_m.png`}
                              alt="Watermark"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '95px',
                                height: 'auto',
                                objectFit: 'cover',
                                pointerEvents: 'none',
                              }}
                            /> */}
                          </>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
        {/* 매물유형표시 */}
        {varProductData.mem_mode !== 1 ? null : (
          <div className="flag_wrap">
            <img src={budongImage} alt="" />
          </div>
        )}
      </div>
      {/* 이미지확대 팝업창 */}
      <SlidingZoomPhotos
        varPhotoImages={varPhotoImages}
        varOpenPhoto={varOpenPhoto}
        onOpenPhotoHandler={onOpenPhotoHandler}
        currentPhotoIndex={currentPhotoIndex} // 인덱스 전달
      />
      {/* YouTube 팝업창 */}
      <ShowYouTube
        varYoutubeLink={varYoutubeLink}
        varOpenYoutube={varOpenYoutube}
        onOpenYoutubeHandler={onOpenYoutubeHandler}
        onPopupBackWin={onPopupBackWin}
      />
    </>
  );
};

export default DoongjiSlider;
