/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import TitleBar from "../layout/TitleBar";
import TabBar from "../layout/TabBar";
import Menus from "../layout/Menus";

/* css */
import "../admin_css/admin_layout.css";
import "../admin_css/admin.css";

const MainAdmin = () => {
  const adminLoginState = Number(localStorage.getItem('adminLoginState'));
  //console.log(adminLoginState);
  if(adminLoginState === 0) {
    window.location.href = "/Admin/Login";
  }

  return (
    <div>
      <TitleBar/>
      <TabBar varMenuType={''}/>
      <Menus varMenuType={''} varMenuItem={''} />
      <div>
        {/* 이미지표시 */}
      </div>
    </div>
  );
}
export default MainAdmin;