import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import ApprovalSearch from "./ApprovalSearch";
import ApprovalSearchResult from "./ApprovalSearchResult";
import Paging from "../../layout/Paging";

//미승인 파트너스 승인
function MemberApproval({setMenuItem}) {
  const admin_region = localStorage.getItem("adminRegion");
  //회원목록
  const [varApprovalLists,setApprovalLists] = useState([]);

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  const [varMaxNumPage, setMaxNumPage] = useState(0);

  //검색
  //const [varApprovalIndex, setApprovalIndex] = useState(0);
  const [varSearchData, setSearchData] = useState({
    sdate:null,
    edate:null,
    searchOption:"all",
    searchString:''
  });

  //회원목록 읽어오기
  const getMemberApprovalList = async () => {
    const sendData = {admin_region:admin_region,pageNum:varPageNum, pageSize:varPageSize, searchData:varSearchData};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_approval_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setTotalCount(datas.totalcount);
        setApprovalLists(datas.results);
        setMaxNumPage(datas.totalcount-(varPageNum-1)*varPageSize);
      } else {
        setTotalCount(0);
        setApprovalLists([]);
        setMaxNumPage(0);
      }
    });
  }
  
  //페이지 정보 표시 - 최초
  useEffect(() => {
    setPageNum(1); // 검색 조건이 변경될 때 페이지 번호를 1로 초기화
  }, [varSearchData]);

  useEffect(() => {
    setMenuItem('MemberApproval');
    getMemberApprovalList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum,varSearchData]);

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">파트너스 회원 승인</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>회원 관리<img src={arrImage} alt=""/>파트너스 승인<img src={arrImage} alt=""/>파트너스 회원 승인
        </div>
      </div>
      <div className="content">
        <ApprovalSearch varTotalCount={varTotalCount} varSearchData={varSearchData} setSearchData={setSearchData} />
        <ApprovalSearchResult varMaxNumPage={varMaxNumPage} varApprovalLists={varApprovalLists} getMemberApprovalList={getMemberApprovalList} />
        <div className="paging" style={{ marginBottom: "20px" }}>
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </div>
      </div>
    </div>
  )
}

export default MemberApproval;
