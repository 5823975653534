/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import { Link } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import DaumPostCode from "../../components/popup/DaumPostCode";
import ShowBigSizePhoto from '../../components/popup/ShowBigSizePhoto';
import DetailPhotos from '../../components/doongji/DetailPhotos';

import { ChangeMobileNumber,ChangeMobileFormat,isObjEmpty,onlyNumber } from "../../components/common/Common";

//건물 관리 의뢰합니다
const DoongjiManagement = () => {
  const mem_idx = localStorage.getItem("mem_idx");
  const [varProductData,setProductData] = useState({});
  //매물종류목록
  const [varSeriousList,setSeriousList] = useState([]);
  const [varProductPhotos,setProductPhotos] = useState([]); //이미 저장된 매물사진 목록
  const [varTempProductPhotos,setTempProductPhotos] = useState([]); //신규 매물 사진 목록 - 선택된 사진 저장 배열
  const [varTempProductPhotoFiles,setTempProductPhotoFiles] = useState([]); //신규 매물 사진 정보 목록 - 선택된 사진 정보 저장 배열
  const [varEmptyProductPhotos,setEmptyProductPhotos] = useState([]); //신규 매물 사진 목록 - 비어 있는 사진 저장 배열

  // 중복제출 방지
  const [isSubmitting, setIsSubmitting] = useState(false);

  //매물정보 저장 객체
  const newData = {ri_idx:0,ri_mem_idx:mem_idx,ri_serious:0,ri_zipcode:"",ri_address1:"",ri_address2:"",ri_regcode:"",ri_dongname:"",ri_roadname:"",
                  ri_location:"",ri_title:"",ri_comment:"",ri_phone:""};

  //매물종류목록 읽어오기
  const getProductInformation = async () => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_doongji_serious`).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        setSeriousList(datas.seriouslist);
        //매물사진 15개까지 등록가능 --> 빈 공간 저장배열 만들기
        const photoData = {file_idx:0,file_name:"",file_preview:""};
        let photoMax = 15;
        let photoArray = [];
        for(var i=0; i<photoMax; i++) {
          photoArray.push(photoData);
        }
        setEmptyProductPhotos(photoArray);
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    setProductData(newData);
    getProductInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  //주소찾기----------------------------------------------------------------------
  const [varOpenZipcode,setOpenZipcode] = useState(false);
  //주소찾기팝업창 열기/닫기
  const onOpenZipcodeHandler = (e,bmode) => {
    e.preventDefault();
    setOpenZipcode(bmode);
  }
  //주소관련 정보 업데이트
  const ChangeAddress = (zipcode,address1,regcode,dongName,roadName,locString) => {
    console.log(zipcode,address1,regcode,dongName,roadName,locString);
    setProductData((prevState)=>{return{...prevState,ri_zipcode:zipcode,ri_address1:address1,ri_regcode:regcode,ri_dongname:dongName,ri_roadname:roadName,ri_location:locString}});
  };

  //매물사진 확대하기-------------------------------------------------------------------
  const [varOpenPhoto,setOpenPhoto] = useState(false);
  const [varPhotoImage,setPhotoImage] = useState(null);
  const onShowBigSizePhotoHandler = (e,files) => {
    e.preventDefault();
    setPhotoImage(files);
    setOpenPhoto(true);
  }
  const onOpenPhotoHandler = (e,bmode) => {
    setOpenPhoto(bmode);
  }

  //입력값 저장
  const onChangeInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    if(name === "ri_phone") {
      const svalue = onlyNumber(value);
      setProductData((prevState)=>{return{...prevState,[name]:ChangeMobileNumber(svalue)}});
    } else {
      setProductData((prevState)=>{return{...prevState,[name]:value}});
    }
  }

  //저장하기
  const onRegisterManagementHandler = async(e) => {
    if(varProductData.ri_title === "") {
      alert('신청 제목을 먼저 입력하십시오.'); return false;
    } else if(varProductData.ri_zipcode === "") {
      alert('소재지를 먼저 선택하십시오'); return false;
    } else if(varProductData.ri_serious === 0) {
      alert('매물 종류를 먼저 선택하십시오'); return false;
    }  else if(varProductData.ri_comment === "") {
      alert('의뢰 내용을 먼저 선택하십시오'); return false;
    } else if(varProductData.ri_phone === "") {
      alert('연럭처를 먼저 선택하십시오'); return false;
    }
    if(!window.confirm('전문가에게 관리를 의뢰하시겠습니까?')) return false;
    if (isSubmitting) return; // 이미 제출 중이면 중복 제출 방지
    setIsSubmitting(true); // 제출 중으로 변경

    //서버로 업로드할 매물사진을 배열로 저장
    let PhotoForm = new FormData();;
    for(var i=0; i < varTempProductPhotoFiles?.length; i++) {
      let formData = varTempProductPhotoFiles[i];
      PhotoForm.append('request',formData.get('request'));
    }
    console.log('추가된 매물 사진 서버로 업로드 등록',varTempProductPhotos?.length);
    //추가된 매물 사진 배열을 서버로 업로드 등록
    if(varTempProductPhotos?.length > 0) {
      await axios.post(`${CONFIG.SERVER_HOST}/requestArray`, PhotoForm).then((res) => {
        console.log(res.data);
        if(res.data.retvalue === 1) {
          const fileNames = res.data.fileNames; //배열
          console.log(fileNames);
          //매물사진 DB 등록
          RegisterDoongjiProduct(fileNames);
        }
      });
    } else {  //등록할 매물사진이 없는 경우
      RegisterDoongjiProduct("");
    }
  }
  //서버의 데이타베이스로 매물 정보를 저장
  const RegisterDoongjiProduct = async(fileNames) => {
    let ri_idx = varProductData.ri_idx;
    const sendData = {infos:varProductData};
    console.log('sendData',sendData);
    //매물정보 DB 등록
    await axios.post(`${CONFIG.SERVER_HOST}/_update_doongji_manage`, sendData).then((res) => {
      console.log(res.data);
      if(res.data.retvalue === 1) {
        ri_idx = res.data.ri_idx;
        //매물사진 DB 등록
        if(fileNames!=="") {
          const sendData = {ri_idx:ri_idx,fileNames:fileNames};
          console.log('sendData',sendData);
          axios.post(`${CONFIG.SERVER_HOST}/_register_doongji_manage_photos`, sendData).then((res) => {
            //미리보기
            window.location.href = "/Doongji/DoongjiSeriousOK";
          });
        } else {
          window.location.href = "/Doongji/DoongjiSeriousOK";
        }
      } else {
        alert(res.data.message);
        setIsSubmitting(false); // 제출방지 초기화
      }
    });
  }

  return isObjEmpty(varProductData)?null : (
    <>
      <Header/>
      <HeadTitle titleString={"건물 관리 의뢰합니다"} backString={'/Doongji/DoongjiList'}/>
      <div id="content">
        <div className="pd_wrap03">
          <div className="sell_wrap write_form_wrap">
            <div className="center_wrap">
              {/* 신청 제목 */}
              <div className="form_wrap">
                <div className="tit07_1">신청 제목<span className="star_red"> (필수)</span></div>
                <input type="text" name="ri_title" value={varProductData.ri_title||""} placeholder="신청하려는 매물의 제목을 작성해주세요." onChange={e=>onChangeInputHandler(e)}/>
              </div>
              {/* 매물사진 */}
              <DetailPhotos varMode={4} photoMax={15}
                varProductPhotos={varProductPhotos} setProductPhotos={setProductPhotos}
                varTempProductPhotos={varTempProductPhotos} setTempProductPhotos={setTempProductPhotos}
                varTempProductPhotoFiles={varTempProductPhotoFiles} setTempProductPhotoFiles={setTempProductPhotoFiles}
                varEmptyProductPhotos={varEmptyProductPhotos} setEmptyProductPhotos={setEmptyProductPhotos}
                onShowBigSizePhotoHandler={onShowBigSizePhotoHandler}/>
              <div className="tit07_1">신청 내용</div>
              {/* 매물 주소 */}
              <div className="form_wrap">
                <div className="tit07_2">소재지<span className="star_red"> (필수)</span></div>
                <div className="has_btn form_Address" onClick={e=>onOpenZipcodeHandler(e,true)}>
                  <input type="text" name="ri_zipcode" placeholder="우편번호 입력" value={varProductData.ri_zipcode||""} className="w_s_input back-disbale" readOnly/>
                  <button className="w_s_btn" onClick={(e)=>onOpenZipcodeHandler(e,true)}>우편번호</button>
                </div>
                <div onClick={e=>onOpenZipcodeHandler(e,true)}>
                  <input type="text" name="ri_address1" value={varProductData.ri_address1||""} className="back-disbale" readOnly/>
                </div>
                <input type="text" name="ri_address2" value={varProductData.ri_address2} placeholder="상세주소를 입력하세요." onChange={e=>onChangeInputHandler(e)}/>
              </div>
              {/* 매물종류 */}
              <div className="form_wrap">
                <div className="tit05">매물 종류<span className="star_red"> (필수)</span></div>
                <select name="ri_serious" value={varProductData.ri_serious} onChange={e=>onChangeInputHandler(e)}>
                  <option value={0}>매물종류선택</option>
                  {varSeriousList.map((serious,i)=>(
                    <option key={'serious_'+i} value={serious.vfo_idx}>{serious.vfo_name}</option>
                  ))}
                </select>
              </div>
              {/* 의뢰내용 */}
              <div className="form_wrap">
                <div className="tit05">의뢰 내용<span className="star_red"> (필수)</span></div>
                <textarea name="ri_comment" cols="30" rows="5" style={{maxWidth:'100%',minWidth:'100%',width:'100%'}}
                      placeholder="의뢰 내용을 작성해주세요." value={varProductData.ri_comment} onChange={e=>onChangeInputHandler(e)}/>
              </div>
              {/* 연락처 */}
              <div className="form_wrap">
                <div className="tit05">연락처<span className="star_red"> (필수)</span></div>
                <input type="text" name="ri_phone" value={ChangeMobileFormat(varProductData.ri_phone)} 
                  placeholder="의뢰인의 연락처를 입력해주세요." onChange={e=>onChangeInputHandler(e)}/>
                <div className="txt_gray">건물관리에 관련된 대응이 가능한 관리인 및 담당자의 연락처를 적어주세요.</div>
              </div>
            </div>
            <div className="btm_box">
                <strong>건물 월관리 서비스는<br/>무엇인가요?</strong>
                <p>전문가가 고객님의 소중한 건물을 관리해드립니다.<br/>임대관리부터 청소,인테리어까지 다양한 분야의 관리를 도와드립니다.</p>
            </div>
            <div className="btn_bottom">
              <Link to="/Doongji/DoongjiSerious" className="btn_style_no">취소</Link>
              <button className="btn_style_yes" onClick={e=>onRegisterManagementHandler(e)}>등록</button>
            </div>
          </div>
        </div>
        {/* 사진확대*/}
        <ShowBigSizePhoto varPhotoImage={varPhotoImage} varOpenPhoto={varOpenPhoto} onOpenPhotoHandler={onOpenPhotoHandler}/>
        {/* 우편번호/주소 검색*/}
        <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler}/>
      </div>
    </>
  );
}
export default DoongjiManagement;