import React, {useRef} from "react"; 
import YouTube from 'react-youtube';
import { isEmptyString } from "../common/Common";
import { isNull } from "../common/Common";
import closeIcon from '../../images/icon_close.png';

const ShowYouTube = ({varYoutubeLink,varOpenYoutube,onOpenYoutubeHandler,onPopupBackWin}) => {
  const videoRef = useRef(null);
  let players = null;

  const onReadyPlayer = (e) => {
    console.log('ready',e.target);
    players = e.target;
   /*  e.target.playVideo(0); */
  }
  const onChangePlayerState = (e) => {
    console.log('change',e.target);
    players = e.target;
  }
  const onPlayerClose = (e) => {
    //console.log(e.target);
    e.target.stopVideo(0);
    onOpenYoutubeHandler(false);
    onPopupBackWin(false);
  }
  const onCloseYoutubeHandler = (e) => {
    console.log(players);
    if(!isNull(players)) players.stopVideo(0);
    onOpenYoutubeHandler(false);
    onPopupBackWin(false);
  }

  return !isEmptyString(varYoutubeLink)?null:(
    <div className="poplayerYoutube" style={varOpenYoutube?{display:"block"}:{display:"none"}}>
      <div className="date-right-close"><img src={closeIcon} alt="" onClick={e=>onCloseYoutubeHandler(e)}/></div>
      <div className="consbox2" style={{width:"100%",height:"100%"}}>
        <YouTube videoId={varYoutubeLink} 
            ref={videoRef}
            className="youtube_body" 
            opts={{width:"100%",height:"100%",playerVars: {autoplay:1}}}
            onPause={onPlayerClose}
            onReady={onReadyPlayer}
            onStateChange={onChangePlayerState} 
        />
      </div>
    </div>
  );
}
export default ShowYouTube;