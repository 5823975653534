import React, { useState,useEffect } from "react";
import { useParams,useNavigate,Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import { ChangeMobileFormat,isObjEmpty,ASC,isNull } from '../../../components/common/Common';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import '../../admin_css/board.css';

//내 둥지 내놓기 정보 표시
function ServiceDetail({setMenuItem}) {
  const {pageMode,indexNum} = useParams();
  const navigate = useNavigate();

  const mem_idx = localStorage.getItem("mem_idx");

  //신규정보
  const newData = {
    rf_idx:0,
    rf_mem_idx:mem_idx,
    mem_name:"",
    mem_mobile:"",
    rf_title:"",
    rf_sido:"",
    rf_sigungu:"",
    rf_dongri:"",
    rf_serious:0,
    rf_wantarea:'',
    rf_comment:"",
    rf_phone:"",
    rf_rdate:null,
    rf_status:0
  }

  //선택/추가할 저장 변수 
  const [varServiceDatas, setServiceDatas] = useState({});
  const [varDoongjiSerious,setDoongjiSerious] = useState([]);
  //지역정보 관련 변수
  const [varRegionList,setRegionList] = useState([]);
  const [varSiDoList,setSiDoList] = useState([]);
  const [varSiGunGuList,setSiGunGuList] = useState([]);
  const [varDongRiList,setDongRiList] = useState([]);

  //정보 읽어오기
  const getFiindingInformation = async () => {
    const sendData = {pageMode:pageMode, indexNum:indexNum};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_finding_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //정보
        if(datas.results?.length > 0) {
          setServiceDatas(datas.results[0]);
        }
        //매물종류
        setDoongjiSerious(datas.filters); 
        //지역정보
        setRegionList(datas.regions); 
        const SiDoList = datas.regions.filter(element=>element.regcode.substr(2,8)==='00000000');
        setSiDoList(SiDoList);
        if(datas.results?.length > 0) {
          //읽은 시도청 설정
          const SiDoCode = datas.results[0].rf_sido;
          if(!isNull(SiDoCode)){
            const prefixCode = SiDoCode.substr(0,2);
            //시/군/구 목록
            const sGunGuList = datas.regions.filter(el=>el.regcode.substr(0,2)===prefixCode && el.regcode.substr(5,5)==='00000' && el.regcode.substr(2,8)!=='00000000');
            //쏘팅
            sGunGuList.sort(ASC('regname',true));
            setSiGunGuList(sGunGuList);
          }

          const SiGunGuCode = datas.results[0].rf_sigungu;
          if(!isNull(SiGunGuCode)) {
            const prefixCode = SiGunGuCode.substr(0,5);
            //동/리 목록
            const sDongRiList = datas.regions.filter(el=>el.regcode.substr(0,5)===prefixCode && el.regcode.substr(5,5)!=='00000');
            //쏘팅
            sDongRiList.sort(ASC('regname',true));
            setDongRiList(sDongRiList);
          }
        }
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    setServiceDatas(newData);
    setMenuItem('DoongjiSearch');
    getFiindingInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //시도,시군구 산택----------------------------------------------------------------------
  const onSelectedRegionHandler = (e,ino) => {
    const { value } = e.currentTarget;
    //const sname = e.currentTarget.options[e.currentTarget.selectedIndex].text;

    if(ino === 1) { //시/도 선택시
      if(value === "") {  
        setSiGunGuList([]);
      } else {
        //시군구청 목록 가져오기
        getSiGunGuRegionList(value);
      }
      setDongRiList([]);
      setServiceDatas((prevState)=>{return{...prevState,rf_sido:value,rf_sigungu:'',rf_dongri:''}});
    } else if(ino === 2) {  //시/군/구 선택시
      if(value === "") {  
        setDongRiList([]);
      } else {
        //동리목록 가져오기
        getDongRiRegionList(value);
      }
      setServiceDatas((prevState)=>{return{...prevState,rf_sigungu:value,rf_dongri:''}});
    } else {
      setServiceDatas((prevState)=>{return{...prevState,rf_dongri:value}});
    }
  }
  //시/군/구 목록 가져오기
  const getSiGunGuRegionList = (regcode) => {
    const prefixCode = regcode.substr(0,2);
    //시/군/구 목록
    const sGunGuList = varRegionList.filter(el=>el.regcode.substr(0,2)===prefixCode && el.regcode.substr(5,5)==='00000' && el.regcode.substr(2,8)!=='00000000');
    //쏘팅
    sGunGuList.sort(ASC('regname',true));
    setSiGunGuList(sGunGuList);
  }
  //동/리 목록 가져오기
  const getDongRiRegionList = (regcode) => {
    const prefixCode = regcode.substr(0,5);
    //동/리 목록
    const sDongRiList = varRegionList.filter(el=>el.regcode.substr(0,5)===prefixCode && el.regcode.substr(5,5)!=='00000');
    //쏘팅
    sDongRiList.sort(ASC('regname',true));
    setDongRiList(sDongRiList);
  }

  //정보 입력--------------------------------------------------------
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    console.log(name, value);
    setServiceDatas({...varServiceDatas,[name]:value});
  };

  //내둥지찾기 정보 저장
  const onUpdateServiceHandler = async (e) => {
    if(varServiceDatas.rf_title==="") {
      alert("신청제목을 먼저 입력하세요."); return false;
    } else if(varServiceDatas.rf_zipcode==="") {
      alert("우편번호/주소를 먼저 선택하세요.");return false;
    } else if(Number(varServiceDatas.rf_serious)===0) {
      alert("매물종류를 먼저 선택하세요.");return false;
    } else if(varServiceDatas.rf_comment==="") {
      alert("의뢰내용을 먼저 입력하세요.");return false;
    } else if(varServiceDatas.rf_phone==="") {
      alert("담당자 연락처를 먼저 입력하세요.");return false;
    }
    if(!window.confirm('정보를 저장합니까?')) return false;

    const sendData = {indexNum:indexNum, infos:varServiceDatas};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_finding_information`, sendData).then((res) => {
      alert(res.data.message);
      if (res.data.retvalue === 1) {
        let newIndex = Number(varServiceDatas.rf_idx);
        if(newIndex === 0) newIndex = Number(res.data.newIndex);
        navigate(`/Admin/ProductService/DoongjiSearch`);
      }
    });
  }

  return isObjEmpty(varServiceDatas)?null: (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">내 둥지 찾아주세요</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>둥지/장터관리<img src={arrImage} alt=""/>기타 서비스 관리<img src={arrImage} alt=""/>내 둥지 찾아주세요
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <table className="write">
          <colgroup>
              <col width="20%"></col>
              <col width="30%"></col>
              <col width="20%"></col>
              <col width="30%"></col>
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>신청제목</th>
                <td>
                  <input type="text" className="title-box" name={"rf_title"} value={varServiceDatas.rf_title} onChange={(e)=>onChangeInputHandler(e)}/>
                </td>
                <th>신청자</th>
                <td>{varServiceDatas.mem_name+'('+ChangeMobileFormat(varServiceDatas.mem_mobile)+')'}</td>
              </tr>
              <tr>
                <th>찾는동네</th>
                <td colSpan={3}>
                  <select value={varServiceDatas.rf_sido||""} style={{width:"32%"}} onChange={e=>onSelectedRegionHandler(e,1)}>
                    <option value="">시/도 선택</option>
                    {varSiDoList.map((sido,i)=>(
                      <option key={'sido_'+i} value={sido.regcode}>{sido.regname}</option>
                    ))}
                  </select>&nbsp;&nbsp;
                  <select value={varServiceDatas.rf_sigungu||""} style={{width:"32%"}} onChange={e=>onSelectedRegionHandler(e,2)}>
                    <option value="">시/군/구 선택</option>
                    {varSiGunGuList.map((sigungu,i)=>(
                      <option key={'sigungu_'+i} value={sigungu.regcode}>{sigungu.regname}</option>
                    ))}
                  </select>&nbsp;&nbsp;
                  <select value={varServiceDatas.rf_dongri||""} style={{width:"32%"}} onChange={e=>onSelectedRegionHandler(e,3)}>
                    <option value="">동/리 선택</option>
                    {varDongRiList.map((dongri,i)=>(
                      <option key={'dongri_'+i} value={dongri.regcode}>{dongri.regname}</option>
                    ))}
                  </select><br/>
                  <input type="text" name="rf_wantarea" value={varServiceDatas.rf_wantarea} placeholder="원하는 지역명을 작성해주세요 예)논현동,대치동" onChange={e=>onChangeInputHandler(e)}/>
                </td>
              </tr>
              <tr>
                <th>매물종류</th>
                <td>
                  <select name={"rf_serious"} value={varServiceDatas.rf_serious} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value={0}>매물종류선택</option>
                    {varDoongjiSerious.map((serious,i)=>(
                      <option key={'serious_'+i} value={serious.vfo_idx}>{serious.vfo_name}</option>
                    ))}
                  </select>
                </td>
                <th>담당자 연락처</th>
                <td>
                  <input type="text" name={"rf_phone"} value={ChangeMobileFormat(varServiceDatas["rf_phone"])||""} onChange={(e)=>onChangeInputHandler(e)}/>
                </td>
              </tr>
              <tr>
                <th>의뢰내용</th>
                <td colSpan={3}>
                  <textarea name={"rf_comment"} value={varServiceDatas["rf_comment"]||""} onChange={(e)=>onChangeInputHandler(e)} style={{width:"100%,height:60px"}}></textarea>
                </td>
              </tr>
              <tr>
                <th>진행상태</th>
                <td>
                  <select name={"rf_status"} onChange={(e)=>onChangeInputHandler(e)} value={varServiceDatas["rf_status"]}>
                    <option value={0}>종료</option>
                    <option value={1}>신청중</option>
                    <option value={2}>답변완료</option>
                  </select>
                </td>
                <th></th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" style={{width:"100px"}} onClick={(e)=>onUpdateServiceHandler(e)}>저장</button>
          <Link to={`/Admin/ProductService/DoongjiSearch`} style={{width:"100px"}} className="btn line">목록</Link>
        </div>
      </div>
    </div>
  )
}

export default ServiceDetail;