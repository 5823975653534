import React from "react";
import { Link } from "react-router-dom";
/* import axios from 'axios';
import CONFIG from '../../../configs/Configs'; */

import {ChangeDateStringOnlyDate,addThousandPoint,ResetCheckBoxes} from "../../../components/common/Common";

function JangterSearchResult({varMaxNumPage,varJangterLists,setJangterLists,onDeleteJangterHandler}) {

  //목록의 모든 항목 선택하기
  const onCheckedAll = (e) => {
    const bChecked = Number(e.currentTarget.checked);
    clearBoardLists(bChecked);
    //배열값 변경
    let copyDatas = [...varJangterLists];
    for (var i=0; i < copyDatas.length; i++) {
      copyDatas[i].checked = bChecked;
    }
    setJangterLists(copyDatas);
  } 
  //목록상의 항목 선택하기
  const onCheckedElement = (e,jt_idx) => {
    const bChecked = Number(e.currentTarget.checked);
    const findIndex = varJangterLists.findIndex(element=>element.jt_idx===jt_idx);
    let copyLists = [...varJangterLists];
    copyLists[findIndex].checked = bChecked;
    setJangterLists(copyLists);
    ResetCheckBoxes();
  } 
  //체크박스 초기화
  const clearBoardLists = (bmode) => {
    const checkBoxes = document.querySelectorAll('.del-item');
    for(var i=0; i < checkBoxes?.length; i++) {
      checkBoxes[i].checked = bmode;
    }
  }
  
  return (
    <div className="main-box">
      <div className="btn-right">
        <Link to={`/Admin/ProductList/JangterDetail/0`} className="btn-danger">추가</Link>
        <button onClick={(e)=>onDeleteJangterHandler(e)} className="btn line">삭제</button>
      </div>
      <table className="list">
        <colgroup>
          <col style={{width:"5%"}} />
          <col style={{width:"8%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"22%"}} />
          <col style={{width:"15%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"10%"}} />
        </colgroup>
        <thead>
          <tr>
            <th className="board-list">
              <input type="checkbox" id="deleteItems" onChange={(e)=>onCheckedAll(e)} />
            </th>
            <th>번호</th>
            <th>등록코드</th>
            <th>제목</th>
            <th>분류</th>
            <th>금액</th>
            <th>등록일자</th>
            <th>진행상태</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {varJangterLists.map((product, i) => (
            <tr key={'product_'+product.jt_idx}>
              <td className="board-list">
                <input type="checkbox" className={"del-item"} id={"deleteItem_" + product.jt_idx} onChange={(e)=>onCheckedElement(e,product.jt_idx)}/>
              </td>
              <td>{varMaxNumPage - i}</td>
              <td className="ellipsis">{product.jt_code}</td>
              <td className="ellipsis">{product.jt_title}</td>
              <td className="ellipsis">{`${product.vbs_name}/${product.vbi_name}${product.vbp_name===null?"":"/"+product.vbp_name}`}</td>
              <td className="ellipsis">{addThousandPoint(product.jt_price)}&nbsp;원</td>
              <td className="ellipsis">{ChangeDateStringOnlyDate(product.jt_rdate)}</td>
              <td className="ellipsis">
                {product.jt_status===1?"진행중"
                :product.jt_status===2?"거래완료":"종료"
                }
              </td>
              <td>
                <Link to={`/Admin/ProductList/JangterDetail/${product.jt_idx}`} style={{width:"90px"}} className="btn line">세부정보</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default JangterSearchResult;