/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import CONFIG from '../../../configs/Configs';

import VariableOneBlock from '../variables/VariableOneBlock';
// import VariableTwoBlock from '../variables/VariableTwoBlock';
//import VariableThreeBlock from '../variables/VariableThreeBlock';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

//어플에 사용되는 선택 항목들 정의 - 둥지관련
const DoongjiVariables = ({setMenuItem}) => {

  //메뉴 지정
  useEffect(()=>{
    setMenuItem('DoongjiVariables');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div id="contents" className="ad_contents">
        <div className="tit">
          <div className="title">카테고리 관리</div>
          <div className="path">
              <img src={iconhome} alt=""/>HOME<img src={arrImage} alt=""/>변수항목관리<img src={arrImage} alt=""/>둥지 카테고리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="select-list" style={{borderCollapse:"collapse"}}>
              <thead></thead>
              <tbody>
                {/* 검색필터 - 분류/항목 */}
                {/* <VariableTwoBlock varMode={1} varItems={CONFIG.VariableItems} /> */}
                {/* 상품옵션 */}
                <VariableOneBlock varMode={2} varItems={CONFIG.VariableItems} />
                {/* 건축물용도 */}
                <VariableOneBlock varMode={3} varItems={CONFIG.VariableItems} />
                {/* 건축물내부구조 */}
                <VariableOneBlock varMode={4} varItems={CONFIG.VariableItems} />
                {/* 종목구분 */}
                <VariableOneBlock varMode={5} varItems={CONFIG.VariableItems} />
                {/* 건물위치 */}
                <VariableOneBlock varMode={8} varItems={CONFIG.VariableItems} />
                {/* 용도지역 */}
                <VariableOneBlock varMode={9} varItems={CONFIG.VariableItems} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
}
export default DoongjiVariables;