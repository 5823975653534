/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ useEffect } from "react"; 
import { Link,useNavigate } from "react-router-dom";

import logoImage from "../../images/realtor/logo.png";
import locaImage from "../../images/realtor/loca_icon.png";

import "../../css/realtor.css";

const RealEstate = () => {
  const navigate = useNavigate();
  const realtor_login_state = Number(localStorage.getItem('memberLoginState'));

  useEffect(() => {
    //로그인상태가 아니면 
    if(!realtor_login_state) {
      alert('로그인을 먼저 하세요.');
      navigate('/RealEstateAgent');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //로그아웃
  const onLogoutHandler = (e) => {
    localStorage.removeItem("autoLogin");
    localStorage.removeItem("mem_idx");
    localStorage.removeItem("mcm_idx");
    localStorage.removeItem("mem_mode");
    localStorage.removeItem("mem_nickname");
    localStorage.removeItem("mcm_name");
    localStorage.removeItem("memberLoginState");
    localStorage.removeItem("mem_admin");
    navigate("/RealEstateAgent");
  }

  return (
    <>
      <header id="header_add">
        <button className="logo"><img src={logoImage} alt=""/></button>
        <span className="log-name">{localStorage.getItem("mcm_name")}</span>
        {realtor_login_state===0?null:<button className="btn-logout" onClick={e=>onLogoutHandler(e,true)}>로그아웃</button>}
      </header>
      <div className="realtor_add_con">
        <img src={locaImage} alt="" />
        <p className="con_tit">
          안녕하세요, 둥지장터 부동산 사장님 매물 등록 페이지입니다.
        </p>
        <div className="btn_wrap">
          <div className="btn2">
            <Link to={"/Doongji/DoongjiDetail/-1"}>신규 매물 등록</Link>
            <p>
              <img src={locaImage} alt="" />
              <span>구매하신 이용권 보유현황에 따라 매물을 등록할 수 있습니다.</span>
            </p>
          </div>
          <div className="btn2">
            <Link to={"/Doongji/TempRegisteredDoongjiList"}>임시 저장 리스트</Link>
            <p>
              <img src={locaImage} alt="" />
              <span>기존 임시 저장하셨던 매물의 리스트를 확인하고,<br />수정하여 등록할 수 있습니다.</span>
            </p>
          </div>
          <div className="btn2">
            <Link to={"/Doongji/RegisteredDoongjiList"}>내 등록 매물 보기</Link>
            <p>
              <img src={locaImage} alt="" />
              <span>기존 등록하신 매물 리스트를 확인할 수 있습니다.</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default RealEstate;