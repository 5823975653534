import React, { useState,useEffect } from "react"; 
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import {v4 as uuidv4} from 'uuid';

import { addThousandPoint,ChangeUnitBillion,ChangeDateStringOnlyDate,isObjEmpty,ChangeMobileFormat,isNull } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import PaymentMethod from '../../components/include/PaymentMethod';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const PremiumDetail = () => {
  let { ph_idx } = useParams();
  const navigate = useNavigate();
  const [varPremiumData,setPremiumData] = useState({});
  const [varCompanyName,setCompanyName] = useState('');
  const [varOrderName,setOrderName] = useState('');
  const [varBankAccountList,setBankAccountList] = useState([]);

  //프리미엄광고 정보 호출하기
  const getPremiumInformation = async () => {
    const sendData = {ph_idx:ph_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_premium_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1 && datas.results?.length > 0) {
        setPremiumData(datas.results[0]);
        //회사명
        setCompanyName(datas.results[0].mcm_name);
        //상품명
        let orderName = "둥지프리미엄광고";
        if(datas.results[0].rpa_mode === 1) orderName = "장터프리미엄광고";
        setOrderName(orderName);
      }
      setBankAccountList(datas.banks);
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    document.getElementById('main_body').style.backgroundColor = '#FFFFFF';
    getPremiumInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //결제하기
  const onPremiumProgress = async(e) => {
    if(varPremiumData.ph_method === 0) {
      if(varPremiumData.bh_idx === 0) {
        alert('입금계좌를 먼저 선택하세요.'); return false;
      } else if(varPremiumData.ph_sender === 0) {
        alert('입금자명을 먼저 입력하세요.'); return false;
      }
    }
    if(!window.confirm('결제를 진행하시겠습니까?')) return false;

    //주문번호 생성
    //주문번호 생성
    let randomId = '';
    if(varPremiumData.ph_idx > 0){
      randomId = varPremiumData.ph_code;
    } else {
      randomId = uuidv4();
    }

    const sendData = {ph_code:randomId,payInfo:varPremiumData};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_premium_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(varPremiumData.ph_method === 1) {  //카드결제
          const payString = `${randomId}|${varPremiumData.ph_amount}|${varCompanyName}|${varOrderName}`;
          console.log(payString);
          const enCodedString = encodeURI(payString); //base64_encode(payString);
          console.log(enCodedString);
          if(varPremiumData.ph_idx === 0) ph_idx = datas.ph_idx;
          navigate(`/TossPayment/${ph_idx}/${enCodedString}`);
        } else {   //무통장 입금
          SendAligoMessage();
        }
      } else {
        alert(datas.message);
      }
    });
  }
  //무통장 알리고 메시지 발송
  const SendAligoMessage = async() => {
    const receiverPhone = ChangeMobileFormat(varPremiumData.mem_mobile);
    const bankData = varBankAccountList.find(el=>el.bh_idx===varPremiumData.bh_idx);

    //console.log(varPremiumData);
    //console.log(varPremiumData.bh_account,varPremiumData.bh_owner);
    const message = `[둥지장터_입금요청]
    프리미엄 광고 신청 완료를 위해 입금 부탁드립니다.
    (관리자 입금 확인후 광고 집행이 됩니다.)    
    
    ▶계좌번호: ${bankData.bl_name}
    ${bankData.bh_account}
    ▶예금주: ${bankData.bh_owner}
    ▶금액: ${addThousandPoint(varPremiumData.ph_amount)+" 원"}`;

    const sendData = {sender:"02-3144-2654",receiver:receiverPhone,msg:message,msg_type:'LMS'};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_send_message`, sendData).then((res) => {
      console.log(res.data);
      alert(res.data.message);
      navigate(`/MyPage/PremiumView/${varPremiumData.ph_idx}`); 
    });
  }

  return isObjEmpty(varPremiumData)?null:(
    <>
      <Header/>
      <HeadTitle titleString={"파트너스 결제 관리"} backString={"PremiumList"}/>
      <div id="content">
        <div className="pd_wrap04">
          <div className="center_wrap">
            <div className="ad_pay2_wrap">
              <div className="form_wrap">
                <div className="tit07_1">희망 광고 컨텐츠</div>
                <div className="ad_con_box">
                  <span className="btn">{varPremiumData.ph_mode===3?"둥지":"장터"}</span>
                  <span className="txt">
                    {varPremiumData.ph_mode===3?
                      (isNull(varPremiumData.structure)?'삭제된 컨텐츠':varPremiumData.structure+' '+varPremiumData.types+' '+ChangeUnitBillion(varPremiumData.rp_deposit))
                    :null}
                  </span>
                </div>
              </div>
              <div className="form_wrap">
                <div className="tit07_1">희망 광고 지역</div>
                <div className="ad_con_box">
                  <ul className="ad_region">
                    {varPremiumData.rpa_regcode1===null||varPremiumData.rpa_regcode1===""?null:<li>{varPremiumData.regname1}</li>}
                    {varPremiumData.rpa_regcode2===null||varPremiumData.rpa_regcode2===""?null:<li>{varPremiumData.regname2}</li>}
                    {varPremiumData.rpa_regcode3===null||varPremiumData.rpa_regcode3===""?null:<li>{varPremiumData.regname3}</li>}
                    {varPremiumData.rpa_regcode4===null||varPremiumData.rpa_regcode4===""?null:<li>{varPremiumData.regname4}</li>}
                    {varPremiumData.rpa_regcode5===null||varPremiumData.rpa_regcode5===""?null:<li>{varPremiumData.regname5}</li>}
                  </ul>
                </div>
              </div>
              <div className="form_wrap">
                <div className="tit07_1">희망 게재 기간</div>
                <div className="ad_con_box">
                  <div className="ad_period">
                    <span>{ChangeDateStringOnlyDate(varPremiumData.rpa_sdate)}</span><span className="mid_bar02"> ~ </span><span>{ChangeDateStringOnlyDate(varPremiumData.rpa_edate)}</span>
                  </div>
                </div>
              </div>
              <div className="form_wrap">
                <div className="tit07_1">광고 금액</div>
                <div className="ad_con_box">
                  <span className="ad_price">{addThousandPoint(varPremiumData.ph_amount)+" 원"}</span>
                </div>
              </div>
              <PaymentMethod varBankAccountList={varBankAccountList} varPaymentData={varPremiumData} setPaymentData={setPremiumData}/>
              {(varPremiumData.ph_status===0 || varPremiumData.ph_status>3)?null:
                <div className="btn_bottom one_btn">
                  <button className="btn_style_yes" onClick={e=>onPremiumProgress(e)}>결제하기</button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PremiumDetail;