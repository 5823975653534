/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
//import useGeolocation from "react-hook-geolocation";
import useCurrentLocation from '../../components/include/useCurrentLocation';

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import DoongjiProduct from "../../components/doongji/DoongjiProduct";

import { isObjEmpty,isNull } from "../../components/common/Common";

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

/* const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
  maximumAge: 1000 * 3600 * 24, // 24 hour
} */
function getLocation() {
  if (navigator.geolocation) { // GPS를 지원하면
    navigator.geolocation.getCurrentPosition(function(position) {
    }, function(error) {
      console.error(error);
    }, {
      enableHighAccuracy: false,
      maximumAge: 0,
      timeout: Infinity
    });
  } else {
    alert('GPS를 지원하지 않습니다');
  }
}

const NearRegisteredDoongjiList = () => {
  const mem_idx = localStorage.getItem("mem_idx");
  const [varMyLocation,setMyLocation] = useState({});
  const [varSearchDistance,setSearchDistance] = useState(0);
  const [varResultString,setResultString] = useState('검색중.....');

  const location = useCurrentLocation();

  //검색된 매물목록 관련
  const [varTotalCount,setTotalCount] = useState(0);
  const [varDoongjiList,setDoongjiList] = useState([]);

  //둥지매물 검색후 목록 가져오기
  const getDoongjiNearList = async() => {
    console.log(varMyLocation);
    //const loc = {lat:'37.2960981405064',lng:'127.640508006388'};
    const sendData = {mem_idx:mem_idx,myLocation:varMyLocation,distance:varSearchDistance};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_doongji_nearlist`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //검색된 전체수량
        setTotalCount(datas.results.length);
        //검색된 목록
        setDoongjiList(datas.results);
        //검색결과
          if(datas.results?.length === 0) setResultString('검색된 매물이 없습니다.');
      }
    });
  }
  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    console.log(location);
    if(location.loaded && !isNull(location.coordinates)) {
      console.log(location.coordinates.lat,location.coordinates.lng);
      setMyLocation({lat:location.coordinates.lat,lng:location.coordinates.lng});
      setSearchDistance(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if(varSearchDistance > 0 && !isObjEmpty(varMyLocation)) {
      getDoongjiNearList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varMyLocation,varSearchDistance]); 

  //매물을 찜목록으로 등록/해제
  const ChangeJjimDoongjiList = async (rp_idx,jj_idx,jj_status) => {
    let copyList = [...varDoongjiList];
    const findIndex = copyList.findIndex(element=>element.rp_idx === rp_idx);
  
    const sendData = {pageMode:1,opp_idx:rp_idx,jj_idx:jj_idx,mem_idx:mem_idx,jj_status:jj_status};
    await axios.post(`${CONFIG.SERVER_HOST}/_update_jjim_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(res.data);
      if(datas.retvalue === 1) {
        //검색된 목록
        copyList[findIndex].jj_idx = datas.jj_idx;
        copyList[findIndex].jj_status = jj_status;
        copyList[findIndex].mem_idx = mem_idx;
        setDoongjiList(copyList);
        return datas.jj_idx;
      }
    });   
  }

  const onChangeSearchingDistanceHandler = (e) => {
    const sdistance = e.currentTarget.value;
    setSearchDistance(sdistance);
  }

  return (
    <>
      <Header/>
      <HeadTitle titleString={"내 근처 매물 보기"} backString={'/Doongji/DoongjiList'}/>
      <div id="content">
        <div className="like_list01_wrap">
          <div className="write_dungji_list_wrap">
            <div className="near_title">
              <div>
                <span style={{display:"inline-block",paddingTop:"10px"}}>검색수: {varTotalCount}건</span>
              </div>
              <div>
                <span style={{padding:"10px"}}>검색거리 : </span>
                <select value={varSearchDistance} style={{width:"150px"}} onChange={e=>onChangeSearchingDistanceHandler(e)}>
                  <option value={0}>검색범위선택</option>
                  <option value={1}>1 KM 이내</option>
                  <option value={2}>2 KM 이내</option>
                  <option value={3}>3 KM 이내</option>
                  <option value={5}>5 KM 이내</option>
                  <option value={10}>10 KM 이내</option>
                  <option value={20}>20 KM 이내</option>
                  <option value={50}>50 KM 이내</option>
                </select>
              </div>
            </div>
            <div className="main_dunggi list_type01">
            {varDoongjiList?.length===0?
              <div className="list_con"><div className="c_wrap" style={{textAlign:"center"}}>{varResultString}</div></div>
            :varDoongjiList.map((product,i)=>(
              <div className="list_con" key={'product_'+i}>
                <DoongjiProduct product={product} ChangeJjimDoongjiList={ChangeJjimDoongjiList}/>
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NearRegisteredDoongjiList;