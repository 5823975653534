import React from 'react'

function TermContent({varTermMenu,varTerms,setTerms}) { 
  //약관 내용 변경
  const onContentHandler = (e) => {
    const {name,value} = e.currentTarget;
    setTerms({...varTerms, [name]:value});
  }
  console.log(varTermMenu,varTerms[varTermMenu],varTerms);
  return( 
    <table className="write-list">
      <thead></thead>
      <tbody>
        <tr>
          <td className="term-textarea" style={{textAlign:"center"}}>
            <textarea name={varTermMenu} value={varTerms[varTermMenu]} style={{maxWidth:"95%"}} onChange={(e)=>onContentHandler(e)} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default TermContent;