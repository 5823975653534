import React from "react";
import { Link } from "react-router-dom";
/* import axios from 'axios';
import CONFIG from '../../../configs/Configs'; */

import {ChangeDateStringOnlyDate,ResetCheckBoxes} from "../../../components/common/Common";

function DoongjiSearchResult({varMaxNumPage,varDoongjiLists,setDoongjiLists,onDeleteDoongjiHandler}) {

  //목록의 모든 항목 선택하기
  const onCheckedAll = (e) => {
    const bChecked = Number(e.currentTarget.checked);
    clearBoardLists(bChecked);
    //배열값 변경
    let copyDatas = [...varDoongjiLists];
    for (var i=0; i < copyDatas.length; i++) {
      copyDatas[i].checked = bChecked;
    }
    setDoongjiLists(copyDatas);
  } 
  //목록상의 항목 선택하기
  const onCheckedElement = (e,rp_idx) => {
    const bChecked = Number(e.currentTarget.checked);
    const findIndex = varDoongjiLists.findIndex(element=>element.rp_idx===rp_idx);
    let copyLists = [...varDoongjiLists];
    copyLists[findIndex].checked = bChecked;
    setDoongjiLists(copyLists);
    ResetCheckBoxes();
  } 
  //체크박스 초기화
  const clearBoardLists = (bmode) => {
    const checkBoxes = document.querySelectorAll('.del-item');
    for(var i=0; i < checkBoxes?.length; i++) {
      checkBoxes[i].checked = bmode;
    }
  }

  return (
    <div className="main-box">
      <div className="btn-right">
        <Link to={`/Admin/ProductList/DoongjiDetail/0`} className="btn-danger">추가</Link>
        <button onClick={(e)=>onDeleteDoongjiHandler(e)} className="btn line">삭제</button>
      </div>
      <table className="list">
        <colgroup>
          <col style={{width:"5%"}} />
          <col style={{width:"5%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"27%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"12%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"10%"}} />
        </colgroup>
        <thead>
          <tr>
            <th className="board-list">
              <input type="checkbox" id="deleteItems" onChange={(e)=>onCheckedAll(e)} />
            </th>
            <th>번호</th>
            <th>매물코드</th>
            <th>매물명</th>
            <th>등록유형</th>
            <th>등록자</th>
            <th>등록일자</th>
            <th>진행상태</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {varDoongjiLists.map((product, i) => (
            <tr key={'product_'+product.rp_idx}>
              <td className="board-list">
                <input type="checkbox" className={"del-item"} id={"deleteItem_" + product.rp_idx} onChange={(e)=>onCheckedElement(e,product.rp_idx)}/>
              </td>
              <td>{varMaxNumPage - i}</td>
              <td className="ellipsis">{product.rp_code}</td>
              <td className="ellipsis">{product.rp_title}</td>
              <td className="ellipsis">{product.rp_mode===1?"일반매물":"중개매물"}</td>
              <td className="ellipsis">{product.mcm_name===null||product.mcm_name===""?product.mem_name:product.mcm_name}</td>
              <td className="ellipsis">{ChangeDateStringOnlyDate(product.rp_rdate)}</td>
              <td className="ellipsis">
                {product.rp_status===1?"임시저장"
                :product.rp_status===2?"등록완료"
                :product.rp_status===0?"종료":""
                }
              </td>
              <td>
                <Link to={`/Admin/ProductList/DoongjiDetail/${product.rp_idx}`} style={{width:"100px"}} className="btn line">세부정보</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DoongjiSearchResult;