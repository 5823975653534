/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import {ChangeDateStringOnlyDate} from "../../../components/common/Common";

//부관리자의 목록 표시
function SubAdminList({setShowDetailPage,varTotalList,setCurrentSubAdmin,varSubAdminLists, setSubAdminLists}) {

  //부관리자 정보 편집하기
  const onEditSubAdminHandler = (e,mem_idx) => {
    e.preventDefault();
    setCurrentSubAdmin(Number(mem_idx));
    setShowDetailPage(true);
  }

  //부관리자 추가하기
  const onAddSubAdminHandler = (e) => {
    e.preventDefault();
    setCurrentSubAdmin(0);
    setShowDetailPage(true);
  }

  //부관리자 삭제
  const onDeleteSubAdminHandler = async(e,mem_idx) => {
    e.preventDefault();
    if(!window.confirm('선택된 부운영자를 삭제하시겠습니까?')) return false;
    const sendData = {pageModeTitle:"부운영자",mem_idx:mem_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_admin_list`,sendData).then((res) => {
      const datas = res.data;
      alert(datas.message);
      if(datas.retvalue === 1) {
        //회원목록 재설정
        const newLists = varSubAdminLists.filter(element=>element.mem_idx!==mem_idx);
        setSubAdminLists(newLists);
      }
    });
  }
  
  return (
    <div className="content">
      <div className="main-box2">
        <div className="btn-right">
          <button className="btn-danger" style={{width:"120px"}} onClick={(e)=>onAddSubAdminHandler(e)}>추가등록</button>
        </div>
      </div>
      <div className="main-box">
        <table className="list">
          <colgroup>
            <col width="8%"></col>
            <col width="20%"></col>
            <col width="12%"></col>
            <col width="40%"></col>
            <col width="10%"></col>
            <col width="18%"></col>
          </colgroup>
          <thead>
            <tr>
              <th>번호</th><th>아이디(이메일)</th><th>이름</th><th>메뉴권한</th><th>등록일자</th><th>관리</th>
            </tr>
          </thead>
          <tbody>
            {varTotalList === 0 ? 
              <tr><td colSpan="5" style={{textAlign:"center"}}>등록된 부관리자가 없습니다.</td></tr>
            : varSubAdminLists.map((subadmin, i) => (
              <tr key={'subadmin'+i}>
                <td className=""><p>{varTotalList-i}</p></td>
                <td><p>{subadmin.mem_email}</p></td>
                <td><p>{subadmin.mem_name}</p></td>
                <td>
                  {subadmin.admin_environment === 0 ? '': '환경설정'}
                  {subadmin.admin_member === 0 ? '':subadmin.admin_environment === 0?'회원관리':' , 회원관리'}
                  {subadmin.admin_product === 0 ? '':(subadmin.admin_environment === 0 && subadmin.admin_member === 0)?'둥지/장터관리':' , 둥지/장터관리'}
                  {subadmin.admin_payment === 0 ? '':(subadmin.admin_environment === 0 && subadmin.admin_member === 0 && subadmin.admin_product === 0)?'요금/이용권관리':' , 요금/이용권관리'}
                  {subadmin.admin_board === 0 ? '':(subadmin.admin_environment === 0 && subadmin.admin_member === 0 && subadmin.admin_product === 0 && subadmin.admin_payment === 0)?'게시판관리':' , 게시판관리'}
                </td>
                <td><p>{ChangeDateStringOnlyDate(subadmin.mem_rdate)}</p></td>
                <td>
                  <button className="btn trline" onClick={(e)=>onEditSubAdminHandler(e,subadmin.mem_idx)}>수정</button>
                  <button className="btn trline" onClick={(e)=>onDeleteSubAdminHandler(e,subadmin.mem_idx)}>삭제</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SubAdminList
