import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from 'date-fns/esm/locale';
import "react-datepicker/dist/react-datepicker.css";

import { ChangeSearchingDateFormat } from "../../../components/common/Common";

function BoardSearch({varTotalCount, varSearchData, setSearchData}) {

  //항목별 검색변수값 설정
  const [varStartDate, setStartDate] = useState(varSearchData.sdate===null?null:varSearchData.sdate);
  const [varEndDate, setEndDate] = useState(varSearchData.edate===null?null:varSearchData.edate);
  const [varSearchOption, setSearchOption] = useState(varSearchData.searchOption);
  const [varSearchString, setSearchString] = useState(varSearchData.searchString);

  //검색항목 선택값 저장
  const onSearchOptionHandler = (e) => {   
    setSearchOption(e.target.value);
  }
  //검색문자 저장
  const onSearchStringHandler = (e) => {   
    setSearchString(e.target.value);
  }
  //검색시작
  const onExecuteSearchingHandler = (e) => {
    e.preventDefault();
    setSearchData({
        sdate:ChangeSearchingDateFormat(varStartDate),
        edate:ChangeSearchingDateFormat(varEndDate),
        searchOption:varSearchOption,
        searchString:varSearchString
    });
  }
  //검색기간 설정값 저장
  const onChangeDateHandler = (ino,date) => {
    if(date === "" ) date = null;
    if(ino === 0) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  }
  //검색정보 초기화
  const onClearSearchHandler = (e) => {
    e.preventDefault();
    setSearchData({
      sdate:null,
      edate:null,
      searchOption:"all",
      searchString:""
    });
    setStartDate(null);
    setEndDate(null);
    setSearchOption("all");
    setSearchString("");
  }

  return (
    <div className="main-box">
      <div className="search-area">
        <div className="search-box">
          <p>검색건수 : {varTotalCount} 건</p>&nbsp;&nbsp;
          {/*등록일자*/}
          <div className="datepicker_area">
            <p className="Title">등록일자</p>
            <DatePicker locale={ko} name="sdate" className="datepicker" dateFormat="yyyy.MM.dd" selected={varStartDate} selectStart onChange={(date)=>onChangeDateHandler(0,date)} />
            ~
            <DatePicker locale={ko} name="edate" className="datepicker" dateFormat="yyyy.MM.dd" selected={varEndDate} selectEnd minDate={varStartDate} onChange={(date)=>onChangeDateHandler(1,date)} />   
          </div>
        </div>
        {/*검색항목*/}
        <div className="search-string">
          <select value={varSearchOption} style={{width:"150px"}} onChange={(e)=>onSearchOptionHandler(e)}>
              <option value={"all"}>전체</option>
              <option value={"board_title"}>제목</option>
              <option value={"board_comment"}>내용</option>
          </select>
          <input type="text" value={varSearchString} onChange={(e)=>onSearchStringHandler(e)} placeholder="검색어를 입력해주세요"/>
          <button onClick={(e)=>onExecuteSearchingHandler(e)} className="btn">조회</button>
          <button onClick={(e)=>onClearSearchHandler(e)} className="btn black">초기화</button>
        </div>
      </div>
    </div>
  );
}

export default BoardSearch;