/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import { addThousandPoint } from "../../components/common/Common";

const VoucherSlots = ({varPaymentData,varVoucherSlot,onSelectVoucherHandler}) => {
  
  return (
    <li className={varPaymentData.vo_idx===varVoucherSlot.vo_idx?"voucher_items on":"voucher_items"} id={"voucher_items_"+varVoucherSlot.vo_idx} onClick={e=>onSelectVoucherHandler(e,varVoucherSlot.vo_idx,varVoucherSlot.vo_items,varVoucherSlot.vo_name,varVoucherSlot.vo_amount)}>
      <div className="dung_num">{varVoucherSlot.vo_items}</div>
      {varVoucherSlot.vo_discount === 0?
        <div className="price_wrap"><span className="p01">{addThousandPoint(varVoucherSlot.vo_amount)+" 원"}</span></div>
      :
        <>
          <div className="perct"><span>{varVoucherSlot.vo_discount+"% 할인"}</span></div>
          <div className="price_wrap">
            <span className="p01 no_price">{addThousandPoint(varVoucherSlot.vo_price)+" 원"}</span>&nbsp;&nbsp;
            <span className="p02 now_price">{addThousandPoint(varVoucherSlot.vo_amount)+" 원"}</span>
          </div>
        </>
      }
    </li>
  );
}
export default VoucherSlots;
