/* eslint-disable no-new-object */
import axios from 'axios'
import CONFIG from '../../configs/Configs';
import $ from 'jquery';

/* import { Config } from "aws-sdk"; */

export function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}
export function isNull(sopt) {
  if(sopt === undefined || sopt === null || sopt === "" || sopt === "null" || sopt === 0 ) return true;
  return false;
}

export function isNullExceptZero(sopt) {
  if(sopt === undefined || sopt === null || sopt === "" || sopt === "null") return true;
  return false;
}

export function isNullRegion(sopt) {
  if(sopt === "0000000000") return false;
  if(sopt === undefined || sopt === null || sopt === "" || sopt === "null") return true;
  return false;
}
//객체가 비어 있는지 확인
export function isObjEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
}
/* export function isEmptyObj(obj)  {
  if(obj.constructor === Object && Object.keys(obj).length === 0)  {
    return true;
  }
  return false;
} */

export function DisplayNumber(nums) {
  if(nums === undefined || nums === null || nums === "" || nums === "null") return "";
  if(nums === 0) return "";
  return addThousandPoint(nums);
}

export function DisplayNumberWithZero(nums) {
  if(nums === undefined || nums === null || nums === "" || nums === "null") return "";
  return addThousandPoint(nums);
}

export function ResetDates(sdate,itype,inum) {
  let cDate = null;
  if(isNull(sdate)) cDate = new Date();
  else cDate = new Date(sdate);
  if(itype === 0) cDate.setFullYear(cDate.getFullYear()+inum);
  else if(itype === 1) cDate.setMonth(cDate.getMonth()+inum);
  else cDate.setDate(cDate.getDate()+inum);

  let iyear = cDate?.getFullYear();
  let imonth = ('0' + (cDate?.getMonth() + 1)).slice(-2);
  let idate = ('0' + cDate?.getDate()).slice(-2);
  return iyear + '-' + imonth + '-' + idate;
}
export function GetCurrentMonth() {
  const today = new Date();
  let year = today?.getFullYear();
  let month = ('0' + (today?.getMonth() + 1)).slice(-2);
  return year + '-' + month;
}
export function ChangeShowMonth(smonth,ino) {
  let sdate = new Date(smonth+'-01');
  sdate.setMonth(sdate.getMonth()+ino);
  let year = sdate?.getFullYear();
  let month = ('0' + (sdate?.getMonth() + 1)).slice(-2);
  return year + '-' + month;
}
export function ChangeSearchingDateFormat(sdate) {
  let ndate = "";
  //console.log(sdate);
  if (isNull(sdate)) {
    ndate = null;
  } else {
    let year = sdate?.getFullYear();
    let month = ('0' + (sdate?.getMonth() + 1)).slice(-2);
    let day = ('0' + sdate?.getDate()).slice(-2);
    ndate = year + '-' + month + '-' + day;
  }
  return ndate
}
export function ChangeChattingDate(sdbdate) {
  let sdate = null;
  if (sdbdate === undefined || sdbdate === null || sdbdate === '') sdate = new Date();
  else{
    if(sdbdate.indexOf(' ') > 0) {
      sdbdate = sdbdate.replace(' ','T');
    }
    sdate = new Date(sdbdate);
  } 

  let year = sdate.getFullYear();
  let month = ('0' + (sdate.getMonth() + 1)).slice(-2);
  let day = ('0' + sdate.getDate()).slice(-2);

  let hours = ('0' + sdate.getHours()).slice(-2);
  let minutes = ('0' + sdate.getMinutes()).slice(-2);
  let seconds = ('0' + sdate.getSeconds()).slice(-2);

  const ndate = year + '.' + month + '.' + day + ' ' + hours + ':' + minutes+ ':' + seconds;
  return ndate
}
export function ChangeDateFormat(sdate) {
  if (sdate === '') sdate = new Date();
  let year = sdate?.getFullYear();
  let month = ('0' + (sdate?.getMonth() + 1)).slice(-2);
  let day = ('0' + sdate?.getDate()).slice(-2);
  const ndate = year + '-' + month + '-' + day;
  return ndate
}
export function exceptTimeFromDate(sdate) {
  if (sdate === null || sdate === '') return "";
  return sdate.substr(0,10);
}
export function ChangeDateFormatToKorean(sdate) {
  if (sdate === '') sdate = new Date();
  let year = sdate.getFullYear();
  let month = sdate.getMonth() + 1;
  let day = sdate.getDate();
  const ndate = year + '년 ' + month + ' 월' + day + ' 일';
  return ndate
}
export function ChangeDateTimeFormat(odate) {
  let sdate = null;
  if (odate ===undefined || odate === null || odate === '') sdate = new Date();
  else{
    if(odate.indexOf(' ') > 0) {
      odate = odate.replace(' ','T');
    }
    sdate = new Date(odate);
  } 

  let year = sdate.getFullYear();
  let month = ('0' + (sdate.getMonth() + 1)).slice(-2);
  let day = ('0' + sdate.getDate()).slice(-2);

  let hours = ('0' + sdate.getHours()).slice(-2);
  let minutes = ('0' + sdate.getMinutes()).slice(-2);
  let seconds = ('0' + sdate.getSeconds()).slice(-2);

  const ndate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  return ndate
}
export function ChangeDateMonth(sdate, imode = 0) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    let sdateString = sdate.substr(0, 7);
    if (imode === 1) sdateString = sdateString.replace(/-/g, '.');
    return sdateString;
  }
}
export function ChangeDateString(sdate) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    return sdate.replace(/-/g, '.');
  }
}
export function ChangeDateStringOnlyDate(sdate) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    sdate = sdate.substr(0,10);
    return sdate.replace(/-/g, '.');
  }
}
export function ChangeDateStringToKorean(sdate) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    if(sdate.indexOf(' ') > 0) {
      const datetimeArray = sdate.split(' ');
      sdate = datetimeArray[0];
    }
    const dateArray = sdate.split('-');
    const ndate = dateArray[0] + '년 ' + Number(dateArray[1]) + ' 월 ' + Number(dateArray[2]) + ' 일';
    return ndate
  }
}
export function ChangeDateTimeString(sdate) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    const dateArray = sdate.split(' ');
    return dateArray[0].replace(/-/g, '.') + " " + dateArray[1];
  }
}

export function ChoiceDate(sdate1, sdate2) {
  if (sdate1 !== null && sdate2 !== null) {
    //console.log(new Date(sdate1));
    return new Date(sdate1);
  } else if (sdate1 !== null) {
    //console.log(new Date(sdate1));
    return new Date(sdate1);
  } else if (sdate2 !== null) {
    //console.log(new Date(sdate2));
    return new Date(sdate2);
  } else {
    //console.log(new Date());
    return new Date();
  }
}

export function CalculateCareers(fnum1, fnum2) {

  console.log(fnum1, fnum2);
  const fnums = fnum1 + fnum2;
  const sterms = fnums.toString();

  console.log(fnums);
  if (sterms.indexOf('.') >= 0) {
    const termsArray = sterms.split('.');
    if (Number(termsArray[0]) > 0) {
      return termsArray[0] + " 년 " + termsArray[1] + " 개월";
    } else {
      return termsArray[1] + " 개월";
    }
  } else {
    return sterms + " 년";
  }
}

export function ChangeMoneyUnit(smoney) {
  if (smoney === null || smoney === undefined || smoney === 0) {
    return "없음";
  } else {
    return addThousandPoint(smoney / 10000) + " 만원";
  }
}

export function passedDays(sdate) {
  if (sdate === undefined || sdate === null || sdate === "" || sdate === 0) return 100;
  if (sdate.indexOf(' ') > 0) {
    var sdate_tmp = sdate.split(' ');
    sdate = sdate_tmp[0];
  } 
  const dday = new Date(sdate);
  const today = new Date();
  const gap = today.getTime() - dday.getTime();
  let passeddays = Math.ceil(gap / (1000 * 60 * 60 * 24));
  return passeddays;
}
export function remainDays(expdate) {
  if (expdate === undefined || expdate === null || expdate === "" || expdate === 0) return -1;
  if (expdate.indexOf(' ') > 0) {
    var expdate_temp = expdate.split(' ');
    expdate = expdate_temp[0];
  }
  const dday = new Date(expdate);
  const today = new Date();

  const gap = dday.getTime() - today.getTime();
  let remaindays = Math.ceil(gap / (1000 * 60 * 60 * 24));
  return remaindays;
}
//1000단위 실시간 점검
export function realThousandPoint(snum) {
  if (snum === undefined || snum === null || snum === "") return 0;
  var snum2 = snum.toString().replace(/[^\d]+/g, "");
  return parseInt(snum2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
//천단위 콤마찍기--
export function addThousandPoint(snum) {
  if (snum === undefined || snum === null || snum === "") return 0;
  return parseInt(snum).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
//천단위 콤마찍기-- 관리비 0원 추가
export function addThousandPointAddZero(snum) {
  if (snum === undefined || snum === null || snum === "") return "0";
  return parseInt(snum, 10).toLocaleString();
}
//천단위 콤마지우기
export function removeThousandPoint(snum) {
  return snum.replace(/[^\d]+/g, "");
}
//전화번호에 - 넣기
export function ChangeMobileFormat(mbNumber) {
  if (mbNumber === undefined || mbNumber === "" || mbNumber === null) return "";
  //return mbNumber.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
  return mbNumber.replace(/(^02|^0504|^0505|^0\d{2})(\d+)?(\d{4})$/, '$1-$2-$3');
}
export function ChangeMobileNumber(mbNumber) {
  if (mbNumber === undefined || mbNumber === "" || mbNumber === null) return "";
  return mbNumber.replace(/-/g, '');
}

//아이디 문자열 점검
//2022-08-17 고객사 요청으로 숫자조합 제거 - KSR
export function checkIDString(asValue) {
  //var regExp = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{6,25}$/; //  6자 이상 영문 + 숫자 조합
  var regExp = /^[a-z|A-Z|0-9|]{6,25}$/; //영문,숫자 6자 이상
  if (!regExp.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//2022-08-17 고객사 요청으로 닉네임은 한글도 포함 - KSR 추가
export function checkNickString(asValue) {
  var regExp = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]{3,25}$/; //영문,한글,숫자 6자 이상
  if (!regExp.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//전화번호 검색
export function isPhone(asValue) {
  var regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;
  var regExp2 = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
  if (regExp.test(asValue)) {
    return regExp.test(asValue);
  } else if (regExp2.test(asValue)) {
    return regExp2.test(asValue);
  }
};
//이메일 문자열 점검
export function checkEmailString(eString) {
  if(eString === undefined || eString === null || eString === "") return false;
  var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  if (eString.match(regExp) != null) return true;
  else return false;
}
//비밀번호 형식 검사
export function isPassword(asValue) {
  var regExp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/; //  8 ~ 16자 영문, 숫자, 특수문자 조합
  if (!regExp.test(asValue)) {
    return false;
  }
  return regExp.test(asValue) // 형식에 맞는 경우 true 리턴
}
//숫자 체크 정규식
export function onlyNumber(str) {
  return str.replace(/[^0-9.]/g, "").replace(/(\.*)\./g, "$1");
}
//숫자 체크 정규식
export function onlyBankAccountNumber(str) {
  return str.replace(/[^0-9-]/g, "").replace(/(\.*)\./g, "$1");
}
//사업자등록번호
export function checkCorporateRegiNumber(number){
  number = number.replace(/\s/gi, "");
	var numberMap = number.replace(/-/gi, '').split('').map(function (d){
		return parseInt(d, 10);
	});
  console.log("numberMap")
  console.log(numberMap)

	if(numberMap.length === 10){
		var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
		var chk = 0;

		keyArr.forEach(function(d, i){
			chk += d * numberMap[i];
		});

		chk += parseInt((keyArr[8] * numberMap[8])/ 10, 10);
		console.log(chk);
		return Math.floor(numberMap[9]) === ( (10 - (chk % 10) ) % 10);
	}

	return false;
}

//사업자등록번호
export function isTax(asValue) {
  //var regExp = /^(?=.*[-])(?=.*[0-9]).{8,16}$/;
  var regExp = /^(\d{3,3})+[-]+(\d{2,2})+[-]+(\d{5,5})$/;


  regExp.test("asValue")
  console.log(asValue)
  console.log(regExp.test(asValue))
  if (regExp.test(asValue)) {
    return regExp.test(asValue);
  } else {
    return false;
  }
}

//경력 숫자를 문자로 - 검색시
export function resetCareerForSearching(fterms) {
  if (fterms === undefined || fterms === "" || fterms == null) return "무관";
  if (fterms === 0) {
    return "무관";
  } else {
    const sterms = fterms.toString();
    if (sterms.indexOf('.') >= 0) {
      const termsArray = sterms.split('.');
      if (Number(termsArray[0]) > 0) {
        return "경력 " + termsArray[0] + " 년 " + termsArray[1] + " 개월";
      } else {
        return "경력 " + termsArray[1] + " 개월";
      }
    } else {
      return "경력 " + sterms + " 년";
    }
  }
}
//경력 숫자를 문자로
export function resetCareerFromNumbers(fterms) {
  if (fterms === undefined || fterms === "" || fterms == null) return "무관";
  if (fterms === 0) {
    return "무관";
  } else {
    const sterms = fterms.toString();
    if (sterms.indexOf('.') >= 0) {
      const termsArray = sterms.split('.');
      if (Number(termsArray[0]) > 0) {
        return termsArray[0] + " 년 " + termsArray[1] + " 개월";
      } else {
        return termsArray[1] + " 개월";
      }
    } else {
      return sterms + " 년";
    }
  }
}
export function resetCareerFromMonths(smonths) {
  if (smonths === undefined || smonths === "" || smonths === null) return "없음";
  if (smonths === 0) {
    return "없음";
  } else {
    const years = Math.floor(smonths / 12);
    const months = smonths % 12;
    var sterms = '';
    if (years > 0) {
      sterms = years + " 년";
    }
    if (months > 0) {
      if (sterms !== "") sterms += " ";
      sterms += months + " 개월";
    }
    return sterms
  }
}
export function CalculateCareerBetweenMonths(edate, sdate) {
  if (sdate === undefined || sdate === "" || sdate === null) {
    sdate = ChangeDateFormat('');
  };
  if (edate === undefined || edate === "" || edate === null) {
    edate = ChangeDateFormat('');
  };

  const expArray1 = sdate.split('-');
  if(expArray1.length === 2) expArray1[2] = '01';
  const startday = new Date(Number(expArray1[0]), Number(expArray1[1] - 1), Number(expArray1[2]));
  const expArray2 = edate.split('-');
  if(expArray2.length === 2) expArray2[2] = '01';
  const endday = new Date(Number(expArray2[0]), Number(expArray2[1] - 1), Number(expArray2[2]));
  var months;
  months = (endday.getFullYear() - startday.getFullYear()) * 12;
  months -= startday.getMonth();
  months += endday.getMonth();
  if (months < 0) months = 0;
  return Number(months);
}
export function resetCareerBetweenDays(edate, sdate) {
  if (edate === undefined || edate === "" || edate === null) return "신입";
  if (sdate === undefined || sdate === "" || sdate === null) return "신입";
  const expArray1 = sdate.split('-');
  const startday = new Date(Number(expArray1[0]), Number(expArray1[1] - 1), Number(expArray1[2]));
  const expArray2 = edate.split('-');
  const endday = new Date(Number(expArray2[0]), Number(expArray2[1] - 1), Number(expArray2[2]));
  var years, months;
  months = (endday.getFullYear() - startday.getFullYear()) * 12;
  months -= startday.getMonth();
  months += endday.getMonth();
  if (months < 0) months = 0;
  years = Math.floor(months / 12);
  months = months % 12;

  if (years === 0 && months === 0) {
    return "신입";
  } else {
    if (years === 0) {
      return "경력  " + months + "개월";
    } else {
      if (months > 0) {
        return "경력  " + years + "년 " + months + "개월";
      } else {
        return "경력  " + years + "년";
      }
    }
  }
}
export function getCareerBetweenDays(edate, sdate) {
  if (edate === undefined || edate === "" || edate === null) return "신입";
  if (sdate === undefined || sdate === "" || sdate === null) return "신입";

  const expArray1 = sdate.split('-');
  const startday = new Date(Number(expArray1[0]), Number(expArray1[1] - 1), Number(expArray1[2]));
  const expArray2 = edate.split('-');
  const endday = new Date(Number(expArray2[0]), Number(expArray2[1] - 1), Number(expArray2[2]));
  var years, months;
  months = (endday.getFullYear() - startday.getFullYear()) * 12;
  months -= startday.getMonth();
  months += endday.getMonth();
  if (months < 0) months = 0;
  years = Math.floor(months / 12);
  months = months % 12;

  if (years === 0 && months === 0) {
    return "신입";
  } else {
    if (months > 0) {
      return  years + "년 " + months + "개월";
    } else {
      return  years + "년";
    }
  }
}
//회원구분
export function ChangeMemberTypeString(mode, type) {
  if (mode === 0) {
    return "일반회원";
  } else if (mode === 1) {
    return "기업회원";
  } else {
    if (type === 0) {
      return "헤드헌터(프리랜서)";
    } else {
      return "헤드헌터(서치폼)";
    }
  }
}

//주민등록번호 마킹
export function MaskingCivilCode(scode) {
  console.log(scode);
  if (scode === undefined || scode === null || scode === "") {
    return "";
  }
  var ninArray = scode.split('-');
  var retString = ninArray[0] + '-' + ninArray[1].replace(/([0-9]{6})$/gi, "******");
  return retString;
}
//이메일 마킹
export function MaskingEmail(scode) {
  if (scode === undefined || scode === null || scode === "") {
    return "";
  }
  var ninArray = scode.split('@');
  const ileng = ninArray[0].length;
  let newString = "";
  if(ileng <= 3) {
    newString = ninArray[0];
  } else {
    newString = ninArray[0].substr(0,3);
    for(var i=4; i <= ileng; i++) {
      newString += "*";
    }  
  }

  var retString = newString + '@' + ninArray[1]
  return retString;
}

//문자열 마킹
export function MaskingString(sString,ist,showString) {
  if (sString === undefined || sString === null || sString === "") {
    return "";
  }
  let newString = "";
  let ileng = sString.length;
  if(ist > 0) {
    newString = sString.substr(0,ist);
  }
  //ileng = ileng - ist;
  for(var i=ist; i < ileng; i++) {
    newString += showString;
  }
  return newString;
}


//포트폴리오 파일 저장 - status=0:임시저장/status=1:정식저장
export const file_upload = async (file, re_idx, status) => {
  if (file) {
    const formData = new FormData();
    console.log(re_idx);
    formData.append('port_folio', file);
    console.log(formData.get('port_folio'));
    let apiName = "port_folio";
    if(status === 0) {
      apiName = "port_tmp_folio";
    }

    //이미지 업로드
    await axios.post(`${CONFIG.SERVER_HOST}/${apiName}`, formData)
      .then(res => {
        const { fileName } = res.data;
        console.log(fileName);
        // return fileName;
        file_upload_insert(fileName, re_idx, status);
    });
  }
}
export const move_file_to = async (filename,re_idx, status) => {
  const param = new URLSearchParams();
  param.append('filename', filename);
  param.append('rcp_re_idx', re_idx);
  param.append('toWhere', status);
  //이미지 업로드
  await axios.post(`${CONFIG.SERVER_HOST}/move_file_to`, param).then(res => {
    if(res.data.retvalue === 1) {
      console.log('move OK')
    } else {
      console.log('move Fail')
    }
  });
}

//파일 업로드
export const file_upload1 = async (api, file, re_idx, status) => {
  const formData = new FormData();

  if (file) {
    console.log(file);
    formData.append(api, file);
    console.log(formData.get(api));

    // //이미지 업로드
    await axios.post(`${CONFIG.SERVER_HOST}/${api}`, formData)
      .then(async (res) => {
        const { fileName } = res.data;
        console.log(fileName);
        //return fileName;
        resume_ufile_update(fileName, re_idx, status);
        return fileName;
      });
  }
}

//파일 업로드
export const file_upload2 = async (api, file, re_idx) => {
  const formData = new FormData();
  if (file) {
    console.log(file);
    formData.append(api, file);
    console.log(formData.get(api));
    console.log(`${CONFIG.SERVER_HOST}/${api}`);
    // //이미지 업로드
    await axios.post(`${CONFIG.SERVER_HOST}/port_folio`, formData).then(res => {
        const { fileName } = res.data;
        console.log(fileName);
        //  file_upload_insert(fileName, re_idx);

      });
  }
}

//정식 status = 1/임시 저장 status = 0
export const file_upload_insert = async (rcp_ufile, re_idx, status) => {
  const param = new URLSearchParams();
  param.append('rcp_re_idx', re_idx);
  param.append('rcp_ufile', rcp_ufile);
  param.append('status', status);
  param.append("user_idx", Number(localStorage.getItem('mem_idx')));

  await axios.post(`${CONFIG.SERVER_HOST}/port_folio_file_insert`, param).then((res) => {
      console.log(res);
  });
}

//temp_resume_ufile_update
export const resume_ufile_update = async (re_ufile, re_idx, status) => {
  const param = new URLSearchParams();
  console.log(re_ufile);
  param.append('re_ufile', re_ufile);
  param.append('re_photo', 1);
  param.append('re_idx', re_idx);
  param.append('status', status);

  await axios.post(`${CONFIG.SERVER_HOST}/resume_ufile_update`, param).then((res) => {
    console.log(res);
});
}

//첨부파일 확장자 체크
export function checkFileName(str) {
  var ext = str.split('.');
  var ext1 = "." + ext[1].toLowerCase();
  var reg = /(.*?)\.(xlsx|docx|pdf|zip|hwp|ppt)$/;

  if (ext1.match(reg)) {
    return true;
  } else {
    return false;
  }
}

//주문코드생성
export function getPaymentCode() {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
  const today = new Date();
  const year = today.getFullYear();
  const month = ('0' + (today.getMonth() + 1)).slice(-2);
  const day = ('0' + today.getDate()).slice(-2);
  let randomstring = 'PC'+month+day+year;
  for (let i = 0; i < 6; i++) {
    const rnum = Math.floor(Math.random() * chars.length)
    randomstring += chars.substring(rnum, rnum + 1)
  }
  return randomstring;
}

//코드생성 - 랜덤함수
export function randomString(stringLength) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';

  let randomstring = ''
  for (let i = 0; i < stringLength; i++) {
    const rnum = Math.floor(Math.random() * chars.length)
    randomstring += chars.substring(rnum, rnum + 1)
  }
  return randomstring;
}
//코드생성 - 숫자만 - 랜덤함수
export function randomStringOnlyNumber(stringLength) {
  const chars = '0123456789';

  let randomstring = ''
  for (let i = 0; i < stringLength; i++) {
    const rnum = Math.floor(Math.random() * chars.length)
    randomstring += chars.substring(rnum, rnum + 1)
  }
  return randomstring;
}

//숫자만 입력
export function OnlyNumerics(strNum) {
  if(strNum === undefined || strNum === null || strNum === "") return "";
  return strNum.replace(/[^0-9.]/g, '');
}

export const send_push = async  (title, content) => {
  const FCM_KEY = "AAAAWg86CJw:APA91bEg9ruDA-NE9K-fm2pPlwKF9Ty84QPIV1EVk8jaAiw8SYdUjdagZtfY21styfbKCifOejgvRYdCqwJWIWVpZWcPkaUa3Srzls29PhUR8sJ-cGmg2gsbU7b2QIOzqOoi6u9LXww-";
  /* const FCM_URL = "https://fcm.googleapis.com/fcm/send"; */
  const FCM_TOPIC = "survey";

  // const token = await axios.get(`${CONFIG.SERVER_HOST_V2}/api/v2/front/getUserToken?user_id=${localStorage.getItem('user_id')}`).then((response) => {
  //   console.log("reviewCount1", response.data[0]);
  //   return  response.data[0].use_count;
  // });
  // const title = await axios.get(`${CONFIG.SERVER_HOST_V2}/api/v2/front/getPushTitle?id=2`).then((response) => {
  //   console.log("reviewCount1", response.data[0]);
  //   return  response.data[0].use_count;
  // });

  // const content = await axios.get(`${CONFIG.SERVER_HOST_V2}/api/v2/front/getPushContent?id=2`).then((response) => {
  //   console.log("reviewCount1", response.data[0]);
  //   return  response.data[0].use_count;
  // });

 /*  var key = ''; */

  // var to =token;
  var to =`/topics/${FCM_TOPIC}`;
  var notification = {
    'title': title,
    'body': content,
    'icon': '아이콘',
    'click_action': 'url',
    'android_channel_id':'survey'
  };

  fetch('https://fcm.googleapis.com/fcm/send', {
    'method': 'POST',
    'headers': {
      'Authorization': 'key=' + FCM_KEY,
      'Content-Type': 'application/json'
    },
    'body': JSON.stringify({
      'notification': notification,
      'to': to
    })
  }).then(function (response) {
    console.log('fetch success');
    console.log(response);
    alert("PUSH 발송 되었습니다");
  }).catch(function (error) {
    console.error(error);
    alert('fetch error');
  })
}

export function ChangeDate(sdate) {
  if (sdate === null || sdate === undefined || sdate === "") {
    return "";
  } else {
    const dateArray = sdate.split(' ');
    return dateArray[0];
  }
}

export const resetNavMenu = (imenu) => {
  //console.log(imenu);
  let navBlock = document.querySelector('.nav');
  let navItems = navBlock.querySelectorAll('.depth1');
  //console.log(navItems)
  for(var i=0; i < navItems.length; i++) {
    if(navItems[i].classList.contains('active')) {
      navItems[i].classList.remove('active');
    }
  }
  navItems[imenu].classList.add('active');
}

export const resetNavSubMenu = (main_idx, sub_idx) => {
  let navBlock = document.querySelector('.nav');
  let navItems = navBlock.querySelectorAll('.depth2 > li');
  //console.log(navItems)
  for(var i=0; i < navItems.length; i++) {
    if(navItems[i].classList.contains('active')) {
      navItems[i].classList.remove('active');
    }
  }
  if(sub_idx < 0) {
    return;
  }
  const depth1Boxes = document.querySelectorAll('.depth1');
  const depth2Boxes = depth1Boxes[main_idx].querySelector('.depth2');
  const liBoxes = depth2Boxes.querySelectorAll('li');
//   console.log(liBoxes)
  if(!liBoxes[sub_idx].classList.contains('active')) {
    liBoxes[sub_idx].classList.add('active');
  }
}
//Company - Heding
export const resetNavSubMenuForHeding = (sub_idx) => {
  let navBlock = document.querySelector('.nav');
  let navItems = navBlock.querySelectorAll('.depth2 > li');
  for(var i=0; i < navItems.length; i++) {
    if(navItems[i].classList.contains('active')) {
      navItems[i].classList.remove('active');
    }
  }
  if(sub_idx < 0) {
    return;
  }
  const depth1Boxes = document.querySelectorAll('.depth1');
  const depth2Boxes = depth1Boxes[5].querySelector('.depth2');
  const liBoxes = depth2Boxes.querySelectorAll('li');
  for(var j=0; j < liBoxes.length; j++) {
    if(liBoxes[j].getAttribute('id') === 'heding_'+sub_idx) {
      if(!liBoxes[j].classList.contains('active')) {
        liBoxes[j].classList.add('active');
      }
      break;
    }
  }
}
//화면조정
export const mobileWrap = () => {
  if (window.innerWidth < 890) {
    let tr = $(".tr");
    if (!tr.children().hasClass("m_wrap")) {
      tr.wrapInner("<div className='m_wrap'>");
      for(var i = 0; i < tr.length; i++) {
        let mWrap = $(tr[i]).children(".m_wrap");
        mWrap.after($(mWrap).children(".row_btn"));
      }
    }
  } else {
    let mWrap = $(".m_wrap");
    mWrap.children().unwrap();
  }
}

export const mobileWrapSearchTalent=()=>{
  if (window.innerWidth < 1024) {
    let tr = document.querySelectorAll(".tr");
    if (!$(tr).children().hasClass("m_colgroup")) {
      for(var i = 0; i < tr.length; i++) {
        $(tr[i]).find(".m_group").wrapAll("<div className='m_colgroup group1'></div>");
        $(tr[i]).find(".row_sort").wrapAll("<div className='m_colgroup group2'></div>");
      }
    }
  } else {
    $(".m_colgroup").children().unwrap();
  }
}

export const addDateDBFormat = (sdate,adddays) => {
  let fdate = new Date();
  if(sdate !== null && sdate !== "" && sdate !== "null") {
    fdate = new Date(sdate);
  }
  if(adddays === 0) return sdate;
  fdate.setDate(fdate.getDate() + adddays);
  return ChangeDateFormat(fdate);
}

//날짜형식변경 - 인증경력
export const dateToString = (date) => {
  return date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0');
}

//문자열에 공백이 있는 경우
export function blank_pattern (asValue) {
  let blankStr = /[\s]/g;
  if (blankStr.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//문자열에 특수문자가 있는 경우
export function special_pattern (asValue) {
  let specialStr = /[`~!@#$%^&*|\\\'\";:\/?]/gi; //eslint-disable-line
  if (specialStr.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//문자열에 한글, 영문만 입력 가능
export function name_pattern (asValue) {
  let nameRegExp  = /^[가-힣a-zA-Z]+$/;
  if (nameRegExp.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//문자열에 숫자가 있는 경우
export function include_num (asValue) {
  let numRegExp  = /^[0-9]+$/
  if (numRegExp.test(asValue)) {
    return false;
  } else {
    return true;
  }
}

//접속자 IP 주소 가져오기
export const getIPData = async (varInputs,setInputs,sname) => {
  await axios.get("https://geolocation-db.com/json/").then((res) => {
    if(sname === '') {
      setInputs(res.data.IPv4);
    } else {
      setInputs({ ...varInputs, [sname]: res.data.IPv4 });
    }
  });
};

//이메일중복검사 버튼 표시 결정
export const CheckDuplicationObject = (objID,bmode) => {
  //중복확인버튼 표시 유무
  const objBotton = document.getElementById(objID);
  console.log(objBotton);
  console.log(bmode);
  if(!bmode) {
    if(objBotton.classList.contains('hide')) objBotton.classList.remove('hide');
  } else {
    if(!objBotton.classList.contains('hide')) objBotton.classList.add('hide');
  }
}
//파일 input을 리셋
export const resetFileForm = (elFileForm) => {
  var orgParent = elFileForm.parentNode;
  var orgNext = elFileForm.nextSibling;

  var tmp = document.createElement('form');
  tmp.appendChild(elFileForm);

  tmp.reset();
  orgParent.insertBefore(elFileForm,orgNext);
}

//문자열 변환 —— 데이타베이스 저장
export const escapeHtml = (stext) => {
  if(stext === undefined || stext === null || stext === "") return "";
  return stext.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
}
export const unescapeHtml = (stext) => {
  if(stext === undefined || stext === null || stext === "") return "";
  return stext.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&quot;/g, "\"").replace(/&#039;/g, "'");
};

//파일 크기 제한
export const  CheckLimitFileSize = (selFile,kbSize) => {
  const fileSize = selFile.size;
  const maxSize = kbSize * 1024;
  if(fileSize > maxSize) return false;
  else return true;
}

//br 처리
export const changeTextArea =(text) => {
  console.log(typeof text);
  var br_text = '';
  br_text = text.replaceAll(/(\n|\r\n)/g, "<br>");
  // br_text = text?.replace(/&lt;/g, '<');
  // br_text = text?.replace(/&gt;/g,'>');
  // br_text = text?.replace(/&nbsp;/g,' ');
  // br_text = text?.replace(/&amp;/g, '&');
  // br_text = text?.replace(/&quot;/g, '"');
  console.log(br_text);
  return br_text;
}

//총경력계산
export const ResetTotalCareer = (resumeAddCareerList,varInputs,setInputs,setTotalAddWork) => {
  const icount = resumeAddCareerList.length;
  let imonth = 0;
  for(var i=0; i < icount; i++) {
    let sdate = resumeAddCareerList[i].rec_sdate;
    let edate = resumeAddCareerList[i].rec_edate;
    console.log(edate,sdate,CalculateCareerBetweenMonths(edate,sdate));
    imonth += CalculateCareerBetweenMonths(edate,sdate);
  }
  const fyear = Math.floor(imonth/12);
  const fmonth = imonth - fyear*12;
  //const numTerms = fyear+'.'+fmonth;
  const numTerms = `${fyear}.${fmonth}`;
  setInputs({...varInputs, careeradd_year:numTerms})
  let strCareer = "";
  if(fyear > 0) {
    strCareer = fyear+" 년";
  }
  if(fmonth > 0) {
    strCareer += " "+fmonth+" 개월";
  }
  setTotalAddWork(strCareer);
}

export const IfNotNull= (input)=>{
  if (input !== null && input !== undefined && input !== '') {
    return true;
  };
  return false;
}
//문자열에 문자열을 구분자를 통해 추가
export const AddOptionToString = (sadd,stotal,sdiv) => {
  if(isNull(stotal)) {
    stotal = sadd;
  } else {
    stotal = stotal + sdiv + sadd;
  }
  return stotal;
}
//문자열에 구분자로 구분된 문자항목중에서 지정한 문자항목 제외
export const ExceptOptionToString = (soption,stotal,sdiv) => {
  if(!stotal.includes(soption)) return stotal;  //옵션이 포함되어 있지 않으면
  if(!stotal.includes(sdiv)) return ""; //구분자없이 지정 옵션만 있으면 
  let retString = "";
  const optionArray = stotal.split(sdiv);
  for(var i=0; i < optionArray.length; i++) {
    if(optionArray[i] !== soption) {
      retString = AddOptionToString(optionArray[i],retString,sdiv);
    }
  }
  return retString;
}

//sorting doongji
export const CheckSortingItems = (key) => {
  let returnObj = {fname:'', fdir:true, fmode:true};
  switch (Number(key)) {
    case 1:  //급매등록순
      returnObj.fname = "rp_rdate";
      break;
    default:
    case 2: //최근등록순
      returnObj.fname = "rp_rdate";
      break;
    case 3: //최초등록순
      returnObj.fname = "rp_rdate";
      returnObj.fdir = false;   //ASC
      break;
    case 4: //매매가 낮은순
    case 6: //보증금 낮은순
    case 8: //전세 낮은순
      returnObj.fname = "rp_deposit";
      returnObj.fdir = false;  //ASC
      returnObj.fmode = false; //숫자
      break    
    case 5: //매매가 높은순
    case 7: //보증금 높은순
    case 9: //전세 높은순
      returnObj.fname = "rp_deposit";
      returnObj.fmode = false; //숫자
      break;
    case 10: //월세 낮은순
      returnObj.fname = "rp_monthly";
      returnObj.fmode = false; //숫자
      returnObj.fdir = false; //ASC
      break;
    case 11: //월세 높은순
      returnObj.fname = "rp_monthly";
      returnObj.fmode = false; //숫자
      break;
    case 12: //평수 낮은순
      returnObj.fname = "rp_sale_area";
      returnObj.fmode = false; //숫자
      returnObj.fdir = false; //ASC
      break;
    case 13: //평수 높은순
      returnObj.fname = "rp_sale_area";
      returnObj.fmode = false; //숫자
      break;
    case 14: //조회수 높은순
      returnObj.fname = "rp_hits";
      returnObj.fmode = false; //숫자
      break;
  }
  return returnObj;
}
export const ASC = (key,bmode) => {
  return function (a, b) {
    var x,y;
    if(!bmode) {  //실수비교
      x = parseInt(a[key]);
      y = parseInt(b[key]);
    } else {  //날짜비교
      x = a[key];
      y = b[key];
    }

    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
  };
};

export const DESC = (key,bmode) => {
  return function (a, b) {
    var x,y;
    if(!bmode) {  //실수비교
      x = parseInt(a[key]);
      y = parseInt(b[key]);
    } else {  //날짜비교
      x = a[key];
      y = b[key];
    }

    if (x > y) return -1;
    if (x < y) return 1;
    return 0;
  };
};

export const Sorting = (key,dmode) => {
  return function (a, b) {
    var x,y;
    x = parseInt(a[key]);
    y = parseInt(b[key]);

    if(dmode === 0) {  //낮은순
      if (x < y) return -1;
      if (x > y) return 1;
    } else {  //높은순
      if (x > y) return -1;
      if (x < y) return 1;
    }
    return 0;
  };
};


//매매유형선택에 따른 필터버튼 활성화/비활성화
export const SetFilterButtons = (buttonList,optionList) => {
  //필터버튼 활성화
  let selectedButtone10 = false, selectedButtone11 = false, selectedButtone12 = false;
  const checkList = optionList.filter(element=>element.vsm_idx===1);
  //매물유형이 선택된 경우
  let selectedSeriousType = 0;
  for(var i=0; i < checkList.length; i++) {
    if(checkList[i].vfo_select===1) { //선택되어 있으면
      selectedSeriousType++;
      if(checkList[i].vfo_mode === 10) {
        selectedButtone10 = true;
      } else if(checkList[i].vfo_mode === 11) {
        selectedButtone11 = true;
      } else if(checkList[i].vfo_mode === 12) {
        selectedButtone12 = true;
      }
    }
  }
  //선택되었으면 필터버튼 활성화
  if(selectedSeriousType > 0) {
    let findIndex10 = buttonList.findIndex(element=>element.vsm_idx===10);
    buttonList[findIndex10].vsm_show = Number(selectedButtone10);
    let findIndex11 = buttonList.findIndex(element=>element.vsm_idx===11);
    buttonList[findIndex11].vsm_show = Number(selectedButtone11);
    let findIndex12 = buttonList.findIndex(element=>element.vsm_idx===12);
    buttonList[findIndex12].vsm_show = Number(selectedButtone12);
  }

  return buttonList;
}
//매매유형선택에 따른 필터버튼 활성화/비활성화
export const SetFilterGroups = (optionList) => {
  //필터버튼초기화
  const filterButton10 = document.getElementById('filter_pop_10');  //구조
  const filterButton11 = document.getElementById('filter_pop_11');  //업종
  const filterButton12 = document.getElementById('filter_pop_12');  //테마

  //필터버튼 활성화
  let selectedButtone10 = false, selectedButtone11 = false, selectedButtone12 = false;
  const checkList = optionList.filter(element=>element.vsm_idx===1);
  for(var i=0; i < checkList.length; i++) {
    if(checkList[i].vfo_select===1) { //선택되어 있으면
      if(checkList[i].vfo_mode === 10) {
        selectedButtone10 = true;
      } else if(checkList[i].vfo_mode === 11) {
        selectedButtone11 = true;
      } else if(checkList[i].vfo_mode === 12) {
        selectedButtone12 = true;
      }
    }
  }
  //선택되었으면 필터버튼 활성화
  if(selectedButtone10){
    if(filterButton10.classList.contains('hidden')) filterButton10.classList.remove('hidden'); 
  } else {
    if(!filterButton10.classList.contains('hidden')) filterButton10.classList.add('hidden');
  }
  if(selectedButtone11){ 
    if(filterButton11.classList.contains('hidden')) filterButton11.classList.remove('hidden'); 
  } else {
    if(!filterButton11.classList.contains('hidden')) filterButton11.classList.add('hidden');
  }
  if(selectedButtone12){ 
    if(filterButton12.classList.contains('hidden')) filterButton12.classList.remove('hidden'); 
  } else {
    if(!filterButton12.classList.contains('hidden')) filterButton12.classList.add('hidden');
  }
}
//타이머 - 표시
export const TimeFormat = (itime) => {
  const m = Math.floor(itime / 60).toString();
  let s = (itime % 60).toString();
  if (s.length === 1) s = `0${s}`;
  return `${m}:${s}`;
}
//평을 제곱미터로
export const ChangeToSquareMeter = (pyong,ino) => {
  const sm = pyong * 3.3058;
  const retvalue = sm.toFixed(ino);
  return retvalue;
}
export const ChangeToPyong = (pyong,ino) => {
  const sm = pyong * 0.3025;
  const retvalue = sm.toFixed(ino);
  return retvalue;
}
//만원을 억원으로
export const ChangeUnitBillion = (manwon) => {
  let billwon = "";
  if(manwon >= 100000000)
    billwon = addThousandPoint(Math.floor(manwon/100000000).toString())+"조원대";
  else if(manwon >= 10000)
    billwon = addThousandPoint(Math.floor(manwon/10000).toString())+"억원대";
  else 
    billwon = addThousandPoint(manwon)+"만원";
  return billwon;
}
//둥지 매물의 사진에 대한 전체 파일명 반환 (패스포함)
export const SetDoongjiPhotoFile = (file_path,file_name) => {
  const subfolderpath = file_name.substr(0,6);
  const fullFileName = file_path+subfolderpath+'/'+file_name;
  return fullFileName;
}

//ALIGO SMS 발송
export const sendMessageToMobile = async(mem_mobile,certCode) => {
  const receiverPhone = ChangeMobileFormat(mem_mobile);
  const message = `요청하신 인증코드입니다 : ${certCode}`;
  const sendData = {sender:"02-3144-2654",receiver:receiverPhone,msg:message,msg_type:'SMS'};
  console.log(sendData);
  await axios.post(`${CONFIG.SERVER_HOST}/_send_message`, sendData).then((res) => {
    console.log(res.data);
    alert(res.data.message);
  });
}
//NULL 값의 경우 처리 --- 데이타베이스 저장을 위해
export const MakeNullDateFields = function(sfield,sdate) {
  if(sdate === null || sdate === "") {
    return `${sfield}=NULL`;
  } else {
    return `${sfield}='${ChangeSearchingDateFormat(sdate)}'`;
  }
}
//문자열을 배열로 저장
export const  MakeStringToArray = function(sString) {     
  let namesArray = [];  
  if(sString!==null && sString !== "" ){
    if(sString.includes(',')) {
      namesArray = sString.split(',');
    } else {
      namesArray[0] = sString;
    } 
  }
  return namesArray;
}
//배열을 문자열로 저장
export const MakeArrayToString = function(namesArray) {     
  let namesString = '';  
  if(namesArray?.length === 0) return namesString;
  if(namesArray?.length === 1){
    namesString = namesArray[0];
  } else {
    namesString = namesArray.join(',');
  }
  return namesString;
}
//지역코드와 지역명을 배열로 재작업
export const MakeRegionArray = function(codeString,nameString) {
  let regArray = [];
  let regObj = {regcode:"",regname:""};
  if(!codeString.includes(',')) {
    regObj.regcode = codeString;
    regObj.regname = nameString;
    regArray.push(regObj);
  } else {
    const codeArray = codeString.split(',');
    const nameArray = nameString.split(',');
    for(var i=0; i < codeArray?.length; i++) {
      let loopObj = {...regObj};
      loopObj.regcode = codeArray[i];
      loopObj.regname = nameArray[i];
      regArray.push(loopObj);
    }
  }
  return regArray;
}

//input=file 초기화
export const SetEmptyInputFile = (fileBox) => {
  var agent = navigator.userAgent.toLowerCase();
  if ( (navigator.appName === 'Netscape' && navigator.userAgent.search('Trident') !== -1) || (agent.indexOf("msie") !== -1) ){
      // ie 일때 input[type=file] init.
      $(fileBox).replaceWith( $(fileBox).clone(true) );
      $("#parentCreateGroupFullName").val("");
  } else {
      //other browser 일때 input[type=file] init.
      $(fileBox).val("");
      $("#parentCreateGroupFullName").val("");
  }
}
//로그인 상태 점검 - 메시지 포함
export const CheckLoginState = () => {
  const loginState = localStorage.getItem("memberLoginState");
  if(loginState === undefined || loginState === null || !Boolean(loginState) || Number(loginState)===0) {
    alert('로그인되지 않은 상태이므로 사용할 수 없습니다. 먼저 로그인 하세요.');
    return false;
  } else {
    return true;
  }
}
export const CheckBlockingLoginState = () => {
  const loginState = localStorage.getItem("memberLoginState");
  if(loginState === undefined || loginState === null || !Boolean(loginState) || Number(loginState)===0) {
    return false;
  } else {
    return true;
  }
}
export const CheckLoginStateOnly = () => {
  const loginState = localStorage.getItem("memberLoginState");
  if(loginState === undefined || loginState === null || !Boolean(loginState) || Number(loginState)===0) {
    return false;
  } else {
    return true;
  }
}
/* 객체가 비어 있는지 확인 */
export function isEmptyString(sString) {
  if(sString === undefined || sString === null || sString === "") return false;
  else return true;
}

export function ShowDoongjiTitle(varProductData,sdiv) {
  let stitle = "";
  if(varProductData.structure !== null) stitle = varProductData.structure;
  let smode = "";
  switch(Number(varProductData.rp_type)) {
    case 11:
      smode = "매매 ";
      smode += ChangeUnitBillion(varProductData.rp_deposit);
      break;
    case 12:
      smode = "전세 ";
      smode += ChangeUnitBillion(varProductData.rp_deposit);
      break;
    case 13:
    case 14:
    default:
      smode = "월세/단기임대 ";
      if(varProductData.rp_deposit !== undefined || varProductData.rp_deposit !== null || varProductData.rp_deposit !== "") {
        smode += ChangeUnitBillion(varProductData.rp_deposit);
      }
      if(varProductData.rp_monthly !== undefined || varProductData.rp_monthly !== null || varProductData.rp_monthly !== "") {
        smode += sdiv+varProductData.rp_monthly+'만원';
      }
      if(varProductData.rp_admin_expense !== undefined || varProductData.rp_admin_expense !== null || varProductData.rp_admin_expense !== "") {
        smode += sdiv+varProductData.rp_admin_expense+'만원';
      }
      break;
    case 15:
      smode = "분양가 ";
      smode += ChangeUnitBillion(varProductData.rp_deposit);
      break;
  }
  if(smode !== null){
    if(stitle === "") stitle = smode;
    else stitle += sdiv+smode;
  } 
  return stitle;
}

export function ShowDoongjiTitle2(varProductData,sdiv) {
  let stitle = "";
  switch(Number(varProductData.rp_type)) {
    case 11:
      //stitle += ChangeUnitBillion(varProductData.rp_deposit);
      stitle += addThousandPoint(varProductData.rp_deposit)+'만원';
      break;
    case 12:
      //stitle += ChangeUnitBillion(varProductData.rp_deposit);
      stitle += addThousandPoint(varProductData.rp_deposit)+'만원';
      break;
    case 13:
    case 14:
    default:
      if(varProductData.rp_deposit !== undefined || varProductData.rp_deposit !== null || varProductData.rp_deposit !== "") {
        //stitle += ChangeUnitBillion(varProductData.rp_deposit);
        stitle += addThousandPoint(varProductData.rp_deposit)+'만원';
      }
      if(varProductData.rp_monthly !== undefined || varProductData.rp_monthly !== null || varProductData.rp_monthly !== "") {
        //stitle += sdiv+varProductData.rp_monthly+'만원';
        stitle += sdiv+addThousandPoint(varProductData.rp_monthly)+'만원';
      }
      if(varProductData.rp_admin_expense !== undefined || varProductData.rp_admin_expense !== null || varProductData.rp_admin_expense !== "") {
        //stitle += sdiv+varProductData.rp_admin_expense+'만원';
        stitle += sdiv+addThousandPoint(varProductData.rp_admin_expense)+'만원';
      }
      break;
    case 15:
      //stitle += ChangeUnitBillion(varProductData.rp_deposit);
      stitle += addThousandPoint(varProductData.rp_deposit)+'만원';
      break;
  }
  return stitle;
}
export function ShowDoongjiTitle3(varProductData,sdiv) {
  let stitle = "";
  switch(Number(varProductData.rp_type)) {
    case 11:
      stitle += addThousandPoint(varProductData.rp_deposit*10000);
      break;
    case 12:
      stitle += addThousandPoint(varProductData.rp_deposit*10000);
      break;
    case 13:
    case 14:
    default:
      if(varProductData.rp_deposit !== undefined || varProductData.rp_deposit !== null || varProductData.rp_deposit !== "") {
        stitle += addThousandPoint(varProductData.rp_deposit*10000);
      }
      if(varProductData.rp_monthly !== undefined || varProductData.rp_monthly !== null || varProductData.rp_monthly !== "") {
        stitle += sdiv+addThousandPoint(varProductData.rp_monthly*10000);
      }
      if(varProductData.rp_admin_expense !== undefined || varProductData.rp_admin_expense !== null || varProductData.rp_admin_expense !== "") {
        if(varProductData.rp_admin_expense > 0) stitle += sdiv+addThousandPoint(varProductData.rp_admin_expense*10000);
      }
      break;
    case 15:
      stitle += addThousandPoint(varProductData.rp_deposit*10000);
      break;
  }
  return stitle;
}
export const GetPhotoImage = (fileName,smode) => {
  if(fileName === null || fileName === "") fileName = `default_${smode}.png`;
  let fullfile = `${smode}/${fileName}`;
  return fullfile;
}

//FCM TOKEN 메모리에 저장
export const fnGetToken=(token,os) => {
  console.log(token,os);
  localStorage.setItem('fcm_token',token);
}
//체크박스 재조정
export const ResetCheckBoxes = () => {
  //전체선택항목 조정
  let selectItem = 0;
  const checkBoxes = document.querySelectorAll('.del-item');
  for(var i=0; i < checkBoxes?.length; i++) {
    if(checkBoxes[i].checked) selectItem++;;
  }
  const selectAllBox = document.getElementById('deleteItems');
  if(selectItem === checkBoxes?.length) selectAllBox.checked = true;
  else selectAllBox.checked = false;
}

/* export const SetSearchRegionListToStorage = (objList) => {
  let sobjString = "";
  for(var i=0; i < objList?.length; i++) {
    let sline = objList[i].regcode+"|"+objList[i].regname;
    if(sobjString === "") sobjString = sline;
    else sobjString += ','+sline;
  }
  localStorage.setItem('searchRegion',sobjString);
}
export const ReSetSearchRegionListFromStorage = (setSearchRegionList) => {
  const sListString = localStorage.getItem('searchRegion');
  const sListArray = sListString.split(',');
  let regionArray = [];
  for(var i=0; i < sListArray?.length; i++) {
    const lineArray = sListArray[i].split('|');
    const addLine = {regcode:lineArray[0],regname:lineArray[1]};
    regionArray.push(addLine);
  }
  setSearchRegionList(regionArray);
} */

export const setMetaTags = ({ viewport="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" }) => {
    document
      .querySelector('meta[property="og:viewport"]')
      .setAttribute("content", `${viewport}`);
};

export const SetFindingAddress = (objs,regionList) => {
  const sido_code = objs.rf_sido;
  const sigungu_code = objs.rf_sigungu;
  const dongri_code = objs.rf_dongri;
  let taddress = "";
  if(isNull(sido_code)) return "";
  taddress = regionList.filter(el=>el.regcode===sido_code)[0].regname;
  if(isNull(sigungu_code)) return taddress;
  taddress += ' '+regionList.filter(el=>el.regcode===sigungu_code)[0].regname;
  if(isNull(dongri_code)) return taddress;
  taddress += ' '+regionList.filter(el=>el.regcode===dongri_code)[0].regname;
  return taddress;
}

export const GetAuthority = (sadmin,sregname) => {
  let sAuthority = "";
  if(sadmin===1){ 
    sAuthority="운영자";
  } else if(sadmin===2) { 
    sAuthority="부운영자";
  } else {
    if(isNull(sregname)) sAuthority = "지역관리자";
    else sAuthority = sregname;
  }
  return sAuthority;
}

export const SetProductTitle = (payment) => {
  let retString = payment.ph_mode===1?"둥지요금제":payment.ph_mode===2?"장터요금제":payment.ph_mode===3?"둥지프리미엄광고":"장터프리미엄광고";
  if(payment.ph_mode===1) retString += ` (슬럿:${payment.d_slot},기간:${payment.pay_terms}개월)`;
  else if(payment.ph_mode===2) retString += ` (카테고리:${payment.j_slot},기간:${payment.pay_terms}개월)`;
  return retString;
}