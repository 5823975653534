/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 

import { OnlyNumerics, isObjEmpty } from '../common/Common';
import closeButton from "../../images/icon_close.png";

const FilterMinMax = ({ varFilterMode,varPopupFilterMinMax,varFixedFilterList,setFixedFilterList,onPopupFilterMinMaxHandler}) => {
  //필터변수
  const [varFilterData,setFilterData] = useState({});
  //초가값 저장
  const [varInitialData,setInitialData] = useState({});

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(varFilterMode > 0) {
      const currentData = varFixedFilterList.filter(element=>element.vsm_idx===varFilterMode);
      console.log(currentData[0]);
      setFilterData(currentData[0]);
      setInitialData(currentData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varFilterMode,varPopupFilterMinMax]);
  
  useEffect(() => {
    console.log(varFilterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varFilterData]);

  //min&max- 전체유무
  const onSelectMinMaxHandler = (e) => {
    const bmode = e.currentTarget.checked; 
    //필터배열정리
    if(bmode) { //전체
      setFilterData({...varFilterData, vsm_all:Number(bmode),vsm_select:Number(!bmode),vsm_min:0,vsm_max:0});
    } else {
      setFilterData({...varFilterData, vsm_all:Number(bmode),vsm_select:Number(!bmode)});
    }
    //전체배열에 반영
/*     let copyData = [...varFixedFilterList];
    const findIndex = copyData.findIndex(element=>element.vsm_idx===varFilterMode);
    copyData[findIndex].vsm_select = Number(!bmode)};
    setFixedFilterList(copyData); */
  }

  //min&max- 입력값
  const onChangeMinMaxHandler = (e) => {
    const { name,value } = e.currentTarget;
    let ivalue = Number(OnlyNumerics(value));
    //필터배열정리
    let iall = 1;
    let iselect = 0;
    let value2 = varFilterData.vsm_min;
    if(name === "vsm_min") value2 = varFilterData.vsm_max;

    if((!isNaN(ivalue) && ivalue > 0) || value2 > 0){
      iall = 0;
      iselect = 1;
    } 
    const allBox = document.getElementById('vsm_all');
    allBox.checked = iall;

    setFilterData({...varFilterData, [name]:ivalue,vsm_all:iall,vsm_select:iselect});
  }

  //검색시작
  const onSearchingFilterHandler = (e) =>{
    if(varFilterData.vsm_min > varFilterData.vsm_max){
      alert("정확한 범위설정 후 적용해주세요.");
      return false;
    }
    let copyData = [...varFixedFilterList];
    const findIndex = copyData.findIndex(element=>element.vsm_idx===varFilterMode);
    copyData[findIndex] = {...varFilterData};
    //검색실행
    console.log(varFilterData,copyData);
    setFixedFilterList(copyData);
    onPopupFilterMinMaxHandler(e,-1);
    //팝업창 닫기
  }
  //창닫기
  const onClosePopupHandler = (e) => {
    //값초기화
    const allBox = document.getElementById('vsm_all');
    allBox.checked = varInitialData.vsm_all;
    const minBox = document.getElementById('vsm_min');
    minBox.value = varInitialData.vsm_min;
    const maxBox = document.getElementById('vsm_max');
    maxBox.value = varInitialData.vsm_max;    
    onPopupFilterMinMaxHandler(e,false);
  }

  //Focus --> Min/Max
  const onFocusHandler = (e) => {
    const value = Number(e.currentTarget.value);
    if(value === 0) e.currentTarget.value = '';
  }
  const onBlurHandler = (e) => {
    const value = e.currentTarget.value;
    if(value === '') e.currentTarget.value = 0;
  }
  
  return isObjEmpty(varFilterData)?null:(
    <div className="layer-pop mini_ft04 h_auto" style={varPopupFilterMinMax?{display:"block"}:{display:"none"}}>
      <div className="pop_head">
        <div className="layer_tit">{varFilterData.vsm_name}</div>
        <div className="close_layer top_close_btn"><img src={closeButton} alt="" onClick={(e)=>onClosePopupHandler(e)}/></div>
      </div>
      <div className="layer_inner">
        <div className="con_box" style={{marginTop:"10px"}}>
          <ul className="dan3">
            <li className="terms_chk33">
              <input type="checkbox" name="vsm_all" id="vsm_all" className="chk1" 
                  checked={varFilterData.vsm_all===1?true:false}  onChange={e=>onSelectMinMaxHandler(e)}/>
              <i></i><label htmlFor="vsm_all">전체</label>
            </li>
          </ul>
          <div className="ipt_box">
            <input type="text" name="vsm_min" id="vsm_min" style={{textAlign:"center"}} value={varFilterData.vsm_min} onFocus={onFocusHandler} onBlur={onBlurHandler} onChange={e=>onChangeMinMaxHandler(e)}/>
            <span className="mini_bar">~</span>
            <input type="text" name="vsm_max" id="vsm_max" style={{textAlign:"center"}} value={varFilterData.vsm_max} onFocus={onFocusHandler} onChange={e=>onChangeMinMaxHandler(e)}/>
            <span className="danwe">{varFilterData.vsm_mode===1?"만원":"평"}</span>
          </div>
        </div>
      </div>
      <div className="btn_bottom one_btn">
        <button className="btn_style_yes" onClick={e=>onSearchingFilterHandler(e)}>적용</button>
      </div>
    </div>
  );
}
export default FilterMinMax