import React, { useState, useEffect } from "react"; 
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { v4 as uuidv4 } from 'uuid';

import { addThousandPoint, isObjEmpty, remainDays, ChangeMobileFormat } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import PaymentMethod from '../../components/include/PaymentMethod';
import VoucherSlots from '../../components/voucher/VoucherSlots';

const BuyVoucher = () => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  // 이용권 형식 : 1:둥지/2:장터
  const ph_mode = Number(localStorage.getItem("mem_mode"));

  let { ph_idx } = useParams();
  const navigate = useNavigate();

  const [varVoucherMode, setVoucherMode] = useState(0);
  const [varVoucherAmount, setVoucherAmount] = useState(0);
  const [varPaymentData, setPaymentData] = useState({});
  const [varVoucherDoongjiList, setVoucherDoongjiList] = useState([]);
  const [varBankAccountList, setBankAccountList] = useState([]);

  const [varExtendVoucher, setExtendVoucher] = useState(0);
  const [varExtendVoucherIndex, setExtendVoucherIndex] = useState(false);

  const [varCompanyName, setCompanyName] = useState('');
  const [varMemberMobile, setMemberMobile] = useState('');

  // 프리미엄 광고 목록 가져오기
  const getVoucherList = async () => {
    const sendData = { mem_idx: mem_idx, ph_mode: ph_mode, ph_idx: Number(ph_idx) };
    console.log(sendData);
    try {
      const res = await axios.post(`${CONFIG.SERVER_HOST}/_get_voucher_list`, sendData);
      const datas = res.data;
      console.log(datas);
      if (datas.retvalue === 1) {
        setBankAccountList(datas.banks);
        // 검색된 목록
        let products = datas.results;
        let payments = datas.payments;

        // Determine prefix based on ph_mode
        const prefixName = ph_mode === 1 ? "둥지_매물 " : "장터 등록 카테고리 ";

        products = products.map(product => {
          const voItems = product.vo_slot;
          let vo_items = `${prefixName}${voItems}`;

          if (ph_mode === 1) {
            vo_items += "개 등록 이용권";
          } else if (ph_mode === 2) {
            vo_items += "개 선택 이용권";
          }

          // Set vo_name based on ph_mode
          let vo_name = "";
          if (ph_mode === 1) {
            vo_name = `둥지_매물 ${voItems}개 등록 이용권`;
          } else if (ph_mode === 2) {
            vo_name = `장터 등록 카테고리 ${voItems}개 선택 이용권`;
          }

          return {
            ...product,
            vo_items,
            vo_name
          };
        });

        // Update payments with formatted vo_items
        if (payments?.length > 0) {
          payments = payments.map(payment => {
            const matchedProduct = products.find(product => product.vo_idx === payment.vo_idx);
            if (matchedProduct) {
              return {
                ...payment,
                vo_items: matchedProduct.vo_items
              };
            }
            return payment;
          });
        }

        setMemberMobile(datas.members[0].mem_mobile);
        console.log(products);
        setVoucherDoongjiList(products);

        // 이용권신청정보
        if (payments?.length > 0) {
          setPaymentData(payments[0]);
          setVoucherAmount(datas.payments[0].ph_amount);
        }

        // 둥지수행업체명
        setCompanyName(datas.company_name);

        // 이용권 번호
        const voucherIdx = datas.voucherIdx;
        setExtendVoucherIndex(voucherIdx);

        // 이용권 사용유무 - 잔여일자
        if (datas.expiredate?.length > 0) {
          const expdate = datas.expiredate[0].expiredate;
          const remaindays = remainDays(expdate);
          if (remaindays > 0) {
            if (remaindays <= 10) {
              setExtendVoucher(1);
            } else {
              alert('현재 이용권을 사용중입니다. 이용권 사용기간 내에는 추가 구매할 수 없습니다. \n구매취소를 원하시거나 더 자세한 사항은 관리자에게 문의해주세요.');
              navigate('/Mypage/PremiumList');
            }
          }
        }
      }
    } catch (error) {
      console.error("Error fetching voucher list:", error);
      alert('이용권 목록을 불러오는 중 오류가 발생했습니다.');
    }
  }

  useEffect(() => {
    // 전체 백화면 색 변경
    document.getElementById('main_body').style.backgroundColor = '#FFFFFF';
    // 페이지형식지정 - 둥지/장터
    setVoucherMode(Number(ph_mode));
    // 신규 입력값 저장 배열
    const newData = {
      ph_idx: ph_idx,
      pay_idx: 0,
      ph_mode: ph_mode,
      pay_mem_idx: mem_idx,
      vo_idx: 0,
      pay_terms: 0,
      vo_slot: 0,
      vo_name: "",
      vo_items: "",
      vo_amount: 0,
      ph_method: 0,
      bh_idx: 0,
      ph_sender: "",
      ph_extend: 0,
      ph_status: 1
    };
    setPaymentData(newData);
    // 서버에서 이용권 목록 가져오기
    getVoucherList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ph_mode, ph_idx]);

  // 이용권 선택
  const onSelectVoucherHandler = (e, vo_idx, vo_items, vo_name, vo_amount) => {
    e.preventDefault();
    const imonth = varPaymentData.pay_terms;
    setPaymentData({
      ...varPaymentData,
      vo_idx: vo_idx,
      vo_items: vo_items,
      vo_name: vo_name,
      vo_amount: vo_amount,
      ph_extend: varExtendVoucher
    });

    // 선택표시조정
    const groupBoxes = document.querySelectorAll('.voucher_items');
    groupBoxes.forEach(box => box.classList.remove('on'));

    const selectedBox = document.getElementById(`voucher_items_${vo_idx}`);
    if (selectedBox) selectedBox.classList.add('on');

    console.log(varPaymentData);
    // 결제금액 계산
    CalculateAmount(vo_amount, imonth);
  }

  // 사용기간 선택
  const onChangeTermsHandler = (e) => {
    const { value } = e.currentTarget;
    const imonth = Number(value);
    setPaymentData({ ...varPaymentData, pay_terms: imonth });

    const vo_amount = varPaymentData.vo_amount;
    console.log(vo_amount, imonth);
    // 결제금액 계산
    CalculateAmount(vo_amount, imonth);
  }

  // 결제금액 계산
  const CalculateAmount = (vo_amount, imonth) => {
    // 사용기간에 따른 할인율 적용
    let dprice = 1.0;
    if (imonth === 3) dprice = 0.97;
    else if (imonth === 6) dprice = 0.95;
    else if (imonth === 12) dprice = 0.9;
    // 실제 구매금액
    const ph_amount = Math.round(vo_amount * imonth * dprice);
    setVoucherAmount(ph_amount);
  }

  // 결제하기 
  const onPaymentProgress = async (e) => {
    // 선택항목 점검
    if (![1, 3, 6, 12].includes(varPaymentData.pay_terms)) {
      alert('이용 기간을 먼저 선택하세요.');
      return false;
    } else if (varPaymentData.vo_idx === 0) {
      alert('이용권 종류를 먼저 선택하세요.');
      return false;
    }

    if (varPaymentData.ph_method === 0) {
      if (varPaymentData.bh_idx === 0) {
        alert('입금계좌를 먼저 선택하세요.');
        return false;
      } else if (varPaymentData.ph_sender === "") {
        alert('입금자명을 먼저 입력하세요.');
        return false;
      }
    }

    if (!window.confirm('구매내역을 저장하시겠습니까?')) return false;

    // 주문번호 생성
    let randomId = '';
    if (varPaymentData.ph_idx > 0) {
      randomId = varPaymentData.ph_code;
    } else {
      randomId = uuidv4();
    }

    const sendData = {
      randomId: randomId,
      ph_method: varPaymentData.ph_method,
      ph_amount: varVoucherAmount,
      payInfo: varPaymentData
    };
    console.log(sendData);
    try {
      const res = await axios.post(`${CONFIG.SERVER_HOST}/_update_voucher_information`, sendData);
      const datas = res.data;
      console.log(datas);
      if (datas.retvalue === 1) {
        if (varPaymentData.ph_method === 1) {  // 카드결제
          console.log(varPaymentData);
          const payString = `${randomId}|${varVoucherAmount}|${varCompanyName}|${varPaymentData.vo_items + '_' + varPaymentData.pay_terms}`;
          console.log(payString);
          const enCodedString = encodeURI(payString); // base64_encode(payString);
          console.log(enCodedString);
          if (varPaymentData.ph_idx === 0) ph_idx = datas.ph_idx;
          navigate(`/TossPayment/${ph_idx}/${enCodedString}`);
        } else {  // 무통장 입금
          if (Number(varPaymentData.ph_idx) === 0) {
            alert('이용권 구매에 대한 무통장입금이 신청되었습니다.');
            SendAligoMessage();
          } else {
            alert('이용권 구매정보가 재저장되었습니다.');
            navigate('/MyPage/PaymentList');
          }
        }
      } else {
        alert(datas.message);
      }
    } catch (error) {
      console.error("Error updating voucher information:", error);
      alert('이용권 정보를 업데이트하는 중 오류가 발생했습니다.');
    }
  }

  // 무통장 알리고 메시지 발송
  const SendAligoMessage = async () => {
    const receiverPhone = ChangeMobileFormat(varMemberMobile);
    const bankData = varBankAccountList.find(el => el.bh_idx === varPaymentData.bh_idx);
    
    const message = `[이용권구매_입금요청]
이용권 구매완료를 위해 입금 부탁드립니다.
(관리자 입금 확인 후 이용이 가능합니다.)

▶계좌번호: ${bankData.bl_name}
${bankData.bh_account}
▶예금주: ${bankData.bh_owner}
▶금액: ${addThousandPoint(varVoucherAmount)} 원
`;
    const sendData = { sender: "02-3144-2654", receiver: receiverPhone, msg: message, msg_type: 'LMS' };
    console.log(sendData);
    try {
      const res = await axios.post(`${CONFIG.SERVER_HOST}/_send_message`, sendData);
      console.log(res.data);
      alert(res.data.message);
      navigate('/MyPage/PaymentList');
    } catch (error) {
      console.error("Error sending message:", error);
      alert('메시지를 발송하는 중 오류가 발생했습니다.');
    }
  }

  return isObjEmpty(varVoucherDoongjiList) || isObjEmpty(varPaymentData) ? null : (
    <>
      <Header />
      <HeadTitle titleString={"이용권 구매"} backString={'/MyPage/PaymentList'} />
      <div id="content">
        <div className="pd_wrap04 ad_pay_list_wrap" style={{ paddingTop: "0px" }}>
          <div className="tb_wrap">
            <div className="center_wrap">
              <div className="buy_dungji_wrap"> 
                <div className="form_wrap">
                  <div className="tit07_1">이용권 종류
                    {varVoucherMode === 2 ? null : <span className="gray_txt"><span className="gray_bar">&nbsp;</span>부동산 매물 등록 수량</span>}
                  </div>
                  <div className="pass_type_wrap">
                    <ul>
                      {varVoucherDoongjiList.map((voucher, i) => (
                        varExtendVoucher && varExtendVoucherIndex > 0 ?
                          (varExtendVoucherIndex === voucher.vo_idx && (
                            <VoucherSlots
                              key={`voucher_${i}`}
                              varPaymentData={varPaymentData}
                              varVoucherSlot={voucher}
                              onSelectVoucherHandler={onSelectVoucherHandler}
                            />
                          )) :
                          <VoucherSlots
                            key={`voucher_${i}`}
                            varPaymentData={varPaymentData}
                            varVoucherSlot={voucher}
                            onSelectVoucherHandler={onSelectVoucherHandler}
                          />
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="form_wrap">
                  <div className="tit07_1">이용 기간</div>
                  <div className="use_period_wrap">
                    <ul className="dan4">
                      <li className="terms_chk33">
                        <input
                          type="radio"
                          name="pay_terms"
                          className="chk1"
                          id="pay_terms1"
                          value="1"
                          checked={varPaymentData.pay_terms === 1}
                          onChange={onChangeTermsHandler}
                        />
                        <i></i><label htmlFor="pay_terms1">1개월</label>
                      </li>
                      <li className="terms_chk33">
                        <input
                          type="radio"
                          name="pay_terms"
                          className="chk1"
                          id="pay_terms3"
                          value="3"
                          checked={varPaymentData.pay_terms === 3}
                          onChange={onChangeTermsHandler}
                        />
                        <i></i><label htmlFor="pay_terms3">3개월</label>
                        <div className="perct"><span>3%↓</span></div>
                      </li>
                      <li className="terms_chk33">
                        <input
                          type="radio"
                          name="pay_terms"
                          className="chk1"
                          id="pay_terms6"
                          value="6"
                          checked={varPaymentData.pay_terms === 6}
                          onChange={onChangeTermsHandler}
                        />
                        <i></i><label htmlFor="pay_terms6">6개월</label>
                        <div className="perct"><span>5%↓</span></div>
                      </li>
                      <li className="terms_chk33">
                        <input
                          type="radio"
                          name="pay_terms"
                          className="chk1"
                          id="pay_terms12"
                          value="12"
                          checked={varPaymentData.pay_terms === 12}
                          onChange={onChangeTermsHandler}
                        />
                        <i></i><label htmlFor="pay_terms12">12개월</label>
                        <div className="perct"><span>10%↓</span></div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form_wrap">
                  <div className="tit07_1">결제 금액{varExtendVoucher ? " (연장)" : null}</div>
                  <div className="buy_con_box">
                    <div className="total_price">
                      <span>부가세포함</span><span className="tt_price">{addThousandPoint(varVoucherAmount)} 원</span>
                    </div>
                  </div>
                </div>
                <PaymentMethod varBankAccountList={varBankAccountList} varPaymentData={varPaymentData} setPaymentData={setPaymentData} />
                <div className="btn_bottom one_btn">
                  <button className="btn_style_yes" id="payment-button" onClick={onPaymentProgress}>
                    {varPaymentData.ph_method === 0
                      ? Number(ph_idx) === 0 ? "구매신청" : "구매정보저장"
                      : "PG결제하기"}{varExtendVoucher ? " (연장)" : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </>
  );
}
export default BuyVoucher;
