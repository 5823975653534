import React from 'react';
import { Link } from 'react-router-dom';

function Tabbar({varMenuType}){
  const adminLoginState = Number(localStorage.getItem('adminLoginState'));
  const adminMode = Number(localStorage.getItem('adminMode'));

  let memuItems = "";
  switch(adminMode) {
    default:
    case 1:
      memuItems = "AdminState"; break;
    case 2:
      memuItems = "SubAdminState"; break;
    case 3:
      memuItems = "AgencyAdminState"; break;
  }

  let menuAvailablesString = localStorage.getItem('menuAvailables');
  if(menuAvailablesString === null || menuAvailablesString === "" ) {
    menuAvailablesString = "0|0|0|0|0";
  }
  const menuAvailablesArray = menuAvailablesString.split('|');
  //console.log(menuAvailablesString);

  const onImpossibleUseHandler = (e) => {
    alert('사용권한이 없는 메뉴입니다.');
  }

  return(
    <div className="top-menu">
      {adminLoginState === 0 ? "" :
        <>
          {Number(menuAvailablesArray[0])===0?<div className="top-option gray" onClick={(e)=>onImpossibleUseHandler(e)}>환경설정</div>
            :<Link to={`/Admin/Environment/${memuItems}`} className={varMenuType==="ENVIRONMENT"?"selected":""}>환경설정</Link>
          }
          {Number(menuAvailablesArray[1])===0?<div className="top-option gray" onClick={(e)=>onImpossibleUseHandler(e)}>회원관리</div>
            :<Link to="/Admin/Member/MemberLists/1" className={varMenuType==="MEMBER"?"selected":""}>회원관리</Link>
          }
          {Number(menuAvailablesArray[2])===0?<div className="top-option gray" onClick={(e)=>onImpossibleUseHandler(e)}>둥지/장터관리</div>
            :<Link to="/Admin/ProductList/DoongjiList" className={varMenuType==="PRODUCTS"?"selected":""}>둥지/장터관리</Link>
          }
          {Number(menuAvailablesArray[3])===0?<div className="top-option gray" onClick={(e)=>onImpossibleUseHandler(e)}>요금/이용권관리</div>
            :<Link to="/Admin/RatePlan/DoongjiRatePlan" className={varMenuType==="PAYMENTS"?"selected":""}>요금/이용권관리</Link>
          }
          {Number(menuAvailablesArray[4])===0?<div className="top-option gray" onClick={(e)=>onImpossibleUseHandler(e)}>앱 게시판 관리</div>
            :<Link to="/Admin/Board/BoardNotice" className={varMenuType==="BOARDS"?"selected":""}>앱 게시판 관리</Link>
          }
          {Number(menuAvailablesArray[5])===0?<div className="top-option gray" onClick={(e)=>onImpossibleUseHandler(e)}>관리자 게시판 관리</div>
            :<Link to="/Admin/AdminBoard/1" className={varMenuType==="ADMINBOARDS"?"selected":""}>관리자 게시판 관리</Link>
          }
          
        </>
      }
    </div>
  )
}

export default Tabbar;



