/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from '../../../configs/Configs';

//2단계 변수 설정의 경우 
const VariableSubwayBlock = () => {
  //변수 선택 항목 목록
  const [varSubwayLineList,setSubwayLineList] = useState([]); 
  const [varSubwayStationList,setSubwayStationList] = useState([]);

  //선택된 항목 
  const [varCurrentSubwayLineName,setCurrentSubwayLineName] = useState("");  
  const [varCurrentSubwayStationIndex,setCurrentSubwayStationIndex] = useState(0);
  const [varCurrentSubwayStationName,setCurrentSubwayStationName] = useState("");

  //신규
  const [varInputSubwayLineName,setInputSubwayLineName] = useState("");  
  const [varInputSubwayStationName,setInputSubwayStationName] = useState("");  

  //변경
  const [varEditMode1,setEditMode1] = useState(false);
  const [varEditMode2,setEditMode2] = useState(false);

  const getSubwayVariableList = async(imode) => {
    let sendData = {imode:imode,subway_line:""};
    if(imode === 2) sendData = {imode:imode,subway_line:varCurrentSubwayLineName};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_subway_variables`,sendData).then((res) => {
      console.log(res.data.results);
      if(res.data.retvalue === 1) {
        if(imode === 1) {
          setSubwayLineList(res.data.results);
          setSubwayStationList([]);
        } else {
          setSubwayStationList(res.data.results);
        }
      }
    });
  }
  //최초실행
  useEffect(()=>{
    getSubwayVariableList(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //상부변수 항목값이 변경되면 - 하부 목록 가져오기
  useEffect(()=>{
    getSubwayVariableList(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCurrentSubwayLineName]);

  //항목선택
  const SelectVariableItemHandler = (e,imode) => {
    const { value } = e.currentTarget;
    const selectObj = e.currentTarget;
    if(imode === 1) {
      setCurrentSubwayLineName(value);
      setInputSubwayLineName(value);
      const box1 = document.getElementById('keyinItem1');
      const box2 = document.getElementById('btnReg1');
      if(!box1.classList.contains('adm_hide')) box1.classList.add('adm_hide');
      if(!box2.classList.contains('adm_hide')) box2.classList.add('adm_hide');
      setEditMode1(false);
    } else {
      setCurrentSubwayStationIndex(Number(value));
      const svalue = selectObj.options[selectObj.selectedIndex].text;
      setCurrentSubwayStationName(svalue);
      setInputSubwayStationName(svalue);
      const box1 = document.getElementById('keyinItem2');
      const box2 = document.getElementById('btnReg2');
      if(!box1.classList.contains('adm_hide')) box1.classList.add('adm_hide');
      if(!box2.classList.contains('adm_hide')) box2.classList.add('adm_hide');
      setEditMode1(false);
    }
  }

  //항목입력
  const keyinVariableItemHandler = (e,imode) => {
    const keyInString = e.currentTarget.value;
    if(imode === 1) {
      setInputSubwayLineName(keyInString);
    } else {
      setInputSubwayStationName(keyInString);
    }
  }

  //항목삭제
  const deleteVariableItemHandler = async (e,imode) => {
    let sendData = {};
    let noSelect = false;
    if(Number(imode) === 1){ 
      sendData = {imode:imode,delLine:varCurrentSubwayLineName};
      if(varCurrentSubwayLineName === "") noSelect = true;
    } else {
      sendData = {imode:imode,delLine:varCurrentSubwayStationIndex};
      if(varCurrentSubwayStationIndex === 0) noSelect = true;
    } 

    if(noSelect) {
      alert('삭제할 변수항목이 선택되어 있지 않습니다.');
      return false;
    }

    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_subway_variables`,sendData).then((res) => {
      if(res.data.retvalue === 1) {
        if(Number(imode) ===1 ) { //지하철노선
          const copyList1 = varSubwayLineList.filter(element=>element.subway_line !== varCurrentSubwayLineName);
          setSubwayLineList(copyList1);
          const copyList2 = varSubwayStationList.filter(element=>element.subway_line !== varCurrentSubwayLineName);
          setSubwayStationList(copyList2);
          setCurrentSubwayLineName("");
          setInputSubwayLineName("");
          setSubwayStationList([]);
        } else  { //지하철 역
          const copyList = varSubwayStationList.filter(element=>Number(element.subway_idx) !== Number(varCurrentSubwayStationIndex));
          setSubwayStationList(copyList);
          setCurrentSubwayStationIndex(0);
          setInputSubwayStationName('');
        }
      } else {
        alert(res.data.message);
      }
    });
  }

  //항목업데이트
  const updateVariableItemHandler = async (e,imode) => {
    let sendData = {};
    if(imode === 1){  //노선
      if(varInputSubwayLineName === "") { 
        alert('등록할 노선명을 먼저 입력하십시요.');return false;
      } else {
        //입력된 노선명의 중복 유무 확인
        const copyList = varSubwayLineList.filter(element=>element.subway_line === varInputSubwayLineName);
        if(copyList.length > 0) {
          alert('이미 존재하는 노선입니다.');return false;
        }
        //변경모드 -> 변경할 노선 선택 유무 확인
        if(varEditMode1){
          if(varCurrentSubwayLineName === "") {
            alert('변경할 노선이 선택되어 있지 않습니다.\n변경할 노선을 먼저 선택하십시요.');return false;
          }
        }
      }

      //신규등록이면 노선배열에 추가
      if(!varEditMode1) {
        const addSubwayLine = {subway_idx:0,subway_line:varInputSubwayLineName};
        setSubwayLineList([...varSubwayLineList,addSubwayLine]);
        setCurrentSubwayLineName(varInputSubwayLineName);
        return false;
      } else {
        sendData = {imode:imode,editMode:Number(varEditMode1),lines:'',olds:varCurrentSubwayLineName,news:varInputSubwayLineName,idx:0};
      }
    } else {  //전철역
      if(varInputSubwayStationName === "") {
        alert('등록할 지하철역이 입력되어 있지 않습니다.');return false;
      }
      //노선이 선택되어 있는지 유무
      if(varCurrentSubwayLineName === "") {
        alert('지하철역이 포함되는 노선이 선택되어 있지 않습니다.\n작업대상 노선을 먼저 선택하십시요.');return false;
      }
      //노선내에 동일한 지하철역명이 존재하는지 유무 확인
      const copyList = varSubwayStationList.filter(element=>element.subway_line===varCurrentSubwayLineName && element.subway_name===varInputSubwayStationName);
      if(copyList.length > 0) {
        alert('이미 존재하는 역입니다.');return false;
      }
      let curIdx = 0;
      if(varEditMode2) {
        if(varCurrentSubwayStationIndex === 0) {
          alert('변경할 지하철역이 선택되어 있지 않습니다.');return false;
        }
        curIdx = varCurrentSubwayStationIndex;
      } 
      sendData = {imode:imode,editMode:Number(varEditMode2),lines:varCurrentSubwayLineName,olds:varCurrentSubwayStationName,news:varInputSubwayStationName,idx:curIdx};
    }

    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_subway_variables`,sendData).then((res) => {
      console.log(res.data);
      const datas = res.data;
      if(datas.retvalue === 1) {
        if(imode === 1) { //지하철노선 - 노선명변경
          let lineLists = [...varSubwayLineList];
          const findIndex = varSubwayLineList.findIndex(element=>element.subway_line === varCurrentSubwayLineName);
          lineLists[findIndex].subway_line = varInputSubwayLineName;
          setSubwayLineList([...lineLists]);
          setCurrentSubwayLineName(varInputSubwayLineName);
          setInputSubwayLineName('');
          //getSubwayVariableList(1);
          //버튼 및 입력창 상태 변경
          const box1 = document.getElementById('keyinItem1');
          const box2 = document.getElementById('btnReg1');
          if(!box1.classList.contains('adm_hide')) box1.classList.add('adm_hide');
          if(!box2.classList.contains('adm_hide')) box2.classList.add('adm_hide');
        } else { 
          if(varEditMode2) {  //지하철역명 변경
            let stationLists = [...varSubwayStationList];
            const findIndex = varSubwayStationList.findIndex(element=>element.subway_idx === varCurrentSubwayStationIndex);
            stationLists[findIndex].subway_name = varInputSubwayStationName;
            setSubwayStationList([...stationLists]);
          } else {  //지하철역 추가
            setCurrentSubwayStationIndex(datas.newIndex);
            getSubwayVariableList(2);
          }
          setCurrentSubwayStationName(varInputSubwayStationName);
          setInputSubwayStationName('');

          //버튼 및 입력창 상태 변경
          const box1 = document.getElementById('keyinItem2');
          const box2 = document.getElementById('btnReg2');
          if(!box1.classList.contains('adm_hide')) box1.classList.add('adm_hide');
          if(!box2.classList.contains('adm_hide')) box2.classList.add('adm_hide');
        }
      } else {
        alert(res.data.message);
      }
    });
  }

  //초기화 - 추가를 위해
  const InitializeVariableItemHandler = (e,imode) => {
    if(imode === 1) {
      const box1 = document.getElementById('keyinItem1');
      const box2 = document.getElementById('btnReg1');
      setCurrentSubwayLineName("");
      setInputSubwayLineName("");
      setSubwayStationList([]);
      if(box1.classList.contains('adm_hide')) box1.classList.remove('adm_hide');
      if(box2.classList.contains('adm_hide')) box2.classList.remove('adm_hide');
      setCurrentSubwayStationName("");
      setCurrentSubwayStationIndex(0);
      setInputSubwayStationName("");
      document.getElementById('btnReg1').innerText = "등록하기";
      setEditMode1(false);
    } else {
      const box1 = document.getElementById('keyinItem2');
      const box2 = document.getElementById('btnReg2');
      if(box1.classList.contains('adm_hide')) box1.classList.remove('adm_hide');
      if(box2.classList.contains('adm_hide')) box2.classList.remove('adm_hide');
      setCurrentSubwayStationName("");
      setCurrentSubwayStationIndex(0);
      setInputSubwayStationName("");
      document.getElementById('btnReg2').innerText = "등록하기";
      setEditMode1(false);
    }
  }
  const changeVariableItemHandler = (e,imode) => {
    if(imode === 1) {
      if(varCurrentSubwayLineName === "") {
        alert('변경할 노선이 선택되어 있지 않습니다.');
        return false;
      }
      const box1 = document.getElementById('keyinItem1');
      const box2 = document.getElementById('btnReg1');
      if(box1.classList.contains('adm_hide')) box1.classList.remove('adm_hide');
      if(box2.classList.contains('adm_hide')) box2.classList.remove('adm_hide');
      document.getElementById('btnReg1').innerText = "변경하기";
      setEditMode1(true);
    } else {
      if(varCurrentSubwayStationIndex === 0) {
        alert('변경할 전철역이 선택되어 있지 않습니다.');
        return false;
      }
      const box1 = document.getElementById('keyinItem2');
      const box2 = document.getElementById('btnReg2');
      if(box1.classList.contains('adm_hide')) box1.classList.remove('adm_hide');
      if(box2.classList.contains('adm_hide')) box2.classList.remove('adm_hide');
      document.getElementById('btnReg2').innerText = "변경하기";
      setEditMode2(true);
    }
  }

  return (
    <>
      <tr>
        <th>지하철 노선</th>
        <td className="select-items">
          <select size="5" onChange={(e)=>SelectVariableItemHandler(e,1)} value={varCurrentSubwayLineName}>
            <option value={""}>아래의 항목을 선택하세요</option>  
            { varSubwayLineList.length > 0 ? varSubwayLineList.map((items,i) => (
              <option key={'subwayline_'+i} value={items.subway_line}>{items.subway_line}</option>     
            )) : null }
          </select>
        </td>
        <td className="select-addition"><button onClick={(e)=>deleteVariableItemHandler(e,1)} className="btn line">삭제</button></td>
        <td className="select-addition"><button onClick={(e)=>changeVariableItemHandler(e,1)} className="btn line">변경</button></td>
        <td className="select-addition"><button onClick={(e)=>InitializeVariableItemHandler(e,1)} className="btn line">신규</button></td>
        <td className="select-addition sel-label"><label>변수항목</label></td>
        <td className="select-input">
          <input type="text" className="line_input adm_hide" onChange={(e)=>keyinVariableItemHandler(e,1)} id="keyinItem1" value={varInputSubwayLineName} />
        </td>
        <td className="select-addition2">
          <button onClick={(e)=>updateVariableItemHandler(e,1)} id="btnReg1" className="btn line adm_hide" style={{width:"90px"}}>등록하기</button>
        </td>
      </tr>
      <tr>
        <th>지하철 역</th>
        <td className="select-items">
          <select size="5" onChange={(e)=>SelectVariableItemHandler(e,2)} value={varCurrentSubwayStationIndex}>
            <option value={0}>아래의 항목을 선택하세요</option> 
            { varSubwayStationList.length > 0 ? varSubwayStationList.map((items2,i) => (
              <option key={'items2_'+i} value={items2.subway_idx}>{items2.subway_name}</option>     
            )) : null }
          </select>
        </td>
        <td className="select-addition"><button onClick={(e)=>deleteVariableItemHandler(e,2)} className="btn line">삭제</button></td>
        <td className="select-addition"><button onClick={(e)=>changeVariableItemHandler(e,2)} className="btn line">변경</button></td>
        <td className="select-addition"><button onClick={(e)=>InitializeVariableItemHandler(e,2)} className="btn line">신규</button></td>
        <td className="select-addition sel-label"><label>변수항목</label></td>
        <td className="select-input">
          <input type="text" className="station_input adm_hide" onChange={(e)=>keyinVariableItemHandler(e,2)} id="keyinItem2" value={varInputSubwayStationName} />
        </td>
        <td className="select-addition2">
          <button onClick={(e)=>updateVariableItemHandler(e,2)} id="btnReg2" className="btn line adm_hide" style={{width:"90px"}}>등록하기</button>
        </td>
      </tr>
    </>
  );
}
export default VariableSubwayBlock;