/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect,useState } from "react";
import {useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';


import closeIcon from '../../images/icon_close.png';

//둥지매물 삭제를 위한 팝업창
const DoongjiShow = ({varPopupDoongjiShow,varCurrentDoongjiIndex,varAvailableCount,setAvailableCount,varRushSales,varDoongjiList,setDoongjiList,onPopupDoongjiShowHandler,varRemainDays}) => {
  //게시상태저장변수
  const [varShows,setShows] = useState(0);
  const [varOldShows,setOldShows] = useState(0);
  const [varStatus,setStatus] = useState(0);

  const navigator = useNavigate();

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(varCurrentDoongjiIndex > 0) {
      const copyData = varDoongjiList.find(el=>el.rp_idx===varCurrentDoongjiIndex);
      console.log(copyData);
      setOldShows(copyData.rp_show);
      setShows(Number(copyData.rp_show));
      setStatus(Number(copyData.rp_status));
      document.getElementById('rp_show'+copyData.rp_show).checked=true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCurrentDoongjiIndex]);

  //게시상태변경
  const onChangeShowingHandler = (e) => {
    const showState = Number(e.currentTarget.value);
    console.log("varAvailableCount :", varAvailableCount,"showState :", showState, "varOldShows :", varOldShows);
    //    if(varOldShows !== 2 && showState === 2 && varStatus===0) {
    if(varOldShows === 1 && showState !== 1 && varStatus===0) {
      if(varRemainDays < 0) {
        alert('이용권이 없습니다. 이용권을 구매하세요');
        navigator('/MyPage/BuyVoucher/0');
        return false;
      }
      if(varAvailableCount <= 0) {
        alert('이미 게시된 매물들로 인하여 추가로 매물을 게시할 수 없습니다.');
        return false;
      }
      const curDoongjiData = varDoongjiList.find(el=>el.rp_idx===varCurrentDoongjiIndex);
      if(curDoongjiData.rp_rush_sale === 1 && varRushSales > 0) {
        alert('급매는 단 한 매물만 적용할 수 있습니다. 이 매물을 급매매물로 지정하려면 다른 급매매물을 먼저 일반매물로 변경해주세요');
        return false;
      }
    }
    setShows(showState);
  }

  //결과
  const onSetShowingHandler = async (e) => {
    //선택된 둥지 상품 찾기
    let CopyList = [...varDoongjiList];
    const findIndex = CopyList.findIndex(element=>element.rp_idx===varCurrentDoongjiIndex);
    //게시중으로 변경하는 경우
    if(varOldShows === 1 && varShows === 2 && varStatus===0) {
      if(varRemainDays < 0) {
        alert('보유한 이용권이 없습니다. 이용권을 구매하세요');
        navigator('/MyPage/BuyVoucher/0');
        return false;
      }
      if(varAvailableCount <= 0) {
        console.log("varAvailableCount :", varAvailableCount, "varOldShows :", varOldShows);
        alert('이미 게시된 매물들로 인하여 추가로 매물을 게시할 수 없습니다.');
        return false;
      }
      //게시된 매물수 감소
      let remCount = varAvailableCount - 1;
      setAvailableCount(remCount);
      //급매매물수 점검
      if(CopyList[findIndex].rp_rush_sale === 1 && varRushSales > 0) {
        alert('급매는 단 한 매물만 적용할 수 있습니다. 이 매물을 급매매물로 지정하려면 다른 급매매물을 먼저 일반매물로 변경하거나 게시중지로 변경해주세요');
        return false;
      }
    }

    //서버에 적용
    const sendData = {rp_idx:varCurrentDoongjiIndex,rp_show:varShows};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_doongji_showing`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //선택된 장터의 정보 변경하기
        CopyList[findIndex].rp_show = varShows;
        //전체배열에 적용
        setDoongjiList(CopyList);
        // varOldShows 업데이트
        setOldShows(varShows);
        onPopupDoongjiShowHandler(e,false);
      }
    });
  }  

  return (
    <div className="layer-pop w_del_popup desc_popup_st h_auto" style={varPopupDoongjiShow?{display:"block"}:{display:"none"}}>
      <div className="close_bt_wrap">
        <div className="close_layer top_close_btn"><img src={closeIcon} alt="" onClick={e=>onPopupDoongjiShowHandler(e,false)}/></div>
      </div>
      <div className="layer_inner">
        <div className="center_wrap">
          <div className="con">
            <div className="con_box">
              <strong>게시 상태 변경</strong>
              <div className="desc" style={{paddingLeft:"20px"}}>다음과 같이 게시물의 상태를 변경합니다.</div>
              <div className="dan_short2" style={{flexDirection:"column",width:"60%",padding:"10px 0px 0px 35px"}}>
                <div className="terms_chks show-option">
                  <input type="radio" name="rp_show" className="chk1" id="rp_show2" value="2"
                        checked={varShows===2?true:false} onChange={e=>onChangeShowingHandler(e)}/>
                        <i></i>&nbsp;&nbsp;<label htmlFor="rp_show2">게시중</label>
                </div>
                <div className="terms_chks show-option">
                  <input type="radio" name="rp_show" className="chk1" id="rp_show1" value="1"
                        checked={varShows===1?true:false} onChange={e=>onChangeShowingHandler(e)}/>
                        <i></i>&nbsp;&nbsp;<label htmlFor="rp_show1">거래완료</label>
                </div>
                <div className="terms_chks show-option">
                  <input type="radio" name="rp_show" className="chk1" id="rp_show0" value="0"
                        checked={varShows===0?true:false} onChange={e=>onChangeShowingHandler(e)}/>
                        <i></i>&nbsp;&nbsp;<label htmlFor="rp_show0">게시중지</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn_bottom">
        <button type="button" className="btn_style_yes close_layer" onClick={e=>onPopupDoongjiShowHandler(e,false)}>취소</button>
        <button type="button" className="btn_style_no" onClick={e=>onSetShowingHandler(e)}>선택 상태 변경</button>
      </div>
    </div>
  );
}
export default DoongjiShow;