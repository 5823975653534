import React, { useState,useEffect } from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import JangterProduct from '../../components/jangter/JangterProduct';
import ChangeJangterShowing from '../../components/popup/ChangeJangterShowing';
import JangterDelete from '../../components/popup/JangterDelete';


import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

//등록된 나의 장터 목록 보기
const RegisteredJangterList = () => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));

  //장터목록
  const [varJangterList,setJangterList] = useState([]);
  const [varCurrentJangterIndex,setCurrentJangterIndex] = useState(0);

  //내 장터 목록 가져오기
  const getJangterList = async () => {
    const sendData = {mem_idx:mem_idx};
    console.log(mem_idx)
    await axios.post(`${CONFIG.SERVER_HOST}/_get_my_jangter_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setJangterList(datas.results);
      }
    });
  }   
  //페이지 정보 표시 - 최초
  useEffect(() => {
    document.getElementById('main_body').style.backgroundColor = '#FFFFFF';
    getJangterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //게시상태 변경 팝업창
  const [varPopupShowing,setPopupShowing] = useState(false);
  const onShowingJangterProductHandler = async (e,jt_idx) => {
    e.preventDefault();
    setCurrentJangterIndex(jt_idx);
    onPopupWinHandler(true);
    setPopupShowing(true);
  }
  const onOpenCloseShowingHandler = (e,bmode) => {
    e.preventDefault();
    if(!bmode) getJangterList();
    onPopupWinHandler(bmode);
    setPopupShowing(bmode);
  }

  //삭제
  const [varPopupJangterDelete,setPopupJangterDelete] = useState(false);
  const onDeleteJangterProductHandler = async (e,jt_idx) => {
    e.preventDefault();
    
    setCurrentJangterIndex(jt_idx);
    onPopupWinHandler(true);
    setPopupJangterDelete(true);
  }
  const onPopupJangterDeleteHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupJangterDelete(false);
      getJangterList();
    } else {
      onPopupWinHandler(bmode);
      setPopupJangterDelete(bmode);
    }
  }
  const onPopupWinHandler = (bmode) => {
    console.log(bmode);
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }


  return (
    <>
      <Header/>
      <HeadTitle titleString={"내 등록 모두의 장터"}/>
      <div className="layer-bg"></div> 
      <div id="content">
        <div className="like_list01_wrap pd_wrap" style={{paddingTop:"0px"}}>
          <div className="write_dungji_list_wrap">
            <div className="main_dunggi list_type01">
              {varJangterList?.length===0?
                <div className="list_con">장터에 등록된 내용이 없습니다.</div>
              :varJangterList.map((jangter,i)=>(
                jangter.pr_status===3?
                  <div className="list_con" key={'jangter'+i}>
                    <JangterProduct product={jangter} varJangterList={varJangterList} setJangterList={setJangterList}/>
                    <div className="layer-bg3">
                      <div className="txt_wrap">
                        <span className="report-link">
                          <strong style={{fontSize:"20px",color:"white"}}>블라인드 처리</strong><br/>
                          블라인드 처리된 게시물입니다.<br/>해제를 원하시면 관리자에게 문의해주세요.
                        </span>
                      </div>
                    </div>
                  </div>
                :
                <div className="list_con" key={'jangter'+i}>
                  <JangterProduct product={jangter} varJangterList={varJangterList} setJangterList={setJangterList}/>
                  <div className="btn_bottom two_btn con_btn">
                    <button className="btn_style_dark w_state_open" onClick={e=>onShowingJangterProductHandler(e,jangter.jt_idx)}>{jangter.jt_show===0?"게시중지":jangter.jt_show===1?"거래완료":"게시중"}</button>
                    &nbsp;&nbsp;<button className="btn_style_white w_del_open" onClick={e=>onDeleteJangterProductHandler(e,jangter.jt_idx)}>삭제</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* 장터 게시물 삭제 */}
        <JangterDelete varPopupJangterDelete={varPopupJangterDelete} varCurrentJangterIndex={varCurrentJangterIndex} 
          onPopupJangterDeleteHandler={onPopupJangterDeleteHandler}/>
        {/* 게시상태 변경 팝업창 */}
        <ChangeJangterShowing varPopupShowing={varPopupShowing} varCurrentJangterIndex={varCurrentJangterIndex}
              varJangterList={varJangterList} setJangterList={setJangterList} onOpenCloseShowingHandler={onOpenCloseShowingHandler}/>
      </div>
    </>
  );
}
export default RegisteredJangterList;
