/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";

import VariableSubwayBlock from '../variables/VariableSubwayBlock';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

//어플에 사용되는 선택 항목들 정의 - 둥지관련
const SubwayVariables = ({setMenuItem}) => {

  //메뉴 지정
  useEffect(()=>{
    setMenuItem('SubwayVariables');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div id="contents" className="ad_contents">
        <div className="tit">
          <div className="title">카테고리 관리</div>
          <div className="path">
              <img src={iconhome} alt=""/>HOME<img src={arrImage} alt=""/>변수항목관리<img src={arrImage} alt=""/>지하철 카테고리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="select-list2" style={{borderCollapse:"collapse"}}>
              <thead></thead>
              <tbody>
                {/* 지하철역 */}
                <VariableSubwayBlock/>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
}
export default SubwayVariables;