import React, { useState,useEffect } from "react";
import CONFIG from '../../../configs/Configs';
import companyPhoto from "../../../images/sub/@thumnail03.png";
import taxPhoto from "../../../images/sub/@thumnail03.png";
import licensePhoto from "../../../images/sub/@thumnail03.png";

import { ChangeDateStringOnlyDate,ChangeDateTimeString,ChangeMobileFormat,GetPhotoImage,unescapeHtml } from '../../../components/common/Common';

function MemberRetired({varMemberInfo,onMemberInfoHandler,onShowBigSizePhotoHandler}) {
  const mem_mode = Number(varMemberInfo.mem_mode);  //0:일반/1:부동산/2:시업자

  const [varPhoto1,setPhoto1] = useState(companyPhoto);
  const [varPhoto2,setPhoto2] = useState(taxPhoto);
  const [varPhoto3,setPhoto3] = useState(licensePhoto);

  const [varAddress,setAddress] = useState('');

  useEffect(() => {
    if(mem_mode === 0) {
      setAddress(`(${varMemberInfo.mem_zipcode}) ${varMemberInfo.mem_address1} ${varMemberInfo.mem_address2}`);
    } else {
      setAddress(`(${varMemberInfo.mcm_zipcode}) ${varMemberInfo.mcm_address1} ${varMemberInfo.mcm_address2}`);
    }
    let photoFile = companyPhoto;
    if(mem_mode === 0) {
      if(varMemberInfo.mem_image === 1) {
        const fileName = varMemberInfo.mem_file;
        photoFile = `${CONFIG.SERVER_HOST}/`+GetPhotoImage(fileName,'member');
      }
    } else {
      if(varMemberInfo.mcm_image === 1) {
        const fileName = varMemberInfo.mcm_file;
        photoFile = `${CONFIG.SERVER_HOST}/`+GetPhotoImage(fileName,'member');
      }
    }
    setPhoto1(photoFile);
    let taxFile = taxPhoto;
    let licenseFile = licensePhoto;
    if(mem_mode === 1) {
      //사업자등록증 존재하면
      if(varMemberInfo.mcm_tax_image === 1) {
        const fileName = varMemberInfo.mcm_tax_file;
        taxFile = `${CONFIG.SERVER_HOST}/`+GetPhotoImage(fileName,'member');
      }
      //부동산자격증 존재하면
      if(varMemberInfo.mcm_broker_image === 1) {
        const fileName = varMemberInfo.mcm_broker_file;
        licenseFile = `${CONFIG.SERVER_HOST}/`+GetPhotoImage(fileName,'member');
      }
    }
    setPhoto2(taxFile);
    setPhoto3(licenseFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varMemberInfo]);


  return (
    <div className="main-box">
      <table className="write">
        <colgroup><col width="20%"/><col width="30%"/><col width="20%"/><col width="30%"/></colgroup>
        <thead></thead>
      {mem_mode===0?
        <tbody>
          <tr>
            <th>아이디(이메일)</th>
            <td>{varMemberInfo.mem_email}</td>
            <th>이름</th>
            <td>{varMemberInfo.mem_name}</td>
          </tr>
          <tr>
            <th>닉네임</th>
            <td>{varMemberInfo.mem_nickname}</td>
            <th>휴대폰</th>
            <td>{ChangeMobileFormat(varMemberInfo.mem_mobile)}</td>
          </tr> 
          <tr>
            <th>등록일시</th>
            <td>{ChangeDateTimeString(varMemberInfo.mem_rdate)}</td>
            <th>탈퇴일시</th>
            <td>{ChangeDateTimeString(varMemberInfo.mem_edate)}</td>
          </tr> 
          <tr>
            <th>주소</th>
            <td colSpan="3">{`(${varMemberInfo.mem_zipcode}) ${varMemberInfo.mem_address1} ${varMemberInfo.mem_address2}`}</td>
          </tr>   
          <tr className="image-area">
            <th className="image-title">프로필사진</th>
            <td colSpan={3} className="image-block">
              <div className="image-box">
                <img className="request-image" src={varPhoto1} alt="" onClick={(e)=>onShowBigSizePhotoHandler(e,varPhoto1)}/>
              </div>
            </td>  
          </tr>
          <tr>
            <th>탈퇴유무</th>
            <td colSpan={3}>
              <select name="mem_status" style={{width:"150px"}} value={varMemberInfo.mem_status} onChange={(e)=>onMemberInfoHandler(e)}>
                <option value={0}>탈퇴중</option>
                <option value={1}>사용중</option>
              </select>
            </td>
          </tr>
        </tbody>
      :
        <tbody>
          <tr>
            <th>아이디(이메일)</th>
            <td>{varMemberInfo.mem_email}</td>
            <th>등록일자</th>
            <td>{ChangeDateStringOnlyDate(varMemberInfo.mem_rdate)}</td>
          </tr>
          <tr>
            <th>업체명</th>
            <td>{varMemberInfo.mcm_name}</td>
            <th>업체 대표 번호</th>
            <td>{ChangeMobileFormat(varMemberInfo.mcm_phone)}</td>
          </tr>  
          <tr>
            <th>사업장주소</th>
            <td colSpan="3">{varAddress}</td>
          </tr>     
          <tr>
            <th>담당자명</th>
            <td>{varMemberInfo.mcm_name}</td>
            <th>휴대폰</th>
            <td>{ChangeMobileFormat(varMemberInfo.mem_mobile)}</td>
          </tr> 
          <tr>
            <th>회원경고단계</th>
            <td>{varMemberInfo.mem_warning===0?"없음":`${varMemberInfo.mem_warning}단계`}</td>
          </tr>
          <tr>
            <th>사업자등록번호</th>
            <td>{varMemberInfo.mcm_tax_id}</td>
            <th>이메일(세금계산서발행용)</th>
            <td>{varMemberInfo.mcm_tax_email}</td>
          </tr> 
          <tr className="image-area">
            <th className="image-title">업체대표사진</th>
            <td colSpan={3} className="image-block">
              <div className="image-box" id="imgFile1">
                <img className="request-image" src={varPhoto1} alt="" onClick={(e)=>onShowBigSizePhotoHandler(e,varPhoto1)}/>
              </div>
            </td>  
          </tr>
          {varMemberInfo.mem_mode===2?null:
            <tr className="image-area">
              <th className="image-title">사업자등록증</th>
              <td className="image-block">
                <div className="image-box" id="imgFile2">
                  <img className="request-image" src={varPhoto2} alt="" onClick={(e)=>onShowBigSizePhotoHandler(e,varPhoto2)}/>
                </div>
              </td>  
              <th className="image-title">공인중개사자격증</th>
              <td colSpan={3} className="image-block">
                <div className="image-box" id="imgFile3">
                  <img className="request-image" src={varPhoto3} alt="" onClick={(e)=>onShowBigSizePhotoHandler(e,varPhoto3)}/>
                </div>
              </td>  
            </tr>
          }
          <tr>
            <th>업체 소개글</th>
            <td colSpan={3}>{varMemberInfo.mcm_introduce}</td>
          </tr>
          <tr>
            <th>관리자 메모</th>
            <td colSpan={3}>{unescapeHtml(varMemberInfo.mem_memo)||""}</td>
          </tr> 
          <tr>
            <th>탈퇴유무</th>
            <td colSpan={3}>
              <select name="mem_status" style={{width:"140px"}} value={varMemberInfo.mem_status} onChange={(e)=>onMemberInfoHandler(e)}>
                <option value={0}>탈퇴중</option>
                <option value={1}>사용중</option>
              </select>
            </td>
          </tr>
        </tbody>
      }
      </table>
    </div>
  );
}

export default MemberRetired;