/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from "react"; 
import { Link,useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { CheckBlockingLoginState,isNull } from "../../components/common/Common";
import logoImage from "../../images/common/logo_header.png";

const Header = () => {
  const navigate = useNavigate();
  const CheckLoginStatus = async() => {
    const mem_idx = localStorage.getItem("mem_idx");
    if(isNull(mem_idx)) return false;
    const sendData = {mem_idx:mem_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_check_blocking_member`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        localStorage.removeItem("mem_idx");
        localStorage.removeItem("mcm_idx");
        localStorage.removeItem("mem_mode");
        localStorage.removeItem("mem_nickname");
        localStorage.removeItem("mcm_name");
        localStorage.removeItem("memberLoginState");
        localStorage.removeItem("mem_admin");
    
        localStorage.removeItem("autoLogin");
    
        localStorage.removeItem('DoongjiSearchingString');
        localStorage.removeItem('DoongjiSearchRegionList');
        localStorage.removeItem('DoongjiFixedFilterList');
        localStorage.removeItem('DoongjiFixedFilterOptionList');
        localStorage.removeItem('DoongjiFilterOptions');
        localStorage.removeItem('DoongjiSortingList');
    
        localStorage.removeItem('JangterSearchingString');
        localStorage.removeItem('JangterSearchRegionList');
    
        localStorage.removeItem('PartnerSearchingString');
        localStorage.removeItem('PartnerSearchRegionList');
    
        navigate(`/Login`);
      }
    });
  }

  useEffect(() => {
    if(CheckBlockingLoginState()) CheckLoginStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  return (
    <div id="head">
      <div id="header_fix">
        <h1><Link to={"/Main"}><img src={logoImage} alt=""/></Link></h1>
      </div>
    </div>
  );
}
export default Header;