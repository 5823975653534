import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import { isObjEmpty } from "../../../components/common/Common";

//부관리자의 정보 표시
function SubAdminDetail({varShowDetailPage,setShowDetailPage,varCurrentSubAdmin,varSubAdminLists,getSubAdminList}) {
  //선택/추가할 부관리자 정보 저장 변수 
  const newData = {
    mem_idx : 0,
    mem_email : "",
    mem_name : "",
    mem_pwd : "",
    mem_pwd2 : "",
    admin_environment : 1,
    admin_member : 1,
    admin_product: 1,
    admin_payment: 1,
    admin_board: 1,
    admin_admboard: 1,
    chk_email:0
  }
  const [varSubAdminDatas, setSubAdminDatas] = useState({});

/*   //부관리자 정보 읽어오기
  const getSubAdminState = async() => {
    //신규가 아니면
    if (varCurrentSubAdmin !== undefined && varCurrentSubAdmin > 0) {
      setSubAdminDatas(varSubAdminLists.filter(element=>element.mem_idx=varCurrentSubAdmin));
    }
  };
 */

  //페이지 정보 표시 - 최초
  useEffect(() => {
    console.log(varCurrentSubAdmin);
    if(varCurrentSubAdmin === undefined || varCurrentSubAdmin === 0) { //신규추가의 경우
      setSubAdminDatas(newData);
    } else if(varCurrentSubAdmin > 0){
      let curData = varSubAdminLists.find(element=>element.mem_idx===varCurrentSubAdmin);
      curData.mem_pwd = "";
      curData.mem_pwd2 = "";
      setSubAdminDatas(curData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCurrentSubAdmin]);

  //부관리자 정보 입력
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    if(name === "mem_email") {
      if(varSubAdminDatas.chk_email === 1) {
        setSubAdminDatas({...varSubAdminDatas,chk_email:0,mem_email:value});
      } else {
        setSubAdminDatas({...varSubAdminDatas,mem_email:value});
      }
    } else {
      setSubAdminDatas({...varSubAdminDatas,[name]:value});
    };
  }
    
  //부관리자 메뉴 사용 권한 설정
  const onChangeAvailableMenuHandler = (e) => {
    const { name, checked } = e.currentTarget;
    const value = Number(checked);
    setSubAdminDatas({...varSubAdminDatas,[name]:value});
  };

  //중복확인
  const onCheckDuplicationHandler = async (e) => {
    if(varSubAdminDatas.mem_email === "") {
      alert("아이디(이메일주소)를 먼저 입력하세요."); return false;
    }
    const sendData = {checkField:'mem_email',checkString:varSubAdminDatas.mem_email};
    await axios.post(`${CONFIG.SERVER_HOST}/_check_duplication`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        setSubAdminDatas({...varSubAdminDatas,chk_email:1});
      } else {
        setSubAdminDatas({...varSubAdminDatas,chk_email:0,mem_email:''});
      }
      alert(datas.message);
    });
  }

  //부관리자 정보 저장
  const onUpdateSubAdminHandler = async (e) => {
    if (varSubAdminDatas.mem_email === '') {
      alert('아이디(이메일)를 입력해 주세요'); return false;
    } else if(varSubAdminDatas.chk_email === 0) {
      alert('아이디(이메일)의 중복검사를 먼저 수행해 주세요'); return false;
    } else if (varSubAdminDatas.mem_name === '') {
      alert('이름을 입력해 주세요'); return false;
    } 
    if(varSubAdminDatas.mem_idx === 0) {
      if (varSubAdminDatas.mem_pwd === '') {
        alert('비밀번호를 입력해 주세요'); return false;
      } else if (varSubAdminDatas.mem_pwd2 === '') {
        alert('비밀번호 확인란을 입력해주세요 입력해 주세요'); return false;
      } else if (varSubAdminDatas.mem_pwd !== varSubAdminDatas.mem_pwd2) {
        alert('비밀번호와 확인비밀번호의 입력값이 일치하지 않습니다.'); return false;
      }
    } else {
      if (varSubAdminDatas.mem_pwd !== varSubAdminDatas.mem_pwd2) {
        alert('비밀번호와 확인비밀번호의 입력값이 일치하지 않습니다.'); return false;
      }
    }

    if(!window.confirm('부운영자 정보를 저장합니까?')) return false;
    const sendData = {dataInfo:varSubAdminDatas};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_subadmin_state`, sendData).then((res) => {
      if (res.data.retvalue === 1) {      
        setShowDetailPage(false);
        getSubAdminList();
        alert(res.data.message);
      }
    });
  }

  const onCancelSubAdminHandler = (e) => {
    setShowDetailPage(false);
  }
  
  return isObjEmpty(varSubAdminDatas) || !varShowDetailPage? null:(
    <div className="content">
      <div className="main-box">
        <table className="write">
          <thead></thead>
          <tbody>
            <tr>
              <th>아이디(이메일)</th>
              <td>
                <input type="text" className="email-box" name="mem_email" value={varSubAdminDatas.mem_email||""} onChange={(e)=>onChangeInputHandler(e)} maxLength="100" />
                <button className={varSubAdminDatas.chk_email===1?"btn line disable":"btn line"} style={{width:"100px"}} onClick={e=>onCheckDuplicationHandler(e)}>중복체크</button>
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td><input type="text" className="name-box" name="mem_name" value={varSubAdminDatas.mem_name||""} onChange={(e)=>onChangeInputHandler(e)} maxLength="30" /></td>
            </tr>
            <tr>
              <th>비밀번호</th>
              <td><input type="password" className="password-box" name="mem_pwd" value={varSubAdminDatas.mem_pwd||""} onChange={(e)=>onChangeInputHandler(e)} maxLength="20" /></td>
            </tr>
            <tr>
              <th>비밀번호 확인</th>
              <td><input type="password" className="password-box" name="mem_pwd2" value={varSubAdminDatas.mem_pwd2||""} onChange={(e)=>onChangeInputHandler(e)} maxLength="20" /></td>
            </tr>
            <tr>
              <th>메뉴사용권한설정</th>
              <td><div className="write-radio">
                <input type="checkbox" id="chk1" className="admin_input" name="admin_environment" checked={varSubAdminDatas.admin_environment===1} onChange={(e)=>onChangeAvailableMenuHandler(e)} />
                <label htmlFor="chk1" >환경설정</label>
                <input type="checkbox" id="chk2" className="admin_input" name="admin_member" checked={varSubAdminDatas.admin_member===1} onChange={(e)=>onChangeAvailableMenuHandler(e)} />
                <label htmlFor="chk2" >회원관리</label>
                <input type="checkbox" id="chk3" className="admin_input" name="admin_product" checked={varSubAdminDatas.admin_product===1} onChange={(e)=>onChangeAvailableMenuHandler(e)} />
                <label htmlFor="chk3" >둥지 / 장터관리</label>
                <input type="checkbox" id="chk4" className="admin_input" name="admin_payment" checked={varSubAdminDatas.admin_payment===1} onChange={(e)=>onChangeAvailableMenuHandler(e)} />
                <label htmlFor="chk4" >요금 / 이용권관리</label>
                <input type="checkbox" id="chk5" className="admin_input" name="admin_board" checked={varSubAdminDatas.admin_board===1} onChange={(e)=>onChangeAvailableMenuHandler(e)} />
                <label htmlFor="chk5" >앱 게시판관리</label>
                <input type="checkbox" id="chk6" className="admin_input" name="admin_admboard" checked={varSubAdminDatas.admin_admboard===1} onChange={(e)=>onChangeAvailableMenuHandler(e)} />
                <label htmlFor="chk6" >관리자 게시판관리</label>
              </div></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-center">
        <button className="btn-danger" style={{width:"100px"}} onClick={(e)=>onUpdateSubAdminHandler(e)}>저장</button>
        <button className="btn line" style={{width:"100px"}} onClick={(e)=>onCancelSubAdminHandler(e)}>취소</button>
      </div>
    </div>
  );
}

export default SubAdminDetail;