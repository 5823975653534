/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CONFIG from "../../../configs/Configs";
import axios from "axios";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import Paging from "../../layout/Paging";
import AdminBoardSearch from "../../components/boards/AdminBoardSearch";
import AdminBoardSearchResult from "../../components/boards/AdminBoardSearchResult";

import arrImage from "../../images/arr.png";
import iconhome from "../../images/icon-home.png";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import "../../admin_css/board.css";


const AdminBoard = () => {
  const { adMode } = useParams();

  //검색 정보 저장 변수
  const [varSearchData, setSearchData] = useState({
    sdate:null,
    edate:null,
    searchOption:"all",
    searchString:''
  });
  //페이지 구분
  const [varMenuTitle,setMenuTitle] = useState('');
  const [varMenuItem,setMenuItem] = useState('');

  //게시글 목록 저장 변수
  const [varBoardLists,setBoardLists] = useState([]);

  //페이지 조절 변수
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //게시판 정보 전체 가져오기
  const readBoardLists = () => {
    var sendData = {adMode:adMode,currentPage: varPageNum,pageSize: varPageSize,searchData: varSearchData};
    console.log(sendData);
    axios.post(`${CONFIG.SERVER_HOST}/_get_admin_board_list`, sendData).then((res) => {
      console.log(res.data);
      if (res.data.retvalue === 1) {
        setTotalCount(res.data.totalcount);
        const pmax = res.data.totalcount - (varPageNum - 1) * varPageSize;
        const datas = res.data.results.map((data, i) => ({
          board_idx: data.board_idx,
          board_num: pmax - i,
          board_title: data.board_title,
          mem_admin: data.mem_admin,
          mem_name: data.mem_name,
          board_rdate: data.board_rdate,
          board_hits: data.board_hits,
          regname:data.regname,
          checked:0
        }));
        setBoardLists(datas);
      } else {
        setTotalCount(0);
        setBoardLists([]);
        //alert("검색된 공지사항이 없습니다.");
      }
    });
  }

  useEffect(() => {
    let stitle="",smenu="";
    switch(Number(adMode)) {
      default:
      case 1: stitle="공지사항";smenu = "AdminBoardNotice"; break;
      case 2: stitle="뉴스/개발";smenu = "AdminNews"; break;
      case 3: stitle="지역관리자 커뮤니티";smenu = "AdminGuestBoard"; break;
      case 4: stitle="급매물/공동중개";smenu = "AdminSaleDoongji"; break;
      case 5: stitle="매물찾습니다";smenu = "AdminFindDoongji"; break;
    }
    setMenuTitle(stitle);
    setMenuItem(smenu);
    console.log(adMode);
    setSearchData({sdate:null,edate:null,searchOption:"all",searchString:''});
    console.log('aaa');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adMode]);

  useEffect(() => {
    setPageNum(1); // 검색 조건이 변경될 때 페이지 번호를 1로 초기화
  }, [varSearchData]);
  
  //페이지가 바뀌면
  useEffect(() => {
    readBoardLists();
    console.log('bbb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum,varSearchData,adMode]);

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'ADMINBOARDS'}/>
      <Menus varMenuType={'ADMINBOARDS'} varMenuItem={varMenuItem}/>
      <div id="contents" className="ad_contents">
        <div className="tit">
          <div className="title">{varMenuTitle}</div>
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arrImage} alt="" />관리자 게시판<img src={arrImage} alt="" />{varMenuTitle}
          </div>
        </div>
        <div className="content">
          <AdminBoardSearch varTotalCount={varTotalCount} varSearchData={varSearchData} setSearchData={setSearchData}/>
          <AdminBoardSearchResult adMode={adMode} varBoardLists={varBoardLists} setBoardLists={setBoardLists} readBoardLists={readBoardLists}/>
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </div>
      </div>
    </>
  );
}
export default AdminBoard;