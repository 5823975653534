import React, { useState,useEffect } from "react"; 
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { addThousandPoint,passedDays,ChangeDateString } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const PremiumList = () => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const navigator = useNavigate();
  //검색된 프리미엄 광고 목록 관련
  const [varPremiumList,setPremiumList] = useState([]);

  //프리미엄 광고 목록 가져오기
  const getPremiumPaymentList = async() => {
    const sendData = {mem_idx:mem_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_premium_payment_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //검색된 목록
        if(datas.results?.length > 0) {
          var pdate = 0;
          let paylist = datas.results;
          for(var i=0; i < paylist.length; i++) {
            paylist[i].cbutton = false;
            //무통장 입금 & 결제대기
            if(paylist[i].ph_method === 0) {
              if(paylist[i].ph_status === 1) {  //신청
                pdate = passedDays(paylist[i].ph_rdate);  //등록일자로부터 경과 일자
                if(pdate <= 7) paylist[i].cbutton = true;
              } else if(paylist[i].ph_status === 2) { //승인
                pdate = passedDays(paylist[i].ph_adate);  //승인일자로부터 경과 일자
                if(pdate <= 7) paylist[i].cbutton = true;
              } else if(paylist[i].ph_status === 3) { //결제
                pdate = passedDays(paylist[i].ph_pdate);  //승인일자로부터 경과 일자
                if(pdate <= 7) paylist[i].cbutton = true;
              }
            //카드결제
            } else {
              if(paylist[i].ph_status === 3) { //결제
                pdate = passedDays(paylist[i].ph_pdate);  //승인일자로부터 경과 일자
                if(pdate <= 7) paylist[i].cbutton = true;
              }
            }
          }
          setPremiumList(paylist);
        }
      }
    });
  }
  useEffect(() => {
    document.getElementById('main_body').style.backgroundColor = '#FFFFFF';
    getPremiumPaymentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //4:결제취소, 5:환불요청
  const onPaymentHandler = async (e,payobj,ino) => {
    let message = "";
    if(ino === 0){  //신청 --> 신청취소 (무통장/카드)
      message = "주문하신 프리미엄광고 신청을 취소합니까?";
    } else if(ino === 4){ 
      message = "대기중인 프리미엄광고의 결제를 취소합니까?";
    } else if(ino === 5) {
      if(payobj.ph_method === 0){
        message = "프리미엄광고의 결제금액의 환불을 요청합니까?";
      } else {
        message = "카드로 결제한 프리미엄광고의 결제를 취소합니까?";
        ino = 4;
      }
    }
    
    if(!window.confirm(message)) return false;
    const sendData = {ph_idx:payobj.ph_idx,ph_status:ino,rpa_idx:payobj.rpa_idx,ph_mode:payobj.ph_mode};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_change_payment_status`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(payobj.ph_method === 1) {  //카드 결제 취소
          CancelCreditCardPayment(payobj);
        }
        getPremiumPaymentList();
      }
    });
  }
  //카드결제취소
  const CancelCreditCardPayment = async(payobj) => {
    const refund_paymentkey = payobj.ph_paymentkey;
    const sendData = {paymentKey:refund_paymentkey};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_cancel_payment_tosspayment`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        RefundPayment(payobj);
      } else {
        alert(datas.message);
      }
    });
  }
  //환불내역 저장
  const RefundPayment = async(payobj) => {
    const refund_idx = payobj.ph_idx;
    const refund_money = payobj.ph_amount;
    const refund_method = payobj.ph_method;
    const refund_reason = "고객 변심";
    const sendData = {ph_idx:refund_idx,pr_amount:refund_money,pr_method:refund_method,pr_reason:refund_reason};
    await axios.post(`${CONFIG.SERVER_HOST}/_update_refund_payment`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      alert(datas.message);
    });
  }

  const onLinkDetailHandler = (e,premium) => {
    switch (premium.ph_status) {
      case 1: //신청
        if(premium.ph_mode===3) navigator(`/MyPage/RegisterPremium/1/${premium.rp_idx}`);
        else navigator(`/MyPage/RegisterPremium/2/${premium.jp_idx}`);
        break;
      case 2: //결제대기
        navigator(`/MyPage/PremiumDetail/${premium.ph_idx}`);
        break;
      case 3: //결제완료
        navigator(`/MyPage/PremiumView/${premium.ph_idx}`);
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Header/>
      <HeadTitle titleString={"파트너스 결제 관리"} backString={'/MyPage'}/>
      <div id="content" style={{marginBottom:"280px"}}>
        <div className="pd_wrap02_bottom">
          <div className="ad_pay_list_wrap" > 
            <div className="go_ad_pay">
              <div className="center_wrap"><Link to="/MyPage/BuyVoucher/0" className="">이용권 구매하기</Link></div>
            </div>
            <div className="tab_menu">
              <ul>
                <li className="on"><Link to="/MyPage/PremiumList">프리미엄 광고 신청 내역</Link></li>
                <li><Link to="/MyPage/PaymentList">결제 내역 보기</Link></li>
              </ul>
            </div>
            <div className="write_form_wrap bottom">
              <div className="tb_wrap">
                <div className="table_st01"> 
                  <table>
                    <colgroup>
                      <col width="40%"/><col width="15%"/><col width="20%"/><col width="25%"/>
                    </colgroup>
                    <thead>
                      <tr><th>희망 기간</th><th>광고유형</th><th>상태</th><th>금액</th></tr>
                    </thead>
                    <tbody>
                      {varPremiumList?.length===0?
                        <tr><td colSpan="4" style={{textAlign:"center"}}>신청한 광고 내역이 없습니다.</td></tr>
                      :varPremiumList.map((premium,i)=>(
                        <tr key={"premium_"+i}>
                          <td>{ChangeDateString(premium.rpa_sdate)}~{ChangeDateString(premium.rpa_edate)}</td>
                          <td>{premium.ph_mode===3?"둥지":"장터"}</td>
                          <td>
                            {premium.ph_status===1?
                                <>
                                  <span className="state02" style={{color:"#00008B"}} onClick={e=>onLinkDetailHandler(e,premium)}>신청중</span>
                                  {premium.cbutton?
                                    <button className="btn smalllist" onClick={e=>onPaymentHandler(e,premium,0)}>주문취소</button>
                                  :null}
                                </>
                              :premium.ph_status===2?
                                <>
                                  <span className="state02" style={{color:"red"}} onClick={e=>onLinkDetailHandler(e,premium)}>결제대기</span>
                                  {premium.cbutton?
                                    <button className="btn smalllist" onClick={e=>onPaymentHandler(e,premium,4)}>결제취소</button>
                                  :null}
                                </>
                              :premium.ph_status===3?
                                <>
                                  <span className="state02" style={{color:"#00008B"}} onClick={e=>onLinkDetailHandler(e,premium)}>결제완료</span>
                                  {premium.cbutton?
                                    <button className="btn smalllist" onClick={e=>onPaymentHandler(e,premium,5)}>{premium.ph_method===0?"환불요청":"결제취소"}</button>
                                  :null}
                                </>
                              :premium.ph_status===4?<span className="state01" style={{color:"#00008B"}}>결제취소</span>
                              :premium.ph_status===5?<span className="state01" style={{color:"#00008B"}}>환불요청</span>
                              :premium.ph_status===6?<span className="state01" style={{color:"#00008B"}}>환불완료</span>
                              :premium.ph_status===7?<span className="state01" style={{color:"#00008B"}}>환불실패</span> :null}
                          </td>
                          <td>{addThousandPoint(premium.ph_amount)+" 원"}</td>
                        </tr>
                      ))}
                    </tbody> 
                  </table>
                </div>
              </div>
              <div className="btm_box">
                <strong>프리미엄 광고는<br/> 어떻게 결제하나요?</strong>
                <p>
                  <span style={{fontWeight:"700",color:"#666",lineHeight:"1.4"}}>광고 신청 --- 광고비 산정 --- 결제 --- 광고 집행</span><br/>
                    -신청접수 후 광고비 산정까지 1~2일 소요됩니다.<br/>
                    -광고비 산정 결과는 문자로 발송드립니다.<br/>
                    -<span style={{color:"red"}}>결제대기</span>를 클릭하여 결제를 진행하여 주세요.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PremiumList;