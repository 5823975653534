import React from "react"; 
import { Link,useNavigate } from 'react-router-dom';

import CONFIG from '../../configs/Configs';
import {CopyToClipboard} from "react-copy-to-clipboard/src";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import Footer from "../../components/include/Footer";

import myPage01Icon from "../../images/mypage_01.svg";
import myPage02Icon from "../../images/mypage_02.svg";
import myPage03Icon from "../../images/mypage_03.svg";
import myPage04Icon from "../../images/mypage_04.svg";
import myPage05Icon from "../../images/mypage_05.svg";
import myPage06Icon from "../../images/mypage_06.svg";
import myPage07Icon from "../../images/mypage_07.svg";
import myPage08Icon from "../../images/mypage_08.svg";
import myPage09Icon from "../../images/mypage_09.svg";
import botn_logout from '../../admin/images/botn-logout.png';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const MyPage = () => {
  const navigate = useNavigate();
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const loginState = Boolean(localStorage.getItem("memberLoginState"));
  if(mem_mode > 0 && !loginState) {
    alert('관리자의 승인이후에 로그인하셔야만 사용할 수 있습니다.');
    window.location.href = "/Login";
  }
  
  const onLogoutHandler = (e) => {
    localStorage.removeItem("mem_idx");
    localStorage.removeItem("mcm_idx");
    localStorage.removeItem("mem_mode");
    localStorage.removeItem("mem_nickname");
    localStorage.removeItem("mcm_name");
    localStorage.removeItem("memberLoginState");
    localStorage.removeItem("mem_admin");

    localStorage.removeItem("autoLogin");

    localStorage.removeItem('DoongjiSearchingString');
    localStorage.removeItem('DoongjiSearchRegionList');
    localStorage.removeItem('DoongjiFixedFilterList');
    localStorage.removeItem('DoongjiFixedFilterOptionList');
    localStorage.removeItem('DoongjiFilterOptions');
    localStorage.removeItem('DoongjiSortingList');

    localStorage.removeItem('JangterSearchingString');
    localStorage.removeItem('JangterSearchRegionList');

    localStorage.removeItem('PartnerSearchingString');
    localStorage.removeItem('PartnerSearchRegionList');

    navigate(`/Login`);

  }

  //공유하기
  const onShareProductHandler = async(e) => {
    alert('클립보드로 복사되었습니다.');
  }

  return (
    <>
      <Header/>
      <HeadTitle titleString={"마이페이지"} backString={"/Doongji/DoongjiList"}/>
      <div id="content">
        <div className="pd_btm100">
          <div className="mypage_wrap">
            <div className="center_wrap">
              <ul className="menu">
                <li>
                  <Link to={mem_mode===0?"/MyPage/MyInfo":"/MyPage/MyPartnerInfo"}><img src={myPage01Icon} alt=""/>
                    내정보 관리{mem_mode===0?" (일반)":mem_mode===1?" (부동산)":" (사업자)"}
                  </Link>
                </li>	
                <li><Link to="/Doongji/RegisteredDoongjiList" state={{ mypage: true }} style={{ display: 'flex', alignItems: 'center' }}><img src={myPage02Icon} alt="" />내 등록 둥지</Link></li>
                {mem_mode !== 2? null :
                  <li><Link to="/Jangter/RegisterPartner"><img src={myPage03Icon} alt=""/>내 장터 관리</Link></li>
                }
                <li><Link to="/Jangter/RegisteredJangterList"><img src={myPage04Icon} alt=""/>내 등록 모두의 장터</Link></li>
                {mem_mode === 0? null :
                  <li><Link to="/MyPage/PremiumList"><img src={myPage05Icon} alt=""/>내 결제 관리</Link></li>
                } 
                <li><Link to="/MyPage/NoticeList"><img src={myPage06Icon} alt=""/>공지사항</Link></li>
                <li><Link to="/MyPage/FAQList"><img src={myPage07Icon} alt=""/>FAQ</Link></li>
                <li><Link to="/MyPage/OneToOneList"><img src={myPage08Icon} alt=""/>1:1문의</Link></li>
                <li><Link to="/MyPage/Settings"><img src={myPage09Icon} alt=""/>앱설정</Link></li>
              </ul>
              <div className="f-rightt" style={{padding:"5px 10px",border:"solid 1px #ddd",margin:"10px 20px",borderRadius:"10px",backgroundColor:"#fff"}}>
                <button onClick={(e)=>onLogoutHandler(e)} className="btn-s"><img src={botn_logout} style={{width:"16px",verticalAlign:"unset"}} alt=""/> 로그아웃</button>
              </div>
              <div className="join_share_box" style={{marginTop:"40px"}}>
                <div className="box goshare" style={{paddingTop:"3px"}}>
                    <div className="tit06">둥지장터 앱을 공유해보세요!</div>
                    <CopyToClipboard text={`${CONFIG.PAYMENT_HOST}/Main`}  onCopy={e=>onShareProductHandler(e)}>
                    <button>
                      둥지장터 앱 공유하기
                    </button>
                    </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer varPageMode={5} />
    </>
  );
}
export default MyPage;