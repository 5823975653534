import React, { useEffect,useState } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import AgencyAdminDetail from './AgencyAdminDetail';

function AgencyAdminInformation({setMenuItem}) {
  const admin_idx = localStorage.getItem("adminIdx");

  //부관리자 목록 정보 저장 변수 
  const [varAgencyAdminLists, setAgencyAdminLists] = useState([]);
  //현재 선택된 부관리자 고유번호 저장 변수
  const [varCurrentAgencyAdmin,setCurrentAgencyAdmin] = useState(admin_idx);
  //부관리자 정보 변경 페이지 표시 유무
  const [varShowDetailPage] = useState(true);

  //부관리자 목록 가져오기
  const getAgencyAdminList = () => {
    axios.post(`${CONFIG.SERVER_HOST}/_get_agencyadmin_list`).then(res => {
      if(res.data.retvalue === 1) {
        if(res.data.results?.length > 0) {
          //부운영자 목록
          setAgencyAdminLists(res.data.results);
        } else {
          setAgencyAdminLists([]);
        }
      } else {
        setAgencyAdminLists([]);
      }
    })
  };
  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('AgencyAdminInformation');
    getAgencyAdminList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">지역관리자 관리
        <div className="path"><img src={iconhome} alt="" />
        HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt="" />운영 계정 관리<img src={arrImage} alt="" />지역관리자 관리
        </div>
      </div>
      <AgencyAdminDetail varShowDetailPage={varShowDetailPage} varCurrentAgencyAdmin={varCurrentAgencyAdmin} setCurrentAgencyAdmin={setCurrentAgencyAdmin}
            varAgencyAdminLists={varAgencyAdminLists} setAgencyAdminLists={setAgencyAdminLists} />
    </div>
  )
}

export default AgencyAdminInformation;