/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import { Link } from 'react-router-dom';

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

import newNextIcon from "../../images/icon_next_y.png";
import register2Icon from "../../images/regist02.png";
import register3Icon from "../../images/regist03.png";

const DoongjiSerious = () => {

  return (
    <>
      <Header/>
      <HeadTitle titleString={"둥지 등록"} backString={'/Doongji/DoongjiList'}/>
      <div id="content">
        <div className="pd_wrap02">
          <div className="regist_wrap">
            <div className="top_area b_shadow">
              <div className="big_txt">둥지에서 매물을<br/>소개해보세요</div>
              <Link to="/Doongji/DoongjiDetail/0" className="go_rg_write">내 매물 등록하기 <img src={newNextIcon} alt=""/></Link>
            </div>
            <div className="find_bg_gray">
              <div className="center_wrap">
                <div className="top_txt_gray">등록에 어려움이 있으시다면 <br/>아래 별도 서비스를 이용해보세요!</div>
                <ul className="menu_wrap">
                  <li>
                    <Link to="/Doongji/DoongjiProfessional">
                      <strong>내 집 내놓기</strong>
                      <span>전문가가<br/>도와드립니다.</span>
                      <img src={newNextIcon} alt="" className="arrow_img"/>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Doongji/DoongjiFinding">
                      <strong>내 집을<br/>찾아주세요</strong>
                      <span>좋은집<br/>찾아드립니다.</span>
                      <img src={newNextIcon} alt="" className="arrow_img"/>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Doongji/DoongjiManagement">
                      <strong>내 건물 월관리 의뢰</strong>
                      <span>건물 관리!<br/>이제는 둥지에게<br/>맡겨보세요!</span>
                      <img src={newNextIcon} alt="" className="arrow_img"/>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Doongji/DoongjiPartner">
                      <strong>공인중개사<br/>파트너 모집</strong>
                      <span>함께할 수 있는<br/>둥지장터</span>
                      <img src={newNextIcon} alt="" className="arrow_img"/>
                    </Link>
                  </li>
                </ul>
                <Link to="/MyPage/FAQList" className="banner_faq banner">
                  <img src={register2Icon} alt="" className="left_img"/>
                  <span className="txt_wrap">
                    <strong>매물 등록 시 어려움이 있으신가요?</strong>
                    <span>FAQ에서 도움을 받아보세요!</span>
                  </span>
                </Link>
                <Link to="/MyPage/FAQList" className="banner_what_p banner">
                  <img src={register3Icon} alt="" className="left_img"/>
                  <span className="txt_wrap">
                    <strong>Premium 서비스는 무엇인가요?</strong>
                    <span>자세히 알아보기</span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </>
  );
}
export default DoongjiSerious;