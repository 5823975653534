/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import DoongjiRatePlan from "../../components/payments/DoongjiRatePlan";
import JangterRatePlan from "../../components/payments/JangterRatePlan";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import "../../admin_css/board.css";

/* 요금/이용권 관리 */
const RatePlan = () => {
  //메뉴항목지정
  const [varMenuItem,setMenuItem] = useState('');

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'PAYMENTS'}/>
      <Menus varMenuType={'PAYMENTS'} varMenuItem={varMenuItem}/>
      <Routes>
        {/* 둥지 파트너 요금제 */}
        <Route path="DoongjiRatePlan" element={<DoongjiRatePlan setMenuItem={setMenuItem}/>} />
        {/* 장터 파트너 요금제 */}
        <Route path="JangterRatePlan" element={<JangterRatePlan setMenuItem={setMenuItem}/>} />
      </Routes>
    </>
  );
}
export default RatePlan;