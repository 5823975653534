import React from "react";
import { useNavigate } from 'react-router-dom';

const HeadTitle = ({ titleString, backString }) => {
  const navigate = useNavigate();

  // 백페이지 처리 함수
  const onBackPageHandler = async (e) => {
    try {
      if (backString === undefined || backString === null || backString === "" || backString === "backDoongji" || backString === -1) {
        // 히스토리가 없을 때 대비하여 navigate(-1) 실행
        navigate(-1);
      } else if (backString === "nonDoongji") {
        navigate('/Doongji/DoongjiSerious');
      } else if (backString === "nonWebDoongji" || backString === "Doongji/DoongjiDetail") {
        navigate('/RealEstate');
      } else if (backString === "PremiumList") {
        navigate('/MyPage/PremiumList');
      } else if (backString === "DoubleBack") {
        // 뒤로가기 2번
        navigate(-1);
        navigate(-1);
      } else if (backString === "MyPage") {
        navigate('/MyPage');
      } else {
        navigate(backString);
      }
    } catch (error) {
      // navigate(-1)이 실패했을 경우 한 번 더 시도
      console.error('첫 navigate 실패. 다시 시도 중...', error);
      navigate(-1);
    }

    console.log(backString);
  }

  return (
    <div className="head-top-wrap">
      <div className="head-top-inner">
        {backString === "none" ? null : <button onClick={e => onBackPageHandler(e)} className="btn-pre" />}
        <div className="top-tit" style={{ left: "50px", width: "80%", textOverflow: "ellipsis" }}>{titleString}</div>
      </div>
    </div>
  );
}

export default HeadTitle;
