/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Zipcode from '../../components/include/Zipcode';
// 이미지
import closeImage from "../../images/btn_close_white.png";

const { kakao } = window;

const DaumPostCode = ({ varOpenZipcode, setOpenZipcode, ChangeAddress }) => {

  useEffect(() => {
    if (varOpenZipcode) {
      // 배경 스크롤 비활성화
      document.body.style.overflow = 'hidden';
    } else {
      // 배경 스크롤 활성화
      document.body.style.overflow = 'auto';
    }

    // 컴포넌트 언마운트 시 overflow 속성 초기화
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [varOpenZipcode]);

  // 주소가 선택되면
  const onCompleteZipcodeHandler = (data) => {


    let fullAddr = data.jibunAddress === "" ? data.autoJibunAddress : data.jibunAddress; // 주소
    let extraAddr = "";
    const regcode = data.bcode;   // 행정구역 동코드 
    const dongName = data.bname;  // 동 이름
    const roadName = data.roadAddress;  // 도로 주소
    const zipcode = data.zonecode;  // 우편번호
    const userSelectedType = data.userSelectedType;
    console.log(data);

    // 특수주소 재정리
    if (data.addressType === "R") {
      if (data.buildingName !== "") {
        extraAddr += extraAddr !== "" ? `, ${data.buildingName}` : data.buildingName;
        fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
      }
    }

    // 좌표 만들기
    getLocation(zipcode, fullAddr, regcode, dongName, roadName, userSelectedType);
    // 우편번호 창 닫기
    setOpenZipcode(false);
  };

  // 주소로 좌표를 검색합니다
  const getLocation = (zipcode, fullAddr, regcode, dongName, roadName, userSelectedType) => {
    var geocoder = new kakao.maps.services.Geocoder();
    geocoder.addressSearch(fullAddr, function (result, status) {
      if (status === kakao.maps.services.Status.OK) {
        const latpos = result[0].y;
        const longpos = result[0].x;
        const locString = `${latpos},${longpos}`;
        ChangeAddress(zipcode, fullAddr, regcode, dongName, roadName, locString, userSelectedType);
      } else {
        ChangeAddress(zipcode, fullAddr, regcode, dongName, roadName, "", userSelectedType);
      }
    });
  } 

  return (
    <div className="poplayerZipcodeSearch" style={varOpenZipcode ? { display: "flex" } : { display: "none" }}>
  <div id="zipcode-modal" className="consbox">
    <div className="modal-header">
      <button className="btnclose" onClick={() => setOpenZipcode(false)}>
        <img src={closeImage} alt="닫기" />
      </button>
    </div>
    <div className="modal-content">
      {varOpenZipcode && (
        <Zipcode 
          varOpenZipcode={varOpenZipcode} 
          setOpenZipcode={setOpenZipcode} 
          onCompleteZipcodeHandler={onCompleteZipcodeHandler} 
        />
      )}
    </div>
  </div>
</div>

  );
}

export default DaumPostCode;
