/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";

import Header from "../../components/include/Header";
import Footer from "../../components/include/Footer";
import FooterCom from '../../components/include/FooterCom';

import QuickDoongjiMenu from "../../components/include/QuickDoongjiMenu";
import SearchHeader from '../../components/include/SearchHeader';
import PremiumDoongjiList from '../../components/doongji/PremiumDoongjiList';
import SearchedResult from '../../components/doongji/SearchedResult';

import SelectRegion from '../../components/include/SelectRegion';
import SelectFilters from '../../components/include/SelectFilters';
import FilterSelect from "../../components/popup/FilterSelect";
import FilterMinMax from "../../components/popup/FilterMinMax";
import FilterSubway from "../../components/popup/FilterSubway";
import SearchKeyword from "../../components/include/SearchKeyword";

import { isNull,isObjEmpty } from "../../components/common/Common";

import previewImage from "../../images/icon_pre_y.svg";

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const DoongjiList = () => {
  const loginState = Number(localStorage.getItem("memberLoginState"));
  const mem_idx = localStorage.getItem("mem_idx");
  //검색관련 변수
  const [varSearchingString,setSearchingString] = useState('');
  //지역 필터창
  const [varFilterMode,setFilterMode] = useState(0);  //필터분류번호
  const [varSearchRegionList,setSearchRegionList] = useState([]);
  //등록유형 == 0:없음/1:일반매물/2:중개매물/3:전체
  const [varFilterOptions,setFilterOptions] = useState({});  

  //전체 필터창
  const [varFixedFilterList,setFixedFilterList] = useState([]);
  const [varFixedFilterOptionList,setFixedFilterOptionList] = useState([]);
  const [varSubwayLineList,setSubwayLineList] = useState([]);
  const [varSubwayStationList,setSubwayStationList] = useState([]);
  const [varSortingList,setSortingList] = useState([]);

  //검색된 매물목록 관련
  const [varTotalCount,setTotalCount] = useState(0);
  const [varDoongjiList,setDoongjiList] = useState([]);

  //페이지 관련
  const [varPageNum,setPageNum] = useState(1);
  const [varPageSize] = useState(10);
  const [varMorePage,setMorePage] = useState(false);

  //둥지옵션목록 가져오기
  const getFilterOptions = async() => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_filter_option_list`).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //검색된 목록
        setFixedFilterList(datas.filters);
        setFixedFilterOptionList(datas.options);
        setSubwayLineList(datas.subwayline);
        setSubwayStationList(datas.subwaystation);
        //쏘팅목록
        setSortingList(datas.sortings);
        //getDoongjiList();
      }
    });
  }

  //둥지매물 검색후 목록 가져오기
  const getDoongjiList = async() => {
    //메모리에 저장
    if(isObjEmpty(varFixedFilterList)) return false;
    localStorage.setItem('DoongjiSearchingString',varSearchingString);
    localStorage.setItem('DoongjiSearchRegionList',JSON.stringify(varSearchRegionList));
    localStorage.setItem('DoongjiFixedFilterList',JSON.stringify(varFixedFilterList));
    let DoongjiFixedFilterOptionList = JSON.stringify(varFixedFilterOptionList);
    localStorage.setItem('DoongjiFixedFilterOptionList',DoongjiFixedFilterOptionList);
    localStorage.setItem('DoongjiFilterOptions',JSON.stringify(varFilterOptions));
    localStorage.setItem('DoongjiSortingList',JSON.stringify(varSortingList));

    //서버 호출
    const sendData = {mem_idx:mem_idx,pageNum:varPageNum,pageSize:varPageSize,searchString:varSearchingString,
      searchRegion:varSearchRegionList,filters:varFixedFilterList,options:varFixedFilterOptionList,sorting:varFilterOptions};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_doongji_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        // 주소 비공개 시 검색결과에 필터링
        let filteredResults = datas.results;
        let totalResults = datas.totalcount;
        if(varSearchRegionList.length !== 0) {
          filteredResults = filteredResults.filter(item => item.rp_hidden_address !== 1);
          totalResults = filteredResults.length
        }

        if(varPageNum === 1) {
          //검색된 전체수량
          // setTotalCount(datas.totalcount);
          setTotalCount(totalResults);
          //검색된 목록
          // setDoongjiList(datas.results);
          setDoongjiList(filteredResults);
        } else {
          // setDoongjiList(varDoongjiList.concat(datas.results));
          setDoongjiList(varDoongjiList.concat(filteredResults));
        }
        // if(datas.totalcount > varPageNum*varPageSize) setMorePage(true);
        if(totalResults > varPageNum*varPageSize) setMorePage(true);
        else setMorePage(false);
      } else {
        setTotalCount(0);
        setDoongjiList([]);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    $('.btm_quick_menu').show();
    $('.btm_quick_menu .q_open_btn').show();
    setFilterOptions({rp_mode:3,filters:2});

    const DoongjiSearchingString = localStorage.getItem('DoongjiSearchingString');
    if(!isNull(DoongjiSearchingString)) setSearchingString(DoongjiSearchingString);
    //console.log(DoongjiSearchingString);

    const DoongjiSearchRegionList = localStorage.getItem('DoongjiSearchRegionList');
    if(!isNull(DoongjiSearchRegionList) && JSON.parse(DoongjiSearchRegionList)?.length > 0) setSearchRegionList(JSON.parse(DoongjiSearchRegionList));
    //console.log(JSON.parse(DoongjiSearchRegionList));

    const DoongjiFixedFilterList = localStorage.getItem('DoongjiFixedFilterList');
    if(!isNull(DoongjiFixedFilterList) && JSON.parse(DoongjiFixedFilterList)?.length > 0) setFixedFilterList(JSON.parse(DoongjiFixedFilterList));
    //console.log(JSON.parse(DoongjiFixedFilterList));

    const DoongjiFixedFilterOptionList = localStorage.getItem('DoongjiFixedFilterOptionList');
    if(!isNull(DoongjiFixedFilterOptionList) && JSON.parse(DoongjiFixedFilterOptionList)?.length > 0) setFixedFilterOptionList(JSON.parse(DoongjiFixedFilterOptionList));
    //console.log(JSON.parse(DoongjiFixedFilterOptionList));

    const DoongjiFilterOptions = localStorage.getItem('DoongjiFilterOptions');
    if(!isNull(DoongjiFilterOptions) && JSON.parse(DoongjiFilterOptions)?.length > 0) setFilterOptions(JSON.parse(DoongjiFilterOptions));
    //console.log(JSON.parse(DoongjiFilterOptions));

    const DoongjiSortingList = localStorage.getItem('DoongjiSortingList');
    if(!isNull(DoongjiSortingList) && JSON.parse(DoongjiSortingList)?.length > 0) setSortingList(JSON.parse(DoongjiSortingList));
    
    if(isNull(DoongjiFixedFilterOptionList) || JSON.parse(DoongjiFixedFilterOptionList)?.length === 0) getFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //팝업창 열기
  const [varPopupSearchRegion,setPopupSearchRegion] = useState(false);
  const [varPopupSelectFilter,setPopupSelectFilter] = useState(false);
  const [varPopupFilterSelect,setPopupFilterSelect] = useState(false);
  const [varPopupFilterMinMax,setPopupFilterMinMax] = useState(false);
  const [varPopupFilterSubway,setPopupFilterSubway] = useState(false);
  const [varPopupSearchKeyword,setPopupSearchKeyword] = useState(false);

  const onShowQuickMenuHandler = (e,bmode) => {
    if(bmode) {
      $(".quick_pop").fadeIn("1500");
      $(".layer-bg").fadeIn("1500");
      $(".quick_close_btn").fadeIn("1500");
    } else {
      $(".quick_pop").fadeOut("1500");
      $(".layer-bg").fadeOut("1500");
      $(".quick_close_btn").fadeOut("1500");
    }
  }
  const onPopupWinHandler = (bmode) => {
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }

  //지역선택 팝업창
  const onPopupRegionHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupSearchRegion(false);
      //검색시작
      setPageNum(1);
    } else {
      onPopupWinHandler(bmode);
      setPopupSearchRegion(bmode);
    }
  }
  const onPopupFilterHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupSelectFilter(false);
      //검색시작
      setPageNum(1);
    } else {
      onPopupWinHandler(bmode);
      setPopupSelectFilter(bmode);
    }
  }
  //필터링
  const onSelectFilterHandler = (e,vsm_idx) => {
    e.preventDefault();
    setFilterMode(vsm_idx);
    onPopupWinHandler(true);
    console.log(varFilterMode);
    switch(vsm_idx) {
      case 16: 
        setPopupFilterSubway(true); break;
      case 3:
      case 4:
      case 5:
        setPopupFilterMinMax(true); break;
      default:
        setPopupFilterSelect(true); break;
    }
  }
  const onPopupFilterSelectHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupFilterSelect(false);
      //검색시작
      setPageNum(1);
    } else {
      onPopupWinHandler(bmode);
      setPopupFilterSelect(bmode);
    }
  }
  const onPopupFilterMinMaxHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupFilterMinMax(false);
      //검색시작
      setPageNum(1);
    } else {
      onPopupWinHandler(bmode);
      setPopupFilterMinMax(bmode);
    }
  }
  const onPopupFilterSubwayHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupFilterSubway(false);
      //검색시작
      setPageNum(1);
    } else {
      onPopupWinHandler(bmode);
      setPopupFilterSubway(bmode);
    }
  }
  const onPopupSearchKeywordHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupSearchKeyword(false);
      //검색시작
      setPageNum(1);
    } else {
      setPopupSearchKeyword(bmode);
      onPopupWinHandler(bmode);
    }
  }

  //매물종류 = 중개매물/일반매물
  const FilterReloading =() => {
    setPageNum(1);
    //getDoongjiList();
  }

  useEffect(() => {
    getDoongjiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum]);  
  
  useEffect(() => {
    getDoongjiList();
    console.log("varSearchRegionList :",varSearchRegionList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSearchRegionList,varFilterOptions,varSearchingString,varFixedFilterList]);  

  return (
    <>
      <Header/>
      <div id="content">
       {/* 배경죽이는 항목 */}
        <div className="layer-bg" style={{backgroundColor:"rgb(0 0 0 / 69%)"}}></div>  
        <div className="quick_close_btn close-layer">
          <img src={previewImage} alt="" onClick={e=>onShowQuickMenuHandler(e,false)} style={{cursor:"pointer"}}/>&nbsp;&nbsp;뒤로가기
        </div>
        {loginState===0?null:<QuickDoongjiMenu onShowQuickMenuHandler={onShowQuickMenuHandler} />}
        <div className="main_wrap">
          {/* 검색할 지역 선택 및 검색문자 입력 */}
          <SearchHeader varSearchingString={varSearchingString} setSearchingString={setSearchingString} onPopupSearchKeywordHandler={onPopupSearchKeywordHandler} 
                        varSearchRegionList={varSearchRegionList} setSearchRegionList={setSearchRegionList}
                        onPopupRegionHandler={onPopupRegionHandler} setPageNum={setPageNum}/>
          <div className="main_con">
            {/* 프리미엄광고 표시부 */}
            <PremiumDoongjiList varSearchRegionList={varSearchRegionList} />
            {/* 검색결과 매물목록 표시부 */}
            <SearchedResult varSortingList={varSortingList} varTotalCount={varTotalCount} 
                        varFilterOptions={varFilterOptions} setFilterOptions={setFilterOptions}
                        varDoongjiList={varDoongjiList} setDoongjiList={setDoongjiList} 
                        varFixedFilterList={varFixedFilterList} getFilterOptions={getFilterOptions}
                        onSelectFilterHandler={onSelectFilterHandler} onPopupFilterHandler={onPopupFilterHandler}
                        FilterReloading={FilterReloading} varMorePage={varMorePage} 
                        varPageNum={varPageNum} setPageNum={setPageNum}/>
          </div>
          {/* 지역선택팝업창------ */}
          <SelectRegion varPopupSearchRegion={varPopupSearchRegion} onPopupRegionHandler={onPopupRegionHandler}
                        varSearchRegionList={varSearchRegionList} setSearchRegionList={setSearchRegionList} />
          {/* 전체 필터창------- */}
          <SelectFilters varPopupSelectFilter={varPopupSelectFilter} 
                          varSubwayLineList={varSubwayLineList} varSubwayStationList={varSubwayStationList}
                          varFixedFilterList={varFixedFilterList} setFixedFilterList={setFixedFilterList}
                          varFixedFilterOptionList={varFixedFilterOptionList} setFixedFilterOptionList={setFixedFilterOptionList} 
                          onPopupFilterHandler={onPopupFilterHandler} onSelectFilterHandler={onSelectFilterHandler}/>
          {/* 팝업창-선택형------- */}
          <FilterSelect varFilterMode={varFilterMode} varPopupFilterSelect={varPopupFilterSelect}
                        varFixedFilterList={varFixedFilterList} setFixedFilterList={setFixedFilterList}
                        varFixedFilterOptionList={varFixedFilterOptionList} setFixedFilterOptionList={setFixedFilterOptionList} 
                        onPopupFilterSelectHandler={onPopupFilterSelectHandler} />  
          {/* 팝업창-최소/최대값지정형------- */}
          <FilterMinMax varFilterMode={varFilterMode} varPopupFilterMinMax={varPopupFilterMinMax}
                        varFixedFilterList={varFixedFilterList} setFixedFilterList={setFixedFilterList}
                        onPopupFilterMinMaxHandler={onPopupFilterMinMaxHandler}/> 
          {/* 팝업창-지하철역 선택형------- */}
          <FilterSubway varFilterMode={varFilterMode} varPopupFilterSubway={varPopupFilterSubway}
                        varSubwayLineList={varSubwayLineList} varSubwayStationList={varSubwayStationList}
                        varFixedFilterList={varFixedFilterList} setFixedFilterList={setFixedFilterList}
                        onPopupFilterSubwayHandler={onPopupFilterSubwayHandler}/> 
          {/* 키워드 검색 팝업창--------- */}  
          <SearchKeyword varPopupSearchKeyword={varPopupSearchKeyword} onPopupSearchKeywordHandler={onPopupSearchKeywordHandler}
                          setSearchingString={setSearchingString} />
        </div>
        <FooterCom/>
      </div>
      <Footer varPageMode={1} />
    </>
  );
}
export default DoongjiList;