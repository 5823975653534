import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import TermContent from './TermContent';
import { escapeHtml,unescapeHtml } from "../../../components/common/Common";

function AdminTerms({setMenuItem}) {
  let { termCode } = useParams();
  const [varTermTitle,setTermTitle] = useState('');
  const [varTermMenu,setTermMenu] = useState('');
  const [varTermMessage,setTermMessage] = useState('');

  //약관 변수값 저장 변수
  const [varTerms, setTerms] = useState({
    serviceterm : "",
    privacy : "",
    marketing : "",
    product_manage : "",
    supply_information : ""
  });

  //약관 읽어오기
  const getTermsDocument = async () => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_terms`).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        let copyData = datas.results;
        //문자열 변환
        copyData.serviceterm = unescapeHtml(copyData.serviceterm);
        copyData.privacy = unescapeHtml(copyData.privacy);
        copyData.marketing = unescapeHtml(copyData.marketing);
        copyData.product_manage = unescapeHtml(copyData.product_manage);
        copyData.supply_information = unescapeHtml(copyData.supply_information);
        setTerms(copyData);
      }
    });
  }
  //표시할 약관 종류 설정
  const resetPageMode = (termCode) => {
    let termItem="",termMenu="";
    console.log(termCode);
    switch(Number(termCode)) {
      default:
      case 1: 
        setTermTitle("이용악관");
        termItem = "ServiceTerm";
        termMenu = "serviceterm"; 
        setTermMessage("이용약관을 저장합니까?");
        break;
      case 2: 
        setTermTitle("개인정보처리방침");
        termItem = "Privacy";
        termMenu = "privacy"; 
        setTermMessage("개인정보처리방침을 저장합니까?");
        break;
      case 3: 
        setTermTitle("마케팅정보수신동의");
        termItem = "Marketing";
        termMenu = "marketing"; 
        setTermMessage("마케팅정보수신동의사항을 저장합니까?");
        break;      
      case 4: 
        setTermTitle("매물관리정책동의");
        termItem = "ProductManage";
        termMenu = "product_manage"; 
        setTermMessage("매물관리정책동의사항을 저장합니까?");
        break;
      case 5: 
        setTermTitle("제3자정보제공동의");
        termItem = "SupplyInformation";
        termMenu = "supply_information"; 
        setTermMessage("제3자정보제공동의사항을 저장합니까?");
        break;
    }
    console.log(termItem,termMenu);
    setMenuItem(termItem);
    setTermMenu(termMenu);
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    resetPageMode(termCode);
    getTermsDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termCode]);

  //약관 재저장
  const onUpdateTermsHandler = async (e) => {
    e.preventDefault();
    if (!window.confirm(varTermMessage)) {
          return false;
    }
    //문자열 변경
    let copyData = {...varTerms};
    copyData.serviceterm = escapeHtml(copyData.serviceterm);
    copyData.privacy = escapeHtml(copyData.privacy);
    copyData.marketing = escapeHtml(copyData.marketing);
    copyData.product_manage = escapeHtml(copyData.product_manage);
    copyData.supply_information = escapeHtml(copyData.supply_information);
    //약관을 서버로 저장
    const sendData = {termCode:termCode,varTerms:copyData};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_terms`, sendData).then((res) => {
      alert(res.data.message);
    });
  }

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">{varTermTitle}</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt=""/>약관관리<img src={arrImage} alt=""/>{varTermTitle}
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <TermContent varTermMenu={varTermMenu} varTerms={varTerms} setTerms={setTerms} />
        </div>
        <div className="btn-center">
          <button className="btn" style={{maxWidth:"90px"}} onClick={(e)=>onUpdateTermsHandler(e)}>저장</button>
        </div>
      </div>
    </div>
  )
}

export default AdminTerms;