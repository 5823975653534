import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';
import { isObjEmpty,onlyBankAccountNumber } from "../../../components/common/Common";

//은행계좌 정보 표시
function CompanyAccountDetail({varShowDetailPage,setShowDetailPage,varBankLists,varCurrentAccount,varAccountLists,getCompanyAccountsList}) {
  //약관 변수값 저장 변수
  const varAccounts = {
    bh_idx : 0,
    bl_code : '000',
    bh_account: "",
    bh_owner: ""
  };
  const [varCurrentAccountData,setCurrentAccountData] = useState({});
  
/*   //추가/선택된 은행계좌 정보 읽어오기
  const getCompanyAccountState = async() => {
    if (varCurrentAccount !== undefined && varCurrentAccount > 0) {
      const currentData = varAccountLists.filter(element=>element.admin_idx===varCurrentAccount);
      setCurrentAccountData(currentData[0]);
    }
  }; */


  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(varCurrentAccount === undefined || varCurrentAccount === 0) {
      setCurrentAccountData(varAccounts);
    } else {
      const currentData = varAccountLists.filter(element=>element.bh_idx===varCurrentAccount);
      setCurrentAccountData(currentData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCurrentAccount]);

  //은행계좌 정보 저장
  const onUpdateAccountHandler = async (e) => {
    if (varCurrentAccountData.bl_code === '' || varCurrentAccountData.bl_code === '000') {
      alert('은행을 먼저 선택해 주세요'); return false;
    } else if (varCurrentAccountData.bh_account === '') {
      alert('계좌번호를 입력해 주세요'); return false;
    } else if (varCurrentAccountData.bh_owner === '') {
      alert('예금주를 입력해 주세요'); return false;
    }
    await axios.post(`${CONFIG.SERVER_HOST}/_update_company_account_state`, varCurrentAccountData).then((res) => {
      if (res.data.retvalue === 1) {
        alert(res.data.message);
        setShowDetailPage(false);
        getCompanyAccountsList();
      }
    });
  }

  //은행계좌 정보 입력
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    if(name === "bh_account") {
      const numbers = onlyBankAccountNumber(value);
      setCurrentAccountData({...varCurrentAccountData,[name]:numbers});
    } else {
      setCurrentAccountData({...varCurrentAccountData,[name]:value});
    }
  };

  const onCancelAccountHandler = (e) => {
    setShowDetailPage(false);
  }

  if(varShowDetailPage) {
    return isObjEmpty(varCurrentAccountData)? null:(
      <div className="content">
        <div className="main-box">
          <table className="write">
            <thead></thead>
            <tbody>
              <tr>
                <th>은행</th>
                <td>
                  <select name="bl_code" className="bank-box" value={varCurrentAccountData.bl_code} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value='000'>은행선택</option>
                    {varBankLists.map((banks,i)=>(
                      <option key={"banks_"+i} value={banks.bl_code}>{banks.bl_name}</option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <th>계좌번호</th>
                <td><input type="text" className="account-box" name="bh_account" value={varCurrentAccountData.bh_account} onChange={(e)=>onChangeInputHandler(e)} maxLength="30" /></td>
              </tr>
              <tr>
                <th>예금주</th>
                <td><input type="text" className="name-box" name="bh_owner" value={varCurrentAccountData.bh_owner} onChange={(e)=>onChangeInputHandler(e)} maxLength="20" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" onClick={(e)=>onUpdateAccountHandler(e)}>저장</button>
          <button className="btn line" style={{width:"80px"}} onClick={(e)=>onCancelAccountHandler(e)}>취소</button>
        </div>
      </div>
    )
  }
}

export default CompanyAccountDetail;