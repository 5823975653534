import React, { useRef, useEffect } from "react";
// import CONFIG from '../../configs/Configs';
import closeIcon from '../../images/icon_close.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Zoom, Pagination } from 'swiper/modules';

import noSlideImage from '../../images/no_img.png'; // 플레이스홀더 이미지

const SlidingZoomPhotos = ({ varPhotoImages, varOpenPhoto, onOpenPhotoHandler, currentPhotoIndex }) => {
  const imgRef = useRef([]);
  const tBoxRef = useRef([]);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && varOpenPhoto) {
      swiperRef.current.slideToLoop(currentPhotoIndex, 0); // 0은 이동 속도(밀리초)입니다.
    }
  }, [currentPhotoIndex, varOpenPhoto]);

  return (
    <div className="poplayerPhoto" style={varOpenPhoto ? { display: "block" } : { display: "none" }}>
      <div className="date-right-close">
        <img src={closeIcon} alt="" onClick={e => onOpenPhotoHandler(e, false)} />
      </div>
      <div id="popup_area" style={{ width: "100vw", height: "100vh" }} >
        <Swiper
          onSwiper={(swiper) => { swiperRef.current = swiper; }}
          loop={true}
          zoom={true}
          pagination={{ clickable: true }}
          modules={[Zoom, Pagination]}
          className="mySwiper"
        >
          {varPhotoImages.map((photoObj, i) => (
            <SwiperSlide key={'photo_' + i}>
              <div className="swiper-zoom-container" ref={(el) => (tBoxRef.current[i] = el)}>
                {photoObj.loading ? (
                  <div className="loading-indicator">
                    {/* 로딩 스피너 */}
                    <div className="loader"></div>
                  </div>
                ) : photoObj.src ? (
                  <>
                    <img
                      className="shown-image"
                      ref={(el) => (imgRef.current[i] = el)}
                      src={photoObj.src}
                      alt=""
                      style={{ objectFit: 'cover' }}
                    />
                    {/* 워터마크 */}
                    {/* <img
                      src={`${CONFIG.SERVER_HOST}/water_m.png`}
                      alt="Watermark"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '95px',
                        height: 'auto',
                        objectFit: 'cover',
                        pointerEvents: 'none',
                      }}
                    /> */}
                  </>
                ) : (
                  // 이미지 로드 실패 시 플레이스홀더 표시
                  <img
                    className="shown-image"
                    src={noSlideImage}
                    alt=""
                    style={{ objectFit: 'cover' }}
                  />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SlidingZoomPhotos;
