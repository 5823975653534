/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";
import { ChangeDateStringOnlyDate } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

//공지사항
const FAQList = () => {
  const mem_idx = localStorage.getItem("mem_idx");
  //const [varTotalCount,setTotalCount] = useState(0);
  const [varFAQList,setFAQList] = useState([]);

  //공지사항 목록 가져오기
  const getFAQList = async() => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_faq_list`).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //검색된 전체수량
        //setTotalCount(datas.totalcount);
        //검색된 목록
        setFAQList(datas.results);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    getFAQList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //내용 보이고 안보이고
  const onExpandFAQHandler = (board_idx) => {
    const titleBlock = document.getElementById("title_"+board_idx);
    if(titleBlock.classList.contains('on')) {
      titleBlock.classList.remove('on');
      $('#comment_'+board_idx).slideUp();
    } else {
      titleBlock.classList.add('on');
      $('#comment_'+board_idx).slideDown();
      ShowBoardData(board_idx);
    }
  }
  const ShowBoardData = async (board_idx) => {
    const sendData = {mem_idx:mem_idx,board_idx:board_idx,boardTable:'board_faq'};
    await axios.post(`${CONFIG.SERVER_HOST}/_plus_hit_board`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 0) {
        alert(datas.message);
      }
    });
  }

  return (
    <>
      <Header/>
      <HeadTitle titleString={"FAQ"}/>
      <div id="content">
        <div className="pd_wrap02">
          <div className="faq_wrap">
          {varFAQList?.length === 0?
            <div className="f_con b_shadow">
              <div className="top_wrap">
                <div className="center_wrap">
                  <div className="tit03" style={{textAlign:"center",color:"#bbb"}}>등록된 FAQ가 없습니다.</div>
                </div>
              </div>
            </div>
          :varFAQList.map((faq,i)=>(
            <div className="f_con b_shadow" key={"faq_"+i}>
              <div className="top_wrap" id={"title_"+faq.board_idx}>
                <div className="center_wrap">
                  <div className="tit03" style={{width:"320px"}}>{faq.board_title}</div>
                  <div className="date">{ChangeDateStringOnlyDate(faq.board_rdate)}</div>
                  <div className="right_arrow rote180" onClick={e=>onExpandFAQHandler(faq.board_idx)}>&nbsp;</div>
                </div>
              </div>
              <div className="txt_con" id={"comment_"+faq.board_idx}>
                <div className="center_wrap" style={{whiteSpace:"pre-line"}}>{faq.board_comment}</div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default FAQList;