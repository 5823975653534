import React from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import {AddOptionToString,ChangeDateStringOnlyDate,ResetCheckBoxes,SetFindingAddress} from "../../../components/common/Common";

/* 기타서비스 목록 표시 페이지 */
function ServiceResult({pageMode,varMaxNumPage,varRegionList,varServiceLists,setServiceLists,getServiceList}) {
  let tableName = "", prefixName = "", callPage = "";
  switch(Number(pageMode)) {
    default:
    case 1:
      tableName = "doongji_request";
      prefixName = "rq";
      callPage = "ServiceDetail";
      break;
    case 2:
      tableName = "doongji_finding";
      prefixName = "rf";
      callPage = "FindingDetail";
      break;
    case 3:
      tableName = "doongji_manage";
      prefixName = "ri";
      callPage = "ServiceDetail";
      break;
    case 4:
      tableName = "doongji_partner";
      prefixName = "rw";
      callPage = "PartnerDetail";
      break;
  }

  //목록의 모든 항목 선택하기
  const onCheckedAll = (e) => {
    const bChecked = Number(e.currentTarget.checked);
    clearBoardLists(bChecked);
    //배열값 변경
    let copyDatas = [...varServiceLists];
    for (var i=0; i < copyDatas.length; i++) {
      copyDatas[i].checked = bChecked;
    }
    setServiceLists(copyDatas);
  } 
  //체크박스 초기화
  const clearBoardLists = (bmode) => {
    const checkBoxes = document.querySelectorAll('.del-item');
    for(var i=0; i < checkBoxes?.length; i++) {
      checkBoxes[i].checked = bmode;
    }
  }

  //목록상의 항목 선택하기
  const onCheckedElement = (e,indexNum) => {
    const bChecked = Number(e.currentTarget.checked);
    const findIndex = varServiceLists.findIndex(element=>element[`${prefixName}_idx`]===indexNum);
    let copyLists = [...varServiceLists];
    copyLists[findIndex].checked = bChecked;
    setServiceLists(copyLists);
    ResetCheckBoxes();
  } 

  //기타서비스 선택 항목 삭제
  const onDeleteServiceHandler = async (e) => {
    //선택된 회원목록 추출
    let delListString = "";
    let delService = 0;
    varServiceLists.map(element=>{
      if(element.checked) {
        delService++;
        delListString = AddOptionToString(element[`${prefixName}_idx`],delListString,',');
      }
      return null;
    });
    if(delService === 0) {
      alert('삭제할 항목이 선택되어 있지 않습니다.');
      return false;
    }
    if(!window.confirm('선택된 항목을 삭제하시겠습니까?')) return false;

    const sendData = {stable:tableName, sprefix:prefixName, delListString:delListString};
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_product_list`,sendData).then((res) => {
      const datas = res.data;
      alert(datas.message);
      if(datas.retvalue === 1) {
        //회원목록 재설정
        getServiceList();
      }
    });
    const selectAllBox = document.getElementById('deleteItems');
    selectAllBox.checked = false;
  }

  return (
    <div className="main-box">
      <div className="btn-right">
        {/* <Link to={`/Admin/ProductService/${callPage}/${pageMode}/0`} className="btn-danger">추가</Link> */}
        <button onClick={(e)=>onDeleteServiceHandler(e)} className="btn line">삭제</button>
      </div>
      <table className="list">
        <colgroup>
          <col style={{width:"5%"}} />
          <col style={{width:"5%"}} />
          <col style={{width:"18%"}} />
          <col style={{width:"20%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"12%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"10%"}} />
        </colgroup>
        <thead>
          <tr>
            <th className="board-list">
              <input type="checkbox" id="deleteItems" onChange={(e)=>onCheckedAll(e)} />
            </th>
            <th>번호</th>
            <th>신청제목</th>
            <th>{Number(pageMode)===2?"찾는동네":"매물주소"}</th>
            <th>매물종류</th>
            <th>등록일자</th>
            <th>진행상태</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {varServiceLists.map((service, i) => (
            <tr key={'service_'+service[`${prefixName}_idx`]}>
              <td className="board-list">
                <input type="checkbox" className={"del-item"} id={"deleteItem_" + service[`${prefixName}_idx`]} 
                          onChange={(e)=>onCheckedElement(e,service[`${prefixName}_idx`])}/>
              </td>
              <td>{varMaxNumPage - i}</td>
              <td className="ellipsis">{service[`${prefixName}_title`]}</td>
              <td className="ellipsis">{Number(pageMode)===2?SetFindingAddress(service,varRegionList):service[`${prefixName}_address1`]+" "+service[`${prefixName}_address2`]}</td>
              <td className="ellipsis">{service.vfo_name}</td>
              <td className="ellipsis">{ChangeDateStringOnlyDate(service[`${prefixName}_rdate`])}</td>
              <td className="ellipsis">
                {service[`${prefixName}_status`]===1?"답변대기중"
                :service[`${prefixName}_status`]===2?"답변완료" : ""
                }
              </td>
              <td>
                <Link to={`/Admin/ProductService/${callPage}/${pageMode}/${service[`${prefixName}_idx`]}`} style={{width:"90px"}} className="btn line">세부정보</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ServiceResult;