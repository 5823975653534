/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"; 
import { isObjEmpty } from "../common/Common";
import $ from "jquery";
import colseIcon from "../../images/icon_close.png";

const DetailStatus = ({varProductData,varTerms,onChangeInputHandler,onChangeRadioInputHandler}) => {
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const [varOpenTerms,setOpenTerms] = useState(false);
  const [varCurrentTerm,setCurrentTerm] = useState('product_manage');  
  
  //약관팝업창 열기/닫기
  const onOpenTermsHandler = (e,bmode,imode) => {
    let sobject = "product_manage";
    if(bmode) {
      if(imode === 2) sobject = "supply_information";
    }
    setCurrentTerm(sobject);
    setOpenTerms(bmode);
    onPopupWinHandler(bmode);
  } 
  const onPopupWinHandler = (bmode) => {
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }
  return isObjEmpty(varProductData) ? null : (
    <>
      {/* 일반매출의 경우 */}
      <div className="form_wrap">
        <ul className="dan2 aitem_center">
          <li className="pl10">전화번호 비공개</li>
          <li className="w50">
            <ul className="dan2">
              <li className="terms_chk35">
                <input type="radio" name="rp_hidden_phone" className="chk1" id="rp_hidden_phone0" value="0"
                      defaultChecked={varProductData.rp_hidden_phone===0} onChange={e=>onChangeRadioInputHandler(e)}/>
                <i></i><label htmlFor="rp_hidden_phone0" >공개</label>
              </li>
              <li className="terms_chk35">
                <input type="radio" name="rp_hidden_phone" className="chk1" id="rp_hidden_phone1" value="1"
                      defaultChecked={varProductData.rp_hidden_phone===1} onChange={e=>onChangeRadioInputHandler(e)}/>
                <i></i><label htmlFor="rp_hidden_phone1" >비공개</label>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      {mem_mode!==0?null:
        <div className="form_wrap">
          <ul className="dan2 aitem_center">
            <li className="pl10">등록인 유형</li>
            <li className="w50">
              <ul className="dan2 aitem_center">
                <li className="terms_chk35">
                  <input type="radio" name="rp_register_type" className="chk1" id="rp_register_type0" value="0" 
                        defaultChecked={varProductData.rp_register_type===0} onChange={e=>onChangeRadioInputHandler(e)}/>
                  <i></i><label htmlFor="rp_register_type0" >임차인</label>
                </li>
                <li className="terms_chk35">
                  <input type="radio" name="rp_register_type" className="chk1" id="rp_register_type1" value="1"
                        defaultChecked={varProductData.rp_register_type===1} onChange={e=>onChangeRadioInputHandler(e)}/>
                  <i></i><label htmlFor="rp_register_type1" >임대인</label>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      }
      {/* 약관동의 */}
      <div className="agree_area" style={{paddingBottom:"20px"}}>
        <div className="line02"></div>
        <div className="terms_chks">
          <input type="checkbox" name="rp_manage_agree" id="rp_manage_agree" className="chk1"
                defaultChecked={varProductData.rp_manage_agree===1} onChange={e=>onChangeInputHandler(e)}/>
          <i></i><label htmlFor="rp_manage_agree">(필수) 매물 관리 정책 동의</label>
          <div className="agr_reg01_btn"><button style={{fontSize:"14px"}} onClick={e=>onOpenTermsHandler(e,true,1)}>보기</button></div>
        </div>
        <div className="terms_chks">
          <input type="checkbox" name="rp_supplyinfo_agree" id="rp_supplyinfo_agree" className="chk1"
                defaultChecked={varProductData.rp_supplyinfo_agree===1} onChange={e=>onChangeInputHandler(e)}/>
          <i></i><label htmlFor="rp_supplyinfo_agree">(필수) 제3자 정보 제공 동의</label>
          <div className="agr_reg02_btn"><button style={{fontSize:"14px"}} onClick={e=>onOpenTermsHandler(e,true,2)}>보기</button></div>
        </div>
        <br/>
      </div>
      {/* 팝업-약관표시창 */}
      <div className="layer-pop pop-condition" style={varOpenTerms?{display:"block"}:{display:"none"}}>
        <div className="layer_inner" style={{padding:"5px 0px",height:"80%"}}>
          <div className="pop_head">
            <div className="layer_tit">{varTerms[varCurrentTerm+'_title']}</div>
            <div className="close_layer top_close_btn"><img src={colseIcon} alt="" onClick={e=>onOpenTermsHandler(e,false,0)}/></div>
          </div>
          <div className="center_wrap" style={{padding:"0px"}}>
            <div className="con">
              <div className="con_box" style={{marginTop:"0px"}}>
                <div className="desc">{varTerms[varCurrentTerm]}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_bottom one_btn">
          <button className="btn_style_yes close_layer" onClick={e=>onOpenTermsHandler(e,false,0)}>확인</button>
        </div>
      </div>
    </>
  );
}
export default DetailStatus;