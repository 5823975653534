import React, { useState,useEffect } from "react";
import { useParams,useNavigate,Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import {ChangeDateStringOnlyDate,isObjEmpty} from "../../../components/common/Common";

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';


//신고물 정보 표시
function ReportDetail({setMenuItem}) {
  const {pr_idx} = useParams();
  const navigate = useNavigate();

  //선택/추가할 저장 변수 
  const [varProductDatas, setProductDatas] = useState({});
  //신고물 정보
  const [varProductInfo, setProductInfo] = useState({title:"",names:"",links:""});

  //신고물 정보 읽어오기
  const getReportInformation = async () => {
    const sendData = {pr_idx:pr_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_report_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1 && datas.results?.length > 0) {
        const reportData = datas.results[0];
        setProductDatas(reportData);
        let productTitle = "";
        let productMakerIdx = 0;

        //등록자
        if(reportData.pr_mode === 1) {
          productMakerIdx = reportData.rp_mem_idx;
          productTitle = reportData.rp_title;
        } else if(reportData.pr_mode === 2) {
          productMakerIdx = reportData.jt_mem_idx;
          productTitle = reportData.jt_title;
        } else {
          productMakerIdx = reportData.jp_mcm_idx;
          productTitle = reportData.jp_names;
        }
        
        setProductInfo({...varProductInfo,title:productTitle});
        GetMakerInformation(reportData.pr_mode,productMakerIdx);                    
      }
    });
  }

  //신고물 등록자 정보 호출
  const GetMakerInformation = async(prMode,maker_idx) => {
    const sendData = {prMode:prMode,maker_idx:maker_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_product_maker`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        const memInfo = datas.results;
        let snames = "";
        if(prMode === 3){
          snames = `${memInfo.mem_name} (${memInfo.mem_nickname} : ${memInfo.mem_email})`;
        } else {
          snames = `${memInfo.mem_nickname} (${memInfo.mem_email})`;
        }
        const memMode = memInfo.mem_mode;
        const memIdx = memInfo.mem_idx;
        let productLinks = `/Admin/Member/MemberDetail/${memMode+1}/${memIdx}`;
        setProductInfo({...varProductInfo,names:snames,links:productLinks});
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('ReportList');
    getReportInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //신고물 정보 입력
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    if(name === "pr_status") setProductDatas({...varProductDatas,[name]:Number(value)});
    else setProductDatas({...varProductDatas,[name]:value});
  };

  //신고물 정보 저장
  const onUpdateProductHandler = async (e) => {
    if (!window.confirm('신고에 대한 상태를 저장합니까?')) return false;

    const sendData = {pr_idx:pr_idx, infos:varProductDatas};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/admin_update_report_information`, sendData).then((res) => {
      alert(res.data.message);
      if (res.data.retvalue === 1) {
        navigate("/Admin/ProductList/ReportList");
      }
    });
  }
  //신고자보기
  const onShowMemberDetailHandler = (e) => {
    navigate(`/Admin/Member/MemberDetail/1/${varProductDatas.mem_idx}`);
  }
  //상품등록자 보기
  const onShowRegisterDetailHandler = (e) => {
    navigate(varProductInfo.links);
  }
  //신고물보기
  const onShowProductDetailHandler = (e) => {
    let sPageName = "";
    if(varProductDatas.pr_mode===1) sPageName = "DoongjiDetail";
    else if(varProductDatas.pr_mode===2) sPageName = "JangterDetail";
    else if(varProductDatas.pr_mode===3) sPageName = "PartnerDetail";
    else return false;
    navigate(`/Admin/ProductList/${sPageName}/${varProductDatas.dj_idx}`);
  }

  return  isObjEmpty(varProductDatas)?null: (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">신고물 정보</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>둥지/장터관리<img src={arrImage} alt=""/>이용 관리<img src={arrImage} alt=""/>신고물 정보
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <table className="write">
          <colgroup>
              <col width="20%"></col>
              <col width="30%"></col>
              <col width="20%"></col>
              <col width="30%"></col>
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>신고자</th>
                <td style={{cursor:"pointer"}} onClick={onShowMemberDetailHandler}>{varProductDatas.mem_nickname} ({varProductDatas.mem_email})</td>
                <th>신고분야</th>
                <td>{varProductDatas.pr_mode===1?"둥지":varProductDatas.pr_mode===2?"장터":"파트너"}</td>
              </tr>
              <tr>
                <th>신고대상</th>
                <td style={{cursor:"pointer"}} onClick={onShowProductDetailHandler}>
                  {varProductDatas.pr_mode===1?varProductDatas.rp_title:varProductDatas.pr_mode===2?varProductDatas.jt_title:varProductDatas.jp_names}
                </td>
                <th>작성자</th>
                <td style={{cursor:"pointer"}} onClick={e=>onShowRegisterDetailHandler(e)}>{varProductInfo.names}</td>
              </tr>
              <tr>
                <th>신고항목</th>
                <td>{varProductDatas.pr_reason===0?"허위매물":"기타"}</td>
                <th>신고내용</th>
                <td>{varProductDatas.pr_comment}</td>
              </tr>
              <tr>
                <th>신고일자</th>
                <td>{ChangeDateStringOnlyDate(varProductDatas.pr_rdate)}</td>
                <th>진행상태</th>
                <td>
                  <select className="select-box" name="pr_status" value={varProductDatas.pr_status} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value={0}>신고삭제</option>
                    <option value={1}>신고중</option>
                    <option value={2}>신고확인</option>
                    <option value={3}>경고(블라인드)</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>관리자메모</th>
                <td colSpan={3}><textarea name="pr_admin_memo" style={{width:"100%"}} value={varProductDatas.pr_admin_memo||""} onChange={e=>onChangeInputHandler(e)}/></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" style={{width:"100px"}} onClick={(e)=>onUpdateProductHandler(e)}>저장</button>
          <Link to={"/Admin/ProductList/ReportList"} style={{width:"100px"}} className="btn line">목록</Link>
        </div>
      </div>
    </div>
  )
}

export default ReportDetail;