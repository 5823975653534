import React, { useState,useEffect } from "react"; 
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import { addThousandPoint,isObjEmpty } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

const BuyVoucher = () => {
  //이용권 형식 : 1:둥지/2:장터
  const ph_mode = Number(localStorage.getItem("mem_mode"));
  const { ph_idx } = useParams();

  const [varPaymentData,setPaymentData] = useState({});

  //프리미엄 광고 목록 가져오기
  const getVoucherList = async() => {
    const sendData = {ph_mode:ph_mode,ph_idx:ph_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_voucher_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //이용권신청정보
        if(datas.payments?.length > 0) {
          let prefixName = "";
          if(ph_mode === 1) prefixName = "둥지_매물 ";
          else prefixName = "장터 등록 카테고리 ";

          const payments = datas.payments[0];
          const voItems = payments.vo_slot;
          payments.vo_items = prefixName+voItems;
          if(ph_mode === 2) payments.vo_items += "개 선택 이용권";
          payments.vo_name = prefixName.substring(0,2)+voItems;
          if(ph_mode === 1) payments.vo_items += "개 등록 이용권";
          setPaymentData(payments);
        }
      }
    });
  }
  useEffect(() => {
    //전체 백화면 색 변경
    document.getElementById('main_body').style.backgroundColor = '#FFFFFF';
    //서버에서 이용권 목록 가져오기
    getVoucherList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ph_mode,ph_idx]);

  return isObjEmpty(varPaymentData)?null:(
    <>
      <Header/>
      <HeadTitle titleString={"이용권 구매"} backString={'/MyPage/PaymentList'}/>
      <div id="content">
        <div className="pd_wrap04 ad_pay_list_wrap" style={{paddingTop:"0px"}}>
          <div className="tb_wrap">
            <div className="center_wrap">
              <div className="buy_dungji_wrap" > 
                <div className="form_wrap">
                  <div className="tit07_1">이용권 종류</div>
                  <div className="pass_type_wrap">
                    <ul>
                      <li className="voucher_items on">
                        <div className="dung_num">{varPaymentData.vo_items}</div>
                        {varPaymentData.vo_discount > 0 && (
                          <div className="perct" style={{ top: "-10px" }}>
                            <span>{varPaymentData.vo_discount + "% 할인"}</span>
                          </div>
                        )}
                        <div className="price_wrap">
                          {varPaymentData.vo_discount > 0 ? (
                            <>
                              <span className="p01 no_price">{addThousandPoint(varPaymentData.vo_price) + " 원"}</span>&nbsp;&nbsp;
                              <span className="p02 now_price">{addThousandPoint(varPaymentData.vo_amount) + " 원"}</span>
                            </>
                          ) : (
                            <span className="p01">{addThousandPoint(varPaymentData.vo_price) + " 원"}</span>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form_wrap">
                  <div className="tit07_1">이용 기간</div>
                  <div className="use_period_wrap" style={{margin:"0px 0px 10px 10px"}}>{varPaymentData.pay_terms}개월</div>
                </div>
                <div className="form_wrap">
                  <div className="tit07_1">결제 금액</div>
                  <div className="buy_con_box">
                    <div className="total_price">
                      <span>부가세포함</span><span className="tt_price">{addThousandPoint(varPaymentData.ph_amount)+" 원"}</span>
                    </div>
                  </div>
                </div>
                <div className="form_wrap">
                  <div className="tit07_1">결제 수단</div>
                  <div className="pay_mtd_wrap" style={{margin:"0px 0px 10px 10px"}}>{varPaymentData.ph_method===0?"무통장입금":"신용카드"}</div>
                </div>
                {varPaymentData.ph_method === 1? null:
                  <div className="form_wrap">
                    <div className="tit07_1">입금 계좌</div>
                    <div className="pay_mtd_wrap" style={{margin:"0px 0px 10px 10px"}}>{varPaymentData.bh_account+' ('+varPaymentData.bl_name+')'}</div>
                    <div className="tit07_1">입금자명</div>
                    <div className="pay_mtd_wrap" style={{margin:"0px 0px 10px 10px"}}>{varPaymentData.ph_sender||""}</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div> 
      </div>
    </>
  );
}
export default BuyVoucher;