/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import KakaoMap from '../../components/include/KakaoMap';
import { isObjEmpty,isNull } from '../../components/common/Common';
//매물 위치 정보 표시
const DoongjiLocationInfo = ({varProductData,onOpenMapLocationHandler}) => {

  return isObjEmpty(varProductData) || varProductData.rp_hidden_address===1?null:(
    <div className="info03">
      <div className="tit07_1">위치</div>
      <div className="con_box">
        <div>{varProductData.rp_address1||""}&nbsp;{varProductData.rp_address2||""}<br/>
          <span className="doro"><span className="bt">도로명 : </span>{varProductData.rp_roadname||""}</span>
        </div>
      </div>
      {/* 지도표시 */}
      {isNull(varProductData.rp_location) && isNull(varProductData.rp_address1)?null:
        <KakaoMap varCenterPoint={varProductData.rp_location||""} varAddress={varProductData.rp_address1||""} onOpenMapLocationHandler={onOpenMapLocationHandler}/>
      }
      <div className="txt_gray txt_dark_gray">*지도를 클릭하시면 확대됩니다.</div>
    </div>
  );
}
export default DoongjiLocationInfo;