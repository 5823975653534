/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams,useNavigate,Link } from "react-router-dom";
import CONFIG from "../../../configs/Configs";
import axios from "axios";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";
import Paging from "../../layout/Paging";

import arrImage from "../../images/arr.png";
import iconhome from "../../images/icon-home.png";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import "../../admin_css/board.css";
import { unescapeHtml,escapeHtml,isNull,isObjEmpty,ChangeDateString } from "../../../components/common/Common";

const AdminBoardDetail = () => {
  const admin_idx = Number(localStorage.getItem('adminIdx'));
  const admin_name = localStorage.getItem("adminName");
  const admin_mode = Number(localStorage.getItem("adminMode")); //1:운영자
  const admin_authority = localStorage.getItem("adminAuthority");

  let { adMode,board_idx } = useParams();
  const navigator = useNavigate();

  //게시판 변수 저장 객체
  const newData = {      
    board_idx: 0,
    board_mem_idx: admin_idx,
    mem_name: admin_name,
    admin_mode:admin_mode,
    admin_authority:admin_authority,
    board_title: "",
    board_comment: "",
    board_rdate: null,
    board_hits: 0
  };

  //게시판정보
  const [varBoardInfo,setBoardInfo] = useState({});
  //글작성자 회원번호
  const [varWriterState,setWriterState] = useState(false);  //true:편집모드/false:보기

  const [varMenuTitle,setMenuTitle] = useState("");
  const [varMenuItem,setMenuItem] = useState("");
  const [varBoardAction,setBoardAction] = useState("");

  //댓글
  const [varReplyList,setReplyList] = useState([]);
  const [varReplyComment,setReplyComment] = useState("");

  //댓글 -- 페이지 조절 변수
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //게시판정보 읽어오기
  const getBoardInformation = async () => {
    var sendData = {adMode:adMode,board_idx: board_idx,pageNum:varPageNum,pageSize:varPageSize};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_admin_board_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(datas.results?.length > 0) {
          let copyData = datas.results[0];
          if(admin_mode===1||admin_idx===copyData.board_mem_idx) {
            setWriterState(true);
          }
          const adminAuthority = copyData.mem_admin===1?"운영자":copyData.mem_admin===2?"부운영자":isNull(copyData.regname)?"지역관리자":copyData.regname;
          copyData.admin_authority = adminAuthority;
          setBoardInfo(copyData);
        } else {
          setBoardInfo(newData);
          if(Number(board_idx)===0) setWriterState(true);
        }
        //댓글
        if(datas.replys?.length > 0) {
          setReplyList(datas.replys);
          setTotalCount(datas.rcount);
        }
      } else {
        setBoardInfo(newData);
        if(Number(board_idx)===0) setWriterState(true);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    let stitle="",smenu="";
    switch(Number(adMode)) {
      default:
      case 1: stitle="공지사항";smenu = "AdminBoardNotice"; break;
      case 2: stitle="뉴스/개발";smenu = "AdminNews"; break;
      case 3: stitle="지역관리자 커뮤니티";smenu = "AdminGuestBoard"; break;
      case 4: stitle="급매물/공동중개";smenu = "AdminSaleDoongji"; break;
      case 5: stitle="매물찾습니다";smenu = "AdminFindDoongji"; break;
    }
    setMenuTitle(stitle);
    setMenuItem(smenu);

    let saction = "저장";
    if(Number(board_idx) === 0) saction = "등록";
    setBoardAction(saction);

    getBoardInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board_idx,adMode]);

  //회원정보편집
  const onBoardInfoHandler = (e) => {
    const {name,value} = e.currentTarget;
    console.log(name);
    if(name === "reply_comment") {
      setReplyComment(escapeHtml(value));
    } else {
      setBoardInfo({...varBoardInfo, [name]:escapeHtml(value)});
    }
  }

  //게시판정보 저장
  const onUpdateBoardHandler = async (e) => {
    if(!window.confirm(`${varMenuTitle}에 ${varBoardAction}하시겠습니까?`)) return false;
    
    const sendData = {adMode:adMode,boardInfo: varBoardInfo};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_admin_board_information`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //목록페이지로 이동
        navigator(`/Admin/AdminBoard/${adMode}`);
      } else {
        alert(datas.message);
      }
    });
  }

  //글삭제
  const onDeleteBoardIndormation = async(e) => {
    if(!window.confirm('이 글을 삭제합니까?')) return false;
    const imode = Number(adMode)+3;
    const sendData = {mode:imode,board_idx: board_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_board_information`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        navigator(`/Admin/AdminBoard/${adMode}`);
      } else {
        alert(datas.message);
      }
    });    
  }

  //댓글
  //댓글목록 읽어오기
  const readReplyList = async () => {
    var sendData = {adMode:adMode,board_idx:board_idx,pageNum:varPageNum,pageSize:varPageSize};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_admin_reply_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(datas.replys?.length > 0) {
          setReplyList(datas.replys);
          if(varPageNum === 1) setTotalCount(datas.rcount);
        }
      } else {
        setBoardInfo(newData);
      }
    });
  }
  //페이지가 바뀌면
  useEffect(() => {
    readReplyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum]);

  const [varCurrentReplyIndex,setCurrentReplyIndex] = useState(0);
  //댓글 신규
  const onNewReplyHandler = (e) => {
    setCurrentReplyIndex(0);
    setReplyComment("");
  }
  //댓글 편집
  const onEditReplyHandler = (e,reply_idx) => {
    setCurrentReplyIndex(reply_idx);
    const replyData = varReplyList.filter(el=>el.reply_idx===reply_idx);
    setReplyComment(unescapeHtml(replyData[0].reply_comment));
  }
  //댓글 삭제
  const onDeleteReplyHandler = async(e,reply_idx) => {
    var sendData = {adMode:adMode,reply_idx:reply_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_admin_reply_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      alert(datas.message);
      if(datas.retvalue === 1) {
        setCurrentReplyIndex(0);
        setReplyComment("");
        readReplyList();
      }
    });
  }
  //댓글 저장
  const onSaveReplyCommentHandler = async(e) => {
    if(varReplyComment === "") {
      alert('등록할 댓글을 먼저 입력하세요.'); return false;
    }
    if(!window.confirm('댓글을 저장합니까?')) return false;
    var sendData = {adMode:adMode,mem_idx:admin_idx,board_idx:board_idx,reply_idx:varCurrentReplyIndex,reply_comment:varReplyComment};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_admin_reply_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      alert(datas.message);
      if(datas.retvalue === 1) {
        setCurrentReplyIndex(0);
        setReplyComment("");
        readReplyList();
      }
    });

    setCurrentReplyIndex(0);
    setReplyComment("");
  }

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'ADMINBOARDS'}/>
      <Menus varMenuType={'ADMINBOARDS'} varMenuItem={varMenuItem}/>
      <div id="contents" className="ad_contents">
        <div className="tit">
          <div className="title">{varWriterState?Number(board_idx)===0?`${varMenuTitle}등록`:`${varMenuTitle}상세`:`${varMenuTitle}열람`}</div>
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arrImage} alt="" />관리자 게시판
            <img src={arrImage} alt="" />{varMenuTitle}
            <img src={arrImage} alt="" />{varWriterState?Number(board_idx)===0?`${varMenuTitle}등록`:`${varMenuTitle}상세`:`${varMenuTitle}열람`}
          </div>
        </div>
        {isObjEmpty(varBoardInfo)?null:
        <div className="content">
          <div className="main-box">
            <div className="btn-right">
              {varWriterState?<button className="btn line black" style={{width:"100px"}} onClick={(e)=>onUpdateBoardHandler(e)}>저장</button>:null}
              {varWriterState && Number(board_idx)>0?<button onClick={(e)=>onDeleteBoardIndormation(e)} style={{width:"100px"}} className="btn line">삭제</button>:null}
              <Link to={`/Admin/AdminBoard/${adMode}`} style={{width:"100px"}} className="btn line">목록</Link>
            </div>
            <table className="write" style={{borderTop:"1px solid #eee"}}>
              <colgroup>
                <col width="20%"></col>
                <col width="30%"></col>
                <col width="20%"></col>
                <col width="30%"></col>
              </colgroup>
              <thead></thead>
              <tbody>
                <tr>
                  <th>제목</th>
                  <td colSpan={3}>
                    {varWriterState || Number(board_idx)===0?
                      <input type="text" name="board_title" value={unescapeHtml(varBoardInfo.board_title)} onChange={(e)=>onBoardInfoHandler(e)}/>
                      :unescapeHtml(varBoardInfo.board_title)
                    }
                  </td>
                </tr>
                <tr>
                  <th>권한</th>
                  <td>{varBoardInfo.admin_authority}</td>
                  <th>작성자</th>
                  <td>{varBoardInfo.mem_name}</td>
                </tr>
                <tr>
                  <th>내용</th>
                  <td colSpan={3}>
                    {varWriterState?
                      <textarea name="board_comment" style={{maxWidth:"100%"}} value={unescapeHtml(varBoardInfo.board_comment)} onChange={(e)=>onBoardInfoHandler(e)}/>
                    :unescapeHtml(varBoardInfo.board_comment)
                    }
                  </td>
                </tr>
                {Number(adMode)===1 || Number(board_idx) === 0 ?null:
                  <tr>
                    <th>댓글</th>
                    <td colSpan={3}>
                      <div className="reply-block">
                        <textarea name="reply_comment" className="reply-comment" value={varReplyComment} onChange={(e)=>onBoardInfoHandler(e)}/>
                        <button className="btn gray reply-button" style={{padding:"10px 0px",height:"60px"}} onClick={e=>onSaveReplyCommentHandler(e)}>
                          {varCurrentReplyIndex===0?"신규등록":"재저장"}
                        </button>
                        <button className="btn gray reply-button" style={{padding:"10px 0px",height:"60px"}} onClick={e=>onNewReplyHandler(e)}>초기화</button>
                      </div>
                      {varReplyList.map((reply,i)=>(
                        <div className="reply-list-block">
                          <div className="reply-list-top">
                            <span style={{marginTop:"10px"}}>{ChangeDateString(reply.reply_rdate)}&nbsp;&nbsp;{reply.mem_nickname}</span>
                            <div>
                            {admin_idx===1||reply.reply_mem_idx===admin_idx?<button onClick={(e)=>onEditReplyHandler(e,reply.reply_idx)}>편집</button>:null}
                            {admin_idx===1||reply.reply_mem_idx===admin_idx?<button onClick={(e)=>onDeleteReplyHandler(e,reply.reply_idx)}>삭제</button>:null}
                            </div>
                          </div>
                          <div className="reply-list-subject">
                            <p>{unescapeHtml(reply.reply_comment)}</p>
                          </div>
                        </div>
                      ))}
                      <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
        }
      </div>
    </>
  );
}
export default AdminBoardDetail;