import React, { useState,useEffect,useRef } from "react"; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";
import { isObjEmpty,remainDays,isNull } from "../../components/common/Common";

import Header from "../../components/include/Header";
import Footer from "../../components/include/Footer";
import ExistPartner from "../../components/popup/ExistPartner";

import jangterIcon0 from "../../images/jangter_icon01.png";
import jangterIcon1 from "../../images/jangter_icon02.png";
import jangterIcon2 from "../../images/jangter_icon03.png";
import jangterIcon3 from "../../images/jangter_icon04.png";
import jangterIcon4 from "../../images/jangter_icon05.png";
import jangterIcon5 from "../../images/jangter_icon06.png";

import jangterIcon7 from "../../images/jangter_icon07.png";

const Jangter = () => {
  const navigator = useNavigate();
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const scrollRef = useRef([]);

  const [varBusinessList,setBusinessList] = useState([]);
  const [varSubBusinessList,setSubBusinessList] = useState([]);
  const iconArray = [jangterIcon0,jangterIcon1,jangterIcon2,jangterIcon3,jangterIcon4,jangterIcon5];
  const [varSerious1List,setSerious1List] = useState([]);
  const [varSerious2List,setSerious2List] = useState([]);

  //비지니스항목 열고 닫기
  const [varBusiness,setBusiness] = useState([]);
  //장터사용유무
  const [varMemoState,setMemoState] = useState(true);

  //선택된 장터번호
  const [varSelectPartnerIndex,setSelectPartnerIndex] = useState(0);

  //장터 분류 정보 호출하기
  const getJangterInformation = async () => {
    const sendData = {mem_idx:mem_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_jangter_business`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1 ) {
        //파트너업종
        setBusinessList(datas.results);   
        let subBizList = [];      
        for(var i=0; i < datas.results?.length; i++) {
          const vb_idx = datas.results[i].vb_idx;
          const bizlists = datas.business.filter(element=>element.vb_idx===vb_idx);
          subBizList.push(bizlists);
        }
        setSubBusinessList(subBizList);  //파트너업종별 세부정보
        //모두의 장터
        setSerious1List(datas.serious1);     
        let subSerList = [];     
        for(var j=0; j < datas.serious1?.length; j++) {
          const vbs_idx = datas.serious1[j].vbs_idx;
          const serlists = datas.serious2.filter(element=>element.vbs_idx===vbs_idx);
          subSerList.push(serlists);
        }
        setSerious2List(subSerList);  //파트너업종별 세부정보

        //사업자이면
        if(mem_mode === 2) {
          //이용권보유현황 확인
          if(datas.accounts?.length === 0) {   //이용권 구매 없으면
            setMemoState(false);
          }
          const accounts = datas.accounts[0];
          //이용권이 종료했으면
          if(accounts.jangter_slots === 0 || remainDays(accounts.jangter_expdate)<0) {
            setMemoState(false);
          }
        }
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    $('.market_main_wrap .top_wrap').addClass('on');
    let businessString = localStorage.getItem("businessOptions");
    //console.log(businessString);
    if(businessString !== undefined && businessString != null && businessString !== "") {
      let businessArray = businessString.split(",");
      setBusiness(businessArray);
    }
    getJangterInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ResetScrollTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSerious2List]);

  //선택되었던 매물로 이동
  const ResetScrollTop = () => {
    const scrollIndex = localStorage.getItem('scrollIndex');
    if(!isNull(scrollIndex) && scrollIndex !== -1){
      const inum  = localStorage.getItem('scrollIndex');
      const iTop  = localStorage.getItem('scrollTop');
      const saleBlock = scrollRef.current[inum];
      if(!isNull(saleBlock)) { 
        setTimeout(()=>window.scroll({top:iTop,left:0}),10);
        localStorage.setItem('scrollIndex',-1);
      }
    }
  }

  //항목 열기/닫기
  const OpenCloseBelowHandler = (e,i) => {
    const thisItem = e.currentTarget;
    const thisParent = thisItem.parentNode.parentNode;
    //console.log(thisParent);
    let copyData = [...varBusiness];
    if(thisParent.classList.contains("on")) {
      thisParent.classList.remove("on");
      copyData[i] = "1";
    } else {
      thisParent.classList.add("on");
      copyData[i] = "0";
    }
    const businessString = copyData.join();
    //console.log(businessString);
    setBusiness(copyData);
    localStorage.setItem("businessOptions",businessString);
  }

  //파트너스회원유무 확인
  const [varPositionIndex,setPositionInex] = useState(0);
  const onLinkPartnerPageHandler = (e,inum,vbd_idx) => {
    setPositionInex(inum);
    if(mem_mode === 2 && !varMemoState) {
      setSelectPartnerIndex(vbd_idx);
      setExistPartner(true);
      onPopupWinHandler(true);
    } else {
      localStorage.setItem('scrollIndex',inum);
      localStorage.setItem('scrollTop',window.scrollY);
      console.log(inum,window.scrollY);
      navigator(`/Jangter/PartnerList/${vbd_idx}`);
    }
  }
  //장터목록으로 
  const onLinkJangterPageHandler = (e,inum,vbi_idx) => {
    setPositionInex(inum);
    localStorage.setItem('scrollIndex',inum);
    localStorage.setItem('scrollTop',window.scrollY);
    navigator(`/Jangter/JangterList/${vbi_idx}`);
  }

  //팝업창 뒤의 전체 화면 죽이기
  const onPopupWinHandler = (bmode) => {
    //console.log(bmode);
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }

  //파트너스 등록유무
  const [varExistPartner,setExistPartner] = useState(false);
  const onOpenExistPartnerHandler = (e,ino) => {
    setExistPartner(false);
    onPopupWinHandler(false);
    localStorage.setItem('scrollIndex',varPositionIndex);
    localStorage.setItem('scrollTop',window.scrollY);
    if(ino === 1) navigator(`/Jangter/PartnerList/${varSelectPartnerIndex}`);
    else if(ino === 2) navigator(`/Jangter/RegisterPartner`);
  }

  return isObjEmpty(varBusinessList)?null:(
    <>
      <Header/>
      {/* <HeadTitle titleString={"내 장터 관리"}/> */}
      <div id="content" style={{paddingBottom:"40px"}}>
        {/* 배경죽이는 항목 */}
        {/* <div className="layer-bg"></div>  */} 
        <div className="pd_wrap02">
          <div className="market_main_wrap">
            {varBusinessList.map((biz1,i)=>(
              <div className="mk_menu_wrap b_shadow" key={'biz1_'+i}  ref={e=>(scrollRef.current[i]=e)}>
                <div className={Number(varBusiness[i])===1?"top_wrap":"top_wrap on"}>
                  <div className="center_wrap">
                    <div className="tit03"><img src={iconArray[i]} alt=""/>{biz1.vb_name}
                      <span className="gray_txt"><span className="gray_bar">&nbsp;</span>해결해드릴게요</span>
                    </div>
                    <div className="right_arrow rote180" onClick={(e)=>OpenCloseBelowHandler(e,i)}>&nbsp;</div>
                  </div>
                  <div className="menu_area">
                    <div className="center_wrap">
                      <ul className="dan4">
                        {varSubBusinessList[i].map((biz2,j)=>(
                          <li key={'biz2_'+j} onClick={e=>onLinkPartnerPageHandler(e,i,biz2.vbd_idx)}>{biz2.vbd_name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="mk_menu_wrap b_shadow menu_area_last" ref={e=>(scrollRef.current[varBusinessList.length]=e)}>
              <div className="top_wrap">
                <div className="center_wrap">
                  <div className="tit03"><img src={jangterIcon7} alt=""/>모두의 장터</div>
                </div>
              </div>  
              {varSerious1List.map((serious1,i)=>(
                <div className="menu_area" key={'serious1_'+i}>
                  <div className="center_wrap">
                    <div className="tit06">{serious1.vbs_name}</div>
                    <ul className="dan4"> 
                      {varSerious2List[i].map((serious2,j)=>(
                        <li key={'serious12_'+j} onClick={e=>onLinkJangterPageHandler(e,varBusinessList.length,serious2.vbi_idx)}>{serious2.vbi_name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}          
            </div>
          </div>  
        </div>
        {/* 파트너스 등록--------- */}  
        <ExistPartner varExistPartner={varExistPartner} onOpenExistPartnerHandler={onOpenExistPartnerHandler}/>     
      </div>
      <Footer varPageMode={2} />
    </>
  );
}
export default Jangter;