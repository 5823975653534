/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import { getYear, getMonth } from "date-fns"; // getYear, getMonth
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko'; // 한국어적용

import { isNull,ChangeSearchingDateFormat, isObjEmpty,DisplayNumber } from "../common/Common";
//import calculateIcon from "../../images/icon_cal.png";

registerLocale("ko", ko);
const _ = require('lodash');

const DetailBuilding = ({varProductData,setProductData,varStructureList,varCoolHeatList,varDirectionList,varLocationList,onChangeInputHandler,onChangeSelectHandler}) => {

  const [varEnterDate,setEnterDate] = useState(null);
  const [varUsedAppDate,setUsedAppDate] = useState(null);

  const years = _.range(1970, getYear(new Date()) + 1, 1); // 수정 
  const months = ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월']; 
  
  //페이지 정보 표시 - 최초
  useEffect(() => {
    console.log(varProductData,isObjEmpty(varProductData));
    if(!isNull(varProductData) && isObjEmpty(varProductData) === false) {
      //console.log(varProductData.rp_can_enter_date,varProductData.rp_used_app_date);
      if(varProductData.rp_can_enter_date !== null && varProductData.rp_can_enter_date !== "")
        setEnterDate(new Date(varProductData.rp_can_enter_date));
      if(varProductData.rp_used_app_date !== null && varProductData.rp_used_app_date !== "")
        setUsedAppDate(new Date(varProductData.rp_used_app_date));
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varProductData.rp_can_enter_date,varProductData.rp_used_app_date]);

  //검색기간 설정값 저장
  const onChangeDateHandler = (ino,date) => {
    if(date === "" ) date = null;
    let sname = "";
    if(ino === 1) {
      setEnterDate(date);
      sname = 'rp_can_enter_date';
    } else {
      setUsedAppDate(date);
      sname = 'rp_used_app_date';
    }
    console.log(ino, date)
    setProductData({...varProductData,[sname]:ChangeSearchingDateFormat(date)});
  }

  const onChangeRadioInputHandler = (e) => {
  const { name, value } = e.currentTarget;
  
  // 상태를 업데이트하면서 기존 상태를 병합
  setProductData(prevState => ({
    ...prevState,
    [name]: Number(value)
  }));

  // if (name === "rp_can_enter") {
  //   const enterDateBlock = document.getElementById('pick_enter_date');
  //   if (Number(value) === 0) {
  //     enterDateBlock.style.display = "none";
  //   } else {
  //     enterDateBlock.style.display = "";
  //   }
  // }
};

  return isObjEmpty(varProductData) ? null : (
    <>
      <div className="tit07_1">매물 정보</div>
        {/* 면적조건 */}
        {varProductData.rp_serious===9?
          <div className="form_wrap2">
            <div className="tit07_2">
              대지면적<span className="star_red">(필수)</span>
            </div>
            <div className="one-line">
              <input type="text" name="rp_sale_area" className="one-line-num" placeholder="0" value={DisplayNumber(varProductData.rp_sale_area)} onChange={e=>onChangeInputHandler(e)}/>
              <span className="danwe_txt2">㎡</span>
            </div>
          </div>
        :
          <>
            <div className="form_wrap2">
              <div className="tit07_2">
              {varProductData.rp_serious === 4 || varProductData.rp_serious === 5 || varProductData.rp_serious === 8 || varProductData.rp_serious === 10 ? "대지면적" : "공급면적"}

              </div>
              <div className="one-line">
                <input type="text" name="rp_sale_area" className="one-line-num" placeholder="0" value={DisplayNumber(varProductData.rp_sale_area)} onChange={e=>onChangeInputHandler(e)}/>
                <span className="danwe_txt2">㎡</span>
              </div>
            </div>
            <div className="form_wrap2">
              <div className="tit07_2">
                {varProductData.rp_serious === 4 || varProductData.rp_serious === 5 || varProductData.rp_serious === 8 || varProductData.rp_serious === 10 ? <span>연면적<span className="star_red">(필수)</span></span> : <span>전용면적 <span className="star_red">(필수)</span></span>}
              </div>
              <div className="one-line">
                <input type="text" name="rp_private_area" className="one-line-num" placeholder="0" value={DisplayNumber(varProductData.rp_private_area)} onChange={e=>onChangeInputHandler(e)}/>
                <span className="danwe_txt2">㎡</span>
              </div>
            </div>
          </>
        }
      {/* 건물위치=빌딩/방 제외=>토지,공장 */}
      {varProductData.rp_serious===9?null
      :varProductData.rp_serious===10?  /* 공장 */
        <>
          <div className="form_wrap2">
            <div className="tit07_2">단독사용</div>
            <div className="one-line line-left-row">
              <div className="terms_chk35" style={{marginRight:"10px"}}>
                <input type="radio" name="rp_use_alone" className="chk1" id="rp_use_alone1" value="1"
                      defaultChecked={varProductData.rp_use_alone===1} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_use_alone1">가능</label>
              </div>
              <div className="terms_chk35">
                <input type="radio" name="rp_use_alone" className="chk1" id="rp_use_alone0" value="0"
                      defaultChecked={varProductData.rp_use_alone===0} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_use_alone0">불가능</label>
              </div>
            </div>
          </div>
          <div className="form_wrap2">
            <div className="tit07_2">전기전력</div>
            <div className="one-line">
              <input type="text" name="rp_electronic" className="one-line-kw" placeholder="0" value={DisplayNumber(varProductData.rp_electronic)} onChange={e=>onChangeInputHandler(e)}/>
              <span className="danwe_txt">kW</span>
            </div>
          </div>
          <div className="form_wrap2">
            <div className="tit07_2">층고</div>
            <div className="one-line">
              <input type="text" name="rp_floor_height" className="one-line-num" placeholder="0" value={DisplayNumber(varProductData.rp_floor_height)} onChange={e=>onChangeInputHandler(e)}/>
              <span className="danwe_txt">m</span>
            </div>
          </div>
        </>
      :varProductData.rp_serious===8? /* 빌딩 */
        <div className="form_wrap2">
          <div className="tit07_2">건물위치<span className="star_red"> (필수)</span></div>
          <select name="rp_bd_location" className="one-line" value={varProductData.rp_bd_location} onChange={e=>onChangeInputHandler(e)}>
            <option value={0}>선택</option>
            {varLocationList.map((locations,i)=>(
              <option key={'locations_'+i} value={locations.vbl_idx}>{locations.vbl_name}</option>
            ))}
          </select>
        </div>
      :
        <div className="form_wrap2">
          <div className="tit07_2">{varProductData.rp_serious===6 || varProductData.rp_serious===7?"룸":"방"}<span className="star_red"> (필수)</span></div>
          <div className="one-line">
            <input type="text" name="rp_rooms" className="one-line-num" placeholder="0" value={DisplayNumber(varProductData.rp_rooms)} onChange={e=>onChangeInputHandler(e)}/>
            <span className="danwe_txt2">개</span>
          </div>
        </div>
      }
      {/* 화장실 */}
      {varProductData.rp_serious===9?null
      :varProductData.rp_serious<6?
        <div className="form_wrap2">
          <div className="tit07_2">화장실<span className="star_red"> (필수)</span></div>
          <div className="one-line">
            <input type="text" name="rp_baths" className="one-line-num" placeholder="0" value={DisplayNumber(varProductData.rp_baths)} onChange={e=>onChangeInputHandler(e)}/>
            <span className="danwe_txt2">개</span>
          </div>
        </div>
      :
        <div className="form_wrap2">
          <div className="tit07_2">화장실<span className="star_red"> (필수)</span></div>
          <div className="one-line line-row">
            <div className="two-line1">
              <input type="text" name="rp_baths" className="one-line-num" placeholder="0" value={DisplayNumber(varProductData.rp_baths)} onChange={e=>onChangeInputHandler(e)}/>
              <span className="danwe_txt2">개</span>
            </div>
            <div className="two-line2">
              <li className="terms_chk35" style={{marginRight:"5px"}}>
                <input type="checkbox" name="rp_bath_side" className="chk1" id="rp_bath_side1" value={1}
                        checked={varProductData.rp_bath_side===1||varProductData.rp_bath_side===3} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_bath_side1">내부</label>
              </li>
              <li className="terms_chk35">
                <input type="checkbox" name="rp_bath_side" className="chk1" id="rp_bath_side2" value={2}
                        checked={varProductData.rp_bath_side===2||varProductData.rp_bath_side===3} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_bath_side2">외부</label>
              </li>
            </div>
          </div>
        </div>
      }
      {varProductData.rp_serious>5?null:
        <div className="form_wrap2">
          <div className="tit07_2">내부 구조<span className="star_red"> (필수)</span></div>
          <select name="rp_structure" className="one-line" value={varProductData.rp_structure} onChange={e=>onChangeSelectHandler(e)}>
            <option value={0}>선택</option>
            {varStructureList.map((structure,i)=>(
              <option key={'structure_'+i} value={structure.vfo_idx}>{structure.vfo_name}</option>
            ))}
          </select>
        </div>
      }
      {/* 토지제외 */}
      {varProductData.rp_serious===9?null:
        <>
          <div className="form_wrap2">
            <div className="tit07_2">냉/난방<span className="star_red"> (필수)</span></div>
            <select name="rp_cool_heat" className="one-line" value={varProductData.rp_cool_heat} onChange={e=>onChangeSelectHandler(e)}>
              <option value={0}>선택</option>
              {varCoolHeatList.map((coolheat,i)=>(
                <option key={'coolheat_'+i} value={coolheat.vfo_idx}>{coolheat.vfo_name}</option>
              ))}
            </select>
          </div>
          <div className="form_wrap2" style={{marginBottom:"5px"}}>
            <div className="tit07_2">해당층<span className="star_red"> (필수)</span></div>
            <div className="one-line">
              <div className="line-row">
                <li className="terms_chk35" style={{marginTop:"10px"}}>
                  <input name="rp_exist_floor_type" className="chk1" id="rp_exist_floor_type0" type="radio" value="0" 
                            defaultChecked={varProductData.rp_exist_floor_type===0} onChange={e=>onChangeInputHandler(e)}/>
                  <i></i><label htmlFor="rp_exist_floor_type0">&nbsp;</label>
                </li>
                <input type="text" name="rp_exist_floor" className="one-line-num" id="rp_exist_floor" placeholder="0" value={DisplayNumber(varProductData.rp_exist_floor)} onChange={e=>onChangeInputHandler(e)}/>
                <span className="danwe_txt2">층</span>
              </div>
            </div>
          </div>
          <div className="form_wrap3">
            <ul className="one-line" >
              <li className="terms_chk35">
                <input type="radio" name="rp_exist_floor_type" className="chk1" id="rp_exist_floor_type1" value="1"
                        defaultChecked={varProductData.rp_exist_floor_type===1} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_exist_floor_type1">건물전체</label>
              </li>
              <li className="terms_chk35">
                <input type="radio" name="rp_exist_floor_type" className="chk1" id="rp_exist_floor_type2" value="2"
                        defaultChecked={varProductData.rp_exist_floor_type===2} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_exist_floor_type2">지하</label>
              </li>
              <li className="terms_chk35">
                <input type="radio" name="rp_exist_floor_type" className="chk1" id="rp_exist_floor_type3" value="3"
                        defaultChecked={varProductData.rp_exist_floor_type===3} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_exist_floor_type3">반지하</label>
              </li>
              <li className="terms_chk35">
                <input type="radio" name="rp_exist_floor_type" className="chk1" id="rp_exist_floor_type4" value="4"
                        defaultChecked={varProductData.rp_exist_floor_type===4} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_exist_floor_type4">옥탑</label>
              </li>
            </ul>
          </div>
          <div className="form_wrap2" style={{marginBottom:"0px"}}>
            <div className="tit07_2">총층수<span className="star_red"> (필수)</span></div>
            <div className="one-line">
              <input type="text" name="rp_total_floor" className="one-line-num" placeholder="0" value={DisplayNumber(varProductData.rp_total_floor)} onChange={e=>onChangeInputHandler(e)}/>
              <span className="danwe_txt">층</span>
            </div>
          </div>
          <div className="form_wrap2" style={{marginBottom:"0px"}}>
            <div className="tit07_2">주차<span className="star_red"> (필수)</span></div>
            <div className="one-line line-left-row">
              <li className="terms_chk35" style={{marginRight:'20px'}}>
                <input type="radio" name="rp_can_parking" className="chk1" id="rp_can_parking1" value="1"
                        defaultChecked={varProductData.rp_can_parking===1} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_can_parking1">가능</label>
              </li>
              <li className="terms_chk35">
                <input type="radio" name="rp_can_parking" className="chk1" id="rp_can_parking0" value="0"
                      defaultChecked={varProductData.rp_can_parking===0} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_can_parking0">불가능</label>
              </li>
            </div>
          </div>
          <div className="form_wrap">
            <div className="dan2_danwe" style={{marginLeft:"30px"}}>
              <div className="has_btn has_danwe has_5_txt saedae">
                <span className="danwe_txt5">세대당주차</span>
                <input type="text" name="rp_exist_parking" className={varProductData.rp_can_parking===0?"disable":""} id="rp_exist_parking" placeholder="0" value={DisplayNumber(varProductData.rp_exist_parking)} onChange={e=>onChangeInputHandler(e)}/>
                <span className="danwe_txt">대</span>
              </div>
              <div className="mid_bar02"> / </div>
              <div className="has_btn has_danwe has_5_txt">
                <span className="danwe_txt5">총주차</span>
                <input type="text" name="rp_total_parking" className={varProductData.rp_can_parking===0?"disable":""} id="rp_total_parking" placeholder="0" value={DisplayNumber(varProductData.rp_total_parking)} onChange={e=>onChangeInputHandler(e)}/>
                <span className="danwe_txt">대</span>
              </div>
            </div>
          </div>
        </>
      }
      {/* 토지/주택류 제외 */}
      {varProductData.rp_serious===9 || varProductData.rp_serious < 6?null:
        <div className="form_wrap2">
          <div className="tit07_2">테라스(현관기준)</div>
          <div className="one-line line-left-row">
            <div className="terms_chk35" style={{marginRight:"20px"}}>
              <input type="radio" name="rp_terrace" className="chk1" id="rp_terrace1" value="1" 
                      defaultChecked={varProductData.rp_terrace===1} onChange={e=>onChangeInputHandler(e)}/>
              <i></i><label htmlFor="rp_terrace1">있음</label>
            </div>
            <div className="terms_chk35">
              <input type="radio" name="rp_terrace" className="chk1" id="rp_terrace0" value="0"
                      defaultChecked={varProductData.rp_terrace===0} onChange={e=>onChangeInputHandler(e)}/>
              <i></i><label htmlFor="rp_terrace0">없음</label>
            </div>
          </div>
        </div>
      }
      {/* 토지제외 */}
      {varProductData.rp_serious===9?null:
        <>
          <div className="form_wrap2">
            <div className="tit07_2">방향(현관기준)<span className="star_red"> (필수)</span></div>
            <select name="rp_direction" className="one-line" value={varProductData.rp_direction} onChange={e=>onChangeSelectHandler(e)}>
              <option value={0}>선택</option>
              {varDirectionList.map((directs,i)=>(
                <option key={'directs_'+i} value={directs.vfo_idx}>{directs.vfo_name}</option>
              ))}
            </select>
          </div>
          <div className="form_wrap2">
            <div className="tit07_2">엘리베이터<span className="star_red"> (필수)</span></div>
            <div className="one-line line-left-row">
              <li className="terms_chk35" style={{marginRight:"20px"}}>
                <input type="radio" name="rp_elevator" className="chk1" id="rp_elevator1" value="1" 
                        defaultChecked={varProductData.rp_elevator===1} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_elevator1">있음</label>
              </li>
              <li className="terms_chk35">
                <input type="radio" name="rp_elevator" className="chk1" id="rp_elevator0" value="0"
                        defaultChecked={varProductData.rp_elevator===0} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_elevator0">없음</label>
              </li>
            </div>
          </div>
        </>
      }
      <div className="form_wrap2">
        <div className="tit07_2">입주가능일<span className="star_red"> (필수)</span></div>
        <div className="one-line">
          <div className="line-left-row">
            <li className="terms_chk35" style={{ marginRight: "20px" }}>
              <input
                type="radio"
                name="rp_can_enter"
                className="chk1"
                id="rp_can_enter0"
                value="0"
                checked={varProductData.rp_can_enter === 0}
                onChange={onChangeRadioInputHandler}
              />
              <i></i>
              <label htmlFor="rp_can_enter0">즉시입주</label>
            </li>
            <li className="terms_chk35">
              <input
                type="radio"
                name="rp_can_enter"
                className="chk1"
                id="rp_can_enter1"
                value="1"
                checked={varProductData.rp_can_enter === 1}
                onChange={onChangeRadioInputHandler}
              />
              <i></i>
              <label htmlFor="rp_can_enter1">협의</label>
            </li>
          </div>
          {varProductData.rp_can_enter === 1 && (
            <div className="line-row" id="pick_enter_date">
              <div className="two-date">
                {/* <DatePicker
                  locale={ko}
                  name="rp_can_enter_date"
                  id="rp_can_enter_date"
                  className="datepicker"
                  dateFormat="yyyy.MM.dd"
                  selected={varEnterDate}
                  onChange={(date) => onChangeDateHandler(1, date)}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                /> */}
                <DatePicker
                renderCustomHeader={({ date,changeYear,changeMonth,decreaseMonth,increaseMonth,prevMonthButtonDisabled,nextMonthButtonDisabled }) => (
                  <div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                      <select className="datepicker-year" value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                          {years.map(option => ( <option key={option} value={option}> {option} </option> ))}
                      </select>
                      <select className="datepicker-year" value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value)) } >
                          {months.map(option => ( <option key={option} value={option}> {option} </option> ))}
                      </select>
                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                  </div>
              )}
              locale={ko} name="rp_can_enter_date" className="datepicker" dateFormat="yyyy.MM.dd" selected={varEnterDate} onChange={(date)=>onChangeDateHandler(1,date)} />
              </div>
              <select
                name="rp_can_enter_state"
                className="two-select"
                id="rp_can_enter_state"
                value={varProductData.rp_can_enter_state}
                onChange={onChangeInputHandler}
              >
                <option value={0}>이전</option>
                <option value={1}>이후</option>
              </select>
            </div>
          )}
        </div>
      </div>
      {/* 토지제외 */}
      {varProductData.rp_serious===9?null:
        <div className="form_wrap2">
          <div className="tit07_2">건축물사용승인일<span className="star_red"> (필수)</span></div>
          <div className="one-line" style={{width:"160px"}}>
            <DatePicker
                renderCustomHeader={({ date,changeYear,changeMonth,decreaseMonth,increaseMonth,prevMonthButtonDisabled,nextMonthButtonDisabled }) => (
                  <div style={{ margin: 10, display: "flex", justifyContent: "center" }} >
                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                      <select className="datepicker-year" value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} >
                          {years.map(option => ( <option key={option} value={option}> {option} </option> ))}
                      </select>
                      <select className="datepicker-year" value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value)) } >
                          {months.map(option => ( <option key={option} value={option}> {option} </option> ))}
                      </select>
                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                  </div>
              )}
              locale={ko} name="rp_used_app_date" className="datepicker" dateFormat="yyyy.MM.dd" selected={varUsedAppDate} onChange={(date)=>onChangeDateHandler(2,date)} />
          </div>
        </div>
      }
    </>
  );
}
export default DetailBuilding;