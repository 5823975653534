import React, { useState, useEffect } from "react"; 
import CONFIG from '../../configs/Configs';
import { GetPhotoImage } from "../common/Common";
import $ from "jquery";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

import SlidingZoomPhotos from '../popup/SlidingZoomPhotos';

import noSlideImage from "../../images/no_img.png";

const JangterSlider = ({ varJangterData, varJangterPhoto }) => {
  const [varPhotoImages, setPhotoImages] = useState([]);

  // 이미지 로딩 및 재시도 함수
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // 캐시 방지용 타임스탬프 추가
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            // console.warn(`Retrying to load image: ${src} (${retries} retries left)`);
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  // 이미지 로딩 처리
  useEffect(() => {
    if (varJangterPhoto?.length > 0) {
      const initialPhotoImages = varJangterPhoto.map((photo) => ({
        file_name: photo.file_name,
        src: null,
        loading: true,
      }));
      setPhotoImages(initialPhotoImages);

      varJangterPhoto.forEach((photo, index) => {
        const sfile = photo.file_name;
        const simage = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(sfile, 'jangter') + `?t=${new Date().getTime()}`;

        loadImageWithRetry(simage)
          .then((loadedImage) => {
            // console.log('Image loaded successfully:', loadedImage);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                src: loadedImage,
                loading: false,
              };
              return newImages;
            });
          })
          .catch((error) => {
            console.error(error.message);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                loading: false,
              };
              return newImages;
            });
          });
      });
    } else {
      setPhotoImages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varJangterPhoto]);

  // 매물사진 확대하기
  const [varOpenPhoto, setOpenPhoto] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0); // 현재 선택된 이미지 인덱스

  const onShowBigSizePhotoHandler = (e, index) => {
    e.preventDefault();
    setCurrentPhotoIndex(index); // 현재 이미지 인덱스 설정
    setOpenPhoto(true);
    onPopupBackWin(true);
  };

  const onOpenPhotoHandler = (bmode) => {
    setOpenPhoto(bmode);
    onPopupBackWin(bmode);
  };

  const onPopupBackWin = (bmode) => {
    if (bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  };

  return (
    <>
      <div className="swiper-container photo_slide" style={{ maxHeight: "430px", height: "400px" }}>
        {/* 매물사진슬라이딩 */}
        {varPhotoImages?.length === 0 ? (
          <div className="c_wrap">
            <div className="img_wrap3">
              <img src={noSlideImage} alt="" className="only-one-image" />
            </div>
          </div>
        ) : varPhotoImages.length === 1 ? (
          <div className="c_wrap">
            <div
              className="img_wrap3"
              style={{ position: 'relative', height: '400px' }}
              onClick={(e) => onShowBigSizePhotoHandler(e, 0)}
            >
              {varPhotoImages[0]?.loading ? (
                <div className="loading-indicator">
                  {/* 로딩 스피너 */}
                  <div className="loader"></div>
                </div>
              ) : (
                <>
                  <img
                    src={varPhotoImages[0]?.src}
                    alt=""
                    className="only-one-image"
                    style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  />
                  {/* 워터마크를 추가하려면 아래 주석을 해제하세요 */}
                  {/* <img
                    src={`${CONFIG.SERVER_HOST}/water_m.png`}
                    alt="Watermark"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '95px',
                      height: 'auto',
                      objectFit: 'cover',
                      pointerEvents: 'none',
                    }}
                  /> */}
                </>
              )}
            </div>
          </div>
        ) : (
          <Swiper pagination={{ clickable: true }} loop={true} modules={[Pagination]}>
            {varPhotoImages.map((photoObj, i) => (
              <SwiperSlide className="swiper-slide" key={'photo_' + i}>
                <div className="c_wrap">
                  <div
                    className="img_wrap3"
                    style={{ position: 'relative', height: '400px' }}
                    onClick={(e) => onShowBigSizePhotoHandler(e, i)}
                  >
                    {photoObj.loading ? (
                      <div className="loading-indicator">
                        {/* 로딩 스피너 */}
                        <div className="loader"></div>
                      </div>
                    ) : (
                      <>
                        <img
                          src={photoObj.src}
                          alt=""
                          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                        />
                        {/* 워터마크를 추가하려면 아래 주석을 해제하세요 */}
                        {/* <img
                          src={`${CONFIG.SERVER_HOST}/water_m.png`}
                          alt="Watermark"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '95px',
                            height: 'auto',
                            objectFit: 'cover',
                            pointerEvents: 'none',
                          }}
                        /> */}
                      </>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {/* 매물유형표시 */}
        <div className="flag_wrap2">
          <span className="flag_text">{varJangterData.vbi_name}</span>
        </div>
      </div>

      {/* 이미지확대 팝업창 */}
      <SlidingZoomPhotos
        varPhotoImages={varPhotoImages}
        varOpenPhoto={varOpenPhoto}
        onOpenPhotoHandler={onOpenPhotoHandler}
        currentPhotoIndex={currentPhotoIndex}
      />
    </>
  );
};

export default JangterSlider;
