import React, { useState,useEffect } from "react";
import DatePicker from "react-datepicker";
import { ko } from 'date-fns/esm/locale';
import "react-datepicker/dist/react-datepicker.css";

import { ChangeSearchingDateFormat,isObjEmpty } from "../../../components/common/Common";

function PaymentSearch({varTotalCount, varSearchData, setSearchData}) {
  //항목별 검색변수값 설정
  const [varStartDate, setStartDate] = useState(null);
  const [varEndDate, setEndDate] = useState(null);
  const [varSearchProgress, setSearchProgress] = useState(0);
  const [varSearchOption, setSearchOption] = useState('all');
  const [varSearchString,setSearchString] = useState('');

  useEffect(() => {
    if(!isObjEmpty(varSearchData)) {
      setStartDate(varSearchData.sdate===null?null:new Date(varSearchData.sdate));
      setEndDate(varSearchData.edate===null?null:new Date(varSearchData.edate));
      setSearchProgress(varSearchData.searchProgress);
      setSearchOption(varSearchData.searchOption);
      setSearchString(varSearchData.searchString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSearchData]);

  //진행단계 선택값 저장
  const onSearchProgressHandler = (e) => {   
    setSearchProgress(Number(e.target.value));
  }

  //검색항목 선택값 저장
  const onSearchOptionHandler = (e) => {   
    setSearchOption(e.target.value);
  }
  //검색문자 저장
  const onSearchStringHandler = (e) => {   
    setSearchString(e.target.value);
  }
  //검색시작
  const onExecuteSearchingHandler = (e) => {
    e.preventDefault();
    setSearchData({
        sdate:ChangeSearchingDateFormat(varStartDate),
        edate:ChangeSearchingDateFormat(varEndDate),
        searchProgress:varSearchProgress,
        searchOption:varSearchOption,
        searchString:varSearchString
    });
  }
  //검색기간 설정값 저장
  const onChangeDateHandler = (ino,date) => {
    if(date === "" ) date = null;
    if(ino === 0) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  }

  return (
    <div className="main-box">
      <div className="search-area">
        <div className="search-box">
          <p>검색건수 : {varTotalCount} 건</p>&nbsp;&nbsp;
          {/*회원등록일자*/}
          <div className="datepicker_area">
            <p className="Title">결제일자</p>
            <DatePicker locale={ko} name="sdate" className="datepicker" dateFormat="yyyy.MM.dd" selected={varStartDate} selectStart onChange={(date)=>onChangeDateHandler(0,date)} />
            ~
            <DatePicker locale={ko} name="edate" className="datepicker" dateFormat="yyyy.MM.dd" selected={varEndDate} selectEnd minDate={varStartDate} onChange={(date)=>onChangeDateHandler(1,date)} />   
          </div>
        </div>
        {/*검색항목*/}
        <div className="search-string" style={{width:"550px"}}>
          <label style={{width:"80px",textAlign:"right",paddingRight:"10px"}}>진행상태</label>
          <select value={varSearchProgress} style={{width:"120px"}} onChange={(e)=>onSearchProgressHandler(e)}>
              <option value={0}>전체</option>
              <option value={1}>신청중</option>
              <option value={2}>승인/결제대기</option>
              <option value={3}>결제완료</option>
              <option value={4}>결제취소</option>
              <option value={5}>환불요청</option>
              <option value={6}>환불완료</option>
              <option value={7}>환불실패</option>
          </select>
          <select value={varSearchOption} style={{width:"90px"}} onChange={(e)=>onSearchOptionHandler(e)}>
              <option value={"all"}>전체</option>
              <option value={"mcm_name"}>구매자명</option>
              <option value={"ph_sender"}>입금자명</option>
          </select>
          <input type="text" value={varSearchString} style={{width:"180px"}} onChange={(e)=>onSearchStringHandler(e)} placeholder="검색어를 입력해주세요"/>
          <button onClick={(e)=>onExecuteSearchingHandler(e)} className="btn">조회</button>
        </div>
      </div>
    </div>
  );
}

export default PaymentSearch;