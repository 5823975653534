import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { ChangeMobileFormat, GetPhotoImage } from "../common/Common";

import heartOffIcon from "../../images/icon_heart_g.png";
import heartOnIcon from "../../images/icon_heart_brown.png";
import callIcon from "../../images/icon_call03.png";
import mapIcon from "../../images/icon_map2.png";

const PremiumPartnerList = ({ varBusiness, varSearchRegionList }) => {
  const mem_idx = localStorage.getItem("mem_idx");
  const memberLoginState = Number(localStorage.getItem("memberLoginState"));
  const navigator = useNavigate();

  // 검색된 프리미엄 매물목록 관련
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPremiumList, setPremiumList] = useState([]);

  // 이미지 로딩 상태 관리
  const [varPremiumImages, setPremiumImages] = useState([]);

  // 이미지 로딩 및 재시도 함수
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // 캐시 방지용 타임스탬프 추가
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  // 프리미엄매물 검색후 목록 가져오기
  const getPartnerPremiumList = async () => {
    const sendData = { vbd_name: varBusiness, searchRegion: varSearchRegionList, mem_idx: mem_idx };
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_partner_premium_list`, sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if (datas.retvalue === 1) {
        // 검색된 전체수량
        if (datas.totalcount > 0) {
          setTotalCount(datas.totalcount);
          // 검색된 목록
          setPremiumList(datas.results);
        }
      }
    });
  }

  // 페이지 정보 표시 - 최초
  useEffect(() => {
    if (varBusiness !== "") getPartnerPremiumList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varBusiness]);

  // 이미지 로딩
  useEffect(() => {
    if (varPremiumList.length > 0) {
      const initialPremiumImages = varPremiumList.map((partner) => ({
        jp_idx: partner.jp_idx,
        src: null,
        loading: true,
      }));
      setPremiumImages(initialPremiumImages);

      varPremiumList.forEach((partner, index) => {
        const sfile = partner.mcm_file;
        const simage =
          `${CONFIG.SERVER_HOST}/` +
          GetPhotoImage(sfile, 'member') +
          `?t=${new Date().getTime()}`;
        loadImageWithRetry(simage)
          .then((loadedImage) => {
            setPremiumImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                src: loadedImage,
                loading: false,
              };
              return newImages;
            });
          })
          .catch((error) => {
            console.error(error.message);
            setPremiumImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                loading: false,
              };
              return newImages;
            });
          });
      });
    } else {
      setPremiumImages([]);
    }
  }, [varPremiumList]);

  // 프리미엄매물을 찜목록으로 등록/해제
  const onChangeJjimListHandler = async (e, jp_idx) => {
    if (memberLoginState === 0) {
      navigator('/Login');
      return false;
    }
    e.preventDefault();
    let copyList = [...varPremiumList];
    const findIndex = copyList.findIndex(element => element.jp_idx === jp_idx);

    let jj_idx = copyList[findIndex].jj_idx;
    let jj_status = copyList[findIndex].jj_status;

    if (jj_idx === null) {
      jj_idx = 0;
      jj_status = 0;
    }
    if (jj_status === 1) jj_status = 0;
    else jj_status = 1;

    const sendData = { pageMode: 3, opp_idx: jp_idx, jj_idx: jj_idx, jj_mem_idx: mem_idx, jj_status: jj_status };
    axios.post(`${CONFIG.SERVER_HOST}/_update_jjim_list`, sendData).then((res) => {
      const datas = res.data;
      copyList[findIndex].jj_idx = datas.jj_idx;
      copyList[findIndex].jj_status = jj_status;
      copyList[findIndex].mem_idx = mem_idx;
      console.log(copyList[findIndex]);
      setPremiumList(copyList);
    });
  }

  return varTotalCount === 0 ? null : (
    <div className="main_premium_wrap">
      <div className="main_box">
        <Swiper autoplay={{ delay: 2500, disableOnInteraction: false }} pagination={{ clickable: true }} modules={[Pagination, Autoplay]}>
          <div className="swiper-container main_box01 slide_box_st01">
            <div className="tit01">PREMIUM
              <div className="swiper-pagination"></div>
            </div>
            <div className="swiper-wrapper">
              {varPremiumList?.length === 0 ? <div><span>등록된 프리미엄광고가 없습니다.</span></div>
                : varPremiumList.map((partner, ii) => {
                  const imageObj = varPremiumImages.find((img) => img.jp_idx === partner.jp_idx);
                  return (
                    <SwiperSlide className="swiper-slide" key={'partnerp' + ii}>
                      <Link to={`/Jangter/PartnerView/${partner.jp_idx}`}>
                        <div className="img_wrap" style={{ position: 'relative' }}>
                          {imageObj && imageObj.loading ? (
                            <div className="loading-indicator" style={{height:"320px", position: 'relative'}}>
                              <div className="loading-spinner"></div>
                            </div>
                          ) : (
                            <img src={imageObj?.src} alt="" style={{ maxHeight: "320px", objectFit: "cover", width: "100%", height: "100%" }} />
                          )}
                        </div>
                      </Link>
                      <div className="c_wrap">
                        <div className="info01">
                          <Link to={`/Jangter/PartnerView/${partner.jp_idx}`}>
                            <div className="c_dong st02"><span>{partner.mcm_dongname}</span></div>&nbsp;
                            <div className="c_price"><span className="c_rent_type st02">{partner.mcm_name}</span></div>
                          </Link>
                          <div className="wish_chk"><img src={partner.jj_idx === null || partner.jj_status === 0 ? heartOffIcon : heartOnIcon} alt="" onClick={e => onChangeJjimListHandler(e, partner.jp_idx)} /></div>
                        </div>
                        <Link to={`/Jangter/PartnerView/${partner.jp_idx}`}>
                          <div className="info02">
                            <div className="call"><img src={callIcon} alt="" />{ChangeMobileFormat(partner.mcm_phone)}</div>
                            <div className="adrs"><img src={mapIcon} alt="" />{partner.mcm_address1 + " " + partner.mcm_address2}</div>
                            <div className="c_desc">{partner.mcm_introduce}</div>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  )
                })}
            </div>
          </div>
        </Swiper>
      </div>
    </div>
  );
}
export default PremiumPartnerList;
