/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

import HeadTitle from '../../components/include/HeadTitle';
import bigIcon from "../../images/big_icon01.png";

//아이디 찾기
const FoundPWDOK = () => {

  return (
    <>
      <HeadTitle titleString={"비밀번호 찾기"} backString={"none"}/>
      <div className="pd_wrap">
        <div className="ok_form_wrap">
          <div className="center_wrap">
            <img src={bigIcon} alt=""/>
            <div className="top_txt">
              <strong>비밀번호가 재설정되었습니다.</strong>
              <p>새로운 비밀번호로 로그인해주세요.</p>
            </div>
            <div className="btn_bottom one_btn">
              <Link to={"/Login"} className="btn_style_yes">로그인 하기</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FoundPWDOK;