import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import RatePlanList from './RatePlanList';
import RatePlanDetail from './RatePlanDetail';

function DoongjiRatePlan({setMenuItem}) {
  //지역 관리자 목록 정보 저장 변수 
  const [varRatePlanLists, setRatePlanLists] = useState([]);
  //현재 선택된 지역 관리자 고유번호 저장 변수
  const [varCurrentRatePlan,setCurrentRatePlan] = useState(-1);
  //지역 관리자 정보 변경 페이지 표시 유무
  const [varShowDetailPage,setShowDetailPage] = useState(false);
  const [varRatePlanMode,setRatePlanMode] = useState(-1);   //0:둥지/1:장터

  //등록된 요금제 수
  const [varTotalList, setTotalList] = useState(0);

  //요금제 목록 가져오기
  const getRatePlanList = async() => {
    const sendData = {rateplanMode: 0};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_voucher_list`,sendData).then(res => {
      if(res.data.retvalue === 1) {
        if(res.data.results?.length > 0) {
          setTotalList(res.data.results.length);
          setRatePlanLists(res.data.results);
        } else {
          setTotalList(0);
          setRatePlanLists([]);
        }
      }
    });
  };

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('DoongjiRatePlan');
    setRatePlanMode(0);
    getRatePlanList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div id="contents" className="ad_contents">
        <div className="tit">
          <div className="title">둥지 파트너 요금제</div>
          <div className="path">
            <img src={iconhome} alt=""/>
            HOME<img src={arrImage} alt=""/>요금/이용권관리<img src={arrImage} alt=""/>요금제 관리<img src={arrImage} alt=""/>둥지 파트너 요금제
          </div>
        </div>
        <RatePlanList varRatePlanMode={varRatePlanMode} varTotalList={varTotalList} 
            setShowDetailPage={setShowDetailPage} setCurrentRatePlan={setCurrentRatePlan} 
            varRatePlanLists={varRatePlanLists} getRatePlanList={getRatePlanList} />
        <RatePlanDetail varShowDetailPage={varShowDetailPage} setShowDetailPage={setShowDetailPage} 
            varRatePlanMode={varRatePlanMode}
            varCurrentRatePlan={varCurrentRatePlan} setCurrentRatePlan={setCurrentRatePlan}
            varRatePlanLists={varRatePlanLists} getRatePlanList={getRatePlanList} />
      </div>
  )
}

export default DoongjiRatePlan;