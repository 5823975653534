/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"; 

import { isObjEmpty } from "../common/Common";
import DaumPostCode from "../../components/popup/DaumPostCode";

const DetailAddress = ({varJangterData,setJangterData}) => {
  const [varOpenZipcode,setOpenZipcode] = useState(false);

  //주소찾기팝업창 열기/닫기
  const onOpenZipcodeHandler = (e,bmode) => {
    console.log(varJangterData);
    setOpenZipcode(bmode);
  }
  //주소관련 정보 업데이트
  const ChangeAddress = (zipcode,address1,regcode,dongName,roadName,locString) => {
    console.log(zipcode,address1,regcode,dongName,roadName,locString);
    setJangterData((prevState)=>{return{...prevState,jt_zipcode:zipcode,jt_address1:address1,jt_regcode:regcode,jt_dongname:dongName,jt_location:locString}});
  };

  return isObjEmpty(varJangterData) ? null : (
    <>
      <div className="form_wrap">
        <div className="tit07_1">
          {varJangterData.jt_serious1===2?"주소":"거래 주소"}&nbsp;
          {varJangterData.jt_serious1===2?<span style={{color:"red",fontSize:"12px"}}>(필수)</span>:null}
        </div>
        <div className="tit07_2"></div>
          <div className="has_btn" onClick={e=>onOpenZipcodeHandler(e,true)}>
          <input type="text" className="w_s_input disbale" name="jt_zipcode" value={varJangterData.jt_zipcode} readOnly/>
          <button className="w_s_btn c_white" onClick={(e)=>onOpenZipcodeHandler(e,true)}>우편번호</button>
        </div>
        <div onClick={e=>onOpenZipcodeHandler(e,true)}>
          <input type="text" className="fix_input" name="jt_address1" value={varJangterData.jt_address1} readOnly/>
        </div>
        {/* <input type="text" name="jt_address2" value={varJangterData.jt_address2} placeholder="상세주소를 입력하세요." onChange={e=>onChangeInputHandler(e)}/> */}
      </div>
      {/* 우편번호/주소 검색*/}
      <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler}/>
    </>
  );
}
export default DetailAddress