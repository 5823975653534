import React, { useState, useEffect } from "react"; 
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";

import SelectDate from "../../components/popup/SelectDate";

import { isNull,MakeNullDateFields,AddOptionToString,isObjEmpty,MakeRegionArray,ChangeSearchingDateFormat,getPaymentCode,ShowDoongjiTitle } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import SelectRegion from '../../components/include/SelectRegion';

import plusImage from "../../images/plus2.png";
import closeIcon from "../../images/icon_close.svg";

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

import calenderIcon from "../../images/btn_calendar.png";

const RegisterPremium = () => {
  const { rpa_mode,ad_idx } = useParams();
  const navigate = useNavigate();
  const mem_idx = localStorage.getItem("mem_idx");

  let apiString = "";
  let newData = {};
  if(Number(rpa_mode)===1){
    apiString = "_get_doongji_product";
    newData = {rp_idx:ad_idx,rp_deposit:0,rp_monthly:0,rp_admin_expense:0,mcm_idx:0,mcm_name:"",rpa_idx:0,rpa_sdate:null,rpa_edate:null,rpa_regcode:"",
              rpa_regname:"",rpa_status:1,structure:"",types:""};
  } else {
    apiString = "_get_partner_product";
    newData = {jp_idx:ad_idx,mcm_idx:0,mcm_name:"",rpa_idx:0,rpa_sdate:null,rpa_edate:null,rpa_regcode:"",
    rpa_regname:"",rpa_status:1};
  }
  const [varCompanyName,setCompanyName] = useState("");

  const [varProductData,setProductData] = useState({});
  const [varSearchRegionList,setSearchRegionList] = useState([]);
  //팝업창 열기
  const [varPopupSearchRegion,setPopupSearchRegion] = useState(false);

  const [varStartDate,setStartDate] = useState(null);
  const [varEndDate,setEndDate] = useState(null);
  const [varIsPremium,setIsPremium] = useState();

  //둥지/장터 매물정보 가져오기
  const getProductForPremium = async() => {
    const sendData = {ad_idx:ad_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/${apiString}`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if( datas.results?.length > 0) {
          //검색된 목록
          const adData = datas.results[0];
          if(adData.rpa_idx === null) adData.rpa_idx = 0;
          setProductData(adData);
          console.log(adData);
          if(adData.rpa_idx > 0) {
            setStartDate(new Date(adData.rpa_sdate));
            setEndDate(new Date(adData.rpa_edate));
            if(adData.rpa_regcode !== "" ) {
              const rpaRegionArray = MakeRegionArray(adData.rpa_regcode,adData.rpa_regname);
              console.log(rpaRegionArray);
              setSearchRegionList(rpaRegionArray);
            }
          }
        } else {
          setProductData(newData);
        }
        //console.log(datas.company.mcm_name);
        setCompanyName(datas.company.mcm_name);
      }
    });
  }
  useEffect(() => {
    getProductForPremium();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPopupWinHandler = (bmode) => {
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }
  //지역선택 팝업창
  const onPopupRegionHandler = (e,bmode) => {
    e.preventDefault(); 
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupSearchRegion(false);
    } else {
      onPopupWinHandler(bmode);
      setPopupSearchRegion(bmode);
    }
    setIsPremium(true);
  }
  //검색지역삭제
  const onDeleteSearchRegionHandler = (e,regcode) => {
    e.preventDefault();
    const newRegionList = varSearchRegionList.filter(element=>element.regcode !== regcode);
    setSearchRegionList(newRegionList);
    console.log(newRegionList);
  }
  //검색기간 설정값 저장
  const onChangeDateHandler = (ino,date) => {
    if(date === "" ) date = null;
    console.log(ino,date,ChangeSearchingDateFormat(date));
    if(ino === 1){ 
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    setPopupDaySelect(false);
  }
  //프리미엄 신청하기
  const onRequestPremiumHandler = async (e) => {
    e.preventDefault();
    if(varSearchRegionList?.length === 0) {
      alert('희망광고지역을 먼저 지정하세요.'); return false;
    }
    if(isNull(varStartDate) || isNull(varEndDate)) {
      alert('희망 게재 기간을 먼저 지정하세요.'); return false;
    }
    if(varStartDate > varEndDate) {
      alert('희망 게재 기간을 정확히 입력해주세요'); return false;
    }
    //지역
    let regString = ""; 
    let regcodeString = ""; 
    let regnameString = ""; 
    for(var i=0; i < varSearchRegionList?.length; i++) {
      regString = AddOptionToString(`rpa_regcode${i+1}='${varSearchRegionList[i].regcode}'`,regString,',');
      regcodeString = AddOptionToString(varSearchRegionList[i].regcode,regcodeString,',');
      regnameString = AddOptionToString(varSearchRegionList[i].regname,regnameString,',');
    }
    //게재기간
    const sdate = MakeNullDateFields('rpa_sdate',varStartDate);
    const edate = MakeNullDateFields('rpa_edate',varEndDate);
    //주문번호 생성
    const random = getPaymentCode();
    const randomId = window.btoa(random);
    //서버에 저장
    const sendData = {rpa_idx:varProductData.rpa_idx,rpa_mode:rpa_mode,ad_idx:ad_idx,mem_idx:mem_idx,sdate:sdate,edate:edate,regString:regString,
                      regcodeString:regcodeString,regnameString:regnameString,ph_code:randomId};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_request_premium_product`,sendData).then((res) => {
      const datas = res.data;
      alert(datas.message);
      if(datas.retvalue === 1) {
        navigate('/MyPage/RequestPremium');
      }
    });   
  }

  //날짜선택 팝업창
  const [varPopupDaySelect,setPopupDaySelect] = useState(false);  //팝업창 열기/닫기
  const [varDate,setDate] = useState(null); //선택된 날짜
  const [varDateMode,setDateMode] = useState(0);  //날짜 항목
  const onToggleOpenDateHandler = (e,imode) => {
    setPopupDaySelect(true);
    setDateMode(imode);
    if(imode === 1) {
      setDate(varStartDate);
    } else {
      setDate(varEndDate);
    }
    console.log(imode)
  }
  //console.log(varCompanyName);
  return isObjEmpty(varProductData)?null:(
    <>
      <Header/>
      <div className="layer-bg"></div> 
      <HeadTitle titleString={"프리미엄 광고 신청"}/>
      <div id="content">
        <div className="pd_wrap03">
          <div className="ad_apply_wrap">
            <div className="center_wrap">
              <div className="form_wrap">
                <div className="tit07_1">희망 광고 컨텐츠</div>
                <div className="ad_apply_con_box">
                  <span className="btn" style={{padding:"0px 10px 5px 10px"}}>{Number(rpa_mode)===1?"둥지":"장터"}</span>
                  <span className="txt">
                    {Number(rpa_mode)===1?ShowDoongjiTitle(varProductData,' ')
                    :varCompanyName}
                  </span>
                </div>
              </div>
              <div className="form_wrap">
                <div className="tit07_1">희망 광고 지역</div>
                <div className="has_btn">
                  <div className="ph_num_dan3 plus">
                    <select id="setAdArea" placeholder="지역선택" onClick={e=>onPopupRegionHandler(e,true)}></select>
                  </div>
                  <img src={plusImage} alt="" className="add_area_btn" onClick={e=>onPopupRegionHandler(e,true)}/>
                </div>
              </div>
              {/* 검색으로 선택된 지역 목록 */}
              <div className="sel_local">
                <p>선택된 동네</p>
                <div style={{width:"100%",overflowX:"scroll",overflowY:"hidden"}}>
                  <ul style={{width:"auto"}}>
                    {varSearchRegionList.map((region,i)=>(
                      <li key={'search_region_'+i} onClick={e=>onDeleteSearchRegionHandler(e,region.regcode)} style={{display:"inline-block",cursor:"pointer"}}>
                        {region.regname}<img src={closeIcon} alt="" className="close_btn"/>
                      </li>
                    ))}  
                  </ul>
                </div>
              </div>  
              <div className="form_wrap">
                <div className="tit07_1">희망 게재 기간</div>
                <div className="dan2_danwe">
                  <input name="rpa_sdate" className="date-picker" value={ChangeSearchingDateFormat(varStartDate)||""} onClick={e=>onToggleOpenDateHandler(e,1)} readOnly/>
                  <img src={calenderIcon} alt="" onClick={e=>onToggleOpenDateHandler(e,1)}/>
                  <span className="mid_bar03"> ~ </span>
                  <input name="rpa_edate" className="date-picker" value={ChangeSearchingDateFormat(varEndDate)||""} onClick={e=>onToggleOpenDateHandler(e,2)} readOnly/>
                  <img src={calenderIcon} alt="" onClick={e=>onToggleOpenDateHandler(e,2)}/>
                </div>
              </div>
              {varProductData.rpa_idx===null || varProductData.rpa_idx===0?null:
              <div className="form_wrap">
                <div className="tit07_1">진행사항<span style={{fontSize:"13px",fontWeight:"lighter",marginLeft:"30px"}}>
                  {varProductData.rpa_status===1?"신청중"
                  :varProductData.rpa_status===2?"승인/결제대기중":"결제완료(편집/저장불가)"
                  }</span>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
        {varProductData.rpa_status===0 || varProductData.rpa_status===3?null:
          <div className="btn_bottom one_btn">
            <button className="btn_style_yes" onClick={e=>onRequestPremiumHandler(e)}>
              {varProductData.rpa_idx===0?"프리미엄 신청하기":"프리미엄 재저장하기"}
            </button>
          </div>
        }
      </div>
      {/* 날짜선택 팝업창------ */}
      <SelectDate varDate={varDate} setDate={setDate} varDateMode={varDateMode} 
              varPopupDaySelect={varPopupDaySelect} setPopupDaySelect={setPopupDaySelect} 
              onChangeDateHandler={onChangeDateHandler} varDropDown={false}/>
      {/* 지역선택팝업창------ */}
      <SelectRegion varPopupSearchRegion={varPopupSearchRegion} onPopupRegionHandler={onPopupRegionHandler}
                    varSearchRegionList={varSearchRegionList} setSearchRegionList={setSearchRegionList}
                    varIsPremium={varIsPremium} />
    </>
  );
}
export default RegisterPremium;