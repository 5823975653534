/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";
import { ChangeDateStringOnlyDate,isObjEmpty } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

//공지사항
const OneToOneList = () => {
  const mem_idx = localStorage.getItem("mem_idx");
  const newData = {board_title:"",board_comment:"",mem_idx:mem_idx};
  
  //const [varTotalCount,setTotalCount] = useState(0);
  const [varOneToOneList,setOneToOneList] = useState([]);
  const [varAddBoardData,setAddBoardData] = useState({});
  
  //공지사항 목록 가져오기
  const getOneToOneList = async() => {
    const sendData = {mem_idx:mem_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_onetoone_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //검색된 전체수량
        //setTotalCount(datas.totalcount);
        //검색된 목록
        setOneToOneList(datas.results);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    
    setAddBoardData(newData);
    getOneToOneList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //내용 보이고 안보이고
  const onExpandFAQHandler = (board_idx) => {
    const titleBlock = document.getElementById("title_"+board_idx);
    if(titleBlock.classList.contains('on')) {
      titleBlock.classList.remove('on');
      $('#comment_'+board_idx).slideUp();
    } else {
      titleBlock.classList.add('on');
      $('#comment_'+board_idx).slideDown();
      ShowBoardData(board_idx);
    }
  }
  const ShowBoardData = async (board_idx) => {
    const sendData = {mem_idx:mem_idx,board_idx:board_idx,boardTable:'board_question'};
    await axios.post(`${CONFIG.SERVER_HOST}/_plus_hit_board`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 0) {
        alert(datas.message);
      }
    });
  }
  //새로운 문의사항 입력--------------------------------------------------------
  const onChangeInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    setAddBoardData({...varAddBoardData,[name]:value});
  }
  //문의사항등록
  const onSaveBoardHandler = async (e) => {
    e.preventDefault();
    if(varAddBoardData.board_title === "") {
      alert('문의제목을 먼저 입력하세요.'); return false;
    } else if (varAddBoardData.board_comment === "") {
      alert('문의내용을 먼저 입력하세요.'); return false;
    }
    if(!window.confirm("입력하신 문의내용을 등록합니까?")) return false;
    await axios.post(`${CONFIG.SERVER_HOST}/_save_onetoone_list`,varAddBoardData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        setAddBoardData(newData);
        getOneToOneList();
      } else {
        alert(datas.message);
      }
    });
  }

  return isObjEmpty(varAddBoardData)?null:(
    <>
      <Header/>
      <HeadTitle titleString={"1:1 문의"}/>
      <div id="content">
        <div className="pd_wrap02">
          <div className="inquiry_wrap">
            <div className="center_wrap">
              <div className="tit06">문의 등록</div>
              <div className="form_wrap">
                <div className="tit">제목</div>
                <input type="text" name="board_title" value={varAddBoardData.board_title} placeholder="문의 제목을 입력해주세요." onChange={e=>onChangeInputHandler(e)}/>
              </div>
              <div className="form_wrap">
                <div className="tit">내용</div>
                <textarea name="board_comment" cols="" rows="" value={varAddBoardData.board_comment} className="dtextarea" placeholder="문의 내용을 입력해주세요" 
                          style={{maxWidth:'100%',minWidth:'100%',width:'100%'}} onChange={e=>onChangeInputHandler(e)}/>
              </div>
              <button className="inquery_ok_btn" onClick={e=>onSaveBoardHandler(e)}>등록</button>
            </div>
          </div>
          <div className="my_inq_wrap">
            <div className="center_wrap"><div className="tit06">문의 내역</div></div>
            <div className="faq_wrap">
            {varOneToOneList?.length === 0?
              <div className="f_con b_shadow">
                <div className="top_wrap">
                  <div className="center_wrap">
                    <div className="tit03" style={{textAlign:"center",color:"#bbb"}}>등록된 1:1 문의가 없습니다.</div>
                  </div>
                </div>
              </div>
            :varOneToOneList.map((otoo,i)=>(
              <div className="f_con b_shadow" key={"otoo_"+i}>
                <div className="top_wrap" id={"title_"+otoo.board_idx}>
                  <div className="center_wrap">
                    <div className="tit03" style={{width:"320px"}}>{otoo.board_title}</div>
                    <div className="date">{ChangeDateStringOnlyDate(otoo.board_rdate)}</div>
                    <div className="right_arrow rote180" onClick={e=>onExpandFAQHandler(otoo.board_idx)}>&nbsp;</div>
                  </div>
                </div>
                <div className="txt_con" id={"comment_"+otoo.board_idx}>
                  <div className="subtitle">질의내용</div>
                  <div className="otoo_comment" style={{whiteSpace:"pre-line"}}>{otoo.board_comment}</div>
                  {otoo.board_answer === null||otoo.board_answer === ""? null:
                    <>
                    <div className="subtitle">답변</div>
                    <div className="otoo_answer" style={{whiteSpace:"pre-line"}}>{otoo.board_answer}</div>
                    </>
                  }
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OneToOneList;