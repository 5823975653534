/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from '../../../configs/Configs';

//2단계 변수 설정의 경우 
const VariableTwoBlock = ({varMode,varItems}) => {
  //2단계 모드 지정
  const varMode2 = Number(varMode) + 20;

  //서버로 전달할 변수 저장 모듈 초기화
  const [varParams1,setParams1] = useState({mode:varMode,index:-1,curIndex:0,name:''});
  const [varParams2,setParams2] = useState({mode:varMode2,index:0,curIndex:0,name:''});

  //1단계 변수 정보 정의 {index:10,title:"장터 대분류",ids:"vbs_idx",idn:"vbs_name"}
  //2단계 변수 정보 정의 {index:30,title:"장터 중분류",ids:"vbi_idx",idn:"vbi_name"}
  let itemObj1 = varItems.filter(element=>element.index === Number(varMode))[0];
  let itemObj2 = varItems.filter(element=>element.index === Number(varMode2))[0];

  //변수 선택 항목 목록
  const [varItem1List,setItem1List] = useState([]); 
  const [varItem2List,setItem2List] = useState([]);

  //선택된 항목
  const [varSelectedItem1Index,setSelectedItem1Index] = useState(0);  
  const [varSelectedItem2Index,setSelectedItem2Index] = useState(0);

  //선택된 항목의 이름
  const [varKeyInItem1Name,setKeyInItem1Name] = useState(''); 
  const [varKeyInItem2Name,setKeyInItem2Name] = useState('');

  const ClearLists1 = () => {
    setKeyInItem1Name('');
    setSelectedItem1Index(0);
    setParams1({...varParams1, index:0, curIndex:0});
  }      
  const ClearLists2 = (ipp) => {
    setKeyInItem2Name('');
    setSelectedItem2Index(0);
    if(ipp){
      setItem2List([]);
      setParams2({...varParams2, index:0, curIndex:0});
    } else {
      setParams2({...varParams2, index:0});
    }
  }

  //변수항목 가져오기
  const getVariableList = (imode) => {
    let sendData = {};
    if(imode === 1) {
      ClearLists2(true);
      if(varParams1.index === 0) {
        ClearLists1(false);
        return false;
      }
      sendData = {...varParams1};
    } else if(imode === 2) {
      if(varParams2.index === 0) {
        ClearLists2(false);
        return false;
      }
      sendData = {...varParams2};
    }
    getNextVariableList(imode,sendData);
  }
  const getNextVariableList = async(imode,sendData) => {
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_variables`,sendData).then((res) => {
      if(res.data.retvalue === 1) {
        if(imode === 1) {
          setParams1({...varParams1, index:0});
          setItem1List(res.data.results);
          setItem2List([]);
        } else {
          setParams2({...varParams2, index:0});
          setItem2List(res.data.results);
        }
      }
    });
  }
  //최초실행
  useEffect(()=>{
    getVariableList(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varParams1.mode]);

  //상부변수 항목값이 변경되면 - 하부 목록 가져오기
  useEffect(()=>{
    getVariableList(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varParams2.curIndex]);

  //항목선택
  const SelectVariableItemHandler = (e,imode) => {
    const selectObj = e.currentTarget;
    const selectedItemIndex = Number(selectObj.value);
    console.log(imode,selectedItemIndex,selectObj);

    if(imode === 1) {
      setSelectedItem1Index(selectedItemIndex);
      if(selectedItemIndex === 0) setKeyInItem1Name('');
      else setKeyInItem1Name(selectObj.options[selectObj.selectedIndex].text);
      setParams1({...varParams1, index:selectedItemIndex});
      setParams2({...varParams2, curIndex:selectedItemIndex, index:-1});
    } else {
      setSelectedItem2Index(selectedItemIndex);
      if(selectedItemIndex === 0) setKeyInItem2Name('');
      else setKeyInItem2Name(selectObj.options[selectObj.selectedIndex].text);
      setParams2({...varParams2, index:selectedItemIndex});
    }
  }

  //항목입력
  const keyinVariableItemHandler = (e,imode) => {
    const keyInString = e.currentTarget.value;
    if(imode === 1) {
      setKeyInItem1Name(keyInString);
      setParams1({...varParams1, name:keyInString});
    } else {
      setKeyInItem2Name(keyInString);
      setParams2({...varParams2, name:keyInString});
    }
  }
  //항목삭제
  const deleteVariableItemHandler = async (e,imode) => {
    let sendData = {};
    let noSelect = false;
    if(imode === 1){ 
      sendData = {...varParams1};
      if(varParams1.index === 0) noSelect = true;
    } else {
      sendData = {...varParams2};
      if(varParams2.index === 0) noSelect = true;
    } 
    if(noSelect) {
      alert('삭제할 변수항목이 선택되어 있지 않습니다.');
      return false;
    }

    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_variables`,sendData).then((res) => {
      alert(res.data.message);
      if(res.data.retvalue === 1) {
        if(imode === 1) {
          //1번쨰 목록 재설정
          setSelectedItem1Index(0);
          setKeyInItem1Name('');
          setParams1({...varParams1, index:-1});
          getVariableList(1);
        } else  {
          //2번쨰 목록 재설정
          setSelectedItem2Index(0);
          setKeyInItem2Name('');
          setParams2({...varParams2, index:-1});
          getVariableList(2);
        }
      }
    });
  }

  //항목업데이트
  const updateVariableItemHandler = async (e,imode) => {
    let sendData = {};
    let noname = false;
    let samename = false;
    let newitem = false;
    if(imode === 1){
      console.log(varParams1);
      if(varParams1.name === "") noname = true;
      if(varItem1List.findIndex(el=>el[itemObj1.ids]===varParams1.name) >= 0) samename = true;
      if(varParams1.index <= 0) newitem = true;
      sendData = {...varParams1};
    } else {
      console.log(varParams2);
      if(varParams2.name === "") noname = true;
      if(varItem2List.findIndex(el=>el[itemObj2.ids]===varParams2.name) >= 0) samename = true;
      if(varParams2.index <= 0) newitem = true;
      if(varParams2.curIndex === 0) {
        alert('등록할 상위 변수항목이 선택되어 있지 않습니다.');
        return false;
      }
      sendData = {...varParams2};
    }

    if(noname) {
      alert('등록할 변수항목이 입력되어 있지 않습니다.');
      return false;
    }
    if(samename) {
      alert('이미 등록된 변수항목입니다. 확인후 다른 변수항목을 추가하십시오.');
      if(imode===1) setKeyInItem1Name('');
      else setKeyInItem2Name('');
      return false;
    }
    let copyData = {};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_variables`,sendData).then((res) => {
      console.log(res.data);
      alert(res.data.message);
      if(res.data.retvalue === 1) {
        console.log('newitem',newitem);
        if(newitem) { //신규추가
          const newIndex = Number(res.data.newIndex);
          if(imode === 1) {
            copyData = {...varParams1};
            copyData.index = newIndex;
            setParams1(copyData);
            //하부항목 초기화
            setSelectedItem1Index(newIndex); //현재 선택된 항목(추가된 항목)
            setKeyInItem1Name(''); //입력창 초기화
            getNextVariableList(1,copyData);
          } else {
            copyData = {...varParams2};
            copyData.index = newIndex;
            setParams2(copyData);
            //하부항목 초기화
            setSelectedItem2Index(newIndex); //현재 선택된 항목(추가된 항목)
            setKeyInItem2Name(''); //입력창 초기화
            getNextVariableList(2,copyData);
          }
        } else {  //변경
          if(imode === 1) {
            const findIndex = varItem1List.findIndex(element => element[itemObj1.ids] === varParams1.index);
            let copyLists = [...varItem1List];
            copyLists[findIndex] = {...copyLists[findIndex], name:varParams1.name};
            setItem1List(copyLists);
          } else {
            const findIndex2 = varItem2List.findIndex(element => element[itemObj2.ids] === varParams2.index);
            let copyLists2 = [...varItem2List];
            copyLists2[findIndex2] = {...copyLists2[findIndex2], name:varParams2.name};
            setItem2List(copyLists2);
          }
        }
      }
    });
  }

  //초기화 - 추가를 위해
  const InitializeVariableItemHandler = (e,imode) => {
    if(imode === 1) {
      setSelectedItem1Index(0);
      setKeyInItem1Name('');
      setParams1({...varParams1, index:0});
      document.getElementById('keyinItem1').focus();
    } else {
      setSelectedItem2Index(0);
      setKeyInItem2Name('');
      setParams2({...varParams2, index:0});
      document.getElementById('keyinItem2').focus();
    }
  }

  return (
    <>
      <tr>
        <th>{itemObj1.title}</th>
        <td className="select-items">
          <select size="5" onChange={(e)=>SelectVariableItemHandler(e,1)} value={varSelectedItem1Index}>
            <option value={0}>아래의 항목을 선택하세요</option>  
            { varItem1List.length > 0 ? varItem1List.map((items,i) => (
              <option key={itemObj1.ids+i} value={items[itemObj1.ids]}>{items[itemObj1.idn]}</option>     
            )) : null }
          </select>
        </td>
        <td className="select-addition"><button onClick={(e)=>deleteVariableItemHandler(e,1)} className="btn line">삭제</button></td>
        <td className="select-addition"><button onClick={(e)=>InitializeVariableItemHandler(e,1)} className="btn line">신규</button></td>
        <td className="select-addition sel-label"><label>변수항목</label></td>
        <td className="select-input">
          <input type="text" onChange={(e)=>keyinVariableItemHandler(e,1)} id="keyinItem1" value={varKeyInItem1Name} />
        </td>
        <td className="select-addition2">
          <button onClick={(e)=>updateVariableItemHandler(e,1)} className="btn line" style={{width:"90px"}}>등록하기</button>
        </td>
      </tr>
      <tr>
        <th>{itemObj2.title}</th>
        <td className="select-items">
          <select size="5" onChange={(e)=>SelectVariableItemHandler(e,2)} value={varSelectedItem2Index}>
            <option value={0}>아래의 항목을 선택하세요</option> 
            { varItem2List.length > 0 ? varItem2List.map((items2,i) => (
              <option key={itemObj2.ids+i} value={items2[itemObj2.ids]}>{items2[itemObj2.idn]}</option>     
            )) : null }
          </select>
        </td>
        <td className="select-addition"><button onClick={(e)=>deleteVariableItemHandler(e,2)} className="btn line">삭제</button></td>
        <td className="select-addition"><button onClick={(e)=>InitializeVariableItemHandler(e,2)} className="btn line">신규</button></td>
        <td className="select-addition sel-label"><label>변수항목</label></td>
        <td className="select-input">
          <input type="text" onChange={(e)=>keyinVariableItemHandler(e,2)} id="keyinItem2" value={varKeyInItem2Name} />
        </td>
        <td className="select-addition2">
          <button onClick={(e)=>updateVariableItemHandler(e,2)} className="btn line" style={{width:"90px"}}>등록하기</button>
        </td>
      </tr>
    </>
  );
}
export default VariableTwoBlock;