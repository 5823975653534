/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import io from "socket.io-client";
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";

import { ChangeDateTimeFormat, isObjEmpty, ShowDoongjiTitle2, GetPhotoImage } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import ShowBigSizePhoto from "../../components/popup/ShowBigSizePhoto";
import ReportProduct from "../../components/popup/ReportProduct";
import BlockChatting from "../../components/popup/BlockChatting";
import ChattingArea from "../../components/chat/ChattingArea";

import callIcon from "../../images/icon_call20.png";
import cutonIcon from "../../images/icon_cuton.svg";
import cutoffIcon from "../../images/icon_cutoff.svg";
import reportIcon from "../../images/icon_singo.svg";
import exitChatIcon from "../../images/icon_next2_y.png";

let varUserMode = 1;

// 채팅룸
const ChatRoom = () => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));

  const navigate = useNavigate();

  // 채팅방번호
  const { ch_mode, ch_idx } = useParams();
  const socket = io.connect(`${CONFIG.CHATTING_HOST}`, { transports: ['websocket'] });

  // 표시내역 히스토리
  const newMessageData = {
    ch_idx: ch_idx,
    cl_mem_idx: mem_idx,
    cl_idx: 0,
    cl_type: 0,
    cl_comment: "",
    cl_file: "",
    cl_preview: null,
    cl_rdate: ""
  };

  // 상태 관리
  const [varChattingHistory, setChattingHistory] = useState([]);
  const [varSendMessage, setSendMessage] = useState('');
  const [varProfileImage, setProfileImage] = useState(null);
  const [varProfileImageLoading, setProfileImageLoading] = useState(false); // 이미지 로딩 상태 추가
  const [varDisplayData, setDisplayData] = useState(0);
  const [varMyName, setMyName] = useState('');
  const [varOppData, setOppData] = useState({});
  // const [varEnterStatus, setEnterStatus] = useState(0);
  const [varPopupBlockChatting, setPopupBlockChatting] = useState(false);
  // const [varMyPosition, setMyPosition] = useState(false);
  const [varBlockStatus, setBlockStatus] = useState(false);
  const [varBlockStatusString, setBlockStatusString] = useState('※ 채팅 시 개인정보 유출에 유의하세요');
  const [varMyBlockStatus, setMyBlockStatus] = useState(false);
  const [varOppBlockStatus, setOppBlockStatus] = useState(false);
  const [varPopupReportProduct, setPopupReportProduct] = useState(false);
  const [varOpenPhoto, setOpenPhoto] = useState(false);
  const [varPhotoImage, setPhotoImage] = useState(null);
  const [varReceviedData, setReceviedData] = useState({});
  const [varChatStatus, setChatStatus] = useState(1);
  const [varChatMemberStatus, setChatMemberStatus] = useState(1);

  // 이미지 로딩 함수
  const loadImageWithRetry = (src, setImageState, setLoadingState, retries = 20, delay = 2000) => {
    setLoadingState(true);
    const attempt = () => {
      const cacheBuster = 't=' + new Date().getTime();
      const separator = src.includes('?') ? '&' : '?';
      const img = new Image();
      img.src = src + separator + cacheBuster;
      img.onload = () => {
        setImageState(src);
        setLoadingState(false);
      };
      img.onerror = () => {
        if (retries > 0) {
          retries--;
          setTimeout(attempt, delay);
        } else {
          setLoadingState(false);
          console.error(`Failed to load image: ${src}`);
        }
      };
    };
    attempt();
  };

  // 채팅방 목록 읽어오기
  const GetChattingRoomInformation = async () => {
    const sendData = { ch_mode: ch_mode, ch_idx: ch_idx, mem_idx: mem_idx };
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_chatroom_history`, sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if (datas.retvalue === 1) {
        // 채팅기록
        if (datas.results?.length > 0) {
          // 대화내용에 이미지가 있으면 이미지로 변환하여 변수로 저장
          for (let i = 0; i < datas.results.length; i++) {
            let chatlist = datas.results[i];
            if (chatlist.cl_type === 1 && chatlist.cl_file !== "") { // 이미지
              chatlist.cl_preview = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(chatlist.cl_file, 'chatting');
            }
          }
          setChattingHistory(datas.results);
        }
        // 채팅방 상태
        setChatStatus(datas.ch_status);
        // 채팅차단 문자열
        const products = datas.products[0];
        // 나의 이름
        const myNameString = products.ch_mem_idx === mem_idx ? products.memName : products.ownName;
        setMyName(myNameString);
        // 상대방정보
        let iMemMode = 1;
        let oppdata = { oppIdx: 0, oppFileName: "", oppMobile: "", oppName: "" };
        if (products.ch_mem_idx === mem_idx) { // 사용자
          oppdata.oppIdx = products.ch_own_idx;
          oppdata.oppFileName = products.ownFileName;
          oppdata.oppMobile = products.ownMobile;
          oppdata.oppName = products.ownName;
        } else { // 매물등록자
          iMemMode = 2;
          oppdata.oppIdx = products.ch_mem_idx;
          oppdata.oppFileName = products.memFileName;
          oppdata.oppMobile = products.memMobile;
          oppdata.oppName = products.memName;
        }
        console.log(iMemMode);
        setChatMemberStatus(iMemMode);
        varUserMode = iMemMode;
        setOppData(oppdata);

        setDisplayData(products);
        DisplayBlockState(products);

        // 상품 거래자 사진 설정 및 로딩
        let photoImage = products.ownFileName;
        if (Number(ch_mode) <= 2) photoImage = products.file_name;
        if (photoImage !== "") {
          let imagePath = '';
          if (Number(ch_mode) === 1) {
            imagePath = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(photoImage, 'doongji');
          } else if (Number(ch_mode) === 2) {
            imagePath = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(photoImage, 'jangter');
          } else {
            imagePath = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(photoImage, 'member');
          }
          loadImageWithRetry(imagePath, setProfileImage, setProfileImageLoading);
        } else {
          setProfileImage(null); // 또는 기본 이미지로 설정
          setProfileImageLoading(false);
        }

        // 채팅서버 연결
        console.log('access', socket);

        socket.emit("access", {
          room: products.ch_room,
          mem_idx: mem_idx,
          opp_idx: oppdata.oppIdx,
          name: myNameString
        });
      }
    });
  }

  // 차단 상태 표시
  const DisplayBlockState = (products) => {
    let stitle = "※ 채팅시 개인정보 유출에 유의하세요";
    if (products.ch_mem_idx === mem_idx) { // 내가 구매자
      if (products.ch_mem_block === 1) { // 상대방이 나를 차단
        setMyBlockStatus(true);
        stitle = '※ 상대방으로부터 대화가 차단된 상태입니다.';
      } else if (products.ch_own_block === 1) { // 내가 상대편을 차단한 경우
        setBlockStatus(true);
        setOppBlockStatus(true);
        stitle = '※ 상대방을 대화차단한 상태입니다.';
      }
    } else { // 내가 판매자
      if (products.ch_own_block === 1) { // 상대방이 나를 차단
        setMyBlockStatus(true);
        stitle = '※ 상대방으로부터 대화가 차단된 상태입니다.';
      } else if (products.ch_mem_block === 1) { // 내가 상대편을 차단한 경우
        setBlockStatus(true);
        setOppBlockStatus(true);
        stitle = '※ 상대방을 대화차단한 상태입니다.';
      }
    }
    setBlockStatusString(stitle);
  }

  // 컴포넌트 마운트 및 언마운트 시 처리
  useEffect(() => {
    // 전체 백화면 색 변경
    document.getElementById('main_body').style.backgroundColor = '#FFFFFF';
    document.querySelector('.pd_btm100').style.paddingBottom = "0px";

    // 서버로부터 데이터 호출
    GetChattingRoomInformation();

    // beforeunload 이벤트 핸들러
    const handleBeforeUnload = (event) => {
      const data = { ch_idx, iMemMode: varUserMode, istate: 0 };
      const url = `${CONFIG.SERVER_HOST}/_update_enter_chatting`;
      const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
      navigator.sendBeacon(url, blob);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // 컴포넌트 언마운트 시 실행될 클린업 함수
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 대화창 스크롤
  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varChattingHistory]);

  // 전달받은 메시지 표시 배열로 저장
  const addMessageList = () => {
    console.log('addmessage', varReceviedData);
    if (!isObjEmpty(varReceviedData)) {
      let copyList = [...varChattingHistory];
      copyList = copyList.concat(varReceviedData);
      setChattingHistory(copyList);
      // 전달받은 메시지 읽기 표시로 변경
      ReadMessageAlready();
    }
  }

  // 전달받은 메시지 읽기 표시로 변경
  const ReadMessageAlready = async () => {
    const sendData = { ch_idx: ch_idx, mem_idx: mem_idx };
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_read_message_already`, sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
    });
  }

  // 대화창 스크롤
  useEffect(() => {
    addMessageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varReceviedData]);

  // 메시지 문자형 입력 처리
  const onChangeInputHandler = (e) => {
    const { value } = e.currentTarget;
    setSendMessage(value);
  }

  // 메시지 입력 - 엔터키
  const onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      sendMessageHandler(e);
    }
  }

  // 메시지 발송할 부분의 처리부분
  // 차단 등의 메시지
  const sendSpecialMessage = (bmode) => {
    let smessage = "";
    if (bmode) smessage = '상대방이 대화를 차단하였습니다.';
    else smessage = '상대방이 대화차단을 해제하였습니다.';

    // 서버에 채팅 내용 발송
    const options = {
      room: varDisplayData.ch_room,
      name: varMyName,
      message: smessage,
      mem_idx: mem_idx,
      opp_idx: varOppData.oppIdx,
      date: new Date().toUTCString()
    }
    socket.emit('sendMessage', options);
  }

  // 메시지 - 파일형 입력 처리
  const onChangeFileHandler = async (e) => {
    const files = e.currentTarget.files[0];
    let newElement = { ...newMessageData };
    newElement.cl_type = 1;
    newElement.cl_preview = null; // 로딩 상태 초기화
    newElement.cl_rdate = ChangeDateTimeFormat();

    if (files.name !== null && files.name !== "") {
      // 서버에 이미지 저장
      let PhotoForm = new FormData();
      PhotoForm.append('chatting', files);

      await axios.post(`${CONFIG.SERVER_HOST}/chatting`, PhotoForm).then((res) => {
        const datas = res.data;
        if (datas.retvalue === 1) {
          newElement.cl_file = datas.fileName;

          // 이미지 로드 상태 관리
          const imagePreviewUrl = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(datas.fileName, 'chatting');
          const img = new Image();
          img.src = imagePreviewUrl;

          // 이미지 로딩 완료 후 업데이트
          img.onload = () => {
            newElement.cl_preview = imagePreviewUrl;
            setReceviedData({ ...newElement });

            // 서버에 채팅 내용 발송
            const options = {
              room: varDisplayData.ch_room,
              name: varMyName,
              message: '',
              filename: datas.fileName,
              mem_idx: mem_idx,
              opp_idx: varOppData.oppIdx,
              date: new Date().toUTCString()
            };
            // 서버 데이터베이스에 저장
            registerChatHistory(newElement, 'sendImage', options);
          };

          // 로딩 중 에러 발생 시 처리
          img.onerror = () => {
            alert('이미지를 불러오는 데 실패했습니다.');
          };
        }
      });
    }
  };

  // 메시지 - 문자형 발송
  const sendMessageHandler = (e) => {
    let newElement = { ...newMessageData };
    newElement.cl_type = 0;
    newElement.cl_comment = varSendMessage;
    newElement.cl_rdate = ChangeDateTimeFormat();
    if (varSendMessage !== null && varSendMessage !== "") {
      // 히스토리에 추가
      setReceviedData(newElement);

      // 서버에 채팅 내용 발송
      const options = {
        room: varDisplayData.ch_room,
        name: varMyName,
        message: varSendMessage,
        mem_idx: mem_idx,
        opp_idx: varOppData.oppIdx,
        date: new Date().toUTCString()
      }
      // 서버 데이터베이스에 저장
      registerChatHistory(newElement, 'sendMessage', options);
      // 문자입력창 초기화
      setSendMessage('');
    }
  }

  // 채팅 내역 등록
  const registerChatHistory = async (newElement, sitem, options) => {
    const sendData = { chatInfos: newElement, ch_status: varChatStatus };
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_register_chat_history`, sendData).then((res) => {
      console.log(res.data);
      if (res.data.retvalue === 1) {
        // 채팅방 확정
        if (varChatStatus === -1) setChatStatus(1);
        socket.emit(sitem, options);
        const memInfo = res.data.members;
        if ((varChatMemberStatus === 1 && memInfo.ch_own_state === 0) || (varChatMemberStatus === 2 && memInfo.ch_mem_state === 0)) {
          let receiverIdx = varDisplayData.ch_own_idx;
          if (varChatMemberStatus === 2) receiverIdx = varDisplayData.ch_mem_idx;
          SendPushMessage(receiverIdx);
        }
      }
    });
  }

  // 푸시메시지 발송
  const SendPushMessage = async (receiverIdx) => {
    const sendData = { pushMode: 2, oppIdx: receiverIdx };
    console.log("sendData :", sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_push_message`, sendData).then((res) => {
      const datas = res.data;
      console.log("datas :", datas);
      if (datas.retvalue === 0) {
        alert(datas.message);
      }
    });
  }

  // 채팅방에서 나가기
  const onExitChatRoomHandler = async () => {
    if (!window.confirm('채팅방을 나가시겠습니까?')) {
      return false;
    }
    // 채팅방 삭제
    const sendData = { ch_idx: ch_idx };
    await axios.post(`${CONFIG.SERVER_HOST}/_remove_chatroom_list`, sendData).then((res) => {
      if (res.data.retvalue === 1) {
        navigate(-1);
      }
    });
    // 서버에 채팅 내용 발송
    const options = {
      room: varDisplayData.ch_room,
      name: varMyName,
      message: "상대방이 채팅방에서 나갔습니다.",
      mem_idx: mem_idx,
      opp_idx: varOppData.oppIdx,
      date: new Date().toUTCString()
    }
    socket.emit('sendMessage', options);
  }

  // 팝업창 처리
  const onPopupWinHandler = (bmode) => {
    if (bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }

  // 신고하기
  const onPopupReportProductHandler = (e, bmode) => {
    e.preventDefault();
    onPopupWinHandler(bmode);
    setPopupReportProduct(bmode);
  }

  // 사진확대
  const onShowBigPhotoHandler = (e, sfile) => {
    setPhotoImage(sfile);
    onOpenPhotoHandler(e, true);
  }
  const onOpenPhotoHandler = (e, bmode) => {
    e.preventDefault();
    onPopupWinHandler(bmode);
    setOpenPhoto(bmode);
  }

  // 대화상대 차단/차단해제 팝업창 호출/닫기
  const onPopupBlockChattingHandler = (e, bmode) => {
    e.preventDefault();
    onPopupWinHandler(bmode);
    setPopupBlockChatting(bmode);
  }

  // 채팅상대 차단/차단해제하기
  const onBlockChattingHandler = async (e) => {
    e.preventDefault();
    var smessage = "차단하시겠습니까?";
    if (varBlockStatus) smessage = "차단을 해제하시겠습니까?";

    if (!window.confirm(smessage)) return false;
    const newBlock = !varBlockStatus;

    let opp_mode = 0;
    if (varDisplayData.ch_own_idx === mem_idx) opp_mode = 1;

    const sendData = { ch_idx: ch_idx, opp_mode: opp_mode, state: newBlock };
    await axios.post(`${CONFIG.SERVER_HOST}/_change_block_chatting`, sendData).then((res) => {
      const datas = res.data;
      alert(datas.message);
      if (datas.retvalue === 1) {
        setBlockStatus(newBlock);
        onPopupBlockChattingHandler(e, false);
        let product = { ...varDisplayData };
        if (opp_mode === 1) product.ch_mem_block = Number(newBlock);
        else product.ch_own_block = Number(newBlock);
        DisplayBlockState(product);
        sendSpecialMessage(newBlock);
      }
    });
  }

  // 상세페이지로 이동
  const onGotoProductHandler = (e) => {
    if (Number(ch_mode) === 1) navigate(`/Doongji/DoongjiView/${varDisplayData.rp_idx}`);
    else if (Number(ch_mode) === 2) navigate(`/Jangter/JangterView/${varDisplayData.jt_idx}`);
    else navigate(`/Jangter/PartnerView/${varDisplayData.jp_idx}`);
  }

  return (
    <>
      <Header />
      <HeadTitle titleString={"채팅"} />
      <div className="layer-bg"></div>
      <div id="content">
        <div className="chatroom_wrap pd_btm100">
          {/* 채팅상부 정보표시부 */}
          <div className="chat_top_wrap">
            <div className="chat_top">
              <div className="chat_top_inner">
                <div className="img_wrap" style={{position:"relative"}} onClick={e => onGotoProductHandler(e)}>
                  {varProfileImageLoading ? (
                    <div className="loading-indicator">
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <img src={varProfileImage} alt="" />
                  )}
                </div>
                <div className="box">
                  <div className="c_name">{varOppData.oppName}
                    <a href={`tel:${varOppData.oppMobile}`}><img src={callIcon} alt="" /></a>
                  </div>
                  <span className="room_info" style={{ cursor: "pointer" }} onClick={e => onGotoProductHandler(e)}>
                    <span className="dong">
                      {Number(ch_mode) === 1 ? varDisplayData.rp_dongname
                        : Number(ch_mode) === 2 ? varDisplayData.jt_dongname
                          : varDisplayData.mcm_dongname}
                    </span>
                    <span className="mid_bar"> </span>
                    {Number(ch_mode) === 1 ?
                      <>
                        <span className="price">[{varDisplayData.serious + " | " + varDisplayData.types}]</span>
                        <span className="mid_bar"> </span>
                        <span className="scl">{ShowDoongjiTitle2(varDisplayData, " ")}</span>
                      </>
                      : Number(ch_mode) === 2 ?
                        <>
                          <span className="price">[{(varDisplayData.vbs_name === null ? "" : varDisplayData.vbs_name + '/') + (varDisplayData.vbi_name === null ? "" : varDisplayData.vbi_name)}]</span>
                          <span className="mid_bar"> </span><br />
                          <span className="scl">{varDisplayData.jt_title}</span>
                        </>
                        :
                        <>
                          <span className="price">[{varDisplayData.jp_names}]</span>
                          <span className="mid_bar"> </span>
                          <span className="scl">{varDisplayData.mcm_introduce}</span>
                        </>
                    }
                  </span>
                </div>
                <ul className="fnc_wrap">
                  {/* 차단하기 */}
                  <li className="go_cut" onClick={e => onPopupBlockChattingHandler(e, true)}>
                    <img src={varBlockStatus ? cutonIcon : cutoffIcon} alt="" />
                  </li>
                  {/* 신고하기 */}
                  <li className="go_singo" onClick={e => onPopupReportProductHandler(e, true)}>
                    <img src={reportIcon} alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="start_msg" style={{ bottom: "10px" }}>
              <span>{varBlockStatusString}</span>
              <div className="fnc_below" onClick={() => onExitChatRoomHandler()}>
                <span>나가기&nbsp;</span><img src={exitChatIcon} alt="" style={{ width: "22px", verticalAlign: "top" }} />
              </div>
            </div>
          </div>
          {/* 채팅표시부 */}
          <div className="chat_con_wrap">
            <div className="chat_con">
              <div className="chat" id="chatRoom">
                <ChattingArea
                  socket={socket}
                  varDisplayData={varDisplayData}
                  newMessageData={newMessageData}
                  varChattingHistory={varChattingHistory}
                  setBlockStatus={setBlockStatus}
                  setReceviedData={setReceviedData}
                  onShowBigPhotoHandler={onShowBigPhotoHandler}
                  setMyBlockStatus={setMyBlockStatus}
                  setBlockStatusString={setBlockStatusString}
                />
              </div>
            </div>
          </div>
          {/* 하단입력부 */}
          {varMyBlockStatus || varOppBlockStatus ? null :
            <div className="msg_send_wrap">
              <div className="msg_send">
                <div className="file_btn file_send">
                  <input
                    type="file"
                    name="send_file"
                    id="fileUpload"
                    accept={"image/*"}
                    onChange={e => onChangeFileHandler(e)}
                  />
                </div>
                <div className="msg_fd_wrap">
                  <input
                    type="text"
                    name="send_message"
                    className="msg_fild"
                    value={varSendMessage}
                    onKeyPress={e => onKeyPressHandler(e)}
                    onChange={e => onChangeInputHandler(e)}
                  />
                </div>
                <div className="btn_send" onClick={e => sendMessageHandler(e)}></div>
              </div>
            </div>
          }
        </div>
      </div>
      {/* 신고하기 */}
      <ReportProduct
        rpIdx={varDisplayData.op_idx}
        rpMode={ch_mode}
        varPopupReportProduct={varPopupReportProduct}
        onPopupReportProductHandler={onPopupReportProductHandler}
      />
      {/* 사진확대 */}
      <ShowBigSizePhoto
        varPhotoImage={varPhotoImage}
        varOpenPhoto={varOpenPhoto}
        onOpenPhotoHandler={onOpenPhotoHandler}
      />
      {/* 차단/차단해제하기 */}
      <BlockChatting
        varBlockStatus={varBlockStatus}
        onBlockChattingHandler={onBlockChattingHandler}
        varPopupBlockChatting={varPopupBlockChatting}
        onPopupBlockChattingHandler={onPopupBlockChattingHandler}
      />
    </>
  )
}

export default ChatRoom;
