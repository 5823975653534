/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { isNull,isObjEmpty,AddOptionToString,ExceptOptionToString } from "../common/Common";
import DaumPostCode from "../../components/popup/DaumPostCode";

const DetailAddress = ({varProductData,setProductData,varThemeList,varPurposeRegions,varPurposeLands,varBuildingStructure,varSecterList,varItemsList,varBuildingPurpose,onChangeInputHandler,onChangeSelectHandler}) => {
  const [varOpenZipcode,setOpenZipcode] = useState(false);
  const ApiKey = "oWqWzWzJ07niv5IW%2BOLZiUCj9Yfll%2FeDXdFmtqZQZiYoQb5wcO00fy16gjSsFn6ukIfDG6%2Ff6s05DBpSDlO61A%3D%3D";
  
  const [varNamesList,setNamesList] = useState([]);

  const navigate = useNavigate();

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(varProductData.rp_serious === 1 && (varProductData.rp_regcode !== null &&varProductData.rp_regcode !== "")) {
      document.getElementById('rp_name').classList.add('hide');
      GetDanjiList(varProductData.rp_regcode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varProductData.rp_serious]);

  useEffect(() => {
    if (varProductData.rp_serious === 1 && varProductData.rp_regcode) {
      const isNameSelected = varNamesList.some(name => name.kaptName === varProductData.rp_name);
      if (isNameSelected) {
        // 특정 kaptName이 선택된 경우 입력 필드 숨김
        document.getElementById('rp_name').classList.add('hide');
      } else {
        // 직접입력인 경우 입력 필드 표시
        document.getElementById('rp_name').classList.remove('hide');
      }
      GetDanjiList(varProductData.rp_regcode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varProductData.rp_name]);
  

  //주소찾기팝업창 열기/닫기
  const onOpenZipcodeHandler = (e,bmode) => {
    e.preventDefault();
    setOpenZipcode(bmode);
  }
  //주소관련 정보 업데이트
  const ChangeAddress = (zipcode,address1,regcode,dongName,roadName,locString,userSelectedType) => {
    console.log(zipcode,address1,regcode,dongName,roadName,locString);
    // console.log(roadName);
    setProductData((prevState)=>{return{...prevState,rp_zipcode:zipcode,rp_address1:address1,rp_regcode:regcode,rp_dongname:dongName,rp_roadname:roadName,rp_location:locString,userSelectedType:userSelectedType,rp_name: ""}});
    //단지목록
    if(varProductData.rp_serious === 1) {
      GetDanjiList(regcode);
    }
    navigate(-1);
  };

  const GetDanjiList = async(regcode) => {
    console.log(regcode);
    const apiURL = `http://apis.data.go.kr/1613000/AptListService2/getLegaldongAptList?bjdCode=${regcode}&ServiceKey=${ApiKey}`;
    await axios.get(apiURL).then((res) => {  
      console.log(res);  
      if(res.status === 200) {
        const datas = res.data.response.body.items.item; 
        let namelist = [];
        for(var i=0; i < datas?.length; i++) {
          namelist.push(datas[i]);
        }
        //sorting
        namelist.sort(function(a, b) {
          return a.kaptName > b.kaptName;
        });
        console.log(namelist);
        setNamesList(namelist);
      }
    });
  }
  //단지명 선택시
  // const onSelectInputHandler = (e) => {
  //   const { value } = e.currentTarget;
  //   if(value === "") {
  //     document.getElementById('rp_name').classList.remove('hide');
  //   } else {
  //     document.getElementById('rp_name').classList.add('hide');
  //     setProductData({...varProductData,rp_name:value});
  //   }
  // }
  const onSelectInputHandler = (e) => {
    const { value } = e.target;
    if (value === "") {
      // "직접입력" 선택 시 입력 필드 표시
      setProductData(prevState => ({
        ...prevState,
        rp_name: ""
      }));
    } else {
      // 특정 kaptName 선택 시 입력 필드 숨김 및 rp_name 업데이트
      setProductData(prevState => ({
        ...prevState,
        rp_name: value
      }));
    }
  };
  

  //선택한 옵션을 문자열로 조정한후에 변수에 저장
  const onSelectOptionHandler = (e) => {
    const { name,value } = e.currentTarget;
    const bmode = e.currentTarget.checked;
    let newString = "";
    if(bmode) { //rp_sector. rp_items, rp_theme
      if(isNull(varProductData[name]) || !varProductData[name].includes(value)) {
        newString = AddOptionToString(value,varProductData[name],',');
        setProductData({...varProductData,[name]:newString});
      }
    } else {
      if(varProductData[name].includes(value)) {
        newString = ExceptOptionToString(value,varProductData[name],',');
        setProductData({...varProductData,[name]:newString});
      }
    }
  }
  // 옵션 한 개만 선택 가능 - 업종, 종목
  const onSelectOptionHandlerOne = (e) => {
    const { name, value } = e.currentTarget;
    const bmode = e.currentTarget.checked;
    let newString = "";
  
    if (bmode) {
      if (isNull(varProductData[name]) || !varProductData[name].includes(value)) {
        if (name === "rp_sector") {
          if (!isNull(varProductData[name]) && varProductData[name].split(',').length >= 1) {
            alert("업종은 중복선택할 수 없습니다.");
            return;
          }
        }
        if (name === "rp_items") {
          if (!isNull(varProductData[name]) && varProductData[name].split(',').length >= 1) {
            alert("종목은 중복선택할 수 없습니다.");
            return;
          }
        }
        newString = AddOptionToString(value, varProductData[name], ',');
        setProductData({ ...varProductData, [name]: newString });
      }
    } else {
      if (varProductData[name].includes(value)) {
        newString = ExceptOptionToString(value, varProductData[name], ',');
        setProductData({ ...varProductData, [name]: newString });
      }
    }
  };
  

/*   useEffect(() => {
    console.log(varProductData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varProductData.rp_theme]); */

  return isObjEmpty(varProductData) ? null : (
    <>
      <div className="form_wrap">
        <div className="tit07_1" style={{display:"flex",justifyContent:"space-between"}}>
          <div>매물 주소&nbsp;<span className="star_red"> (필수)</span></div>
          <div>
            <li className="terms_chks">
              <input type="checkbox" name="rp_hidden_address" className="chk1" id="rp_hidden_address" style={{verticalAlign:"moddle"}}
                    defaultChecked={varProductData.rp_hidden_address} onChange={e=>onChangeInputHandler(e)}/>
              <i></i><label htmlFor="rp_hidden_address" >주소 비공개</label>
            </li>
          </div>
        </div>
        <div className="has_btn form_Address" onClick={(e)=>onOpenZipcodeHandler(e,true)}>
          <input type="text" name="rp_zipcode" placeholder="우편번호 입력" value={varProductData.rp_zipcode||""} className="w_s_input back-disbale" readOnly/>
          <button className="w_s_btn" onClick={(e)=>onOpenZipcodeHandler(e,true)}>우편번호</button>
        </div>
        <div onClick={(e)=>onOpenZipcodeHandler(e,true)}>
        <input type="text" name="rp_address1" value={(varProductData.userSelectedType === "R" ? varProductData.rp_roadname : varProductData.rp_address1||"")} className="back-disbale" readOnly/>
        </div>
        
        {/* 9:토지 */}
        {varProductData.rp_serious === 9 ? null 
        :varProductData.rp_serious <= 4 ?
          <input type="text" name="rp_address2" value={varProductData.rp_address2} placeholder="상세주소를 입력하세요." onChange={e=>onChangeInputHandler(e)}/>
        :varProductData.rp_serious === 8 ?
          /* 빌딩 */
          <div className="form_wrap2">
            <div className="terms_chks" style={{padding:"3px 0px 10px 0px"}}>
              <input type="checkbox" name="rp_address_hidden" className="chk1" id="rp_address_hidden" checked={varProductData.rp_address_hidden} onChange={e=>onChangeInputHandler(e)}/>
              <i style={{marginTop:"8px"}}></i><label style={{marginTop:"5px"}} htmlFor="rp_address_hidden">비공개</label>&nbsp;
            </div>
            <div className="tit07_2">건물명</div>
            <input type="text" name="rp_name" id="build_name" className={varProductData.rp_address_hidden?"one-line2 disabled":"one-line2"} value={varProductData.rp_name||""} onChange={e=>onChangeInputHandler(e)}/>
          </div>
        :
          <div className="form_wrap2">
            <div className="terms_chks" style={{padding:"3px 0px 10px 0px"}}>
              <input type="checkbox" name="rp_address_hidden2" className="chk1" id="rp_address_hidden2" checked={varProductData.rp_address_hidden} onChange={e=>onChangeInputHandler(e)}/>
              <i style={{marginTop:"8px"}}></i><label style={{marginTop:"5px"}} htmlFor="rp_address_hidden2">비공개</label>&nbsp;&nbsp;
            </div>
            <input type="text" name="rp_address2" id="address2" className={varProductData.rp_address_hidden?"disabled":""} style={{width:"200px"}} value={varProductData.rp_address2} placeholder="상세주소를 입력하세요." onChange={e=>onChangeInputHandler(e)}/>
          </div>
        }
      </div>
        
      {varProductData.rp_serious > 3 ? null:
        <div className="form_wrap2">
          <div className="tit07_2">{varProductData.rp_serious===3?"빌라명":"단지명"}</div>
          <div>
            <input type="text" name="rp_name" id="rp_name" className="one-line2" style={{width:"100%"}} value={varProductData.rp_name||""} onChange={e=>onChangeInputHandler(e)}/>
            <br/>
            {varProductData.rp_serious!==1?null:
              <select
              name="rp_name_list"
              id="rp_name_list"
              value={varNamesList.some(name => name.kaptName === varProductData.rp_name) ? varProductData.rp_name : ""}
              onChange={e => onSelectInputHandler(e)}
            >
              <option value="">직접입력</option>
              {varNamesList.map((names, i) => (
                <option key={"names_" + i} value={names.kaptName}>
                  {names.kaptName}
                </option>
              ))}
            </select>            
            }
          </div>
        </div>
      }

      {varProductData.rp_serious > 4 ? null:
        <div className="form_wrap3">
          <div className="has_btn">
            <div className="right_wrap01">
              <div className="terms_chks">
                <input type="checkbox" name="rp_address_hidden3" className="chk1" style={{verticalAlign:"moddle"}} id="rp_address_hidden3" checked={varProductData.rp_address_hidden} onChange={e=>onChangeInputHandler(e)}/>
                <i></i><label htmlFor="rp_address_hidden3">비공개</label>&nbsp;&nbsp;
              </div>
              <input type="text" name="rp_bd_num" id="rp_bd_num" style={{width:"90px"}} value={varProductData.rp_bd_num||""} className={varProductData.rp_address_hidden?"disabled":""} onChange={e=>onChangeInputHandler(e)}/>&nbsp;동&nbsp;&nbsp;
              <input type="text" name="rp_rm_num" id="rp_rm_num" style={{width:"90px"}} value={varProductData.rp_rm_num||""} className={varProductData.rp_address_hidden?"disabled":""} onChange={e=>onChangeInputHandler(e)}/>&nbsp;호
            </div>
          </div>
        </div>
      }
      
      {/* 토지제외 */}
      {varProductData.rp_serious === 9 ? null:
        <div className="form_wrap2">
          <div className="tit07_2">건축물 용도<span className="star_red"> (필수)</span></div>
          <select name="rp_purpose" className="one-line" value={varProductData.rp_purpose} onChange={e=>onChangeSelectHandler(e)}>
            <option value={0}>선택</option>
            {varBuildingPurpose.map((purpose,i)=>(
              <option key={'purpose_'+i} value={purpose.vpp_idx}>{purpose.vpp_name}</option>
            ))}
          </select>
        </div>
      }
      {varProductData.rp_serious === 6 ?  /* 상가 */
        <>
          <div className="form_wrap">
            <div className="tit07_1">업종</div>
            <ul className="dan3">
              {varSecterList.map((sectors,i)=>(
                <li className="terms_chks" key={'sectors_'+i}>
                  <input type="checkbox" name="rp_sector" id={"sectors_"+i} className="chk1" value={sectors.vfo_name} 
                        checked={!isNull(varProductData.rp_sector) && varProductData.rp_sector.includes(sectors.vfo_name)} 
                        onChange={e=>onSelectOptionHandlerOne(e)}/>
                  <i></i>
                  <label htmlFor={"sectors_"+i}>{sectors.vfo_name}</label>
                </li>
              ))}
            </ul>
          </div>
          <div className="form_wrap">
            <div className="tit07_1">종목</div>
            <ul className="dan3">
              {varItemsList.map((items,i)=>(
                <li className="terms_chks" key={'items_'+i}>
                  <input type="checkbox" name="rp_items" id={"items_"+i} className="chk1" value={items.vfi_name} 
                        checked={!isNull(varProductData.rp_items) && varProductData.rp_items.includes(items.vfi_name)} 
                        onChange={e=>onSelectOptionHandlerOne(e)}/>
                  <i></i>
                  <label htmlFor={"items_"+i}>{items.vfi_name}</label>
                </li>
              ))}
            </ul>
          </div>
        </>
      :varProductData.rp_serious === 7 ?  /* 사무실 */
        <div className="form_wrap">
          <div className="tit07_1">테마</div>
          <ul className="dan3">
            {varThemeList.map((theme,i)=>(
              <li className="terms_chks" key={'theme_'+i}>
                <input type="checkbox" name="rp_theme" id={"theme_"+i} className="chk1" value={theme.vfo_name} 
                      checked={!isNull(varProductData.rp_theme) && varProductData.rp_theme.includes(theme.vfo_name)} 
                      onChange={e=>onSelectOptionHandler(e)}/>
                <i></i>
                <label htmlFor={"theme_"+i}>{theme.vfo_name}</label>
              </li>
            ))}
          </ul>
        </div>
      :varProductData.rp_serious === 8 || varProductData.rp_serious === 9 || varProductData.rp_serious === 10 ?  /* 빌딩,토지,공장 */
        <div className="form_wrap2">
          <div className="tit07_2">용도지역<span className="star_red"> (필수)</span></div>
          <select name="rp_purpose_region" className="one-line" value={varProductData.rp_purpose_region} onChange={e=>onChangeSelectHandler(e)}>
            <option value={0}>선택</option>
            {varPurposeRegions.map((purpose_region,i)=>(
              <option key={'purpose_region_'+i} value={purpose_region.vua_idx}>{purpose_region.vua_name}</option>
            ))}
          </select>
        </div>
        :null
      }
      {varProductData.rp_serious === 9 ?  /* 토지 */
        <div className="form_wrap2">
          <div className="tit07_2">지목<span className="star_red"> (필수)</span></div>
          <select name="rp_purpose_land" className="one-line" value={varProductData.rp_purpose_land} onChange={e=>onChangeSelectHandler(e)}>
            <option value={0}>선택</option>
            {varPurposeLands.map((purpose_land,i)=>(
              <option key={'purpose_land_'+i} value={purpose_land.vjs_idx}>{purpose_land.vjs_name}</option>
            ))}
          </select>
        </div>
      :varProductData.rp_serious === 10 ?  /* 공장 */
        <div className="form_wrap2">
          <div className="tit07_2">건축구조<span className="star_red"> (필수)</span></div>
          <select name="rp_bd_structure" className="one-line" value={varProductData.rp_bd_structure} onChange={e=>onChangeSelectHandler(e)}>
            <option value={0}>선택</option>
            {varBuildingStructure.map((bdstructure,i)=>(
              <option key={'bdstructure_'+i} value={bdstructure.vst_idx}>{bdstructure.vst_name}</option>
            ))}
          </select>
        </div>
      :null
      }
      {/* 우편번호/주소 검색*/}
      <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler}/>

    </>
  );
}
export default DetailAddress