import React from "react";
import { Link } from "react-router-dom";
/* import axios from 'axios';
import CONFIG from '../../../configs/Configs'; */

import {ChangeDateStringOnlyDate,ChangeMobileFormat,MaskingString,MaskingEmail,ResetCheckBoxes} from "../../../components/common/Common";

function MemberSearchResult({memMode,varMaxNumPage,varMemberLists,setMemberLists,onDeleteMemberHandler}) {

  //목록의 모든 항목 선택하기
  const onCheckedAll = (e) => {
    const bChecked = Number(e.currentTarget.checked);
    clearBoardLists(bChecked);
  } 
  //체크박스 초기화
  const clearBoardLists = (bmode) => {
    const checkBoxes = document.querySelectorAll('.del-item');
    for(var i=0; i < checkBoxes?.length; i++) {
      checkBoxes[i].checked = bmode;
    }
    //배열값 변경
    let copyDatas = [...varMemberLists];
    for (var j=0; j < copyDatas?.length; j++) {
      copyDatas[j].checked = bmode;
    }
    setMemberLists(copyDatas);
  }
  //목록상의 항목 선택하기
  const onCheckedElement = (e,mem_idx) => {
    const bChecked = Number(e.currentTarget.checked);
    const findIndex = varMemberLists.findIndex(element=>element.mem_idx===mem_idx);
    let copyLists = [...varMemberLists];
    copyLists[findIndex].checked = bChecked;
    setMemberLists(copyLists);
    ResetCheckBoxes();
  } 

  return (
    <>
      <div className="main-box">
        <div className="btn-right">
          {Number(memMode)<=0?null:<Link to={`/Admin/Member/MemberDetail/${memMode}/0`} className="btn-danger">추가</Link>}
          {Number(memMode)<=0?null:<button onClick={(e)=>onDeleteMemberHandler(e,-1)} className="btn line">차단</button>}
          {Number(memMode)<=0?null:<button onClick={(e)=>onDeleteMemberHandler(e,0)} className="btn line">삭제</button>}
          {Number(memMode)>=0?null:<button onClick={(e)=>onDeleteMemberHandler(e,1)} className="btn line">차단해제</button>}
        </div>
        <table className="list">
          <colgroup>
          {Number(memMode)===0?null:<col style={{width:"5%"}} />}
            <col style={{width:"5%"}} />
            <col style={{width:"10%"}} />
          {Number(memMode)<=0?null:<col style={{width:"10%"}} />}
            <col style={{width:"20%"}} />
            <col style={{width:"15%"}} />
            <col style={{width:"10%"}} />
            <col style={{width:"10%"}} />
            {Number(memMode)<=1?null:<col style={{width:"10%"}} />}
            {Number(memMode)<=0?null:<col style={{width:"10%"}} />}
          </colgroup>
          <thead>
            <tr>
              {Number(memMode)===0?null:<th className="board-list"><input type="checkbox" id="deleteItems" onChange={(e)=>onCheckedAll(e)}/></th>}
              <th>번호</th>
              <th>회원명</th>
              {Number(memMode)<=0?null:Number(memMode)===1?<th>닉네임</th>:<th>회사명</th>}
              <th>회원ID(이메일)</th>
              <th>핸드폰번호</th>
              <th>가입일</th>
              <th>{Number(memMode)<=0?Number(memMode)===0?"탈퇴일":"차단일":"최근접속일"}</th>
              {Number(memMode)<=1?null:<th>승인여부</th>}
              {Number(memMode)<=0?null:<th>관리</th>}
            </tr>
          </thead>
          <tbody>
            {varMemberLists?.length === 0? null:
            varMemberLists.map((member, i) => (
              <tr key={'member_'+member.mem_idx} className={member.mem_warning>0?"warning":""}>
                {Number(memMode)===0?null:<td className="board-list">
                  <input type="checkbox" className={"del-item"} id={"deleteItem_" + member.mem_idx} onChange={(e)=>onCheckedElement(e,member.mem_idx)}/>
                </td>}
                <td>{varMaxNumPage - i}</td>
                <td className="ellipsis">{Number(memMode)<=0?MaskingString(member.mem_name,2,'*'):member.mem_name}</td>
                {Number(memMode)<=0?null:Number(memMode)===1?<td className="ellipsis">{member.mem_nickname}</td>:<td className="ellipsis">{member.mcm_name}</td>}
                <td className="ellipsis">{Number(memMode)<=0?MaskingEmail(member.mem_email):member.mem_email}</td>
                <td className="ellipsis">{Number(memMode)<=0?MaskingString(ChangeMobileFormat(member.mem_mobile),9,'*'):ChangeMobileFormat(member.mem_mobile)}</td>
                <td className="ellipsis">{ChangeDateStringOnlyDate(member.mem_rdate)}</td>
                {Number(memMode)<=0?
                  <td className="ellipsis">{ChangeDateStringOnlyDate(member.mem_edate)}</td>
                :
                  <td className="ellipsis">{ChangeDateStringOnlyDate(member.mem_ldate)}</td>
                }
                {Number(memMode)<=1?null:<td className="ellipsis">{member.approval}</td>}
                {Number(memMode)<=0?null:<td>
                  <Link to={`/Admin/Member/MemberDetail/${memMode}/${member.mem_idx}`} style={{width:"80px"}} className="btn line black">세부정보</Link>
                </td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default MemberSearchResult;