import React, { useState,useEffect } from "react"; 
import { Link } from "react-router-dom";

import axios from 'axios';
import CONFIG from '../../configs/Configs';


import { CheckLoginStateOnly,isNull } from "../common/Common";

import menu01Image from '../../images/menu01.svg';
import menu02Image from '../../images/menu02.svg';
import menu03Image from '../../images/menu03.svg';
import menu04Image from '../../images/menu04.svg';
import menu05Image from '../../images/menu05.svg';

import menu01onImage from '../../images/menu01-on.svg';
import menu02onImage from '../../images/menu03-on.svg';
import menu03onImage from '../../images/menu02-on.svg';
import menu04onImage from '../../images/menu04-on.svg';
import menu05onImage from '../../images/menu05-on.svg';


const Footer = ({ varPageMode }) => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const [varChattingYet,setChattingYet] = useState(0);

  //로그인 점검 - 채팅불가
  const loginState = CheckLoginStateOnly();

  //채팅방 목록 읽어오기
  const GetChattingRoomList = async () => {
    const sendData = {mem_mode:mem_mode,mem_idx:mem_idx,ch_mode:1};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_chatroom_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
        const tcount = datas.doongjiCount + datas.jangterCount;
        setChattingYet(tcount);
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    GetChattingRoomList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mobile-bottom-menu mobile">
          <Link to="/Doongji/DoongjiList" className={varPageMode===1?"on":""}><img src={menu01onImage} className="on" alt=""/><img src={menu01Image} alt=""/>둥지</Link>
          <Link to="/Jangter" className={varPageMode===2?"on":""}><img src={menu02onImage} className="on" alt=""/><img src={menu02Image} alt=""/>장터</Link>
          <Link to={loginState?"/Jjim/JjimDoongjiList":"/Login"} className={varPageMode===3?"on":""}>
            <img src={menu03onImage} className="on" alt=""/><img src={menu03Image} alt=""/>찜목록</Link>
          <Link to={loginState?"/Chat/ChatDoongjiList":"/Login"} className={varPageMode===4?"on":""}>
            <img src={menu04onImage} className="on" alt=""/><img src={menu04Image} alt=""/>채팅
            {isNull(varChattingYet)?null:<div className="readyet"><span>{varChattingYet}</span></div>}
          </Link>
          <Link to={loginState?"/MyPage":"/Login"} className={varPageMode===5?"on":""}><img src={menu05onImage} className="on" alt=""/><img src={menu05Image} alt=""/>MY</Link>
      </div>
      <div className="clr"></div>
    </>
  );
}
export default Footer;