/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ useState,useEffect } from "react"; 
import { Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import { addThousandPoint } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

const TempRegisteredDoongjiList = () => {
  const mem_idx = localStorage.getItem("mem_idx");

  //검색된 매물목록 관련
  //const [varTotalCount,setTotalCount] = useState(0);
  const [varDoongjiList,setDoongjiList] = useState([]);

  //둥지매물 검색후 목록 가져오기
  const getTmpDoongjiList = async() => {
    const sendData = {mem_idx:mem_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_tmp_doongji_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //검색된 전체수량
        //setTotalCount(datas.totalcount);
        //검색된 목록
        setDoongjiList(datas.results);
      }
    });
  }

  useEffect(() => {
    getTmpDoongjiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [backString, setBackString] = useState('/Doongji/DoongjiList'); // 추가된 상태

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 750) {
        setBackString('nonWebDoongji');
      } else {
        setBackString('/Doongji/DoongjiList');
      }
    };

    handleResize(); // 초기 렌더링 시 상태 설정
    window.addEventListener('resize', handleResize); // 창 크기 변경 감지

    return () => window.removeEventListener('resize', handleResize); // 정리 작업
  }, []);


  const onDeleteProductHandler = async (e,rp_idx) => {
    e.preventDefault();
    if(!window.confirm('선택하신 임시저장매물을 삭제합니까?')) {
      return false;
    }
    const sendData = {rp_idx:rp_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_doongji_product`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        var copyList = varDoongjiList.filter(element=>element.rp_idx!==rp_idx);
        setDoongjiList(copyList);
      } else {
        alert(datas.message);
      }
    });
  }
  console.log(varDoongjiList);
  return varDoongjiList===undefined ? null: (
    <>
      <Header/>
      <HeadTitle titleString={"임시 저장 리스트"} backString={backString}/>
      <div id="content">
        <div className="storage_wrap">
          {varDoongjiList?.length===0?
            <div className="strg_box"><div className="c_wrap" style={{textAlign:"center"}}>임시로 저장된 매물이 없습니다.</div></div>
          :varDoongjiList.map((product,i)=>(
            <div className="strg_box" key={"product_"+i}>
              <div className="del_btn" onClick={e=>onDeleteProductHandler(e,product.rp_idx)}>삭제</div>
              <Link to={"/Doongji/DoongjiDetail/"+product.rp_idx}>
                <div className="tit03">{product.rp_title}</div>
                <div className="c_wrap" style={{borderRadius:"0px"}}>
                  <div className="info01">
                    <div className="c_dong"><span>{product.rp_dongname}</span></div>&nbsp;
                    <div className="c_type"><span>{product.serious}</span></div>&nbsp;
                    <div className="c_price">
                      <span className="c_rent_type">{product.types}</span>&nbsp;
                      <span className="price">{addThousandPoint(product.rp_deposit)+'/'+product.rp_monthly+'/'+product.rp_admin_expense}</span>
                    </div>
                  </div>
                  <div className="c_hash">
                    <ul>
                      {product.rp_serious<=5 ?
                        <li className="c_option"><span>방{product.rp_rooms}</span></li>
                      :product.rp_serious===6||product.rp_serious===7 ?
                        <li className="c_option"><span>룸{product.rp_rooms}</span></li>
                      :null}
                      <li className="c_option"><span>{product.rp_sale_area}</span></li>
                      {product.rp_serious===9 ? null
                      :product.rp_exist_floor_type===0?
                        <li className="c_option"><span>{product.rp_exist_floor}층</span></li>
                      : <li className="c_option">
                          <span>
                            {product.rp_exist_floor_type===1?"건물전체"
                            :product.rp_exist_floor_type===2?"지하"
                            :product.rp_exist_floor_type===3?"반지하"
                            :product.rp_exist_floor_type===4?"옥탑"
                            :null
                            }
                          </span>
                        </li>
                      }
                      {product.rp_serious===9 ? null
                      :product.rp_serious<=5 ?
                        <li className="c_option"><span>{product.structure}</span></li>
                      :product.rp_terrace===0 ? null:
                        <li className="c_option"><span>테라스</span></li>
                      }
                      {product.rp_serious>5 || product.rp_can_pets===0 ? null
                      :product.rp_can_pets===1 ? <li className="c_option"><span>반려동물</span></li>
                      :<li className="c_option"><span>반려동물협의</span></li>
                      }
                      {product.rp_serious===9 || product.rp_elevator===0 ? null:
                        <li className="c_option"><span>엘리베이터</span></li>
                      }
                    </ul>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>    
      </div>
    </>
  );
}
export default TempRegisteredDoongjiList;