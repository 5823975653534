import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import AgencyAdminList from './AgencyAdminList';
import AgencyAdminDetail from './AgencyAdminDetail';

function AgencyAdminState({setMenuItem}) {
  //지역 관리자 목록 정보 저장 변수 
  const [varAgencyAdminLists, setAgencyAdminLists] = useState([]);
  //현재 선택된 지역 관리자 고유번호 저장 변수
  const [varCurrentAgencyAdmin,setCurrentAgencyAdmin] = useState(-1);
  //지역 저장 변수
  const [varRegionList,setRegionList] = useState([]);
  //지역 관리자 정보 변경 페이지 표시 유무
  const [varShowDetailPage,setShowDetailPage] = useState(false);
  //등록된 지역관리자 수
  const [varTotalList, setTotalList] = useState(0);

  //지역관리자 목록 가져오기
  const getAgencyAdminList = async () => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_agencyadmin_list`).then(res => {
      console.log(res.data);
      if(res.data.retvalue === 1) {
        //지역목록
        setRegionList(res.data.regions);
        if(res.data.results?.length > 0) {
          //지역관리자 수
          setTotalList(res.data.results.length);
          //지역관리자 목록
          setAgencyAdminLists(res.data.results);
        } else {
          setTotalList(0);
          setAgencyAdminLists([]);
        }
      } else {
        setTotalList(0);
        setAgencyAdminLists([]);
      }
    })
  };

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('AgencyAdminState');
    getAgencyAdminList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div id="contents" className="ad_contents">
        <div className="tit">
          <div className="title">지역 관리자 관리</div>
          <div className="path">
            <img src={iconhome} alt=""/>
            HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt=""/>운영 계정 관리<img src={arrImage} alt=""/>지역 관리자 관리
          </div>
        </div>
        <AgencyAdminDetail varShowDetailPage={varShowDetailPage} setShowDetailPage={setShowDetailPage} 
            varRegionList={varRegionList} varCurrentAgencyAdmin={varCurrentAgencyAdmin} 
            varAgencyAdminLists={varAgencyAdminLists} getAgencyAdminList={getAgencyAdminList} />
        <AgencyAdminList setShowDetailPage={setShowDetailPage} varTotalList={varTotalList}
            setCurrentAgencyAdmin={setCurrentAgencyAdmin} setRegionList={setRegionList}
            varAgencyAdminLists={varAgencyAdminLists} setAgencyAdminLists={setAgencyAdminLists} />
      </div>
  )
}

export default AgencyAdminState;