/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react"; 

const { kakao } = window;
//카카오 지도 호출
const KakaoMap = ({varCenterPoint, varAddress, onOpenMapLocationHandler}) => {
  var map = null;

  const setKakaoMap = (lat,lng) => {
    var mapContainer = document.getElementById('map'); // 지도를 표시할 div 
    var coords = new kakao.maps.LatLng(lat,lng);
    var mapOption = { 
        center: coords, // 지도의 중심좌표
        level: 3 // 지도의 확대 레벨
    };
    map = new kakao.maps.Map(mapContainer, mapOption);
    var zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
    var marker = new kakao.maps.Marker({
      map: map,
      position: coords
    });
    // 인포윈도우로 장소에 대한 설명을 표시합니다
    var infowindow = new kakao.maps.InfoWindow({
      content: `<div style="width:150px;text-align:center;padding:6px 10px;">${varAddress}</div>`
    });
    infowindow.open(map, marker);
    map.setCenter(coords);
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(varCenterPoint !== "") {
      var cenArray = varCenterPoint.split(',');
      var rlat = parseFloat(cenArray[0]);
      var rlng = parseFloat(cenArray[1]);
      setKakaoMap(rlat,rlng);
    } else if(varAddress !== "") {
      var geocoder = new kakao.maps.services.Geocoder();
      geocoder.addressSearch(varAddress, function (result, status) {
        if (status === kakao.maps.services.Status.OK) {
          var glat = result[0].y;
          var glng = result[0].x;
          setKakaoMap(glat,glng);
        } else {
          alert('저장된 주소로 위치를 특정할 수 없습니다.'); return null;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{position:"relative"}}>
      <div id="map" className="map-base"></div>
      <div className="map-overpage" onClick={e=>onOpenMapLocationHandler(e,true)}></div>
    </div>
  );
}
export default KakaoMap;