/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 

const PhotoEmptyForm = ({onSelectedPhotoHandler}) => {
  
  return (
    <div className="e_wrap">
      <div className="img_upload_area">
        <div className="img_upload">
          <input type="file" name="files" className="fileUpload" multiple accept="image/*" onChange={e=>onSelectedPhotoHandler(e)}/>
          <div className="prev_img"></div>
        </div>
      </div>
    </div>
  );
}
export default PhotoEmptyForm;