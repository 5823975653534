/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import CONFIG from '../../configs/Configs';
import { GetPhotoImage } from "../common/Common";
import removeImage from "../../images/icon_del_btn.png";

const PhotoExistForm = ({photo,varMode,onDeleteProductPhotoHandler,onShowBigSizePhotoHandler}) => {
  //매물사진저장위치
  let photoMode = "";
  if(varMode === 1) photoMode = 'doongji';
  else if(varMode === 2 || varMode === 22) photoMode = 'jangter';
  else photoMode = 'request';
  const photoImage = `${CONFIG.SERVER_HOST}/`+GetPhotoImage(photo.file_name,photoMode);

  return (
    <div className="e_wrap">
      <div className="image-block-area">
        <div className="image-box">
          <img className="btn-delete-image" src={removeImage} alt="" onClick={(e)=>onDeleteProductPhotoHandler(e,photo)}/>
          <img className="request-image" src={photoImage} alt="" onClick={(e)=>onShowBigSizePhotoHandler(e,photoImage)}/>
        </div>
      </div>
    </div>
  );
}
export default PhotoExistForm;