/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react"; 
import { AddOptionToString,ExceptOptionToString } from "../common/Common";
import { isObjEmpty,isNull } from "../common/Common";

const DetailOptions = ({varProductData,setProductData,varIncludeOptions,onChangeInputHandler}) => {

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(varProductData.rp_options === null) {
      setProductData({...varProductData,rp_options:""});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //선택한 옵션을 문자열로 조정한후에 변수에 저장
  const onSelectOptionHandler = (e) => {
    const { value } = e.currentTarget;
    const bmode = e.currentTarget.checked;
    if(bmode) {
      if(varProductData.rp_options===null || varProductData.rp_options === "" || !varProductData.rp_options.includes(value)) {
        const newString = AddOptionToString(value,varProductData.rp_options,',');
        setProductData({...varProductData,rp_options:newString});
      }
    } else {
      if(varProductData.rp_options.includes(value)) {
        const newString = ExceptOptionToString(value,varProductData.rp_options,',');
        setProductData({...varProductData,rp_options:newString});
      }
    }
  }


  return isObjEmpty(varProductData) ? null : (
    <div className="form_wrap">   
      {varProductData.rp_serious === 9 ? null : (
      <>
        <div className="tit07_1">옵션</div>
        {varProductData.rp_serious <= 5 ?  /* 주거용일 경우 */
          <ul className="dan3">
            {varIncludeOptions.map((options,i)=>(
              <li className="terms_chks" key={'option_'+i}>
                <input type="checkbox" name="rp_options" id={"option_"+i} className="chk1" value={options.vpo_name} 
                      defaultChecked={isNull(varProductData.rp_options)?false:varProductData.rp_options.split(',').includes(options.vpo_name)} 
                      onChange={e=>onSelectOptionHandler(e)}/>
                <i></i>
                <label htmlFor={"option_"+i}>{options.vpo_name}</label>
              </li>
            ))}
          </ul>
        :
          <textarea name="rp_options" cols="30" rows="5" style={{maxWidth:'100%',minWidth:'100%',width:'100%'}} 
              placeholder="직접 입력해주세요." defaultValue={varProductData.rp_options||""} onChange={e=>onChangeInputHandler(e)}/>
        }
      </>
    )}
    </div>
  );
}
export default DetailOptions;