/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import {CopyToClipboard} from "react-copy-to-clipboard/src";

import Header from "../../components/include/Header";

import { isObjEmpty } from "../../components/common/Common";

import LogoIcon from '../../images/logoDoongji.png';
import LoginIDIcon from '../../images/icon_id.png';
import LoginPWIcon from '../../images/icon_pw.png';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";

const Login = () => {
  if(localStorage.getItem("memberLoginState") === undefined) {
    localStorage.setItem("memberLoginState",0);
  }
  if(localStorage.getItem("autoLogin") === undefined) {
    localStorage.setItem("autoLogin",0);
  }
  const loginState = Number(localStorage.getItem("memberLoginState"));
  const autoLogin = Number(localStorage.getItem("autoLogin"));
  const [varLoginInfo,setLoginInfo] = useState({login_id:"",login_pwd:"",autologin:0});

  const regionStorage = localStorage.getItem('varSearchRegionList');
  if(regionStorage !== undefined && regionStorage !== null && regionStorage !== "") {
    localStorage.removeItem('varSearchRegionList');
  }

  const refID = useRef()
  const refPwd = useRef();
  const refLogin = useRef();

  //입력값 저장
  const onChangeInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    setLoginInfo({...varLoginInfo,[name]:value});
  }
  //자동로그인
  const onSetAutoLoginHandler = (e) => {
    const bmode = Number(e.currentTarget.checked);
    setLoginInfo({...varLoginInfo,autologin:bmode});
    localStorage.setItem("autoLogin",bmode);
  }
  //로그인 작업 수행
  const onLoginProgressHandler =  (e) => {
    if(varLoginInfo.login_id === "") {
      alert('로그인하기 위한 아이디(이메일)을 먼저 입력하세요.');
      return false;
    }
    if(varLoginInfo.login_pwd === "") {
      alert('로그인하기 위한 비밀번호를 먼저 입력하세요.');
      return false;
    }
    const mem_device = localStorage.getItem('fcm_token');
    const sendData = {login_mode:0,loginInfo:varLoginInfo,mem_device:mem_device};
    loginProgressing(sendData);
  }
  const loginProgressing = async (sendData) => {
    await axios.post(`${CONFIG.SERVER_HOST}/_login_progress`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        const members = datas.results[0];
        //차단한 회원인 경우
        if(members.mem_status === -1) {
          alert('이용이 중지된 계정입니다.');
          return false;
        }
        //로그인 절차
        if(members.mem_mode === 0) {
          //자동로그인
          localStorage.setItem("mem_idx", members.mem_idx);
          localStorage.setItem("mcm_idx", 0);
          localStorage.setItem("mem_mode", 0);
          localStorage.setItem("mem_nickname", members.mem_nickname);
          localStorage.setItem("mcm_name", '');
        } else {
          if(members.mcm_approval === 0) {
            alert('아직 운영자로부터 승인을 받지 못했습니다.\n조금 더 기다리시거나 운영자에게 직접 연락하세요.'); return false;
          }
          localStorage.setItem("mem_idx", members.mem_idx);
          localStorage.setItem("mcm_idx", members.mcm_idx);
          localStorage.setItem("mem_mode", members.mem_mode);
          localStorage.setItem("mem_nickname", members.mcm_name);
          localStorage.setItem("mcm_name", members.mem_name);
        }
        const bcount = datas.bcount;
        //console.log('bcount',bcount);
        let bizString = "";
        for(var i=0; i < bcount; i++) {
          if(bizString === "") bizString = "0";
          else bizString += ",0";
        }
        //console.log('bizString',bizString);
        localStorage.setItem("businessOptions", bizString);
        localStorage.setItem("memberLoginState", 1);
        localStorage.setItem("mem_admin", 0);

        localStorage.removeItem('DoongjiSearchingString');
        localStorage.removeItem('DoongjiSearchRegionList');
        localStorage.removeItem('DoongjiFixedFilterList');
        localStorage.removeItem('DoongjiFixedFilterOptionList');
        localStorage.removeItem('DoongjiFilterOptions');
        localStorage.removeItem('DoongjiSortingList');
    
        localStorage.removeItem('JangterSearchingString');
        localStorage.removeItem('JangterSearchRegionList');
    
        localStorage.removeItem('PartnerSearchingString');
        localStorage.removeItem('PartnerSearchRegionList');

        localStorage.removeItem('scrollIndex');
        localStorage.removeItem('scrollTop');
        
        //페이지이동
        window.location.href = "/";
      } else {
        alert(datas.message);
      }
    });
  }

  //FCM TOKEN 메모리에 저장
  const eventFromAndroid = async (e) => {
    localStorage.setItem('fcm_token',e.detail.token);
  }
  //ios에 의해 호출되는 함수
  window.onFcmInfoSuccess = (token,os) => {
    localStorage.setItem('fcm_token',token);
  }

  //최초실행부
  useEffect(() => {
    //네이티브에 기기코드 요청
    const mobileType = navigator.userAgent.toLowerCase();
    //console.log(mobileType);
    if (mobileType.indexOf('android') > -1) {
      if(window.newproj) {
        window.addEventListener("onFcmInfoSuccess", eventFromAndroid);
        window.newproj.getFcmInfo();
      } 
    } else if (mobileType.indexOf('iphone') > -1 || mobileType.indexOf('ipad') > -1 || mobileType.indexOf('ipod') > -1) {
      if(window.webkit) window.webkit.messageHandlers.getFcmInfo.postMessage('');
    } 
    return () => {
      if(window.newproj){
        window.removeEventListener("onFcmInfoSuccess",eventFromAndroid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //autologin---------
  if(autoLogin===1 && loginState === 0) {
    if(localStorage.getItem("mem_idx") !== undefined && localStorage.getItem("mem_idx") !== null) {
      const idx = localStorage.getItem("mem_idx");
      const mem_device = localStorage.getItem('fcm_token');
      if(idx > 0) {
        const sendData = {login_mode:1,mem_idx:idx,mem_device:mem_device};
        loginProgressing(sendData);
      }
    }
  }
  //키보드
  const onKeyPressHandler = (e,ino) => {
    if (e.key === 'Enter') {
      if(ino === 0) {
        refPwd.current.focus();
      } else {
        refLogin.current.focus();
        refLogin.current.click();
      }
    }
  }

  //공유하기
  const onShareProductHandler = async(e) => {
    alert('클립보드로 복사되었습니다.');
  }

  return isObjEmpty(varLoginInfo)?null:(
    <>
      <Header/>
      <div className="login_wrap">
        <div className="login_box b_shadow">
          <div className="login_top">
            <p>빠르고 쉽게 찾는 부동산 플랫폼</p>
            <Link to={"/Main"}><h2><img src={LogoIcon} alt=""/></h2></Link>
          </div>
          <div className="login-mid">
            <div className="center_wrap">
              <div className="input_wrap">
                <img src={LoginIDIcon} alt=""/>
                <input type="text" name="login_id" ref={refID} value={varLoginInfo.login_id} placeholder="아이디(이메일)을 입력하세요" onKeyPress={e=>onKeyPressHandler(e,0)} onChange={e=>onChangeInputHandler(e)}/>
              </div>
              <div className="input_wrap">
                <img src={LoginPWIcon} alt=""/>
                <input type="password" name="login_pwd" ref={refPwd} value={varLoginInfo.login_pwd} placeholder="비밀번호를 입력하세요" onKeyPress={e=>onKeyPressHandler(e,1)} onChange={e=>onChangeInputHandler(e)}/>
              </div>
              <div className="terms_chks">
                <input type="checkbox" name="autologin" className="chk1" id="autologin" defaultChecked={varLoginInfo.autologin} onChange={e=>onSetAutoLoginHandler(e)}/>
                <i></i><label htmlFor="autologin">자동로그인</label>
              </div>
              <button className="yellow_btn" ref={refLogin} onClick={e=>onLoginProgressHandler(e)}>로그인</button>
              <div className="s-menu">
                <Link to="/Member/FindID">아이디 찾기</Link>
                <Link to="/Member/FindPWD">비밀번호 찾기</Link>
              </div>
            </div>
          </div>
          <div className="join_share_box" style={{paddingBottom:"10px"}}>
            <div className="center_wrap">
              <div className="box joincom">
                <div className="tit06">아직 둥지장터의 회원이 아니세요?</div>
                <div className="dan2">
                  <Link to="/JoinUs/JoinPartner/1" className="brown_btn">사업자 회원가입</Link>
                  <Link to="/JoinUs/JoinMember" className="yellow_btn">일반 회원가입</Link>
                </div>
              </div>
            </div>
            <div className="center_wrap" style={{marginTop:"10px"}}>
              <div className="box goshare">
                  <div className="tit06">둥지장터 앱을 공유해보세요!</div>
                  <CopyToClipboard text={`${CONFIG.PAYMENT_HOST}/Main`}  onCopy={e=>onShareProductHandler(e)}>
                  <button style={{padding:"0px",width:"100%"}}>둥지장터 앱 공유하기</button>
                  </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;