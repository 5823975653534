/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import DoongjiList from "../../components/products/DoongjiList";
import JangterList from "../../components/products/JangterList";
import PartnerList from "../../components/products/PartnerList";
import ReportList from "../../components/products/ReportList";

import DoongjiDetail from "../../components/products/DoongjiDetail";
import JangterDetail from "../../components/products/JangterDetail";
import PartnerDetail from "../../components/products/PartnerDetail";
import ReportDetail from "../../components/products/ReportDetail";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import "../../admin_css/board.css";

/* 요금/이용권 관리 */
const ProductList = () => {
  //메뉴항목지정
  const [varMenuItem,setMenuItem] = useState('');

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'PRODUCTS'}/>
      <Menus varMenuType={'PRODUCTS'} varMenuItem={varMenuItem}/>
      <Routes>
        {/* 둥지 매물 리스트 */}
        <Route path="DoongjiList" element={<DoongjiList setMenuItem={setMenuItem}/>} />
        {/* 장터 게시 리스트 */}
        <Route path="JangterList" element={<JangterList setMenuItem={setMenuItem}/>} />
        {/* 파트너스 게시 리스트 */}
        <Route path="PartnerList" element={<PartnerList setMenuItem={setMenuItem} />} />
        {/* 신고 리스트 */}
        <Route path="ReportList" element={<ReportList setMenuItem={setMenuItem} />} />
        {/* 둥지 매물 세부 정보 관리 */}
        <Route path="DoongjiDetail/:rp_idx" element={<DoongjiDetail setMenuItem={setMenuItem} />} />
        {/* 장터 게시 세부 정보 관리 */}
        <Route path="JangterDetail/:jt_idx" element={<JangterDetail setMenuItem={setMenuItem} />} />
        {/* 파트너스 게시 세부 정보 관리 */}
        <Route path="PartnerDetail/:jp_idx" element={<PartnerDetail setMenuItem={setMenuItem} />} />
        {/* 신고 세부 정보 관리 */}
        <Route path="ReportDetail/:pr_idx" element={<ReportDetail setMenuItem={setMenuItem} />} />
      </Routes>
    </>
  );
}
export default ProductList;

