/* eslint-disable no-unused-vars */
import React, { useState,useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { isNull } from "./components/common/Common";
//관리자
import MainAdmin from "./admin/pages/MainAdmin";
import LoginAdmin from "./admin/pages/members/LoginAdmin";

import Environment from "./admin/pages/environment/Environment";
import Company from "./admin/pages/environment/Company";
import Variables from "./admin/pages/environment/Variables";
import Terms from "./admin/pages/environment/Terms";

import AdminMember from "./admin/pages/members/AdminMember";

import ProductList from "./admin/pages/products/ProductList";
import ProductAdvertise from "./admin/pages/products/ProductAdvertise";
import ProductService from "./admin/pages/products/ProductService";

import RatePlan from "./admin/pages/payments/RatePlan";
import Payments from "./admin/pages/payments/Payments";
import Boards from "./admin/pages/boards/Boards";

import AdminBoard from "./admin/pages/boards/AdminBoard";
import AdminBoardDetail from "./admin/pages/boards/AdminBoardDetail";
import AdminQuestion from "./admin/pages/boards/AdminQuestion";
import AdminQuestionDetail from "./admin/pages/boards/AdminQuestionDetail";

//사용자
import Main from "./pages/Main";
import Login from "./pages/members/Login";
import JoinUs from "./pages/members/JoinUs";
import Member from "./pages/members/Member"; 

import JjimDoongjiList from "./pages/jjim/JjimDoongjiList";
import JjimJangterList from "./pages/jjim/JjimJangterList";

import ChatDoongjiList from "./pages/chat/ChatDoongjiList";
import ChatJangterList from "./pages/chat/ChatJangterList";
import ChatRoom from "./pages/chat/ChatRoom";

import DoongjiList from "./pages/doongji/DoongjiList";
import DoongjiView from "./pages/doongji/DoongjiView";
import DoongjiDetail from "./pages/doongji/DoongjiDetail";

import TempRegisteredDoongjiList from "./pages/doongji/TempRegisteredDoongjiList";
import RegisteredDoongjiList from "./pages/doongji/RegisteredDoongjiList";
import NearRegisteredDoongjiList from "./pages/doongji/NearRegisteredDoongjiList";

import DoongjiSerious from "./pages/doongji/DoongjiSerious";
import DoongjiSeriousOK from "./pages/doongji/DoongjiSeriousOK";
import DoongjiProfessional from "./pages/doongji/DoongjiProfessional";
import DoongjiFinding from "./pages/doongji/DoongjiFinding";
import DoongjiManagement from "./pages/doongji/DoongjiManagement";
import DoongjiPartner from "./pages/doongji/DoongjiPartner";

import Jangter from "./pages/jangter/Jangter";
import PartnerList from "./pages/jangter/PartnerList";
import PartnerView from "./pages/jangter/PartnerView";
import RegisterPartner from "./pages/jangter/RegisterPartner";
import RegisteredJangterList from "./pages/jangter/RegisteredJangterList";

import JangterList from "./pages/jangter/JangterList";
import JangterView from "./pages/jangter/JangterView";
import JangterDetail from "./pages/jangter/JangterDetail";
import JangterRegisterComplete from "./pages/jangter/JangterRegisterComplete";
import JangterEditComplete from "./pages/jangter/JangterEditComplete";


import RegisterPremium from "./pages/common/RegisterPremium";
import RequestPremium from "./pages/common/RequestPremium";

import MyPage from "./pages/mypage/MyPage";
import MyInfo from "./pages/mypage/MyInfo";
import MyPartnerInfo from "./pages/mypage/MyPartnerInfo";

import Settings from "./pages/mypage/Settings";
import NoticeList from "./pages/mypage/NoticeList";
import FAQList from "./pages/mypage/FAQList";
import OneToOneList from "./pages/mypage/OneToOneList";

import PremiumList from "./pages/mypage/PremiumList";
import PaymentList from "./pages/mypage/PaymentList";
import PremiumDetail from "./pages/mypage/PremiumDetail";
import PremiumView from "./pages/mypage/PremiumView";
import PaymentComplete from "./pages/mypage/PaymentComplete";
import BuyVoucher from "./pages/mypage/BuyVoucher";
import VoucherView from "./pages/mypage/VoucherView";

import TossPayment from "./pages/payment/TossPayment";
import SuccessToss from "./pages/payment/SuccessToss";
import FailToss from "./pages/payment/FailToss";

import RealEstateAgent from "./pages/realtor/RealEstateAgent";
import RealEstate from "./pages/realtor/RealEstate";

function App() {
  //login 상태 점검
  const [isLoginAdmin, setIsLoginAdmin] = useState(false);
  const [isLoginUser, setIsLoginUser] = useState(false);

  useEffect(() => {
    const adminLoginState =  localStorage.getItem("adminLoginState");
    if (!isNull(adminLoginState) && Boolean(adminLoginState)) {
      setIsLoginAdmin(true);
    } else {
      setIsLoginAdmin(false);
    }

    const memberLoginState =  localStorage.getItem("memberLoginState");
    if (!isNull(memberLoginState) && Boolean(memberLoginState)) {
      setIsLoginUser(true);
    } else {
      setIsLoginUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginAdmin, isLoginUser]);   
  


  return (
    <Router>
      {/* 관리페이지들 */}
      <Routes>
        <Route path="/Admin" element={isLoginAdmin ? <MainAdmin /> : <LoginAdmin />} />
        <Route path="/Admin/Login" element={<LoginAdmin />} />

        <Route path="/Admin/Environment/*" element={isLoginAdmin ? <Environment /> : <LoginAdmin />} />
        <Route path="/Admin/Company/*" element={isLoginAdmin ? <Company /> : <LoginAdmin />} />
        <Route path="/Admin/Variables/*" element={isLoginAdmin ? <Variables />: <LoginAdmin />} />
        <Route path="/Admin/Terms/*" element={isLoginAdmin ? <Terms />: <LoginAdmin />} />

        <Route path="/Admin/Member/*" element={isLoginAdmin ? <AdminMember />: <LoginAdmin />} />

        <Route path="/Admin/ProductList/*" element={isLoginAdmin ? <ProductList />: <LoginAdmin />} />
        <Route path="/Admin/ProductAdvertise/*" element={isLoginAdmin ? <ProductAdvertise />: <LoginAdmin />} />
        <Route path="/Admin/ProductService/*" element={isLoginAdmin ? <ProductService />: <LoginAdmin />} />

        <Route path="/Admin/RatePlan/*" element={isLoginAdmin ? <RatePlan />: <LoginAdmin />} />
        <Route path="/Admin/Payment/*" element={isLoginAdmin ? <Payments />: <LoginAdmin />} />
        <Route path="/Admin/Board/*" element={isLoginAdmin ? <Boards />: <LoginAdmin />} />

        <Route path="/Admin/AdminBoard/:adMode" element={isLoginAdmin ? <AdminBoard />: <LoginAdmin />} />
        <Route path="/Admin/AdminBoardDetail/:adMode/:board_idx" element={isLoginAdmin ? <AdminBoardDetail />: <LoginAdmin />} />
        <Route path="/Admin/AdminQuestion" element={isLoginAdmin ? <AdminQuestion />: <LoginAdmin />} />
        <Route path="/Admin/AdminQuestionDetail/:board_idx" element={isLoginAdmin ? <AdminQuestionDetail />: <LoginAdmin />} />
        
      {/* 사용자페이지들 */}
        <Route path="/" element={<Main />} />
        <Route path="/Main" element={<Main />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/JoinUs/*" element={<JoinUs />} />
        <Route path="/Member/*" element={<Member />} />

        <Route path="/Jjim/JjimDoongjiList" element={<JjimDoongjiList />}/>
        <Route path="/Jjim/JjimJangterList" element={<JjimJangterList />}/>

        <Route path="/Chat/ChatDoongjiList" element={<ChatDoongjiList />}/>
        <Route path="/Chat/ChatJangterList" element={<ChatJangterList />}/>
        <Route path="/Chat/ChatRoom/:ch_mode/:ch_idx" element={<ChatRoom />}/>
        
        <Route path="/MyPage/RegisterPremium/:rpa_mode/:ad_idx" element={<RegisterPremium/>}/>
        <Route path="/MyPage/RequestPremium" element={<RequestPremium/>}/>

        <Route path="/Doongji/DoongjiList" element={<DoongjiList/>}/>

        <Route path="/Doongji/DoongjiView/:rp_idx" element={isLoginUser? <DoongjiView/> : <Login />}/>
        <Route path="/Doongji/DoongjiDetail/:rp_idx" element={<DoongjiDetail/>}/>
        <Route path="/Doongji/TempRegisteredDoongjiList" element={<TempRegisteredDoongjiList/>}/>
        <Route path="/Doongji/RegisteredDoongjiList" element={<RegisteredDoongjiList/>}/>
        <Route path="/Doongji/NearRegisteredDoongjiList" element={<NearRegisteredDoongjiList/>}/>

        <Route path="/Doongji/DoongjiSerious" element={<DoongjiSerious/>}/>
        <Route path="/Doongji/DoongjiSeriousOK" element={<DoongjiSeriousOK/>}/>
        <Route path="/Doongji/DoongjiProfessional" element={<DoongjiProfessional/>}/>
        <Route path="/Doongji/DoongjiFinding" element={<DoongjiFinding/>}/>
        <Route path="/Doongji/DoongjiManagement" element={<DoongjiManagement/>}/>
        <Route path="/Doongji/DoongjiPartner" element={<DoongjiPartner/>}/>
        
        <Route path="/Jangter" element={<Jangter />}/>
        <Route path="/Jangter/PartnerList/:vbd_idx" element={<PartnerList />}/>
        <Route path="/Jangter/PartnerView/:jp_idx" element={isLoginUser? <PartnerView /> : <Login />}/>

        <Route path="/Jangter/JangterList/:jt_serious2" element={<JangterList />}/>
        <Route path="/Jangter/JangterView/:jt_idx" element={isLoginUser? <JangterView /> : <Login />}/>
        <Route path="/Jangter/JangterDetail/:jt_idx" element={<JangterDetail />}/>
        <Route path="/Jangter/JangterRegisterComplete/:jt_serious2" element={<JangterRegisterComplete />}/>
        <Route path="/Jangter/JangterEditComplete/:jt_serious2" element={<JangterEditComplete />}/>
        
        <Route path="/Jangter/RegisterPartner" element={<RegisterPartner />}/>
        <Route path="/Jangter/RegisteredJangterList" element={<RegisteredJangterList />}/>

        <Route path="/MyPage" element={<MyPage />}/>
        <Route path="/MyPage/MyInfo" element={<MyInfo />}/>
        <Route path="/MyPage/MyPartnerInfo" element={<MyPartnerInfo />}/>
        
        <Route path="/MyPage/Settings" element={<Settings />}/>
        <Route path="/MyPage/NoticeList" element={<NoticeList />}/>
        <Route path="/MyPage/FAQList" element={<FAQList />}/>
        <Route path="/MyPage/OneToOneList" element={<OneToOneList />}/>

        <Route path="/MyPage/PremiumList" element={<PremiumList />}/>
        <Route path="/MyPage/PaymentList" element={<PaymentList />}/>
        <Route path="/MyPage/PremiumDetail/:ph_idx" element={<PremiumDetail />}/>
        <Route path="/MyPage/PremiumView/:ph_idx" element={<PremiumView />}/>
        <Route path="/MyPage/PaymentComplete/:ph_idx" element={<PaymentComplete />}/>

        <Route path="/MyPage/BuyVoucher/:ph_idx" element={<BuyVoucher />}/>
        <Route path="/MyPage/VoucherView/:ph_idx" element={<VoucherView />}/>

        <Route path="/TossPayment/:ph_idx/:enCodedString" element={<TossPayment />}/>
        <Route path="/SuccessToss" element={<SuccessToss />}/>
        <Route path="/FailToss" element={<FailToss />}/>
        
        <Route path="/RealEstateAgent" element={<RealEstateAgent />}/>
        <Route path="/RealEstate" element={<RealEstate />}/>

      </Routes> 
    </Router>
  );
}

export default App;
