/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect,useCallback } from "react"; 
import { Link,useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";
import { isNull,remainDays } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import DoongjiProduct from "../../components/doongji/DoongjiProduct";
import DoongjiDelete from "../../components/popup/DoongjiDelete";
import DoongjiShow from "../../components/popup/DoongjiShow";

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const RegisteredDoongjiList = () => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const navigate = useNavigate();

  //검색된 매물목록 관련
  const [varAvailableCount,setAvailableCount] = useState(0);
  const [varRushSales,setRushSales] = useState(0);

  const [varDoongjiList,setDoongjiList] = useState([]);
  const [varCurrentDoongjiIndex,setCurrentDoongjiIndex] = useState(0);
  const [varDeleteMode,setDeleteMode] = useState(0);

  const [varRemainDays,setRemainDays] = useState(0);
  
  const [backString, setBackString] = useState('/Doongji/DoongjiList'); // 추가된 상태

  const location = useLocation(); 

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 750) {
        setBackString('nonWebDoongji');
      } else {
        setBackString('/Doongji/DoongjiList');
      }
      if(location.state?.mypage===true){
        setBackString('MyPage');
      }
    };

    handleResize(); // 초기 렌더링 시 상태 설정
    window.addEventListener('resize', handleResize); // 창 크기 변경 감지

    return () => window.removeEventListener('resize', handleResize); // 정리 작업
  }, [location.state?.mypage]);
  
  //둥지매물 검색후 목록 가져오기
  const getDoongjiMyList = useCallback(async() => {
    const sendData = {mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_doongji_my_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //검색된 게시중인 수량
        const showCount = datas.totalcount;
        //검색된 목록
        setDoongjiList(datas.results);
        //보유현황
        const accounts = datas.accounts;
        let rslot = accounts.doongji_slots;
        //이용권사용유효기간 검토
        if(!isNull(accounts.doongji_expdate)) {
          setRemainDays(remainDays(accounts.doongji_expdate));
          const rdays = remainDays(accounts.doongji_expdate);
          if(rdays < 0) rslot = 0;
        }
        //일반인은 무료 2개
        if(rslot === 0 && (accounts.mem_mode === 0 || accounts.mem_mode === 2)) rslot = 2;
        rslot = rslot - showCount;
        setAvailableCount(rslot);
        //console.log(rslot,showCount);
        //급매 수 제한
        setRushSales(datas.rushsales);
        // }
      }
    });
  }, [mem_idx]);

  useEffect(() => {
    getDoongjiMyList();
  }, [getDoongjiMyList]);


  
  //게시물 삭제
  const [varPopupDoongjiDelete,setPopupDoongjiDelete] = useState(false);
  const onDeleteDoongjiProductHandler = async (e,rp_idx,imode) => {
    e.preventDefault();
    setCurrentDoongjiIndex(Number(rp_idx));
    setDeleteMode(imode);
    onPopupWinHandler(true);
    setPopupDoongjiDelete(true);
  }
  const onPopupDoongjiDeleteHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupDoongjiDelete(false);
      getDoongjiMyList();
    } else {
      onPopupWinHandler(bmode);
      setPopupDoongjiDelete(bmode);
    }
  }
  const onPopupWinHandler = (bmode) => {
    //console.log(bmode);
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }

  //게시물 게시상태 변경
  const [varPopupDoongjiShow,setPopupDoongjiShow] = useState(false);
  const onShowDoongjiProductHandler = async(e,rp_idx,rp_show) => {
    console.log(rp_idx,rp_show);
    e.preventDefault();
    setCurrentDoongjiIndex(Number(rp_idx));
    onPopupWinHandler(true);
    setPopupDoongjiShow(true);
  }
  const onPopupDoongjiShowHandler = (e,bmode) => {
    e.preventDefault();
    onPopupWinHandler(bmode);
    setPopupDoongjiShow(bmode);
    if(!bmode) getDoongjiMyList();
  }

  //재등록
  const onReregisterDoongjiHandler = (e,rp_idx) => {
    if(varAvailableCount>0) {  //등록 가능
      navigate(`/Doongji/DoongjiDetail/${rp_idx}`);
      console.log("ReregiTrue",varAvailableCount);
    } 
    else {  //이용권구매
      if(mem_mode===0 || mem_mode===2) {
        alert('이미 2개의 둥지매물이 등록되어 있습니다. 무료로 등록할 수 있는 둥지매물은 2개까지 입니다.');
      }
      else if(varRemainDays < 0){
        alert('보유한 이용권이 없습니다. 이용권을 구매하세요');
        navigate('/MyPage/BuyVoucher/0');
        return false;
      } 
      else {
        alert('이미 게시된 매물들로 인하여 추가로 매물을 게시할 수 없습니다.');
        return false;
      }
    }
  }

  return (
    <>
      <Header/>
      <HeadTitle titleString={"내 등록 매물 보기"} backString={backString}/>
      <div className="layer-bg"></div>  
      <div id="content">
        <div className="like_list01_wrap">
          <div className="write_dungji_list_wrap">
            <div className="main_dunggi list_type01">
            {varDoongjiList?.length===0?
              <div className="list_con"><div className="c_wrap" style={{textAlign:"center"}}>등록된 둥지매물이 없습니다.</div></div>
            :varDoongjiList.map((product,i)=>(
              product.pr_status===3?    /* 블라인드 */
                <div className="list_con" key={'product_'+i}>
                  <DoongjiProduct product={product} varDoongjiList={varDoongjiList} setDoongjiList={setDoongjiList}/>
                </div>
              :
                <div className="list_con" key={'product_'+i}>
                  <DoongjiProduct product={product} varDoongjiList={varDoongjiList} setDoongjiList={setDoongjiList}/>
                  {product.rp_status===0?   /* 거래종료 */
                    <div className="btn_bottom one_btn con_btn">
                    {remainDays(product.rp_edate) > 0 ?  /* 게시기간 남아 있음 */
                      <div className="btn_style_gray2 w_state_open" style={{marginRight:"5px"}} onClick={e=>onShowDoongjiProductHandler(e,product.rp_idx,product.rp_show)}>{product.rp_show===2?"거래완료":"거래종료"}</div>
                    :
                      <>
                        <div className="btn_style_dark w_state_open" style={{marginRight:"5px"}}>{product.rp_show===1?"거래완료":"거래종료"}</div>
                        <button className="btn_style_yes3" onClick={e=>onReregisterDoongjiHandler(e,product.rp_idx)}>매물등록재개</button>
                      </>
                    }
                      <button className="btn_style_white w_del_open" onClick={e=>onDeleteDoongjiProductHandler(e,product.rp_idx,1)}>완전 삭제</button>
                    </div>
                  :
                    <div className="btn_bottom one_btn con_btn">
                      {remainDays(product.rp_edate) < 0 ?   /* 게시중 && 게시기간종료 */
                        <>
                          <button className="btn_style_dark w_state_open" style={{marginRight:"5px"}}>{product.rp_show===1?"거래완료":"거래종료"}</button>
                          <button className="btn_style_yes3" onClick={e=>onReregisterDoongjiHandler(e,product.rp_idx)}>매물등록재개</button>
                        </>
                      :
                        <>
                        <button className="btn_style_gray2 w_state_open" style={{marginRight:"5px"}} onClick={e=>onShowDoongjiProductHandler(e,product.rp_idx,product.rp_show)}>
                          {product.rp_show===0?"게시중지":product.rp_show===1?"거래완료":"게시중"}
                        </button>                   
                        {mem_mode===1 && product.rp_show===2?  /* 부동산파트너 && 게시중 */
                          isNull(product.rpa_idx)? 
                            <Link className="btn_style_premium" to={`/MyPage/RegisterPremium/1/${product.rp_idx}`}>프리미엄 등록</Link>
                            :
                            <Link className="btn_style_premium" to={`/MyPage/PremiumView/${product.ph_idx}`}>프리미엄 보기</Link>
                        :null} 
                        </>
                      }
                      <button className="btn_style_white w_del_open" onClick={e=>onDeleteDoongjiProductHandler(e,product.rp_idx,0)}>삭제</button>
                    </div>
                  }
                </div>
            ))}
            </div>
          </div>
        </div>
        {/* 팝업창 */}
        <DoongjiDelete varCurrentDoongjiIndex={varCurrentDoongjiIndex} varPopupDoongjiDelete={varPopupDoongjiDelete} 
                      varDeleteMode={varDeleteMode} onPopupDoongjiDeleteHandler={onPopupDoongjiDeleteHandler}/>
        <DoongjiShow varPopupDoongjiShow={varPopupDoongjiShow} varCurrentDoongjiIndex={varCurrentDoongjiIndex} 
                    varAvailableCount={varAvailableCount} setAvailableCount={setAvailableCount} varRushSales={varRushSales}
                    varDoongjiList={varDoongjiList} setDoongjiList={setDoongjiList} onPopupDoongjiShowHandler={onPopupDoongjiShowHandler}
                    varRemainDays={varRemainDays}/>
      </div>
    </>
  );
}
export default RegisteredDoongjiList;