/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import DoongjiSlider from '../doongji/DoongjiSlider';
import DoongjiTitle from '../doongji/DoongjiTitle';
import DoongjiPriceInfo from '../doongji/DoongjiPriceInfo';
import DoongjiProductInfo from '../doongji/DoongjiProductInfo';
import DoongjiDetailInfo from '../doongji/DoongjiDetailInfo';
import DoongjiOptionInfo from '../doongji/DoongjiOptionInfo';
import DoongjiLocationInfo from '../doongji/DoongjiLocationInfo';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

import closeButton from "../../images/icon_close.png";

const DoongjiPreview = ({varPreviewIdx,varPreview,onOpenPreviewHandler}) => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));

  const [varProductData,setProductData] = useState({});
  const [varProductPhoto,setProductPhoto] = useState([]);
  
  //빈 것
  const onPopupSupportMoneyHandler = (e,bmode) => {
  }

  //매물정보 읽어오기
  const getProductInformation = async () => {
    const sendData = {rp_idx:varPreviewIdx,mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_doongji_preview_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(datas.results?.length === 1) {
          //매물정보
          setProductData(datas.results[0]);
          //매물사진목록
          setProductPhoto(datas.photos);
        }
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    getProductInformation();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPreviewIdx]);

  return (
      <div className="layer-pop full_pop3" style={varPreview?{display:"block"}:{display:"none"}}>
        <div className="pop_head">
          <div className="layer_tit">미리보기</div>
          <div className="close_layer top_close_btn"><img src={closeButton} alt="" onClick={()=>onOpenPreviewHandler(false)}/></div>
        </div>
        <div className="view_wrap layer_inner" style={{overflowY:"scroll"}}>
          <DoongjiSlider varProductData={varProductData} varProductPhoto={varProductPhoto}/>
          <div className="mid_info_wrap">
            <DoongjiTitle varProductData={varProductData}/>
            <div className="center_wrap">
              <DoongjiPriceInfo varProductData={varProductData}/>
              <DoongjiProductInfo varProductData={varProductData}/>
              <DoongjiDetailInfo varProductData={varProductData} onPopupSupportMoneyHandler={onPopupSupportMoneyHandler}/>
              <DoongjiOptionInfo varProductData={varProductData}/>
              {varProductData.mcm_address1===null || (varProductData.mcm_address1==="" && varProductData.mcm_location === "") ? null: 
                <DoongjiLocationInfo varProductData={varProductData}/>
              }
            </div>
          </div>
        </div>
        <div className="btn_bottom one_btn">
          <button className="btn_style_yes" onClick={()=>onOpenPreviewHandler(false)}>닫기</button>
        </div>
      </div>
  );
}
export default DoongjiPreview;