import React,{useEffect,useState} from "react"; 
import { Link,useSearchParams } from 'react-router-dom';

import HeadTitle from '../../components/include/HeadTitle';
import bigIcon from "../../images/big_icon01.png";

const FailToss = () => {
  //Toss페이로부터 결제성공 전달값
  const [searchParams] = useSearchParams();
  const [varResult,setResult] = useState({orderId:'',code:'',message:''});
  const [varCancelMode,setCancelMode] = useState(false);

  //페이지 정보 표시 - 최초
  useEffect(() => {
    let orderId = searchParams.get("orderId");
    let code = searchParams.get("code");
    let message = searchParams.get("message");
    setResult({orderId:orderId,code:code,message:message});
    if(code === 'PAY_PROCESS_CANCELED') setCancelMode(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadTitle titleString={varCancelMode?"카드 결제 취소":"카드 결제 실패"} backString={"none"}/>
      <div className="pd_wrap">
        <div className="ok_form_wrap">
          <div className="center_wrap">
            <img src={bigIcon} alt=""/>
            {varCancelMode?
              <div className="top_txt">
                <span>Toss결제를 취소하셨습니다.</span><br/>
                <span>※ 차후에 결제를 다시 진행하십시오.</span>
              </div>
              :
              <div className="top_txt">
                <span>Toss결제에 실패하였습니다.</span><br/>
                <span>결제코드 : {varResult.orderId}</span><br/>
                <span>오류코드 : {varResult.code}</span><br/>
                <span>오류메시지 : {varResult.message}</span><br/>
                <span>※ 만약 같은 문제가 지속적으로 발생한다면 문의 부탁드립니다.</span>
              </div>
            }
            <div className="btn_bottom one_btn">
              <Link className="btn_style_yes" to="/MyPage">마이페이지</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FailToss;