/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from "react"; 
import closeIcon from '../../images/icon_close.png';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
  <>
    <button className="image-control-button" onClick={()=>zoomIn()}>IN</button>
    <button className="image-control-button" onClick={() => zoomOut()}>OUT</button>
    <button className="image-control-button" onClick={() => resetTransform()}>INIT</button>
  </>
);

//둥지매물 신고를 위한 팝업창
const ShowBigSizePhoto = ({varPhotoImage,varOpenPhoto,onOpenPhotoHandler}) => {
  const imgRef = useRef();
  const tBoxRef = useRef();
  const transformComponentRef = useRef();

/*   const zoomToImage = () => {
    if (transformComponentRef.current) {
      const { zoomToElement } = transformComponentRef.current;
      zoomToElement("imgExample");
    }
  }; */

  //페이지 정보 표시 - 최초
  useEffect(() => {
    const imgBox = imgRef.current;
    //console.log(imgBox);
    //let width = imgBox.width;
    let height = imgBox.height;
    //console.log(width,height);
    //let xw = tBoxRef.current.offsetWidth;
    let yh = tBoxRef.current.offsetHeight;
    //console.log(xw,yh);
    let offsetY = (yh - height) * 0.5 +'px';
    const showBox = document.querySelector('.transform-component-module_content__FBWxo');
    showBox.setAttribute('style',`transform: translate(0px, ${offsetY}) scale(1)`)
  }, [varPhotoImage]);


  return (
    <div className="poplayerPhoto" style={varOpenPhoto?{display:"block"}:{display:"none"}}>
      <div className="date-right-close"><img src={closeIcon} alt="" onClick={e=>onOpenPhotoHandler(e,false)}/></div>
      <div className= "display-area">
        <div className="consbox2" ref={tBoxRef} style={{position:"relative"}}>
          <TransformWrapper initialScale={1} /* minScale={1} maxScale={10} */ initialPositionX={0} initialPositionY={0} ref={transformComponentRef}>
          {(utils) => (
            <React.Fragment>
              <div className="date-left-close" style={{display:"none"}}>
                <Controls {...utils} />
              </div>
              <TransformComponent>
                <img className="shown-image" ref={imgRef} id="imgExample" src={varPhotoImage} alt=""/>
                {/* <div onClick={zoomToImage}>Example</div> */}
              </TransformComponent>
            </React.Fragment>
          )}
          </TransformWrapper>
        </div>
      </div>
    </div>
  );
}
export default ShowBigSizePhoto;