import React, { useState, useEffect } from "react";
import { useParams,Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import DaumPostCode from "../../../components/popup/DaumPostCode";
import { ChangeMobileNumber,checkEmailString,escapeHtml,isPassword,remainDays } from '../../../components/common/Common';

import MemberNormal from "./MemberNormal";
import MemberCompany from "./MemberCompany";
import MemberRetired from "./MemberRetired";
import AdminShowBigSizePhoto from '../../components/include/AdminShowBigSizePhoto';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

function MemberDetail({varTermTitle}) {
  let { memMode,mem_idx } = useParams();
  const navigate = useNavigate();

  let memTitle = ""; 
  let mem_mode = 0;
  switch(Number(memMode)){
    default:
    case 1:
      memTitle = "일반 회원 관리"; mem_mode=0; break;
    case 2:
      memTitle = "부동산 파트너 관리"; mem_mode=1; break;
    case 3:
      memTitle = "사업자 파트너 관리"; mem_mode=2; break;
    case 0:
      memTitle = "탈퇴 회원 관리"; mem_mode=3; break;
  }
  let newData = {};
  if(mem_mode === 0) {
    newData = {mem_idx:mem_idx,mem_mode:mem_mode,mem_email:'',mem_pwd:'',mem_pwd2:'',mem_name:'',mem_nickname:'',mem_mobile:'',mem_memo:'',mem_warning:0,
              mem_zipcode:'',mem_address1:'',mem_address2:'',mem_regcode:'',mem_dongname:'',mem_roadname:'',mem_location:'',mem_image:0,mem_file:'',chk_email:0};
  } else {
    newData = {mem_idx:mem_idx,mcm_idx:0,mem_mode:mem_mode,mem_email:'',mem_pwd:'',mem_pwd2:'',mcm_name:'',mcm_phone:'',mem_name:'',mem_mobile:'',mem_memo:'',mem_warning:0,
    mcm_zipcode:'',mcm_address1:'',mcm_address2:'',mcm_location:'',mcm_regcode:'',mcm_dongname:'',mcm_roadname:'',mcm_image:0,mcm_file:'',mcm_tax_id:'',mcm_business:0,
    mcm_tax_email:'',mcm_introduce:'',mcm_tax_image:0,mcm_tax_file:'',mcm_broker_image:0,mcm_broker_file:'',mcm_approval:0,chk_email:0,
    mem_zipcode:"",mem_address1:"",mem_address2:"",mem_location:"",mem_nickname:"",mcm_broker_license:''};
  }

  //회원정보
  const [varMemberInfo,setMemberInfo] = useState({});
  const [varBusinessList,setBusinessList] = useState([]);

  //회원정보 읽어오기
  const GetMemberInformation = async () => {
    const sendData = {memMode:memMode, mem_idx:mem_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_member_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        let dataInfo = datas.results;
        if(Number(memMode)===3) {
          dataInfo.vouchers = "";
          GetMemberVouchers(dataInfo);
        } else {
          setMemberInfo(dataInfo);
        }
      } else {
        if(Number(memMode)===3) newData.vouchers = "";
        setMemberInfo(newData);
      }
      setBusinessList(datas.business);
    });
  }

  //이용권 정보 찾기 - 사업자 경우
  const GetMemberVouchers = async (dataInfo) => {
    const sendData = {mem_idx:mem_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_member_vouchers`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(datas.payments.length > 0) {
          const payments = datas.payments[0];
          let retString = "";
          if (payments.ph_mode===2 && remainDays(payments.jangter_expdate) > 0) {
            retString = `장터요금제 (카테고리:${payments.j_slot},기간:${payments.pay_terms}개월)`;
          } else if (payments.ph_mode===4) {
            retString = "장터프리미엄광고";
          }
          dataInfo.vouchers = retString;
        } else {
          dataInfo.vouchers = "";
        }
        setMemberInfo(dataInfo);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMemberInfo(newData);
    GetMemberInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //회원정보편집
  const onMemberInfoHandler = (e) => {
    const {name,value} = e.currentTarget;
    if(name === "mem_mobile" || name === "mcm_phone") {
      var svalue = ChangeMobileNumber(value);
      setMemberInfo({...varMemberInfo, [name]:svalue});
    } else if(name === "mem_email") {
      if(varMemberInfo.chk_email === 1){
        setMemberInfo({...varMemberInfo, [name]:value,chk_email:0});
        const emailButton = document.querySelector('.btn.line.disable');
        console.log(emailButton);
        emailButton.classList.remove("disable");
      } else {
        setMemberInfo({...varMemberInfo, [name]:value});
      }
    } else if(name === "mem_memo") {
      setMemberInfo({...varMemberInfo, [name]:escapeHtml(value)});
    } else if(name === "mcm_business") {
      const { name,value } = e.currentTarget;
      // console.log(name,value);
      setMemberInfo({...varMemberInfo,[name]:Number(value)});
    } else {
      setMemberInfo({...varMemberInfo, [name]:value});
    }
  }



  //회원정보 저장하기
  const updateMemberInformation = async () => {
    //탈퇴회원
    if(Number(memMode) === 0){  
      RegisterMemberInformation("");
      return false;
    } 

    if(varMemberInfo.mem_email === "") {
      alert('아이디(이메일)을 먼저 입력하세요.');return false;
    } else if(!checkEmailString(varMemberInfo.mem_email)) {
      alert('입력된 아이디(이메일)이 부정확한 이메일형식입니다.');return false;
    }

    if(varMemberInfo.mem_idx === 0) {
      if(varMemberInfo.chk_email === 0) {
        alert('아이디(이메일)의 중복검사를 해주세요.');
        return false;
      }
    }

    if(Number(memMode)===1) {
      if(varMemberInfo.mem_nickname === "" ) {
        alert('회원 닉네임을 먼저 입력하세요.');return false;
      } else if(varMemberInfo.mem_zipcode === "") {
        alert('우편번호와 주소를 먼저 선택하세요.');return false;
      } else if(varMemberInfo.mem_mobile === "") {
        alert('휴대폰번호를 먼저 입력하세요.');return false;
      } else if(varMemberInfo.mem_name === "") {
        alert('회원의 이름을 먼저 입력하세요.');return false;
      }
    } else {
       if(varMemberInfo.mcm_name=== "") {
        alert('업체명을 먼저 입력하세요.');return false;
      } else if(varMemberInfo.mcm_phone === "") {
        alert('업체대표번호를 먼저 입력하세요.');return false;
      } else if(varMemberInfo.mcm_zipcode === "") {
        alert('사업장의 우편번호와 주소를 먼저 선택하세요.');return false;
      } else if(varMemberInfo.mcm_business === 0 && varMemberInfo.mem_mode === 2) {
        alert('업체 분류를 선택하세요');return false;
      } else if(varMemberInfo.mem_name === "") {
        alert('담당자 이름을 먼저 입력하세요.');return false;
      } else if(varMemberInfo.mem_mobile === "") {
        alert('휴대폰번호를 먼저 입력하세요.');return false;
      } else if(varMemberInfo.mcm_tax_id === "") {
        alert('사업자등록번호를 먼저 입력하세요.');return false;
      } else if(varMemberInfo.mcm_tax_email === "") {
        alert('이메일(세금계산서발행용)을 먼저 입력하세요.');return false;
      } else if(!checkEmailString(varMemberInfo.mcm_tax_email)) {
        alert('입력된 세금계산서발행용 이메일이 부정확한 이메일형식입니다.');return false;
      } else if(varMemberInfo.mem_mode===1 && varMemberInfo.mcm_broker_license === "") {
        alert('부동산중개사번호를 먼저 입력하세요.');return false;
      } else if(varMemberInfo.mcm_introduce === "") {
        alert('업체 소개글을 먼저 입력하세요.');return false;
      }
    }

    if(varMemberInfo.mem_idx === 0) {
      if(varMemberInfo.mem_pwd === "") {
        alert('비밀번호를 먼저 입력하세요.');return false;
      } else if(varMemberInfo.mem_pwd !== varMemberInfo.mem_pwd2) {
        alert('두개의 비밀번호가 일치하지 않습니다.');return false;
      } else if(!isPassword(varMemberInfo.mem_pwd) || varMemberInfo.mem_pwd?.length < 8 || varMemberInfo.mem_pwd?.length > 12 ) {
        alert('비밀번호는 영문, 숫자, 특수문자 포함 8~12자리로 입력해주세요.'); return false;
      }
    } else {
      if(varMemberInfo.mem_pwd !== "") {
        if(varMemberInfo.mem_pwd !== varMemberInfo.mem_pwd2) {
          alert('두개의 비밀번호가 일치하지 않습니다.');return false;
        }
      }  
    }

    if(!window.confirm('사용자의 정보를 저장합니까?')) return false;
    
    //사진 처리 부분
    if(Number(memMode) === 1) { //일반회원
      var fileName = {mem_file:""};
      const profileImage = document.getElementById('selectProfile');
      console.log(profileImage,profileImage.value);
      if(profileImage.value) {  //새로운 파일이 선택된 경우
        console.log(profileImage.files[0].name);
        let formData = new FormData();
        formData.append('member',profileImage.files[0]);
        await axios.post(`${CONFIG.SERVER_HOST}/member`, formData).then((res) => {
          console.log(res.data);
          if(res.data.retvalue === 1) {
            fileName.mem_file = res.data.fileName;
            RegisterMemberInformation(fileName);
          }
        });
      } else {
        RegisterMemberInformation(fileName);
      }
    } else {
      var fileNames = {mcm_file:"",mcm_tax_file:"",mcm_broker_file:""};
      const selectFile1 = document.getElementById('selectFile1');
      const selectFile2 = document.getElementById('selectFile2');
      const selectFile3 = document.getElementById('selectFile3');
      console.log(selectFile1);
      let ino = 0;
      let formData = new FormData();
      if(selectFile1.value) {
        ino = 1;
        console.log(selectFile1.files[0].name);
        formData.append('member',selectFile1.files[0]);
      }
      if(Number(memMode) === 2) { //부동산업자
        if(selectFile2.value) {
          ino += 2;
          console.log(selectFile2.files[0].name);
          formData.append('member',selectFile2.files[0]);
        }
        if(selectFile3.value) {
          ino += 4;
          console.log(selectFile3.files[0].name);
          formData.append('member',selectFile3.files[0]);
        }
      }
      if(ino > 0) {
        await axios.post(`${CONFIG.SERVER_HOST}/memberArray`, formData).then((res) => {
          console.log(res.data);
          if(res.data.retvalue === 1) {
            const sfileNames = res.data.fileNames; //배열
            console.log(sfileNames);
            if(ino === 1) {
              fileNames.mcm_file = sfileNames[0];
            } else if(ino === 2) {
              fileNames.mcm_tax_file = sfileNames[0];
            } else if(ino === 3) {
              fileNames.mcm_file = sfileNames[0]; 
              fileNames.mcm_tax_file = sfileNames[1];         
            } else if(ino === 4) {
              fileNames.mcm_broker_file = sfileNames[0];          
            } else if(ino === 5) {
              fileNames.mcm_file = sfileNames[0]; 
              fileNames.mcm_broker_file = sfileNames[1];          
            } else if(ino === 6) {
              fileNames.mcm_tax_file = sfileNames[0];  
              fileNames.mcm_broker_file = sfileNames[1];          
            } else if(ino === 7) {
              fileNames.mcm_file = sfileNames[0];
              fileNames.mcm_tax_file = sfileNames[1];  
              fileNames.mcm_broker_file = sfileNames[2];            
            }
            RegisterMemberInformation(fileNames);
          }
        });
      } else {
        RegisterMemberInformation(fileNames);
      }
    }
  }

  //정보저장
  const RegisterMemberInformation = async (fileNames) => {
    const sendData = {memMode:memMode,dataInfo:varMemberInfo,fileNames:fileNames};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_member_information`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        navigate(`/Admin/Member/MemberLists/${memMode}`);
      } else {
        alert(datas.message);
      }
    });
  }

  //정보저장
  const blockMember = async (e) => {
    const sendData = {memMode:memMode,dataInfo:varMemberInfo};
    console.log(sendData);
    if(!window.confirm('해당 회원을 차단하시겠습니까?')) return false;
    await axios.post(`${CONFIG.SERVER_HOST}/_block_member`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        navigate(`/Admin/Member/MemberLists/-1`);
      } else {
        alert(datas.message);
      }
    });
  }
  
  //우편번호/주소찾기
  const [varOpenZipcode, setOpenZipcode] = useState(false);

  //우편번호/주소 검색 팝업창 열기/닫기
  const onOpenZipcodeHandler = (e, bts) => {
    e.preventDefault();
    setOpenZipcode(bts);
  };
  //우편번호 선택으로 우편번호/주소와 같이 반환
  
  const ChangeAddress = (zipcode,address1,regcode,dongName,roadName,locString) => {
    console.log(zipcode,address1,regcode,dongName,roadName,locString);
    if(varMemberInfo.mem_mode === 0) {
      setMemberInfo((prevState)=>{return{...prevState, mem_zipcode:zipcode, mem_address1:address1, mem_regcode:regcode, mem_dongname:dongName,mem_roadname:roadName,mem_location:locString}});
    } else {
      setMemberInfo((prevState)=>{return{...prevState, mcm_zipcode:zipcode, mcm_address1:address1, mcm_regcode:regcode, mcm_dongname:dongName,mcm_roadname:roadName,mcm_location:locString}});
    }
  };

  //사진팝업보기
  const [varOpenPhoto,setOpenPhoto] = useState(false);
  const [varPhotoImage, setPhotoImage] = useState(null);
  const onShowBigSizePhotoHandler = (e,files) => {
    setPhotoImage(files);
    setOpenPhoto(true);
  }
  const onOpenPhotoHandler = (e,bmode) => {
    setOpenPhoto(bmode);
  }

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">{memTitle}</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>회원 관리<img src={arrImage} alt=""/>{varTermTitle}<img src={arrImage} alt=""/>{memTitle}
        </div>
      </div>
      <div className="content">
        {/* 일반회원 */
        Number(memMode)===1? 
          <MemberNormal varMemberInfo={varMemberInfo} setMemberInfo={setMemberInfo}
                      onMemberInfoHandler={onMemberInfoHandler} 
                      onOpenZipcodeHandler={onOpenZipcodeHandler} onShowBigSizePhotoHandler={onShowBigSizePhotoHandler}/>
        
        :/* 탈퇴회원 */
        Number(memMode)===0? 
          <MemberRetired varMemberInfo={varMemberInfo} onMemberInfoHandler={onMemberInfoHandler}
                      onShowBigSizePhotoHandler={onShowBigSizePhotoHandler}/>
        :/* 업체회원 */
          <MemberCompany varMemberInfo={varMemberInfo} setMemberInfo={setMemberInfo} varBusinessList={varBusinessList}
                    onMemberInfoHandler={onMemberInfoHandler} 
                    onOpenZipcodeHandler={onOpenZipcodeHandler} onShowBigSizePhotoHandler={onShowBigSizePhotoHandler}/>
        }
        <div className="btn-center">
          <Link to={`/Admin/Member/MemberLists/${memMode}`} style={{width:"90px"}} className="btn line">목록</Link>
          <button onClick={(e)=>updateMemberInformation(e)} style={{width:"90px"}} className="btn line">저장</button>
          <button onClick={(e)=>blockMember(e)} style={{width:"90px"}} className="btn line">차단</button>
        </div>
        {/* 우편번호/주소 검색*/}
        <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler}/>
        {/* 사진확대*/}
        <AdminShowBigSizePhoto varPhotoImage={varPhotoImage} varOpenPhoto={varOpenPhoto} onOpenPhotoHandler={onOpenPhotoHandler}/>
      </div>
    </div>
  );
}

export default MemberDetail;