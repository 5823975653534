/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState,useEffect} from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { isObjEmpty } from "../../components/common/Common";
import closeIcon from '../../images/icon_close.png';

//둥지매물 신고를 위한 팝업창
const ReportProduct = ({rpIdx,rpMode,varPopupReportProduct,onPopupReportProductHandler}) => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));

  //신고정보 저장 객체
  const newData = {mem_idx:mem_idx,pr_idx:0,pr_mode:rpMode,dj_idx:rpIdx,pr_reason:0,pr_comment:""};
  //console.log(newData);
  const [varReportData,setReportData] = useState({});

  const GetReportInfo = async () => {
    // const sendData = {pr_mode:rpMode,mem_idx:mem_idx,dj_idx:rpIdx};
    // console.log(sendData);
    // await axios.post(`${CONFIG.SERVER_HOST}/_get_report_product`,sendData).then((res) => {
    //   const datas = res.data;
    //   if(datas.retvalue === 1) {
    //     if(datas.results?.length > 0) {
    //       const dataInfo = datas.results[0];
    //       newData.pr_idx = dataInfo.pr_idx;
    //       newData.pr_reason = dataInfo.pr_reason;
    //       newData.pr_comment = dataInfo.pr_comment;
    //     }
    //   } 
    //   console.log(newData);
    //   setReportData(newData);
    // });
    setReportData(newData);
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(rpIdx !== undefined) GetReportInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rpIdx]);
  
  //정보 입력 저장 
  const onChangeRadioHandler = (e) => {
    const { name,value } = e.currentTarget;
    if(Number(value)===0) {
      setReportData({...varReportData,[name]:0});
    } else {
      setReportData({...varReportData,[name]:1});
    }
    
    
  }
  const onChangeInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    setReportData({...varReportData,[name]:value});
  }

  //둥지/장터 매물 신고하기
  const onRegisterReportHandler = async (e) => {
    e.preventDefault();
    if(!window.confirm('신고를 등록하시겠습니까?')) return false;
    const sendData = {infos:varReportData};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_register_report_product`,sendData).then((res) => {
      const datas = res.data;
      alert(datas.message);
      if(datas.retvalue === 1) {
        onPopupReportProductHandler(e,false);
      }
    });
    setReportData(newData);
  }

  return isObjEmpty(varReportData)?null:(
    <div className="layer-pop w_del_popup desc_popup_st h_auto" style={varPopupReportProduct?{display:"block"}:{display:"none"}}>
      <div className="close_bt_wrap">
        <div className="close_layer top_close_btn"><img src={closeIcon} alt="" onClick={e=>onPopupReportProductHandler(e,false)}/></div>
      </div>
      <div className="layer_inner">
        <div className="center_wrap">
          <div className="con">
            <div className="con_box">
              <strong>신고하시겠습니까</strong>
              <div className="singo_sel">
                <ul>
                  <li className="terms_chk35">
                    <input type="radio" name="pr_reason" className="chk1" id="pr_reason0" value="0" defaultChecked={varReportData.pr_reason===0?true:false} onChange={e=>onChangeRadioHandler(e)}/>
                    <i></i><label htmlFor="pr_reason0">허위매물</label>
                  </li>
                  <li className="terms_chk35">
                    <input type="radio" name="pr_reason" className="chk1" id="pr_reason1" value="1" defaultChecked={varReportData.pr_reason===1?true:false} onChange={e=>onChangeRadioHandler(e)}/>
                    <i></i><label htmlFor="pr_reason1">기타</label>
                  </li>
                </ul>
                <textarea name="pr_comment" id="pr_comment" cols="30" rows="5" style={{maxWidth:'100%',minWidth:'100%',width:'100%'}} 
                          placeholder="신고 사유를 기재해주세요." value={varReportData.pr_comment} onChange={e=>onChangeInputHandler(e)}/>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn_bottom">
        <button type="button" className="btn_style_yes close_layer" onClick={e=>onPopupReportProductHandler(e,false)}>취소</button>
        <button type="button" className="btn_style_no" onClick={e=>onRegisterReportHandler(e)}>등록</button>
      </div>
    </div>
  );
}
export default ReportProduct;