import React from "react"; 

import { addThousandPoint } from "../common/Common";

//매물 가격 정보 표시
const DoongjiPriceInfo = ({varProductData}) => {

  return (
    <div className="info02">
      <div className="tit07_1">가격 정보</div>
      <div className="table_st03">
        <table>
          <colgroup><col width="35%"/><col width="65%"/></colgroup>
          <thead></thead>
          <tbody>
            <tr>
                <th>거래유형</th>
                <td><span><span className="red_txt">{varProductData.types}</span></span></td>
            </tr>
            <tr>
                <th>{varProductData.rp_type===0?"매매가/전세금/보증금"
                    :varProductData.rp_type===11?"매매가"
                    :varProductData.rp_type===12?"전세금"
                    :varProductData.rp_type===15?"분양가"
                    :"보증금"}
                </th>
                <td><span>{addThousandPoint(varProductData.rp_deposit)}</span> 만원</td>
            </tr>
            {varProductData.rp_type===15|| varProductData.rp_type===11 || varProductData.rp_type===12? null :
              <tr>
                <th>월세</th>
                <td><span>{addThousandPoint(varProductData.rp_monthly)}</span> 만원</td>
              </tr>
            }
            {varProductData.rp_serious===9? null :
            <tr>
                <th>관리비</th>
                <td><span>{addThousandPoint(varProductData.rp_admin_expense)}</span> 만원</td>
            </tr>
            }
            {varProductData.rp_type===15 || varProductData.rp_serious>=6? null :
            <tr>
                <th>포함내역</th>
                <td><span>{varProductData.rp_include}</span></td>
            </tr>
            }
            {varProductData.rp_serious < 6 || varProductData.rp_serious===9 ? null:
              <tr>
                  <th>시설권리금</th>
                  <td><span>{addThousandPoint(varProductData.rp_right_money)}</span> 만원</td>
              </tr>
            }
            <tr>
                <th>융자</th>
                <td><span>{addThousandPoint(varProductData.rp_mortgage)}</span> 만원</td>
            </tr>
            <tr>
                <th>대출</th>
                <td><span>{varProductData.rp_loan===0?"불가능":"가능"}</span></td>
            </tr>
            {varProductData.rp_serious===9?null:
              <tr>
                  <th>현임차보증금</th>
                  <td><span>{addThousandPoint(varProductData.rp_deposit_now)}</span> 만원</td>
              </tr>
            }
            {varProductData.rp_serious===9?null:
              <tr>
                  <th>현임차월세</th>
                  <td><span>{addThousandPoint(varProductData.rp_monthly_now)}</span> 만원</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default DoongjiPriceInfo;