/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import { isObjEmpty,DisplayNumber } from "../common/Common";

const DetailPriceMore = ({varProductData,onChangeInputHandler,onChangeRadioInputHandler}) => {

  return  isObjEmpty(varProductData) ? null : (
    <div id="trans_type2">  
      <div className="form_wrap2">
        <div className="tit07_2">융자</div>
        <div className="one-line">
          <input type="text" name="rp_mortgage" className="one-line-won" placeholder="0" value={DisplayNumber(varProductData.rp_mortgage)} onChange={e=>onChangeInputHandler(e)} />
          <span className="danwe_txt">만원</span>
        </div>
      </div>
      <div className="form_wrap2">
        <div className="tit07_2">대출</div>
        <div className="dan_short">
          <li className="terms_chks">
            <input type="radio" name="rp_loan" className="chk1" id="loan01" value="1" 
                    defaultChecked={varProductData.rp_loan===1?true:false} onChange={e=>onChangeRadioInputHandler(e)}/>
            <i></i><label htmlFor="loan01">가능</label>
          </li>
          <li className="terms_chks">
            <input type="radio" name="rp_loan" className="chk1" id="loan02" value="0"
                    defaultChecked={varProductData.rp_loan===0?true:false} onChange={e=>onChangeRadioInputHandler(e)}/>
            <i></i><label htmlFor="loan02">불가능</label>
          </li>
        </div>
      </div>
      {/* 매물유형이 토지이면 제외 */}
      {varProductData.rp_serious===9?null:
        <>
          <div className="form_wrap2">
              <div className="tit07_2">현임차보증금</div>
              <div className="one-line">
                <input type="text" name="rp_deposit_now" className="one-line-won" placeholder="0" value={DisplayNumber(varProductData.rp_deposit_now)} onChange={e=>onChangeInputHandler(e)}/>
                <span className="danwe_txt">만원</span>
              </div>
          </div>
          <div className="form_wrap2">
              <div className="tit07_2">현임차월세</div>
              <div className="one-line">
                <input type="text" name="rp_monthly_now" className="one-line-won" placeholder="0" value={DisplayNumber(varProductData.rp_monthly_now)} onChange={e=>onChangeInputHandler(e)}/>
                <span className="danwe_txt">만원</span>
              </div>
          </div>
        </>
      }
    </div>
  );
}
export default DetailPriceMore;