import React, { useState,useEffect } from "react"; 
import { useParams,Link } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { addThousandPoint,ChangeUnitBillion,ChangeDateStringOnlyDate,isObjEmpty,isNullRegion,isNull } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const PremiumView = () => {
  const { ph_idx } = useParams();
  const [varPaymentData,setPaymentData] = useState({});

  //프리미엄광고 정보 호출하기
  const getPremiumInformation = async () => {
    const sendData = {ph_idx:ph_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_premium_banknote_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1 && datas.results?.length > 0) {
        setPaymentData(datas.results[0]);
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    document.getElementById('main_body').style.backgroundColor = '#FFFFFF';
    getPremiumInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isObjEmpty(varPaymentData)?null:(
    <>
      <Header/>
      <HeadTitle titleString={"파트너스 결제 관리"}/>
      <div id="content">
        <div className="pd_wrap04">
          <div className="center_wrap">
            <h2>결제 정보</h2>
            <div className="ad_pay2_wrap">
              <div className="form_wrap">
                <div className="tit07_1">희망 광고 컨텐츠</div>
                <div className="ad_con_box">
                  <span className="btn">{varPaymentData.ph_mode===3?"둥지":"장터"}</span>
                  <span className="txt">
                    {varPaymentData.ph_mode===3?(isNull(varPaymentData.structure)?'삭제된 컨텐츠':(varPaymentData.structure)+' '+varPaymentData.types+' '+ChangeUnitBillion(varPaymentData.rp_deposit))
                    :null}
                  </span>
                </div>
              </div>
              <div className="form_wrap">
                <div className="tit07_1">희망 광고 지역</div>
                <div className="ad_con_box">
                  <ul className="ad_region">
                    {isNullRegion(varPaymentData.rpa_regcode1)?null:<li>{varPaymentData.regname1}</li>}
                    {isNullRegion(varPaymentData.rpa_regcode2)?null:<li>{varPaymentData.regname2}</li>}
                    {isNullRegion(varPaymentData.rpa_regcode3)?null:<li>{varPaymentData.regname3}</li>}
                    {isNullRegion(varPaymentData.rpa_regcode4)?null:<li>{varPaymentData.regname4}</li>}
                    {isNullRegion(varPaymentData.rpa_regcode5)?null:<li>{varPaymentData.regname5}</li>}
                  </ul>
                </div>
              </div>
              <div className="form_wrap">
                <div className="tit07_1">희망 게재 기간</div>
                <div className="ad_con_box">
                  <div className="ad_period">
                    <span>{ChangeDateStringOnlyDate(varPaymentData.rpa_sdate)}</span><span className="mid_bar02"> ~ </span><span>{ChangeDateStringOnlyDate(varPaymentData.rpa_edate)}</span>
                  </div>
                </div>
              </div>
              {varPaymentData.ph_status===1?null:
                <div className="form_wrap">
                  <div className="tit07_1">광고 금액</div>
                  <div className="ad_con_box">
                    <span className="ad_price">{addThousandPoint(varPaymentData.ph_amount)+" 원"}</span>
                  </div>
                </div>
              }
              {varPaymentData.ph_status===1?null:
                <div className="form_wrap">
                  <div className="tit07_1">결제 수단</div>
                  <div className="ad_con_box">
                    <span className="ad_price">{varPaymentData.ph_method===1?"신용카드":"무통장입금"}</span>
                  </div>
                </div>
              }
              {varPaymentData.ph_method === 1 || varPaymentData.ph_status===1? null:
                <div className="form_wrap">
                  <div className="tit07_1">입금 계좌</div>
                  <div className="ad_con_box">
                    <span className="ad_price">{varPaymentData.bh_account}({varPaymentData.bl_name})</span>
                  </div>
                  <div className="tit07_1">입금자명</div>
                  <div className="ad_con_box">
                    <span className="ad_price">{varPaymentData.ph_sender}</span>
                  </div>
                </div>
              }
              <div className="form_wrap">
                <div className="tit07_1">결제 상태</div>
                <div className="ad_con_box">
                  <span className="ad_price">
                    {varPaymentData.ph_status===1?"신청중"
                    :varPaymentData.ph_status===2?"결제대기"
                    :varPaymentData.ph_status===3?"결제완료"
                    :null}
                  </span>
                </div>
              </div>
              <div className="btn_bottom one_btn">
                <Link className="btn_style_yes" to={"/MyPage/PremiumList"}>결제 관리로 이동</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PremiumView;