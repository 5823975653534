/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 

import DoongjiList from "./doongji/DoongjiList";

const Main = () => {
/*   const loginState = Number(localStorage.getItem("memberLoginState"));

  //로그인되어 있지 않으면 --> 로그인페이지로
  if(loginState === 0) {
    window.location.href="/Login";
    return false;
  }  */

  return (
    <>
        <DoongjiList />
    </>
  );
}
export default Main;