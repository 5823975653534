/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import DoongjiVariables from "../../components/environment/DoongjiVariables";
import JangterVariables from "../../components/environment/JangterVariables";
import SubwayVariables from "../../components/environment/SubwayVariables";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import '../../admin_css/variables.css';

const Variables = () => {
  //메뉴항목지정
  const [varMenuItem,setMenuItem] = useState('');

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'ENVIRONMENT'}/>
      <Menus varMenuType={'ENVIRONMENT'} varMenuItem={varMenuItem}/>
      <Routes>
        {/* 카테고리관리 */}
        <Route path="DoongjiVariables" element={<DoongjiVariables setMenuItem={setMenuItem} />} />
        <Route path="JangterVariables" element={<JangterVariables setMenuItem={setMenuItem} />} />
        <Route path="SubwayVariables" element={<SubwayVariables setMenuItem={setMenuItem} />} />
      </Routes>
    </>
  );
}
export default Variables;