import React, { useState,useEffect } from "react";
import { useParams,useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import CONFIG from '../../configs/Configs'; 

import { isObjEmpty,addThousandPoint,removeThousandPoint,ChangeMobileNumber,ChangeMobileFormat,isNull } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import DetailPhotos from '../../components/doongji/DetailPhotos';
import JangterAddress from '../../components/jangter/JangterAddress';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

//파트너 검색
const JangterDetail = () => {
  const mem_idx = localStorage.getItem("mem_idx");
  const { jt_idx } = useParams();
  const navigate = useNavigate();

  if(Number(jt_idx)===0) {

  }

  const newJangter = {jt_idx:jt_idx,jt_mem_idx:mem_idx,jt_code:"",jt_title:"",jt_serious1:0,jt_serious2:0,jt_serious3:0,jt_comment:"",
                      jt_zipcode:"",jt_address1:"",jt_address2:"",jt_location:"",jt_regcode:"",jt_dongname:"",jt_rdate:null,jt_mobile:"",
                      jt_price_mode:0,jt_price:0,jt_hits:0,jt_show:2,jt_status:1}

  const [varJangterData,setJangterData] = useState({});
  const [varJangterSerious1,setJangterSerious1] = useState([]);
  const [varJangterSerious2,setJangterSerious2] = useState([]);
  const [varJangterSerious3,setJangterSerious3] = useState([]);
  const [varFilteredSerious2,setFilteredSerious2] = useState([]);
  const [varFilteredSerious3,setFilteredSerious3] = useState([]);

  //매물사진 팝업창
  const [varOpenBigPhoto,setOpenBigPhoto] = useState(false);
  const [varPhotoImage,setPhotoImage] = useState('');
  const onOpenPhotoHandler = (e,bopen) => {
    setOpenBigPhoto(bopen);
  }
  const [varProductPhotos,setProductPhotos] = useState([]); //이미 저장된 매물사진 목록
  const [varTempProductPhotos,setTempProductPhotos] = useState([]); //신규 매물 사진 목록 - 선택된 사진 저장 배열
  const [varTempProductPhotoFiles,setTempProductPhotoFiles] = useState([]); //신규 매물 사진 정보 목록 - 선택된 사진 정보 저장 배열
  const [varEmptyProductPhotos,setEmptyProductPhotos] = useState([]); //신규 매물 사진 목록 - 비어 있는 사진 저장 배열

  // 중복제출 방지
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 로딩 상태 관리
  const [isLoading, setIsLoading] = useState(false);

  //장터매물정보 읽기
  const getJangterInformation = async() => {
    const sendData = {jt_idx:jt_idx,mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_jangter_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //대분류
        setJangterSerious1(datas.serious1);
        setJangterSerious2(datas.serious2);
        setJangterSerious3(datas.serious3);

        let photoMax = 5;
        let jangterData = {};
        if(datas.results?.length > 0) {
          //검색된 장터 정보
          jangterData = datas.results[0];
          setJangterData(jangterData);
          //매물사진정보
          setProductPhotos(datas.photos);
          photoMax = photoMax - datas.photos?.length;
        } else {
          jangterData = {...newJangter};
          setJangterData(newJangter);
          console.log('jangterData',jangterData);
        }

        //분류필터링
        if (jangterData.jt_serious1 > 0) {
          const filteredData2 = datas.serious2.filter(el=>el.vbs_idx===jangterData.jt_serious1);
          setFilteredSerious2(filteredData2);
        } else {
          setFilteredSerious2([]);
        }
        if (jangterData.jt_serious2 > 0) {
          const filteredData3 = datas.serious3.filter(el=>el.vbi_idx===jangterData.jt_serious2);
          setFilteredSerious3(filteredData3);
        } else {
          setFilteredSerious3([]);
        }
        //매물사진 5개까지 등록가능 --> 빈 공간 저장배열 만들기
        const photoData = {file_idx:0,file_name:"",file_preview:""};
        let photoArray = [];
        for(var i=0; i<photoMax; i++) {
          photoArray.push(photoData);
        }
        setEmptyProductPhotos(photoArray);
      }
    });
  }

  const showLoading = () => {
    setIsLoading(true);
  }

  const hideLoading = () => {
    setIsLoading(false);
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(Number(jt_idx)===0) {
      const jts1 = Number(localStorage.getItem("jangterSerious1"));
      const jts2 = Number(localStorage.getItem("jangterSerious2"));
      newJangter.jt_serious1 = jts1;
      newJangter.jt_serious2 = jts2;
    }
    getJangterInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //입력정보 저장
  const onChangeInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    if(name === "jt_price") {
      setJangterData({...varJangterData,[name]:removeThousandPoint(value)});
    } else if(name === "jt_mobile") {
      setJangterData({...varJangterData,[name]:ChangeMobileNumber(value)});
    } else {
      setJangterData({...varJangterData,[name]:value});
    }
  }
  //분류선택
  const onChangeSelectHandler = (e) => {
    const { name,value } = e.currentTarget;
    const serious = Number(value);
    console.log('serious',serious);
    if(name === "jt_serious1") {
      if(serious === 0) {
        setFilteredSerious2([]);
        setFilteredSerious3([]);
      } else {
        const newList2 = varJangterSerious2.filter(element=>element.vbs_idx===serious);
        console.log('newList2',newList2);
        setFilteredSerious2(newList2);
      }
      setFilteredSerious3([]);
    } else if(name === "jt_serious2") {
      if(serious === 0) {
        setFilteredSerious3([]);
      } else {
        const newList3 = varJangterSerious3.filter(element=>element.vbi_idx===serious);
        setFilteredSerious3(newList3);
      }
    }
    setJangterData({...varJangterData,[name]:serious});
  }

  //장터 등록
  const onRegisterJangterHandler = async (e) => {
    if(varJangterData.jt_title === "") {
      alert('제목을 먼저 입력하십시오.'); return false;
    } else if(varJangterData.jt_comment === "") {
      alert('내용을 먼저 입력하십시오.'); return false;
    } else if(varJangterData.jt_serious1 === 0) {
      alert('대분류를 먼저 선택하십시오'); return false;
    } else if(varJangterData.jt_serious2 === 0) {
      alert('중분류를 먼저 선택하십시오'); return false;
/*     } else if(varJangterData.jt_serious3 === 0) {
      alert('소분류를 먼저 선택하십시오'); return false; */
    } else if(varJangterData.jt_serious1 !== 3 && (varJangterData.jt_price === 0 || isNull(varJangterData.jt_price))) {
      alert('금액을 먼저 입력하십시오'); return false;
    } else if(varJangterData.jt_serious1 === 2 && varJangterData.jt_zipcode === "") {
      alert('주소를 먼저 선택하십시오'); return false;
    }

    if(!window.confirm('현재 입력된 장터를 저장하시겠습니까?')) return false;
    if (isSubmitting) return; // 이미 제출 중이면 중복 제출 방지
    setIsSubmitting(true); // 제출 중으로 변경
    showLoading();

    //서버로 업로드할 매물사진을 배열로 저장
    let PhotoForm = new FormData();
    for(var i=0; i < varTempProductPhotoFiles?.length; i++) {
      let formData = varTempProductPhotoFiles[i];
      PhotoForm.append('jangter',formData.get('jangter'));
    }
    console.log('추가된 매물 사진 서버로 업로드 등록',varTempProductPhotos?.length);
    //추가된 매물 사진 배열을 서버로 업로드 등록
    if(varTempProductPhotos?.length > 0) {
      await axios.post(`${CONFIG.SERVER_HOST}/jangterArray`, PhotoForm).then((res) => {
        console.log(res.data);
        if(res.data.retvalue === 1) {
          const fileNames = res.data.fileNames; //배열
          console.log(fileNames);
          //매물사진 DB 등록
          hideLoading();
          RegisterJangterProduct(fileNames);
        }
      });
    } else {  //등록할 매물사진이 없는 경우
      hideLoading();
      RegisterJangterProduct("");
    }
  }
  //서버의 데이타베이스로 매물 정보를 저장
  const RegisterJangterProduct = async(fileNames) => {
    let jt_idx = varJangterData.jt_idx;
    const sendData = {infos:varJangterData};
    console.log('sendData',sendData);
    //매물정보 DB 등록
    await axios.post(`${CONFIG.SERVER_HOST}/_update_jangter_information`, sendData).then((res) => {
      console.log(res.data);
      if(res.data.retvalue === 1) {
        if(Number(jt_idx) === 0) {
          navigate(`/Jangter/JangterRegisterComplete/${varJangterData.jt_serious2}`);
        } else {
          navigate(`/Jangter/JangterEditComplete/${varJangterData.jt_serious2}`);
        }
        jt_idx = res.data.jt_idx;
        //매물사진 DB 등록
        if(fileNames!=="") {
          const sendData = {jt_idx:jt_idx,fileNames:fileNames};
          console.log('sendData',sendData);
          axios.post(`${CONFIG.SERVER_HOST}/_register_jangter_photos`, sendData).then((res) => {
            //alert(res.data.message);
          });
        }
      } 
      else {
        alert(res.data.message);
      }
    });
  }

  return isObjEmpty(varJangterData)?null:(
    <>
      {/* 로딩 화면 */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-indicator2">
            <div className="loader"></div>
          </div>
        </div>
      )}
      <Header/>
      <HeadTitle titleString={"모두의 장터 등록"}/>
      <div id="content" style={{paddingBottom:"40px"}}>
        <div className="pd_wrap04">
          <div className="sell_wrap write_form_wrap">
            <div className="center_wrap">
              <div className="form_wrap">
                <div className="tit07_1">대분류&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
                <select name="jt_serious1" value={varJangterData.jt_serious1} onChange={e=>onChangeSelectHandler(e)}>
                  <option value="0">선택해주세요</option>
                  {varJangterSerious1.map((serious1,i)=>(
                    <option key={"serious1_"+i} value={serious1.vbs_idx}>{serious1.vbs_name}</option>
                  ))}
                </select>
              </div>
              <div className="form_wrap">
                <div className="tit07_1">중분류&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
                <select name="jt_serious2" value={varJangterData.jt_serious2} onChange={e=>onChangeSelectHandler(e)}>
                  <option value="0">선택해주세요</option>
                  {varFilteredSerious2.map((serious2,i)=>(
                    <option key={"serious2_"+i} value={serious2.vbi_idx}>{serious2.vbi_name}</option>
                  ))}
                </select>
              </div>
              <div className="form_wrap">
                <div className="tit07_1">소분류</div>
                <select name="jt_serious3" value={varJangterData.jt_serious3} onChange={e=>onChangeSelectHandler(e)}>
                  <option value="0">선택해주세요</option>
                  {varFilteredSerious3.map((serious3,i)=>(
                    <option key={"serious3_"+i} value={serious3.vbp_idx}>{serious3.vbp_name}</option>
                  ))}
                </select>
              </div>
              <div className="form_wrap">
                <div className="tit07_1">제목&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
                <input type="text" name="jt_title" value={varJangterData.jt_title} placeholder="제목을 작성해주세요." onChange={e=>onChangeInputHandler(e)}/>
              </div>
              <div className="form_wrap">
                <div className="tit07_1">내용&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
                <textarea type="text" name="jt_comment" cols="30" rows="5" value={varJangterData.jt_comment} placeholder="설명을 작성해주세요." onChange={e=>onChangeInputHandler(e)}/>
              </div>
              <div className="form_wrap">
                <div className="tit07_1">연락처</div>
                <input type="text" name="jt_mobile" value={ChangeMobileFormat(varJangterData.jt_mobile)} placeholder="연락처를 입력해주세요." onChange={e=>onChangeInputHandler(e)}/>
              </div>
              {varJangterData.jt_serious1===3?null:
                <div className="form_wrap">
                  <div className="tit07_1" id="price_title">
                    {varJangterData.jt_serious1===1?<>금액 <span style={{color:"red",fontSize:"12px"}}>(필수)</span></>
                    :
                    <>급여 <span style={{color:"red",fontSize:"12px"}}>(필수)</span></>
                    }
                  </div>
                  {varJangterData.jt_serious1===1?null
                    :
                    <div className="one-line line-left-row">
                      <li className="terms_chks">
                        <input type="radio" name="jt_price_mode" className="chk1" id="jt_price_mode0" value={0} defaultChecked={varJangterData.jt_price_mode===0} onChange={e=>onChangeInputHandler(e)}/>
                        <i></i><label htmlFor="jt_price_mode0">시급</label>
                      </li>
                      <li className="terms_chks">
                        <input type="radio" name="jt_price_mode" className="chk1" id="jt_price_mode1" value={1} defaultChecked={varJangterData.jt_price_mode===1} onChange={e=>onChangeInputHandler(e)}/>
                        <i></i><label htmlFor="jt_price_mode1">월급</label>
                      </li>
                    </div>
                    }
                  <input type="text" name="jt_price" className="jang_price" placeholder="0" value={isNull(varJangterData.jt_price)?"":addThousandPoint(varJangterData.jt_price)} onChange={e=>onChangeInputHandler(e)}/>&nbsp;&nbsp;원
                </div>
              }
              {varJangterData.jt_serious1===3?null:
                <JangterAddress varJangterData={varJangterData} setJangterData={setJangterData}/>
              }
              {/* 매물사진 */}
              <DetailPhotos varMode={2} photoMax={5} setPhotoImage={setPhotoImage} onOpenPhotoHandler={onOpenPhotoHandler} 
                  varProductPhotos={varProductPhotos} setProductPhotos={setProductPhotos}
                  varTempProductPhotos={varTempProductPhotos} setTempProductPhotos={setTempProductPhotos}
                  varTempProductPhotoFiles={varTempProductPhotoFiles} setTempProductPhotoFiles={setTempProductPhotoFiles}
                  varEmptyProductPhotos={varEmptyProductPhotos} setEmptyProductPhotos={setEmptyProductPhotos}/>
              <div className="btn_bottom one_btn">
                <button className="btn_style_yes" onClick={e=>onRegisterJangterHandler(e)}>장터 등록</button>
              </div>
            </div>
          </div>
        </div>
        {/* 사진확대 */}
        <div className="popDoongjiPhoto" style={varOpenBigPhoto?{display:"block"}:{display:"none"}}>
          <div className="consbox2">
            <button className="btnclose" style={{ backgroundColor: "#eee" }} onClick={(e)=>onOpenPhotoHandler(e,false)}>닫기 X</button>
            <img className="shown-image" src={varPhotoImage} alt=""/>
          </div>
        </div>
      </div>
    </>
  );
}
export default JangterDetail;