/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import closeButton from "../../images/icon_close.png";
const ExpireMember = ({varExpireMember,onOpenExpireMemberHandler,onExpireMemberHandler}) => {

  return (
    <div className="layer-pop goodbye_popup desc_popup_st h_auto" style={varExpireMember?{display:"block"}:{display:"none"}}>
      <div className="close_bt_wrap">
        <div className="close_layer top_close_btn" onClick={e=>onOpenExpireMemberHandler(e,false)}><img src={closeButton} alt=""/></div>
      </div>
      <div className="layer_inner">
        <div className="center_wrap">
          <div className="con">
            <div className="con_box">
              <strong>탈퇴하시겠습니까?</strong>
              <div className="txt01">
                탈퇴하시면 둥지장터에 등록하신 모든 정보가<br/>사라지고 추후 복구가 불가능합니다. <br/>정말 탈퇴 하시겠습니까?
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn_bottom">
        <button type="button" className="btn_style_yes close_layer" onClick={e=>onOpenExpireMemberHandler(e,false)}>취소</button>
        <button type="button" className="btn_style_no" onClick={e=>onExpireMemberHandler(e)}>탈퇴</button>
      </div>
    </div>
  );
}

export default ExpireMember;