import React, { useState,useEffect } from "react"; 
import { useParams } from 'react-router-dom'; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";

import { CheckLoginStateOnly,isNull } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import Footer from "../../components/include/Footer";
import FooterCom from '../../components/include/FooterCom';

import SearchHeader from '../../components/include/SearchHeader';
import SelectRegion from '../../components/include/SelectRegion';
import SearchKeyword from "../../components/include/SearchKeyword";

import PremiumPartnerList from '../../components/jangter/PremiumPartnerList.js';
import SearchedPartnerResult from '../../components/jangter/SearchedPartnerResult.js';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

//파트너 검색
const PartnerList = () => {
  let mem_idx = Number(localStorage.getItem("mem_idx"));
  if(!CheckLoginStateOnly()) mem_idx = 0;

  const { vbd_idx } = useParams();

  const [varTotalCount,setTotalCount] = useState(0);
  const [varPartnerList,setPartnerList] = useState([]);

  //페이지 관련
  const [varPageNum,setPageNum] = useState(1);
  const [varPageSize] = useState(10);
  const [varMorePage,setMorePage] = useState(false);
  const [varStartMode,setStartMode] = useState(true);

  //선택한 지역 배열
  const [varSearchRegionList,setSearchRegionList] = useState([]);
  //검색키워드
  const [varSearchingString,setSearchingString] = useState("");

  //팝업창 열기
  const [varPopupSearchRegion,setPopupSearchRegion] = useState(false);  //지역
  const [varPopupSearchKeyword,setPopupSearchKeyword] = useState(false);  //키워드

  //분류명
  const [varBusiness,setBusiness] = useState('');

  //파트너 검색후 목록 가져오기
  const getPartnerTitle = async() => {
    const sendData = {vbd_idx:vbd_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_partner_title`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        const vbd_name = datas.vbd_name;
        setBusiness(vbd_name);
      }
    });
  }

  useEffect(() => {
    if(varStartMode) {
      const PartnerSearchingString = localStorage.getItem('PartnerSearchingString');
      if(!isNull(PartnerSearchingString)) setSearchingString(PartnerSearchingString);
      
      const PartnerSearchRegionList = localStorage.getItem('PartnerSearchRegionList');
      if(!isNull(PartnerSearchRegionList) && JSON.parse(PartnerSearchRegionList)?.length > 0){
        console.log(PartnerSearchRegionList);
        setSearchRegionList(JSON.parse(PartnerSearchRegionList));
      } 
    }
    getPartnerTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  const getPartnerList = async () => {
    if(isNull(varBusiness)) return;
    if(!varStartMode) {
      localStorage.setItem('PartnerSearchingString',varSearchingString);
      localStorage.setItem('PartnerSearchRegionList',JSON.stringify(varSearchRegionList));
    }
    const sendData = {mem_idx:mem_idx,pageNum:varPageNum,pageSize:varPageSize,vbd_name:varBusiness,searchRegion:varSearchRegionList,searchString:varSearchingString};
    console.log(varStartMode,sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_partner_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(varPageNum === 1) {
          //유효성 검토
          let pDate = datas.results;
          //검색된 전체수량
          setTotalCount(datas.totalcount);
          //검색된 목록
          setPartnerList(pDate);
        } else {
          setPartnerList(varPartnerList.concat(datas.results));
        }
        //더보가 버튼 표시유무
        if(datas.totalcount > varPageNum*varPageSize) setMorePage(true);
        else setMorePage(false);
        setStartMode(false);
      }
    });
  }

  //팝업창 뒤의 전체 화면 죽이기
  const onPopupWinHandler = (bmode) => {
    //console.log(bmode);
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }
  //지역선택 팝업창
  const onPopupRegionHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupSearchRegion(false);
      //검색시작
      setPageNum(1);
    } else {
      onPopupWinHandler(bmode);
      setPopupSearchRegion(bmode);
    }
  }

  //키워드 팝업창
  const onPopupSearchKeywordHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupSearchKeyword(false);
      //검색시작
      setPageNum(1);
    } else {
      setPopupSearchKeyword(bmode);
      onPopupWinHandler(bmode);
    }
  }

  useEffect(() => {
    //console.log(varSearchingString,varPageNum,varSearchRegionList);
    //if(!(varStartMode && varPageNum === 1)) {
      getPartnerList();
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSearchingString,varSearchRegionList,varBusiness]);  

  useEffect(() => {
    //console.log(varSearchingString,varPageNum,varSearchRegionList);
    if(!(varStartMode && varPageNum === 1)) {
      getPartnerList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum]); 

  return (
    <>
      <Header/>
      <HeadTitle titleString={"파트너스 장터"}/>
      <div id="content" style={{paddingBottom:"40px"}}>
        {/* 배경죽이는 항목 */}
        <div className="layer-bg"></div>  
        {/* 검색할 지역 선택 및 검색문자 입력 */}
        <div className="main_wrap">
          <SearchHeader varSearchingString={varSearchingString} setSearchingString={setSearchingString} onPopupSearchKeywordHandler={onPopupSearchKeywordHandler} 
                    varSearchRegionList={varSearchRegionList} setSearchRegionList={setSearchRegionList}
                    onPopupRegionHandler={onPopupRegionHandler} setPageNum={setPageNum}/>
          <div className="main_con">
            {/* 프리미엄광고 표시부 */}
            <PremiumPartnerList varBusiness={varBusiness} varSearchRegionList={varSearchRegionList}/>
            {/* 검색결과 파트너스 목록 표시부 */}
            <SearchedPartnerResult varBusiness={varBusiness} varTotalCount={varTotalCount} varPartnerList={varPartnerList} 
                setPartnerList={setPartnerList} varMorePage={varMorePage} varPageNum={varPageNum} setPageNum={setPageNum}/>
          </div>
          {/* 지역선택팝업창------ */}
          <SelectRegion varPopupSearchRegion={varPopupSearchRegion} onPopupRegionHandler={onPopupRegionHandler}
                            varSearchRegionList={varSearchRegionList} setSearchRegionList={setSearchRegionList} />
          {/* 키워드 검색 팝업창--------- */}  
          <SearchKeyword varPopupSearchKeyword={varPopupSearchKeyword} onPopupSearchKeywordHandler={onPopupSearchKeywordHandler}
                    setSearchingString={setSearchingString}/>
        </div>
        <FooterCom/>
      </div>
      <Footer varPageMode={2} />
    </>
  );
}
export default PartnerList;