/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 

import removeImage from "../../images/icon_del_btn.png";

const PhotoTempForm = ({idx,photo,onDeleteTempProductPhotoHandler,onShowBigSizePhotoHandler}) => {
  const photoImage = photo.file_preview;
  return (
    <div className="e_wrap">
      <div className="image-block-area">
        <div className="image-box">
          <img className="btn-delete-image" src={removeImage} alt="" onClick={(e)=>onDeleteTempProductPhotoHandler(e,idx)}/>
          <img className="request-image" src={photoImage} alt="" onClick={(e)=>onShowBigSizePhotoHandler(e,photoImage)}/>
        </div>
      </div>
    </div>
  );
}
export default PhotoTempForm;