/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"; 

import { isObjEmpty,DisplayNumber } from "../../components/common/Common"
import SupportMoney from '../popup/SupportMoney';
import supportIcon from "../../images/icon_mark01.png";

const DetailRegister = ({varProductData,onChangeInputHandler,onPopupWinHandler}) => {
  const [varPopupSupportMoney,setPopupSupportMoney] = useState(false);
  //지원금안내 팝업창 열기/닫기
  const onPopupSupportMoneyHandler = (e,bmode) => {
    e.preventDefault();
    setPopupSupportMoney(bmode);
    onPopupWinHandler(bmode);
  }
  return isObjEmpty(varProductData) ? null : ( 
    <>
      <div className="tit07_1">등록 옵션</div>
      <div className="form_wrap2">
        <div className="tit07_2">부동산 지원금 <img src={supportIcon} alt="" className="jiwon_open_btn" onClick={e=>onPopupSupportMoneyHandler(e,true)}/></div>
        <div className="has_btn has_danwe">
            <input type="text" name="rp_grants" className="one-line" placeholder="0" value={DisplayNumber(varProductData.rp_grants)} onChange={e=>onChangeInputHandler(e)}/>
            <span className="danwe_txt">&nbsp;만원</span>
        </div>
      </div>
      <div className="form_wrap">   
        <div className="tit07_1">매물 설명 <span className="star_red"> (필수)</span></div>
        <textarea name="rp_information" cols="30" rows="5" style={{maxWidth:'100%',minWidth:'100%',width:'100%'}} 
              placeholder="직접 입력해주세요." value={varProductData.rp_information} onChange={e=>onChangeInputHandler(e)}/>
      </div>
      {/* 지원금안내 팝업창 */}
      <SupportMoney varPopupSupportMoney={varPopupSupportMoney} onPopupSupportMoneyHandler={onPopupSupportMoneyHandler}/>
    </>
  );
}
export default DetailRegister;