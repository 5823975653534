import React from "react";
import { Link } from "react-router-dom";
/* import axios from 'axios';
import CONFIG from '../../../configs/Configs'; */

import {ChangeDateStringOnlyDate,ResetCheckBoxes} from "../../../components/common/Common";

/* 사업자 게시물 목록 표시 페이지 */
function PartnerSearchResult({varMaxNumPage,varPartnerLists,setPartnerLists,onDeletePartnerHandler}) {

  //목록의 모든 항목 선택하기
  const onCheckedAll = (e) => {
    const bChecked = Number(e.currentTarget.checked);
    clearBoardLists(bChecked);
    //배열값 변경
    let copyDatas = [...varPartnerLists];
    for (var i=0; i < copyDatas.length; i++) {
      copyDatas[i].checked = bChecked;
    }
    setPartnerLists(copyDatas);
  } 
  //목록상의 항목 선택하기
  const onCheckedElement = (e,jp_idx) => {
    const bChecked = Number(e.currentTarget.checked);
    const findIndex = varPartnerLists.findIndex(element=>element.jp_idx===jp_idx);
    let copyLists = [...varPartnerLists];
    copyLists[findIndex].checked = bChecked;
    setPartnerLists(copyLists);
    ResetCheckBoxes();
  } 
  //체크박스 초기화
  const clearBoardLists = (bmode) => {
    const checkBoxes = document.querySelectorAll('.del-item');
    for(var i=0; i < checkBoxes?.length; i++) {
      checkBoxes[i].checked = bmode;
    }
  }

  return (
    <div className="main-box">
      <div className="btn-right">
        {/* <Link to={`/Admin/ProductList/PartnerDetail/0`} className="btn-danger">추가</Link> */}
        <button onClick={(e)=>onDeletePartnerHandler(e)} className="btn line">삭제</button>
      </div>
      <table className="list">
        <colgroup>
          <col style={{width:"5%"}} />
          <col style={{width:"5%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"16%"}} />
          <col style={{width:"14%"}} />
          <col style={{width:"12%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"8%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"10%"}} />
        </colgroup>
        <thead>
          <tr>
            <th className="board-list">
              <input type="checkbox" id="deleteItems" onChange={(e)=>onCheckedAll(e)} />
            </th>
            <th>번호</th>
            <th>등록코드</th>
            <th>등록사업자</th>
            <th>게시분야</th>
            <th>등록일자</th>
            <th>종료일자</th>
            <th>게시상태</th>
            <th>진행상태</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {varPartnerLists.map((product, i) => (
            <tr key={'product_'+product.jp_idx}>
              <td className="board-list">
                <input type="checkbox" className={"del-item"} id={"deleteItem_" + product.jp_idx} onChange={(e)=>onCheckedElement(e,product.jp_idx)}/>
              </td>
              <td>{varMaxNumPage - i}</td>
              <td className="ellipsis">{product.jp_code}</td>
              <td className="ellipsis">{product.mcm_name}</td>
              <td className="ellipsis">{product.jp_names}</td>
              <td className="ellipsis">{ChangeDateStringOnlyDate(product.jp_rdate)}</td>
              <td className="ellipsis">{ChangeDateStringOnlyDate(product.jp_edate)}</td>
              <td className="ellipsis">{product.jp_show===1?"게시중":"게시중지"}</td>
              <td className="ellipsis">{product.jp_status===1?"등록완료":product.jp_status===2?"내장터등록":"종료"}</td>
              <td>
                <Link to={`/Admin/ProductList/PartnerDetail/${product.jp_idx}`} style={{width:"90px"}} className="btn line">세부정보</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PartnerSearchResult;