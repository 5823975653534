/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 

import closeIcon from '../../images/icon_close.png';

//지원금 팝업창
const SupportMoney = ({varPopupSupportMoney,onPopupSupportMoneyHandler}) => {

  return (
    <div className="layer-pop h_auto" style={varPopupSupportMoney?{display:"block"}:{display:"none"}}>
      <div className="close_layer top_close_btn"><img src={closeIcon} alt="" onClick={e=>onPopupSupportMoneyHandler(e,false)}/></div>
      <div className="layer_inner2">
        <div className="con_box">
          <strong>지원금이란?</strong>
          <div className="txt01">매물을 게시한 회원이 매물을<br/>계약하는 회원에게 지원해주는<br/>보너스 지원금 입니다</div>
        </div>
      </div>
      <div className="btn_bottom one_btn close-layer">
        <button type="button" className="btn_style_yes" onClick={e=>onPopupSupportMoneyHandler(e,false)}>확인</button>
      </div>
    </div>
  );
}
export default SupportMoney;