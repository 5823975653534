import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";
import { isNull, remainDays, removeThousandPoint, unescapeHtml, CheckLoginStateOnly, OnlyNumerics, ChangeSearchingDateFormat, ResetDates, isNullExceptZero } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import DetailRushSale from '../../components/doongji/DetailRushSale';
import DetailPhotos from '../../components/doongji/DetailPhotos';
import DetailAdvertiseTerms from '../../components/doongji/DetailAdvertiseTerms';
import DetailAddress from '../../components/doongji/DetailAddress';
import DetailPrice from '../../components/doongji/DetailPrice';
import DetailBuilding from '../../components/doongji/DetailBuilding';
import DetailLocation from '../../components/doongji/DetailLocation';
import DetailOptions from '../../components/doongji/DetailOptions';
import DetailRegister from '../../components/doongji/DetailRegister';
import DetailStatus from '../../components/doongji/DetailStatus';
import ShowBigSizePhoto from '../../components/popup/ShowBigSizePhoto';
import DoongjiPreview from "../../components/popup/DoongjiPreview";

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";

const DoongjiDetail = () => {
  //둥지 매물 고유번호 --> rp_idx=0:신규등록
  let { rp_idx } = useParams();
  const navigate = useNavigate();

  //로그인 점검 - 채팅불가
  const loginState = CheckLoginStateOnly();
  if (!loginState) {
    alert('둥지매물정보를 변경하고자 하시면 먼저 로그인을 진행해주세요.');
    navigate('/Login');
  }

  const mem_idx = Number(localStorage.getItem("mem_idx"));
  let mcm_idx = Number(localStorage.getItem("mcm_idx"));
  if (isNull(mcm_idx)) {
    mcm_idx = 0;
    localStorage.setItem("mcm_idx", 0);
  }
  const mem_mode = Number(localStorage.getItem("mem_mode"));

  //1:일반매출/2:중개매물
  let reg_mode = 1;
  //부동산업자유무
  if (mem_mode === 1) reg_mode = 2;

  const [backString, setBackString] = useState(''); // 추가된 상태

  // 중복제출 방지
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 로딩 상태 관리
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 750) {
        setBackString('nonWebDoongji');
      }
    };

    handleResize(); // 초기 렌더링 시 상태 설정
    window.addEventListener('resize', handleResize); // 창 크기 변경 감지

    return () => window.removeEventListener('resize', handleResize); // 정리 작업
  }, []);

  //매물정보 저장 객체
  const newData = { rp_idx: rp_idx, rp_mode: reg_mode, rp_rush_sale: 0, rp_title: "", rp_serious: 0, rp_terms: 0, rp_hidden_address: 0, rp_zipcode: "",
    rp_address1: "", rp_address2: "", rp_regcode: "", rp_dongname: "", rp_roadname: "", rp_name: "", rp_address_hidden: 1, rp_sector: "",
    rp_bd_num: "", rp_rm_num: "", rp_purpose: 0, rp_type: 0, rp_deposit: null, rp_monthly: null, rp_admin_expense: null, rp_right_money: null, rp_youtube: null,
    rp_include: "", rp_mortgage: null, rp_loan: 0, rp_deposit_now: null, rp_monthly_now: null, rp_sale_area: null, rp_private_area: null, rp_location: "",
    rp_use_alone: 0, rp_electronic: null, rp_floor_height: null, rp_bd_location: 0, rp_rooms: null, rp_baths: null, rp_bath_side: 0,
    rp_structure: 0, rp_cool_heat: 0, rp_exist_floor_type: 0, rp_exist_floor: null, rp_total_floor: null, rp_can_parking: 0, rp_status: 1,
    rp_exist_parking: null, rp_total_parking: null, rp_terrace: 0, rp_direction: 0, rp_elevator: 0, rp_can_enter: 0, rp_can_enter_date: null,
    rp_can_enter_state: 0, rp_used_app_date: null, rp_can_pets: 0, rp_subway_line: "", rp_subway_station: 0, rp_subway_more: null,
    rp_bus: "", rp_bus_more: null, rp_market_more: null, rp_mart_more: null, rp_school: "", rp_school_more: null, rp_cctv: 0, rp_translate: 0,
    rp_languages: "", rp_options: "", rp_grants: null, rp_information: "", rp_hidden_phone: 0, rp_register_type: 0, rp_items: "",
    rp_manage_agree: 0, rp_supplyinfo_agree: 0, rp_mem_idx: mem_idx, rp_mcm_idx: mcm_idx, rp_enter_date: null, rp_theme: "", rp_rdate: null,
    rp_purpose_region: 0, rp_bd_structure: 0, rp_purpose_land: 0, rp_edate: null, mem_mode: mem_mode, jd_idx: null, jd_status: null
  };
  const [varProductData, setProductData] = useState({});
  const [varProductPhotos, setProductPhotos] = useState([]); //이미 저장된 매물사진 목록
  const [varTempProductPhotos, setTempProductPhotos] = useState([]); //신규 매물 사진 목록 - 선택된 사진 저장 배열
  const [varTempProductPhotoFiles, setTempProductPhotoFiles] = useState([]); //신규 매물 사진 정보 목록 - 선택된 사진 정보 저장 배열
  const [varEmptyProductPhotos, setEmptyProductPhotos] = useState([]); //신규 매물 사진 목록 - 비어 있는 사진 저장 배열

  const [varSubwayLines, setSubwayLines] = useState([]);
  const [varSubwayStations, setSubwayStations] = useState([]);
  const [varBuildingPurpose, setBuildingPurpose] = useState([]);
  const [varIncludeOptions, setIncludeOptions] = useState([]);
  const [varTerms, setTerms] = useState({});
  const [varPurposeRegions, setPurposeRegions] = useState([]); //용도지역
  const [varPurposeLands, setPurposeLands] = useState([]); //지목
  const [varBuildingStructure, setBuildingStructure] = useState([]); //건축구조
  const [varRushSales, setRushSales] = useState([]);

  //등록가능여부
  const [varRegisterAvailable, setRegisterAvailable] = useState(true);

  const [varPreview, setPreview] = useState(false);
  const [varPreviewIdx, setPreviewIdx] = useState(0);

  //매물종류목록
  const [varSeriousList, setSeriousList] = useState([]);
  //거래유형목록
  const [varTypesList, setTypesList] = useState([]);
  //내부구조
  const [varStructureList, setStructureList] = useState([]);
  //냉난방
  const [varCoolHeatList, setCoolHeatList] = useState([]);
  //방향-현관기준
  const [varDirectionList, setDirectionList] = useState([]);
  //건물위치
  const [varLocationList, setLocationList] = useState([]);
  //업종
  const [varSecterList, setSecterList] = useState([]);
  //종목
  const [varItemsList, setItemsList] = useState([]);
  //테마
  const [varThemeList, setThemeList] = useState([]);

  //보유현황관련
  const [varRemainDays, setRemainDays] = useState(0);
  const [varUsedSlots, setUsedSlots] = useState(0);
  const [varHavingSlots, setHavingSlots] = useState(0);

  //rp_status
  const [varStatus, setStatus] = useState(0);

  // 로딩 사진등록 현황
  const [totalPhotos, setTotalPhotos] = useState(0);
  const [processedPhotos, setProcessedPhotos] = useState(0);

  //매물정보 읽어오기
  const getProductInformation = async () => {
    const sendData = { rp_idx: rp_idx, mem_idx: mem_idx };
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_doongji_information`, sendData).then((res) => {
      const datas = res.data;
      // console.log(datas);
      if (datas.retvalue === 1) {
        let photoMax = 15;
        let dataInfos = {};
        if (datas.results?.length === 1) {
          dataInfos = datas.results[0];
          setStatus(dataInfos.rp_status);

          if (dataInfos.rp_status === 0) {
            dataInfos.rp_rush_sale = 0;
          }

          //매물사진정보
          setProductPhotos(datas.photos);
          photoMax = photoMax - datas.photos?.length;

          const today = ChangeSearchingDateFormat(new Date());
          const edate = dataInfos.rp_edate;

          //임시저장의 경우
          if (dataInfos.rp_status === 1) {
            dataInfos.rp_terms = 0;
            dataInfos.rp_rush_sale = 0;
            dataInfos.rp_edate = null;
          } else {
            if (isNull(edate)) {
              dataInfos.rp_edate = ResetDates(today, 2, dataInfos.rp_terms);
            }
          }
          // 매물재개 시 광고게재기간 초기화
          if (dataInfos.rp_status === 0) dataInfos.rp_terms = 0;
          if (edate < today) dataInfos.rp_terms = 0;
          //매물정보
          setProductData(dataInfos);
          console.log("dataInfos: ", dataInfos);
        } else {
          dataInfos = { ...newData };
        }

        //매물사진 15개까지 등록가능 --> 빈 공간 저장배열 만들기
        const photoData = { file_idx: 0, file_name: "", file_preview: "" };
        let photoArray = [];
        for (var i = 0; i < photoMax; i++) {
          photoArray.push(photoData);
        }
        setEmptyProductPhotos(photoArray);
        //지하철노선
        setSubwayLines(datas.subwaylines);
        //지하철역
        setSubwayStations(datas.subwaystations);
        //건축물유형
        setBuildingPurpose(datas.purposes);
        //옵션
        setIncludeOptions(datas.options);
        //급매 수 제한
        setRushSales(datas.rushsales);

        //약관
        const tObj = datas.terms;
        tObj.product_manage = unescapeHtml(tObj.product_manage);
        tObj.supply_information = unescapeHtml(tObj.supply_information);
        const termsObj = { product_manage_title: "매물 관리 정책 동의", supply_information_title: "제3자 정보 제공 동의",
          product_manage: tObj.product_manage, supply_information: tObj.supply_information };
        setTerms(termsObj);
        //용도지역
        setPurposeRegions(datas.purposeregions);
        //지목
        setPurposeLands(datas.purposelands);
        //건축구조
        setBuildingStructure(datas.buildstructure);
        //종목
        setItemsList(datas.bizitems);
        //건물위치
        setLocationList(datas.bdlocations);

        //슬럿보유현황 및 상황분석
        let maxCanUseSlots = 2; //일반회원 등록가능숫자
        let remaindays = 90;
        //부동산사업자이고 신규 또는 등록중인 경우
        if (mem_mode === 1) {
          //등록불가
          if (datas.accounts?.length === 0) setRegisterAvailable(false);

          //현재 보유 물량
          const accounts = datas.accounts[0];
          maxCanUseSlots = accounts.doongji_slots;   //보유슬럿
          remaindays = remainDays(accounts.doongji_expdate); //남은 기간 계산
          //console.log(remaindays);
          //구매수량이 없거나 --> //등록불가
          if (maxCanUseSlots === 0 || remaindays < 0) setRegisterAvailable(false);
        }
        setHavingSlots(maxCanUseSlots);
        setRemainDays(remaindays);

        //현재 게시된 등록수
        const alreadyUsedSlot = datas.slotcount;
        setUsedSlots(alreadyUsedSlot);
        if (maxCanUseSlots < alreadyUsedSlot) {
          setRegisterAvailable(false);
        } else {
          if (maxCanUseSlots === alreadyUsedSlot) {  //빈슬럿이 없는 경우
            //신규 또는 임시저장이 호출된 경우
            if (Number(rp_idx) === 0 || dataInfos.rp_status === 1) {
              setRegisterAvailable(false);
            }
          }
        }
        //매물종류
        const seriouslist = datas.filters.filter(element => element.vsm_idx === 1);
        setSeriousList(seriouslist);
        //거래유형
        const typeslist = datas.filters.filter(element => element.vsm_idx === 2);
        setTypesList(typeslist);
        //내부구조
        const structurelist = datas.filters.filter(element => element.vsm_idx === 10);
        setStructureList(structurelist);
        //냉난방
        const collheatlist = datas.filters.filter(element => element.vsm_idx === 14);
        setCoolHeatList(collheatlist);
        //방향-현관기준
        const directionlist = datas.filters.filter(element => element.vsm_idx === 15);
        setDirectionList(directionlist);
        //업종
        const secterlist = datas.filters.filter(element => element.vsm_idx === 11);
        setSecterList(secterlist);
        //테마
        const themelist = datas.filters.filter(element => element.vsm_idx === 12);
        setThemeList(themelist);
      }
    });
  }


  const CanEnterCheck = useCallback(() => {
    if (!varRegisterAvailable) {
      if (mem_mode === 0 || mem_mode === 2) {  // 일반회원 && 파트너스
        if (varHavingSlots === varUsedSlots) {
          alert('이미 2개의 둥지매물이 등록되어 있습니다.\n무료로 등록할 수 있는 둥지매물은 2개까지입니다.');
          navigate(-1);
        } else {
          alert('무료로 등록할 수 있는 둥지매물은 2개까지입니다.');
          navigate(-1);
        }
      } else {
        if (varHavingSlots === 0 || varRemainDays < 0) {
          alert('보유한 이용권이 없습니다. 이용권 구매 후 매물등록이 가능합니다.');
          navigate('/MyPage/BuyVoucher/0');
        } else if (varProductData.rp_idx === "0" && varHavingSlots === varUsedSlots) {
          if (!window.confirm(`구매한 이용권을 통하여 둥지매물이 이미 ${varHavingSlots}개가 등록되어 있습니다.`)){
            navigate(-1);
            return false;
          } else{
            navigate(-1);
            return false;
          }
        } else {
          if (!window.confirm('이미 게시된 매물들로 인하여 추가로 매물을 게시할 수 없습니다.')){
            navigate(-1);
            return false;
          }
          else{
            navigate(-1);
            return false;
          }
        }
      }
      return false;
    }
  }, [varRegisterAvailable, mem_mode, varHavingSlots, varUsedSlots, varRemainDays, varProductData, navigate]);
  

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if (Number(rp_idx) === 0) {
      //게재종료일자 초기화
      const today = ChangeSearchingDateFormat(new Date());
      newData.rp_edate = ResetDates(today, 2, 15);
      setProductData(newData);
    }

    getProductInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    CanEnterCheck();
  }, [CanEnterCheck]);

  //라디오버튼 변경
  const onSetShowTermsHandler = (e) => {
    const { name, value } = e.currentTarget;

    const idays = Number(value);
    const tday = ChangeSearchingDateFormat(new Date());
    const expdate = ResetDates(tday, 2, idays);
    //console.log(idays,tday,expdate);
    setProductData({ ...varProductData, [name]: idays, rp_edate: expdate });
  }
  //선택창 변경 
  const onChangeSelectHandler = (e) => {
    const { name, value } = e.currentTarget;
    console.log(name, value);
    if(name === "rp_serious"){
      setProductData({ ...varProductData, [name]: Number(value), rp_options: "" });
    } else {
      setProductData({ ...varProductData, [name]: Number(value) });
    }
  }
  //입력창 변경
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    if (name === "rp_deposit" || name === "rp_monthly" || name === "rp_admin_expense" || name === "rp_right_money"
      || name === "rp_mortgage" || name === "rp_deposit_now" || name === "rp_monthly_now" || name === "rp_grants") {
      const svalue = OnlyNumerics(value);
      const swon = removeThousandPoint(svalue);
      setProductData({ ...varProductData, [name]: Number(swon) });
    } else if (name === "rp_sale_area" || name === "rp_private_area" || name === "rp_rooms" || name === "rp_baths"
      || name === "rp_exist_parking" || name === "rp_total_parking" || name === "rp_total_floor" || name === "rp_exist_floor"
      || name === "rp_subway_more" || name === "rp_bus_more" || name === "rp_market_more" || name === "rp_mart_more"
      || name === "rp_school_more") {
      const svalue = OnlyNumerics(value);
      //console.log(value,svalue);
      setProductData({ ...varProductData, [name]: Number(svalue) });
    } else if (name === "rp_address_hidden" || name === "rp_address_hidden2" || name === "rp_address_hidden3" || name === "rp_manage_agree"
      || name === "rp_supplyinfo_agree" || name === "rp_hidden_address") {
      const ichecked = Number(e.currentTarget.checked);
      let sname = name;
      if (name === "rp_address_hidden" || name === "rp_address_hidden2" || name === "rp_address_hidden3") {
        let disableBox = null;
        let disableBox2 = null;
        sname = "rp_address_hidden";
        if (name === "rp_address_hidden2") {
          disableBox = document.getElementById('address2');
        } else if (name === "rp_address_hidden") {
          disableBox = document.getElementById('build_name');
        } else if (name === "rp_address_hidden3") {
          disableBox = document.getElementById('rp_bd_num');
          disableBox2 = document.getElementById('rp_rm_num');
        }
        if (disableBox != null) {
          if (ichecked) {
            if (!disableBox.classList.contains('disabled')) disableBox.classList.add('disabled');
          } else {
            if (disableBox.classList.contains('disabled')) disableBox.classList.remove('disabled');
          }
        }
        if (disableBox2 != null) {
          if (ichecked) {
            if (!disableBox2.classList.contains('disabled')) disableBox2.classList.add('disabled');
          } else {
            if (disableBox2.classList.contains('disabled')) disableBox2.classList.remove('disabled');
          }
        }
      }
      setProductData({ ...varProductData, [sname]: ichecked });
    } else if (name === "rp_bath_side") {
      let ichecked = Number(e.currentTarget.checked);
      let icheckedother = 0;
      if (Number(value) === 1) {
        if (document.getElementById('rp_bath_side2').checked) icheckedother = 2;
      } else {
        if (ichecked) ichecked = 2;
        if (document.getElementById('rp_bath_side1').checked) icheckedother = 1;
      }
      const totalchecked = ichecked + icheckedother;
      setProductData({ ...varProductData, [name]: totalchecked });
    } else {
      //console.log(value);
      setProductData({ ...varProductData, [name]: value });
    }

    if (name === "rp_can_enter") {
      const enterDateBox = document.getElementById('rp_can_enter_date');
      const enterStateBox = document.getElementById('rp_can_enter_state');
      //console.log(enterDateBox,enterStateBox);
      if (Number(value) === 0) {
        if (!enterDateBox.classList.contains('disable')) enterDateBox.classList.add('disable');
        if (!enterStateBox.classList.contains('disable')) enterStateBox.classList.add('disable');
      } else {
        if (enterDateBox.classList.contains('disable')) enterDateBox.classList.remove('disable');
        if (enterStateBox.classList.contains('disable')) enterStateBox.classList.remove('disable');
      }
    } else if (name === "rp_exist_floor_type") {
      const existFloorBox = document.getElementById('rp_exist_floor');
      if (Number(value) === 0) {
        if (existFloorBox.classList.contains('disable')) existFloorBox.classList.remove('disable');
      } else {
        if (!existFloorBox.classList.contains('disable')) existFloorBox.classList.add('disable');
      }
    } else if (name === "rp_can_parking") {
      const existBox = document.getElementById('rp_exist_parking');
      const totalBox = document.getElementById('rp_total_parking');
      if (Number(value) === 0) {
        if (!existBox.classList.contains('disable')) existBox.classList.add('disable');
        if (!totalBox.classList.contains('disable')) totalBox.classList.add('disable');
      } else {
        if (existBox.classList.contains('disable')) existBox.classList.remove('disable');
        if (totalBox.classList.contains('disable')) totalBox.classList.remove('disable');
      }
    }
  }

  const onChangeRadioInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    //console.log('radio',name,value);
    setProductData({ ...varProductData, [name]: value });
  }

  //매물사진 확대하기
  const [varOpenPhoto, setOpenPhoto] = useState(false);
  const [varPhotoImage, setPhotoImage] = useState(null);
  const onShowBigSizePhotoHandler = (e, files) => {
    e.preventDefault();
    setPhotoImage(files);
    onPopupWinHandler(true);
    setOpenPhoto(true);
  }
  const onOpenPhotoHandler = (e, bmode) => {
    onPopupWinHandler(bmode);
    setOpenPhoto(bmode);
  }
  const onPopupWinHandler = (bmode) => {
    //console.log(bmode);
    if (bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }

  //미리보기
  const onPreviewDoongjiHandler = (e) => {
    //임시저장/저장 --> 보기창으로
    onUpdateDoongjiHandler(e, 3);
  }

  //미리보기 로딩 표시 함수
  const showLoading = () => {
    setIsLoading(true);
  }

  const hideLoading = () => {
    setIsLoading(false);
  }

  //임시저장/저장
  const onUpdateDoongjiHandler = async (e, imode) => {
    // imode -> 1: 임시저장 2: 등록완료 3: 미리보기
    if (imode === 2) { //등록완료
      // 등록 시 바로 알럿뜨도록 변경
      if (!varRegisterAvailable) {
        if (mem_mode === 0 || mem_mode === 2) {  //일반회원 && 파트너스
          if (varHavingSlots === varUsedSlots)
            alert('이미 2개의 둥지매물이 등록되어 있습니다.\n무료로 등록할 수 있는 둥지매물은 2개까지입니다.');
          else
            alert('무료로 등록할 수 있는 둥지매물은 2개까지입니다.');
        }
        else {
          if (varHavingSlots === 0 || varRemainDays < 0) {
            if (!window.confirm('보유한 이용권이 없습니다. 이용권 구매 후 매물등록이 가능합니다.')) return false;
          }
          else if (varProductData.rp_idx === "0" && varHavingSlots === varUsedSlots) {
            if (!window.confirm(`구매한 이용권을 통하여 둥지매물이 이미 ${varHavingSlots}개가 등록되어 있습니다.`)) return false;
          }
          else {
            if (!window.confirm('이미 게시된 매물들로 인하여 추가로 매물을 게시할 수 없습니다.')) return false;
          }
        }
        if (mem_mode === 0 || mem_mode === 2) return false;
        // if(linkBackUrl === "/RealEstate") navigate(linkBackUrl);
        // else navigate('/Mypage/BuyVoucher/0');
        return false;
      }

      if (varProductData.rp_youtube?.includes("www.youtube.com/watch?v=")) {
        alert('www.youtube.com/watch?v= 이후 주소를 입력해주세요'); return false;
      }

      if (varProductData.rp_title === "") {
        alert('매물명을 먼저 입력하십시오.'); return false;
      } else if (varProductData.rp_serious === 0) {
        alert('매물종류을 먼저 선택하십시오'); return false;
      } else if (varProductData.rp_terms === 0 || isNull(varProductData.rp_edate)) {
        alert('광고게재기간을 먼저 선택하십시오'); return false;
      } else if (varProductData.rp_hidden_address === 0 && varProductData.rp_zipcode === "") {
        alert('매물 주소지를 먼저 선택하십시오'); return false;
        /* } else if((varProductData.rp_serious===1 || varProductData.rp_serious===3) && varProductData.rp_name === "") {
          alert('단지명을 먼저 선택하십시오'); return false; */
        /* } else if((varProductData.rp_serious===1 || varProductData.rp_serious===3) && varProductData.rp_bd_num === "") {
          alert('동번호를 먼저 입력하십시오'); return false; */
        /* } else if((varProductData.rp_serious<=4 && varProductData.rp_serious!==2) && varProductData.rp_rm_num === "") {
          alert('호번호를 먼저 입력하십시오'); return false; */
      } else if (varProductData.rp_serious !== 9 && varProductData.rp_purpose === 0) {
        alert('건축물용도를 먼저 선택하십시오'); return false;
      } else if (varProductData.rp_can_enter === 1 && isNull(varProductData.rp_can_enter_date)) {
        alert('입주가능일자를 먼저 선택하십시오'); return false;
      }
      if (varProductData.rp_serious === 8 || varProductData.rp_serious === 9 || varProductData.rp_serious === 10) {
        if (varProductData.rp_purpose_region === 0) {
          alert('용도지역을 먼저 선택하십시오'); return false;
        }
      }
      if (varProductData.rp_serious === 9 && varProductData.rp_purpose_land === 0) {
        alert('지목을 먼저 선택하십시오'); return false;
      }
      if (varProductData.rp_serious === 10 && varProductData.rp_bd_structure === 0) {
        alert('건축구조를 먼저 선택하십시오'); return false;
      }
      if (varProductData.rp_type === 0) {
        alert('거래 유형을 먼저 선택하십시오'); return false;
      }
      if (isNull(varProductData.rp_deposit)) {
        let smess = "";
        if (varProductData.rp_type === 0) smess = "매매가/전세금";
        else if (varProductData.rp_type === 11) smess = "매매가";
        else if (varProductData.rp_type === 12) smess = "전세금";
        else if (varProductData.rp_type === 15) smess = "분양가";
        else smess = "보증금";
        alert(smess + '을 먼저 입력하십시오'); return false;
      }
      if (varProductData.rp_type !== 15 && varProductData.rp_type !== 11 && varProductData.rp_type !== 12) {
        if (isNull(varProductData.rp_monthly)) {
          alert('월세를 먼저 입력하십시오'); return false;
        }
      }
      if (varProductData.rp_serious !== 9) {
        if (isNullExceptZero(varProductData.rp_admin_expense)) {
          alert('관리비를 먼저 입력하십시오'); return false;
        }
      }
      /* if(varProductData.rp_serious >= 6 && varProductData.rp_serious!==9){
        if(isNull(varProductData.rp_right_money)) {
          alert('권리금을 먼저 입력하십시오'); return false;
        }
      } */
      if (varProductData.rp_serious === 9) { //토지
        if (isNull(varProductData.rp_sale_area)) {
          alert('대지면적을 먼저 입력하십시오'); return false;
        }
      } else {
        let smess = "전용면적";
        if (varProductData.rp_serious === 4 || varProductData.rp_serious === 5 || varProductData.rp_serious === 8 || varProductData.rp_serious === 10) {
          smess = "연면적";
        }
        if (isNull(varProductData.rp_private_area)) {
          alert(smess + '을 먼저 입력하십시오'); return false;
        }
      }
      if (varProductData.rp_serious === 8) { //빌딩
        if (varProductData.rp_bd_location === 0) {
          alert('건물위치를 먼저 선택하십시오'); return false;
        }
      } else {
        let smess = "방";
        if (varProductData.rp_serious === 6 || varProductData.rp_serious === 7) smess = "룸";
        if (varProductData.rp_serious !== 9 && varProductData.rp_serious !== 10 && isNull(varProductData.rp_rooms)) {
          alert(smess + '의 수를 먼저 입력하십시오'); return false;
        }
        if (varProductData.rp_rooms > 200) {
          alert(smess + '의 수를 정확히 입력하십시오'); return false;
        }
      }

      if (varProductData.rp_serious !== 9 && isNull(varProductData.rp_baths)) {
        alert('화장실의 수를 먼저 입력하십시오'); return false;
      }
      if (varProductData.rp_baths > 200) {
        alert('화장실의 수를 정확히 입력하십시오'); return false;
      }
      if (varProductData.rp_serious <= 5 && varProductData.rp_structure === 0) {
        alert('내부 구조를 먼저 선택하십시오'); return false;
      }
      if (varProductData.rp_serious !== 9 && varProductData.rp_cool_heat === 0) {
        alert('냉/난방방식을 먼저 선택하십시오'); return false;
      }
      if (varProductData.rp_serious !== 9 && varProductData.rp_can_parking === 1 && isNull(varProductData.rp_exist_parking)) {
        alert('세대당 주차수를 먼저 입력하십시오'); return false;
      }
      if (varProductData.rp_serious !== 9 && varProductData.rp_can_parking === 1 && isNull(varProductData.rp_total_parking)) {
        alert('총 주차수를 먼저 입력하십시오'); return false;
      }
      if (varProductData.rp_serious !== 9) {
        if (varProductData.rp_exist_floor_type === 0 && isNull(varProductData.rp_exist_floor)) {
          alert('해당층을 먼저 입력하십시오'); return false;
        }
        if (isNull(varProductData.rp_total_floor)) {
          alert('총층수를 먼저 입력하십시오'); return false;
        }
        if (varProductData.rp_direction === 0) {
          alert('방향(현관기준)을 먼저 선택하십시오'); return false;
        }
      }

      if (varProductData.rp_serious !== 9 && isNull(varProductData.rp_used_app_date)) {
        alert('건축물사용승인일을 먼저 선택하십시오'); return false;
      }
      if (varProductData.rp_information === "") {
        alert('매물에 대한 설명을 먼저 입력하세요.'); return false;
      } else if (varProductData.rp_manage_agree !== 1) {
        alert('매물 관리 정책에 먼저 동의하셔야 합니다.'); return false;
      } else if (varProductData.rp_supplyinfo_agree !== 1) {
        alert('제3자 정보 제공에 먼저 동의하셔야 합니다.'); return false;
      }

      if (varProductData.rp_serious !== 9) {
        const tfloor = varProductData.rp_total_floor;
        const efloor = varProductData.rp_exist_floor;
        //console.log('현행층:',efloor,"총층수",tfloor);
        if ((efloor > tfloor)&&(varProductData.rp_exist_floor_type===0)) {
          alert('매물의 해당층이 총층수보다 높습니다. 재입력하세요.'); return false;
        }
      }
      // console.log(varRemainDays);
    }

    //추가체크사항-------
    let smessage = '현재 매물을 저장하시겠습니까?';
    if (imode === 1) { //임시저장
      smessage = '현재 매물을 임시로 저장하시겠습니까?';
      //임시저장
      if (varProductData.rp_status === 0) setProductData({ ...varProductData, rp_status: 1 });
    }
    else { //저장
      //매물등록재개
      if (varProductData.rp_status === 0) setProductData({ ...varProductData, rp_status: 2, rp_show: 2 });
    }

    if (imode < 3) {
      if (!window.confirm(smessage)) return false;
      if (isSubmitting) return; // 이미 제출 중이면 중복 제출 방지
      setIsSubmitting(true); // 제출 중으로 변경
      showLoading();
    } else {
      // 미리보기 클릭 시 로딩 시작
      showLoading();
    }

    // //서버로 업로드할 매물사진을 배열로 저장
    // let PhotoForm = new FormData();
    // for (var i = 0; i < varTempProductPhotoFiles?.length; i++) {
    //   let formData = varTempProductPhotoFiles[i];
    //   PhotoForm.append('doongji', formData.get('doongji'));
    // }

    // console.log('추가된 매물 사진 서버로 업로드 등록', varTempProductPhotoFiles?.length);
    // //추가된 매물 사진 배열을 서버로 업로드 등록
    // if (varTempProductPhotoFiles?.length > 0) {
    //   await axios.post(`${CONFIG.SERVER_HOST}/doongjiArray`, PhotoForm).then((res) => {
    //     console.log(res.data);
    //     if (res.data.retvalue === 1) {
    //       const fileNames = res.data.fileNames; //배열
    //       //console.log(fileNames);
    //       //매물사진 DB 등록
    //       RegisterDoongjiProduct(imode, fileNames);
    //     }
    //   });
    // } 
    // else {  //등록할 매물사진이 없는 경우
    //   RegisterDoongjiProduct(imode, "");
    // }
    // const uploadFile = (fileData) => {
    //   const formData = new FormData();
    //   formData.append('doongji', fileData.get('doongji'));

    //   return axios.post(`${CONFIG.SERVER_HOST}/doongjiArray`, formData)
    //     .then(res => {
    //       if (res.data.retvalue === 1) {
    //         return res.data.fileNames[0]; // 업로드된 파일 이름 반환
    //       } else {
    //         throw new Error('파일 업로드 실패');
    //       }
    //     })
    //     .catch(err => {
    //       console.error('파일 업로드 중 에러 발생:', err);
    //       throw err;
    //     });
    // };
    // 개별 파일 업로드 함수
    const uploadFile = async (fileData) => {
      const formData = new FormData();
      formData.append('doongji', fileData.get('doongji'));
    
      try {
        const res = await axios.post(`${CONFIG.SERVER_HOST}/doongjiArray`, formData);
        if (res.data.retvalue === 1) {
          // Increment processedPhotos after successful upload
          setProcessedPhotos(prev => prev + 1);
          return res.data.fileNames[0]; // Return the uploaded file name
        } else {
          throw new Error('File upload failed');
        }
      } catch (err) {
        console.error('File upload error:', err);
        throw err;
      }
    };
    
    if (varTempProductPhotoFiles?.length > 0) {
      setTotalPhotos(varTempProductPhotoFiles.length);
      setProcessedPhotos(0);
      try {
        // Upload all files
        const uploadTasks = varTempProductPhotoFiles.map(fileData => uploadFile(fileData));
    
        // Wait for all uploads to complete
        const uploadedFileNames = await Promise.all(uploadTasks);
    
        // Register the product with the uploaded file names
        RegisterDoongjiProduct(imode, uploadedFileNames);
      } catch (err) {
        console.error('File upload error:', err);
        alert('파일 업로드 중 오류가 발생했습니다. 다시 시도해주세요.');
        hideLoading(); // Hide the loading overlay
        setIsSubmitting(false); // Reset the submitting state
        return;
      }
    } else {
      // 등록할 매물사진이 없는 경우
      RegisterDoongjiProduct(imode, "");
    }    
  }

  const RegisterDoongjiProduct = async (imode, fileNames) => {
    let rp_idx = varProductData.rp_idx;
    const sendData1 = { saveMode: imode, infos: varProductData };

    await axios.post(`${CONFIG.SERVER_HOST}/_update_doongji_product`, sendData1).then((res) => {
      console.log(res.data);
      if (res.data.retvalue === 1) {
        rp_idx = res.data.rp_idx;
        if (imode !== 3) setProductData({ ...varProductData, [rp_idx]: rp_idx });

        // 기존 사진 추가 로직
        let allFileNames = [];
        // 등록된 것을 저장하는 것이 아니라면
        // if (!(imode === 2 && (varStatus === 2))) {
        if (imode === 1 || (varStatus === 1 && imode === 2) || (varStatus === 0 && imode === 1)) {
          if (fileNames && fileNames.length > 0) {
            // 기존 사진과 새로운 사진을 합치기 전에 중복 제거
            allFileNames = [...fileNames, ...varProductPhotos.map(photo => photo.file_name)].filter((value, index, self) => self.indexOf(value) === index);
          } else {
            // 기존 사진만 포함
            allFileNames = varProductPhotos.map(photo => photo.file_name);
          }
        }
        else if (imode === 3) {
          allFileNames = [...fileNames, ...varProductPhotos.map(photo => photo.file_name)].filter((value, index, self) => self.indexOf(value) === index);
        }
        else {
          // 임시저장 시에는 새로 추가된 파일만 포함
          allFileNames = fileNames;
        }

        if (allFileNames.length > 0) {
          const sendData2 = { saveMode: imode, rp_idx: rp_idx, fileNames: allFileNames, photos: varProductPhotos };
          RegistePhotos(imode, sendData2);
        }
        else {
          if (imode === 3) { //미리보기
            if (varProductPhotos?.length > 0) {
              const sendData2 = { rp_idx: rp_idx, fileNames: allFileNames, photos: varProductPhotos };
              RegisteTempPhotos(sendData2);
            }
            else {
              setPreviewIdx(rp_idx);
              onOpenPreviewHandler(true);
              hideLoading(); // 로딩 완료 후 로딩 표시 숨기기
            }
          } else if ((imode !== varStatus) && varProductPhotos?.length > 0) {
            //임시저장->저장 또는 저장-->임시저장
            const sendData3 = { rp_idx: rp_idx };
            UpdatePhotosAfterChecking(sendData3);
          } else {
            // navigate(`/Doongji/DoongjiView/${rp_idx}`, { replace: true, state: { doubleBack: true } });
            navigate(`/Doongji/DoongjiView/${rp_idx}`);
            hideLoading();
          }
        }
      }
      else {
        alert(res.data.message);
        hideLoading();
      }
      setTotalPhotos(0);
      setProcessedPhotos(0);
    })
    .catch(err => {
      // Handle error
      setTotalPhotos(0);
      setProcessedPhotos(0);
    });
  };

  const RegistePhotos = async (imode, sendData) => {
    console.log('sendData', sendData);
    if (imode === 1 || imode === 3 || (varStatus === 1 && imode === 2)) {
      sendData.fileNames = [
        ...sendData.photos.map(photo => photo.file_name), // 기존 사진
        ...sendData.fileNames.filter(fileName => !sendData.photos.map(photo => photo.file_name).includes(fileName)) // 새로 추가된 사진
      ];
    }
    await axios.post(`${CONFIG.SERVER_HOST}/_register_doongji_photos`, sendData).then((res) => {
      //alert(res.data.message);
      if (imode === 3) { //미리보기
        setPreviewIdx(sendData.rp_idx);
        onOpenPreviewHandler(true);
        hideLoading(); // 로딩 완료 후 로딩 표시 숨기기
      } else {
        //기존에 서버에 저장되어 있는 사진
        if (varStatus !== imode && !(varStatus === 0 && imode === 2)) {
          const sendData3 = { rp_idx: sendData.rp_idx };
          UpdatePhotosAfterChecking(sendData3);
        }
        else{
        // navigate(`/Doongji/DoongjiView/${sendData.rp_idx}`, { replace: true, state: { doubleBack: true } });
          navigate(`/Doongji/DoongjiView/${sendData.rp_idx}`);
        }
      }
    });
  }

  const RegisteTempPhotos = async (sendData) => {
    console.log('sendData', sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_register_temp_doongji_photos`, sendData).then((res) => {
      setPreviewIdx(sendData.rp_idx);
      onOpenPreviewHandler(true);
      hideLoading(); // 로딩 완료 후 로딩 표시 숨기기
    });
  }

  const UpdatePhotosAfterChecking = async (sendData) => {
    console.log('sendData', sendData);
    const uploadedPhotosSet = new Set(varProductPhotos.map(photo => photo.file_name)); // 이미 업로드된 사진들

    for (var i = 0; i < varProductPhotos.length; i++) {
      const fileName = varProductPhotos[i].file_name;
      if (!uploadedPhotosSet.has(fileName)) { // 중복 확인
        let sendData2 = { rp_idx: sendData.rp_idx, fileName: fileName };
        let res = await axios.post(`${CONFIG.SERVER_HOST}/_update_doongji_photos`, sendData2);
        console.log(res);
      }
    }
    // navigate(`/Doongji/DoongjiView/${sendData.rp_idx}`, { replace: true, state: { doubleBack: true } });
    navigate(`/Doongji/DoongjiView/${sendData.rp_idx}`);
    hideLoading();
  }

  //미리보기
  const onOpenPreviewHandler = (bmode) => {
    setPreview(bmode);
  }

  return (
    <>
      <Header />
      <HeadTitle titleString={mem_mode === 1 ? "매물 등록(부동산)" : "매물 등록(일반)"} backString={backString} />
      <div className="layer-bg"></div>

      {/* 로딩 화면 */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-indicator2">
            <div className="loader"></div>
            {totalPhotos > 0 && (
              <div className="photo-progress">
                <div>사진 처리 중</div>
                <div>
                {processedPhotos} / {totalPhotos} 
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div id="content">
        <div className="pd_wrap03">
          <div className="write_wrap">
            <div className="center_wrap">
              {/* 부동산이 등록한 경우만 --- 등록유형 */}
              {mem_mode !== 1 ? null : <DetailRushSale varRushSales={varRushSales} varProductData={varProductData} setProductData={setProductData} />}
              <div className="form_wrap">
                <div className="tit07_1">동영상URL</div>
                <div className="gray_txt_st01">www.youtube.com/watch?v= 이후 주소를 기재해주세요.</div>
                <input type="text" name="rp_youtube" value={varProductData.rp_youtube || ""} onChange={e => onChangeInputHandler(e)} />
                <div className="gray_txt_st02">동영상 등록 시 매물 소개 페이지 상단에 처음으로 노출됩니다.</div>
              </div>
              {/* 매물사진 */}
              <DetailPhotos varMode={1} photoMax={15}
                varProductPhotos={varProductPhotos} setProductPhotos={setProductPhotos}
                varTempProductPhotos={varTempProductPhotos} setTempProductPhotos={setTempProductPhotos}
                varTempProductPhotoFiles={varTempProductPhotoFiles} setTempProductPhotoFiles={setTempProductPhotoFiles}
                varEmptyProductPhotos={varEmptyProductPhotos} setEmptyProductPhotos={setEmptyProductPhotos}
                onShowBigSizePhotoHandler={onShowBigSizePhotoHandler} />
              {/* 매물명 */}
              <div className="form_wrap">
                <div className="tit07_1">매물명&nbsp;<span className="star_red"> (필수)</span></div>
                <input type="text" name="rp_title" value={varProductData.rp_title || ""} placeholder="매물명을 작성해주세요." onChange={e => onChangeInputHandler(e)} />
                <div className="gray_txt_st02">핵심 키워드를 제목에 기재하시면 더욱 광고 효과를 얻을 수 있습니다.</div>
              </div>
              {/* 매물유형 */}
              <div className="form_wrap2">
                <div className="tit07_1">매물 종류&nbsp;<span className="star_red"> (필수)</span></div>
                <select name="rp_serious" className="one-line" value={varProductData.rp_serious} onChange={e => onChangeSelectHandler(e)}>
                  <option value={0}>매물종류선택</option>
                  {varSeriousList.map((serious, i) => (
                    <option key={'serious_' + i} value={serious.vfo_idx}>{serious.vfo_name}</option>
                  ))}
                </select>
              </div>
              {/* 광고게재기간 */}
              <DetailAdvertiseTerms varRemainDays={varRemainDays} varProductData={varProductData} onSetShowTermsHandler={onSetShowTermsHandler} />
              <div className="sale_type_wrap">
                <DetailAddress varProductData={varProductData} setProductData={setProductData} varThemeList={varThemeList}
                  varPurposeRegions={varPurposeRegions} varPurposeLands={varPurposeLands} varBuildingStructure={varBuildingStructure}
                  varSecterList={varSecterList} varItemsList={varItemsList} varBuildingPurpose={varBuildingPurpose}
                  onChangeInputHandler={onChangeInputHandler} onChangeSelectHandler={onChangeSelectHandler} />
                <DetailPrice varProductData={varProductData} setProductData={setProductData} varTypesList={varTypesList}
                  onChangeInputHandler={onChangeInputHandler} onChangeSelectHandler={onChangeSelectHandler}
                  onChangeRadioInputHandler={onChangeRadioInputHandler} />
                <DetailBuilding varProductData={varProductData} setProductData={setProductData} varStructureList={varStructureList}
                  varCoolHeatList={varCoolHeatList} varDirectionList={varDirectionList} varLocationList={varLocationList}
                  onChangeInputHandler={onChangeInputHandler} onChangeSelectHandler={onChangeSelectHandler} />
                <DetailLocation varProductData={varProductData} setProductData={setProductData}
                  varSubwayLines={varSubwayLines} varSubwayStations={varSubwayStations}
                  onChangeInputHandler={onChangeInputHandler} />
                <DetailOptions varProductData={varProductData} setProductData={setProductData}
                  varIncludeOptions={varIncludeOptions} onChangeInputHandler={onChangeInputHandler} />
                <DetailRegister varProductData={varProductData} onChangeInputHandler={onChangeInputHandler} onPopupWinHandler={onPopupWinHandler} />
              </div>
              <DetailStatus varProductData={varProductData} varTerms={varTerms}
                onChangeInputHandler={onChangeInputHandler} onChangeRadioInputHandler={onChangeRadioInputHandler} />
              <div className="btn_bottom three_btn">
                <button className="btn_style_no" onClick={e => onPreviewDoongjiHandler(e)}>미리보기</button>
                <button className="btn_style_yes write_ok_open" onClick={e => onUpdateDoongjiHandler(e, 2)}>등록</button>
                <button className="btn_style_brown" onClick={e => onUpdateDoongjiHandler(e, 1)}>임시저장</button>
              </div>
            </div>
          </div>
        </div>
        {/* 사진확대*/}
        <ShowBigSizePhoto varPhotoImage={varPhotoImage} varOpenPhoto={varOpenPhoto} onOpenPhotoHandler={onOpenPhotoHandler} />
        {varPreviewIdx === 0 ? null : <DoongjiPreview varPreviewIdx={varPreviewIdx} varPreview={varPreview} onOpenPreviewHandler={onOpenPreviewHandler} />}
      </div>
    </>
  );
}
export default DoongjiDetail;