/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

//은행계좌의 목록 표시
function CompanyAccountList({setShowDetailPage,varTotalList,setCurrentAccount,varAccountLists,getCompanyAccountsList}) {

  //은행계좌 정보 편집하기
  const onEditAccountHandler = (e,admin_idx) => {
    console.log(admin_idx,varAccountLists);
    setCurrentAccount(Number(admin_idx));
    setShowDetailPage(true);
  }

  //은행계좌 추가하기
  const onAddAccountHandler = (e) => {
    setCurrentAccount(0);
    setShowDetailPage(true);
  }

  //은행계좌 삭제
  const onDeleteAccountHandler = async (e,bh_idx) => {
    e.preventDefault();
    let sendData = {bh_idx:bh_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_company_account`,sendData).then((res) => {
      const datas = res.data;
      alert(datas.message);
      if(datas.retvalue === 1) {
        //삭제된 배열을 제외한 계좌번호 목록 재설정
        getCompanyAccountsList();
      }
    });
  }
  
  return (
    <div className="content">
      <div className="main-box2">
        <div className="btn-right">
          <button className="btn-danger" style={{width:"100px"}} onClick={(e)=>onAddAccountHandler(e)}>추가등록</button>
        </div>
      </div>
      <div className="main-box">
        <table className="list">
          <colgroup>
            <col width="10%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="20%"></col>
          </colgroup>
          <thead>
            <tr>
              <th>번호</th><th>은행명</th><th>계좌번호</th><th>계좌주</th><th>관리</th>
            </tr>
          </thead>
          <tbody>
            {varTotalList === 0 ? 
              <tr><td colSpan="5" style={{textAlign:"center"}}>등록된 은행계좌가 없습니다.</td></tr>
            : varAccountLists.map((banks, i) => (
              <tr key={'banks'+i}>
                <td><p>{varTotalList-i}</p></td>
                <td><p>{banks.bl_name}</p></td>
                <td><p>{banks.bh_account}</p></td>
                <td><p>{banks.bh_owner}</p></td>
                <td>
                  <button className="btn trline" onClick={(e)=>onEditAccountHandler(e,banks.bh_idx)}>수정</button>
                  <button className="btn trline" onClick={(e)=>onDeleteAccountHandler(e,banks.bh_idx)}>삭제</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CompanyAccountList
