/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ useState,useEffect,useRef } from "react";
import { Link,useNavigate } from "react-router-dom";

import axios from 'axios';
import CONFIG from '../../configs/Configs';
import HeadTitle from '../../components/include/HeadTitle';

import { TimeFormat,sendMessageToMobile,isObjEmpty,randomStringOnlyNumber,OnlyNumerics,AddOptionToString } from '../../components/common/Common';

//아이디 찾기
const FindID = () => {
  const navigate = useNavigate();

  const newMember = {mem_name:"",mem_mobile1:"",mem_mobile2:"",mem_mobile3:"",mem_cert:""};
  const [varMember,setMember] = useState(newMember);
  const chkMember = {mem_mobile:false,mem_certcode:""};
  const [varCheckMember,setCheckMember] = useState(chkMember);

  //타이머
  const [varTime,setTime] = useState(0);
  const [varTimeDisplay,setTimeDIsplay] = useState(0);
  const timerId = useRef(null);
  //휴대폰 인증관련 메뉴 표시 유무
  const [varShowMobile,setShowMobile] = useState(false);
  const [varMobileTimer,setMobileTimer] = useState(false);

  //전화번호
  const hpRef1 = useRef(null); 
  const hpRef2 = useRef(null); 
  const hpRef3 = useRef(null); 

  //입력값 처리
  const onChangeInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    if(name === 'mem_mobile1' || name === 'mem_mobile2' || name === 'mem_mobile3' ) {
      if(varCheckMember.mem_mobile) {
        setCheckMember({...varCheckMember,mem_mobile:false});
      }
      setMember({...varMember,[name]:OnlyNumerics(value)});
    } else {
      setMember({...varMember,[name]:value});
    }
  }

  //휴대폰-----------------------------------------------------------------------------------
  //타이머 시작 및 종료
  useEffect(() => {
    if(varMobileTimer) {
      if (varTime > 0) {
        timerId.current = setInterval(() => {
          setTime(prev => prev - 1);
          setTimeDIsplay(varTime);
        }, 1000);
        return () => {clearInterval(timerId.current);}
      } else {
        console.log("타임 아웃",varTime);
        setTime(0); //타이머 마감표시
        setMobileTimer(false);  //타이머 초기화 선언
        setTimeDIsplay(varTime);  //시간표시
        setShowMobile(false); //핸드폰 인증 메뉴 감추기
        clearInterval(timerId.current); //타이머 종료
        setCheckMember({...varCheckMember,mem_certcode:""});  //인증코드 초기화
        alert('입력시간이 초과 되었습니다. 휴대폰 인증을 새로 진행해주세요.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTime]);

  //인증절차
  const onSendCertificationHandler = (e) => {
    if(varMember.mem_mobile1==="" || varMember.mem_mobile2==="" || varMember.mem_mobile3==="") {
      alert('인증할 휴대폰번호를 먼저 입력하세요.'); return false;
    }
    const mem_mobile = varMember.mem_mobile1+varMember.mem_mobile2+varMember.mem_mobile3;
    //인증코드 생성 및 발송
    const certCode = randomStringOnlyNumber(4);
    setCheckMember({...varCheckMember,mem_certcode:certCode});
    sendMessageToMobile(mem_mobile,certCode);
    setShowMobile(true);
    //타이머 작동 - display:on
    setMobileTimer(true); //타이머 작동시작
    setTime(180);
  }
  //인증코드 입력후 검증
  const onCheckCertificationHandler = (e) => {
    if(varCheckMember.mem_certcode === "") {
      alert('도착한 인증코드를 먼저 입력하세요.'); return false;
    }
    //console.log(varCheckMember.mem_certcode,varMember.mem_cert);
    if(varCheckMember.mem_certcode !== varMember.mem_cert) {
      alert('입력한 코드가 발송한 인증코드와 일치하지 않습니다. 확인후 다시 입력하세요.'); 
      //인증코드부분 초기화
      setMember({...varMember,mem_cert:""});
      return false;
    }
    //인증완성
    setCheckMember({...varCheckMember,mem_mobile:true});
    setMember({...varMember,mem_certification:1});
    setMobileTimer(false);  //타이머 작동 초기화 선언
    setShowMobile(false); //인증부분 감추기
    clearInterval(timerId.current); //타이머 종료
    alert('휴대폰 본인인증이 되었습니다.');
  }


  //아이디 찾기 실행
  const onFindIDHandler = async (e) => {
    if (varMember.mem_mobile1 === "" || varMember.mem_mobile2 === "" || varMember.mem_mobile3 === "") {
      alert('휴대폰 번호를 먼저 입력하세요'); return false;      
    } else if (!varCheckMember.mem_mobile) {
      alert('휴대폰번호를 먼저 인증하세요'); return false;  
    }
    const sendData = {infos:varMember}
    await axios.post(`${CONFIG.SERVER_HOST}/_find_id`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        let memberString = "";
        const members = datas.members;
        for(var i=0; i < members.length; i++) {
          let member = members[i];
          const sline = `${member.mem_mode===0?"일반회원":member.mem_mode===1?"부동산파트너":"사업자파트너"}:${member.mem_name}:${member.mem_email}`;
          memberString = AddOptionToString(sline,memberString,'|');
        }
        localStorage.setItem("foundMembers", memberString);
        console.log(memberString);
        navigate('/Member/FoundIDOK');
      } else {
        alert(datas.message);
      }
    });
  }

  const onKeyPressHandler = (e,ino) => {
    const { value } = e.currentTarget;
    if(ino === 1) {
      if(value.length === 3) hpRef2.current.focus();
    } else if(ino === 2) {
      if(value.length === 4) hpRef3.current.focus();
    }
  }

  return isObjEmpty(varMember)?null:(
    <>
      <HeadTitle titleString={"아이디 찾기"}/>
      <div className="pd_wrap02">
        <div className="find_common_wrap find_account_wrap">
          <div className="pp_top_text_tp01">가입 시 등록했던 정보를 입력해주세요.</div>
          <div className="find_bg_gray">
            <div className="center_wrap">
              <div className="form_wrap">
                <div className="tit07">이름</div>
                <input type="text" name="mem_name" value={varMember.mem_name} placeholder="회원명/담당자명을 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
              </div>
              <div className="form_wrap">
                <div className="tit07">휴대폰 번호</div>
                <div className="ph_num_dan3">
                  <input type="number" pattern="\d*" name="mem_mobile1" ref={hpRef1} value={varMember.mem_mobile1} maxLength="3" onKeyUp={e=>onKeyPressHandler(e,1)} onChange={e=>onChangeInputHandler(e)}/>
                  <div className="mid_bar">-</div>
                  <input type="number" pattern="\d*" name="mem_mobile2" ref={hpRef2} value={varMember.mem_mobile2} maxLength="4" onKeyUp={e=>onKeyPressHandler(e,2)} onChange={e=>onChangeInputHandler(e)}/>
                  <div className="mid_bar">-</div>
                  <input type="number" pattern="\d*" name="mem_mobile3" ref={hpRef3} value={varMember.mem_mobile3} maxLength="4" onKeyUp={e=>onKeyPressHandler(e,3)} onChange={e=>onChangeInputHandler(e)}/>&nbsp;
                  <button className="w_s_btn c_white" onClick={e=>onSendCertificationHandler(e)}>인증하기</button>
                </div>
                <div className="has_btn" style={varShowMobile?{display:"block"}:{display:"none"}}>
                  <input type="text" name="mem_cert" value={varMember.mem_cert} placeholder="인증번호를 입력해주세요." onChange={e=>onChangeInputHandler(e)}/>
                  <button className="w_s_btn" onClick={e=>onCheckCertificationHandler(e)}>확인</button>
                  {/* <button className="w_s_btn c_gray">인증완료</button> */}
                  <div className="fn_timer" id="expired_time" style={varShowMobile?{display:"block"}:{display:"none"}}>{TimeFormat(varTimeDisplay)}</div>
                </div>
              </div>
              <div className="btn_bottom">
                <button className="btn_style_yes" onClick={e=>onFindIDHandler(e)}>확인</button>
                <Link to={"/Login"} className="btn_style_no">취소</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FindID;