import React from "react"; 

import { addThousandPoint } from "../common/Common";

import supportlandIcon from "../../images/icon_mark01.png";

//매물 상세 정보 표시
const DoongjiDetailInfo = ({varProductData,onPopupSupportMoneyHandler}) => {

  return (
    <div className="info02">
      <div className="tit07_1">상세 정보</div>
      <div className="table_st03">
        <table>
          <colgroup><col width="35%"/><col width="65%"/></colgroup>
          <thead></thead>
          <tbody>
            <tr>
              <th>애완동물</th>
              <td><span>{varProductData.rp_can_pets===0?"불가":varProductData.rp_can_pets===1?"가능":"협의"}</span></td>
            </tr>
            <tr>
              <th>지하철역</th>
              <td>
                {varProductData.rp_subway_line===""?null:<span>{varProductData.rp_subway_line}&nbsp;</span>}
                {varProductData.subway_station===""?null:<span>{varProductData.subway_station}&nbsp;</span>}
                {varProductData.rp_subway_more===0?null:<span>, 도보 {varProductData.rp_subway_more} 분</span>}
              </td>
            </tr>
            <tr>
              <th>버스정류소</th>
              <td>
                {varProductData.rp_bus===""?null:<span>{varProductData.rp_bus}&nbsp;</span>}
                {varProductData.rp_bus_more===0?null:<span>, 도보 {varProductData.rp_bus_more} 분</span>}
              </td>
            </tr>
            <tr>
              <th>대형마트</th>
              <td>
                {varProductData.rp_market_more===0?null:<span>도보 {varProductData.rp_market_more} 분</span>}
              </td>
            </tr>
            <tr>
              <th>편의점</th>
              <td>
                {varProductData.rp_mart_more===0?null:<span>도보 {varProductData.rp_mart_more} 분</span>}
              </td>
            </tr>
            <tr>
              <th>인근학교</th>
              <td>
              {varProductData.rp_school===""?null:<span>{varProductData.rp_school}</span>}
              {varProductData.rp_school_more===0?null:<span>, 도보 {varProductData.rp_school_more} 분</span>}
              </td>
            </tr>
            <tr>
              <th>CCTV</th>
              <td><span>{varProductData.rp_cctv===0?"없음":"있음"}</span></td>
            </tr>
            <tr>
              <th>통역</th>
              <td>
                <span>{varProductData.rp_translate===0?"불가능":"가능"}</span>
                {varProductData.rp_languages===""?null:<span className="mid_bar"> / </span>}
                {varProductData.rp_languages===""?null:<span>{varProductData.rp_languages}</span>}
              </td>
            </tr>
            <tr>
              <th>부동산 지원금 <img src={supportlandIcon} alt="" className="jiwon_open_btn" onClick={e=>onPopupSupportMoneyHandler(e,true)}/></th>
              <td><span>{addThousandPoint(varProductData.rp_grants)}</span> 만원</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default DoongjiDetailInfo;