import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import PremiumSearch from "./PremiumSearch";
import PremiumSearchResult from "./PremiumSearchResult";
import Paging from "../../layout/Paging";

import { AddOptionToString } from '../../../components/common/Common';

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import '../../admin_css/board.css';

/* 프리미엄광고 리스트 */
function PremiumList({setMenuItem}) {
  const admin_region = localStorage.getItem("adminRegion");
  //광고유형 1:둥지/2:장터
  const {rpa_mode} = useParams(); 
  const varPMode = Number(rpa_mode);

  //표시항목 설정
  let menuItem = "";
  let menuTitle = "";
  if(Number(rpa_mode) === 1) {
    menuItem = 'DoongjiPADList';
    menuTitle = "둥지 프리미엄 광고 관리";
  } else {
    menuItem = 'JangterPADList';
    menuTitle = "장터 프리미엄 광고 관리";
  }

  //프리미엄광고 목록
  const [varPremiumLists,setPremiumLists] = useState([]);

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  const [varMaxNumPage, setMaxNumPage] = useState(0);

  //검색
  const newData = {sdate:null,edate:null,searchProgress:0,searchOption:'all',searchString:''};
  const [varSearchData, setSearchData] = useState(null);

  //프리미엄광고 목록 읽어오기
  const getPremiumList = async () => {
    const sendData = {admin_region:admin_region,pageNum:varPageNum, pageSize:varPageSize,pageMode:varPMode, searchData:varSearchData};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_premium_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setTotalCount(datas.totalcount);
        setPremiumLists(datas.results);
        setMaxNumPage(datas.totalcount-(varPageNum-1)*varPageSize);
      } else {
        setTotalCount(0);
        setPremiumLists([]);
        setMaxNumPage(0);
      }
    });
  }

  //페이지 정보 표시 - 페이지가 변경되면
  useEffect(() => {
    setMenuItem(menuItem);
    setSearchData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rpa_mode]);

  useEffect(() => {
    setPageNum(1); // 검색 조건이 변경될 때 페이지 번호를 1로 초기화
  }, [varSearchData]);

  useEffect(() => {
    if(varSearchData !== null) getPremiumList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum,varSearchData]);
  
  //프리미엄광고  항목삭제
  const onDeletePremiumHandler = async (e) => {
    //선택된 회원목록 추출
    let delListString = "";
    let delPremium = 0;
    varPremiumLists.map(element=>{
      if(element.checked) {
        delPremium++;
        delListString = AddOptionToString(element.rpa_idx,delListString,',');
      }
      return null;
    });
    if(delPremium === 0) {
      alert('삭제할 프리미엄광고 항목이 선택되어 있지 않습니다.');
      return false;
    }
    if(!window.confirm('선택된 프리미엄광고 항목을 삭제하시겠습니까?')) return false;

    const sendData = {stable:'advertise', sprefix:'rpa', delListString:delListString};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_product_list`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //회원목록 재설정
        getPremiumList();
      }
    });
    const selectAllBox = document.getElementById('deleteItems');
    selectAllBox.checked = false;
  }

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">{menuTitle}</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>둥지/장터관리<img src={arrImage} alt=""/>프리미엄 광고 관리<img src={arrImage} alt=""/>{menuTitle}
        </div>
      </div>
      <div className="content">
        <PremiumSearch varPMode={rpa_mode} varTotalCount={varTotalCount} varSearchData={varSearchData} setSearchData={setSearchData} />
        <PremiumSearchResult varPMode={varPMode} varMaxNumPage={varMaxNumPage} 
            varPremiumLists={varPremiumLists} setPremiumLists={setPremiumLists} onDeletePremiumHandler={onDeletePremiumHandler}/>
        <div className="paging" style={{ marginBottom: "20px" }}>
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </div>
      </div>
    </div>
  )
}

export default PremiumList;
