/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect,useState } from "react";
import axios from 'axios';
import CONFIG from '../../configs/Configs'; 

import closeIcon from "../../images/icon_close.png";

const ChangeJangterShowing = ({varPopupShowing,varCurrentJangterIndex,varJangterList,setJangterList,onOpenCloseShowingHandler}) => {
  //게시상태저장변수
  const [varShows,setShows] = useState(0);

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(varCurrentJangterIndex > 0) {
      const findIndex = varJangterList.findIndex(element=>element.jt_idx===varCurrentJangterIndex);
      const copyData = varJangterList[findIndex];
      console.log(copyData);
      setShows(Number(copyData.jt_show));
      document.getElementById('jt_show'+copyData.jt_show).checked=true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCurrentJangterIndex]);

  //상태변경
  const onChangeShowingHandler = (e) => {
    const showState = Number(e.currentTarget.value);
    console.log(showState);
    setShows(showState);
  }
  //결과
  const onSetShowingHandler = async (e) => {
    //선택된 장터 상품 찾기
    let CopyList = [...varJangterList];
    const findIndex = CopyList.findIndex(element=>element.jt_idx===varCurrentJangterIndex);
    //서버에 적용
    const sendData = {jt_idx:varCurrentJangterIndex,jt_show:varShows};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_jangter_showing`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //선택된 장터의 정보 변경하기
        CopyList[findIndex].jt_show = varShows;
        //전체배열에 적용
        setJangterList(CopyList);
        onOpenCloseShowingHandler(e,false);
      }
    });
  }

  return (
    <div className="layer-pop desc_popup_st h_auto" style={varPopupShowing?{display:"block"}:{display:"none"}}>
      <div className="close_bt_wrap">
        <div className="close_layer top_close_btn" onClick={e=>onOpenCloseShowingHandler(e,false)}>
          <img src={closeIcon} alt=""/>
        </div>
      </div>
      <div className="layer_inner">
        <div className="center_wrap">
          <div className="con">
            <div className="con_box">
              <strong>게시 상태 변경</strong>
              <div className="desc">다음과 같이 게시물의 상태를 변경합니다.</div>
              <div className="dan_short2" style={{flexDirection:"column",width:"60%",padding:"10px 0px 0px 35px"}}>
                <div className="terms_chks show-option">
                  <input type="radio" name="jt_show" className="chk1" id="jt_show2" value="2"
                      checked={varShows===2?true:false} onChange={e=>onChangeShowingHandler(e)}/>
                  <i></i><label htmlFor="jt_show2">게시중</label>
                </div>
                <div className="terms_chks show-option">
                  <input type="radio" name="jt_show" className="chk1" id="jt_show1" value="1"
                      checked={varShows===1?true:false} onChange={e=>onChangeShowingHandler(e)}/>
                  <i></i><label htmlFor="jt_show1">거래완료</label>
                </div>
                <div className="terms_chks show-option">
                  <input type="radio" name="jt_show" className="chk1" id="jt_show0" value="0"
                      checked={varShows===0?true:false} onChange={e=>onChangeShowingHandler(e)}/>
                  <i></i><label htmlFor="jt_show0">게시중지</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn_bottom">
          <button type="button" className="btn_style_no close_layer" onClick={e=>onOpenCloseShowingHandler(e,false)}>취소</button>
          <button type="button" className="btn_style_yes" onClick={e=>onSetShowingHandler(e)}>선택 상태 변경</button>
      </div>
    </div>
  );
}
export default ChangeJangterShowing;