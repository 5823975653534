/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import closeIcon from '../../../images/icon_close.png';

//둥지매물 신고를 위한 팝업창
const AdminShowBigSizePhoto = ({varPhotoImage,varOpenPhoto,onOpenPhotoHandler}) => {
  return (
    <div className="poplayerAdminPhoto" style={varOpenPhoto?{display:"block"}:{display:"none"}}>
      <div className="date-right-close"><img src={closeIcon} alt="" onClick={e=>onOpenPhotoHandler(e,false)}/></div>
      <div className="consbox2" style={{left:"5%"}}>
        <img className="shown-image" src={varPhotoImage} alt="" onClick={(e)=>onOpenPhotoHandler(e,false)}/>
      </div>
    </div>
  );
}
export default AdminShowBigSizePhoto;