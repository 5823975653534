/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import closeIcon from '../../images/icon_close.png';

//장터매물 삭제를 위한 팝업창
const JangterDelete = ({varPopupJangterDelete,varCurrentJangterIndex,onPopupJangterDeleteHandler}) => {

  //장터매물 삭제
  const onJangterDeleteHandler = async (e) => {
    e.preventDefault();
    //if(!window.confirm('등록된 둥지매물을 삭제합니까?')) return false;
    const sendData = {jt_idx:varCurrentJangterIndex};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_jangter_product`,sendData).then((res) => {
      const datas = res.data;
      alert(datas.message);
      if(datas.retvalue === 1) {
        onPopupJangterDeleteHandler(e,-1);
      }
    });   
  }

  return (
    <div className="layer-pop w_del_popup desc_popup_st h_auto" style={varPopupJangterDelete?{display:"block"}:{display:"none"}}>
      <div className="close_bt_wrap">
        <div className="close_layer top_close_btn"><img src={closeIcon} alt="" onClick={e=>onPopupJangterDeleteHandler(e,false)}/></div>
      </div>
      <div className="layer_inner">
        <div className="center_wrap">
          <div className="con">
            <div className="con_box">
              <strong>게시글 삭제</strong>
              <div className="desc" style={{padding:"10px"}}>등록 글을 삭제하시겠습니까?<br/>삭제 이후에는 다시 불러올 수 없습니다.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn_bottom">
        <button type="button" className="btn_style_yes close_layer" onClick={e=>onPopupJangterDeleteHandler(e,false)}>취소</button>
        <button type="button" className="btn_style_no" onClick={e=>onJangterDeleteHandler(e)}>삭제</button>
      </div>
    </div>
  );
}
export default JangterDelete;