import React, { useState,useEffect } from "react";
import { useParams,Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import companyPhoto from "../../../images/sub/@thumnail03.png";
import taxPhoto from "../../../images/sub/@thumnail03.png";
import licensePhoto from "../../../images/sub/@thumnail03.png";

import { ChangeDateStringOnlyDate,ChangeMobileFormat,GetPhotoImage } from '../../../components/common/Common';

function MemberRetired() {
  let { mcm_idx } = useParams();
  const navigate = useNavigate();
  const [varApprovalData,setApprovalData] = useState({});

  let photoFile = companyPhoto;
  let taxFile = taxPhoto;
  let licenseFile = licensePhoto;
  const [varPhoto1,setPhoto1] = useState(photoFile);
  const [varPhoto2,setPhoto2] = useState(taxFile);
  const [varPhoto3,setPhoto3] = useState(licenseFile);

  //파트너스 등록 유무
  const [varPartnerExist,setPartnerExist] = useState(0);
  const [varPartnerIndex,setPartnerIndex] = useState(0);

  //회원목록 읽어오기
  const getMemberApprovalInformation = async () => {
    const sendData = {mcm_idx:mcm_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_approval_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1 && datas.results?.length > 0){
        setApprovalData(datas.results[0]);
        setApprovalInformation(datas.results[0]);
        if(datas.results[0].mem_mode===1) {
          if(datas.partners?.length === 0) {
            setPartnerExist(1);  //파트너스에 등록 필요
          }
          setPartnerIndex(datas.partners[0].jp_idx);
        }
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    getMemberApprovalInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setApprovalInformation = (approvals) => {
    if(approvals.mcm_image === 1) {
      const fileName = approvals.mcm_file;
      photoFile = `${CONFIG.SERVER_HOST}/`+GetPhotoImage(fileName,'member');
      setPhoto1(photoFile);
    }
    if(approvals.mem_mode === 1) {
      //사업자등록증 존재하면
      if(approvals.mcm_tax_image === 1) {
        const fileName = approvals.mcm_tax_file;
        taxFile = `${CONFIG.SERVER_HOST}/`+GetPhotoImage(fileName,'member');
        setPhoto2(taxFile);
      }
      //부동산자격증 존재하면
      if(approvals.mcm_broker_image === 1) {
        const fileName = approvals.mcm_broker_file;
        licenseFile = `${CONFIG.SERVER_HOST}/`+GetPhotoImage(fileName,'member');
        setPhoto3(licenseFile);
      }
    }
  }

  //회원정보편집
  const onMemberApprovalInfoHandler = (e) => {
    const {name,value} = e.currentTarget;
    setApprovalData({...varApprovalData, [name]:value})
  }
  //승인상태 재저장
  const updateMemberApprovalInformation = async(e) => {
    const sendData = {mcm_idx:mcm_idx,mcm_approval:varApprovalData.mcm_approval,mem_mode:varApprovalData.mem_mode,jpadd:varPartnerExist,jpidx:varPartnerIndex};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_approval_information`,sendData).then((res) => {
      const datas = res.data;
      alert(datas.message);
      if(datas.retvalue === 1){
        navigate('/Admin/Member/MemberApproval');
      }
    });
  }

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">파트너스 회원 승인</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>회원 관리<img src={arrImage} alt=""/>파트너스 승인<img src={arrImage} alt=""/>파트너스 회원 승인
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <table className="write">
            <colgroup><col width="20%"/><col width="30%"/><col width="20%"/><col width="30%"/></colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>아이디(이메일)</th>
                <td>{varApprovalData.mem_email||""}</td>
                <th>등록일자</th>
                <td>{ChangeDateStringOnlyDate(varApprovalData.mem_rdate||"")}</td>
              </tr>
              <tr>
                <th>업체명</th>
                <td colSpan={3}>{varApprovalData.mcm_name}</td>
              </tr>  
              <tr>
                <th>업체유형</th>
                <td>{varApprovalData.mem_mode===1?"부동산파트너":"파트너스"}</td>
                <th>업체 대표 번호</th>
                <td>{ChangeMobileFormat(varApprovalData.mcm_phone||"")}</td>
              </tr>
              <tr>
                <th>사업장주소</th>
                <td colSpan={3}>{`(${varApprovalData.mcm_zipcode||""}) ${varApprovalData.mcm_address1||""} ${varApprovalData.mcm_address2||""}`}</td>
              </tr>     
              <tr>
                <th>담당자명</th>
                <td>{varApprovalData.mcm_name||""}</td>
                <th>휴대폰</th>
                <td>{ChangeMobileFormat(varApprovalData.mem_mobile||"")}</td>
              </tr> 
              <tr>
                <th>사업자등록번호</th>
                <td>{varApprovalData.mcm_tax_id||""}</td>
                <th>이메일(세금계산서발행용)</th>
                <td>{varApprovalData.mcm_tax_email||""}</td>
              </tr> 
              <tr className="image-area">
                <th className="image-title">업체대표사진</th>
                <td colSpan={3} className="image-block">
                  <div className="image-box" id="imgFile1">
                    <img className="request-image" src={varPhoto1} alt=""/>
                  </div>
                </td>  
              </tr>
              {varApprovalData.mem_mode===2?null:
                <tr className="image-area">
                  <th className="image-title">사업자등록증</th>
                  <td className="image-block">
                    <div className="image-box" id="imgFile2">
                      <img className="request-image" src={varPhoto2} alt=""/>
                    </div>
                  </td>  
                  <th className="image-title">공인중개사자격증</th>
                  <td className="image-block">
                    <div className="image-box" id="imgFile3">
                      <img className="request-image" src={varPhoto3} alt=""/>
                    </div>
                  </td>  
                </tr>
              }
              <tr>
                <th>업체 소개글</th>
                <td colSpan={3}>{varApprovalData.mcm_introduce||""}</td>
              </tr>
              <tr>
                <th>승인유무</th>
                <td colSpan={3}>
                  <select name="mcm_approval" style={{width:"120px"}} value={varApprovalData.mcm_approval||0} onChange={(e)=>onMemberApprovalInfoHandler(e)}>
                    <option value={0}>미승인</option>
                    <option value={1}>승인</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <Link to={`/Admin/Member/MemberApproval`} style={{width:"90px"}} className="btn line">목록</Link>
          <button onClick={(e)=>updateMemberApprovalInformation(e)} style={{width:"90px"}} className="btn line">저장</button>
        </div>
      </div>
    </div>
  );
}

export default MemberRetired;