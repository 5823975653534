import React, { useState } from "react";
import axios from "axios";
import CONFIG from '../../../configs/Configs';
import { isNull } from '../../../components/common/Common';
import '../../admin_css/admin_layout.css';

/* 관리자로그인 */
const LoginAdmin = () => {

  const [varEmail, setEmail] = useState("");
  const [varPassword, setPassword] = useState("");

  //로그인을 위한 이메일주소와 암호 입력받기
  const ChangeEmailHandler = (e) => {
    setEmail(e.currentTarget.value);
  };
  const onPasswordHanlder = (e) => {
    setPassword(e.currentTarget.value);
  };

  //관리자 로그인 
  const loginProgressingHandler = async(e) => {
    const params = new URLSearchParams();
    params.append("admin_id", varEmail);
    params.append("admin_pwd", varPassword);

    await axios.post(`${CONFIG.SERVER_HOST}/_login_admin`, params).then((res) => {
      console.log("adminlogin", res.data);
      if (res.data.retvalue === 1) {
        const datas = res.data.results;
        console.log(datas);
        localStorage.setItem("adminLoginState", 1);               //로그인상태
        localStorage.setItem("adminIdx", datas.mem_idx);          //회원번호
        localStorage.setItem("adminName", datas.mem_name);        //회원명
        localStorage.setItem("adminMode", datas.mem_admin);       //1:운영자/2:부운영자/3:지역관리자
        localStorage.setItem("adminRegion", datas.admin_region);
        const adminAuthority = datas.mem_admin===1?"운영자":datas.mem_admin===2?"부운영자":isNull(datas.regname)?"지역관리자":datas.regname;
        localStorage.setItem("adminAuthority",adminAuthority);  //지역관리자 - 관리지역명
        console.log(adminAuthority);
        //메뉴사용권한
        const menuAvailables = datas.admin_environment+'|'+datas.admin_member+'|'+datas.admin_product+'|'+datas.admin_payment+'|'+datas.admin_board+'|'+datas.admin_admboard;
        localStorage.setItem("menuAvailables", menuAvailables);
        //사용자 페이지 접근
        localStorage.setItem("mem_idx", datas.mem_idx);
        localStorage.setItem("mcm_idx", 0);
        localStorage.setItem("mem_mode", datas.mem_mode);
        localStorage.setItem("mem_nickname", adminAuthority);
        localStorage.setItem("mcm_name", adminAuthority);
        localStorage.setItem("memberLoginState", 1);
        localStorage.setItem("mem_admin",datas.mem_admin);
        //페이지 이동
        window.location.href = "/Admin";
      } else {
        alert("정확한 정보를 입력해주세요");
      }
    });
  };

  return (
    <div className="login-wrap-box">
      <div className="login-wrap">
        <p>둥지관리시스템</p>
        <b>관리자로그인</b>
        <span>
          사이트 관리자 페이지로 접속하기 위한 로그인 페이지입니다.
          <br />
          아이디와 비밀번호를 입력하여 주시기기 바랍니다.
        </span>
        <div className="login-form-box">
          <input type="text" value={varEmail} onChange={(e)=>ChangeEmailHandler(e)} />
          <input type="password" value={varPassword} onChange={(e)=>onPasswordHanlder(e)} className="ml-none" />
          <input type="button" value="로그인" onClick={(e)=>loginProgressingHandler(e)} />
        </div>
      </div>
    </div>
  );
}

export default LoginAdmin;
