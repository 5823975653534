import React, { useState,useEffect } from "react";
import { useParams,useNavigate,Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import DaumPostCode from "../../../components/popup/DaumPostCode";
import DetailPhotos from '../../components/include/DetailPhotos';
import AdminShowBigSizePhoto from '../../components/include/AdminShowBigSizePhoto';

import { ChangeMobileFormat,isObjEmpty } from '../../../components/common/Common';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import '../../admin_css/board.css';

//내 둥지 내놓기 정보 표시
function ServiceDetail({setMenuItem}) {
  const {pageMode,indexNum} = useParams();
  const navigate = useNavigate();

  //매물사진
  const [varProductPhotos,setProductPhotos] = useState([]);
  const [varTempProductPhotos,setTempProductPhotos] = useState([]); //신규 매물 사진 목록 - 선택된 사진 저장 배열
  const [varTempProductPhotoFiles,setTempProductPhotoFiles] = useState([]); //신규 매물 사진 정보 목록 - 선택된 사진 정보 저장 배열
  const [varEmptyProductPhotos,setEmptyProductPhotos] = useState([]); //신규 매물 사진 목록 - 비어 있는 사진 저장 배열

  const mem_idx = localStorage.getItem("mem_idx");

  //페이지정보지정
  let prefixName = "", callPage = "", titleName = "";
  switch(Number(pageMode)) {
    default:
    case 1:
      prefixName = "rq";
      callPage = "DoongjiRegister";
      titleName = "내 둥지 내놓기";
      break;
    case 3:
      prefixName = "ri";
      callPage = "DoongjiManage";
      titleName = "건물 월관리 의뢰";
      break;
  }
  //신규정보
  const newData = {
    [prefixName+'_idx']:0,
    mem_idx:mem_idx,
    mem_name:"",
    mem_mobile:"",
    [prefixName+'_title']:"",
    [prefixName+'_zipcode']:"",
    [prefixName+'_address1']:"",
    [prefixName+'_address2']:"",
    [prefixName+'_roadname']:"",
    [prefixName+'_regcode']:"",
    [prefixName+'_dongname']:"",
    [prefixName+'_location']:"",
    [prefixName+'_serious']:0,
    [prefixName+'_comment']:"",
    [prefixName+'_phone']:"",
    [prefixName+'_rdate']:null,
    [prefixName+'_status']:0
  }

  //선택/추가할 저장 변수 
  const [varServiceDatas, setServiceDatas] = useState({});
  const [varDoongjiSerious,setDoongjiSerious] = useState([]);

  //정보 읽어오기
  const getServiceInformation = async () => {
    const sendData = {pageMode:pageMode, indexNum:indexNum};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_service_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //정보
        if(datas.results?.length > 0) {
          setServiceDatas(datas.results[0]);
        }
        //매물종류
        setDoongjiSerious(datas.filters); 
        //첨부사진 가져오기
        let photoMax = 15;
        //매물사진정보
        setProductPhotos(datas.photos);
        photoMax = photoMax - datas.photos?.length;

        //매물사진 15개까지 등록가능 --> 빈 공간 저장배열 만들기
        const photoData = {file_idx:0,file_name:"",file_preview:""};
        let photoArray = [];
        for(var i=0; i<photoMax; i++) {
          photoArray.push(photoData);
        }
        setEmptyProductPhotos(photoArray);
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    setServiceDatas(newData);
    setMenuItem(callPage);
    getServiceInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //정보 입력--------------------------------------------------------
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    console.log(name, value);
    setServiceDatas({...varServiceDatas,[name]:value});
  };

  //우편번호/주소찾기--------------------------------------------------------
  const [varOpenZipcode, setOpenZipcode] = useState(false);
  //우편번호/주소 검색 팝업창 열기/닫기
  const onOpenZipcodeHandler = (e, bts) => {
    e.preventDefault();
    setOpenZipcode(bts);
  };
  //우편번호 선택으로 우편번호/주소와 같이 반환
  const ChangeAddress = (zipcode,address1,regcode,dongName,roadName,locString) => {
    console.log(zipcode,address1,regcode,dongName,roadName,locString);
    const name1 = prefixName+'_zipcode';
    const name2 = prefixName+'_address1';
    const name3 = prefixName+'_roadname';
    const name4 = prefixName+'_regcode';
    const name5 = prefixName+'_dongname';
    const name6 = prefixName+'_location';
    setServiceDatas((prevState) => ({ ...prevState, [name1]: zipcode, [name2]: address1, [name3]: roadName, [name4]: regcode
      , [name5]: dongName, [name6]: locString }));
  };

  //사진첨부--------------------------------------------------------
  //매물사진 확대하기
  const [varOpenPhoto,setOpenPhoto] = useState(false);
  const [varPhotoImage,setPhotoImage] = useState(null);
  const onShowBigSizePhotoHandler = (e,files) => {
    e.preventDefault();
    setPhotoImage(files);
    setOpenPhoto(true);
  }
  const onOpenPhotoHandler = (e,bmode) => {
    setOpenPhoto(bmode);
  }

  //신고물 정보 저장
  const onUpdateServiceHandler = async (e) => {
    console.log('title',prefixName+'_title',varServiceDatas[prefixName+'_title']);
    if(varServiceDatas[prefixName+'_title']==="") {
      alert("신청제목을 먼저 입력하세요."); return false;
    } else if(varServiceDatas[prefixName+'_zipcode']==="") {
      alert("우편번호/주소를 먼저 선택하세요.");return false;
    } else if(Number(varServiceDatas[prefixName+'_serious'])===0) {
      alert("매물종류를 먼저 선택하세요.");return false;
    } else if(varServiceDatas[prefixName+'_comment']==="") {
      alert("의뢰내용을 먼저 입력하세요.");return false;
    } else if(varServiceDatas[prefixName+'_phone']==="") {
      alert("담당자 연락처를 먼저 입력하세요.");return false;
    }
    if(!window.confirm('정보를 저장합니까?')) return false;

    //서버로 업로드할 매물사진을 배열로 저장
    let PhotoForm = new FormData();
    for(var i=0; i < varTempProductPhotoFiles?.length; i++) {
      let formData = varTempProductPhotoFiles[i];
      PhotoForm.append('request',formData.get('service'));
    }
    console.log('추가된 매물 사진 서버로 업로드 등록',varTempProductPhotos?.length);
    //추가된 매물 사진 배열을 서버로 업로드 등록
    if(varTempProductPhotos?.length > 0) {
      await axios.post(`${CONFIG.SERVER_HOST}/requestArray`, PhotoForm).then((res) => {
        console.log(res.data);
        if(res.data.retvalue === 1) {
          const fileNames = res.data.fileNames; //배열
          console.log(fileNames);
          //매물사진 DB 등록
          RegisterDoongjiProduct(fileNames);
        }
      });
    } else {  //등록할 매물사진이 없는 경우
      RegisterDoongjiProduct("");
    }
  }
  const RegisterDoongjiProduct = async (fileNames) => {
    const sendData = {pageMode:pageMode, indexNum:indexNum, infos:varServiceDatas, fileNames:fileNames};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_service_information`, sendData).then((res) => {
      alert(res.data.message);
      if (res.data.retvalue === 1) {
        let newIndex = Number(varServiceDatas[prefixName+'_idx']);
        if(newIndex === 0) newIndex = Number(res.data.newIndex);
        //매물사진 DB 등록
        if(fileNames!=="") {
          const sendData = {pageMode:pageMode,newIndex:newIndex,fileNames:fileNames};
          console.log('sendData',sendData);
          axios.post(`${CONFIG.SERVER_HOST}/_update_service_photos`, sendData).then((res) => {
            //미리보기
            navigate(`/Admin/ProductService/${callPage}`);
          });
        } else {
          navigate(`/Admin/ProductService/${callPage}`);
        }
      }
    });
  }

  return isObjEmpty(varServiceDatas)?null: (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">{titleName}</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>둥지/장터관리<img src={arrImage} alt=""/>기타 서비스 관리<img src={arrImage} alt=""/>{titleName}
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <table className="write">
          <colgroup>
              <col width="20%"></col>
              <col width="30%"></col>
              <col width="20%"></col>
              <col width="30%"></col>
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>신청제목</th>
                <td>
                  <input type="text" className="title-box" name={prefixName+"_title"} value={varServiceDatas[prefixName+'_title']} onChange={(e)=>onChangeInputHandler(e)}/>
                </td>
                <th>신청자</th>
                <td>{varServiceDatas.mem_name+'('+ChangeMobileFormat(varServiceDatas.mem_mobile)+')'}</td>
              </tr>
              <tr className="image-area">
                <th className="image-title">요청사진</th>
                <td colSpan={3}>
                  <div className="image-block" >
                    <DetailPhotos varMode={Number(pageMode)===1?3:4} photoMax={15}
                        varProductPhotos={varProductPhotos} setProductPhotos={setProductPhotos}
                        varTempProductPhotos={varTempProductPhotos} setTempProductPhotos={setTempProductPhotos}
                        varTempProductPhotoFiles={varTempProductPhotoFiles} setTempProductPhotoFiles={setTempProductPhotoFiles}
                        varEmptyProductPhotos={varEmptyProductPhotos} setEmptyProductPhotos={setEmptyProductPhotos}
                        onShowBigSizePhotoHandler={onShowBigSizePhotoHandler}/>
                  </div>
                </td>  
              </tr>
              <tr>
                <th>매물주소</th>
                <td colSpan={3}>
                  <input type="text" className="zipcode-box" name={prefixName+"_zipcode"} value={varServiceDatas[prefixName+'_zipcode']||""} readOnly/>
                  <button onClick={(e)=>onOpenZipcodeHandler(e,true)} style={{width:"90px"}} className="btn">우편번호</button><br/>
                  <input type="text" className="address1-box" name={prefixName+"_address1"} value={varServiceDatas[prefixName+"_address1"]||""} readOnly/>&nbsp;&nbsp;
                  <input type="text" className="address2-box" name={prefixName+"_address2"} id={prefixName+"_address2"} value={varServiceDatas[prefixName+"_address2"]||""} 
                          placeholder="상세 주소를 입력해주세요." onChange={(e)=>onChangeInputHandler(e)}/>
                </td>
              </tr>
              <tr>
                <th>매물종류</th>
                <td>
                  <select name={prefixName+"_serious"} value={varServiceDatas[prefixName+'_serious']} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value={0}>매물종류선택</option>
                    {varDoongjiSerious.map((serious,i)=>(
                      <option key={'serious_'+i} value={serious.vfo_idx}>{serious.vfo_name}</option>
                    ))}
                  </select>
                </td>
                <th>담당자 연락처</th>
                <td>
                  <input type="text" name={prefixName+"_phone"} value={ChangeMobileFormat(varServiceDatas[prefixName+"_phone"])||""} onChange={(e)=>onChangeInputHandler(e)}/>
                </td>
              </tr>
              <tr>
                <th>의뢰내용</th>
                <td colSpan={3}>
                  <textarea name={prefixName+"_comment"} value={varServiceDatas[prefixName+"_comment"]||""} onChange={(e)=>onChangeInputHandler(e)} style={{width:"100%,height:60px"}}></textarea>
                </td>
              </tr>
              <tr>
                <th>진행상태</th>
                <td>
                  <select name={prefixName+"_status"} onChange={(e)=>onChangeInputHandler(e)} value={varServiceDatas[prefixName+"_status"]}>
                    <option value={0}>종료</option>
                    <option value={1}>신청중</option>
                    <option value={2}>답변완료</option>
                  </select>
                </td>
                <th></th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" style={{width:"100px"}} onClick={(e)=>onUpdateServiceHandler(e)}>저장</button>
          <Link to={`/Admin/ProductService/${callPage}`} style={{width:"100px"}} className="btn line">목록</Link>
        </div>
        {/* 우편번호/주소 검색*/}
        <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler}/>
        {/* 사진확대*/}
        <AdminShowBigSizePhoto varPhotoImage={varPhotoImage} varOpenPhoto={varOpenPhoto} onOpenPhotoHandler={onOpenPhotoHandler}/>
      </div>
    </div>
  )
}

export default ServiceDetail;