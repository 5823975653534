/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from 'react-router-dom';
import HeadTitle from '../../components/include/HeadTitle';

import joinIcon3 from "../../images/join_icon03.png";

const JoinMemberOK = () => {

  return (
    <>
      <HeadTitle titleString={"회원가입"}/>
      <div className="pd_wrap">
        <div className="ok_form_wrap join_ok_wrap">
          <div className="center_wrap">
            <img src={joinIcon3} alt=""/>
            <div className="top_txt">
              <strong>회원 가입이 완료되었습니다!</strong>
              <p>둥지장터의 다양한 서비스를 이용해보세요.</p>
            </div>
            <div className="btn_bottom">
              <Link to={"/"} className="btn_style_yes">홈으로 이동</Link>
              <Link to={"/Doongji/DoongjiSerious"} className="btn_style_no">둥지 새글 작성</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default JoinMemberOK;