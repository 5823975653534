import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { ChangeMobileFormat, GetPhotoImage } from "../common/Common";

import heartOffIcon from "../../images/icon_heart_w.svg";
import heartOnIcon from "../../images/icon_heart_y.svg";
import callIcon from "../../images/icon_call_gray.png";
import mapIcon from "../../images/icon_map_gray.png";

// 파트너스 항목 표시
const PartnerProduct = ({ inum, product, varPartnerList, setPartnerList }) => {
  const mem_idx = localStorage.getItem("mem_idx");
  const memberLoginState = Number(localStorage.getItem("memberLoginState"));
  const navigator = useNavigate();

  // 이미지 로딩 상태 관리
  const [imageSrc, setImageSrc] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);

  // 이미지 로딩 및 재시도 함수
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // Cache-busting timestamp
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  // 이미지 로딩
  useEffect(() => {
    const sfile = product.mcm_file;
    const simage = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(sfile, 'member') + `?t=${new Date().getTime()}`;
    setImageLoading(true);
    loadImageWithRetry(simage)
      .then((loadedImage) => {
        setImageSrc(loadedImage);
        setImageLoading(false);
      })
      .catch((error) => {
        console.error(error.message);
        setImageLoading(false);
      });
  }, [product.mcm_file]);

  // 찜목록 등록/해제
  const onChangeJjimListHandler = async (e, jp_idx) => {
    if (memberLoginState === 0) {
      navigator('/Login');
      return false;
    }
    e.preventDefault();
    let jj_idx = product.jj_idx;
    let jj_status = product.jj_status;
    if (jj_idx === null) {
      jj_idx = 0;
      jj_status = 0;
    }
    if (jj_status === 1) jj_status = 0;
    else jj_status = 1;

    const sendData = { pageMode: 3, opp_idx: jp_idx, jj_idx: jj_idx, jj_mem_idx: mem_idx, jj_status: jj_status };
    await axios.post(`${CONFIG.SERVER_HOST}/_update_jjim_list`, sendData).then((res) => {
      const datas = res.data;
      let copyList = [...varPartnerList];
      const findIndex = copyList.findIndex(element => element.jp_idx === jp_idx);
      copyList[findIndex].jj_idx = datas.jj_idx;
      copyList[findIndex].jj_status = jj_status;
      copyList[findIndex].mem_idx = mem_idx;
      console.log(copyList[findIndex]);
      setPartnerList(copyList);
    });
  }

  const onGoingDetailHandler = (e, jp_idx) => {
    localStorage.setItem('scrollIndex', inum);
    localStorage.setItem('scrollTop', window.scrollY);
    navigator(`/Jangter/PartnerView/${jp_idx}`);
  }

  return (
    <div className="mk_con">
      <div className="name_wrap">
        <div className="center_wrap">
          <div className="tit03">
            <span className="btn_st_A">{product.mcm_dongname}</span>
            <span className="btn_title">{product.mcm_name}</span>
            <div className="wish_chk go_right" onClick={e => onChangeJjimListHandler(e, product.jp_idx)}>
              <img src={product.jj_idx === null || product.jj_status === 0 ? heartOffIcon : heartOnIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="info_wrap" onClick={e => onGoingDetailHandler(e, product.jp_idx)}>
        <div className="img_wrap" style={{ position: 'relative' }}>
          {imageLoading ? (
            <div className="loading-indicator">
              <div className="loading-spinner"></div>
            </div>
          ) : (
            <img src={imageSrc} alt="" style={{ objectFit: "cover", width: "100%", height: "100%" }} />
          )}
        </div>
        <div className="info_txt">
          <div className="location"><img src={mapIcon} alt="" />&nbsp;{product.mcm_address1}</div>
          <div className="call"><img src={callIcon} alt="" />&nbsp;{ChangeMobileFormat(product.mcm_phone)}</div>
          <div className="desc"><span className="descript-lines4">{product.mcm_introduce}</span></div>
        </div>
      </div>
      {/* 게시중일 때 노출됨 */}
      {product.pr_status === 3 ?
        <div className="blind_bg" style={{ zIndex: "3", display: "block" }}>
          <div className="txt_wrap">
            <strong>블라인드 처리</strong>
            <span>처리 관리자에게 문의해주세요</span>
          </div>
        </div>
        : null}
    </div>
  )
}
export default PartnerProduct;
