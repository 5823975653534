import React, { useState,useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import {removeThousandPoint,addThousandPoint,isObjEmpty} from "../../../components/common/Common";

//요금제의 정보 표시
function RatePlanDetail({varShowDetailPage,setShowDetailPage,varRatePlanMode,varCurrentRatePlan,varRatePlanLists,getRatePlanList}) {
  //요금 변수
  const newData = {
    vo_idx : 0,
    vo_name : "",
    vo_price : "",
    vo_amount : "",
    vo_rdate : ""
  };

  //선택/추가할 저장 변수 
  const [varRatePlanDatas, setRatePlanDatas] = useState({});

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(varCurrentRatePlan === 0) {
      setRatePlanDatas(newData);
    } else {
      const findData = varRatePlanLists.filter(element=>element.vo_idx===varCurrentRatePlan);
      setRatePlanDatas(findData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCurrentRatePlan]);

  //요금제정보 저장
  const onUpdateRatePlanHandler = async (e) => {
    if (varRatePlanDatas.vo_name === '') {
      alert('요금제명을 입력해 주세요'); return false;
    } else if (varRatePlanDatas.vo_price === '') {
      alert('요금제 정가를 입력해 주세요'); return false;
    } else if (varRatePlanDatas.vo_amount === '') {
      alert('요금제 판매가격을 입력해 주세요'); return false;
    }
    const sendData = {rateplanMode: varRatePlanMode,ratePlanDatas:varRatePlanDatas};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_update_voucher_state`, sendData).then((res) => {
      alert(res.data.message);
      if (res.data.retvalue === 1) {
        getRatePlanList();
        setShowDetailPage(false);
      }
    });
  }

  //지역관리자정보 입력
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    if(name === "vo_name") {
      setRatePlanDatas({...varRatePlanDatas,[name]:value});
    } else {
      let strValue = removeThousandPoint(value);
      setRatePlanDatas({...varRatePlanDatas,[name]:strValue});
    }
  };

  if(varShowDetailPage) {
    return isObjEmpty(varRatePlanDatas)?null:(
      <div className="content">
        <div className="main-box">
          <table className="write">
            <thead></thead>
            <tbody>
              <tr>
                <th>요금제명</th>
                <td><input type="text" className="title-box" name="vo_name" value={varRatePlanDatas.vo_name||""} onChange={(e)=>onChangeInputHandler(e)}/></td>
              </tr>
              <tr>
                <th>정식가격</th>
                <td><input type="text" className="price-box" name="vo_price" value={addThousandPoint(varRatePlanDatas.vo_price)||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;원</td>
              </tr>
              <tr>
                <th>판매가격</th>
                <td> <input type="text" className="price-box" name="vo_amount" value={addThousandPoint(varRatePlanDatas.vo_amount)||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;원</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" style={{width:"100px"}} onClick={(e)=>onUpdateRatePlanHandler(e)}>저장</button>
          <button className="btn line" style={{width:"100px"}} onClick={()=>setShowDetailPage(false)}>닫기</button>
        </div>
      </div>
    )
  }
}

export default RatePlanDetail;