/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import { Link } from "react-router-dom";
import $ from "jquery";

import closeImage from "../../images/icon_close.svg";
import openQuickImage from "../../images/icon-plus.png"; 
import topImage from "../../images/icon-top.png"; 

import quickMenu1Image from "../../images/quick_menu01.png";
import quickMenu2Image from "../../images/quick_menu02.png";
import quickMenu3Image from "../../images/quick_menu03.png";
import quickMenu4Image from "../../images/quick_menu04.png";

/* 퀵메뉴 */
let dragYpos = 0;
let dragStep = 100;
const QuickDoongjiMenu = ({onShowQuickMenuHandler}) => {

  const onGotoTopHandler = (e) => {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, 'slow');
  }
  const onDragStartHandler = (e) => {
    //const quickButtons = document.querySelector('.btm_quick_menu');
    console.log(e.clientY);
    dragYpos = e.clientY;
  }
  const onDragDropHandler = (e) => {
    const quickButtons = document.querySelector('.btm_quick_menu');
    let posMove = e.clientY - dragYpos;
    console.log(posMove,e.clientY,dragYpos);
    if(posMove < 0) dragStep = dragStep + 70;
    else dragStep = dragStep - 70;
    quickButtons.style.bottom = dragStep + "px";
  }

  return (
    <>
      <ul className="btm_quick_menu" draggable="true" onDragStart={e=>onDragStartHandler(e)} onDragEnd={e=>onDragDropHandler(e)}>
        <li className="q_open_btn"><img src={openQuickImage} alt="" className="quick-button" onClick={e=>onShowQuickMenuHandler(e,true)}/></li>
        <li className="go_top" onClick={(e)=>onGotoTopHandler(e)}><img src={topImage} className="quick-button" alt=""/></li>
      </ul>
      <div className="layer-pop quick_pop" style={{display:"none"}}>
        <div className="close-layer top_close_btn"><img src={closeImage} alt="" onClick={e=>onShowQuickMenuHandler(e,false)}/></div>
        <div className="center_wrap">
          <div className="quick_menu">
            <ul>
              <li><Link to={'/Doongji/DoongjiSerious'}><img src={quickMenu1Image} alt=""/> 신규등록</Link></li>
              <li><Link to={'/Doongji/TempRegisteredDoongjiList'}><img src={quickMenu2Image} alt=""/> 임시 저장 리스트</Link></li>
              <li><Link to={'/Doongji/RegisteredDoongjiList'}><img src={quickMenu3Image} alt=""/> 내 등록 매물 보기</Link></li>
              <li><Link to={'/Doongji/NearRegisteredDoongjiList'}><img src={quickMenu4Image} alt=""/> 내 근처 매물 보기</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default QuickDoongjiMenu;