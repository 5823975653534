/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";

import HeadTitle from '../../components/include/HeadTitle';
import { MaskingEmail,isNull } from "../../components/common/Common";
import bigIcon from "../../images/big_icon02.png";

//아이디 찾기
const FoundIDOK = () => {
  const members = localStorage.getItem("foundMembers");
  const [varMemberList,setMemberList] = useState([]);

  useEffect(() => {
    let memberList = [];
    if(!isNull(members)) {
      const memberArray = members.split('|');
      for(var i=0; i < memberArray.length; i++) {
        const memberString = memberArray[i].split(':');
        let memData = {mode:memberString[0],name:memberString[1],email:memberString[2]};
        memberList.push(memData);
      }
    }
    console.log(memberList);
    setMemberList(memberList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  return (
    <>
      <HeadTitle titleString={"아이디 찾기"}/>
      <div className="pd_wrap">
        <div className="ok_form_wrap join_ok_wrap">
          <div className="center_wrap">
            <img src={bigIcon} alt=""/>
            <div className="top_txt">
              <div className="find_txt">
                회원님의 아이디는<br/>
                {varMemberList.map((member,i)=>(
                  <div><strong>{MaskingEmail(member.email)} {`(${member.mode})`}</strong></div>
                ))}
                입니다.
              </div>
            </div>
            <div className="btn_bottom">
              <Link to={"/Login"} className="btn_style_yes">로그인 하기</Link>
              <Link to={"../FindPWD"} className="btn_style_no">비밀번호 찾기</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FoundIDOK;