/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const ExistPartner = ({varExistPartner,onOpenExistPartnerHandler}) => {

  return (
    <div className="popup" style={varExistPartner?{display:"block"}:{display:"none"}}>
      <div className="layer_bg"></div>
      <div className="pop_con">
        <p>아직 파트너스 회원이 아니시군요!</p>
        <strong>파트너스로 사업장을 장터에 올릴 수 있어요!</strong>
        <div className="pop_btn">
            <button className="btn_no" onClick={e=>onOpenExistPartnerHandler(e,1)}>다음에 하기</button>
            <button className="btn_go" onClick={e=>onOpenExistPartnerHandler(e,2)}>파트너스 등록하기</button>
        </div>    
      </div>
    </div>
  );
}
export default ExistPartner;