import React, { useState,useEffect } from "react";
import { useParams,useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import {ChangeDateStringOnlyDate,addThousandPoint,isObjEmpty,ResetDates,ChangeSearchingDateFormat} from "../../../components/common/Common";

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';
import closeImage from "../../../images/common/btn_close_white.png";

//결제정보 표시
function PaymentDetail({setMenuItem}) {
  const { ph_mode,ph_idx } = useParams();
  const navigate = useNavigate();
  //const mem_idx = localStorage.getItem("adminIdx");
  //console.log(ph_mode,ph_idx);

  //페이지 정보 지정
  let menuItem = "", menuTitle = "";
  if(Number(ph_mode) === 1) {
    menuItem = "PaymentVoucherList";
    menuTitle = "이용권 결제 관리";
  } else if(Number(ph_mode) === 2) {
    menuItem = "PaymentVoucherList";
    menuTitle = "이용권 결제 관리";
  } else {
    menuItem = "PaymentPremiumList";
    if(Number(ph_mode) === 3) menuTitle = "둥지 프리미엄 결제 관리";
    else menuTitle = "파트너 프리미엄 결제 관리";
  }

  //선택/추가할 저장 변수 
  const [varPaymentDatas, setPaymentDatas] = useState();
  //보유은행목록
  //const [varHavingBankList, setHavingBankList] = useState([]);
  //세부내역팝업창 - 이용권
  const [varShowVoucher, setShowVoucher] = useState(false);
  //세부내역팝업창 - 광고
  const [varShowAdvertise, setShowAdvertise] = useState(false);
  //사용자 이용권 보유현황
  const [varAccounts,setAccounts] = useState({});

  //결제정보 읽어오기
  const getPaymentInformation = async () => {
    const sendData = {paymentMode:ph_mode, ph_idx:ph_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_payment_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setAccounts(datas.accounts[0]);
        setPaymentDatas(datas.results[0]);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem(menuItem);
    getPaymentInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //결제정보 저장 ---데이타베이스
  const onUpdatePaymentHandler = async (e) => {
    if(!window.confirm('변경된 결제정보를 저장합니까?')) return false;

    const sendData = {infos:varPaymentDatas};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_update_payment_state`, sendData).then((res) => {
      console.log(sendData);
      if (res.data.retvalue === 1) {  
        CanceledPayment(); 
      } else {
        alert(res.data.message);
      }
    });

    // 저장 시 관련 알림 전송
    let smess_push = "";
    if((varPaymentDatas.ph_mode === 1 || varPaymentDatas.ph_mode === 2) && varPaymentDatas.ph_status === 3){
      smess_push = "이용권 결제완료 알림을 전송하시겠습니까?"
      if(!window.confirm(smess_push)) return false;
      SendPushAlert(4,varPaymentDatas.ph_mem_idx);
    }
    else if((varPaymentDatas.ph_mode === 3 || varPaymentDatas.ph_mode === 4) && varPaymentDatas.ph_status === 3){
      smess_push = "프리미엄 결제완료 알림을 전송하시겠습니까?"
      if(!window.confirm(smess_push)) return false;
      SendPushAlert(5,varPaymentDatas.ph_mem_idx);
    }
    else if((varPaymentDatas.ph_mode === 1 || varPaymentDatas.ph_mode === 2) && varPaymentDatas.ph_status === 6){
      // 환불 시 둥지 매물 종료
      ExpireDoongji();
      smess_push = "이용권 환불완료 알림을 전송하시겠습니까?"
      if(!window.confirm(smess_push)) return false;
      SendPushAlert(6,varPaymentDatas.ph_mem_idx);
    }
    else if((varPaymentDatas.ph_mode === 1 || varPaymentDatas.ph_mode === 2) && varPaymentDatas.ph_status === 7){
      smess_push = "이용권 환불실패 알림을 전송하시겠습니까?"
      if(!window.confirm(smess_push)) return false;
      SendPushAlert(7,varPaymentDatas.ph_mem_idx);
    }
    else if((varPaymentDatas.ph_mode === 3 || varPaymentDatas.ph_mode === 4) && varPaymentDatas.ph_status === 6){
      smess_push = "프리미엄 환불완료 알림을 전송하시겠습니까?"
      if(!window.confirm(smess_push)) return false;
      SendPushAlert(8,varPaymentDatas.ph_mem_idx);
    }
    else if((varPaymentDatas.ph_mode === 3 || varPaymentDatas.ph_mode === 4) && varPaymentDatas.ph_status === 7){
      smess_push = "프리미엄 환불실패 알림을 전송하시겠습니까?"
      if(!window.confirm(smess_push)) return false;
      SendPushAlert(9,varPaymentDatas.ph_mem_idx);
    }
    // 알림 없이 만료일에 따라 상태만 변경
    else if((varPaymentDatas.ph_mode === 1 || varPaymentDatas.ph_mode === 2) && varPaymentDatas.ph_status === 4){
      ExpireDoongji();
    }
  }

  const SendPushAlert = async(pushMode, ioppidx) => {
    const sendData = {pushMode: pushMode,oppIdx: ioppidx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_send_push_message`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        navigate(`/Admin/Payment/${menuItem}`);
      } else {
        alert(datas.message);
      }
    });
  }

  const ExpireDoongji = async(e) => {
    const sendData = {infos:varPaymentDatas};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_expire_doongji`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      alert(datas.message);
    });
  }

  //결제취소 / 환급처리
  const CanceledPayment = async() => {
    //이용권구매 - 장터 && 3:결제왼료
    if(varPaymentDatas.ph_mode === 2 && varPaymentDatas.ph_status === 3) {
      const sendData = {mem_idx:varPaymentDatas.mem_idx};
      console.log(sendData);
      await axios.post(`${CONFIG.SERVER_HOST}/admin_update_jangter_partner`,sendData).then((res) => {
        const datas = res.data;
        console.log(datas);
        alert(datas.message);
      });
      //4:결제취소/ 5:환불 요청 / 6:환불
      //카드결제취소................................................................
    } else if(varPaymentDatas.ph_status === 4 && varPaymentDatas.ph_method===1) {  //카드&결제취소
      //카드결제취소 유효성 검토-----
      const refund_paymentkey = varPaymentDatas.ph_paymentkey;
      const sendData = {paymentKey:refund_paymentkey};
      console.log(sendData);
      await axios.post(`${CONFIG.SERVER_HOST}/_cancel_payment_tosspayment`,sendData).then((res) => {
        const datas = res.data;
        console.log(datas);
        if(datas.retvalue === 1) {
          if(varPaymentDatas.ph_mode === 1 || varPaymentDatas.ph_mode === 2) ReturnMemberAccount();
          else RefundPayment();
        } else {
          alert(datas.message);
        }
      });
    //무통장입금 & 환불
    } else if(varPaymentDatas.ph_status === 6 && varPaymentDatas.ph_method===0){
      if(varPaymentDatas.ph_mode === 1 || varPaymentDatas.ph_mode === 2) ReturnMemberAccount();
      else RefundPayment();
    }
    navigate(`/Admin/Payment/${menuItem}`);
  }
  //이용권사용내역 조정
  const ReturnMemberAccount = async() => {
    //결제했던 이용권 보유현황을 원래대로 복귀
    let cur_slot = 0, cur_expdate = null, refund_slot = 0;
    if(varPaymentDatas.ph_mode===1) { //둥지
      cur_slot = varAccounts.doongji_slots;
      cur_expdate = varAccounts.doongji_expdate;
      refund_slot = varPaymentDatas.doongji_slots;
    } else {  //장터
      cur_slot = varAccounts.jangter_slots;
      cur_expdate = varAccounts.jangter_expdate;
      refund_slot = varPaymentDatas.jangter_slots;
    }
    let reduceSlot = varPaymentDatas.ph_extend===0?refund_slot:0;
    const new_slot = cur_slot - reduceSlot;
    const new_expdate = ResetDates(cur_expdate,1,varPaymentDatas.pay_terms*-1); 

    console.log(cur_slot,reduceSlot,new_slot,cur_expdate,new_expdate);
    const sendData = {mem_idx:varPaymentDatas.ph_mem_idx,ph_mode:varPaymentDatas.ph_mode,slot:new_slot,cexpdate:cur_expdate,expdate:new_expdate,mcmidx:varPaymentDatas.mcm_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_reset_member_account`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        RefundPayment();
      } else {
        alert(datas.message);
      }
    });
  }  
  //환불내역 및 사유 저장
  const RefundPayment = async() => {
    const refund_idx = varPaymentDatas.ph_idx;
    const refund_money = varPaymentDatas.ph_amount;
    const refund_method = varPaymentDatas.ph_method;
    const refund_reason = "고객 변심";
    const sendData = {ph_idx:refund_idx,pr_amount:refund_money,pr_method:refund_method,pr_reason:refund_reason};
    await axios.post(`${CONFIG.SERVER_HOST}/_update_refund_payment`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      alert(datas.message);
    });
  }

  //진행상태 변경
  const onChangeStatusHandler = (e) => {
    const { value } = e.currentTarget;
    //둥지/장터 이용권 : 신청 --> 결제완료
    if(Number(ph_mode) < 3 && varPaymentDatas.ph_status === 1 && Number(value) === 3) {
      //const imonths = varPaymentDatas.pay_terms;
      const tday = ChangeSearchingDateFormat(new Date());
      //const expdate = ResetDates(tday,1,imonths);
      //console.log('new',imonths,expdate);
      if(Number(ph_mode) === 1) //둥지
        setPaymentDatas({...varPaymentDatas, ph_status:Number(value),doongji_expdate:tday});
      else 
        setPaymentDatas({...varPaymentDatas, ph_status:Number(value),jangter_expdate:tday});
    //둥지/장터 이용권 : 결제완료 --> 종료
    } else if(Number(ph_mode) < 3 && varPaymentDatas.ph_status === 3 && Number(value) === 0) {
      const rday = ResetDates("",2,-1);
      if(Number(ph_mode) === 1) //둥지
        setPaymentDatas({...varPaymentDatas, ph_status:Number(value),doongji_expdate:rday});
      else 
        setPaymentDatas({...varPaymentDatas, ph_status:Number(value),jangter_expdate:rday});
    } else {
      setPaymentDatas({...varPaymentDatas, ph_status:Number(value)});
    }
    
  }

  //구매상품내역 팝업창 
  const onShowPaymentHandler = (e) => {
    if(varPaymentDatas.ph_mode>=3) {
      setShowVoucher(false);
      setShowAdvertise(true);
    } else {
      setShowVoucher(true);
      setShowAdvertise(false);
    }
  }
  const onCloseVoucherHandler = (e) => {
    setShowVoucher(false);
  }
  const onCloseAdvertiseHandler = (e) => {
    setShowAdvertise(false);
  }

  //구매자 회원페이지로 이동
  const onGotoMemberHandler = (e) => {
    if(varPaymentDatas.mem_mode === 0){
      navigate(`/Admin/Member/MemberDetail/1/${varPaymentDatas.ph_mem_idx}`);
    } else {
      const memMode = varPaymentDatas.mem_mode + 1;
      navigate(`/Admin/Member/MemberDetail/${memMode}/${varPaymentDatas.ph_mem_idx}`);
    }
  }

  return isObjEmpty(varPaymentDatas)?null:(
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">{menuTitle}</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>요금/이용권관리<img src={arrImage} alt=""/>이용권 결제 관리<img src={arrImage} alt=""/>{menuTitle}
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <table className="write">
            <colgroup>
              <col width="20%"></col>
              <col width="30%"></col>
              <col width="20%"></col>
              <col width="30%"></col>
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>구매코드<button className="btn line" style={{width:"100px"}} onClick={(e)=>onShowPaymentHandler(e)}>세부내역</button></th>
                <td>{varPaymentDatas.ph_code}</td>
                <th>구매유형</th>
                <td>
                  {varPaymentDatas.ph_mode===1?"둥지이용권":varPaymentDatas.ph_mode===2?"장터이용권":"프리미엄광고"}
                  {varPaymentDatas.ph_mode<3 && varPaymentDatas.ph_extend===1?" (기간연장)":null}
                </td>
              </tr>
              <tr>
                <th>구매자</th>
                <td onClick={e=>onGotoMemberHandler(e)}>{varPaymentDatas.mem_mode === 0?varPaymentDatas.mem_name:varPaymentDatas.mcm_name}&nbsp;({varPaymentDatas.mem_email})</td>
                <th>구매금액</th>
                <td>{addThousandPoint(varPaymentDatas.ph_amount)}&nbsp;원</td>
              </tr>
              <tr>
                <th>결제방식</th>
                <td>{varPaymentDatas.ph_method===0?"무통장입금":"카드"}</td>
                <th>등록일자</th>
                <td>{ChangeDateStringOnlyDate(varPaymentDatas.ph_rdate)}</td>
              </tr>
              {varPaymentDatas.ph_method===1?null:
                <tr>
                  <th>입금계좌</th>
                  <td>{varPaymentDatas.bl_name===null?null:varPaymentDatas.bl_name+'('+varPaymentDatas.bh_account+') '+varPaymentDatas.bh_owner}</td>
                  <th>입금자</th>
                  <td>{varPaymentDatas.ph_sender}</td>
                </tr>
              }
              <tr>
                <th>신청일자</th>
                <td>{ChangeDateStringOnlyDate(varPaymentDatas.ph_rdate)}</td>
                {varPaymentDatas.ph_mode>2?
                  <><th>승인일자</th><td>{ChangeDateStringOnlyDate(varPaymentDatas.ph_adate)}</td></>
                :
                  <><th></th><td></td></>
                }
              </tr>
              <tr>
                <th>결제일자</th>
                <td>{ChangeDateStringOnlyDate(varPaymentDatas.ph_pdate)}</td>
                <th>진행상태</th>
                <td>
                  <select name="ph_status" style={{width:"140px"}} value={varPaymentDatas.ph_status} onChange={(e)=>onChangeStatusHandler(e)}>
                    <option value={0}>종료</option>
                    <option value={1}>신청중</option>
                    {varPaymentDatas.ph_method===0 && varPaymentDatas.ph_mode>2?<option value={2}>승인/결제대기</option>:null}
                    <option value={3}>결제완료</option>
                    {varPaymentDatas.ph_method===1?<option value={4}>결제취소</option>:null}
                    {varPaymentDatas.ph_method===0?<option value={5}>환불요청</option>:null}
                    {varPaymentDatas.ph_method===0?<option value={6}>환불완료</option>:null}
                    {varPaymentDatas.ph_method===0?<option value={7}>환불실패</option>:null}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" style={{width:"100px"}} onClick={(e)=>onUpdatePaymentHandler(e)}>저장</button>
          <Link to={`/Admin/Payment/${menuItem}`} style={{width:"100px"}} className="btn line">목록</Link>
        </div>
      </div>

      {/* 팝업창 - 이용권세부내역 */}
      <div className="poplayerPayments" style={varShowVoucher ? { display: "block" } : { display: "none" }}>
        <div className="consbox" style={{ zIndex: 999 }}>
          <div className="btnclose" onClick={(e)=>onCloseVoucherHandler(e,false)}><img src={closeImage} alt=""/></div>
          <table className="write">
            <thead></thead>
            <tbody>
              <tr><th>이용권명</th><td>{varPaymentDatas.vo_name}</td></tr>
              <tr><th>유형</th><td>{varPaymentDatas.pay_mode===1?"둥지":"장터"}</td></tr>
              <tr><th>정가</th><td>{addThousandPoint(varPaymentDatas.vo_price)}&nbsp;원</td></tr>
              <tr><th>구매가</th><td>{addThousandPoint(varPaymentDatas.vo_amount)}&nbsp;원</td></tr>
              <tr><th>등록수량</th><td>{varPaymentDatas.vo_slot}</td></tr>
              <tr><th>이용기간</th><td>{varPaymentDatas.pay_terms}&nbsp;개월</td></tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* 팝업창 - 프리미엄광고 세부내역 */}
      <div className="poplayerPayments" style={varShowAdvertise ? { display: "block" } : { display: "none" }}>
        <div className="consbox" style={{ zIndex: 999 }}>
          <div className="btnclose" onClick={(e)=>onCloseAdvertiseHandler(e,false)}><img src={closeImage} alt=""/></div>
          <table className="write">
            <thead></thead>
            <tbody>
              <tr><th>광고유형</th><td>{varPaymentDatas.pay_mode===1?"둥지":"장터"}</td></tr>
              <tr><th>광고지역</th><td>{varPaymentDatas.rpa_regname}</td></tr>
              <tr><th>광고기간</th><td>{ChangeDateStringOnlyDate(varPaymentDatas.rpa_sdate)+" ~ "+ChangeDateStringOnlyDate(varPaymentDatas.rpa_edate)}</td></tr>
              <tr><th>등록일자</th><td>{ChangeDateStringOnlyDate(varPaymentDatas.rpa_rdate)}</td></tr>
              <tr><th>게시상태</th><td>{varPaymentDatas.rpa_show===0?'게시중지':'게시중'}</td></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PaymentDetail;