/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import {ChangeDateStringOnlyDate} from "../../../components/common/Common";

//지역관리자의 목록 표시
function AgencyAdminList({setShowDetailPage,varTotalList,setCurrentAgencyAdmin,varAgencyAdminLists, setAgencyAdminLists}) {

  //지역관리자 정보 편집하기
  const onEditAgencyAdminHandler = (e,mem_idx) => {
    e.preventDefault();
    setCurrentAgencyAdmin(Number(mem_idx));
    setShowDetailPage(true);
  }

  //지역관리자 추가하기
  const onAddAgencyAdminHandler = (e) => {
    e.preventDefault();
    setCurrentAgencyAdmin(0);
    setShowDetailPage(true);
  }

  //지역관리자 삭제
  const onDeleteAgencyAdminHandler = async(e,mem_idx) => {
    e.preventDefault();
    if(!window.confirm('선택된 항목을 삭제하시겠습니까?')) return false;
    const sendData = {pageModeTitle:"지역관리자",mem_idx:mem_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_admin_list`,sendData).then((res) => {
      const datas = res.data;
      alert(datas.message);
      if(datas.retvalue === 1) {
        //지역관리자목록 재설정
        const newLists = varAgencyAdminLists.filter(element=>element.mem_idx!==mem_idx);
        setAgencyAdminLists(newLists);
      }
    });
  }
  
  return (
    <div className="content">
      <div className="main-box2">
        <div className="btn-right">
          <button className="btn-danger" style={{width:"120px"}} onClick={(e)=>onAddAgencyAdminHandler(e)}>추가등록</button>
        </div>
      </div>
      <div className="main-box">
        <table className="list">
          <colgroup>
            <col width="8%"></col>
            <col width="35%"></col>
            <col width="12%"></col>
            <col width="15%"></col>
            <col width="12%"></col>
            <col width="18%"></col>
          </colgroup>
          <thead>
            <tr>
              <th>번호</th><th>아이디(이메일)</th><th>이름</th><th>관리지역</th><th>등록일자</th><th>관리</th>
            </tr>
          </thead>
          <tbody>
            {varTotalList === 0 ? 
              <tr><td colSpan="5" style={{textAlign:"center"}}>등록된 지역 관리자가 없습니다.</td></tr>
            : varAgencyAdminLists.map((agencyadmin, i) => (
              <tr key={'agencyadmin'+i}>
                <td className=""><p>{varTotalList-i}</p></td>
                <td><p>{agencyadmin.mem_email}</p></td>
                <td><p>{agencyadmin.mem_name}</p></td>
                <td><p>{agencyadmin.regname}</p></td>
                <td><p>{ChangeDateStringOnlyDate(agencyadmin.mem_rdate)}</p></td>
                <td>
                  <button className="btn trline" onClick={(e)=>onEditAgencyAdminHandler(e,agencyadmin.mem_idx)}>수정</button>
                  <button className="btn trline" onClick={(e)=>onDeleteAgencyAdminHandler(e,agencyadmin.mem_idx)}>삭제</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AgencyAdminList
