import React, { useState,useEffect } from "react";

//파트너스 게시물 정보 표시
function SetRegion({irow,varProductDatas,setProductDatas,varRegionList}) {
  //현재 검색 지역 코드
  let regCode = varProductDatas['rpa_regcode'+irow];
  if(regCode === null) regCode = "";

  const [varRegionList1, setRegionList1] = useState([]);
  const [varRegionList2, setRegionList2] = useState([]);
  const [varRegionList3, setRegionList3] = useState([]);

  const [varSiDoCode,setSiDoCode] = useState('');
  const [varSiGunGuCode,setSiGunGuCode] = useState('');
  const [varDongriCode,setDongriCode] = useState('');
  const [varSiDoName,setSiDoName] = useState('');
  const [varSiGunGuName,setSiGunGuName] = useState('');

  const SetRegionListNValue = (regCode) => {
    //console.log(regCode);
    let newData = {regcode:"0000000000",regname:"전국",regstatus:1};
    let copyList = [];
    copyList.push(newData);

    const sidoList = varRegionList.filter(element=>element.regcode.substr(2,8)==='00000000');
    setRegionList1(copyList.concat(sidoList));
    if(regCode !== "") {
      const sidoCode = regCode.substr(0,2);
      if(sidoCode === "00") {
        setRegionList2([]);
        setRegionList3([]);
        setSiDoCode(regCode);
        setSiGunGuCode('00000000');
        setDongriCode('00000');
      } else {
        //현재 지정된 시도코드
        let sidoRegion = regCode;
        if(regCode.substr(2,8)!=='00000000') sidoRegion = sidoCode+'00000000'; 
        setSiDoCode(sidoRegion);

        //해당 시군구 배열
        const sigunguList = varRegionList.filter(element=>element.regcode.substr(2,8)!=='00000000' && element.regcode.substr(5,5)==='00000' && element.regcode.substr(0,2)===sidoCode);
        setRegionList2(sigunguList);

        const sigunguCode = regCode.substr(0,5);
        //현재 지정된 시군구코드
        let sigunguRegion = regCode;
        if(regCode.substr(5,5)!=='00000') sigunguRegion = sigunguCode+'00000'; 
        if(regCode.substr(2,8)==='00000000') sigunguRegion = '00000000';
        setSiGunGuCode(sigunguRegion);

        if(regCode.substr(2,8)==='00000000') {
          setRegionList3([]);
        } else {
          const dongriList = varRegionList.filter(element=>element.regcode.substr(5,5)!=='00000' &&  element.regcode.substr(0,5)===sigunguCode);
          setRegionList3(dongriList);
    
          //현재 지정된 동리코드
          if(regCode.substr(5,5)!=='00000') setDongriCode(regCode);
          else setDongriCode('00000');
        }
      }
    }
  }
      
  //페이지 정보 표시 - 최초
  useEffect(() => {
    SetRegionListNValue(regCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regCode]);

  //지역선택
  const onChangeRegionHandler = (e,icol) => {
    const { value } = e.currentTarget;
    let regcode = value;
    let regname = e.currentTarget.options[e.currentTarget.selectedIndex].text;
    const sname1 = 'rpa_regcode'+irow;
    const sname2 = 'regname'+irow;
    console.log(regcode);
    if(icol === 1) {  //시도 선택시  
      setSiDoCode(regcode); 
      setSiDoName(regname);   
      const sidoCode = regcode.substr(0,2);
      console.log(sidoCode);
      if(sidoCode === "00") {   //전국
        setRegionList2([]);
      } else {
        const sigunguList = varRegionList.filter(element=>element.regcode.substr(2,8)!=='00000000' && element.regcode.substr(5,5)==='00000' && element.regcode.substr(0,2)===sidoCode);
        console.log(sigunguList);
        setRegionList2(sigunguList);  //시군구목록
      }
      setRegionList3([]);   //동리목록
      setSiGunGuCode('');   //시군구코드
      setDongriCode('');    //동리코드
    } else if(icol === 2) { //시군구 선택시
      if(varSiDoCode.substr(0,2) === "00") {
        return false;
      }
      setSiGunGuName(regname);   
      if(regcode === '00000000') {
        regcode = varSiDoCode;
        regname = varSiDoName;
        setRegionList3([]);
      } else { 
        const sigunguCode = regcode.substr(0,5);
        const dongriList = varRegionList.filter(element=>element.regcode.substr(0,5)===sigunguCode && element.regcode.substr(5,5)!=='00000');
        setRegionList3(dongriList);
      }
      setSiGunGuCode(regcode);
      setDongriCode('');
    } else {
      if(varSiDoCode.substr(0,2) === "00") {
        return false;
      }
      if(regcode === '00000') {
        regcode = varSiGunGuCode;
        regname = varSiGunGuName;
      }
    }
    setProductDatas({...varProductDatas,[sname1]:regcode,[sname2]:regname});
  }

  return (
    <td>
      <select name="regcode1" className="select-box" value={varSiDoCode} onChange={(e)=>onChangeRegionHandler(e,1)}>
        <option key={'region1'} value="">시.도선택</option>
        {varRegionList1.map((region1,i)=>(
          <option key={'region1_'+i} value={region1.regcode}>{region1.regname}</option>
        ))}
      </select>&nbsp;
      <select name="regcode2" className="select-box" value={varSiGunGuCode} onChange={(e)=>onChangeRegionHandler(e,2)}>
        <option key={'region2'} value="">시.군.구선택</option>
        <option key={'region2a'} value="00000000">전체선택</option>
        {varRegionList2.map((region2,j)=>(
          <option key={'region2_'+j} value={region2.regcode}>{region2.regname}</option>
        ))}
      </select>&nbsp;
      <select name="regcode3" className="select-box" value={varDongriCode} onChange={(e)=>onChangeRegionHandler(e,3)}>
        <option key={'region3'} value="">동.리선택</option>
        <option key={'region3a'} value="00000">전체선택</option>
        {varRegionList3.map((region3,k)=>(
          <option key={'region3_'+k} value={region3.regcode}>{region3.regname}</option>
        ))}
      </select>&nbsp;
    </td>
  );
}

export default SetRegion;