/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import CONFIG from '../../configs/Configs';
import { ChangeChattingDate,ChangeDateTimeFormat,GetPhotoImage } from "../../components/common/Common";

const ChattingArea = ({socket,varDisplayData,newMessageData,varChattingHistory,setBlockStatus,setReceviedData,onShowBigPhotoHandler,setMyBlockStatus,setBlockStatusString,setOutStatus}) => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));

  const navigate = useNavigate();
 /*  const [varEnterRoom,setEnterRoom] = useState(false); */
  
  useEffect(() => {
    //다른 사람이 접속시 받은 메시지
    socket.on('updateMessage',(data) => {
      console.log('updateMessage',data);
      if(data.message !== null && data.message !== "") {
        if(data.message === "상대방이 대화를 차단하였습니다.") {
          setMyBlockStatus(true);
          setBlockStatus(true);
          setBlockStatusString('※ 상대방으로부터 대화가 차단된 상태입니다.');
        /* } else if(data.message.indexOf('채팅방에 들어왔습니다.')>0) {
          if(!varEnterRoom) setEnterRoom(true);
          else return false; */
        } else if(data.message === "상대방이 대화차단을 해제하였습니다.") {
          setMyBlockStatus(false);
          setBlockStatus(false);
          setBlockStatusString('※ 채팅시 개인정보 유출에 유의하세요');
        } else if(data.message === "상대방이 채팅방에서 나갔습니다.") {
          navigate(-1);
          alert(data.message);
        }
        let newElement = {...newMessageData};
        newElement.cl_mem_idx = data.mem_idx;
        newElement.cl_type = 0;
        newElement.cl_comment = data.message;
        newElement.cl_rdate = ChangeDateTimeFormat();
        //히스토리에 추가
        setReceviedData(newElement);
      }
    });
    //다른 사람으로부터 받는 메시지 
    socket.on('updateImage',(data) => {
      console.log('updateImage',data);
      if(data.filename !== null && data.filename !== "") {
        let newElement = {...newMessageData};
        newElement.cl_mem_idx = data.mem_idx;
        newElement.cl_type = 1;
        newElement.cl_file = data.filename;
        newElement.cl_preview = `${CONFIG.SERVER_HOST}/`+GetPhotoImage(data.filename,'chatting');
        newElement.cl_rdate = ChangeDateTimeFormat();
        //히스토리에 추가
        setReceviedData(newElement);
      }
    }); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  return (
    <>
      {/* 서버에 저장된 메시지 */}
      {varChattingHistory.map((chats,i)=>(
        /* 문자 */
        chats.cl_type === 0?
          <div key={'chats_'+i} className={chats.cl_mem_idx===mem_idx?"msg_box send_msg":"msg_box receive_msg"}>
            {chats.cl_mem_idx===mem_idx?null:
              <div className="name">{chats.cl_mem_idx===varDisplayData.ch_mem_idx?varDisplayData.memName:varDisplayData.ownName}</div>
            }
            <div className="msg msg_text"><span>{chats.cl_comment}</span></div>
            <div className="time">{ChangeChattingDate(chats.cl_rdate)}</div>
          </div>
        : /* 이미지파일 */
        <div key={'chats_'+i} className={chats.cl_mem_idx===mem_idx?"msg_box send_msg":"msg_box receive_msg"}>
          {chats.cl_mem_idx===mem_idx?null:
            <div className="name">{chats.cl_mem_idx===varDisplayData.ch_mem_idx?varDisplayData.memName:varDisplayData.ownName}</div>
          }
          <div className="msg img_wrap" onClick={e=>onShowBigPhotoHandler(e,chats.cl_preview)}>
            <img src={chats.cl_preview} alt="" className="chat_img"/>
          </div>
          {chats.cl_comment===null||chats.cl_comment===""?null:<div className="msg msg_text"><span>{chats.cl_comment}</span></div>}
          <div className="time">{ChangeChattingDate(chats.cl_rdate)}</div>
        </div>
      ))}
    </>
  );
}
export default ChattingArea;