/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react"; 
import closeIcon from '../../images/icon_close.png';

const { kakao } = window;

const MapLocation = ({varCenterPoint,varAddress,varMapLocation,onOpenMapLocationHandler}) => {
  var maps = null;

  const setKakaoMapLocation = (lat,lng) => {
    var mapContainer = document.getElementById('maploc'); // 지도를 표시할 div 
    var coords = new kakao.maps.LatLng(lat,lng);
    var mapOption = { 
        center: coords, // 지도의 중심좌표
        level: 3 // 지도의 확대 레벨
    };
    maps = new kakao.maps.Map(mapContainer, mapOption);
    var zoomControl = new kakao.maps.ZoomControl();
    maps.addControl(zoomControl, kakao.maps.ControlPosition.LEFT);
    var marker = new kakao.maps.Marker({
      map: maps,
      position: coords
    });
    // 인포윈도우로 장소에 대한 설명을 표시합니다
    var infowindow = new kakao.maps.InfoWindow({
      content: `<div style="width:150px;text-align:center;padding:6px 10px;">${varAddress}</div>`
    });
    infowindow.open(maps, marker);
    maps.setCenter(coords);
  }  

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(varCenterPoint !== undefined && varCenterPoint !== null && varCenterPoint !== "") {
      var locArray = varCenterPoint.split(',');
      var lat = parseFloat(locArray[0]);
      var lng = parseFloat(locArray[1]);
      setKakaoMapLocation(lat,lng);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varMapLocation]);

  return (
    <div className="poplayerMap" style={varMapLocation?{display:"block"}:{display:"none"}}>
        <div className="date-right-close"><img src={closeIcon} alt="" onClick={e=>onOpenMapLocationHandler(e,false)}/></div>
        <div id="maploc" style={{width:"100%",height:"100%"}}></div>
    </div>
  );
}
export default MapLocation;