import React, { useState,useEffect } from "react";
import { useParams,useNavigate,Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import {ChangeMobileFormat,ChangeDateStringOnlyDate,isObjEmpty} from "../../../components/common/Common";

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

//사업자 게시물 정보 표시
function PartnerDetail({setMenuItem}) {
  const {jp_idx} = useParams();
  const navigate = useNavigate();

  const newPartnerData = {jp_idx:0,jp_code:'',jp_mcm_idx:0,vb_idx:0,jp_names:'',jp_rdate:null,jp_adate:null,jp_edate:null,
      jp_show:0,jp_status:-1,mcm_name:'',mcm_dongname:'',mcm_address1:'',mcm_address2:'',mcm_introduce:'',vb_name:'',mem_email:''};

  //선택/추가할 저장 변수 
  const [varProductDatas, setProductDatas] = useState({});
  const [varBusinessList, setBusinessList] = useState([]);
  const [varBusinessDetailList, setBusinessDetailList] = useState([]);
  const [varSubBusinessList, setSubBusinessList] = useState([]);

  //사업자 게시물 정보 읽어오기
  const getPartnerInformation = async () => {
    const sendData = {jp_idx:jp_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_partner_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      let vb_idx = 0;
      if(Number(jp_idx)===0) {
        setProductDatas(newPartnerData);
      } else {
        if(datas.retvalue === 1) {
          if(datas.results?.length > 0) {
            setProductDatas(datas.results[0]);
            vb_idx = datas.results[0].vb_idx;
          }
        }
      }
      setBusinessList(datas.business);
      setBusinessDetailList(datas.businessdetail);
      if(vb_idx > 0) {
        const copyLists = datas.businessdetail.filter(element=>Number(element.vb_idx)===Number(vb_idx));
        setSubBusinessList(copyLists);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('PartnerList');
    getPartnerInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //사업자 게시물 정보 입력
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    setProductDatas({...varProductDatas,[name]:value});
    if(name === "vb_idx") {
      if(Number(value) === 0) {
        setSubBusinessList([]);
      } else {
        const copyLists = varBusinessDetailList.filter(element=>Number(element.vb_idx)===Number(value));
        setSubBusinessList(copyLists);
      }
    }
  };

  //옵션처리
  const onChangeOptionsHandler = (e,sname) => {
    const currentString = varProductDatas.jp_names;
    let newString = "";
    if(e.currentTarget.checked) {
      //선택수량 점검
      let countOption = 0;
      if(currentString !== "") {
        const currentArray = currentString.split(',');
        countOption = currentArray?.length;
      }
      if(countOption === 3) {
        alert('게시분야는 최대 3개까지만 선택이 가능합니다.');
        e.currentTarget.checked = false;
        return false;
      }

      if(currentString === null || currentString === "") {
        setProductDatas({...varProductDatas,jp_names:sname });
      } else {
        if(!currentString.includes(sname)) {
          newString = currentString+","+sname;
          setProductDatas({...varProductDatas,jp_names:newString });
        }
      }
    } else {
      if(currentString.includes(sname)) {
        if(currentString.includes(','+sname)) {
          newString = currentString.replace(','+sname,"");
        } else {
          newString = currentString.replace(sname+',',"");
        }
        setProductDatas({...varProductDatas,jp_names:newString });
      }
    }
  }

  //사업자 게시물 정보 저장
  const onUpdateProductHandler = async (e) => {
    if(varProductDatas.jp_idx === 0) {
      if (varProductDatas.vb_idx === 0) {
        alert('등록분야를 선택해 주세요'); return false;
      }
    }
    if (varProductDatas.jp_names === "") {
      alert('게시분야를 먼저 선택해 주세요'); return false;
    } else if(varProductDatas.jp_status === -1) {
      alert('진행상태를 먼저 선택해 주세요'); return false;
    }

    if(!window.confirm('사업자 게시물 정보를 저장합니까?')) return false;

    const sendData = {jp_idx: jp_idx, infos:varProductDatas};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/admin_update_partner_information`, sendData).then((res) => {
      if (res.data.retvalue === 1) {
        navigate("/Admin/ProductList/PartnerList");
      } else {
        alert(res.data.message);
      }
    });
  }

  const onShowMemberHander = (e) => {
    navigate(`/Admin/Member/MemberDetail/3/${varProductDatas.com_idx}`);
  }

  return isObjEmpty(varProductDatas)?null: (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">사업자 게시물 정보</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>둥지/장터관리<img src={arrImage} alt=""/>이용 관리<img src={arrImage} alt=""/>사업자 게시물 정보
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <table className="write">
          <colgroup>
              <col width="20%"></col>
              <col width="30%"></col>
              <col width="20%"></col>
              <col width="30%"></col>
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>사업자명</th>
                <td style={{cursor:"pointer"}} onClick={onShowMemberHander}>{varProductDatas.mcm_name}&nbsp;({varProductDatas.mem_email})</td>
                <th>사업자위치</th>
                <td>{varProductDatas.mcm_dongname}</td>
              </tr>
              <tr>
                <th>사업자 소개</th>
                <td colSpan={3} dangerouslySetInnerHTML={{__html:varProductDatas.mcm_introduce}}></td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td>{ChangeMobileFormat(varProductDatas.mcm_phone)}</td>
                <th>주소</th>
                <td>{varProductDatas.mcm_address1+' '+varProductDatas.mcm_address2}</td>
              </tr>
              <tr><td colSpan={4} className="product_subtitle">등록장터</td></tr>
              <tr>
                <th>등록분야</th>
                <td colSpan={3}>
                  <select name="vb_idx" className="select-box" value={varProductDatas.vb_idx} onChange={(e)=>onChangeInputHandler(e)} disabled> 
                    <option key={"biz"} value={0}>분야선택</option>      
                  {varBusinessList.map((biz,i)=>(
                    <option key={"biz_"+i} value={biz.vb_idx}>{biz.vb_name}</option>
                  ))}
                  </select>
                </td>
              </tr>
              <tr>
                <th>게시분야</th>
                <td colSpan={3}>
                  {varSubBusinessList.map((options,i)=>(
                    <span key={"options_"+i} >
                      <input type="checkbox" name="jp_options" id={"options_"+i} defaultChecked={varProductDatas.jp_names===null?false:varProductDatas.jp_names.includes(options.vbd_name)} onChange={(e)=>onChangeOptionsHandler(e,options.vbd_name)}/>
                      &nbsp;<label htmlFor={"options_"+i}>{options.vbd_name}</label>&nbsp;
                    </span>
                  ))}
                </td>
              </tr>
              <tr>
                <th>게시상태</th>
                <td>
                  <select name="jp_show" className="select-box" value={varProductDatas.jp_show} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value={0}>게시중지</option>
                    <option value={1}>게시중</option>
                  </select>
                </td>
                <th>등록일자</th>
                <td>{ChangeDateStringOnlyDate(varProductDatas.jp_rdate)}</td>
              </tr>
              <tr>
                <th>진행상태</th>
                <td colSpan={3}>
                  <select name="jp_status" className="select-box" value={varProductDatas.jp_status} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value={0}>종료</option>
                    <option value={1}>등록완료</option>
                    <option value={2}>내장터등록</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" style={{width:"100px"}} onClick={(e)=>onUpdateProductHandler(e)}>{jp_idx===0?"등록":"저장"}</button>
          <Link to={"/Admin/ProductList/PartnerList"} style={{width:"100px"}} className="btn line">목록</Link>
        </div>
      </div>
    </div>
  )
}

export default PartnerDetail;