import React, { useState,useEffect,useRef } from "react";
import { Link } from 'react-router-dom'; 
import axios from 'axios';
import CONFIG from '../../configs/Configs'; 
import $ from "jquery";
import {isNull } from "../../components/common/Common";
import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import Footer from "../../components/include/Footer";
import JjimProduct from "../../components/jjim/JjimProduct";

import editIcon from "../../images/icon_next2_y.png";

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const JjimDoongjiList = () => {
  const mem_idx = localStorage.getItem("mem_idx");
  const [varJjimList,setJjimList] = useState([]);
  const [varDeleteList,setDeleteList] = useState([]);

  const scrollRef = useRef([]);

  //찜 둥지 목록 가져오기
  const getJjimDoongjiList = async () => {
    const sendData = {mem_idx:mem_idx,pageMode:1};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_jjim_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setJjimList(datas.results);
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    getJjimDoongjiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ResetScrollTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varJjimList]);
  
  //선택되었던 매물로 이동
  const ResetScrollTop = () => {
    if(!isNull(localStorage.getItem('scrollIndex')) && localStorage.getItem('scrollIndex')!== -1){
      const inum  = localStorage.getItem('scrollIndex');
      const iTop  = localStorage.getItem('scrollTop');
      const saleBlock = scrollRef.current[inum];
      if(!isNull(saleBlock)) { 
        setTimeout(()=>window.scroll({top:iTop,left:0}),10);
        localStorage.setItem('scrollIndex',-1);
      }
    }
  }

  //편집버튼 틀릭시
  const onShowDeleteItems = (e) => {
    $('.btn_area_edit').show();
    $('.go_edit').hide();
    $('.edit_on_wrap').show();
  }
  //완료버튼 클릭시
  const onHideDeleteItems = (e) => {
      $('.go_edit').show();
      $('.btn_area_edit').hide();
      $('.edit_on_wrap').hide();
  }
  const onDeleteJjimHandler = async (e) => {
    if(varDeleteList?.length === 0) {
      alert('찜을 취소할 둥지를 먼저 선택하세요.');return false;
    }
    if(!window.confirm('선택한 둥지들의 찜을 취소합니까?')) return false;

    const sendData = {delList:varDeleteList,pageMode:1};
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_jjim_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setDeleteList([]);
        getJjimDoongjiList();
      } else {
        alert(datas.message);
      }
      onHideDeleteItems(e);
    });   
  }

  return (
    <>
      <Header/>
      <HeadTitle titleString={"찜목록"} backString={"/Doongji/DoongjiList"}/>
      <div id="content" style={{paddingBottom:"40px"}}>
        <div className="like_list01_wrap pd_sub_wrap">
          <div className="tab_menu">
            <ul>
              <li className="on"><Link to={'/Jjim/JjimDoongjiList'}><div>둥지</div></Link></li>
              <li><Link to={'/Jjim/JjimJangterList'}><div>장터</div></Link></li>
            </ul>
          </div>
          {varJjimList?.length===0?null:
            <section>
              <div className="top_edit_area">
                <div className="go_edit" onClick={e=>onShowDeleteItems(e)}>
                  <button>편집 <img src={editIcon} alt=""/></button>
                </div>
                <div className="btn_area_edit">     
                  <ul>
                    <li className="del_btn"><button onClick={e=>onDeleteJjimHandler(e)}>삭제</button></li>
                    <li className="ok_btn" onClick={e=>onHideDeleteItems(e)}><button>완료</button></li>
                  </ul>
                </div>
              </div>
            </section>
          }
          <section className="main_wrap">
            <div className="dungji_wrap">
              <div className="main_dunggi list_type01">
                {varJjimList?.length===0?
                  <div className="list_con">
                    <div className="info01">찜한 둥지 매물이 없습니다.</div>
                </div>
                :varJjimList.map((jjim,i)=>(
                  <div className="list_con" key={'jjim_'+i} ref={e=>(scrollRef.current[i]=e)}>
                    <JjimProduct varIndex={i} varMode={1} varProduct={jjim} varDeleteList={varDeleteList} setDeleteList={setDeleteList}/>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>   
      <Footer varPageMode={3} />
    </>
  );
}
export default JjimDoongjiList;