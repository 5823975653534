import React, { useState,useEffect } from "react"; 
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { addThousandPoint,GetCurrentMonth,ChangeShowMonth,ChangeDateStringOnlyDate,passedDays,ResetDates } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

import prevIcon from '../../images/icon_pre_y.png';
import nextIcon from '../../images/icon_next_y.png';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

//결제내역보기 - 이용권
const PaymentList = () => {
  const navigator = useNavigate();
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const mem_mode = Number(localStorage.getItem("mem_mode"));

  //선택/추가할 저장 변수 
  const [varPaymentDatas, setPaymentDatas] = useState();

  //결제내역 목록 관련
  const [varPaymentList,setPaymentList] = useState([]);
  const [varPayMonth,setPayMonth] = useState("");

  //사용자 이용권 보유현황
  const [varAccounts,setAccounts] = useState({});

  //결제내역 목록 가져오기
  const getVoucherPaymentList = async(curMonth) => {
    const sendData = {mem_idx:mem_idx,mem_mode:mem_mode,payMonth:curMonth};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_payment_list`,sendData).then((res) => {
      const datas = res.data;
      console.log("datas : ", datas);
      if(datas.retvalue === 1) {
        setAccounts(datas.accounts[0]);
        setPaymentDatas(datas.results[0]);
        //검색된 목록
        if(datas.results?.length > 0) {
          //결제목록 재조정 - 결제취소/환불요청
          var pdate = 0;
          let paylist = datas.results;
          for(var i=0; i < paylist.length; i++) {
            paylist[i].cbutton = false;
            //무통장 입금 & 결제대기
            if(paylist[i].ph_method === 0) {
              if(paylist[i].ph_status === 1) {  //신청
                pdate = passedDays(paylist[i].ph_rdate);  //등록일자로부터 경과 일자
                if(pdate <= 7) paylist[i].cbutton = true;
              } else if(paylist[i].ph_status === 2) { //승인
                pdate = passedDays(paylist[i].ph_adate);  //승인일자로부터 경과 일자
                if(pdate <= 7) paylist[i].cbutton = true;
              } else if(paylist[i].ph_status === 3) { //결제
                pdate = passedDays(paylist[i].ph_pdate);  //승인일자로부터 경과 일자
                if(pdate <= 7) paylist[i].cbutton = true;
              }
            //카드결제
            } else {
              if(paylist[i].ph_status === 1) {  //신청
                pdate = passedDays(paylist[i].ph_rdate);  //등록일자로부터 경과 일자
                if(pdate <= 7) paylist[i].cbutton = true;
              } else if(paylist[i].ph_status === 3) { //결제
                pdate = passedDays(paylist[i].ph_pdate);  //승인일자로부터 경과 일자
                if(pdate <= 7) paylist[i].cbutton = true;
              }
            }
          }
          console.log(paylist);
          setPaymentList(paylist);
        } else {
          setPaymentList([]);
        }
      }
    });
  }

  const ExpireDoongji = async(e) => {
    const sendData = {infos:varPaymentDatas};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_expire_doongji`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      alert(datas.message);
    });
  }


  useEffect(() => {
    document.getElementById('main_body').style.backgroundColor = '#FFFFFF';
    const curMonth = GetCurrentMonth();
    setPayMonth(curMonth);
    getVoucherPaymentList(curMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //해당월 변경
  const onChangeMonthHandler = (e,ino) => {
    e.preventDefault();
    const newMonth = ChangeShowMonth(varPayMonth,ino);
    setPayMonth(newMonth);
    getVoucherPaymentList(newMonth);
  }

  //4:결제취소:무통장입금/카드결제, 5:환불요청:무통장입금
  const onPaymentHandler = async (e,payobj,ino) => {
    let message = "구매하신 이용권에 대해 환불을 요청합니까?";
    if(ino === 0){  //신청 --> 신청취소 (무통장/카드)
      message = "주문하신 이용권을 취소합니까?";
    } else if(ino === 4){ //결제대기 ---> 결제취소 (무통장)
      message = "대기중인 이용권의 결제를 취소합니까?";
    }else if(ino === 5){  //결제완료 ---> 환불요청(무통장)/결제취소(카드)
      if(payobj.ph_method === 0){
        message = "환불을 요청하시겠습니까?";
      } else {
        message = "카드로 결제한 이용권을 취소합니까?";
        ino=4;
      }
    }

    if(!window.confirm(message)) return false;

    let sendData = {ph_idx:payobj.ph_idx,ph_status:ino,rpa_idx:0,ph_mode:payobj.ph_mode,ph_extend:payobj.ph_extend,ph_mem_idx:payobj.ph_mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_change_payment_status`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        if(payobj.ph_method === 1 && ino === 4) {  //카드 결제 취소
          CancelCreditCardPayment(payobj);
        } else {
          getVoucherPaymentList(varPayMonth);
        }
      }
    });
    ExpireDoongji();
    navigator(`/MyPage`);
  }
  //카드결제취소
  const CancelCreditCardPayment = async(payobj) => {
    const refund_paymentkey = payobj.ph_paymentkey;
    const sendData = {paymentKey:refund_paymentkey};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_cancel_payment_tosspayment`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        ReturnMemberAccount(payobj);
      } else {
        alert(datas.message);
      }
    });
  }
  //이용권사용내역 조정
  const ReturnMemberAccount = async(payobj) => {
    //이용권 보유현황 재조정
    let cur_slot = 0, cur_expdate = null;
    if(payobj.ph_mode===1) {
      cur_slot = varAccounts.doongji_slots;
      cur_expdate = varAccounts.doongji_expdate;
    } else {
      cur_slot = varAccounts.jangter_slots;
      cur_expdate = varAccounts.jangter_expdate;
    }
    let reduceSlot = payobj.ph_extend===0?payobj.slots:0;
    const new_slot = cur_slot - reduceSlot;
    const new_expdate = ResetDates(cur_expdate,1,payobj.pay_terms*-1); 
    
    const sendData = {mem_idx:mem_idx,ph_mode:payobj.ph_mode,slot:new_slot,cexpdate:cur_expdate,expdate:new_expdate,mcmidx:payobj.mcm_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_reset_member_account`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        RefundPayment(payobj);
      } else {
        alert(datas.message);
      }
    });
  }  
  //환불내역 저장
  const RefundPayment = async(payobj) => {
    const refund_idx = payobj.ph_idx;
    const refund_money = payobj.ph_amount;
    const refund_method = payobj.ph_method;
    const refund_reason = "고객 변심";
    const sendData = {ph_idx:refund_idx,pr_amount:refund_money,pr_method:refund_method,pr_reason:refund_reason};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_refund_payment`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      alert(datas.message);
    });
  }

  const onLinkDetailHandler = (e,payment) => {
    switch (payment.ph_status) {
      case 1: //신청
        navigator(`/MyPage/BuyVoucher/${payment.ph_idx}`);
        break;
      case 2:
      case 3:
        navigator(`/MyPage/VoucherView/${payment.ph_idx}`);
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Header/>
      <HeadTitle titleString={"파트너스 결제 관리"} backString={'/MyPage'}/>
      <div id="content">
        <div className="pd_wrap02">
          <div className="ad_pay_list_wrap" > 
            <div className="go_ad_pay">
              <div className="center_wrap"><Link to="/MyPage/BuyVoucher/0" className="">이용권 구매하기</Link></div>
            </div>
            <div className="tab_menu">
              <ul>
                <li><Link to="/MyPage/PremiumList">프리미엄 광고 신청 내역</Link></li>
                <li className="on"><Link to="/MyPage/PaymentList">결제 내역 보기</Link></li>
              </ul>
            </div>
            <div className="tb_wrap">
              <div className="tb_cal_wrap">
                <div className="month">
                    <img src={prevIcon} alt="" onClick={e=>onChangeMonthHandler(e,-1)}/>
                    &nbsp;&nbsp;&nbsp;<strong>{varPayMonth}</strong>&nbsp;&nbsp;&nbsp;
                    <img src={nextIcon} alt="" onClick={e=>onChangeMonthHandler(e,1)}/>
                </div>
              </div>
              <div className="table_st01"> 
                <table>
                  <colgroup>
                    <col width="20%"/><col width="35%"/><col width="20%"/><col width="25%"/>
                  </colgroup>
                  <thead>
                    <tr><th>일자</th><th>구매항목</th><th>상태</th><th>금액</th></tr>
                  </thead>
                  <tbody>
                    {varPaymentList?.length===0?
                      <tr><td colSpan="4" style={{textAlign:"center"}}>결제 내역이 없습니다.</td></tr>
                    :varPaymentList.map((payment,i)=>(
                      <tr key={"payment_"+i}>
                        <td>
                          {/* <Link to={`/MyPage/PaymentDetail/${payment.ph_idx}`} style={{width:"100%"}}> */}
                            {ChangeDateStringOnlyDate(payment.ph_status<=2?payment.ph_rdate:payment.ph_status===3?payment.ph_pdate:payment.ph_status===4?payment.ph_cdate:payment.ph_status===5||payment.ph_status===7?payment.ph_qdate:payment.ph_fdate)}
                          {/* </Link> */}
                        </td>
                        <td>{payment.ph_mode===1?"둥지이용권 구매"
                            :payment.ph_mode===2?"장터이용권 구매"
                            :payment.ph_mode===3?"프리미엄 둥지등록"
                            :payment.ph_mode===4?"프리미엄 장터등록":null}
                        </td>
                        <td>
                          {payment.ph_status===1?
                            <>
                              <span className="state02" style={{color:"red"}} onClick={e=>onLinkDetailHandler(e,payment)}>신청</span>
                              {payment.cbutton?
                                <button className="btn smalllist" onClick={e=>onPaymentHandler(e,payment,0)}>주문취소</button>
                              :null}
                            </>
                          :payment.ph_status===2?
                            <>
                              <span className="state02" style={{color:"red"}} onClick={e=>onLinkDetailHandler(e,payment)}>결제대기</span>
                              {payment.cbutton?
                                <button className="btn smalllist" onClick={e=>onPaymentHandler(e,payment,4)}>결제취소</button>
                              :null}
                            </>
                          :payment.ph_status===3?
                            <>
                              <span className="state02" style={{color:"#00008B"}} onClick={e=>onLinkDetailHandler(e,payment)}>결제완료</span>
                              {payment.cbutton?
                                <button className="btn smalllist" onClick={e=>onPaymentHandler(e,payment,5)}>{payment.ph_method===0?"환불요청":"결제취소"}</button>
                              :null}
                            </>
                          :payment.ph_status===4?<span className="state01" style={{color:"#00008B"}}>결제취소</span>
                          :payment.ph_status===5?<span className="state01" style={{color:"#00008B"}}>환불요청</span>
                          :payment.ph_status===6?<span className="state01" style={{color:"#00008B"}}>환불완료</span>
                          :payment.ph_status===7?<span className="state01" style={{color:"#00008B"}}>환불실패</span> :null}
                        </td>
                        <td>{addThousandPoint(payment.ph_amount)+" 원"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PaymentList;