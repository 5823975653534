import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';
import DaumPostCode from "../../../components/popup/DaumPostCode";

import { ChangeMobileFormat,ChangeMobileNumber,onlyBankAccountNumber } from '../../../components/common/Common';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

//서비스 업체 정보 관리
function CompanyInfo({setMenuItem}) {

  //서비스 업체 정보 저장 변수 
  const [varCompanyInformation, setCompanyInformation] = useState();

  //서비스 업체 정보 가져오기
  const getCompanyInformation = async () => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_company_information`).then(res => {
      if(res.data.retvalue === 1) {
        if(res.data.results?.length > 0) {
          //서비스 업체 정보 저장
          setCompanyInformation(res.data.results[0]);
        }
      }
    })
  };
  
  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('CompanyInfo');
    getCompanyInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //서비스 업체 정보 재저장
  const onUpdateInformationHandler = async (e) => {
    e.preventDefault();
    if (!window.confirm('서비스업체정보를 저장합니까?')) {
          return false;
    }
    //서비스 업체 정보를 서버로 저장
    await axios.post(`${CONFIG.SERVER_HOST}/_update_company_information`, varCompanyInformation).then((res) => {
      alert(res.data.message);
    });
  }

  //서비스 업체 정보 변경
  const onChangeInformationHandler = (e) => {
    const {name,value} = e.currentTarget;
    if(name === "telephone" || name === "faxmille") {
      const values = ChangeMobileFormat(ChangeMobileNumber(value));
      setCompanyInformation({...varCompanyInformation, [name]:values});
    } else if(name === "bizcode") {
      const taxcode = onlyBankAccountNumber(value);
      setCompanyInformation({...varCompanyInformation, [name]:taxcode});
    } else {
      setCompanyInformation({...varCompanyInformation, [name]:value});
    }
  }

  //우편번호/주소찾기
  const [varOpenZipcode, setOpenZipcode] = useState(false);

  //우편번호/주소 검색 팝업창 열기/닫기
  const onOpenZipcodeHandler = (e, bts) => {
    e.preventDefault();
    setOpenZipcode(bts);
  };
  //우편번호 선택으로 우편번호/주소와 같이 반환
  const ChangeAddress = (zipcode,address1,regcode,dongName,roadName,locString) => {
    console.log(zipcode,address1,regcode,dongName,roadName,locString);
    setCompanyInformation((prevState) => ({ ...prevState, zipcode: zipcode, address1: address1, location:locString }));
  };

  return varCompanyInformation===undefined?null:(
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">업체 정보 관리</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt=""/>운영 정보 관리<img src={arrImage} alt=""/>업체 정보 관리
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <table className="write">
            <colgroup>
              <col width="20%"/><col width="30%"/><col width="20%"/><col width="30%"/>
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>회사명</th>
                <td><input type="text" className="account-box" name="company_name" value={varCompanyInformation.company_name||""} onChange={(e)=>onChangeInformationHandler(e)} /></td>
                <th>대표이사</th>
                <td><input type="text" className="name-box" name="company_ceo" value={varCompanyInformation.company_ceo||""} onChange={(e)=>onChangeInformationHandler(e)} /></td>
              </tr>
              <tr>
                <th>주소</th>
                <td colSpan={3}>
                  <input type="text" name={"zipcode"} className="zipcode-box" value={varCompanyInformation.zipcode||""} readOnly/>
                  <button onClick={(e)=>onOpenZipcodeHandler(e,true)} className="btn" style={{width:"90px"}}>우편번호</button><br/>
                  <input type="text" name={"address1"} className="address1-box" value={varCompanyInformation.address1||""} readOnly/>&nbsp;&nbsp;
                  <input type="text" name={"address2"} className="address2-box" id={"address2"} value={varCompanyInformation.address2||""} 
                          placeholder="상세 주소를 입력해주세요." onChange={(e)=>onChangeInformationHandler(e)}/>
                </td>
              </tr>
              <tr style={{display:"none"}}>
                <th>위치(위도,경도)</th>
                <td><input type="text" className="account-box" name="location" value={varCompanyInformation.location||""} onChange={(e)=>onChangeInformationHandler(e)} /></td>
                <th></th><td></td>
              </tr>
              <tr>
                <th>사업자등록증번호</th>
                <td><input type="text" className="name-box" name="bizcode" value={varCompanyInformation.bizcode||""} onChange={(e)=>onChangeInformationHandler(e)} /></td>
                <th>통신판매업신고번호</th>
                <td><input type="text" className="name-box" name="buytelcode" value={varCompanyInformation.buytelcode||""} onChange={(e)=>onChangeInformationHandler(e)} /></td>
              </tr>
              <tr>
                <th>대표전화</th>
                <td><input type="text" className="name-box" name="telephone" value={ChangeMobileFormat(varCompanyInformation.telephone)||""} onChange={(e)=>onChangeInformationHandler(e)}/></td>
                <th>팩스</th>
                <td><input type="text" className="name-box" name="faxmille" value={ChangeMobileFormat(varCompanyInformation.faxmille)||""} onChange={(e)=>onChangeInformationHandler(e)} /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" onClick={(e)=>onUpdateInformationHandler(e)}>저장</button>
        </div>
      </div>
      {/* 우편번호/주소 검색*/}
      <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler}/>
    </div>
  )
}

export default CompanyInfo;