/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CONFIG from "../../../configs/Configs";
import axios from "axios";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import Paging from "../../layout/Paging";
import AdminQuestionSearch from "../../components/boards/AdminQuestionSearch";
import AdminQuestionSearchResult from "../../components/boards/AdminQuestionSearchResult";

import arrImage from "../../images/arr.png";
import iconhome from "../../images/icon-home.png";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import "../../admin_css/board.css";


const AdminQuestion = () => {
  const my_idx = Number(localStorage.getItem('adminIdx'));
  const my_mode = Number(localStorage.getItem("adminMode")); //1:운영자



  //검색 정보 저장 변수
  const [varSearchData, setSearchData] = useState({
    sdate:null,
    edate:null,
    searchOption:"all",
    searchString:''
  });

  //게시글 목록 저장 변수
  const [varBoardLists,setBoardLists] = useState([]);

  //페이지 조절 변수
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //게시판 정보 전체 가져오기
  const readBoardLists = () => {
    var sendData = {adMode:6,currentPage: varPageNum,pageSize: varPageSize,searchData: varSearchData,my_idx:my_idx,my_mode:my_mode};
    console.log(sendData);
    axios.post(`${CONFIG.SERVER_HOST}/_get_admin_board_list`, sendData).then((res) => {
      console.log(res.data);
      if (res.data.retvalue === 1) {
        setTotalCount(res.data.totalcount);
        const pmax = res.data.totalcount - (varPageNum - 1) * varPageSize;
        const datas = res.data.results.map((data, i) => ({
          board_idx: data.board_idx,
          board_num: pmax - i,
          board_title: data.board_title,
          mem_admin: data.mem_admin,
          mem_name: data.mem_name,
          board_rdate: data.board_rdate,
          board_hits: data.board_hits,
          board_status:data.board_status,
          regname:data.regname,
          checked:0
        }));
        setBoardLists(datas);
      } else {
        setTotalCount(0);
        setBoardLists([]);
        //alert("검색된 공지사항이 없습니다.");
      }
    });
  }
  
  //페이지가 바뀌면
  useEffect(() => {
    readBoardLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum,varSearchData]);

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'ADMINBOARDS'}/>
      <Menus varMenuType={'ADMINBOARDS'} varMenuItem={'AdminQuestion'}/>
      <div id="contents" className="ad_contents">
        <div className="tit">
          <div className="title">1:1 문의</div>
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arrImage} alt="" />관리자 게시판<img src={arrImage} alt="" />1:1 문의
          </div>
        </div>
        <div className="content">
          <AdminQuestionSearch varTotalCount={varTotalCount} varSearchData={varSearchData} setSearchData={setSearchData}/>
          <AdminQuestionSearchResult varBoardLists={varBoardLists} setBoardLists={setBoardLists} readBoardLists={readBoardLists}/>
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </div>
      </div>
    </>
  );
}
export default AdminQuestion;