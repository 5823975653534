/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect,useRef } from "react";
import { useNavigate,useParams } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import { TimeFormat,isPassword,sendMessageToMobile,isObjEmpty,checkEmailString,unescapeHtml,randomStringOnlyNumber,OnlyNumerics } from '../../components/common/Common';
import HeadTitle from '../../components/include/HeadTitle';
import DaumPostCode from "../../components/popup/DaumPostCode";

import joinIcon from "../../images/join_icon01.png";
import colseIcon from "../../images/icon_close.png";

import companyPhoto from "../../images/sub/@thumnail03.png";
import taxPhoto from "../../images/sub/@thumnail03.png";
import licensePhoto from "../../images/sub/@thumnail03.png";

//mem_mode=1:부동산파트너/=2:사업자 파트너
const JoinPartner = () => {
  const { joinMode } = useParams();
  const navigate = useNavigate();
  let mem_idx = 0;
  //파일관련
  const fileRef1 = useRef(null);
  const fileRef2 = useRef(null);
  const fileRef3 = useRef(null);
 
  //전화번호
  const hpRef1 = useRef(null); 
  const hpRef2 = useRef(null); 
  const hpRef3 = useRef(null); 
  const phRef1 = useRef(null); 
  const phRef2 = useRef(null); 
  const phRef3 = useRef(null); 

  //회원정보 저장 객체
  const newMember = {mem_idx:0,mem_mode:Number(joinMode),mem_email:"",mem_pwd:"",mem_pwd2:"",mcm_name:"",mcm_phone1:"",mcm_phone2:"",mcm_phone3:"",
  mcm_zipcode:"",mcm_address1:"",mcm_address2:"",mcm_location:"",mem_name:"",mem_mobile1:"",mem_mobile2:"",mem_mobile3:"",mem_cert:"",mcm_business:0,
  mcm_tax_id:"",mcm_tax_email:"",mcm_introduce:"",mem_condition:1,mem_privacy:1,mem_marketing:0,mem_certification:0,mcm_roadname:"",mcm_broker_license:"",
  mcm_regcode:"",mcm_dongname:"",mcm_approval:0,mcm_image:0,mcm_file:"",mcm_tax_image:0,mcm_tax_file:"",mcm_broker_image:0,mcm_broker_file:""};
  
  const chkMember = {mem_email:false,mem_mobile:false,mem_certcode:""};

  //회원정보
  const [varMember,setMember] = useState({});
  //인증정보
  const [varCheckMember,setCheckMember] = useState({});
  //프로필사진
  const [varProfilePhoto,setProfilePhoto] = useState(companyPhoto);
  const [varTaxPhoto,setTaxPhoto] = useState(taxPhoto);
  const [varBrokerPhoto,setBrokerPhoto] = useState(licensePhoto);
  //업종목록
  const [varBusinessList,setBusinessList] = useState([]);
  
  //팝업창
  const [varOpenTerms,setOpenTerms] = useState(false);
  const [varOpenZipcode,setOpenZipcode] = useState(false);
  //약관
  const [varCurrentTerm,setCurrentTerm] = useState('serviceterm');   
  const [varTerms,setTerms] = useState({serviceterm_title:"이용약관",privacy_title:"개인정보취급방침",marketing_title:"마케팅정보수신",serviceterm:"",privacy:"",marketing:""});

  //타이머
  const [varTime,setTime] = useState(0);
  const [varTimeDisplay,setTimeDIsplay] = useState(0);
  const timerId = useRef(null);
  //휴대폰 인증관련 메뉴 표시 유무
  const [varShowMobile,setShowMobile] = useState(false);
  const [varMobileTimer,setMobileTimer] = useState(false);

  //FCM TOKEN 메모리에 저장
  const eventFromAndroid = async (e) => {
    localStorage.setItem('fcm_token',e.detail.token);
  }
  //ios에 의해 호출되는 함수
  window.onFcmInfoSuccess = (token,os) => {
    console.log(token);
    localStorage.setItem('fcm_token',token);
  }

  // 중복제출 방지
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setMember(newMember);
    setCheckMember(chkMember);

    //네이티브에 기기코드 요청
    const mobileType = navigator.userAgent.toLowerCase();
    //console.log(mobileType);
    if (mobileType.indexOf('android') > -1) {
      if(window.newproj) {
        window.addEventListener("onFcmInfoSuccess", eventFromAndroid);
        window.newproj.getFcmInfo();
      } 
    } else if (mobileType.indexOf('iphone') > -1 || mobileType.indexOf('ipad') > -1 || mobileType.indexOf('ipod') > -1) {
      //alert(window.webkit);
      if(window.webkit) window.webkit.messageHandlers.getFcmInfo.postMessage('');
    } 

    getTermInformation();
    getBusinessList();
    return () => {
      if(window.newproj){
        window.removeEventListener("onFcmInfoSuccess",eventFromAndroid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //약관정보 읽어오기
  const getTermInformation = async () => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_terms`).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        const termObj = datas.results;
        termObj.serviceterm = unescapeHtml(termObj.serviceterm);
        termObj.privacy = unescapeHtml(termObj.privacy);
        termObj.marketing = unescapeHtml(termObj.marketing);
        setTerms({...varTerms,serviceterm:termObj.serviceterm,privacy:termObj.privacy,marketing:termObj.marketing});
      }
    });
  }
  //업종읽어오기
  const getBusinessList = async () => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_business_list`).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        setBusinessList(datas.results);
      }
    });
  }

  //회원종류 선택 핸들러
  const onSelectModeHandler = (e, imode) => {
    const prevMode = varMember.mem_mode;
    if (prevMode === 1 && imode === 2) {
      // 부동산파트너에서 사업자파트너로 변경할 때
      setMember({
        ...varMember,
        mem_mode: imode,
        mcm_tax_image: 0,
        mcm_tax_file: "",
        mcm_broker_image: 0,
        mcm_broker_file: "",
        mcm_broker_license: "",
      });
      // 이미지 초기화
      setTaxPhoto(taxPhoto);
      setBrokerPhoto(licensePhoto);
      // 파일 입력 초기화
      if (fileRef1.current) fileRef1.current.value = null; // tax_file
      if (fileRef2.current) fileRef2.current.value = null; // broker_file
    } else if (prevMode === 2 && imode === 1) {
      // 사업자파트너에서 부동산파트너로 변경할 때
      setMember({
        ...varMember,
        mem_mode: imode,
        mcm_business: 0,
      });
    } else {
      setMember({ ...varMember, mem_mode: imode });
    }
  };

  //선택창 변경 
  const onChangeSelectHandler = (e) => {
    const { name,value } = e.currentTarget;
    console.log(name,value);
    setMember({...varMember,[name]:Number(value)});
  }
  //입력값 처리
  const onChangeInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    if(name === 'mem_email') {
      if(varCheckMember.mem_email) {
        setCheckMember({...varCheckMember,mem_email:false});
      }
      setMember({...varMember,[name]:value});
    } else if(name === 'mem_mobile1' || name === 'mem_mobile2' || name === 'mem_mobile3' ) {
      if(varCheckMember.mem_mobile) {
          setCheckMember({...varCheckMember,mem_mobile:false});
      }
      setMember({...varMember,[name]:OnlyNumerics(value)});
    } else if(name === 'mcm_phone1' || name === 'mcm_phone2' || name === 'mcm_phone3') {
      setMember({...varMember,[name]:OnlyNumerics(value)});
    } else {
      setMember({...varMember,[name]:value});
    }
  }
  //중복확인
  const onCheckDuplicationHandler = async (e,soption) => {
    if(varMember[soption] === "") {
      alert("아이디(이메일주소)를 먼저 입력하세요."); return false;
    }
    const sendData = {checkField:soption,checkString:varMember[soption]};
    await axios.post(`${CONFIG.SERVER_HOST}/_check_duplication`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        setCheckMember({...varCheckMember,[soption]:true});
      } else {
        setMember({...varMember,[soption]:""});
      }
      alert(datas.message);
    });
  }

  //휴대폰-----------------------------------------------------------------------------------
  //타이머 시작 및 종료
  useEffect(() => {
    if(varMobileTimer) {
      if (varTime > 0) {
        timerId.current = setInterval(() => {
          setTime(prev => prev - 1);
          setTimeDIsplay(varTime);
        }, 1000);
        return () => {clearInterval(timerId.current);}
      } else {
        console.log("타임 아웃",varTime);
        setTime(0); //타이머 마감표시
        setMobileTimer(false);  //타이머 초기화 선언
        setTimeDIsplay(varTime);  //시간표시
        setShowMobile(false); //핸드폰 인증 메뉴 감추기
        clearInterval(timerId.current); //타이머 종료
        setCheckMember({...varCheckMember,mem_certcode:""});  //인증코드 초기화
        alert('입력시간이 초과 되었습니다. 휴대폰 인증을 새로 진행해주세요.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTime]);

  const onSendCertificationHandler = async(e) => {
    if(varMember.mem_mobile1==="" || varMember.mem_mobile2==="" || varMember.mem_mobile3==="") {
      alert('인증할 휴대폰번호를 먼저 입력하세요.'); return false;
    }
    const mem_mobile = varMember.mem_mobile1+varMember.mem_mobile2+varMember.mem_mobile3;
    //동일한 전번이 존재하는지 확인
    const sendData = {mem_mode:varMember.mem_mode, mem_mobile:mem_mobile};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_check_duplicate_mobile`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        SendCertificationHandler(mem_mobile);
      } else {
        alert(datas.message);
      }
    });
  }
  const SendCertificationHandler = (mem_mobile) => {    
    //인증코드 생성 및 발송
    const certCode = randomStringOnlyNumber(4);
    setCheckMember({...varCheckMember,mem_certcode:certCode});
    sendMessageToMobile(mem_mobile,certCode);
    setShowMobile(true);
    //타이머 작동 - display:on
    setMobileTimer(true); //타이머 작동시작
    setTime(180);
  }
  //인증코드 입력후 검증
  const onCheckCertificationHandler = (e) => {
    if(varCheckMember.mem_certcode === "") {
      alert('도착한 인증코드를 먼저 입력하세요.'); return false;
    }
    //console.log(varCheckMember.mem_certcode,varMember.mem_cert);
    if(varCheckMember.mem_certcode !== varMember.mem_cert) {
      alert('입력한 코드가 발송한 인증코드와 일치하지 않습니다. 확인후 다시 입력하세요.'); 
      //인증코드부분 초기화
      setMember({...varMember,mem_cert:""});
      return false;
    }
    //인증완성
    setCheckMember({...varCheckMember,mem_mobile:true});
    setMember({...varMember,mem_certification:1});
    setMobileTimer(false);  //타이머 작동 초기화 선언
    setShowMobile(false); //인증부분 감추기
    clearInterval(timerId.current); //타이머 종료
    alert('휴대폰 본인인증이 되었습니다.');
  }
  //우편번호/주소 검색 팝업창 열기/닫기-------------------------------------------------------------------------------------
  const onOpenZipcodeHandler = (e, bmode) => {
    e.preventDefault();
    setOpenZipcode(bmode);
  };
  //우편번호 선택으로 우편번호/주소와 같이 반환
  const ChangeAddress = (zipcode,fullAddr,regcode,dongName,roadName,locString) => {
    console.log(zipcode,fullAddr,regcode,dongName,roadName,locString);
    setMember((prevState)=>{return{...prevState,mcm_zipcode:zipcode, mcm_address1:fullAddr, mcm_regcode:regcode, mcm_dongname:dongName,mcm_roadname:roadName,mcm_location:locString}});
  };

  //약관 팝업창 열기/닫기-------------------------------------------------------------------------------------
  const onOpenTermsHandler = (e,bmode) => {
    const { name } = e.currentTarget;
    setCurrentTerm(name);
    setOpenTerms(bmode);
  } 
  //약관 정체 선택유무
  const onCheckAllTermsHandler = (e) => {
    const bmode = e.currentTarget.checked;
    const imode = Number(bmode);
    for(var i=1; i <= 3; i++) {
      document.getElementById('agree_chk'+i).checked = bmode;
    }
    setMember({...varMember,mem_condition:imode,mem_privacy:imode,mem_marketing:imode})
  }
  //약관별 선택유무
  const onCheckedTermHandler = (e) => {
    const { name } = e.currentTarget;
    const imode = Number(e.currentTarget.checked);
    setMember({...varMember,[name]:imode});
    //전체선택 표시유무 확인
    let inon = 0;
    for(var i=1; i <= 3; i++) {
      if(!document.getElementById('agree_chk'+i).checked) {
        inon++;
        document.getElementById('chk_all').checked = false;
        break;
      }
    }
    if(inon === 0) document.getElementById('chk_all').checked = true;
  }
  //로그인
  const onGotoLoginHandler = (e) => {
    navigate(`/Login`);
  }
  //프로필사진
  const onSelectPhotoHandler = (e,ino) => {
    if(!varCheckMember.mem_mobile) {
      alert('사진등록전에 휴대폰인증을 먼저 하셔야 합니다.'); return false;
    }
    e.preventDefault();
    if(ino === 1) fileRef1.current.click();
    else if(ino === 2) fileRef2.current.click();
    else if(ino === 3) fileRef3.current.click();
  }  
  const onSelectedPhotoHandler = (e,ino) => {
    e.preventDefault();
    const uploadFile = e.currentTarget.files[0];
    //화면에 이미지 표시
    
    if(ino === 1) {
      setTaxPhoto(URL.createObjectURL(uploadFile));
      setMember({...varMember, mcm_tax_image:1});
    } else if(ino === 2) {
      setBrokerPhoto(URL.createObjectURL(uploadFile));
      setMember({...varMember, mcm_broker_image:1});
    } else {
      setProfilePhoto(URL.createObjectURL(uploadFile));
      setMember({...varMember, mcm_image:1});
    }
  }

  //회원등록-----------------------------------------------------------------------------------------------
  const onRegisterMemberHandler = async(e) => {
    if(varMember.mem_idx > 0) {
      alert('이미 회원으로 등록되었습니다.'); return false;
    }
    if(varMember.email ==="") {
      alert('아이디(이메일)을 먼저 입력하세요'); return false;
    } else if (!checkEmailString(varMember.mem_email)) {
      alert('입력된 아이디(이메일)이 이메일 형식에 부합되지 않습니다.'); return false;
    } else if (!varCheckMember.mem_email) {
      alert('아이디(이메일)의 중복검사를 먼저 실행하세요'); return false;
    } else if (varMember.mem_pwd === "") {
      alert('비밀번호를 먼저 입력하세요.'); return false;
    } else if (varMember.mem_pwd !== varMember.mem_pwd2) {
      alert('비밀번호와 확인용 비밀번호가 일치하지 않습니다.'); return false;
    } else if(!isPassword(varMember.mem_pwd) || varMember.mem_pwd?.length < 8 || varMember.mem_pwd?.length > 12 ) {
      alert('비밀번호는 영문, 숫자, 특수문자 포함 8~12자리로 입력해주세요.'); return false;
    } else if (varMember.mcm_name === "") {
      alert('업체명을 먼저 입력하세요'); return false;
    } else if (varMember.mcm_zipcode === "") {
      alert('주소를 먼저 선택하세요'); return false;   
    } else if (varMember.mem_name === "") {
      alert('담당자명을 먼저 입력하세요'); return false;
    } else if (varMember.mcm_phone1 === "" || varMember.mcm_phone2 === "" || varMember.mcm_phone3 === "") {
      alert('업체 대표 번호를 먼저 입력하세요'); return false;  
    } else if (varMember.mem_mobile1 === "" || varMember.mem_mobile2 === "" || varMember.mem_mobile3 === "") {
      alert('휴대폰 번호를 먼저 입력하세요'); return false;   
    } else if (varMember.mcm_tax_id === "") {
      alert('사업자등록번호를 먼저 입력하세요'); return false;
    } else if (varMember.mcm_tax_email === "") {
      alert('이메일(세금계산서 발행용)을 먼저 입력하세요'); return false;   
    } else if (!checkEmailString(varMember.mcm_tax_email)) {
      alert('입력된 이메일(세금계산서 발행용)이 이메일 형식에 부합되지 않습니다.'); return false;
    } else if (varMember.mcm_introduce === "") {
      alert('업체 소개글을 먼저 입력하세요'); return false;
    } else if (!varCheckMember.mem_mobile) {
      alert('담당자 휴대폰 번호를 먼저 인증하세요'); return false;  
    } else if (varMember.mem_condition === 0) {
      alert('이용약관에 먼저 동의하셔야만 합니다.'); return false;
    } else if (varMember.mem_privacy === 0) {
      alert('개인정보취급방침에 먼저 동의하셔야만 합니다.'); return false;
    }

    if(varMember.mem_mode===2 && varMember.mcm_business === 0) {  //사업자파트너
        alert('업종분류를 먼저 선택해야만 합니다.'); return false;
    }

    if(mem_idx > 0) {
      alert('이미 등록되었습니다.'); return false;
    }

    if(!window.confirm('회원으로 등록하시겠습니까?')) return false;
    if (isSubmitting) return; // 이미 제출 중이면 중복 제출 방지
    setIsSubmitting(true); // 제출 중으로 변경
    let proFileName = "", taxFileName = "", licenseFileName = "";
    let ino = 0;
    let formData = new FormData();
    if(varMember.mcm_image === 1) {
      ino=1;
      const profileImage = document.getElementById('profile');
      formData.append('member',profileImage.files[0]);
    }
    if(varMember.mcm_tax_image === 1) {
      ino = ino + 2;
      const taxFile = document.getElementById('tax_file');
      formData.append('member',taxFile.files[0]);
    }
    if(varMember.mcm_broker_image === 1) {
      ino = ino + 4;
      const brokerFile = document.getElementById('broker_file');
      formData.append('member',brokerFile.files[0]);
    }
    //추가된 매물 사진 배열을 서버로 업로드 등록
    if(ino > 0) {
      await axios.post(`${CONFIG.SERVER_HOST}/memberArray`, formData).then((res) => {
        console.log(res.data);
        if(res.data.retvalue === 1) {
          const fileNames = res.data.fileNames; //배열
          console.log(fileNames);
          if(ino === 1) {
            proFileName = fileNames[0];
          } else if(ino === 2) {
            taxFileName = fileNames[0];
          } else if(ino === 3) {
            proFileName = fileNames[0]; 
            taxFileName = fileNames[1];         
          } else if(ino === 4) {
            licenseFileName = fileNames[0];          
          } else if(ino === 5) {
            proFileName = fileNames[0]; 
            licenseFileName = fileNames[1];          
          } else if(ino === 6) {
            taxFileName = fileNames[0];  
            licenseFileName = fileNames[1];          
          } else if(ino === 7) {
            proFileName = fileNames[0];
            taxFileName = fileNames[1];  
            licenseFileName = fileNames[2];            
          }
          RegisterMemberInformation(proFileName,taxFileName,licenseFileName);
        } else {
          alert('프로필 관련 사진을 업로드할 수 없습니다.');
        }
      });
    } else { 
      RegisterMemberInformation(proFileName,taxFileName,licenseFileName);
    }
  }

  //데이타베이스로 회원정보 등록
  const RegisterMemberInformation = async (proFileName,taxFileName,licenseFileName) => {
    const mem_device = localStorage.getItem('fcm_token');
    const sendData = {infos:varMember,mem_device:mem_device,proFileName:proFileName,taxFileName:taxFileName,licenseFileName:licenseFileName};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_register_partner_member`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        mem_idx = datas.mem_idx;
        setMember({...varMember,mem_idx:mem_idx});
        localStorage.setItem("memberLoginState", 0);
        localStorage.setItem("mem_idx",0);
        navigate(`../JoinPartnerOK`);
      } else {
        alert('회원가입에 실패하였습니다.\n잘못 입력된 값이 있는지 확인 바랍니다.');
      }
    });
  }

  const onKeyPressHandler = (e,ino) => {
    const { value } = e.currentTarget;
    if(ino === 1) {
      if((value.length === 3 && value === "010") || value.length === 4) phRef2.current.focus();
    } else if(ino === 2) {
      if(value.length === 4) phRef3.current.focus();
    }
  }
  const onKeyPressMobileHandler = (e,ino) => {
    const { value } = e.currentTarget;
    console.log(ino,value);
    if(ino === 1) {
      if(value.length === 3) hpRef2.current.focus();
    } else if(ino === 2) {
      if(value.length === 4) hpRef3.current.focus();
    }
  }

  return isObjEmpty(varMember)?null: (
    <>
      <HeadTitle titleString={"회원가입"}/>
      <div className="pd_wrap04">
        <div className=" join_wrap">
          <div className="center_wrap">
            <div className="form_wrap">
              <div className="tit07">가입 유형을 선택해주세요&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <div className="dan2">
                <li className="terms_chks">
                  <input type="radio" name="join_type" className="chk1 join_cpn_type01" id="join_cpn_type01" checked={varMember.mem_mode===1?true:false} onChange={e=>onSelectModeHandler(e,1)}/>
                  <i></i><label htmlFor="join_cpn_type01">부동산파트너</label>
                </li>
                <li className="terms_chks">
                  <input type="radio" name="join_type" className="chk1 join_cpn_type02" id="join_cpn_type02" checked={varMember.mem_mode===2?true:false} onChange={e=>onSelectModeHandler(e,2)}/>
                  <i></i><label htmlFor="join_cpn_type02">사업자파트너</label>
                </li>
              </div>
            </div>
            <div className="cpn01_join_wrap"></div>
            <div className="form_wrap">
              <div className="tit07">이메일(이메일 주소)&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <div className="has_btn">
                <input type="text" className="w_s_input" name="mem_email" value={varMember.mem_email} placeholder="이메일을 입력하세요." onChange={e=>onChangeInputHandler(e)}/>
                <button className="w_s_btn c_white" onClick={e=>onCheckDuplicationHandler(e,'mem_email')}>중복체크</button>
              </div>
            </div>
            <div className="form_wrap">
              <div className="tit07">비밀번호&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <input type="password" name="mem_pwd" value={varMember.mem_pwd} placeholder="비밀번호를 입력하세요." onChange={e=>onChangeInputHandler(e)}/>
              <div className="txt_s_red"><img src={joinIcon} alt=""/>영문, 숫자, 특수문자 포함 8~12자리로 입력해주세요.</div>
            </div>
            <div className="form_wrap">
              <div className="tit07">비밀번호 재확인&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <input type="password" name="mem_pwd2" value={varMember.mem_pwd2} placeholder="비밀번호와 동일하게 입력해주세요." onChange={e=>onChangeInputHandler(e)}/>
              <div className="txt_s_red"><img src={joinIcon} alt=""/>비밀번호와 동일하게 입력해주세요.</div>
            </div>
            <div className="form_wrap">
              <div className="tit07">업체명&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <input type="text" name="mcm_name" value={varMember.mcm_name} placeholder="업체명을 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
            </div>
            <div className="form_wrap">
              <div className="tit07">업체 대표 번호&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <div className="ph_num_dan4">
                <input type="number" pattern="\d*" name="mcm_phone1" ref={phRef1} value={varMember.mcm_phone1} maxLength={4} onKeyUp={e=>onKeyPressHandler(e,1)} onChange={e=>onChangeInputHandler(e)}/>
                <div className="mid_bar">-</div>
                <input type="number" pattern="\d*" name="mcm_phone2" ref={phRef2} value={varMember.mcm_phone2} maxLength={4} onKeyUp={e=>onKeyPressHandler(e,2)} onChange={e=>onChangeInputHandler(e)}/>
                <div className="mid_bar">-</div>
                <input type="number" pattern="\d*" name="mcm_phone3" ref={phRef3} value={varMember.mcm_phone3} maxLength={4} onChange={e=>onChangeInputHandler(e)}/>
              </div>
            </div>
            <div className="form_wrap">
              <div className="tit07">사업장주소&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <div className="has_btn" onClick={e=>onOpenZipcodeHandler(e,true)}>
                <input type="text" className="w_s_input disable" name="mcm_zipcode" value={varMember.mcm_zipcode} onClick={e=>onOpenZipcodeHandler(e,true)} readOnly/>
                <button className="w_s_btn c_white" onClick={e=>onOpenZipcodeHandler(e,true)}>우편번호</button>
              </div>
              <div onClick={e=>onOpenZipcodeHandler(e,true)}>
                <input type="text" className="fix_input" name="mcm_address1" value={varMember.mcm_address1} onClick={e=>onOpenZipcodeHandler(e,true)} readOnly/>
              </div>
              <input type="text" className="mt03" name="mcm_address2" value={varMember.mcm_address2} placeholder="상세주소" onChange={e=>onChangeInputHandler(e)}/>
            </div>
            <div className="form_wrap">
              <div className="tit07">담당자명&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <input type="text" name="mem_name" value={varMember.mem_name} placeholder="담당자명을 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
            </div>
            <div className="form_wrap">
              <div className="tit07">담당자 휴대폰 번호&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <div className="ph_num_dan3">
                <input type="number" pattern="\d*" name="mem_mobile1" ref={hpRef1} value={varMember.mem_mobile1} className="input-center" maxLength={3} onKeyUp={e=>onKeyPressMobileHandler(e,1)} onChange={e=>onChangeInputHandler(e)}/>
                <div className="mid_bar">-</div>
                <input type="number" pattern="\d*" name="mem_mobile2" ref={hpRef2} value={varMember.mem_mobile2} className="input-center" maxLength={4} onKeyUp={e=>onKeyPressMobileHandler(e,2)} onChange={e=>onChangeInputHandler(e)}/>
                <div className="mid_bar">-</div>
                <input type="number" pattern="\d*" name="mem_mobile3" ref={hpRef3} value={varMember.mem_mobile3} className="input-center" maxLength={4} onChange={e=>onChangeInputHandler(e)}/>&nbsp;
                <button className="w_s_btn c_white" onClick={e=>onSendCertificationHandler(e)}>인증하기</button>
              </div>
              <div className="has_btn" style={varShowMobile?{display:"block"}:{display:"none"}}>
                <input type="text" name="mem_cert" value={varMember.mem_cert} placeholder="인증번호를 입력해주세요." onChange={e=>onChangeInputHandler(e)}/>
                <div className="fn_timer" style={varShowMobile?{display:"block"}:{display:"none"}}>{TimeFormat(varTimeDisplay)}</div>
                <button className="w_s_btn" onClick={e=>onCheckCertificationHandler(e)}>확인</button>
              </div>
            </div>
            <div className="form_wrap">
              <div className="tit07">사업자등록번호&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <input type="text" name="mcm_tax_id" value={varMember.mcm_tax_id} placeholder="사업자등록번호를 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
            </div>
            {/* 사업자등록증은 부동산파트너일 때만 표시 */}
            {varMember.mem_mode===2?null:
              <div className="form_wrap">
                <div className="tit07">사업자등록증</div>
                <div className="input_img_wrap">
                  <div className="input_img_con">
                    <div className="img_upload_area">
                      <div className="img_upload">
                        <input type="file" ref={fileRef1} name="tax_file" id="tax_file" className="fileUpload" accept={"image/*"} onChange={e=>onSelectedPhotoHandler(e,1)}/>
                        <div className="prev_img"><img src={varTaxPhoto} alt="" onClick={(e)=>onSelectPhotoHandler(e,1)}/></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="form_wrap">
              <div className="tit07">이메일(세금계산서 발행용)&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <input type="text" name="mcm_tax_email"  value={varMember.mcm_tax_email} placeholder="세금계산서 발행용 이메일을 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
            </div>
            {varMember.mem_mode===2?
              <div className="form_wrap">
                <div className="tit07">업체분류&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
                <select name="mcm_business" value={varMember.mcm_business} onChange={e=>onChangeSelectHandler(e)}>
                  <option value={0}>업종선택</option>
                  {varBusinessList.map((biz,i)=>(
                    <option key={'biz_'+i} value={biz.vb_idx}>{biz.vb_name}</option>
                  ))}
                </select>
              </div>
            :
            <>
              <div className="form_wrap">
                <div className="tit07">공인중개사 자격증</div>
                <div className="input_img_wrap">
                  <div className="input_img_con">
                    <div className="img_upload_area">
                      <div className="img_upload">
                        <input type="file" ref={fileRef2} name="broker_file" id="broker_file" className="fileUpload" accept={"image/*"} onChange={e=>onSelectedPhotoHandler(e,2)}/>
                        <div className="prev_img"><img src={varBrokerPhoto} alt="" onClick={(e)=>onSelectPhotoHandler(e,2)}/></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form_wrap">
                <div className="tit07">부동산중개사번호&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
                <input type="text" name="mcm_broker_license" value={varMember.mcm_broker_license} placeholder="부동산중개사번호를 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
              </div>
            </>
            }
            <div className="form_wrap">
              <div className="tit07">업체 대표 사진</div>
              <div className="input_img_wrap">
                <div className="input_img_con">
                  <div className="img_upload_area">
                    <div className="img_upload">
                      <input type="file" ref={fileRef3} name="profile" id="profile" className="fileUpload" accept={"image/*"} onChange={e=>onSelectedPhotoHandler(e,3)}/>
                      <div className="prev_img"><img src={varProfilePhoto} alt="" onClick={(e)=>onSelectPhotoHandler(e,3)}/></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form_wrap">
              <div className="tit07">업체 소개글&nbsp;<span style={{color:"red",fontSize:"12px"}}>(필수)</span></div>
              <textarea name="mcm_introduce" cols="30" rows="5" style={{maxWidth:"100%",minWidth:"100%",width:"100%"}} value={varMember.mcm_introduce}
                              placeholder="이후 장터에 소개될 소개글입니다. 업체에 대한 정보를 간략하게 설명해주세요." onChange={e=>onChangeInputHandler(e)}/>
            </div>
            <div className="agree_area service">
              {/* <div className="line02"></div> */}
              <div className="terms_chks all_agr">
                  <input className="chk1" type="checkbox" name="chk_all" id="chk_all" onClick={e=>onCheckAllTermsHandler(e)}/>
                  <i></i><label htmlFor="chk_all">서비스 이용약관 전체 동의</label>
              </div>
              <div className="center_wrap2" id="agree_item_chk">
                <div className="terms_chks">
                  <input type="checkbox" className="chk1" name="mem_condition" id="agree_chk1" defaultChecked={varMember.mem_condition} onClick={e=>onCheckedTermHandler(e)}/>
                  <i></i><label htmlFor="agree_chk1">(필수) 이용 약관에 동의</label>
                  <div className="term_pop_btn"><button name="serviceterm"style={{fontSize:"14px",paddingTop:"3px"}}  onClick={e=>onOpenTermsHandler(e,true)}>보기</button></div>
                </div>
                <div className="terms_chks">
                  <input type="checkbox" className="chk1" name="mem_privacy" id="agree_chk2"defaultChecked={varMember.mem_privacy} onClick={e=>onCheckedTermHandler(e)}/>
                  <i></i><label htmlFor="agree_chk2">(필수) 개인정보 취급 방침에 동의</label>
                  <div className="privacy_pop_btn"><button name="privacy" style={{fontSize:"14px",paddingTop:"3px"}} onClick={e=>onOpenTermsHandler(e,true)}>보기</button></div>
                </div>
                <div className="terms_chks">
                    <input type="checkbox" className="chk1" name="mem_marketing" id="agree_chk3"defaultChecked={varMember.mem_marketing} onClick={e=>onCheckedTermHandler(e)}/>
                    <i></i><label htmlFor="agree_chk3">(선택) 마케팅 정보 수신에 동의합니다.</label>
                    <div className="marketing_pop_btn"><button name="marketing" style={{fontSize:"14px",paddingTop:"3px"}} onClick={e=>onOpenTermsHandler(e,true)}>보기</button></div>
                </div>
                <br/>
                <div className="btn_bottom">
                  <button className="btn_style_no" onClick={e=>onGotoLoginHandler(e)}>취소</button>
                  <button className="btn_style_yes" onClick={e=>onRegisterMemberHandler(e)} disabled={isSubmitting}>확인</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 약관표시창 */}
        <div className="layer-pop term_pop filter_pop02 h80" style={varOpenTerms?{display:"block"}:{display:"none"}}>
          <div className="layer_inner">
            <div className="pop_head">
              <div className="layer_tit">{varTerms[varCurrentTerm+'_title']}</div>
              <div className="close_layer top_close_btn"><img src={colseIcon} alt="" onClick={e=>onOpenTermsHandler(e,false)}/></div>
            </div>
            <div className="center_wrap">
              <div className="con">
                <div className="con_box">
                  <div className="desc">{varTerms[varCurrentTerm]}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn_bottom one_btn">
            <button className="btn_style_yes close_layer" onClick={e=>onOpenTermsHandler(e,false)}>확인</button>
          </div>
        </div>
        {/* 우편번호/주소 검색*/}
        <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler}/>
      </div>
    </>
  );
}
export default JoinPartner;
