/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 

const DetailAdvertiseTerms = ({varRemainDays,varProductData,onSetShowTermsHandler}) => {

  return (
    <div className="form_wrap">
      <div className="tit07_1">광고 게재 기간 <span className="star_red"> (필수)</span></div>
      <ul className="dan5">
        {varRemainDays <=0?
          <li className="terms_chk34">
            <input type="radio" name="rp_terms" className="chk1" id="rp_terms0" value={0} 
              defaultChecked={varProductData.rp_terms===0} onChange={e=>onSetShowTermsHandler(e)}/>
              <i></i><label htmlFor="rp_terms0">없음</label>
          </li>
        :varRemainDays<15?
          <li className="terms_chk34">
            <input type="radio" name="rp_terms" className="chk1" id="rp_termsf" value={varRemainDays} 
              defaultChecked={varProductData.rp_terms===varRemainDays} onChange={e=>onSetShowTermsHandler(e)}/>
              <i></i><label htmlFor="rp_termsf">{varRemainDays}일</label>
          </li>
        :varRemainDays>=15?
          <li className="terms_chk34">
            <input type="radio" name="rp_terms" className="chk1" id="rp_terms1" value="15" 
              defaultChecked={varProductData.rp_terms===15} onChange={e=>onSetShowTermsHandler(e)}/>
              <i></i><label htmlFor="rp_terms1">15일</label>
          </li>
        :null}
        {varRemainDays<30?null:
          <li className="terms_chk34">
            <input type="radio" name="rp_terms" className="chk1" id="rp_terms2" value="30" 
              defaultChecked={varProductData.rp_terms===30} onChange={e=>onSetShowTermsHandler(e)}/>
              <i></i><label htmlFor="rp_terms2">30일</label>
          </li>
        }
        {varRemainDays<45?null:
          <li className="terms_chk34">
            <input type="radio" name="rp_terms" className="chk1" id="rp_terms3" value="45"
              defaultChecked={varProductData.rp_terms===45} onChange={e=>onSetShowTermsHandler(e)}/>
              <i></i><label htmlFor="rp_terms3">45일</label>
          </li>
        }
        {varRemainDays<60?null:
        <li className="terms_chk34">
          <input type="radio" name="rp_terms" className="chk1" id="rp_terms4" value="60" 
            defaultChecked={varProductData.rp_terms===60} onChange={e=>onSetShowTermsHandler(e)}/>
            <i></i><label htmlFor="rp_terms4">60일</label>
        </li>
        }
        {varRemainDays<90?null:
          <li className="terms_chk34">
            <input type="radio" name="rp_terms" className="chk1" id="rp_terms5" value="90"
              defaultChecked={varProductData.rp_terms===90} onChange={e=>onSetShowTermsHandler(e)}/>
              <i></i><label htmlFor="rp_terms5">90일</label>
          </li>
        }
      </ul>
    </div>
  );
}
export default DetailAdvertiseTerms