/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import closeIcon from '../../images/icon_close.png';

//둥지매물 신고를 위한 팝업창
const BlockChatting = ({varBlockStatus,onBlockChattingHandler,varPopupBlockChatting,onPopupBlockChattingHandler}) => {

  return (
    <div className="layer-pop w_del_popup desc_popup_st h_auto" style={varPopupBlockChatting?{display:"block"}:{display:"none"}}>
      <div className="layer_inner">
        <div className="close_bt_wrap">
          <div className="close_layer top_close_btn"><img src={closeIcon} alt="" onClick={e=>onPopupBlockChattingHandler(e,false)}/></div>
        </div>
        <div className="center_wrap">
          <div className="con">
            {!varBlockStatus?
              <div className="con_box">
                <strong>차단하시겠습니까?</strong>
                <div className="txt01">
                  차단하시면 상대방이 보내는 메시지를<br/>수신하실 수 없습니다. 차단하시겠습니까?
                </div>
              </div>
            :
              <div className="con_box">
                <strong>차단 해제 하시겠습니까?</strong>
                <div className="txt01">
                현재 상대방은 차단된 회원입니다.<br/>차단을 해제하고 대화하시겠습니까?
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="btn_bottom">
        <button type="button" className="btn_style_yes close_layer" onClick={e=>onPopupBlockChattingHandler(e,false)}>취소</button>
        <button type="button" className="btn_style_no" onClick={e=>onBlockChattingHandler(e)}>
          {!varBlockStatus?"차단하기":"차단해제"}
        </button>
      </div>
    </div>
  );
}
export default BlockChatting;