/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Routes,Route } from "react-router-dom";

import Header from "../../components/include/Header";

import FindID from './FindID';
import FindPWD from './FindPWD';
import FoundIDOK from './FoundIDOK';
import FoundPWDOK from './FoundPWDOK';
import EditPWD from './EditPWD';

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const Member = () => {
  return (
    <>
      <Header/>
      <div id="content">
        <Routes>
          <Route path="/FindID" element={<FindID />} />
          <Route path="/FindPWD" element={<FindPWD />} />
          <Route path="/FoundIDOK" element={<FoundIDOK />} />
          <Route path="/FoundPWDOK" element={<FoundPWDOK />} />
          <Route path="/EditPWD/:backMode" element={<EditPWD />} />
        </Routes>
      </div>
    </>
  );
}
export default Member;