/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react"; 

import closeButton from "../../images/icon_close.png";
import bulletButton from "../../images/bullet.png";

import { isObjEmpty, OnlyNumerics, SetFilterGroups } from '../common/Common';

const SelectFilters = ({
  varPopupSelectFilter,
  varSubwayLineList,
  varSubwayStationList,
  varFixedFilterList,
  setFixedFilterList,
  varFixedFilterOptionList,
  setFixedFilterOptionList,
  onPopupFilterHandler
}) => {
  // 지하철역 선택 시 저장할 목록
  const [varSelectedStationList, setSelectedStationList] = useState([]);

  // 임시 필터 리스트 및 옵션 리스트
  const [varTempFixedFilterList, setTempFixedFilterList] = useState([]);
  const [varTempFixedFilterOptionList, setTempFixedFilterOptionList] = useState([]);

  const minRef = useRef();
  const maxRef = useRef();

  // 팝업이 열릴 때 현재 필터 상태를 임시 상태로 복사
  useEffect(() => {
    if (varPopupSelectFilter) {
      setTempFixedFilterList(JSON.parse(JSON.stringify(varFixedFilterList)));
      setTempFixedFilterOptionList(JSON.parse(JSON.stringify(varFixedFilterOptionList)));
    }
  }, [varPopupSelectFilter, varFixedFilterList, varFixedFilterOptionList]);

  // 옵션 다중 선택 핸들러 (임시 상태 수정)
  const onSelectFilterOptionHandler = (e, vfo_idx) => {
    const bmode = e.currentTarget.checked;

    // 필터 옵션 임시 리스트 복사
    let varCopyList = [...varTempFixedFilterOptionList];
    const findIndex = varCopyList.findIndex(element => element.vfo_idx === vfo_idx);
    if (findIndex === -1) return;

    const vsm_idx = varCopyList[findIndex].vsm_idx;
    const optionLists = varCopyList.filter(element => element.vsm_idx === vsm_idx);

    // 매물종류의 경우 (vsm_idx === 1)
    if (vsm_idx === 1) {
      let ib = 0, ic = 0;
      for (let i = 0; i < optionLists.length; i++) {
        if (optionLists[i].vfo_idx <= 5) { // 주거시설
          if (optionLists[i].vfo_select === 1) ib++;
        } else {  // 비주거 시설
          if (optionLists[i].vfo_select === 1) ic++;
        }
      }
      if (ib > 0 || ic > 0) {
        if (ib > 0 && vfo_idx > 5) {
          // 비주거시설 선택 시 주거시설 선택 불가
          alert("주거시설과 비주거시설은 동시에 선택할 수 없습니다.");
          return;
        } else if (ic > 0 && vfo_idx <= 5){
          // 주거시설 선택 시 비주거시설 선택 불가
          alert("주거시설과 비주거시설은 동시에 선택할 수 없습니다.");
          return;
        }
      }
    }

    // 필터 옵션 업데이트
    varCopyList[findIndex].vfo_select = Number(bmode);
    setTempFixedFilterOptionList(varCopyList);

    // 매물유형에 따른 메뉴 항목 조정
    if (varCopyList[findIndex].vsm_idx === 1) {
      SetFilterGroups(varCopyList);
    }
  }

  // 필터별 설정값 변경 핸들러 (임시 상태 수정)
  const setFilterValueInList = (vsm_idx, name, value) => {
    let varCopyList = [...varTempFixedFilterList];
    const findIndex = varCopyList.findIndex(element => element.vsm_idx === vsm_idx);
    if (findIndex === -1) return;

    varCopyList[findIndex][name] = value;
    if (name === 'subway_line') {
      varCopyList[findIndex]['subway_idx'] = 0;
      setSelectedStationList([]); // 지하철 노선 변경 시 역 선택 초기화
    }
    setTempFixedFilterList(varCopyList);
  }

  // min&max - 전체 유무 핸들러 (임시 상태 수정)
  const onSelectMinMaxHandler = (e, vsm_idx) => { 
    const bmode = e.currentTarget.checked;
    setFilterValueInList(vsm_idx, 'vsm_all', Number(bmode)); 

    // '전체'가 체크되면 min과 max를 0으로 초기화
    if (bmode) {
      setFilterValueInList(vsm_idx, 'vsm_min', 0);
      setFilterValueInList(vsm_idx, 'vsm_max', 0);
    }
  }

  // min&max - 입력값 변경 핸들러 (임시 상태 수정)
  const onChangeMinMaxHandler = (e, vsm_idx) => {
    const { name, value } = e.currentTarget;
    let ivalue = Number(OnlyNumerics(value));
    setFilterValueInList(vsm_idx, name, ivalue);
    if(ivalue !== 0) setFilterValueInList(vsm_idx, 'vsm_all', 0);
  }

  // 지하철 노선 선택 핸들러 (임시 상태 수정)
  const onSelectSubwayLineHandler = (e, vsm_idx) => {
    const { name, value } = e.currentTarget;
    setFilterValueInList(vsm_idx, name, value);

    // 지하철역 변경
    if (value === "") {
      setSelectedStationList([]);
    } else {
      const selectList = varSubwayStationList.filter(element => element.subway_line === value);
      setSelectedStationList(selectList);
    }
  }

  // 지하철 역 선택 핸들러 (임시 상태 수정)
  const onSelectSubwayStationHandler = (e, vsm_idx) => {
    const { value } = e.currentTarget;
    setFilterValueInList(vsm_idx, 'subway_idx', value);
  }

  // 검색 시작 핸들러 (조건매물보기 버튼)
  const onSearchingFilterHandler = (e) => {
    e.preventDefault();
    // 최소, 최대 값 필터 유효성 검사
    for (let filter of varTempFixedFilterList) {
      if (filter.vsm_mode === 1 || filter.vsm_mode === 2) { // 최소, 최대 값 필터인 경우
        if (filter.vsm_min > filter.vsm_max) {
          alert("정확한 범위 설정 후 적용해주세요.");
          return;
        }
      }
    }

    // 실제 필터 리스트 및 옵션 리스트 업데이트
    setFixedFilterList([...varTempFixedFilterList]);
    setFixedFilterOptionList([...varTempFixedFilterOptionList]);

    // 팝업 닫기
    onPopupFilterHandler(e, -1);
  }

  // 팝업 닫기 핸들러 (닫기 버튼 클릭 시)
  const onClosePopupHandler = (e) => {
    e.preventDefault();
    // 임시 상태를 초기 상태로 되돌림 (변경 사항 취소)
    setTempFixedFilterList([...varFixedFilterList]);
    setTempFixedFilterOptionList([...varFixedFilterOptionList]);

    // 팝업 닫기
    onPopupFilterHandler(e, false);
  }

  // Min/Max 입력 포커스 핸들러
  const onFocusHandler = (e) => {
    const value = Number(e.currentTarget.value);
    if(value === 0) e.currentTarget.value = '';
  }

  // Min/Max 입력 블러 핸들러
  const onBlurHandler = (e) => {
    const value = e.currentTarget.value;
    if(value === '') e.currentTarget.value = 0;
  }

  return isObjEmpty(varTempFixedFilterList) ? null : ( 
    <div className="layer-pop full_pop2" style={varPopupSelectFilter ? { display: "block" } : { display: "none" }}>
      <div className="pop_head">
        <div className="layer_tit">상세필터 매물 찾기</div>
        <div className="close_layer top_close_btn">
          <img src={closeButton} alt="" onClick={onClosePopupHandler} />
        </div>
      </div>
      <div className="layer_inner">
        <div className="scroll-wrap2">
          {varTempFixedFilterList.map((filter, i) => (
            <div
              key={'filter_' + i}
              id={'filter_pop_' + filter.vsm_idx}
              className={
                (filter.vsm_idx === 10 || filter.vsm_idx === 11 || filter.vsm_idx === 12) && filter.vsm_show === 0
                  ? "con_group hidden"
                  : "con_group"
              }
            >
              {filter.vsm_mode === 0 || filter.vsm_mode === 4 || filter.vsm_mode === 5 || filter.vsm_mode === 6 ? // 선택 방식
                <>
                  <div className="pop_subtit">
                    <span className="bullet_wrap"><img src={bulletButton} alt=""/></span>{filter.vsm_name}
                  </div>
                  <ul className="dan4">
                    {varTempFixedFilterOptionList.map((option, j) => (
                      option.vsm_idx !== filter.vsm_idx ? null 
                      :
                      <li className="terms_chk33" key={'filter_' + i + '_' + j}> 
                        <input
                          type="checkbox"
                          name={"fta_" + option.vfo_idx}
                          className="chk1"
                          id={"fta_" + option.vfo_idx}
                          checked={option.vfo_select === 1}
                          onChange={(e) => onSelectFilterOptionHandler(e, option.vfo_idx)}
                        />
                        <i></i>
                        <label htmlFor={"fta_" + option.vfo_idx}>{option.vfo_name}</label>
                      </li>
                    ))}
                  </ul>
                </>
              : filter.vsm_mode === 1 || filter.vsm_mode === 2 ? // 최소, 최대 (만원/㎡)
                <>
                  <div className="pop_subtit">
                    <span className="bullet_wrap"><img src={bulletButton} alt=""/></span>{filter.vsm_name}
                  </div>
                  <div className="dan_input01">
                    <div className="terms_chkpop">
                      <input
                        type="checkbox"
                        name="vsm_all"
                        className="chk1"
                        id={"vsm_" + filter.vsm_idx}
                        onChange={(e) => onSelectMinMaxHandler(e, filter.vsm_idx)}
                        checked={filter.vsm_all === 1}
                      />
                      <i></i>
                      <label htmlFor={"vsm_" + filter.vsm_idx}>전체</label>
                    </div>
                    <input
                      type="text"
                      name="vsm_min"
                      className="mm_box"
                      ref={minRef}
                      value={filter.vsm_min}
                      onFocus={onFocusHandler}
                      onBlur={onBlurHandler}
                      onChange={(e) => onChangeMinMaxHandler(e, filter.vsm_idx)}
                    />
                    <span className="mini_bar">&nbsp;~</span>
                    <input
                      type="text"
                      name="vsm_max"
                      className="mm_box"
                      ref={maxRef}
                      value={filter.vsm_max}
                      onFocus={onFocusHandler}
                      onBlur={onBlurHandler}
                      onChange={(e) => onChangeMinMaxHandler(e, filter.vsm_idx)}
                    />
                    &nbsp;&nbsp;<span className="danwe">{filter.vsm_mode === 1 ? "만원" : "㎡"}</span>
                  </div>
                </>
              : /* filter.vsm_mode === 3  지하철 */
                <>
                  <div className="pop_subtit">
                    <span className="bullet_wrap"><img src={bulletButton} alt=""/></span>지하철역
                  </div>
                  <div className="dan2" style={{ marginBottom: "20px" }}>
                    <select
                      name="subway_line"
                      value={filter.subway_line}
                      onChange={(e) => onSelectSubwayLineHandler(e, filter.vsm_idx)}
                    >
                      <option value="">노선선택</option>
                      {varSubwayLineList.map((subway1, i) => (
                        <option key={'subwayline_' + i} value={subway1.subway_line}>{subway1.subway_line}</option>
                      ))}
                    </select>
                    <select
                      name="subway_idx"
                      value={filter.subway_idx}
                      onChange={(e) => onSelectSubwayStationHandler(e, filter.vsm_idx)}
                    >
                      <option value={0}>역선택</option>
                      {varSelectedStationList.map((subway2, i) => (
                        <option key={'subwaystation_' + i} value={subway2.subway_idx}>{subway2.subway_name}</option>
                      ))}
                    </select>
                  </div>
                </>    
              }
            </div>
          ))}
        </div>
      </div>
      <div className="btn_bottom">
        <button className="btn_style_no" onClick={onClosePopupHandler}>닫기</button>
        <button className="btn_style_yes" onClick={onSearchingFilterHandler}>조건매물보기</button>
      </div>
    </div>
  );
}

export default SelectFilters;
