import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import ServiceSearch from "./ServiceSearch";
import ServiceResult from "./ServiceResult";
import Paging from "../../layout/Paging";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import '../../admin_css/board.css';

/* 내둥지 내놓기 */
function DoongjiManage({setMenuItem}) {
  const admin_region = localStorage.getItem("adminRegion");
  //목록저장변수
  const [varServiceLists,setServiceLists] = useState([]);
  //매물종류
  const [varDoongjiSerious,setDoongjiSerious] = useState([]);

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  const [varMaxNumPage, setMaxNumPage] = useState(0);

  //검색
  //const [varServiceIndex, setServiceIndex] = useState(0);
  const [varSearchData, setSearchData] = useState({
    sdate:null,
    edate:null,
    filterOption:0,
    searchOption:"all",
    searchString:''
  });

  //내둥지 내놓기 목록 읽어오기
  const getServiceList = async () => {
    const sendData = {pageMode:3,admin_region:admin_region, pageNum:varPageNum, pageSize:varPageSize, searchData:varSearchData};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_service_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //검색된 전체수량
        setTotalCount(datas.totalcount);
        //검색된 목록
        setServiceLists(datas.results);
        //매물 종류
        setDoongjiSerious(datas.filters); 
        //해당페이지 최대번호
        setMaxNumPage(datas.totalcount-(varPageNum-1)*varPageSize);
      } else {
        setTotalCount(0);
        setServiceLists([]);
        setDoongjiSerious([]);
        setMaxNumPage(0);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('DoongjiManage');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageNum(1); // 검색 조건이 변경될 때 페이지 번호를 1로 초기화
  }, [varSearchData]);

  //페이지 정보 표시 - 페이지가 변경되면
  useEffect(() => {
    getServiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum,varSearchData]);
  
  return (
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">건물 월관리 의뢰</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>둥지/장터관리<img src={arrImage} alt=""/>기타 서비스 관리<img src={arrImage} alt=""/>건물 월관리 의뢰
        </div>
      </div>
      <div className="content">
        <ServiceSearch pageMode={3} varDoongjiSerious={varDoongjiSerious}
            varTotalCount={varTotalCount} varSearchData={varSearchData} setSearchData={setSearchData} />
        <ServiceResult pageMode={3} varMaxNumPage={varMaxNumPage} varRegionList={[]}
            varServiceLists={varServiceLists} setServiceLists={setServiceLists} getServiceList={getServiceList}/>
        <div className="paging" style={{ marginBottom: "20px" }}>
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </div>
      </div>
    </div>
  )
}

export default DoongjiManage;
