/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ useEffect } from 'react';
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import {ChangeDateStringOnlyDate,addThousandPoint} from "../../../components/common/Common";

//요금제의 목록 표시
function RatePlanList({varRatePlanMode,varTotalList,setShowDetailPage,setCurrentRatePlan,varRatePlanLists, getRatePlanList}) {
  //페이지 정보 표시 - 최초
  useEffect(() => {
    //setMenuItem('DoongjiRatePlan');
    getRatePlanList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varRatePlanMode]);

  //요금제 정보 편집하기
  const onEditRatePlanHandler = (e,vo_idx) => {
    setCurrentRatePlan(Number(vo_idx));
    setShowDetailPage(true);
  }

  //요금제 추가하기
  const onAddRatePlanHandler = (e) => {
    setCurrentRatePlan(0);
    setShowDetailPage(true);
  }

  //요금제 삭제하기
  const onDeleteRatePlanHandler = async(e,vo_idx) => {
    if(!window.confirm('선택한 요금제를 삭제합니까?')) return false;
    const sendData = {rateplanMode: varRatePlanMode,vo_idx:vo_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_voucher_list`,sendData).then(res => {
      alert(res.data.message);
      if(res.data.retvalue === 1) {
        getRatePlanList();
      }
    })
  }
  
  return (
    <div className="content">
      <div className="main-box2">
        <div className="btn-right">
          <button className="btn-danger" onClick={(e)=>onAddRatePlanHandler(e)}>추가등록</button>
        </div>
      </div>
      <div className="main-box">
        <table className="list">
          <colgroup>
            <col width="8%"></col>
            <col width="30%"></col>
            <col width="15%"></col>
            <col width="15%"></col>
            <col width="12%"></col>
            <col width="20%"></col>
          </colgroup>
          <thead>
            <tr>
              <th>번호</th><th>요금제명</th><th>금액</th><th>판매가격</th><th>등록일자</th><th>관리</th>
            </tr>
          </thead>
          <tbody>
            {varTotalList === 0 ? 
              <tr><td colSpan="6" style={{textAlign:"center"}}>등록된 요금제가 없습니다.</td></tr>
            : varRatePlanLists.map((voucher, i) => (
              <tr key={'voucher_'+i}>
                <td className=""><p>{i+1}</p></td>
                <td><p>{voucher.vo_name}</p></td>
                <td><p>{addThousandPoint(voucher.vo_price)}&nbsp;원</p></td>
                <td><p>{addThousandPoint(voucher.vo_amount)}&nbsp;원</p></td>
                <td><p>{ChangeDateStringOnlyDate(voucher.vo_rdate)}</p></td>
                <td>
                  <button className="btn line" onClick={(e)=>onEditRatePlanHandler(e,voucher.vo_idx)}>수정</button>
                  <button className="btn line black" onClick={(e)=>onDeleteRatePlanHandler(e,voucher.vo_idx)}>삭제</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RatePlanList
