/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import { useParams,useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";
import {CopyToClipboard} from "react-copy-to-clipboard/src";

import { isNull,isObjEmpty,CheckLoginStateOnly,ChangeDateStringOnlyDate } from "../../components/common/Common";

import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';
import KakaoMap from '../../components/include/KakaoMap';
import PartnerSlider from '../../components/jangter/PartnerSlider';
import PartnerDealingInfo from '../../components/jangter/PartnerDealingInfo';
import ReportProduct from '../../components/popup/ReportProduct';
import MapLocation from '../../components/popup/MapLocation';

import heartOffIcon from "../../images/icon_heart_w.svg";
import heartOnIcon from "../../images/icon_heart_y.svg";
import alertIcon from "../../images/icon_singo.png";
import shareIcon from "../../images/icon_share.png";

import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

//파트너스 항목 표시
const PartnerView = () => {
  const { jp_idx } = useParams();
  const navigator = useNavigate();
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const memberLoginState = Number(localStorage.getItem("memberLoginState"));

  const [varPartnerData,setPartnerData] = useState({});
  const [varPartnerPhoto,setPartnerPhoto] = useState([]);
  const [varIsExistPartner,setIsExistPartner] = useState(true);

  //파트너스 정보 가져오기
  const getPartnerInformation = async() => {
    const sendData = {jp_idx:jp_idx,mem_idx:mem_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_partner_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //검색된 목록
        setPartnerData(datas.results[0]);
        setPartnerPhoto(datas.photos);
        if(isNull(datas.results[0])) {
          setIsExistPartner(false);
        }
      }
      else{
        setIsExistPartner(false);
      }
    });
  }
  //페이지 정보 표시 - 최초
  useEffect(() => {
    getPartnerInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //공유하기
  const onSharePartnerHandler = async(e) => {
    if(memberLoginState === 0) {
      navigator('/Login');
    }
    alert('클립보드로 복사되었습니다.');
  }

  //신고하기
  const [varPopupReportProduct,setPopupReportProduct] = useState(false);
  const onPopupReportProductHandler = (e,bmode) => {
    if(memberLoginState === 0) { navigator('/Login'); }
    e.preventDefault();
    onPopupWinHandler(bmode);
    setPopupReportProduct(bmode);
  }
  const onPopupWinHandler = (bmode) => {
    console.log(bmode);
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }

  //찜목록 등록/해제
  const onChangeJjimListHandler = async (e,jp_idx) => {
    if(memberLoginState === 0) { navigator('/Login'); }
    e.preventDefault();
    let jj_idx = varPartnerData.jj_idx;
    let jj_status = varPartnerData.jj_status;
    if(jj_idx === null) {
      jj_idx = 0;
      jj_status = 0;
    } 
    if(jj_status === 1) jj_status = 0;
    else jj_status = 1;
  
    const sendData = {pageMode:3,opp_idx:jp_idx,jj_idx:jj_idx,jj_mem_idx:mem_idx,jj_status:jj_status};
    await axios.post(`${CONFIG.SERVER_HOST}/_update_jjim_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(res.data);
      if(datas.retvalue === 1) {
        //검색된 목록
        setPartnerData({...varPartnerData,jj_idx:datas.jj_idx,jj_status:jj_status});
      }
    });   
  }
  ////채팅방열기
  const onMakeChatRoomHandler = async (e) => {
    if(!CheckLoginStateOnly(mem_idx)) {
      navigator('/Login');
    } else {
      //채팅방 정보 읽거나 만들기
      const sendData = {ch_mode:3,no_idx:jp_idx,mem_idx:mem_idx};
      await axios.post(`${CONFIG.SERVER_HOST}/_get_chatroom`,sendData).then((res) => {
        const datas = res.data;
        console.log(datas);
        if(datas.retvalue === 1 || datas.retvalue === 2) {  //채팅방을 찾음(1)/새로만듦(2)
          navigator(`/Chat/ChatRoom/3/${datas.ch_idx}`); 
        } else {
          alert(datas.message);
        }
      });
    }
  }

  //전체 지도보기
  const [varMapLocation,setMapLocation] = useState(false);
  const onOpenMapLocationHandler = (e,bmode) => {
    setMapLocation(bmode);
  }

  return isObjEmpty(varPartnerData)?
  ( varIsExistPartner===true?null:
    <div style={{ backgroundColor: "black", color: "white", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <HeadTitle/>
      비공개이거나 만료된 파트너스입니다.
    </div>
  ):(
    <> 
      <Header/>
      <div className="layer-bg" style={{backgroundColor:"rgba(0,0,0,1)"}}></div>
      <HeadTitle titleString={varPartnerData.mcm_name}/>
      <div id="content" style={{paddingBottom:"40px"}}>
        <div className="pd_wrap02">
          <div className="cpy_view_wrap">
            <PartnerSlider varPartnerPhoto={varPartnerPhoto}/>
          </div>
          <div className="top_info_wrap b_shadow">
            <div className="center_wrap" style={{margin:"5px 0px 0px 0px",backgroundColor:"#ffffff"}}>
              <div className="c_wrap02">
                <div className="tit03">
                  <span className="btn_st_A">{varPartnerData.mcm_dongname}</span>
                  <span className="btn_title">{varPartnerData.mcm_name}</span>
                  <div className="right_wrap">
                    <div className="heart wish_chk" onClick={e=>onChangeJjimListHandler(e,varPartnerData.jp_idx)}>
                      <img src={varPartnerData.jj_idx===null||varPartnerData.jj_status===0?heartOffIcon:heartOnIcon} alt="하트"/>
                    </div>
                    <div className="singo"><img src={alertIcon} alt="신고" onClick={e=>onPopupReportProductHandler(e,true)}/></div>
                    <div className="share">
                      <CopyToClipboard text={`${CONFIG.PAYMENT_HOST}/Jangter/PartnerView/${varPartnerData.jp_idx}`} onCopy={e=>onSharePartnerHandler(e)}>
                        <img src={shareIcon} alt="공유"/>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
                <div className="date_wrap">
                  <div className="date">등록일 <span>{ChangeDateStringOnlyDate(varPartnerData.jp_rdate)}</span></div> 
                  <span className="gray_bar">&nbsp;</span>
                  <div className="hit">조회수 <span>{varPartnerData.jp_hits}</span>&nbsp;회</div>
                </div> 
                <div className="desc" style={{padding:"10px 0px",whiteSpace:'pre-line'}}>{varPartnerData.mcm_introduce}</div>
              </div>
            </div>
          </div>
          <div className="mid_info_wrap b_shadow">
            <div className="center_wrap" style={{backgroundColor:"#fff",marginTop:"5px",paddingTop:"5px"}}>
              <div className="info01">
                <div className="tit07_1">위치</div>
                <div className="desc" style={{marginBottom:"5px"}}>{varPartnerData.mcm_address1+" "+varPartnerData.mcm_address2}</div>
                {/* 지도표시 */}
                <KakaoMap varCenterPoint={varPartnerData.mcm_location} varAddress={varPartnerData.mcm_address1} onOpenMapLocationHandler={onOpenMapLocationHandler}/>
                <div className="desc">* 지도를 클릭하시면 확대됩니다.</div>
              </div>
            </div>
          </div>
          <PartnerDealingInfo varPartnerData={varPartnerData}/>
          {varPartnerData.mem_idx===mem_idx?null:
            <div className="btn_bottom one_btn" style={{zIndex:"10"}}>
              <button className="btn_style_yes" onClick={e=>onMakeChatRoomHandler(e)}>채팅하기</button>
            </div>
          }
        </div>
      </div>
      <ReportProduct rpIdx={jp_idx} rpMode={3} varPopupReportProduct={varPopupReportProduct} onPopupReportProductHandler={onPopupReportProductHandler}/>
      <MapLocation varCenterPoint={varPartnerData.mcm_location} varAddress={varPartnerData.mcm_address1} 
                    varMapLocation={varMapLocation} onOpenMapLocationHandler={onOpenMapLocationHandler}/>
    </>
  );
}
export default PartnerView;