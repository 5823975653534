/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import BoardNotice from "../../components/boards/BoardNotice";
import BoardFAQ from "../../components/boards/BoardFAQ";
import BoardOneToOne from "../../components/boards/BoardOneToOne";
import BoardDetail from "../../components/boards/BoardDetail";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import "../../admin_css/board.css";

const Boards = () => {
  //메뉴항목지정
  const [varMenuItem,setMenuItem] = useState('');

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'BOARDS'}/>
      <Menus varMenuType={'BOARDS'} varMenuItem={varMenuItem}/>
      <Routes>
        {/* 게시판관리 */}
        <Route path="BoardNotice" element={<BoardNotice setMenuItem={setMenuItem}/>} />
        <Route path="BoardFAQ" element={<BoardFAQ setMenuItem={setMenuItem}/>} />
        <Route path="BoardOneToOne" element={<BoardOneToOne setMenuItem={setMenuItem} />} />
        <Route path="BoardDetail/:mode/:board_idx" element={<BoardDetail varMenuItem={varMenuItem} setMenuItem={setMenuItem} />} />
      </Routes>
    </>
  );
}
export default Boards;