import React, { useState, useEffect } from "react";
import callingImage from "../../images/icon_call30.png";
import CONFIG from '../../configs/Configs';
import { ChangeMobileFormat, GetPhotoImage } from "../common/Common";

// 매물 중개 정보 표시
const DoongjiDealingInfo = ({ varProductData }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);

  // Load image with retry
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // Cache-busting timestamp
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  useEffect(() => {
    let photoImage = "";
    if (Number(varProductData.mem_mode) === 0) {
      photoImage = varProductData.mem_file;
    } else {
      photoImage = varProductData.mcm_file;
    }

    if (photoImage === null || photoImage === "") {
      photoImage = 'default_member.png';
    }

    const imageUrl = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(photoImage, 'member') + `?t=${new Date().getTime()}`;
    setImageLoading(true);
    loadImageWithRetry(imageUrl)
      .then((loadedImage) => {
        setImageSrc(loadedImage);
        setImageLoading(false);
      })
      .catch((error) => {
        console.error(error.message);
        setImageLoading(false);
      });
  }, [varProductData.mem_mode, varProductData.mem_file, varProductData.mcm_file]);

  return (
    <div className="info04" style={{ marginBottom: "50px" }}>
      <div className="tit07_1">중개 정보</div>
      <div className="info04_inner">
        <div className="img_wrap" style={{ maxWidth: "30%", position: 'relative' }}>
          {imageLoading ? (
            <div className="loading-indicator" style={{width:"107px", height:"100px", position: 'relative'}}>
              <div className="loading-spinner"></div>
            </div>
          ) : (
            <img src={imageSrc} alt="" style={{ objectFit: "cover", width: "100%", height: "100%" }} />
          )}
        </div>
        <div className="box">
          <div className="c_name">{varProductData.mem_mode === 1 ? varProductData.mcm_name : varProductData.mem_nickname}</div>
          {Number(varProductData.mem_mode) === 1 || Number(varProductData.mem_mode) === 2 ? ( // 부동산
            <span className="person_info">
              대표&nbsp;&nbsp;<span className="name_txt">{varProductData.mem_name}</span>&nbsp;&nbsp;<br />
              {varProductData.rp_hidden_phone === 1 ? "전화번호 공개 여부" : ChangeMobileFormat(varProductData.mcm_phone)}
              <br />
              {varProductData.mcm_broker_license}
            </span>
          ) : (
            <span className="person_info">
              {varProductData.rp_register_type === 0 ? "임차인" : "임대인"}
              <br />
              {varProductData.rp_hidden_phone === 1 ? null : ChangeMobileFormat(varProductData.mem_mobile)}
            </span>
          )}
          {/* 파트너가 볼 경우에만 노출 */}
          {Number(varProductData.mem_mode) === 1 || Number(varProductData.mem_mode) === 2 ? (
            <>
              <div className="adrs">
                {varProductData.mcm_zipcode === null ? null : varProductData.mcm_address1 + ' ' + varProductData.mcm_address2}
              </div>
              <div className="fnc_wrap">
                {varProductData.rp_hidden_phone === 1 ? null : (
                  <a href={`tel:${varProductData.mcm_phone}`}>
                    <img src={callingImage} style={{ width: "50px" }} alt="" />
                  </a>
                )}
              </div>
            </>
          ) : (
            <div className="fnc_wrap">
              {varProductData.rp_hidden_phone === 1 ? null : (
                <a href={`tel:${varProductData.mem_mobile}`}>
                  <img src={callingImage} style={{ width: "50px" }} alt="" />
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DoongjiDealingInfo;
