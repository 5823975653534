import React from "react";
import { Link } from "react-router-dom";
/* import axios from 'axios';
import CONFIG from '../../../configs/Configs'; */

import {ChangeDateStringOnlyDate,ResetCheckBoxes} from "../../../components/common/Common";

/* 신고물 목록 표시 페이지 */
function ReportSearchResult({varMaxNumPage,varReportLists,setReportLists,onDeleteReportHandler}) {

  //목록의 모든 항목 선택하기
  const onCheckedAll = (e) => {
    const bChecked = Number(e.currentTarget.checked);
    clearBoardLists(bChecked);
    //배열값 변경
    let copyDatas = [...varReportLists];
    for (var i=0; i < copyDatas.length; i++) {
      copyDatas[i].checked = bChecked;
    }
    setReportLists(copyDatas);
  } 
  //목록상의 항목 선택하기
  const onCheckedElement = (e,pr_idx) => {
    const bChecked = Number(e.currentTarget.checked);
    const findIndex = varReportLists.findIndex(element=>element.pr_idx===pr_idx);
    let copyLists = [...varReportLists];
    copyLists[findIndex].checked = bChecked;
    setReportLists(copyLists);
    ResetCheckBoxes();
  } 
  //체크박스 초기화
  const clearBoardLists = (bmode) => {
    const checkBoxes = document.querySelectorAll('.del-item');
    for(var i=0; i < checkBoxes?.length; i++) {
      checkBoxes[i].checked = bmode;
    }
  }

  return (
    <div className="main-box">
      <div className="btn-right">
        <button onClick={(e)=>onDeleteReportHandler(e)} className="btn line">삭제</button>
      </div>
      <table className="list">
        <colgroup>
          <col style={{width:"5%"}} />
          <col style={{width:"8%"}} />
          <col style={{width:"12%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"25%"}} />
          <col style={{width:"12%"}} />
          <col style={{width:"10%"}} />
          <col style={{width:"8%"}} />
          <col style={{width:"10%"}} />
        </colgroup>
        <thead>
          <tr>
            <th className="board-list">
              <input type="checkbox" id="deleteItems" onChange={(e)=>onCheckedAll(e)} />
            </th>
            <th>번호</th>
            <th>신고자</th>
            <th>신고분야</th>
            <th>신고대상</th>
            <th>신고항목</th>
            <th>신고일자</th>
            <th>진행상태</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {varReportLists.map((product, i) => (
            <tr key={'product_'+product.pr_idx} className={product.pr_status===3?"warning":""}>
              <td className="board-list">
                <input type="checkbox" className={"del-item"} id={"deleteItem_" + product.pr_idx} onChange={(e)=>onCheckedElement(e,product.pr_idx)}/>
              </td>
              <td style={product.pr_news===0?{color:"red"}:{color:"#666"}}>{varMaxNumPage - i}</td>
              <td className="ellipsis">{product.mem_nickname}</td>
              <td className="ellipsis">{product.pr_mode===1?"둥지":product.pr_mode===2?"장터":"파트너"}</td>
              <td className="ellipsis">{product.pr_mode===1?product.rp_title:product.pr_mode===2?product.jt_title:product.jp_names}</td>
              <td className="ellipsis limit-box">{product.pr_reason===0?"허위매물":"기타"}</td>
              <td className="ellipsis">{ChangeDateStringOnlyDate(product.pr_rdate)}</td>
              <td className="ellipsis">{product.pr_status===1?"신고중":product.pr_status===2?"신고확인":product.pr_status===3?"블라인드":"신고삭제"}</td>
              <td>
                <Link to={`/Admin/ProductList/ReportDetail/${product.pr_idx}`} style={{width:"90px"}} className="btn line">세부정보</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ReportSearchResult;