import React from "react"; 
import { Link } from 'react-router-dom';
import Header from "../../components/include/Header";
import HeadTitle from '../../components/include/HeadTitle';

import okIcon from "../../images/big_icon03.png";

import "../../css/layout.css";
import "../../css/sub.css";

const RequestDoongjiPremium = () => {
  return (
    <>
      <Header/>
      <HeadTitle titleString={"프리미엄 광고 신청 접수 완료"}/>
      <div id="content">
        <div className="pd_wrap">
          <div className="ok_form_wrap join_ok_wrap">
            <div className="center_wrap">
              <img src={okIcon} alt=""/>
              <div className="top_txt">
                <strong>프리미엄 광고 신청이 완료되었습니다.</strong>
                <p>신청하신 내용이 관리자에게 전달될 예정입니다.<br/>
                    관리자 확인 후 광고 집행비를 확정해드립니다.<br/>
                    집행비 결제 이후 광고가 등록됩니다.
                </p>
              </div>
              <div className="btn_bottom one_btn">
                <Link className="btn_style_yes" to={"/MyPage/PremiumList"}>파트너스 결제 관리 이동</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RequestDoongjiPremium;