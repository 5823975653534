/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";

import DoongjiRegister from "../../components/doongji/DoongjiRegister";
import DoongjiSearch from "../../components/doongji/DoongjiSearch";
import DoongjiManage from "../../components/doongji/DoongjiManage";
import DoongjiPartner from "../../components/doongji/DoongjiPartner";

import ServiceDetail from "../../components/doongji/ServiceDetail";
import FindingDetail from "../../components/doongji/FindingDetail";
import PartnerDetail from "../../components/doongji/PartnerDetail";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import "../../admin_css/board.css";

/* 기타 서비스 관리 */
const ProductService = () => {
  //메뉴항목지정
  const [varMenuItem,setMenuItem] = useState('');

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'PRODUCTS'}/>
      <Menus varMenuType={'PRODUCTS'} varMenuItem={varMenuItem}/>
      <Routes>
        {/* 내 둥지 내놓기 */} 
        <Route path="DoongjiRegister" element={<DoongjiRegister setMenuItem={setMenuItem}/>} />
        {/* 내둥지 찾아주세요 */}
        <Route path="DoongjiSearch" element={<DoongjiSearch setMenuItem={setMenuItem}/>} />
        {/* 건물 월관리 의뢰 */}
        <Route path="DoongjiManage" element={<DoongjiManage setMenuItem={setMenuItem} />} />
        {/* 공인중개사 파트너 모집 */}
        <Route path="DoongjiPartner" element={<DoongjiPartner setMenuItem={setMenuItem} />} />

        {/* 내 둥지 내놓기/건물 월관리 의뢰 세부 정보 관리 */}
        <Route path="ServiceDetail/:pageMode/:indexNum" element={<ServiceDetail setMenuItem={setMenuItem} />} />
        {/*  내둥지 찾아주세요 의뢰 세부 정보 관리 */}
        <Route path="FindingDetail/:pageMode/:indexNum" element={<FindingDetail setMenuItem={setMenuItem} />} />
        {/* 공인중개사 파트너 모집 세부 정보 관리 */}
        <Route path="PartnerDetail/:pageMode/:indexNum" element={<PartnerDetail setMenuItem={setMenuItem} />} />
      </Routes>
    </>
  );
}
export default ProductService;

