/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"; 
import mapIcon from "../../images/icon_map_y.svg";
import closeIcon from "../../images/icon_close.svg";
import { isNull } from "../../components/common/Common";
const SearchDoongji = ({varSearchingString,setSearchingString,onPopupSearchKeywordHandler,varSearchRegionList,setSearchRegionList,onPopupRegionHandler,setPageNum}) => {

  //검색지역삭제
  const onDeleteSearchRegionHandler = (e,regcode) => {
    const newRegionList = varSearchRegionList.filter(element=>element.regcode !== regcode);
    console.log(newRegionList);
    setSearchRegionList(newRegionList);
  }
  //검색어초기화
  const onClearSearchHandler = (e) => {
    setSearchingString("");
  }
  
  return (
    <div className="search_top_wrap">
      <div className="center_wrap">
        <div className="search_top">
          <div className="btn_map_setting filter01_open_btn" onClick={e=>onPopupRegionHandler(e,true)}>
            <img src={mapIcon} alt=""/>지역선택
          </div>
          {/* 키워드검색창 호출 */}
          <div className="searchbox">
            <form method="post" name="searchfrm2">
              <fieldset className="search_open_btn">
                <input type="text" className="input" name="searchString" value={varSearchingString} placeholder="키워드를 검색해보세요" onClick={e=>onPopupSearchKeywordHandler(e,true)} readOnly/>
                {isNull(varSearchingString)?
                  <input type="button" className="btn" value="검색" onClick={e=>onPopupSearchKeywordHandler(e,true)}/>
                  :<input type="button" className="btn3" value="초기화" onClick={e=>onClearSearchHandler(e)}/>
                }
              </fieldset>
            </form>
          </div>
        </div>
        {/* 검색으로 선택된 지역 목록 */}
        <div className="sel_local">
          <p>선택된 동네</p>
          <ul>
            {varSearchRegionList?.length===0?
              <li style={{cursor:"pointer"}}>전체</li>
            :varSearchRegionList.map((region,i)=>(
              <li key={'search_region_'+i} onClick={e=>onDeleteSearchRegionHandler(e,region.regcode)} style={{cursor:"pointer"}}>
                {region.regname}<img src={closeIcon} alt="" className="close_btn"/>
              </li>
            ))}        
          </ul>
        </div>
      </div>
    </div>
  );
}
export default SearchDoongji;