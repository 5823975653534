import React, { useEffect,useState } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import SubAdminDetail from './SubAdminDetail';

function SubAdminInformation({setMenuItem}) {
  const admin_idx = localStorage.getItem("adminIdx");

  //부관리자 목록 정보 저장 변수 
  const [varSubAdminLists, setSubAdminLists] = useState([]);
  //현재 선택된 부관리자 고유번호 저장 변수
  const [varCurrentSubAdmin,setCurrentSubAdmin] = useState(admin_idx);
  //부관리자 정보 변경 페이지 표시 유무
  const [varShowDetailPage] = useState(true);

  //부관리자 목록 가져오기
  const getSubAdminList = () => {
    axios.post(`${CONFIG.SERVER_HOST}/_get_subadmin_list`).then(res => {
      if(res.data.retvalue === 1) {
        if(res.data.results?.length > 0) {
          //부운영자 목록
          setSubAdminLists(res.data.results);
        } else {
          setSubAdminLists([]);
        }
      } else {
        setSubAdminLists([]);
      }
    })
  };
  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('SubAdminInformation');
    getSubAdminList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="contents" className="ad_contents">
      <div className="tit">부운영자 관리
        <div className="path"><img src={iconhome} alt="" />
        HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt="" />운영 계정 관리<img src={arrImage} alt="" />부운영자 관리
        </div>
      </div>
      <SubAdminDetail varShowDetailPage={varShowDetailPage} varCurrentSubAdmin={varCurrentSubAdmin} setCurrentSubAdmin={setCurrentSubAdmin}
            varSubAdminLists={varSubAdminLists} setSubAdminLists={setSubAdminLists} />
    </div>
  )
}

export default SubAdminInformation;