import React from "react"; 
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import {CopyToClipboard} from "react-copy-to-clipboard/src";

import { ChangeDateStringOnlyDate,ShowDoongjiTitle2 } from "../common/Common";

import heartOffIcon from "../../images/icon_heart_w.png";
import heartOnIcon from "../../images/icon_heart_y.png";
import alertIcon from "../../images/icon_singo.png";
import shareIcon from "../../images/icon_share.png";

const DoongjiHeader = ({varProductData,setProductData,onPopupReportProductHandler}) => {
  const mem_idx = localStorage.getItem("mem_idx");
  const memberLoginState = Number(localStorage.getItem("memberLoginState"));
  const navigator = useNavigate();

  //찜목록 등록/해제
  const onChangeJjimListHandler = async (e) => {
    if(memberLoginState === 0) {
      navigator('/Login');
      return false;
    }
    const rp_idx = varProductData.rp_idx;
    let jj_idx = varProductData.jj_idx;
    let jj_status = varProductData.jj_status;
    if(jj_idx === null) {
      jj_idx = 0;
      jj_status = 0;
    } 
    if(jj_status === 1) jj_status = 0;
    else jj_status = 1;
  
    const sendData = {pageMode:1,opp_idx:rp_idx,jj_idx:jj_idx,jj_mem_idx:mem_idx,jj_status:jj_status};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_jjim_list`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        //검색된 목록
        console.log(datas);
        setProductData({...varProductData,jj_idx:datas.jj_idx,jj_status:jj_status});
      }
    });   
  }

  //공유하기
  const onShareProductHandler = async(e) => {
    if(memberLoginState === 0) {
      navigator('/Login');
    }
    alert('클립보드로 복사되었습니다.');
  }

  return (
    <div className="top_info_wrap b_shadow">
      <div className="center_wrap">
        <div className="c_wrap02">
          <div className="c_dong"><span>{varProductData.rp_dongname||""}</span></div>
          <div className="b_num">매물번호&nbsp; 
            <span>{varProductData.rp_code||""}</span>
            <div className="right_wrap" style={{marginRight:"10px"}}>
              <div className="wish_chk" style={{marginRight:"10px"}}><img src={varProductData.jj_idx===null||varProductData.jj_status===0?heartOffIcon:heartOnIcon} alt="하트" onClick={e=>onChangeJjimListHandler(e)}/></div>
              <div className="go_singo" style={{marginRight:"10px"}}><img src={alertIcon} alt="신고" onClick={e=>onPopupReportProductHandler(e,true)}/></div>
              <div className="share">
                <CopyToClipboard text={`${CONFIG.PAYMENT_HOST}/Doongji/DoongjiView/${varProductData.rp_idx}`}  onCopy={e=>onShareProductHandler(e)}>
                <img src={shareIcon} alt="공유"/>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="c_rent_type">
            <span>{varProductData.types||""}</span>
            <span className="price">{ShowDoongjiTitle2(varProductData,'/')}</span>
          </div>
          <div className="date_wrap">
            <div className="date">등록일 <span>{ChangeDateStringOnlyDate(varProductData.rp_rdate||"")}</span></div>
            <div className="hit ">조회수 <span>{varProductData.rp_hits||"0"}</span>회</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DoongjiHeader;