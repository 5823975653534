import React from "react"; 

import detail01Image from "../../images/detail_icon01.png";
import detail02Image from "../../images/detail_icon02.png";
import detail03Image from "../../images/detail_icon03.png";
import detail04Image from "../../images/detail_icon04.png";
import { addThousandPoint } from "../common/Common";

const DoongjiTitle = ({varProductData}) => {

  return (
      <div className="info01">
        <ul className="dan4">
          <li><img src={detail01Image} alt=""/><p><span>{varProductData.serious}</span></p></li>
          <li><img src={detail02Image} alt=""/>
            <p><span>
              {varProductData.rp_serious===9?addThousandPoint(varProductData.rp_sale_area)
              :`${addThousandPoint(varProductData.rp_sale_area)}/${addThousandPoint(varProductData.rp_private_area)}`
              }
            </span>㎡</p>
          </li>
          <li><img src={detail03Image} alt=""/>
            <p><span>
              {varProductData.rp_exist_floor_type===1? `건물전체/${varProductData.rp_total_floor}`
              :varProductData.rp_exist_floor_type===2? `지하/${varProductData.rp_total_floor}`
              :varProductData.rp_exist_floor_type===3? `반지하/${varProductData.rp_total_floor}`
              :varProductData.rp_exist_floor_type===4? `옥탑/${varProductData.rp_total_floor}`
              :`${varProductData.rp_exist_floor}/${varProductData.rp_total_floor}`}
            </span>층</p>
          </li>
          <li><img src={detail04Image} alt=""/><p><span>{varProductData.rp_admin_expense}</span>만원</p></li>
        </ul>
        <div className="center_wrap">
          <div className="tit07_1">매물 정보</div>
          <div className="txt" style={{whiteSpace:'pre-line'}}>{varProductData.rp_information}</div>
        </div>
      </div>
  );
}
export default DoongjiTitle;