/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import TitleBar from "../../layout/TitleBar";
import TabBar from "../../layout/TabBar";
import Menus from "../../layout/Menus";
import MemberLists from "../../components/members/MemberLists";
import MemberDetail from "../../components/members/MemberDetail";
import MemberApproval from "../../components/members/MemberApproval";
import ApprovalDetail from "../../components/members/ApprovalDetail";

/* css */
import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";

const AdminMember = () => {
  //메뉴항목지정
  const [varMenuItem,setMenuItem] = useState('');
  const [varTermTitle,setTermTitle] = useState('');

  return (
    <>
      <TitleBar/>
      <TabBar varMenuType={'MEMBER'}/>
      <Menus varMenuType={'MEMBER'} varMenuItem={varMenuItem}/>
      <Routes>
        <Route path="MemberLists/:memMode" element={<MemberLists setMenuItem={setMenuItem} varTermTitle={varTermTitle} setTermTitle={setTermTitle} />} />
        <Route path="MemberDetail/:memMode/:mem_idx" element={<MemberDetail varTermTitle={varTermTitle} />} />
        <Route path="MemberApproval" element={<MemberApproval setMenuItem={setMenuItem} />} />
        <Route path="ApprovalDetail/:mcm_idx" element={<ApprovalDetail />} />
      </Routes>
    </>
  );
}
export default AdminMember;