import React from "react"; 
import { isObjEmpty } from "../common/Common";

//결제진행
const PaymentMethod = ({varBankAccountList,varPaymentData,setPaymentData}) => {
  
  //결제수단 선택
  const onChangeMethodHandler = (e) => {
    const { name,value } = e.currentTarget;
    if(name === "ph_method") {
      const ph_method = Number(value);
      setPaymentData({...varPaymentData,ph_method:ph_method});
    } else {
      const bh_idx = Number(value);
      const bankData = varBankAccountList.find(el=>el.bh_idx===bh_idx);
      setPaymentData({...varPaymentData,bh_idx:bh_idx,bh_account:bankData.bh_account,bh_owner:bankData.bh_owner});
    }
  }
  //입금자
  const onInputSenderHandler = (e) => {
    const { name,value } = e.currentTarget;
    setPaymentData({...varPaymentData,[name]:value});
  }

  return isObjEmpty(varPaymentData) || varBankAccountList?.length===0 ?null:(
    <>
      <div className="form_wrap">
        <div className="tit07_1">결제 수단</div>
        <div className="pay_mtd_wrap">
          <ul className="dan2">
            <li className="terms_chk33">
              <input type="radio" name="ph_method" className="chk1" id="ph_method1" value={1} 
                      checked={varPaymentData.ph_method===1} onChange={e=>onChangeMethodHandler(e)}/>
              <i></i><label htmlFor="ph_method1">신용카드</label>
            </li>
            <li className="terms_chk33">
              <input type="radio" name="ph_method" className="chk1" id="ph_method0" value={0} 
                      checked={varPaymentData.ph_method===0} onChange={e=>onChangeMethodHandler(e)}/>
              <i></i><label htmlFor="ph_method0">무통장입금</label>
            </li>
          </ul>
        </div>
      </div>
      {varPaymentData.ph_method === 1? null:
        <div className="form_wrap">
          <div className="tit07_1">입금 계좌</div>
          <div className="pay_mtd_wrap">
            <select name="bh_idx" value={varPaymentData.bh_idx||0} onChange={e=>onChangeMethodHandler(e)}>
              <option value={0}>입금계좌선택</option>
              {varBankAccountList.map((account,i)=>(
                <option key={'account_'+i} value={account.bh_idx}>{account.bh_account+' ('+account.bl_name+')'}</option>
              ))}
            </select>
          </div>
          <div className="tit07_1">입금자명</div>
          <div className="pay_mtd_wrap">
            <input type="text" name="ph_sender" style={{border:"solid 1px #eee"}} value={varPaymentData.ph_sender||""} onChange={e=>onInputSenderHandler(e)}/>
          </div>
        </div>
      }
    </>
  );
}
export default PaymentMethod;