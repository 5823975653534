import React, { useState,useEffect } from "react"; 
import { Link,useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';     

import mapIcon from "../../images/icon_map_gray.png";
import callIcon from "../../images/icon_call_gray.png";

import { isObjEmpty,ChangeMobileFormat } from "../common/Common";

import noSlideImage from "../../images/no_img.png";

const JjimPartner = ({varIndex,varProduct,varDeleteList2,setDeleteList2}) => {
  const navigate = useNavigate();
  
  //사진 목록 저장
  const [varPhotoImage,setPhotoImage] = useState();
  //매물정보
  const [varProductData,setProductData] = useState({});
  //사진 목록 저장
  const [varPhotoList,setPhotoList] = useState([]);

  // 이미지 로딩 상태 관리
  const [varPhotoImages, setPhotoImages] = useState([]);

  // 로딩 상태 관리
  const [varPhotoLoading, setPhotoLoading] = useState(true);


    // 사진 목록 가져오기
  const getProductPhotosList = async() => {
    const sendData = {pageMode:3,ad_idx:varProduct.jp_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_jjim_photo_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1 && datas.results?.length > 0) {
        // 검색된 목록
        setPhotoList(datas.results);
      } else {
        if(varProduct.mcm_image === 1) {
          const memberImage = `${CONFIG.SERVER_HOST}/member/${varProduct.mcm_file}?t=${new Date().getTime()}`;
          loadImageWithRetry(memberImage)
            .then((loadedImage) => {
              setPhotoImage(loadedImage);
              setPhotoLoading(false); // 로딩 완료 후 false로 설정
            })
            .catch((error) => {
              console.error(error.message);
              setPhotoImage(noSlideImage);
              setPhotoLoading(false); // 에러 발생 시에도 로딩 완료로 설정
            });
        }
        else {
          setPhotoImage(noSlideImage);
          setPhotoLoading(false);
        }
      }
    });
  }

  // loadImageWithRetry 함수 정의
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // 캐시 방지용 타임스탬프 추가
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            console.warn(`Retrying to load image: ${src} (${retries} retries left)`);
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setProductData(varProduct);
    getProductPhotosList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varProduct]);

  // 이미지 로딩 처리
  useEffect(() => {
    if (varPhotoList?.length > 0) {
      const initialPhotoImages = varPhotoList.map((photo) => ({
        file_name: photo.file_name,
        src: null,
        loading: true,
      }));
      setPhotoImages(initialPhotoImages);

      varPhotoList.forEach((photo, index) => {
        const sfile = photo.file_name;
        const simage = `${CONFIG.SERVER_HOST}/jangter/${sfile}?t=${new Date().getTime()}`;

        loadImageWithRetry(simage)
          .then((loadedImage) => {
            console.log('Image loaded successfully:', loadedImage);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                src: loadedImage,
                loading: false,
              };
              return newImages;
            });
          })
          .catch((error) => {
            console.error(error.message);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                loading: false,
              };
              return newImages;
            });
          });
      });
    } else {
      setPhotoImages([]);
    }
  }, [varPhotoList]);

  //삭제를 선택한 경우
  const onSelectDeleteHandler = (e,jj_idx) => {
    //e.preventDefault();
    const bmode = e.currentTarget.checked;
    console.log(bmode);
    if(bmode) {
      if(!varDeleteList2.includes(jj_idx)) {
        setDeleteList2(varDeleteList2.concat(jj_idx));
      }
    } else {
      if(varDeleteList2.includes(jj_idx)) {
        const copyList = varDeleteList2.filter(element=>element!==jj_idx);
        setDeleteList2(copyList);
      }
    }
  }

  const onGoingDetailHandler = (e) => {
    localStorage.setItem('scrollIndex',varIndex);
    localStorage.setItem('scrollTop',window.scrollY);
    navigate(`/Jangter/PartnerView/${varProductData.jp_idx}`);
  }

  return isObjEmpty(varProductData)?null:(
    <>
      <div className="edit_on_wrap">
        <div className="terms_chk">
          <input type="checkbox" name="edit" className="chk1" id={"delp_"+varIndex} 
                  defaultChecked={false} onChange={e=>onSelectDeleteHandler(e,varProductData.jj_idx)}/>
          <i></i><label htmlFor={"delp_"+varIndex}>&nbsp;</label>
        </div>
      </div>
          {/* 사진 슬라이드 */}
      <div className="swiper-container listbox listbox01">
      {varPhotoImages?.length <= 1 ?
        <div className="c_wrap" key={'photo_00'}>
          <div className="img_wrap2" style={{ textAlign: 'center', margin: 'auto' }} onClick={e => onGoingDetailHandler(e)}>
            {varPhotoImages?.length === 0 ?
              (
                varPhotoLoading ? (
                  // 로딩 중일 때 로딩 인디케이터 표시
                  <div className="loading-indicator">
                    {/* 로딩 스피너 */}
                    <div className="loader"></div>
                  </div>
                ) : (
                  <img src={varPhotoImage} alt="" />
                )
              ) : (
                <div>
                  {varPhotoImages[0]?.loading ? (
                    // Display loading indicator
                    <div className="loading-indicator">
                      {/* Loading spinner */}
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <>
                      <img src={`${CONFIG.SERVER_HOST}/jangter/${varPhotoImages[0].file_name}`} alt="" />
                      {/* <img src={`${CONFIG.SERVER_HOST}/water_m.png`} alt="Watermark" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '55px', height: 'auto', objectFit: 'cover', pointerEvents: 'none' }} /> */}
                    </>
                  )}
                </div>
              )
            }
          </div>
        </div>
        :
        // Swiper 부분은 그대로 유지
        <Swiper slidesPerView={1.5} spaceBetween={5} loop={true} pagination={{ clickable: true }} modules={[Pagination]} className="mySwiper">
          {varPhotoImages.map((photoObj, i) => (
            <SwiperSlide className="swiper-slide" key={'photo_' + i}>
              <div className="c_wrap">
                <div className="img_wrap" onClick={e => onGoingDetailHandler(e)}>
                  {photoObj.loading ? (
                    // Display loading indicator
                    <div className="loading-indicator">
                      {/* Loading spinner */}
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <>
                      <img src={`${CONFIG.SERVER_HOST}/jangter/${photoObj.file_name}`} alt="" />
                      {/* <img src={`${CONFIG.SERVER_HOST}/water_m.png`} alt="Watermark" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '55px', height: 'auto', objectFit: 'cover', pointerEvents: 'none' }} /> */}
                    </>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      }
    </div>

      {/* 매물정보 */}
      <div className="c_wrap" style={{borderRadius:"0px"}}> 
        <Link to={`/Jangter/PartnerView/${varProductData.jp_idx}`}>
          <div className="info_wrap">
            <div className="info_txt">
              <div className="tit03"><span className="btn_st_A">{varProductData.mcm_dongname}</span>{varProductData.mcm_name}</div>
              <div className="location"><img src={mapIcon} alt=""/>&nbsp;{varProductData.mcm_address1}</div>
              <div className="call"><img src={callIcon} alt=""/>&nbsp;{ChangeMobileFormat(varProductData.mcm_phone)}</div>
              <div className="descript-lines2">{varProductData.mcm_introduce}</div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
export default JjimPartner;